import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notificationsList: any = [];
  notificationsCount: number = 0;


  constructor(public utilityService: UtilityService, public authService: AuthService, private apiService: ApiService, private socketService: SocketService) {
    this.utilityService.breadcrumb = "Notifications";
  }

  ngOnInit() {
    this.fetchNotifications();
    this.socketService.emitEvent('getCount', '');
    //To get overall count
    this.socketService.listen('getCount').subscribe((count: any) => {
      this.notificationsCount = count;
    });
    //To get data on page change
    this.socketService.listen('notificationsIndex').subscribe((response: any) => {
      this.notificationsList = response.allNotifications;
    });
  }

  fetchNotifications() {
    if (this.authService.userObj.role == "pmo" || this.authService.userObj.role == "admin") {
      this.apiService.get('/getNotifications?limit=10').subscribe((response: any) => {
        this.notificationsList = response.allNotifications;
      });
    }
  }

  notificationsIndex(event) {
    this.socketService.emitEvent('notificationsIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  }

  thisoptionChanged(e){
    console.log('event',e)
  }

}
