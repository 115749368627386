import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pdf-small-order-view',
  templateUrl: './pdf-small-order-view.component.html',
  styleUrls: ['./pdf-small-order-view.component.scss']
})
export class PdfSmallOrderViewComponent implements OnInit, OnDestroy {
  smallOrderPreview = {};
  disableBtn: boolean = true;
  public showPreview = false;
  @ViewChild('popUp', { static: false }) public lgModal;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  public modalRef: BsModalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private modalService: BsModalService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.openSmallOrderPreview(params['id']);
    });
  }

  openSmallOrderPreview(id) {
    this.showPreview = false;
    this.apiService.get('/smallorder/' + id).subscribe((response: any) => {

      this.smallOrderPreview = response;

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      setTimeout(() => {
        document.getElementById("download").style.display = "none";
      }, 100);

      this.spinner.hide();

      this.modalRef = this.modalService.show(this.lgModal, this.config);
      this.modalService.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }

}
