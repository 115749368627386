import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubPortfolioAnalyticsRoutingModule } from './sub-portfolio-analytics-routing.module';
import { SubPortfolioAnalyticsComponent } from './sub-portfolio-analytics.component';
import { FormsModule } from '@angular/forms';
import { DxDataGridModule, DxPivotGridModule, DxChartModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';


@NgModule({
  declarations: [SubPortfolioAnalyticsComponent],
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    CurrencyFormatterPipeModule,
    SubPortfolioAnalyticsRoutingModule,
    DxPivotGridModule,
    DxChartModule
  ]
})
export class SubPortfolioAnalyticsModule { }
