import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProgramRisksComponent } from './program-risks.component';
import { DxDataGridModule, DxSelectBoxModule, DxCheckBoxModule, DxTextBoxModule, DxPopoverModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    ModalModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    CurrencyFormatterPipeModule,
    DxTextBoxModule,
    DxPopoverModule
  ],
  declarations: [
    ProgramRisksComponent
  ],
  exports: [
    ProgramRisksComponent
  ]
})
export class ProgramRisksModule { }
