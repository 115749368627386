import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-subportfolios',
  templateUrl: './subportfolios.component.html',
  styleUrls: ['./subportfolios.component.scss']
})
export class SubportfoliosComponent implements OnInit, OnDestroy {
  subportfoliosList = [];
  subportfoliosCount: number = 0;
  programsSearch: any = '';
  isPrivileged: boolean = false;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  stateStore;
  subportfoliosPageIndex = 2;

  constructor(public utilityService: UtilityService, private authService: AuthService, private apiService: ApiService,
    private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "My Subportfolios";
    this.utilityService.breadcrumbList = ['My Subportfolios'];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.stateStore = {
      enabled: true,
      storageKey: 'subportfoliosList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.socketService.listen('subportfoliosByUserCount').subscribe(count => {
      this.subportfoliosCount = count;

      if (count > 20) {
        this.socketService.emitEvent('subportfoliosByUser', { pageIndex: this.subportfoliosPageIndex, pageSize: 20, id: this.authService.userObj.id });
      }
    });

    this.socketService.listen('subportfoliosByUser').subscribe((response: any) => {
      this.subportfoliosList = this.subportfoliosList.concat(response);

      if (this.subportfoliosPageIndex < (this.subportfoliosCount / 20)) {
        this.subportfoliosPageIndex++;
        this.socketService.emitEvent('subportfoliosByUser', { pageIndex: this.subportfoliosPageIndex, pageSize: 20, id: this.authService.userObj.id });
      }
    });

    this.fetchSubportfolios();
  }

  fetchSubportfolios() {
    this.subportfoliosPageIndex = 2;
    this.spinner.show();
    this.apiService.get('/subPortfolio/user/' + this.authService.userObj.id + '?limit=20').subscribe((response: any) => {
      this.subportfoliosList = response;
      this.socketService.emitEvent('subportfoliosByUserCount', { id: this.authService.userObj.id });
      this.spinner.hide();
    });
  }

  refreshGrid() {
    // this.dataGrid.instance.option('dataSource', this.subportfoliosList);
    this.fetchSubportfolios();
  }

  ngOnDestroy() {
    this.socketService.removeListener('subportfoliosByUserCount');
    this.socketService.removeListener('subportfoliosByUser');
  }

}
