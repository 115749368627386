import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramsComponent } from './programs.component';
import { RolesGuard } from 'app/gaurds/roles.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProgramsComponent,
      },
      {
        path: 'detail',
        loadChildren: () => import('./program-detail/program-detail.module').then(mod => mod.ProgramDetailModule)
      },
      {
        path: 'create',
        loadChildren: () => import('./create-program/create-program.module').then(mod => mod.CreateProgramModule),
        data: {
          allowedRoles: ['pmo', 'admin']
        },
        canActivate: [RolesGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramsRoutingModule { }
