import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

var globalThis;

@Component({
  selector: 'app-project-milestones',
  templateUrl: './project-milestones.component.html',
  styleUrls: ['./project-milestones.component.scss']
})
export class ProjectMilestonesComponent implements OnInit {
  mileStonesValues: any = [];
  @Input() reportId;
  @Input() isMember;
  @Output() updateMTAGrid = new EventEmitter<any>();
  @Output() updateMTAGridDeletionCase = new EventEmitter<any>();
  public modalRef: BsModalRef;
  milestoneForm: any = {
    name: '',
    description: '',
    dueDate: '',
    status: 'Due',
    report: false
  };
  isEdit = false;
  backupForm;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  reportRelevantMilestoneCount = 0;
  statusOptions = [];
  helpGuide;
  projectDetailObj;
  isDueDateValid: boolean = true;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private modalService: BsModalService, private spinner: NgxSpinnerService,
    private utilityService: UtilityService, private localize: I18nPipe, private route: ActivatedRoute, private authService: AuthService) {
    this.statusOptions = ['Due', 'Completed'];
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;


    this.getHelpGuideData();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.current != null && params.current != "null" && params.current != undefined) {
        this.apiService.get('/statusReports/' + params.current).subscribe((statusReportResponse: any) => {
          this.apiService.get('/reports/' + statusReportResponse.projectReport.id).subscribe((response: any) => { //this.reportId
            this.projectDetailObj = response;

            this.mileStonesValues = response.mileStonesValues;
            this.reportRelevantMilestoneCount = response.reportRelevantMilestoneCount;
            if (this.mileStonesValues == undefined) {
              this.mileStonesValues = [];
            }
            if (this.reportRelevantMilestoneCount == undefined) {
              this.reportRelevantMilestoneCount = 0;
            }
            if (this.mileStonesValues.length > 0) {
              this.reportRelevantMilestoneCount = 0;
              for (let i = 0; i < this.mileStonesValues.length; i++) {
                if (this.mileStonesValues[i].report == true) {
                  this.reportRelevantMilestoneCount++;
                }
              }
            }
          });
        });
      } else {
        this.apiService.get('/reports/' + params.id).subscribe((response: any) => { //this.reportId
          this.projectDetailObj = response;

          this.mileStonesValues = response.mileStonesValues;
          this.reportRelevantMilestoneCount = response.reportRelevantMilestoneCount;
          if (this.mileStonesValues == undefined) {
            this.mileStonesValues = [];
          }
          if (this.reportRelevantMilestoneCount == undefined) {
            this.reportRelevantMilestoneCount = 0;
          }
          if (this.mileStonesValues.length > 0) {
            this.reportRelevantMilestoneCount = 0;
            for (let i = 0; i < this.mileStonesValues.length; i++) {
              if (this.mileStonesValues[i].report == true) {
                this.reportRelevantMilestoneCount++;
              }
            }
          }
        });
      }
    });
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Project Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  save() {
    if (this.reportId != undefined && this.milestoneformValidation()) {
      if (this.mileStonesValues.length == 0) {
        this.milestoneForm.id = 1;
      } else {
        this.milestoneForm.id = this.mileStonesValues[this.mileStonesValues.length - 1].id + 1;
      }

      if (this.milestoneForm.report == true) {
        if (this.reportRelevantMilestoneCount <= 4) {
          this.mileStonesValues.push(this.milestoneForm);
          this.resetForm();
          this.reportRelevantMilestoneCount++;
          this.spinner.show();
          this.apiService.put('/reports/' + this.reportId, {
            reportRelevantMilestoneCount: this.reportRelevantMilestoneCount,
            mileStonesValues: this.mileStonesValues
          }).subscribe(response => {
            this.updateMTAGrid.emit({ mileStonesValues: this.mileStonesValues });
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.mileStonesValues);
            this.modalRef.hide();
            this.spinner.hide();
          });
        }
        else {
          this.showNotificationBox('Warning', 'Report relevant can be maximum of 5.', 'warning');
        }
      } else {
        this.mileStonesValues.push(this.milestoneForm);
        this.resetForm();
        this.spinner.show();
        this.apiService.put('/reports/' + this.reportId, {
          reportRelevantMilestoneCount: this.reportRelevantMilestoneCount,
          mileStonesValues: this.mileStonesValues
        }).subscribe(response => {
          this.updateMTAGrid.emit({ mileStonesValues: this.mileStonesValues });
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.mileStonesValues);
          this.modalRef.hide();
          this.spinner.hide();
        });
      }
    }
  }

  onEditorPreparingMilestonesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'id') {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = 'Enter your description';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'extentOfLosses') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = 'CHF';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.milestoneForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  onDelete(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant risk cannot be deleted.', 'warning');
        } else {
          let idx;
          this.mileStonesValues.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });
          this.mileStonesValues.splice(idx, 1);

          // Deleting whole column from the Trend Analysis History Table.
          this.projectDetailObj.milestoneTable.map(val => {
            delete (val[`milestone${obj.id}`]);
          });

          //Updating id's 
          // for (let index = 0; index < this.mileStonesValues.length; index++) {
          //   this.mileStonesValues[index].id = index + 1;
          // }

          this.apiService.put('/reports/' + this.reportId, {
            mileStonesValues: this.mileStonesValues,
            milestoneTable: this.projectDetailObj.milestoneTable
          }).subscribe(response => {
            this.updateMTAGridDeletionCase.emit({ mileStonesValues: this.mileStonesValues });
            this.dataGrid.instance.option('dataSource', this.mileStonesValues);
            this.showNotificationBox('Success', 'Milestone deleted.', 'success');
          });
        }
      }
    });
  }

  resetForm() {
    this.milestoneForm = {
      name: '',
      description: '',
      dueDate: '',
      status: 'Due',
      report: false
    };
  }

  edit() {
    if (this.milestoneformValidation()) {
      if (this.milestoneForm.report == true) {
        if (this.backupForm.report == true && this.milestoneForm.report == true) {
          this.spinner.show();
          this.apiService.put('/reports/' + this.reportId, {
            reportRelevantMilestoneCount: this.reportRelevantMilestoneCount,
            mileStonesValues: this.mileStonesValues
          }).subscribe(response => {
            this.updateMTAGrid.emit({ mileStonesValues: this.mileStonesValues });
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.mileStonesValues);
            this.modalRef.hide();
            this.spinner.hide();
          });
        } else {
          if (this.reportRelevantMilestoneCount <= 4) {
            if (this.backupForm.report == false && this.milestoneForm.report == true) {
              this.reportRelevantMilestoneCount++;
            }
            this.spinner.show();
            this.apiService.put('/reports/' + this.reportId, {
              reportRelevantMilestoneCount: this.reportRelevantMilestoneCount,
              mileStonesValues: this.mileStonesValues
            }).subscribe(response => {
              this.updateMTAGrid.emit({ mileStonesValues: this.mileStonesValues });
              this.isEdit = false;
              this.dataGrid.instance.option('dataSource', this.mileStonesValues);
              this.modalRef.hide();
              this.spinner.hide();
            });
          }
          else {
            this.milestoneForm.report = false;
            this.showNotificationBox('Warning', 'Report relevant can be maximum of 5.', 'warning');
          }
        }
      } else {
        if (this.backupForm.report == true && this.milestoneForm.report == false) {
          this.reportRelevantMilestoneCount--;
        }
        this.spinner.show();

        this.apiService.put('/reports/' + this.reportId, {
          reportRelevantMilestoneCount: this.reportRelevantMilestoneCount,
          mileStonesValues: this.mileStonesValues
        }).subscribe(response => {
          this.updateMTAGrid.emit({ mileStonesValues: this.mileStonesValues });
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.mileStonesValues);
          this.modalRef.hide();
          this.spinner.hide();
        });
      }
    }
  }

  milestoneformValidation(): boolean {
    if (this.milestoneForm.name.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a milestone name first.', 'warning');
      return false;
    }
    if (this.milestoneForm.description.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a milestone description first.', 'warning');
      return false;
    }
    if (!this.isDueDateValid) {
      this.showNotificationBox('Warning', 'Please enter milestone due date in correct format (dd.MM.yyyy) first.', 'warning');
      return false;
    }
    if (this.milestoneForm.dueDate == "" || this.milestoneForm.dueDate == null) {
      this.showNotificationBox('Warning', 'Please enter a milestone due date first.', 'warning');
      return false;
    }
    if (this.milestoneForm.status == "" || this.milestoneForm.status == undefined) {
      this.showNotificationBox('Warning', 'Please enter a milestone status first.', 'warning');
      return false;
    }
    else {
      return true;
    }
  }

  checkStatus(status) {
    if (status.value == 'Completed') {
      swal({
        title: `${this.localize.transform('Confirmation')}`,
        text: `${this.localize.transform('Are you sure will you like to mark this milestone complete? The status of milestone cannot be reverted')}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: `${this.localize.transform('Yes')}`,
        cancelButtonText: `${this.localize.transform('No')}`,
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.milestoneForm.status = status.value;
        } else {
          this.milestoneForm.status = 'Due';
        }
      });
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
