import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewAsRoutingModule } from './view-as-routing.module';
import { ViewAsComponent } from './view-as.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DxDataGridModule, DxCheckBoxModule, DxSelectBoxModule, DxAutocompleteModule, DxScrollViewModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { MaterialModule } from 'app/material.module';
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFModule } from 'app/pdf/pdf.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [ViewAsComponent],
  imports: [
    CommonModule,
    ViewAsRoutingModule,
    NgxSpinnerModule,
    DxDataGridModule,
    I18nModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PDFModule,
    MaterialModule,
    ClipboardModule,
    NgxSpinnerModule,
    ModalModule,
    DxAutocompleteModule,
    DxScrollViewModule
  ]
})
export class ViewAsModule { }
