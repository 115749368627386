import { UtilityService } from 'app/services/utility.service';
declare var $: any;
declare var html2canvas: any;
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal/modal.directive';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment';
import { DxChartComponent } from 'devextreme-angular';

import * as jsPDF from 'jspdf';
import { ShortNumberPipe } from 'app/shared/short-number-pipe/short-number.pipe';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
declare var $: any;
declare var html2canvas: any;
let globalThis;

@Component({
  selector: 'app-pdf-project-status-report',
  templateUrl: './pdf-project-status-report.component.html',
  styleUrls: ['./pdf-project-status-report.component.scss']
})
export class PdfProjectStatusReportComponent implements OnInit {
  @Input() report;
  @Input() prevReport;
  projectReport;
  evaList = [];
  projectsPerProjectTypeChart;
  progressChart;
  financialTrend;
  financialTrendEVA;
  @ViewChild('progressChart', { static: false }) progressChartElem: DxChartComponent;
  @ViewChild('financialTrend', { static: false }) financialTrendElem: DxChartComponent;
  @ViewChild('milestoneTrendAnalysis', { static: false }) milestoneTrendAnalysisElem: DxChartComponent;
  mtaChart: any = [{
    reportingDate: moment(Date.now()),
    milestones: [Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now()]
  }];
  mtaReport = [];
  risks = [];
  risksTopFiveRiskCategorySelectOptions;
  risksTopFiveReputationRiskSelectOptions = [{
    reputationRisks: 0,
  }, {
    reputationRisks: 1,
  }, {
    reputationRisks: 2,
  }, {
    reputationRisks: 3,
  }, {
    reputationRisks: 4,
  }];
  riskPrevious = '';
  riskCurrent = '';
  otherRisksRE: number = 0;
  costPosition = [];
  showLoading: boolean = false;
  BAC = 0;
  EAC = 0;
  AC = 0;
  EV = 0;
  PV = 0;

  constructor(private apiService: ApiService, private utilityService: UtilityService,
    private shortNumber: ShortNumberPipe, private localize: I18nPipe, private authService: AuthService) {

    globalThis = this;

    this.projectsPerProjectTypeChart = [
      {
        name: 'Plant / Solution Project',
        value: 0
      }, {
        name: 'Infrastructure',
        value: 0
      }, {
        name: 'Development Project',
        value: 0
      }, {
        name: 'M&A- /Divestment Project',
        value: 0
      }, {
        name: 'Optimization Project',
        value: 0
      }, {
        name: 'IT Project',
        value: 0
      }, {
        name: 'Post-Merger Project',
        value: 0
      },];

    this.progressChart = [
      {
        area: '% ' + this.localize.transform("Work"),  //'% Arbeit', //Work',
        value: 0
      }, {
        area: '% ' + this.localize.transform("Cost"),  //'% Kosten', //Cost',
        value: 0
      }, {
        area: '% ' + this.localize.transform("Time"),   //'% Zeit',   //Time',
        value: 0
      },];

    this.financialTrend = [
      {
        type: this.localize.transform("Original Budget"),  //"Budget original",   //"Original Budget",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Current Budget"), //"Budget aktuell",  //"Current Budget",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Actual Cost"), //"Kosten aktuell",  //"Actual Cost",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Forecast"),  //"Kosten prognose", //"Forecast",
        bkwShare: 0,
        otherShare: 0
      }
    ];

    this.financialTrendEVA = [
      {
        type: this.localize.transform("Original Budget"),  //"Budget original",   //"Original Budget",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Current Budget"),  //"Budget aktuell",  //"Current Budget",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Actual Cost"),  //"Kosten aktuell",  //"Actual Cost",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: this.localize.transform("Forecast"),  //"Kosten prognose", //"Forecast",
        bkwShare: 0,
        otherShare: 0
      }, {
        type: "EAC",
        bkwShare: 0,
        otherShare: 0
      }
    ]
  }

  ngOnInit() {
    if (this.report.costTypeTable != undefined) {
      let totalValOriginalBudget = (this.report.costTypeTable[6].originalBudget * 100) / this.report.bkwShare;
      if (totalValOriginalBudget <= 0) {
        this.financialTrend[0].otherShare = 0;
        this.financialTrend[0].bkwShare = 0;

        this.financialTrendEVA[0].otherShare = 0;
        this.financialTrendEVA[0].bkwShare = 0;
      }
      else {
        let externalValOriginalBudget = totalValOriginalBudget - this.report.costTypeTable[6].originalBudget;
        this.financialTrend[0].otherShare = externalValOriginalBudget;
        this.financialTrend[0].bkwShare = totalValOriginalBudget - externalValOriginalBudget;

        this.financialTrendEVA[0].otherShare = externalValOriginalBudget;
        this.financialTrendEVA[0].bkwShare = totalValOriginalBudget - externalValOriginalBudget;
      }

      let totalValCurrentBudget = (this.report.costTypeTable[6].currentBudget * 100) / this.report.bkwShare;
      if (totalValCurrentBudget <= 0) {
        this.financialTrend[1].otherShare = 0;
        this.financialTrend[1].bkwShare = 0;

        this.financialTrendEVA[1].otherShare = 0;
        this.financialTrendEVA[1].bkwShare = 0;
      }
      else {
        let externalValCurrentBudget = totalValCurrentBudget - this.report.costTypeTable[6].currentBudget;
        this.financialTrend[1].otherShare = externalValCurrentBudget;
        this.financialTrend[1].bkwShare = totalValCurrentBudget - externalValCurrentBudget;

        this.financialTrendEVA[1].otherShare = externalValCurrentBudget;
        this.financialTrendEVA[1].bkwShare = totalValCurrentBudget - externalValCurrentBudget;
      }

      // let totalValActualCost = (this.report.costTypeTable[6].actualCost * 100) / this.report.bkwShare;
      // if (totalValActualCost <= 0) {
      this.financialTrend[2].otherShare = this.report.partnerCost ? this.report.partnerCost : 0;
      this.financialTrend[2].bkwShare = this.report.costTypeTable[6].actualCost;

      this.financialTrendEVA[2].otherShare = this.report.partnerCost ? this.report.partnerCost : 0;
      this.financialTrendEVA[2].bkwShare = this.report.costTypeTable[6].actualCost;
      // }
      // else {
      //   let externalValActualCost = totalValActualCost - this.report.costTypeTable[6].actualCost;

      //   this.financialTrend[2].otherShare = externalValActualCost;
      //   this.financialTrend[2].bkwShare = totalValActualCost - externalValActualCost;

      //   this.financialTrendEVA[2].otherShare = externalValActualCost;
      //   this.financialTrendEVA[2].bkwShare = totalValActualCost - externalValActualCost;
      // }

      let totalValForecast = (this.report.costTypeTable[6].forecast * 100) / this.report.bkwShare;
      if (totalValForecast <= 0) {
        this.financialTrend[3].otherShare = 0;
        this.financialTrend[3].bkwShare = 0;

        this.financialTrendEVA[3].otherShare = 0;
        this.financialTrendEVA[3].bkwShare = 0;
      }
      else {
        let externalValForecast = totalValForecast - this.report.costTypeTable[6].forecast;

        this.financialTrend[3].otherShare = externalValForecast;
        this.financialTrend[3].bkwShare = totalValForecast - externalValForecast;

        this.financialTrendEVA[3].otherShare = externalValForecast;
        this.financialTrendEVA[3].bkwShare = totalValForecast - externalValForecast;
      }
    }

    if (this.report.EVA != undefined) {
      if (this.report.EVA.length > 0) {
        this.report.EVA.forEach(val => {
          this.EAC = val.EAC ? val.EAC : this.EAC;
        });
        // let totalValEAC = (this.EAC * 100) / this.report.bkwShare;
        // if (totalValEAC <= 0) {
        //   this.financialTrendEVA[4].otherShare = 0;
        //   this.financialTrendEVA[4].bkwShare = 0;
        // } else {
        // let externalValEAC = totalValEAC - this.EAC;
        this.financialTrendEVA[4].otherShare = this.EAC;
        // this.financialTrendEVA[4].bkwShare = totalValEAC - externalValEAC;
        // }
      }
    }

    this.progressChart[0].value = (this.report.percentageComplete);
    let mta = this.report.MTAChart;

    mta.forEach((element, index) => {
      element.reportingDate = new Date(element.reportingDate);
      for (let i = 1; i <= Object.keys(element).length - 1; i++) {
        element[`milestone${i}`] = element[`milestone${i}`] != undefined ? new Date(element[`milestone${i}`]) : null;
      }
      mta[index] = element;
    });
    this.mtaChart = mta;

    // Current Reserve Chart 
    if (this.report.currentReserveHistory != undefined) {
      this.report.currentReserveHistory.forEach((element, index) => {
        this.report.currentReserveHistory[index].date = new Date(element.date);
      });
    }

    if (typeof (this.report.mileStonesValues) == 'object') {
      this.report.mileStonesValues = [];
    }
    this.report.mileStonesValues.forEach(value => {
      if (value.report == true) {
        this.mtaReport.push(value);
      }
    });

    let id = this.report.projectReport.id ? this.report.projectReport.id : this.report.projectReport;
    this.apiService.get('/reports/' + id).subscribe(projectReportObj => {
      this.projectReport = projectReportObj;

      if (this.projectReport.mileStonesValues != undefined) {
        let milestoneVal = this.projectReport.mileStonesValues;
        this.projectReport.mileStonesValues = [];

        for (let i = 0; i < milestoneVal.length; i++) {
          if (milestoneVal[i].report == true) {
            this.projectReport.mileStonesValues.push(milestoneVal[i]);
          }
        }
      }

      for (let i = 0; i < this.projectReport.mileStonesValues.length - 1; i++) {
        if (this.projectReport.mileStonesValues[i].report === false) {
          this.projectReport.mileStonesValues.splice(i, 1);
        }
      }

      this.progressChart[1].value = (((this.report.costTypeTable ? this.report.costTypeTable[6].actualCost : 0)
        / (this.report.costTypeTable ? this.report.costTypeTable[6].currentBudget : 1) * 100)).toFixed(2);

      let start = moment(this.report.startDate);
      let end = moment(this.report.endDate);
      let plannedDate = moment(this.projectReport.plannedEndDate);

      let duration = moment.duration(end.diff(start));
      // this.progressChart[2].value = ((moment.duration(moment(Date.now()).diff(start)).asDays() / (duration.asDays()) * 100)).toFixed(2);
      this.progressChart[2].value = Math.abs(100 - (Math.round((plannedDate.diff(start, 'days') - moment(Date.now()).diff(start, 'days')) / plannedDate.diff(start, 'days') * 100)));

      if (this.report.risks != undefined) {
        for (let i = 0; i < this.report.risks.length; i++) {
          if (this.report.risks[i].report == false) {
            this.otherRisksRE += this.report.risks[i].riskExposure;
          }
        }
      }

      if (this.report.EVA != undefined) {
        this.evaList = this.report.EVA;
        for (let i = this.evaList.length - 1; i >= 0; i--) {
          if (this.evaList[i].CPI != 0 && this.evaList[i].CPI != undefined) {
            this.costPosition.push({ CPI: this.evaList[i].CPI, SPI: this.evaList[i].SPI });
            break;
          }
        }
      }

      this.evaList.forEach(val => {
        this.BAC = val.BAC ? val.BAC : this.BAC;
        this.EAC = val.EAC ? val.EAC : this.EAC;
        this.AC = val.AC ? val.AC : this.AC;
        if (this.AC != 0) {
          if (val.AC != undefined) {
            this.PV = val.PV;
            this.PV = val.PV;
          }
        }
        this.EV = val.EV ? val.EV : this.EV;
      });

      this.report.risks.forEach(risk => {
        if (risk.report) {
          this.risks.push(risk);
        }
      });

      // Calculate Risk previous and current status
      if (this.report.riskStatus == "Low") {
        this.riskCurrent = "L";
      } else if (this.report.riskStatus == 'Medium') {
        this.riskCurrent = 'M';
      } else {
        this.riskCurrent = 'H';
      }

      if (this.prevReport) {
        if (this.prevReport.riskStatus == "Low") {
          this.riskPrevious = "L";
        } else if (this.prevReport.riskStatus == 'Medium') {
          this.riskPrevious = 'M';
        } else if (this.prevReport.riskStatus == 'High') {
          this.riskPrevious = 'H';
        } else {
          this.riskPrevious = '';
        }
      } else {
        this.riskPrevious = '';
      }


      setTimeout(() => {
        this.progressChartElem.instance.option('dataSource', this.progressChart);
      }, 0);
    });
  }

  customizePoint(point) {
    if (point.argument == "O") {
      return {
        color: "#1f497d"
      }
    } else if (point.argument == "C") {
      return {
        color: "#8eb4e3"
      }
    } else if (point.argument == "A") {
      return {
        color: "#9bbb59"
      }
    } else if (point.argument == "F") {
      return {
        color: "#f79646"
      }
    }
  }

  onCellPrepared(e) {
    e.cellElement.style.backgroundColor = "#f0f0f0";
  }

  customizeSeries(e) {
    return e ? { showInLegend: true } : { showInLegend: false };
  }

  onDone(e) {
    setTimeout(() => {
      let series;
      if (this.report.mileStonesValues) {
        this.report.mileStonesValues.forEach((val, index) => {
          series = e.component.getSeriesByName("M" + (index + 1));
          if (series.getAllPoints().length == 0) {
            e.component.option(`series[${index}].showInLegend`, false);
          }
        });
      }
    });
  }

  onDoneReservesChart(e) {
    setTimeout(() => {
      let series;
      this.report.currentReserveHistory.forEach((val, index) => {
        series = e.component.getSeriesByName("M" + (index + 1));
        if (series.getAllPoints().length == 0) {
          e.component.option(`series[${index}].showInLegend`, false);
        }
      });
    });
  }

  download() {
    let imgWidth = 297;
    let pageHeight = 210; //295
    let position = 0;

    const elementToPrint: any = $('#printable-area');
    let downloadElem = elementToPrint.find('#download')[0];
    downloadElem.remove();
    const pdf = new jsPDF('l', 'mm', 'a4'); //[200, 800]

    this.showLoading = true;

    $('#progressChart .dxc-labels g g rect').css('fill', 'transparent');
    $('#financialTrend .dxc-labels g g rect').css('fill', 'transparent');
    $('#progressChart .dxc-labels g g text').css('fill', 'black');
    $('#financialTrend .dxc-labels g g text').css('fill', 'black');

    html2canvas(elementToPrint[0], { scale: 3.0 }).then(function (canvas) {
      //let imgHeight = canvas.height * imgWidth / canvas.width;
      // let imgHeight = 416;
      let imgHeight = 393.279375;

      let heightLeft = imgHeight;
      let image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position + 1, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      pdf.save('Report.pdf');
      globalThis.showLoading = false;
      elementToPrint.children().children().append(downloadElem);
    });
  }

  customizeTooltip(arg: any) {
    return {
      text: arg.percentText + ' - ' + arg.valueText
    };
  }

  customizeTextFinancialTrend = (arg: any) => {
    return arg.valueText + "M";
  }

  customizeProgressChartLabel(e) {
    return e.value + '%';
  }

  customizeFinancialTrendLabel(e) {
    return globalThis.shortNumber.transform(e.value);
  }
}
