import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { UtilityService } from "../../services/utility.service";
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'app/services/auth.service';


@Component({
  selector: 'app-pipeline-view',
  templateUrl: './pipeline-view.component.html',
  styleUrls: ['./pipeline-view.component.scss']
})
export class PipelineViewComponent implements OnInit, OnDestroy {
  projectsList;
  backupList;
  projectsCount: number = 0;
  projectsSearch: string = '';
  stateStore;
  isVisible: boolean = false;
  showPreview = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  showsmallOrderPreview = false;
  outlinePreview;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  smallOrderPreview;
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('lgOrderModal', { static: false }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  @ViewChild('smallOrderModal', { static: false }) public smallOrderModal;
  smallOrdersList = [];
  FrontEnd_PATH = '';
  projectsPageIndex = 1;

  constructor(private apiService: ApiService, private utilityService: UtilityService, private socketService: SocketService, private authService: AuthService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Project Pipeline';
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    this.stateStore = {
      enabled: true,
      storageKey: 'pipelineProjectsList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.projectsList = [];
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Dashboard', 'Project Pipeline'];

    this.socketService.listen('submittedProjectsCount').subscribe(count => {
      this.projectsCount = count;

      this.socketService.emitEvent('submittedProjectsIndex', { pageIndex: this.projectsPageIndex, pageSize: 10 });
    });

    this.socketService.listen('submittedProjectsIndex').subscribe((reportsList: any) => {
      reportsList = this.setProjectOutlineValues(reportsList);
      this.projectsList = this.projectsList.concat(reportsList);
      this.isVisible = true;
      this.spinner.hide();

      if (this.projectsPageIndex < (this.projectsCount / 10)) {
        this.projectsPageIndex++;
        this.socketService.emitEvent('submittedProjectsIndex', { pageIndex: this.projectsPageIndex, pageSize: 10 });
      }
    });

    // this.socketService.listen('submittedProjectsSearchIndex').subscribe(reportsList => {
    //   reportsList = this.setProjectOutlineValues(reportsList);
    //   this.projectsList = reportsList;
    // });

    // this.socketService.listen('submittedProjectsSearch').subscribe(projectsResult => {
    //   this.projectsCount = projectsResult.count;
    //   projectsResult = this.setProjectOutlineValues(projectsResult.projects);
    //   this.projectsList = projectsResult;
    // });

    this.socketService.listen('smallOrdersFilter').subscribe(projects => {
      this.smallOrdersList = projects;
    });
    this.socketService.emitEvent('smallOrdersFilter', { filtersArray: [{ status: ["Draft"] }] });

    this.socketService.emitEvent('submittedProjectsCount', {});
  }

  // projectsIndex(event) {
  //   if (this.projectsSearch == '') {
  //     this.socketService.emitEvent('submittedProjectsIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  //   } else {
  //     this.socketService.emitEvent('submittedProjectsSearchIndex', { search: this.projectsSearch, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  //   }
  // }

  // onSearchChange(event) {
  //   this.socketService.emitEvent('submittedProjectsSearch', { search: event.target.value });
  // }

  // fetchProjectsList() {
  //   this.apiService.get('/getSubmittedProjects').subscribe(reportsList => {
  //     reportsList = this.setProjectOutlineValues(reportsList);
  //     this.projectsList = reportsList;
  //     this.backupList = JSON.parse(JSON.stringify(reportsList));
  //   });
  // }

  setProjectOutlineValues(projLst): any {
    projLst.forEach(proj => {
      if (proj.docType == 'Outline') {
        if (proj.projectOutline.length > 0) {
          this.dropdownInit(proj, 'projectOutline');
        }
      } else if (proj.docType == 'Order') {
        this.dropdownInit(proj, 'projectOrder');
      } else if (proj.docType == 'Change Request') {
        this.dropdownInit(proj, 'changeRequests');
      } else if (proj.docType == 'Closing Report') {
        this.dropdownInit(proj, 'closingReport');
      }
    });

    return projLst;
  }

  openOutlinePreview(outline) {
    this.spinner.show();
    this.showPreview = false;
    outline.projectOutline[0].projectName = outline.projectName;
    this.apiService.get('/projectOutline/' + outline.projectOutline[0].id).subscribe(response => {
      this.outlinePreview = response;
      this.spinner.hide();

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  openOrderPreview(project, order) {
    this.showOrderPreview = false;
    order.projectId = project;
    order = this.utilityService.setOrderPdfValues(order);
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openChangeRequestPreview(project, changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = project;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(project, closingReport) {
    this.showClosingPreview = false;
    closingReport.project = project;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  openSmallOrderPreview(smallOrder) {
    this.showsmallOrderPreview = false;
    this.smallOrderPreview = smallOrder;

    setTimeout(() => {
      this.showsmallOrderPreview = true;
    }, 100);
    this.smallOrderModal.show();
    this.smallOrderModal.onHidden.subscribe((reason: string) => {
      this.showsmallOrderPreview = false;
    });
  }

  dropdownInit(proj, projectKey: string) {
    if (proj[projectKey][proj[projectKey].length - 1].businessUnit.id == undefined) {
      proj[projectKey][proj[projectKey].length - 1].businessUnit = this.utilityService.businessUnitOptions.values.find(x => x.id == proj[projectKey][proj[projectKey].length - 1].businessUnit);
    }

    if (proj[projectKey][proj[projectKey].length - 1].businessArea.id == undefined) {
      proj[projectKey][proj[projectKey].length - 1].businessArea = this.utilityService.businessAreaOptions.values.find(x => x.id == proj[projectKey][proj[projectKey].length - 1].businessArea);
    }

    if (proj[projectKey][proj[projectKey].length - 1].strategicContribution.id == undefined) {
      proj[projectKey][proj[projectKey].length - 1].strategicContribution = this.utilityService.strategicContributionOptions.values.find(x => x.id == proj[projectKey][proj[projectKey].length - 1].strategicContribution);
    }

    if (proj[projectKey][proj[projectKey].length - 1].projectType.id == undefined) {
      proj[projectKey][proj[projectKey].length - 1].projectType = this.utilityService.projectTypeOptions.values.find(x => x.id == proj[projectKey][proj[projectKey].length - 1].projectType);
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('submittedProjectsCount');
    this.socketService.removeListener('submittedProjectsIndex');
    this.socketService.removeListener('submittedProjectsSearchIndex');
    this.socketService.removeListener('submittedProjectsSearch');
  }

  customizeExportData(e) {

  }
}
