

export const languages: any = [
  {
    "key": "us",
    "alt": "United States",
    "title": "English (US)"
  },
  // {
  //   "key": "fr",
  //   "alt": "France",
  //   "title": "Français"
  // },
  {
    "key": "de",
    "alt": "German",
    "title": "Deutsch"
  },
];
