import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ProjectBudgetComponent } from './project-budget.component';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    CurrencyFormatterPipeModule
  ],
  declarations: [ProjectBudgetComponent],
  exports: [ProjectBudgetComponent]
})
export class ProjectBudgetModule { }
