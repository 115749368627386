import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-small-orders',
  templateUrl: './small-orders.component.html',
  styleUrls: ['./small-orders.component.scss']
})
export class SmallOrdersComponent implements OnInit, OnDestroy {
  smallOrdersList = [];
  teamSmallOrdersList = [];
  smallOrdersCount: number = 0;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('lgNewProjectModal', { static: false }) public newProjectModal;
  stateStore;
  showPreview = false;
  showNewBox = false;
  smallOrderPreview;
  smallOrdersPageIndex = 2;
  FrontEnd_PATH;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(public utilityService: UtilityService, private router: Router, private authService: AuthService, private apiService: ApiService,
    private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "My Orders";
    this.utilityService.breadcrumbList = ['My Orders'];
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.stateStore = {
      enabled: true,
      storageKey: 'smallOrdersList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.socketService.listen('smallOrdersByUserCount').subscribe(count => {
      this.smallOrdersCount = count;

      if (count > 20) {
        this.socketService.emitEvent('smallOrdersByUserIndex', { pageIndex: this.smallOrdersPageIndex, pageSize: 20, userId: this.authService.userObj.id });
      }
    });

    this.socketService.listen('smallOrdersByUserIndex').subscribe((response: any) => {
      this.smallOrdersList = this.smallOrdersList.concat(response);

      if (this.smallOrdersPageIndex < (this.smallOrdersCount / 20)) {
        this.smallOrdersPageIndex++;
        this.socketService.emitEvent('smallOrdersByUserIndex', { pageIndex: this.smallOrdersPageIndex, pageSize: 20, userId: this.authService.userObj.id });
      }
    });

    this.fetchSmallOrders();
    this.fetchTeamSmallOrders();
  }

  openOrderPreview(smallOrder) {
    this.showPreview = false;
    this.smallOrderPreview = smallOrder;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  openNewProjectBox() {
    this.spinner.show();
    this.showNewBox = false;

    setTimeout(() => {
      this.showNewBox = true;
    }, 100);

    this.newProjectModal.show();
    this.newProjectModal.onHidden.subscribe((reason: string) => {
      this.showNewBox = false;
    });
    this.spinner.hide();
  }

  fetchSmallOrders() {
    this.spinner.show();
    this.apiService.get('/smallOrder/user/' + this.authService.userObj.id + '?limit=20').subscribe((response: any) => {
      this.smallOrdersList = response;
      this.socketService.emitEvent('smallOrdersByUserCount', { userId: this.authService.userObj.id });
      this.spinner.hide();
    });
  }


  fetchTeamSmallOrders() {
    this.apiService.get('/user/' + this.authService.userObj.id).subscribe((responseUser: any) => {
      if (responseUser.teams.length > 0) {
        this.teamSmallOrdersList = [];
        responseUser.smallOrderTeam.forEach((team, index) => {
          this.apiService.get('/smallOrder/' + team.smallOrder).subscribe((responseProject: any) => {
            this.apiService.get('/getSmallOrderRight/' + responseProject.id + '/' + this.authService.userObj.id).subscribe((right: any) => {
              responseProject.isView = right.isView;
              responseProject.isEdit = right.isEdit;
              this.teamSmallOrdersList.push(responseProject);
            });
          });
        });
      }
    });
  }

  navigateToDetailsGantt(routeObj, isTeamSmallOrder) {
    this.utilityService.isTeamSmallOrder = isTeamSmallOrder;
    this.router.navigate(routeObj);
  }

  refreshGrid() {
    this.smallOrdersPageIndex = 2;
    this.fetchSmallOrders();
    this.dataGrid.instance.option('dataSource', this.smallOrdersList);
  }

  ngOnDestroy() {
    this.socketService.removeListener('smallOrdersByUserCount');
    this.socketService.removeListener('smallOrdersByUserIndex');
  }

}
