import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-program-agregated-milstones',
  templateUrl: './program-agregated-milstones.component.html',
  styleUrls: ['./program-agregated-milstones.component.scss']
})
export class ProgramAgregatedMilstonesComponent implements OnInit {
  agregatedMilstonesList = [];
  reportId;
  isVisible: boolean = false;

  constructor(private apiService: ApiService, private authService: AuthService, private spinner: NgxSpinnerService,
   private route: ActivatedRoute, private utilityService: UtilityService) {
  }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.reportId = params.id;

      this.apiService.get('/program/' + this.reportId).subscribe((response: any) => {
        this.utilityService.breadcrumbList = ['My Programs', response.programName, 'Details', 'Milestones'];
        response.reports.forEach((report, index) => {
          report.mileStonesValues.forEach(milestone => {
            if (milestone.description != "") {
              let obj: any = {};
              obj.projectName = report.projectName;
              obj.description = milestone.description;
              obj.dueDate = milestone.dueDate;
              obj.report = milestone.report;
              if (milestone.status == undefined) {
                obj.status = "";
              }
              else {
                obj.status = milestone.status;
              }
              this.agregatedMilstonesList.push(obj);
            }
          });
        });
        this.isVisible = true;
        this.spinner.hide();
      });
    });
  }

  onEditorPreparingMilestonesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'projectName') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
    }
  }
}
