import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectPhasesFinancialsComponent } from './project-phases-financials.component';


const routes: Routes = [
  {
    path: '',
    component: ProjectPhasesFinancialsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectPhasesFinancialsRoutingModule { }
