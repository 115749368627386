import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreatePortfolioRoutingModule } from './create-portfolio-routing.module';
import { CreatePortfolioComponent } from './create-portfolio.component';
import { FormsModule } from '@angular/forms';
import { DxSelectBoxModule, DxTextBoxModule, DxDataGridModule, DxPopoverModule, DxAutocompleteModule, DxDateBoxModule, DxCheckBoxModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { MaterialModule } from 'app/material.module';

@NgModule({
  imports: [
    CommonModule,
    CreatePortfolioRoutingModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    I18nModule,
    DxDataGridModule,
    DxPopoverModule,
    MaterialModule,
    DxAutocompleteModule,
    DxDateBoxModule,
    DxCheckBoxModule
  ],
  declarations: [CreatePortfolioComponent]
})
export class CreatePortfolioModule { }
