import { UtilityService } from 'app/services/utility.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/Rx';
import { DxDataGridComponent, DxScrollViewComponent } from 'devextreme-angular';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { NotificationsService } from 'app/services/notifications.service';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
declare var $: any;
var globalThis;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projectsList: any;
  projectsCount: number = 0;
  projectsSearch: any = '';
  teamProjectsList: any = [];
  public projectTableOptions: any = {};
  projectsSubject = new Subject<any>();
  outlinePreview;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  showPreview = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  showNewBox = false;
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('lgOrderModal', { static: false }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  @ViewChild('commentsModal', { static: false }) public commentsModal;
  @ViewChild('lgNewProjectModal', { static: false }) public newProjectModal;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  BASE_PATH = '';
  attachmentQueryParams = '';
  showComments = false;
  commentsArr = [];
  commentMsg = '';
  commentsProjectId;
  FrontEnd_PATH = '';
  projectsStateStore;
  teamProjectsStateStore;
  projectsPageIndex = 2;

  constructor(public datePipe: DatePipe, public utilityService: UtilityService, private apiService: ApiService, private authService: AuthService,
    private router: Router, private localize: I18nPipe, private notificationService: NotificationsService,
    private route: ActivatedRoute, private socketService: SocketService, private spinner: NgxSpinnerService) {
    globalThis = this;

    this.utilityService.breadcrumb = "My Projects";
    this.utilityService.breadcrumbList = ['My Projects'];

    this.outlinePreview = {
      kpiTableData: [],
    }
    this.fetchTeamProjects();
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    this.projectsStateStore = {
      enabled: true,
      storageKey: 'projectsList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.teamProjectsStateStore = {
      enabled: true,
      storageKey: 'teamProjectsList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.socketService.listen('projectsCount').subscribe(count => {
      this.projectsCount = count;

      if (count > 20) {
        this.socketService.emitEvent('projectsIndex', { userId: this.authService.userObj.id, pageIndex: this.projectsPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('projectsIndex').subscribe((response: any) => {
      let assignedProjectsValues = this.assignProjectsValues(response);
      this.projectsList = this.projectsList.concat(assignedProjectsValues);

      if (this.projectsPageIndex < (this.projectsCount / 20)) {
        this.projectsPageIndex++;
        this.socketService.emitEvent('projectsIndex', { userId: this.authService.userObj.id, pageIndex: this.projectsPageIndex, pageSize: 20 });
      }
    });

    this.fetchProjects();
  
  }

  fetchProjects() {
    this.spinner.show();
    this.apiService.get('/projects/userProjects/' + this.authService.userObj.id + '?limit=20').subscribe((response: any) => {
      this.projectsList = this.assignProjectsValues(response);
      this.socketService.emitEvent('projectsCount', { userId: this.authService.userObj.id });
      this.spinner.hide();
    });
  }

  assignProjectsValues(response) {
    response.forEach((project, index) => {
      if (project.docType == 'Outline') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.projectOutline[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.projectOutline[0].projectSponsor;
        }
      } else if (project.docType == 'Order') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.projectOrder[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.projectOrder[0].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.projectOrder[0].projectFico;
        }
      } else if (project.docType == 'Change Request') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].projectFico;
        }
      } else if (project.docType == 'Closing Report') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.closingReport[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.closingReport[0].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.closingReport[0].projectFico;
        }
      }
    });

    return response;
  }

  fetchTeamProjects() {
    this.apiService.get('/user/' + this.authService.userObj.id + '/teams?limit=350').subscribe((teams: any) => {
      if (teams.length > 0) {
        this.teamProjectsList = [];
        

        teams.forEach((team, index) => {
          if (team.project && team.project != "undefined") {
            this.apiService.get('/projects/' + team.project).subscribe((responseProject: any) => {
              if (responseProject.projectReport && responseProject.projectReport.status == 'Active') {
                this.apiService.get('/getRight/' + responseProject.id + '/' + this.authService.userObj.id).subscribe((right: any) => {
                  responseProject.isView = right.isView;
                  responseProject.isEdit = right.isEdit;

                  this.teamProjectsList.push(responseProject);

                  if (index == teams.length - 1) {
                    this.teamProjectsList.forEach((project, index) => {
                      this.apiService.get('/projectOutline/' + project.projectOutline[0].id).subscribe((outlineResponse: any) => {
                        project.projectOutline[0] = outlineResponse;
                        if (project.projectOrder[0] != undefined && project.projectOrder[0] != null) {
                          this.apiService.get('/projectOrder/' + project.projectOrder[0].id).subscribe((orderResponse: any) => {
                            project.projectOrder[0] = orderResponse;
                          });
                        }
                        if (project.changeRequests != undefined && project.changeRequests != null) {
                          if (project.changeRequests.length > 0) {
                            project.changeRequests.forEach((changeRequest, index) => {
                              this.apiService.get('/changeRequest/' + project.changeRequests[index].id).subscribe((changeRequestResponse: any) => {
                                project.changeRequests[index] = changeRequestResponse;
                              });
                            });
                          }
                        }
                        if (project.closingReport[0] != undefined && project.closingReport[0] != null) {
                          this.apiService.get('/closingReport/' + project.closingReport[0].id).subscribe((closingReportResponse: any) => {
                            project.closingReport[0] = closingReportResponse;
                          });
                        }
                        if (project.docType == 'Outline') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.projectOutline[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.projectOutline[0].projectSponsor;
                          }
                        } else if (project.docType == 'Order') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].projectFico;
                          }
                        } else if (project.docType == 'Change Request') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].projectFico;
                          }
                        } else if (project.docType == 'Closing Report') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].projectFico;
                          }
                        }
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }

    
    });
  }

  redirectToLink(link) {
    if (link != '') {
      window.open(link, "_blank");
    }
  }

  createProjectPhase(project) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(`Do you want to create phase from the Project ${project.projectName}?`)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        let projectOutline = project.projectOutline[project.projectOutline.length - 1];
        delete projectOutline.id;
        delete projectOutline.projectId;
        projectOutline.projectName = project.projectName + "-" + project.uid;

        let projectObj = {
          outlineSubmitted: true,
          outlineApproved: true,
          orderSubmitted: false,
          orderApproved: false,
          isSponsorApproved: true,
          projectName: project.projectName + "-" + project.uid,
          status: "Approved",
          workflowStatus: "Outline has been approved.",
          docType: "Outline",
          isFreezed: true,
          user: project.user,
          subPortfolio: project.subPortfolio,
          startDate: project.startDate,
          endDate: project.endDate,
          isPMOApprovedOutline: true,
          mode: "project",
          isPhase: true,
          parentProject: project.id,
          changeRequestMade: false,
          isCashedOut: false,
          isClosed: false,
          pmoApprovedOutlineDate: project.pmoApprovedOutlineDate,
          sponsorApprovedDate: project.sponsorApprovedDate,
          projectOutline
        }

        this.spinner.show();
        this.apiService.post('/cloneOutlineProject', projectObj).subscribe((projectCreated: any) => {
          projectCreated = projectCreated.project;

          let budget = [
            {
              costType: "External Costs",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 0,
              group: "CAPEX",
            }, {
              costType: "Internal Costs",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 1,
              group: "CAPEX",
            }, {
              costType: "External Costs",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 2,
              group: "OPEX"
            }, {
              costType: "Internal Costs",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 3,
              group: "OPEX"
            }, {
              costType: "Revenues",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 4,
              group: "Sonstiges",
            }, {
              costType: "Reserves",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              group: "Sonstiges",
              id: 5,
            }, {
              costType: "Total",
              budget: '',
              thereofIT: '',
              currentBudget: "",
              yearlyForecast: '',
              thereofITForecast: '',
              remainingProjectBudget: "",
              id: 6,
              group: "Sonstiges",
            },
          ];

          this.apiService.post('/projectBudgetCost/createForSubportfolio', {
            subPortfolio: project.subPortfolio.id,
            project: projectCreated.id,
            budget: budget
          }).subscribe((projectBudgetCostResponce: any) => {

            let startDate = projectCreated.projectOutline[0].startOfProjectOrder;
            let endDate = projectCreated.projectOutline[0].initiationApprovalDate;
            projectCreated.projectOutline[0].plannedEndDate = project.projectOutline[0].initiationApprovalDate;
            projectCreated.projectOutline[0].forecastEndDate = project.projectOutline[0].initiationApprovalDate;

            let costTypeTable = [
              {
                costTypes: "External Costs",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 0,
                group: 'CAPEX',
              },
              {
                costTypes: "Internal Costs",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 1,
                group: 'CAPEX',
              },
              {
                costTypes: "External Costs",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 2,
                group: 'OPEX',
              },
              {
                costTypes: "Internal Costs",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 3,
                group: 'OPEX',
              },
              {
                costTypes: "Revenues",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 4,
                group: 'Sonstiges',
              },
              {
                costTypes: "Reserves",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 5,
                group: 'Sonstiges',
              },
              {
                costTypes: "Total",
                actualCost: '',
                forecast: '',
                currentBudget: "",
                originalBudget: '',
                id: 6,
                group: 'Sonstiges',
              },];

            projectCreated.projectOutline[0].fundsApprovedForInitiationTable.forEach((value, index) => {
              costTypeTable[index].forecast = value.budget;
              costTypeTable[index].currentBudget = value.budget;
              costTypeTable[index].originalBudget = value.budget;
            });

            let estimatedProjectCostTableOutline = projectCreated.projectOutline[0].estimatedProjectTable;
            let projectBudgetCurrent = projectCreated.projectOutline[0].fundsApprovedForInitiationTable[4].total;
            delete (projectCreated.projectOutline[0].fundsApprovedForInitiationTable);

            let risks = [
              {
                id: 1,
                name: '',
                description: '',
                probability: '',
                extentOfLosses: '',
                riskExposure: '',
                riskStrategy: '',
                reputationRisks: '',
                measures: '',
                report: true,
              }, {
                id: 2,
                name: '',
                description: '',
                probability: '',
                extentOfLosses: '',
                riskExposure: '',
                riskStrategy: '',
                reputationRisks: '',
                measures: '',
                report: true,
              }, {
                id: 3,
                name: '',
                description: '',
                probability: '',
                extentOfLosses: '',
                riskExposure: '',
                riskStrategy: '',
                reputationRisks: '',
                measures: '',
                report: true,
              }, {
                id: 4,
                name: '',
                description: '',
                probability: '',
                extentOfLosses: '',
                riskExposure: '',
                riskStrategy: '',
                reputationRisks: '',
                measures: '',
                report: true,
              }, {
                id: 5,
                name: '',
                description: '',
                probability: '',
                extentOfLosses: '',
                riskExposure: '',
                riskStrategy: '',
                reputationRisks: '',
                measures: '',
                report: true,
              }];

            let mileStonesValues = [
              {
                id: 1,
                name: '',
                description: "",
                dueDate: '',
                report: true
              },
              {
                id: 2,
                name: '',
                description: "",
                dueDate: '',
                report: true
              },
              {
                id: 3,
                name: '',
                description: "",
                dueDate: '',
                report: true
              },
              {
                id: 4,
                name: '',
                description: "",
                dueDate: '',
                report: true
              },
              {
                id: 5,
                name: '',
                description: "",
                dueDate: '',
                report: true
              }
            ];
            let actualCostTable = [{
              costType: "External Costs",
              actualCost: '',
              id: 0,
              group: "CAPEX",
              actualBudget: '',
            }, {
              costType: "Internal Costs",
              actualCost: '',
              id: 1,
              group: "CAPEX",
              actualBudget: '',
            }, {
              costType: "External Costs",
              actualCost: '',
              id: 2,
              group: "OPEX",
              actualBudget: '',
            }, {
              costType: "Internal Costs",
              actualCost: '',
              id: 3,
              group: "OPEX",
              actualBudget: '',
            }, {
              costType: "Revenues",
              actualCost: '',
              id: 4,
              group: "Sonstiges",
              actualBudget: '',
            }, {
              costType: "Reserves",
              actualCost: '',
              group: "Sonstiges",
              actualBudget: '',
              id: 5,
            }, {
              costType: "Total",
              actualCost: '',
              id: 6,
              group: "Sonstiges",
              actualBudget: '',
            }];


            projectCreated.projectOutline[0].risksTable.forEach((element, index) => {
              risks[index].description = element.description
            });

            delete (projectCreated.projectOutline[0].attachment1);
            delete (projectCreated.projectOutline[0].attachment2);
            delete (projectCreated.projectOutline[0].attachments);
            delete (projectCreated.projectOutline[0].isAttachement1Added);
            delete (projectCreated.projectOutline[0].isAttachement2Added);
            let outlineStartDate = projectCreated.projectOutline[0].startDate;
            let outlineEndDate = projectCreated.projectOutline[0].endDate;
            delete (projectCreated.projectOutline[0].startDate);
            delete (projectCreated.projectOutline[0].endDate);
            delete (projectCreated.projectOutline[0].startOfProjectOrder);
            delete (projectCreated.projectOutline[0].initiationApprovalDate);

            let projectPhaseOptions = this.utilityService.projectPhaseOptions;
            let projectPhase;

            if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
              projectPhase = projectPhaseOptions.values.find(val => val.name == 'Initiating').id;
            }

            this.apiService.post('/reports', {
              uid: projectCreated.uid,
              ...projectOutline,
              projectName: projectCreated.projectName,
              user: projectCreated.user,
              startDate: startDate,
              endDate: endDate,
              plannedEndDate: endDate,
              forecastEndDate: endDate,
              outlineStartDate,
              outlineEndDate,
              project: projectCreated.id,
              projectId: projectCreated.id,
              status: 'Active',
              projectPhase: projectPhase ? projectPhase : undefined,
              program: projectCreated.projectOutline[0].program,
              projectBudgetCurrent: projectBudgetCurrent,
              costTypeTable: costTypeTable,
              estimatedProjectCostTableOutline: estimatedProjectCostTableOutline,
              currentReserveHistory: [],
              risks: risks,
              mileStonesValues: mileStonesValues,
              reportRelevantRisksCount: 0,
              actualCostTable
            }).subscribe((report: any) => {
              this.apiService.put('/projects/' + projectCreated.id, {
                projectReport: report.id
              }).subscribe((response: any) => {
                this.apiService.post('/team', {
                  users: [report.projectSponsor.id],
                  project: projectCreated.id
                }).subscribe((responseTeam: any) => {
                  this.apiService.post('/rights', {
                    user: report.projectSponsor.id,
                    project: projectCreated.id,
                    isView: true,
                    isEdit: true
                  }).subscribe((responseRights: any) => {
                    this.refreshGrid();
                    this.notification('Success', "Outline phase has been created.", 'success');
                    this.spinner.hide();
                  });
                });
              });
            });
          });
        });
      }
    });
  }

  openOutlinePreview(outline) {
    this.spinner.show();
    this.showPreview = false;
    outline.projectOutline[0].projectName = outline.projectName;
    this.apiService.get('/projectOutline/' + outline.projectOutline[0].id).subscribe(response => {
      this.outlinePreview = response;
      this.spinner.hide();

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  openOrderPreview(project, order) {
    this.showOrderPreview = false;
    order.projectId = project;
    order = this.utilityService.setOrderPdfValues(order);
    order.projectOrderId = order.id;
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openComments(projectObj) {
    this.showComments = false;
    this.commentsArr = projectObj.comments;
    this.commentsProjectId = projectObj.id;

    setTimeout(() => {
      this.showComments = true;
    }, 100);
    this.commentsModal.show();
    this.commentsModal.onHidden.subscribe((reason: string) => {
      this.showComments = false;
      this.commentsArr = [];
    });
  }

  saveComments(e) {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';
      setTimeout(() => {
        this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      }, 100);

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe((response: any) => {
        // Update the existing grid
        this.projectsList.forEach((project, index) => {
          if (this.commentsProjectId == project.id) {
            this.projectsList[index].comments = response.comments;
          }
        });
        this.notificationService.createNotification(this.commentsProjectId, '', 'comment', this.authService.userObj.id);
        this.refreshGrid();
      });
    }
  }

  refreshGrid() {
    this.projectsPageIndex = 2;
    this.fetchProjects();
    this.fetchTeamProjects();
    this.dataGrid.instance.option('dataSource', this.projectsList);
  }

  openChangeRequestPreview(project, changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = project;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;
    this.changeRequestPreview.changeRequestId = changeRequest.id;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(project, closingReport) {
    this.showClosingPreview = false;
    closingReport.project = project;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;
    this.closingReportPreview.closingReportId = closingReport.id;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  openNewProjectBox() {
    this.spinner.show();
    this.showNewBox = false;

    setTimeout(() => {
      this.showNewBox = true;
    }, 100);

    this.newProjectModal.show();
    this.newProjectModal.onHidden.subscribe((reason: string) => {
      this.showNewBox = false;
    });
    this.spinner.hide();
  }

  showChangeRequest(data) {
    let flag;
    if (data.key.closingReport) {
      flag = data.key.closingReport.length > 0 ?
        (data.key.closingReport[0].isApproved == true ? false : (data.key.closingReport[0].isSubmitted == true ?
          false : true)) : true;
    }

    if (flag) {
      if (data.key.changeRequests.length > 0) {
        return !data.key.changeRequestMade;
      } else {
        return true;
      }
    } else {
      return flag;
    }
  }

  deleteProject(projectObj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/projects/' + projectObj.id).subscribe(response => {
          this.apiService.delete('/projectOutline/' + projectObj.projectOutline[0].id).subscribe(res => {
            let index;
            for (let i = 0; i < this.projectsList.length; i++) {
              if (this.projectsList[i].id == projectObj.id) {
                index = i;
              }
            }
            this.projectsList.splice(index, 1);
            this.refreshGrid();
            this.notification('Success', "Project has been deleted successfully.", 'success');
          });
        });
      }
    });
  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  scrollToBottom(e) {
    setTimeout(() => {
      e.component.scrollBy($('.dx-scrollview-content').height());
    }, 100);
  }

  navigateToProject(routeObj) {
    this.utilityService.isTeamProject = true;
    this.router.navigate(routeObj);
  }

  navigateToProjectDetails(routeObj, isTeamProject, isTeamEdit) {
    this.utilityService.isTeamProject = isTeamProject;
    this.utilityService.isTeamEdit = isTeamEdit;
    this.router.navigate(routeObj);
  }

  calculateSubmissionDateSortValue(rowData) {
    if (rowData.docType == 'Outline') {
      return rowData.projectOutline[0].date ? globalThis.datePipe.transform(rowData.projectOutline[0].date, 'yyyy/MM/dd') : '';
    }

    if (rowData.docType == 'Order') {
      return rowData.projectOrder[0].date ? globalThis.datePipe.transform(rowData.projectOrder[0].date, 'yyyy/MM/dd') : '';
    }

    if (rowData.docType == 'Change Request') {
      return rowData.changeRequests[rowData.changeRequests.length - 1].date ? globalThis.datePipe.transform(rowData.changeRequests[rowData.changeRequests.length - 1].date, 'yyyy/MM/dd') : '';
    }

    if (rowData.docType == 'Closing Report') {
      return rowData.closingReport[0].date ? globalThis.datePipe.transform(rowData.closingReport[0].date, 'yyyy/MM/dd') : '';
    }
  }


  calculateSubmissionDateValue(rowData) {
    if (rowData.docType == 'Outline') {
      return rowData.projectOutline[0].date ? globalThis.datePipe.transform(rowData.projectOutline[0].date, 'dd.MM.yyyy') : '';
    }

    if (rowData.docType == 'Order') {
      return rowData.projectOrder[0].date ? globalThis.datePipe.transform(rowData.projectOrder[0].date, 'dd.MM.yyyy') : '';
    }

    if (rowData.docType == 'Change Request') {
      return rowData.changeRequests[rowData.changeRequests.length - 1].date ? globalThis.datePipe.transform(rowData.changeRequests[rowData.changeRequests.length - 1].date, 'dd.MM.yyyy') : '';
    }

    if (rowData.docType == 'Closing Report') {
      return rowData.closingReport[0].date ? globalThis.datePipe.transform(rowData.closingReport[0].date, 'dd.MM.yyyy') : '';
    }
  }


  ngOnDestroy() {
    this.socketService.removeListener('projectsCount');
    this.socketService.removeListener('projectsIndex');
  }
}
