import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pdf-change-request-view',
  templateUrl: './pdf-change-request-view.component.html',
  styleUrls: ['./pdf-change-request-view.component.scss']
})
export class PdfChangeRequestViewComponent implements OnInit {
  changeRequestPreview;
  showChangePreview = false;
  @ViewChild('lgChangeModal', {static: false}) public lgChangeModal;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private modalService: BsModalService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.utilityService.projectList = this.route.snapshot.data.projects;
      this.openChangeRequestPreview(params['id']);
    });
  }

  openChangeRequestPreview(id) {
    this.showChangePreview = false;
    this.apiService.get('/outlineApproval/' + id).subscribe((response: any) => {
      if (response.changeRequest.project.projectReport == undefined) {
        this.apiService.get('/projects/' + response.changeRequest.project).subscribe(projectResponse => {
          response.changeRequest.project = projectResponse;
          this.apiService.get('/reports/' + response.changeRequest.project.projectReport.id).subscribe(reportResponse => {
            response.changeRequest.project.projectReport = reportResponse;
            response.changeRequest = this.utilityService.setChangeRequestPdfValues(response.changeRequest);
            this.changeRequestPreview = response.changeRequest;
            this.changeRequestPreview.changeRequestId = response.changeRequest.id;

            setTimeout(() => {
              this.showChangePreview = true;
            }, 100);

            this.spinner.hide();

            this.modalRef = this.modalService.show(this.lgChangeModal, this.config);
            this.modalService.onHidden.subscribe((reason: string) => {
              this.showChangePreview = false;
            });
          });
        });
      } else {
        this.apiService.get('/reports/' + response.project.projectReport).subscribe((reportObj: any) => {
          response.changeRequest.project.projectReport = reportObj;
          response.changeRequest = this.utilityService.setChangeRequestPdfValues(response.changeRequest);
          this.changeRequestPreview = response.changeRequest;
          this.changeRequestPreview.changeRequestId = response.changeRequest.id;

          setTimeout(() => {
            this.showChangePreview = true;
          }, 100);

          setTimeout(() => {
            document.getElementById("download").style.display = "none";
          }, 100);

          this.spinner.hide();

          this.modalRef = this.modalService.show(this.lgChangeModal, this.config);
          this.modalService.onHidden.subscribe((reason: string) => {
            this.showChangePreview = false;
          });
        });
      }
    }, (error) => {
      this.apiService.get('/changeRequest/' + id).subscribe((responseChangeRequest: any) => {
        this.apiService.get('/reports/' + responseChangeRequest.project.projectReport).subscribe(responseProjectReport => {
          responseChangeRequest.project.projectReport = responseProjectReport;
          responseChangeRequest = this.utilityService.setChangeRequestPdfValues(responseChangeRequest);
          this.changeRequestPreview = responseChangeRequest;
          this.changeRequestPreview.changeRequestId = responseChangeRequest.id;

          setTimeout(() => {
            this.showChangePreview = true;
          }, 100);

          setTimeout(() => {
            document.getElementById("download").style.display = "none";
          }, 100);

          this.spinner.hide();

          this.modalRef = this.modalService.show(this.lgChangeModal, this.config);
          this.modalService.onHidden.subscribe((reason: string) => {
            this.showChangePreview = false;
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }
}
