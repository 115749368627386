import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProgramAgregatedMilstonesComponent } from './program-agregated-milstones.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgramAgregatedMilstonesRoutingModule } from './program-agregated-milstones-routing.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    ProgramAgregatedMilstonesRoutingModule
  ],
  declarations: [
    ProgramAgregatedMilstonesComponent
  ]
})
export class ProgramAgregatedMilstonesModule { }
