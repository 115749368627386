import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxDateBoxModule,
  DxCheckBoxModule, DxProgressBarModule, DxChartModule, DxScrollViewModule
} from 'devextreme-angular';
import { SubPortfolioStatusReportRoutingModule } from './sub-portfolio-status-report-routing.module';
import { SubPortfolioStatusReportComponent } from './sub-portfolio-status-report.component';
import { I18nModule } from "../../../../shared/i18n/i18n.module";
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFModule } from 'app/pdf/pdf.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { PdfSubPortfolioStatusReportModule } from './pdf-sub-portfolio-status-report/pdf-sub-portfolio-status-report.module';

@NgModule({
  declarations: [SubPortfolioStatusReportComponent],
  imports: [
    CommonModule,
    SubPortfolioStatusReportRoutingModule,
    DxDataGridModule,
    I18nModule,
    ModalModule,
    DxScrollViewModule,
    FormsModule,
    ReactiveFormsModule,
    PDFModule,
    MaterialModule,
    ClipboardModule,
    NgxSpinnerModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    CurrencyFormatterPipeModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxProgressBarModule,
    DxChartModule,
    PdfSubPortfolioStatusReportModule
  ],
})
export class SubPortfolioStatusReportModule { }
