import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProjectsListWithPortfolioComponent } from './projects-list-with-portfolio.component';
import { DxDataGridModule, DxSelectBoxModule, DxAutocompleteModule, DxScrollViewModule, DxTextBoxModule, DxProgressBarModule, DxDropDownBoxModule, DxTreeViewModule, DxTagBoxModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { PdfMultiProjectReportModule } from '../pdf-multi-project-report/pdf-multi-project-report.module';
import { ProjectsListWithPortfolioRoutingModule } from './projects-list-with-portfolio-routing.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    DxSelectBoxModule,
    ModalModule,
    CurrencyFormatterPipeModule,
    DxScrollViewModule,
    DxTextBoxModule,
    PDFModule,
    DxProgressBarModule,
    PdfMultiProjectReportModule,
    ProjectsListWithPortfolioRoutingModule,
    DxDropDownBoxModule,
    DxAutocompleteModule,
    DxTreeViewModule,
    DxTagBoxModule
  ],
  declarations: [
    ProjectsListWithPortfolioComponent
  ]
})
export class ProjectsListWithPortfolioModule { }
