import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pdf-order-view',
  templateUrl: './pdf-order-view.component.html',
  styleUrls: ['./pdf-order-view.component.scss']
})
export class PdfOrderViewComponent implements OnInit {
  orderPreview;
  showOrderPreview = false;
  @ViewChild('orderModal', { static: false }) public lgOrderModal;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private modalService: BsModalService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.openOrderPreview(params['id']);
    });
  }

  openOrderPreview(id) {
    this.showOrderPreview = false;
    this.apiService.get('/outlineApproval/' + id).subscribe((response: any) => {
      response.projectOrder = this.utilityService.setOrderPdfValues(response.projectOrder);
      this.orderPreview = response.projectOrder;
      this.orderPreview.projectOrderId = this.orderPreview.id;

      setTimeout(() => {
        this.showOrderPreview = true;
      }, 100);

      setTimeout(() => {
        document.getElementById("download").style.display = "none";
      }, 100);

      this.spinner.hide();

      this.modalRef = this.modalService.show(this.lgOrderModal, this.config);
      this.modalService.onHidden.subscribe((reason: string) => {
        this.showOrderPreview = false;
      });
    }, (error) => {
      this.apiService.get('/projectOrder/' + id).subscribe(responseOrder => {
        responseOrder = this.utilityService.setOrderPdfValues(responseOrder);
        this.orderPreview = responseOrder;
        this.orderPreview.projectOrderId = this.orderPreview.id;

        setTimeout(() => {
          this.showOrderPreview = true;
        }, 100);

        setTimeout(() => {
          document.getElementById("download").style.display = "none";
        }, 100);

        this.spinner.hide();

        this.modalRef = this.modalService.show(this.lgOrderModal, this.config);
        this.modalService.onHidden.subscribe((reason: string) => {
          this.showOrderPreview = false;
        });
      });
    });
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }

}
