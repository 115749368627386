import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-project-status-report',
  templateUrl: './project-status-report.component.html',
  styleUrls: ['./project-status-report.component.scss']
})
export class ProjectStatusReportComponent implements OnInit {
  projectObj: any = {
    uid: '',
    portfolio: {
      id: ''
    },
    projectBudgetOriginal: "",
    projectManager: {
      id: 0,
      email: ''
    },
    pmoOfficer: '',
    projectSponsor: '',
    projectFico: '',
    kpisTable: [{}, {}, {}, {}, { value: '' }],
    program: '',
    threeYearsBudgetBreakdown: [],
    mandatoryProject: {
      id: ""
    },
    businessArea: {
      id: ""
    },
    businessUnit: {
      id: ""
    },
    businessSegment: {
      id: ""
    },
    reportingLevel: {
      id: ""
    },
    classification: {
      id: ""
    },
    projectType: {
      id: ""
    },
    strategicContribution: {
      id: ""
    },
    profitability: {
      id: ""
    },
    feasibility: {
      id: ""
    },
    projectMethodology: {
      id: ""
    },
    projectPhase: {
      id: ""
    },
    digitalizationTopic: {
      id: ""
    },
    digitalizationFocus: {
      id: ""
    },
    itPlatform: {
      id: ""
    },
    itRelevant: {
      id: ""
    },
    subPortfolio: {
      id: ''
    }
  };
  statusReportTable = [];
  projectReportId;
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  isMember: boolean = false;
  prevStatusReport;
  FrontEnd_PATH;
  projectId;
  public isTeamEdit: boolean = false;
  public isTeamProject: boolean = false;
  public enableCreateReportButton: boolean = false;
  stateStore;
  isVisible: boolean = false;

  constructor(private apiService: ApiService, private authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private formBuilder: FormBuilder, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    globalThis = this;
    this.getProjectInfo();

    this.stateStore = {
      enabled: true,
      storageKey: 'projectStatusReportList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      this.isTeamProject = this.utilityService.isTeamProject;
      this.isTeamEdit = this.utilityService.isTeamEdit;

      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.utilityService.breadcrumbList = ['My Projects', projectData.projectName, 'Details', 'Status Report'];
        // Check for user roles
        if (!this.isTeamEdit) {
          let user = this.authService.userObj;
          if (user.email == projectData.pmoOfficer.email 
            || user.email == projectData.projectManager.email 
            || user.email == projectData.projectSponsor.email 
            || user.email == projectData.projectFico.email
            || user.role == 'admin'
            || user.role == 'pmo') {
            this.isTeamEdit = true;
            this.utilityService.isTeamEdit = true;
          }
        }

        this.apiService.get('/getActivePortfolios').subscribe((portfolioList: any) => {
          this.apiService.get('/projectBudgetCost/project/' + projectData.project.id).subscribe((responceBudget: any) => {

            this.projectReportId = params.id;
            this.projectId = projectData.project.id;

            this.projectObj = projectData;

            this.fetchStatusReports(params.id);

            this.spinner.hide();
            this.isVisible = true;
            this.utilityService.projectDetail.next({
              projectReportId: this.projectReportId,
              projectId: this.projectId,
              isTeamProject: this.isTeamProject,
              projectName: this.projectObj.projectName,
              tabIndex: 1
            });
          });
        });
      });
    });
  }

  fetchStatusReports(id) {
    this.spinner.show();

    this.apiService.get('/getStatusReportsByProjectReport/' + id).subscribe((statusReportList: any) => {
      this.statusReportTable = statusReportList;
      this.enableCreateReportButton = true;
      this.spinner.hide();
    });
  }

  actionEvent(event, item) {
    let elem: any = document.getElementById('status-' + item.key.id);

    if (event.target.value == "Edit") {
      if (this.projectObj.status != 'Closed') {
        this.router.navigate([`create/${this.statusReportTable.length > 1 ? this.statusReportTable[this.statusReportTable.length - 2].id : null}/${item.key.id}`], {relativeTo: this.route});
        elem.options.selectedIndex = 0;
      }
    } else if (event.target.value == "View") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        this.openStatusPreview(item.key, item.component.getVisibleRows()[item.rowIndex - 1].key);
      } else {
        this.openStatusPreview(item.key, {});
      }
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "copy") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        let text = `${this.FrontEnd_PATH}/view/project/statusReport/${item.component.getVisibleRows()[item.rowIndex - 1].key.id}/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      } else {
        let text = `${this.FrontEnd_PATH}/view/project/statusReport/0/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      }
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "Delete") {
      this.deleteReportConfirmation(item.key.id);
      elem.options.selectedIndex = 0;
    }
  }

  deleteReportConfirmation(id) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        this.apiService.delete('/statusReports/' + id).subscribe(response => {
          let data = this.statusReportTable.filter(report => report.id != id);
          this.statusReportTable = data;
          this.projectObj.hasDraftReport = false;
          this.apiService.put('/reports/' + this.projectReportId, {
            hasDraftReport: false
          }).subscribe(res => { });

          this.spinner.hide();
          this.showNotificationBox('Success', 'Project Report has been Deleted.', 'success');
        });
      }
    });
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

  openStatusPreview(outline, prev) {
    this.statusReportPreview = outline;
    this.prevStatusReport = prev;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  createReportDisableChecker() {
    let flag = false;
    if (this.projectObj.hasDraftReport == true) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.projectObj.status == 'Closed' || this.projectObj.status == 'Not Realized') {
      flag = true;
    }

    return flag;
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
