import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
declare var $: any;
declare var html2canvas: any;
import * as jsPDF from 'jspdf';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'app/services/auth.service';
let globalThis;

@Component({
  selector: 'app-pdf-change-request',
  templateUrl: './pdf-change-request.component.html',
  styleUrls: ['./pdf-change-request.component.scss']
})
export class PdfChangeRequestComponent implements OnInit {
  @Input() public project;
  oldStrategicContributionSlider = 0;
  oldFeasibilitySlider = 0;
  oldProfitabilitySlider = 0;
  newStrategicContributionSlider = 0;
  newFeasibilitySlider = 0;
  newProfitabilitySlider = 0;
  renderChart = true;
  trippleConstraint = {
    cost: 2,
    time: 4,
    scope: 3
  };
  public formObject: any = {};
  lineChartColors = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)"
    }
  ];
  public radarChartType: string = "radar";
  public radarChartLabels: string[] = [];
  costDateList = [];
  public datePercentageDeviation;
  showLoading: boolean = false;
  itPlatform1;
  itPlatform2;
  itPlatform3;
  argumentAxesTitle = 'Time';
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private authService: AuthService, private localize: I18nPipe, private utilityService: UtilityService, private apiService: ApiService, private spinner: NgxSpinnerService) {
    globalThis = this;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';

    this.argumentAxesTitle = this.localize.transform('Time');

    this.formObject.radarChartData = [
      {
        data: [
          this.trippleConstraint.time,
          this.trippleConstraint.cost,
          this.trippleConstraint.scope
        ],
        label: this.localize.transform("Triple Constraint")
      }
    ];

    this.radarChartLabels = [this.localize.transform("Time"), this.localize.transform("Cost"), this.localize.transform("Scope")];

  }

  ngOnInit() {
    console.log(this.project)
    if (this.project.project.projectReport) {
      if (this.project.prioritizationOld) {
        if (this.project.prioritizationOld.strategicContribution.name == "Low") {
          this.oldStrategicContributionSlider = 33.33;
        } else if (this.project.prioritizationOld.strategicContribution.name == "Medium") {
          this.oldStrategicContributionSlider = 66.67;
        } else if (this.project.prioritizationOld.strategicContribution.name == "High") {
          this.oldStrategicContributionSlider = 100;
        }

        if (this.project.prioritizationOld.feasibility.name == "Low") {
          this.oldFeasibilitySlider = 33.33;
        } else if (this.project.prioritizationOld.feasibility.name == "Medium") {
          this.oldFeasibilitySlider = 66.67;
        } else if (this.project.prioritizationOld.feasibility.name == "High") {
          this.oldFeasibilitySlider = 100;
        }

        if (this.project.prioritizationOld.profitability.name == "Low") {
          this.oldProfitabilitySlider = 33.33;
        } else if (this.project.prioritizationOld.profitability.name == "Medium") {
          this.oldProfitabilitySlider = 66.67;
        } else if (this.project.prioritizationOld.profitability.name == "High") {
          this.oldProfitabilitySlider = 100;
        }
      }
    }

    if (this.project.strategicContribution.name == "Low") {
      this.newStrategicContributionSlider = 33.33;
    } else if (this.project.strategicContribution.name == "Medium") {
      this.newStrategicContributionSlider = 66.67;
    } else if (this.project.strategicContribution.name == "High") {
      this.newStrategicContributionSlider = 100;
    }

    if (this.project.feasibility.name == "Low") {
      this.newFeasibilitySlider = 33.33;
    } else if (this.project.feasibility.name == "Medium") {
      this.newFeasibilitySlider = 66.67;
    } else if (this.project.feasibility.name == "High") {
      this.newFeasibilitySlider = 100;
    }

    if (this.project.profitability.name == "Low") {
      this.newProfitabilitySlider = 33.33;
    } else if (this.project.profitability.name == "Medium") {
      this.newProfitabilitySlider = 66.67;
    } else if (this.project.profitability.name == "High") {
      this.newProfitabilitySlider = 100;
    }

    if (this.project.trippleConstraint != undefined) {
      this.trippleConstraint = this.project.trippleConstraint;
    }

    this.updateChart();

    if (this.project.plannedEndDate != undefined && this.project.newEndDate != undefined) {
      let obj1 = { date: moment(this.project.plannedEndDate).format('DD. MMM YYYY'), value: this.project.actualProjectBudget[6].budget };
      let obj2 = { date: moment(this.project.newEndDate).format('DD. MMM YYYY'), value: this.project.effectsOnProjectBudget[6].budget };
      this.costDateList[0] = { ...obj1 };
      this.costDateList[1] = { ...obj2 };
    }

    let plannedEndDate: any = moment(this.project.plannedEndDate);
    let newEndDate: any = moment(this.project.newEndDate);
    let startDate: any = moment(this.project.project.projectReport.startDate);
    let endDate: any = moment(this.project.endDate);
    this.datePercentageDeviation = (((newEndDate.diff(startDate, 'days') - plannedEndDate.diff(startDate, 'days')) /
      plannedEndDate.diff(startDate, 'days')) * 100).toFixed(2) + '%';

    if (this.project.itPlatform.length > 0) {
      this.project.itPlatform.forEach((itPlatform, idx) => {
        if (!itPlatform.id) {
          let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (idx == 0) {
            this.itPlatform1 = itPlatformObj.name;
          } else if (idx == 1) {
            this.itPlatform2 = itPlatformObj.name;
          } else if (idx == 2) {
            this.itPlatform3 = itPlatformObj.name;
          }
        }
      });
    }
  }

  updateChart() {
    if (
      this.trippleConstraint.cost < 11 && this.trippleConstraint.cost > 0 && this.trippleConstraint.time > 0 && this.trippleConstraint.time < 11 &&
      this.trippleConstraint.scope > 0 && this.trippleConstraint.scope < 11) {
      this.formObject.radarChartData[0].data[1] = this.trippleConstraint.cost;
      this.formObject.radarChartData[0].data[0] = this.trippleConstraint.time;
      this.formObject.radarChartData[0].data[2] = this.trippleConstraint.scope;
      this.renderChart = false;
      setTimeout(() => {
        this.renderChart = true;
      }, 0);
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Values must be between 1-10."),
        "#fb3c4a",
      );
    }
  }

  // Chart events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  saveComments(obj, elem) {
    if (this.project && this.project.changeRequestId) {
      this.spinner.show();
      this.apiService.put('/changeRequest/' + this.project.changeRequestId, obj).subscribe(response => {
        this.notification("Success", "Comment Updated", "success");
        this.spinner.hide();
        elem.instance.hide();
      });
    } else {
      elem.instance.hide();
      this.notification("Failed", "Save your Change Request first to add comments", "warning");
    }
  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  download() {
    let imgWidth = 210;
    let pageHeight = 295; //295
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4'); //[200, 800]
    let element = $('#changeRequestPDF');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    let copylinkElem = element.find('#copylink')[0];
    if (copylinkElem) {
      copylinkElem.remove();
    }

    this.showLoading = true;

    html2canvas(element[0], { scale: 2.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 2, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 5, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      pdf.save('Change Request Report.pdf');
      globalThis.showLoading = false;
      element.children().children().append(downloadElem);
      if (copylinkElem) {
        element.children().children().append(copylinkElem);
      }
    });
  }

}