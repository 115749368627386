import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import 'rxjs/operator/map';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { OrgChartComponent } from 'app/components/org-chart/org-chart.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-project-team',
  templateUrl: './project-team.component.html',
  styleUrls: ['./project-team.component.scss']
})
export class ProjectTeamComponent implements OnInit {
  @Input() isProjectUneditable;
  teamId;
  teamUsers: any = [];
  user: any = {
    email: ""
  };
  usersList: any = [];
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  isView: boolean = false;
  isEdit: boolean = false;
  isRightEdit: boolean = false;
  rightsId;
  projectId;
  isTeamProject: boolean = false;;
  isVisible: boolean = false;
  isEditCheckboxDisabled: boolean = false;
  //Chart
  projectOrganizationChartData = [];
  isChartLoaded: boolean = false;
  isOrderApproved: boolean = false;
  projectOrderId;
  @ViewChild("diagram", { static: false }) diagram: OrgChartComponent;

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList[3] = 'Team';
    let sub = this.route.params.subscribe(params => {
      this.projectId = params.id;
      this.isTeamProject = this.utilityService.isTeamProject;

      this.apiService.get('/project/team/' + this.projectId).subscribe((projectTeam: any) => {
        if (projectTeam.teamId != null && projectTeam.teamId != undefined) {
          this.teamId = projectTeam.teamId;
          this.teamUsers = projectTeam.teamUsers;
        }
        this.spinner.hide();
        this.isVisible = true;
      });

      this.spinner.show();
      this.apiService.get('/projects/' + this.projectId).subscribe((projectRes: any) => {
        if (projectRes.projectOrder && projectRes.projectOrder.length > 0 && projectRes.projectOrder[0].status == 'Approved') {
          this.isOrderApproved = true;
          this.projectOrderId = projectRes.projectOrder[0].id;
        }

        this.projectOrganizationChartData = projectRes.projectOrder && projectRes.projectOrder.length > 0 ? projectRes.projectOrder[0].projectOrganizationChart : [];
        this.isChartLoaded = true;
        this.spinner.hide();
      })
    });
  }

  saveChart() {
    if (this.projectOrderId != undefined) {
      this.spinner.show();
      this.apiService.put('/projectOrder/' + this.projectOrderId, {
        projectOrganizationChart: this.diagram.export()
      }).subscribe(response => {
        this.notificationBox('Saved', 'Chart has been updated!', 'success')
        this.spinner.hide();
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.resetForm();
    this.modalRef = this.modalService.show(template, this.config);
  }

  userValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.user = response;
          }
        });
      }
    }
  }

  onEditClicked(e) {
    if (e.value) {
      this.isEditCheckboxDisabled = true
      this.isView = true;
    } else {
      this.isEditCheckboxDisabled = false
    }
  }

  userOnInputEvent(e) {
    this.apiService.get('/user/search/' + this.user.email).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  addUser() {
    if (this.user.id != '' && this.user.id != undefined && this.user.id != null &&
      this.user.email != '' && this.user.email != undefined && this.user.name != null) {
      if (this.teamUsers.find(val => val.id == this.user.id) != undefined) {
        this.notificationBox("Warning", "User has been already added.", "warning");
      } else {
        this.spinner.show();
        if (this.teamId != undefined && this.teamId != null) {
          let teamUsersIds = this.getteamUsersIds();
          teamUsersIds.push(this.user.id);
          this.apiService.put('/team/' + this.teamId, {
            users: teamUsersIds,
          }).subscribe((responseTeam: any) => {
            this.apiService.post('/rights', {
              user: this.user.id,
              project: this.projectId,
              isView: this.isView,
              isEdit: this.isEdit
            }).subscribe((responseRights: any) => {
              let userObj = { user: '', id: '', isView: '', isEdit: '' };
              userObj.user = this.user;
              userObj.id = responseRights.id;
              userObj.isView = responseRights.isView;
              userObj.isEdit = responseRights.isEdit;
              this.teamUsers.push(userObj);
              this.notificationBox("Success", "User Added Successfully.", "success");
              this.modalRef.hide();
              this.spinner.hide();
            });
          });
        } else {
          this.apiService.post('/team', {
            users: [this.user.id],
            project: this.projectId
          }).subscribe((responseTeam: any) => {
            this.teamId = responseTeam.id;
            this.apiService.post('/rights', {
              user: this.user.id,
              project: this.projectId,
              isView: this.isView,
              isEdit: this.isEdit
            }).subscribe((responseRights: any) => {
              let userObj = { user: '', id: '', isView: '', isEdit: '' };
              userObj.user = this.user;
              userObj.id = responseRights.id;
              userObj.isView = responseRights.isView;
              userObj.isEdit = responseRights.isEdit;
              this.teamUsers.push(userObj);
              this.notificationBox("Success", "User Added Successfully.", "success");
              this.modalRef.hide();
              this.spinner.hide();
            });
          });
        }
      }
    } else {
      this.notificationBox("Warning", "Please select user first.", "warning");
    }
  }

  deleteRights(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to remove this user?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        if (this.teamId != undefined && this.teamId != null) {
          let teamUsersIds = this.getteamUsersIds();

          let index = teamUsersIds.findIndex(x => x == obj.user.id);
          if (index > -1) {
            teamUsersIds.splice(index, 1);
          }

          if (teamUsersIds.length > 0) { //If there is atleast one user left in team, we update existing team
            this.apiService.put('/team/' + this.teamId, {
              users: teamUsersIds,
            }).subscribe((responseTeam: any) => {
              this.apiService.delete('/rights/' + obj.id).subscribe((responseRights: any) => {
                let indexInTeamUsers = this.teamUsers.findIndex(x => x.id == obj.id);
                if (indexInTeamUsers > -1) {
                  this.teamUsers.splice(indexInTeamUsers, 1);
                }

                this.notificationBox("Success", "User Deleted Successfully.", "success");
                this.spinner.hide();
              });
            });

          } else { //If there is no user left in team, delete the team
            this.apiService.delete('/team/' + this.teamId).subscribe((responseTeam: any) => {
              this.apiService.delete('/rights/' + obj.id).subscribe((responseRights: any) => {
                let indexInTeamUsers = this.teamUsers.findIndex(x => x.id == obj.id);
                if (indexInTeamUsers > -1) {
                  this.teamUsers.splice(indexInTeamUsers, 1);
                }

                this.teamId = null;
                this.notificationBox("Success", "User Deleted Successfully.", "success");
                this.spinner.hide();
              });
            });
          }
        }
      }
    });
  }

  editRights() {
    this.spinner.show();
    this.apiService.put('/rights/' + this.rightsId, {
      isView: this.isView,
      isEdit: this.isEdit
    }).subscribe((responseRights: any) => {
      let userObj = this.teamUsers.find(val => val.id == this.rightsId);
      userObj.isView = responseRights.isView;
      userObj.isEdit = responseRights.isEdit;
      this.notificationBox("Success", "Rights Updated Successfully.", "success");
      this.modalRef.hide();
      this.spinner.hide();
    });
  }

  getteamUsersIds(): any {
    let teamUsersIds = [];
    this.teamUsers.forEach(team => {
      teamUsersIds.push(team.user.id);
    });
    return teamUsersIds;
  }

  openRightsModel(template, obj) {
    console.log('obj', obj)
    this.resetForm();
    this.isRightEdit = true;
    this.user = obj.user;
    this.rightsId = obj.id;
    this.isView = obj.isView;
    this.isEdit = obj.isEdit;
    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.user = {
      email: ""
    };
    this.isRightEdit = false;
    this.isEdit = false;
    this.isView = false;
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
