import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';


@Component({
  selector: 'app-sub-portfolio-projects',
  templateUrl: './sub-portfolio-projects.component.html',
  styleUrls: ['./sub-portfolio-projects.component.scss']
})
export class SubPortfolioProjectsComponent implements OnInit, OnDestroy {
  reportsList = [];
  stateStore;
  idsArray = [];
  reportsCount: number = 0;
  reportsSearch: string = '';
  projectsSubject = new Subject<any>();
  subportfolioId;
  isVisible: boolean = false;
  subportfolioSmallOrdersList: any = [];
  smallOrderStateStore;

  constructor(private apiService: ApiService, private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private utilityService: UtilityService) {
    this.stateStore = {
      enabled: true,
      storageKey: 'subPortfolioProjectList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.smallOrderStateStore = {
      enabled: true,
      storageKey: 'subPortfolioSmallOrdersList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Projects & Orders'];

    let sub = this.route.params.subscribe(params => {
      this.subportfolioId = params.subPortfolio;

      this.socketService.listen('subportfolioReports').subscribe(response => {
        this.reportsList = response;
        this.reportsList = this.reportsList.map(item => { return {...item, forecast: item.costTypeTable ? item.costTypeTable[6].forecast : 0, actualCost: item.costTypeTable ? item.costTypeTable[6].actualCost : 0, originalBudget: item.costTypeTable ? item.costTypeTable[6].originalBudget : 0, currentBudget: item.costTypeTable ? item.costTypeTable[6].currentBudget : 0, reportingDate: (item.statusReports && item.statusReports.length > 0) ? item.statusReports[item.statusReports.length-1].reportingDate : ''}})
        this.spinner.hide();
      });

      this.socketService.listen('subportfolioOrders').subscribe(response => {
        this.subportfolioSmallOrdersList = response;
        this.subportfolioSmallOrdersList = this.subportfolioSmallOrdersList.map(item => { return {...item, forecast: item.costTypeTable ? item.costTypeTable[6].forecast : 0, actualCost: item.costTypeTable ? item.costTypeTable[6].actualCost : 0, originalBudget: item.costTypeTable ? item.costTypeTable[6].originalBudget : 0, currentBudget: item.costTypeTable ? item.costTypeTable[6].currentBudget : 0, reportingDate: (item.smallOrderStatusReports && item.smallOrderStatusReports.length > 0) ? item.smallOrderStatusReports[item.smallOrderStatusReports.length-1].reportingDate : ''}})
      });

      this.socketService.emitEvent('subportfolioReports', { id: this.subportfolioId });
      this.socketService.emitEvent('subportfolioOrders', { id: this.subportfolioId });
    });
  }

  ngOnDestroy() {
    this.socketService.removeListener('subportfolioReports');
    this.socketService.removeListener('subportfolioOrders');
  }

}
