import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-small-order-status-report',
  templateUrl: './small-order-status-report.component.html',
  styleUrls: ['./small-order-status-report.component.scss']
})
export class SmallOrderStatusReportComponent implements OnInit {
  statusReportTable = [];
  smallOrderId;
  smallOrderObj;
  prevStatusReport;
  FrontEnd_PATH;
  public enableCreateReportButton: boolean = false;
  stateStore;
  isVisible: boolean = false;
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;

  constructor(private apiService: ApiService, private authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private formBuilder: FormBuilder, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.utilityService.breadcrumb = "Order Details";
    globalThis = this;
    this.getOrderInfo();

    this.stateStore = {
      enabled: true,
      storageKey: 'smallOrderStatusReportList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getOrderInfo() {
    let sub = this.route.params.subscribe(params => {
      this.smallOrderId = params.id;

      this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe((response: any) => {
        this.smallOrderObj = response;
        this.utilityService.breadcrumbList = ['My Orders', this.smallOrderObj.name, 'Status Report'];
        this.fetchStatusReports(this.smallOrderId);

        this.isVisible = true;
      });
    });
  }

  fetchStatusReports(id) {
    this.spinner.show();

    this.apiService.get('/getStatusReportsByOrder/' + id).subscribe((statusReportList: any) => {
      this.statusReportTable = statusReportList;
      this.enableCreateReportButton = true;
      this.spinner.hide();
    });
  }

  actionEvent(event, item) {
    let elem: any = document.getElementById('status-' + item.key.id);

    if (event.target.value == "Edit") {
      if (this.smallOrderObj.status != 'Closed') {
        this.router.navigate([`smallOrder/${this.smallOrderId}/details/statusReport/${this.statusReportTable.length > 1 ? this.statusReportTable[this.statusReportTable.length - 2].id : null}/${item.key.id}`]);
        elem.options.selectedIndex = 0;
      }
    } 
    else if (event.target.value == "View") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        this.openStatusPreview(item.key, item.component.getVisibleRows()[item.rowIndex - 1].key);
      } else {
        this.openStatusPreview(item.key, {});
      }
      elem.options.selectedIndex = 0;
    } 
    // else if (event.target.value == "copy") {
    //   if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
    //     let text = `${this.FrontEnd_PATH}/view/project/statusReport/${item.component.getVisibleRows()[item.rowIndex - 1].key.id}/${item.key.id}`;
    //     this.clipboardService.copyFromContent(text);
    //   } else {
    //     let text = `${this.FrontEnd_PATH}/view/project/statusReport/0/${item.key.id}`;
    //     this.clipboardService.copyFromContent(text);
    //   }
    //   elem.options.selectedIndex = 0;
    // } 
    else if (event.target.value == "Delete") {
      this.deleteReportConfirmation(item.key.id);
      elem.options.selectedIndex = 0;
    }
  }

  deleteReportConfirmation(id) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();

        this.apiService.delete('/smallOrderStatusReport/' + id).subscribe(response => {
          let data = this.statusReportTable.filter(report => report.id != id);
          this.statusReportTable = data;
          this.smallOrderObj.hasDraftReport = false;
          this.apiService.put('/smallOrder/' + this.smallOrderId, {
            hasDraftReport: false
          }).subscribe(res => { });
          
          this.spinner.hide();
          this.showNotificationBox('Success', 'Order Report has been Deleted.', 'success');
        });
      }
    });
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

  openStatusPreview(outline, prev) {
    this.statusReportPreview = outline;
    this.prevStatusReport = prev;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  createReportDisableChecker() {
    let flag = false;
    if (this.smallOrderObj.hasDraftReport) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.smallOrderObj.status == 'Closed') {
      flag = true;
    }

    return flag;
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
