import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    DxDataGridModule,
    MatPaginatorModule
  ],
  declarations: [NotificationsComponent]
})
export class NotificationsModule { }
