import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfOutlineComponent } from 'app/pdf/pdf-outline/pdf-outline.component';
import { PdfOrderComponent } from 'app/pdf/pdf-order/pdf-order.component';
import { PdfChangeRequestComponent } from 'app/pdf/pdf-change-request/pdf-change-request.component';
import { PdfClosingReportComponent } from 'app/pdf/pdf-closing-report/pdf-closing-report.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { DxPieChartModule, DxSliderModule, DxChartModule, DxPopoverModule } from 'devextreme-angular';
import { ChartsModule } from 'ng2-charts';
import { PdfSmallOrderComponent } from './pdf-small-order/pdf-small-order.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CurrencyFormatterPipeModule,
    DxPieChartModule,
    DxSliderModule,
    DxChartModule,
    ChartsModule,
    I18nModule,
    ClipboardModule,
    FormsModule,
    DxPopoverModule
  ],
  declarations: [
    PdfOutlineComponent,
    PdfOrderComponent,
    PdfChangeRequestComponent,
    PdfClosingReportComponent,
    PdfSmallOrderComponent
  ],
  exports: [
    PdfOutlineComponent,
    PdfOrderComponent,
    PdfChangeRequestComponent,
    PdfClosingReportComponent,
    PdfSmallOrderComponent
  ]
})

export class PDFModule { }