import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ApiService {
  // public BASE_PATH = "https://localhost:1337";
  // public BASE_PATH = "https://109.203.124.158";
  public BASE_PATH = "https://109.203.126.97:1337";
  // public BASE_PATH = "http://109.203.126.97:1337";
  // BASE_PATH = 'https://megowork.co.uk:1337';
  // public BASE_PATH = "https://159.100.251.162:1337"; //Prod 2
  // BASE_PATH = 'https://89.145.164.26';
  // BASE_PATH = 'https://159.100.254.226';
  // BASE_PATH = 'https://be.bkw-oneview.com';
  projectTableInitCounter = 0;
  // public FrontEndPATH = "http://euk-84842.eukservers.com/#"; //Test 1
  public FrontEndPATH = "http://euk-88794.eukservers.com/#"; 
  // public FrontEndPATH = "http://test.megowork.solutions/#"; //Test 2
  // public FrontEndPATH = "http://euk-84842.eukservers.com/#";
  // public FrontEndPATH = "http://159.100.251.162/#";
  // public FrontEndPATH = "http://89.145.164.26/#";
  // public FrontEndPATH = "http://159.100.254.226/#";
  // public FrontEndPATH = "https://www.bkw-oneview.com/#";
  public httpOptions;
  accessToken: string;

  constructor(private http: HttpClient, ) {
    if (this.accessToken == undefined) {
      this.accessToken = localStorage.getItem('token');
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.accessToken
      })
    };
  }

  get(path) {
    this.checkHeaders();
    return this.http.get(this.BASE_PATH + path, this.httpOptions)
  }

  post(path, body) {
    return this.http.post(this.BASE_PATH + path, body, this.httpOptions)
  }

  put(path, body) {
    return this.http.put(this.BASE_PATH + path, body, this.httpOptions)
  }

  patch(path, body) {
    return this.http.patch(this.BASE_PATH + path, body, this.httpOptions)
  }

  delete(path) {
    return this.http.delete(this.BASE_PATH + path, this.httpOptions)
  }

  postDownload(path, body) {
    return this.http.post(this.BASE_PATH + path, body, this.httpOptions);
  }

  login(params?: string) {
    if (params != undefined) {
      window.location.href = this.BASE_PATH + '/login?redirectTo=' + params;
    } else {
      window.location.href = this.BASE_PATH + '/login';
    }
  }

  checkHeaders() {
    if (this.accessToken == undefined) {
      let token = localStorage.getItem('token');
      if (token != undefined) {
        this.accessToken = token;
        this.httpOptions = {};
        this.httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.accessToken
          })
        };
      }
    }
  }
}
