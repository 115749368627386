import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular';
import { ApiService } from 'app/services/api.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'app/services/notifications.service';
declare var $: any;

@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderPreviewComponent implements OnInit {
  id: any;
  orderPreview;
  orderObj;
  disableBtn: boolean = true
  showOrderPreview = false;
  @ViewChild('orderModal', { static: false }) public lgOrderModal;
  commentsArr = [];
  commentMsg = '';
  modalRef;
  commentsProjectId;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private authService: AuthService, private modalService: BsModalService,
    private spinner: NgxSpinnerService, private notificationService: NotificationsService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.utilityService.projectList = this.route.snapshot.data.projects;
      this.openOrderPreview();
    });
  }

  openOrderPreview() {
    this.showOrderPreview = false;
    this.apiService.get('/outlineApproval/' + this.id).subscribe((response: any) => {
      this.orderObj = response

      this.showComments();

      response.projectOrder = this.utilityService.setOrderPdfValues(response.projectOrder);
      this.orderPreview = response.projectOrder;

      setTimeout(() => {
        this.showOrderPreview = true;
      }, 100);

      setTimeout(() => {
        document.getElementById("download").style.display = "none";

        if (this.orderObj.isFreezed == true) {
          this.disableBtn = true
        }
        else {
          this.disableBtn = false;
        }
      }, 100);

      this.spinner.hide();

      this.modalRef = this.modalService.show(this.lgOrderModal, this.config);
      this.modalService.onHidden.subscribe((reason: string) => {
        this.showOrderPreview = false;
      });
    });
  }

  approve(): void {
    this.spinner.show();
    this.apiService.put('/outlineApproval/' + this.orderObj.id, {
      status: 'Approved',
      isFreezed: true,
      // overallStatus: "Approved"
    }).subscribe((approvedProj: any) => {
      this.orderObj.status = approvedProj.status;
      this.orderObj = approvedProj;
      this.notificationService.createNotification(this.orderObj.project.id, 'Project Order', 'approved', this.orderObj.assignedTo.id);
      if (approvedProj.sentTo == "PMO") {
        this.apiService.post('/outlineApproval', {
          projectOrder: approvedProj.projectOrder,
          status: "Open",
          projectStatus: 'Submitted',
          overallStatus: "Submitted",
          assignedTo: approvedProj.projectOrder.projectSponsor.id,
          project: approvedProj.project.id,
          docType: 'Order',
          sentTo: "Sponsor",
          isFreezed: false,
          uid: approvedProj.uid,
          version: approvedProj.projectOrder.version,
          isPMOApprovedOrder: true,
          pmoApprovedOrderDate: new Date().toISOString(),
        }).subscribe((newApproval: any) => {
          this.apiService.put('/projects/' + this.orderObj.project.id, {
            workflowStatus: 'Order has been sent to Project Sponsor for approval.',
            pmoApprovedOrderDate: new Date().toISOString(),
            isPMOApprovedOrder: true,
            status: 'Submitted'
          }).subscribe((response: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOrderNew = approvedProj.projectOrder;
            approvedOrderNew.projectId = projectResponse;
            approvedOrderNew.projectId.isPMOApprovedOrder = true;
            approvedOrderNew.status = 'Submitted';
            this.apiService.put('/outlineApproval/' + this.orderObj.id, {
              projectOrder: approvedOrderNew,
              isPMOApprovedOrder: true,
              projectStatus: 'Submitted',
              overallStatus: "Submitted",
              pmoApprovedOrderDate: new Date().toISOString()
            }).subscribe((resp: any) => {
              this.disableBtn = true;
              this.notification('Success', "Order has been sent to Project Sponsor for approval.");
            this.spinner.hide();
              this.apiService.post('/sendEmail', {
                email: newApproval.projectOrder.projectSponsor.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
              <a href="${this.apiService.FrontEndPATH}/orderPreview/${newApproval.id}" target="_blank">${'Projektauftrag / Project Order : ' + response.projectName}</a>`
              }).subscribe(msg => { });
            });
          });
        });
      } else if (approvedProj.sentTo == "Sponsor") {
        this.apiService.post('/outlineApproval', {
          projectOrder: approvedProj.projectOrder,
          status: "Open",
          projectStatus: 'Submitted',
          overallStatus: "Submitted",
          assignedTo: approvedProj.projectOrder.projectFico.id,
          project: approvedProj.project.id,
          docType: 'Order',
          sentTo: "FICO",
          isFreezed: false,
          uid: approvedProj.uid,
          version: approvedProj.projectOrder.version,
          isPMOApprovedOrder: true,
          isSponsorApprovedOrder: true,
          sponsorApprovedOrderDate: new Date().toISOString(),
          pmoApprovedOrderDate: this.orderObj.pmoApprovedOrderDate
        }).subscribe((newApproval: any) => {
          this.apiService.put('/projects/' + this.orderObj.project.id, {
            workflowStatus: 'Order has been sent to Financial Manager for approval.',
            sponsorApprovedOrderDate: new Date().toISOString(),
            isSponsorApprovedOrder: true,
            status: 'Submitted'
          }).subscribe((response: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOrderNew = approvedProj.projectOrder;
            approvedOrderNew.projectId = projectResponse;
            approvedProj.projectOrder.projectId.isSponsorApprovedOrder = true;
            approvedOrderNew.status = 'Submitted';

            this.apiService.put('/outlineApproval/' + this.orderObj.id, {
              projectOrder: approvedOrderNew,
              isPMOApprovedOrder: true,
              isSponsorApprovedOrder: true,
              projectStatus: 'Submitted',
              overallStatus: "Submitted",
              sponsorApprovedOrderDate: new Date().toISOString(),
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  uid: resp.uid,
                  docType: 'Order',
                  sentTo: 'PMO',
                  version: resp.version
                },
                projectItem: {
                  projectOrder: approvedOrderNew,
                  isSponsorApprovedOrder: true,
                  projectStatus: 'Submitted',
                  sponsorApprovedOrderDate: new Date().toISOString(),
                  overallStatus: "Submitted"
                }
              }).subscribe((resp: any) => {
                this.disableBtn = true;
                this.notification('Success', "Order has been sent to Financial Manager for approval.");
            this.spinner.hide();
                this.apiService.post('/sendEmail', {
                  email: newApproval.projectOrder.projectFico.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
              <a href="${this.apiService.FrontEndPATH}/orderPreview/${newApproval.id}" target="_blank">${'Projektauftrag / Project Order : ' + response.projectName}</a>`
                }).subscribe(msg => { });
              });
            });
          });
        });
      } else if (approvedProj.sentTo == "FICO") {
        this.apiService.put('/projects/' + this.orderObj.project.id, {
          orderApproved: true,
          status: 'Approved',
          workflowStatus: 'Order has been approved.',
          ficoApprovedOrderDate: new Date().toISOString(),
          isFicoApprovedOrder: true,
          isOrderFreezed: true
        }).subscribe((project: any) => {
          let projectResponse = Object.assign({}, project);
          delete (projectResponse.projectOutline);
          delete (projectResponse.projectOrder);
          delete (projectResponse.history);
          delete (projectResponse.changeRequests);
          delete (projectResponse.closingReport);
          delete (projectResponse.approvals);
          delete (projectResponse.user);
          delete (projectResponse.subPortfolio);
          let approvedOrderNew = approvedProj.projectOrder;
          approvedOrderNew.projectId = projectResponse;
          this.apiService.put('/outlineApproval/' + this.orderObj.id, {
            projectOrder: approvedOrderNew,
            isPMOApprovedOrder: true,
            isSponsorApprovedOrder: true,
            isFicoApprovedOrder: true,
            ficoApprovedOrderDate: new Date().toISOString(),
            projectStatus: 'Approved',
            overallStatus: "Approved"
          }).subscribe((resp: any) => {
            this.apiService.post('/updatepreviousapproval/', {
              query: {
                uid: resp.uid,
                docType: 'Order',
                version: resp.version
              },
              projectItem: {
                projectOrder: approvedOrderNew,
                isFicoApprovedOrder: true,
                projectStatus: 'Approved',
                ficoApprovedOrderDate: new Date().toISOString(),
                overallStatus: "Approved"
              }
            }).subscribe((resp: any) => {

              this.reportOverwriter(approvedProj, project);
            });
          });
        });
      }
    });
  }

  onHold(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.orderObj.project.id, {
      status: 'On Hold',
      workflowStatus: 'Order has been put on hold.'
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.orderObj.id, {
        status: 'On Hold',
        projectStatus: 'On Hold',
        overallStatus: "On Hold"
      }).subscribe((appRes: any) => {
        this.orderObj.status = appRes.status;
        this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
          status: 'On Hold',
        }).subscribe(orderRes => {
          let projectResponse = Object.assign({}, response);
          delete (projectResponse.projectOutline);
          delete (projectResponse.projectOrder);
          delete (projectResponse.history);
          delete (projectResponse.changeRequests);
          delete (projectResponse.closingReport);
          delete (projectResponse.approvals);
          delete (projectResponse.user);
          delete (projectResponse.subPortfolio);
          let approvedOrderNew = appRes.projectOrder;
          approvedOrderNew.projectId = projectResponse;
          approvedOrderNew.status = 'On Hold';
          this.apiService.put('/outlineApproval/' + this.orderObj.id, {
            projectOrder: approvedOrderNew
          }).subscribe((resp: any) => {
            this.apiService.post('/updatepreviousapproval/', {
              query: {
                uid: resp.uid,
                docType: 'Order',
                version: resp.version
              },
              projectItem: { projectOrder: approvedOrderNew, projectStatus: 'On Hold', overallStatus: "On Hold" }
            }).subscribe((resp: any) => {
              this.notificationService.createNotification(this.orderObj.project.id, 'Project Order', 'held', this.orderObj.assignedTo.id);
              this.apiService.post('/sendEmail', {
                email: appRes.projectOrder.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Order On Hold')[0].text + `<br>` +
                  'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
              }).subscribe(msg => { });
              this.notification('Success', "Order has been put on hold.");
	      this.spinner.hide();
            });
          });
        });
      });
    });
  }

  returnToSender(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.orderObj.project.id, {
      status: 'Returned',
      isOrderFreezed: false,
      orderSubmitted: true,
      // isPMOApprovedOrder: false,
      // isSponsorApprovedOrder: false,
      orderReturned: true,
      workflowStatus: 'Order has been returned back from approver.',
      orderReturnDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.orderObj.id, {
        status: 'Returned',
        projectStatus: 'Returned',
        orderReturned: true,
        isFreezed: true,
        orderReturnDate: new Date().toISOString(),
        overallStatus: "Returned"
      }).subscribe((appRes: any) => {
        this.orderObj.status = appRes.status;
        this.disableBtn = true;

        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedOrderNew = appRes.projectOrder;
        approvedOrderNew.projectId.status = 'Returned';
        approvedOrderNew.projectId.orderReturnDate = new Date().toISOString();
        approvedOrderNew.status = 'Returned';
        approvedOrderNew.projectId.workflowStatus = 'Order has been returned back from approver.'
        this.apiService.put('/outlineApproval/' + this.orderObj.id, {
          projectOrder: approvedOrderNew
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Order',
              version: resp.version
            },
            projectItem: { projectOrder: approvedOrderNew, projectStatus: 'Returned', orderReturnDate: new Date().toISOString(), overallStatus: "Returned" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.orderObj.project.id, 'Project Order', 'returned', this.orderObj.assignedTo.id);
            this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
              status: 'Returned',
            }).subscribe(orderRes => {
              this.apiService.post('/sendEmail', {
                email: appRes.projectOrder.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Returned')[0].text + `<br>` +
                  'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
              }).subscribe(msg => { });
              this.notification('Success', "Order has been returned to sender.");
	      this.spinner.hide();
            });
          });
        });
      });
    });
  }

  reject(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.orderObj.project.id, {
      status: 'Rejected',
      workflowStatus: 'Order has been rejected.',
      //orderSubmitted: false,
      isOrderFreezed: true,
      orderRejectDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.orderObj.id, {
        status: 'Rejected',
        projectStatus: 'Rejected',
        isFreezed: true,
        orderRejectDate: new Date().toISOString(),
        overallStatus: "Rejected"
      }).subscribe((appRes: any) => {
        this.orderObj.status = appRes.status;
        this.disableBtn = true;

        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedOrderNew = appRes.projectOrder;
        approvedOrderNew.projectId = projectResponse;
        approvedOrderNew.projectId.orderRejectDate = new Date().toISOString();
        approvedOrderNew.status = 'Rejected';
        this.apiService.put('/outlineApproval/' + this.orderObj.id, {
          projectOrder: approvedOrderNew
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Order',
              version: resp.version
            },
            projectItem: { projectOrder: approvedOrderNew, projectStatus: 'Rejected', orderRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.orderObj.project.id, 'Project Order', 'rejected', this.orderObj.assignedTo.id);
            this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
              status: 'Rejected',
            }).subscribe(orderRes => {
              this.apiService.put('/reports/' + response.projectReport.id, {
                status: "Closed"
              }).subscribe(reportResponse => {
                this.apiService.post('/sendEmail', {
                  email: appRes.projectOrder.projectManager.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Rejected')[0].text + `<br>` +
                    'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
                }).subscribe(msg => { });
                this.notification('Success', "Order has been rejected.");
		 this.spinner.hide();
              });
            });
          });
        });
      });
    });
  }

  reportOverwriter(approvedProj, project) {
    let budgetTable = project.projectOrder[0].costTypeTable;
    delete (project.projectOrder[0].costTypeTable);
    delete (project.projectOrder[0].attachment1);
    delete (project.projectOrder[0].attachment2);
    delete (project.projectOrder[0].attachments);
    delete (project.projectOrder[0].isAttachement1Added);
    delete (project.projectOrder[0].isAttachement2Added);

    let obj: any = {
      projectName: project.projectName,
      ...project.projectOrder[0],
      status: 'Active',
    };

    delete (obj.question);
    obj.orderQuestion = project.projectOrder[0].question;

    obj.risks.forEach((risk, idx) => {
      obj.risks[idx].report = false;
    });

    obj.mileStonesValues.forEach((val, index) => {
      obj.mileStonesValues[index].status = 'Due';
    });

    if (project.subPortfolio.id != project.projectReport.subPortfolio) {
      let budget = [
        {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          group: "Sonstiges",
          id: 5,
        }, {
          costType: "Total",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 6,
          group: "Sonstiges",
        },
      ];

      this.apiService.delete('/projectBudgetCost/project/' + project.id).subscribe((deleteResponce: any) => {
        this.apiService.post('/ProjectBudgetCost', {
          portfolioBudgetYear: project.subPortfolio.currentYear,
          project: project.id,
          budget: budget
        }).subscribe((projectBudgetCostResponce: any) => { });
      });
    }

    this.apiService.get('/reports/' + project.projectReport.id).subscribe((response: any) => {
      let costTypeTable = response.costTypeTable;

      budgetTable.forEach((value, index) => {
        costTypeTable[index].forecast = value.budget;
        costTypeTable[index].currentBudget = value.budget;
        costTypeTable[index].originalBudget = value.budget;
      });
      obj.costTypeTable = costTypeTable;

      //obj.mileStonesValues = response.mileStonesValues != undefined ? response.mileStonesValues : [];

      obj.plannedEndDate = obj.endDate;
      obj.forecastEndDate = obj.endDate;

      let projectPhaseOptions = this.utilityService.projectPhaseOptions;
      let projectPhase;
      
      if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
        projectPhase = projectPhaseOptions.values.find(val => val.name == 'Executing').id;
      }
      obj.projectPhase = projectPhase;

      this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
        this.apiService.post('/history', {
          date: Date.now(),
          project: project.id,
          document: 'Project Order',
          status: 'Approved'
        }).subscribe(historyResponse => {
          this.disableBtn = true;

          this.addUserToTeam(project.id, response.projectFico);

          this.apiService.put('/projectOrder/' + project.projectOrder[0].id, {
            status: 'Approved',
          }).subscribe(orderRes => {
            this.apiService.post('/sendEmail', {
              email: approvedProj.projectOrder.projectManager.email,
              subject: "oneView: Projekt Genehmigungsworkflow",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Approval Confirmation')[0].text + `<br>` +
                'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
            }).subscribe(msg => { });
            this.notification('Success', "Project Order Approved.");
            this.spinner.hide();
          });
        });
      });
    });
  }

  getteamUsersIds(teamUsers): any {
    let teamUsersIds = [];
    teamUsers.forEach(team => {
      teamUsersIds.push(team.user.id);
    });
    return teamUsersIds;
  }

  async addUserToTeam(projectId, user) {
    let teamId, teamUsers = [];
    await this.apiService.get('/project/team/' + projectId).subscribe((projectTeam: any) => {
      if (projectTeam.teamId != null && projectTeam.teamId != undefined) {
        teamId = projectTeam.teamId;
        teamUsers = projectTeam.teamUsers;
      }

      if (user.id != '' && user.id != undefined && user.id != null &&
        user.email != '' && user.email != undefined && user.name != null) {
        if (teamUsers.find(val => val.id == user.id) != undefined) {
          // this.spinner.hide();
        } else {
          if (teamId != undefined && teamId != null) {
            let teamUsersIds = this.getteamUsersIds(teamUsers);
            teamUsersIds.push(user.id);
            this.apiService.put('/team/' + teamId, {
              users: teamUsersIds,
            }).subscribe((responseTeam: any) => {
              this.apiService.post('/rights', {
                user: user.id,
                project: projectId,
                isView: true,
                isEdit: true
              }).subscribe((responseRights: any) => {
                // this.spinner.hide();
              });
            });
          } else {
            this.apiService.post('/team', {
              users: [user.id],
              project: projectId
            }).subscribe((responseTeam: any) => {
              teamId = responseTeam.id;
              this.apiService.post('/rights', {
                user: user.id,
                project: projectId,
                isView: true,
                isEdit: true
              }).subscribe((responseRights: any) => {
                // this.spinner.hide();
              });
            });
          }
        }
      } else {
        // this.spinner.hide();
      }
    });

  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }

  showComments(): void {
    this.commentsArr = [];
    this.commentsArr = this.orderObj.project.comments;
    this.commentsProjectId = this.orderObj.project.id;
  }

  saveComments(e) {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';
      setTimeout(() => {
        this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      }, 100);

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe(response => {
        // this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      });
    }
  }

  scrollToBottom(e) {
    setTimeout(() => {
      e.component.scrollBy($('.dx-scrollview-content').height());
    }, 100);
  }
}
