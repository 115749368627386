import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-small-orders-lessons-learned',
  templateUrl: './small-orders-lessons-learned.component.html',
  styleUrls: ['./small-orders-lessons-learned.component.scss']
})
export class SmallOrdersLessonsLearnedComponent implements OnInit {
  @Input() isProjectUneditable;
  smallOrder: any = { lessonsLearned: [] };
  isEdit = false;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  lessonsForm: any = {
    lessonCategory: {
      id: ''
    },
    description: '',
  };
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  backupForm;
  lessonCategorySelectOptions;
  lessonTypeSelectOptions;
  smallOrderId;
  isMember;
  isVisible: boolean = false;
  isTeamProject: boolean = false;

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    this.lessonTypeSelectOptions = ["Positive", "Negative"];
  }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.smallOrderId = params.id;
      this.isTeamProject = this.utilityService.isTeamProject;
      this.lessonCategorySelectOptions = this.utilityService.lessonsCategoryOptions;

      this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe(response => {
        this.smallOrder = response;
        this.utilityService.breadcrumbList = ['My Orders', this.smallOrder.name, 'Lessons'];
        if (this.smallOrder.lessonsLearned == undefined) {
          this.smallOrder.lessonsLearned = [];
        }
        this.isVisible = true;
        this.spinner.hide();
      });
    });
  }

  save() {
    if (this.smallOrderId != undefined) {
      if (this.smallOrder.lessonsLearned.length == 0) {
        this.lessonsForm.id = 1;
      } else {
        this.lessonsForm.id = this.smallOrder.lessonsLearned[this.smallOrder.lessonsLearned.length - 1].id + 1;
      }

      this.smallOrder.lessonsLearned.push(this.lessonsForm);
      this.resetForm();
      this.spinner.show();
      this.apiService.put('/smallOrder/' + this.smallOrderId, {
        lessonsLearned: this.smallOrder.lessonsLearned
      }).subscribe(response => {
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.smallOrder.lessonsLearned);
        this.modalRef.hide();
        this.spinner.hide();
      });
    }
  }

  edit() {
    this.apiService.put('/smallOrder/' + this.smallOrderId, {
      lessonsLearned: this.smallOrder.lessonsLearned
    }).subscribe(response => {
      this.isEdit = false;
      this.dataGrid.instance.option('dataSource', this.smallOrder.lessonsLearned);
      this.modalRef.hide();
      this.spinner.hide();
    });
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.lessonsForm = {
      lessonCategory: {
        id: ''
      },
      description: '',
    };
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.lessonsForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  openDeleteDialog(template, obj) {
    let idx;
    this.smallOrder.lessonsLearned.forEach((val, index) => {
      if (val.id == obj.id) {
        idx = index;
      }
    });
    this.smallOrder.lessonsLearned.splice(idx, 1);

    // Updating id's 
    for (let index = 0; index < this.smallOrder.lessonsLearned.length; index++) {
      this.smallOrder.lessonsLearned[index].id = index + 1;
    }

    this.apiService.put('/smallOrder/' + this.smallOrderId, {
      lessonsLearned: this.smallOrder.lessonsLearned,
    }).subscribe(response => {
      this.dataGrid.instance.option('dataSource', this.smallOrder.lessonsLearned);
    });
  }

  lessonCategoryValueChanged(e) {
    if (this.lessonCategorySelectOptions != undefined) {
      this.lessonCategorySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.lessonsForm.lessonCategory = response;
        }
      });
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
