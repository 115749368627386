import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SubPortfolioMilestonesRoutingModule } from './sub-portfolio-milestones-routing.module';
import { SubPortfolioMilestonesComponent } from './sub-portfolio-milestones.component';


@NgModule({
  declarations: [SubPortfolioMilestonesComponent],
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    SubPortfolioMilestonesRoutingModule
  ]
})
export class SubPortfolioMilestonesModule { }
