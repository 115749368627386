import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipelineViewComponent } from './pipeline-view.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipelineViewRoutingModule } from './pipeline-view-routing.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    PDFModule,
    ModalModule,
    CurrencyFormatterPipeModule,
    NgxSpinnerModule,
    ClipboardModule,
    PipelineViewRoutingModule
  ],
  declarations: [
    PipelineViewComponent
  ]
})
export class PipelineViewModule { }
