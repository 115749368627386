import { Component, OnInit, TemplateRef } from '@angular/core';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrls: ['./help-guide.component.scss']
})
export class HelpGuideComponent implements OnInit {
  projectForms: any = [];
  formObj: any = {};
  public modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public inputField = '';
  public inputFieldFr = '';
  public inputFieldDe = '';
  pages: any = [{ tab: 'Text', type: 'text' }, { tab: 'Image', type: 'image' }];
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  index: number = 0;
  projectFormTitle: string;
  externalLink: string;
  lessonsExternalLink: string;

  constructor(private modalService: BsModalService, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.selectFile();
    this.utilityService.breadcrumb = 'Help Guide';
    this.utilityService.breadcrumbList = ['Settings', 'Help Guide'];
    this.externalLink = this.authService.userObj.configuration.externalHelpLink;
    this.lessonsExternalLink = this.authService.userObj.configuration.lessonsExternalLink;
  }

  ngOnInit() {
    this.spinner.show();
    this.getHelpGuide();
  }

  getHelpGuide(): void {
    this.apiService.get('/helpGuide/').subscribe((response: any) => {
      this.apiService.get('/helpGuide/getHelpGuideByFormName/order').subscribe(orderGuideResponse => {
        let obj = response.find(val => val.name == 'Project Order');
        let idx = response.indexOf(obj);
        response[idx] = orderGuideResponse;
        this.projectForms = response;
        this.spinner.hide();
      });
      this.apiService.get('/helpGuide/getHelpGuideByFormName/outline').subscribe(outlineGuideResponse => {
        let obj = response.find(val => val.name == 'Project Outline');
        let idx = response.indexOf(obj);
        response[idx] = outlineGuideResponse;
        this.projectForms = response;
        this.spinner.hide();
      });
    });
  }

  openEditDialog(template, obj, projectFormTitle) {
    this.spinner.show();
    this.projectFormTitle = projectFormTitle;

    this.apiService.get('/helpGuideMapper/' + obj.id).subscribe((response: any) => {
      this.formObj = response.data;

      if (this.formObj.type == 'image') {
        this.index = 1;

        setTimeout(() => {
          let attach1: any = document.getElementById("attact1-span");
          if (this.formObj.originalName != undefined && this.formObj.originalName != "") {
            attach1.value = this.formObj.originalName;
          }
          else {
            attach1.value = "";
          }
        }, 100)
      }
      else {
        this.index = 0;
        this.inputField = this.formObj.value;
        this.inputFieldDe = response.de;
        this.inputFieldFr = response.fr;
      }

      this.spinner.hide();
      this.openModal(template);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  closeModel() {
    this.modalRef.hide();
    this.resetFields();
  }

  resetFields() {
    this.inputField = null;
    this.inputFieldDe = null;
    this.inputFieldFr = null;
    let attach1: any = document.getElementById("attact1-span");
    if (attach1 != null) {
      attach1.value = "";
    }
  }

  save(obj, type) {
    obj.type = type;
    obj.value = this.inputField;
    this.formObj = obj;

    if (type == 'image') {
      if (this.formObj.attachmentId != undefined && this.formObj.attachmentId != "") {
        this.apiService.delete("/attachment/" + this.formObj.attachmentId).subscribe((response: any) => {
          this.apiService.post("/deleteFile", {
            path: response.path
          }).subscribe(fileResponse => {
            this.uploadAttachments();
          });
        });
      } else {
        this.uploadAttachments();
      }
    } else {
      if (this.inputField == '' || this.inputField == undefined || this.inputFieldDe == ''
        || this.inputFieldDe == undefined || this.inputFieldFr == '' || this.inputFieldFr == undefined) {
        this.showNotification('Warning', 'Please fill all language fields.', 'rgb(240, 77, 93)');
      } else {
        if (this.formObj.attachmentId != undefined && this.formObj.attachmentId != "") {
          this.apiService.delete("/attachment/" + this.formObj.attachmentId).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.formObj.attachmentId = "";
              this.formObj.originalName = "";
              this.updateHelpGuide();
            });
          });
        }
        else {
          this.formObj.attachmentId = "";
          this.formObj.originalName = "";
          this.updateHelpGuide();
        }
      }
    }
  }

  updateHelpGuide() {
    this.spinner.show();
    this.apiService.put('/helpGuideMapper/' + this.formObj.id, {
      helpGuide: {
        value: this.formObj.value,
        type: this.formObj.type,
        attachmentId: this.formObj.attachmentId,
        originalName: this.formObj.originalName
      },
      de: this.inputFieldDe,
      fr: this.inputFieldFr
    }).subscribe((response: any) => {
      let objArr = this.projectForms.find(val => val.id == response.data[0].helpGuide);
      let index = this.projectForms.indexOf(objArr);
      let objData = this.projectForms[index].fields.find(val => val.id == response.data[0].id);
      let i = this.projectForms[index].fields.indexOf(objData);
      this.projectForms[index].fields[i] = response.data[0];
      this.notification('Success', 'Save Successfully.');
      this.closeModel();
      this.spinner.hide();
    });
  }

  setAttachmentName(e) {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);

      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe((attach: any) => {
        this.formObj.attachmentId = attach.id;
        this.formObj.value = this.apiService.BASE_PATH + '/' + attach.fileName;
        this.formObj.originalName = attach.originalName;

        this.updateHelpGuide();
      });
    };
  }

  uploadAttachments() {
    this.uploader.uploadAll();
  }

  notification(title, message, color = '#5b835b') {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'success',
    );
  }

  showNotification(title, message, color = 'rgb(115, 158, 115)') {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'warning',
    );
  }

  saveLink(e) {
    this.spinner.show();
    this.authService.userObj.configuration.externalHelpLink = this.externalLink;
    this.authService.userObj.configuration.lessonsExternalLink = this.lessonsExternalLink;
    this.apiService.put('/configurations/' + this.authService.userObj.configuration.id, {
      externalHelpLink: this.externalLink,
      lessonsExternalLink: this.lessonsExternalLink
    }).subscribe(response => {
      localStorage.setItem('userObj', JSON.stringify(this.authService.userObj));
      this.notification('Success', 'Save Successfully.');
      this.spinner.hide();
    })
  }
}
