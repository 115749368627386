import { AuthService } from './../../services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ApiService } from '../../services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UtilityService } from '../../services/utility.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
declare var $: any;

var globalThis;

@Component({
  selector: 'app-projects-list-with-portfolio',
  templateUrl: './projects-list-with-portfolio.component.html',
  styleUrls: ['./projects-list-with-portfolio.component.scss']
})
export class ProjectsListWithPortfolioComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  resizingModes: string[] = ['nextColumn', 'widget'];
  columnResizingMode: string;
  projectsList = [];
  portfolioProjectsCount: number = 0;
  portfolioProjectsSearch: any = '';
  portfolioList = [{ id: undefined, name: 'All' }];
  backupList;
  isResetPressed: boolean = true;
  stateStore;
  showPreview: boolean = false;
  isFilterApplied: boolean = false;
  multiProject = [];
  currencyFormat = { format: "Fr #'###.##" };
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  backupFiltered;
  portfolioFilter;
  programFilter;
  businessAreaFilter;
  businessUnitFilter;
  projectTypeFilter;
  projectCategoryFilter;
  reportingLevelFilter;
  statusFilter;
  subProjectsFilter;
  projectPhaseFilter;
  projectMethodologyFilter;
  subProjectsList;
  backUpList = [];
  partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
  businessUnitOptions;
  businessAreaOptions;
  projectPhaseOptions;
  projectMethodologyOptions;
  projectTypeOptions;
  reportingLevelOptions;
  statusOptions;
  classificationOptions;
  subProjectsOptions;
  subPortfolioList = [{ id: undefined, name: 'All' }];
  subPortfolioFilter;
  isSubPortfolio: boolean = true;
  // isClickFilter: boolean = false;
  @ViewChild('lgModal', { static: true }) public lgModal;
  @ViewChild('lgOrderModal', { static: true }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: true }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: true }) public lgClosingModal;
  outlinePreview;
  loadingVisible = false;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  showOutlinePreview: boolean = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  projectsSubject = new Subject<any>();
  isViewer = false;
  projectsPageIndex = 2;
  lastUpdated: any = "";
  projectsProgress = 0;
  itRelevantOptions;
  itRelevantFilter;
  itPlatformOptions;
  technologyOptions;
  itPlatformFilter = [];
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeViewObj;
  usersList = [];
  projectSponsorFilter;
  projectManagerFilter;
  projectNameFilter;
  projManagerEmail;
  projSponsorEmail;
  projectsSearchList = [];
  // reportsList: any = [];
  projectName;
  filtersArray;
  userIds = [];
  isSubportfolioFilter: boolean = false;
  projectsCount: any = 0;
  dropdownPageIndex = 1;
  BASE_PATH = '';
  attachmentQueryParams = '';
  dashboardObj:any

  constructor(private apiService: ApiService, private modalService: BsModalService, private utilityService: UtilityService,
    private authService: AuthService, private router: Router, private socketService: SocketService, private spinner: NgxSpinnerService, private localize: I18nPipe) {
      this.columnResizingMode = this.resizingModes[0];
      this.utilityService.dashboardDataObservable.subscribe((data: any) => {
        this.dashboardObj = data;
      })
      if (this.utilityService.dashboardObj.length == 0) {
        this.utilityService.fetchDashboard();
      } else {
        this.dashboardObj = this.utilityService.dashboardObj;
      }
  
      this.utilityService.dashboardDataObservable.subscribe((data: any) => {
        this.dashboardObj = data;
      })
  
      if (this.utilityService.dashboardObj.length == 0) {
        this.utilityService.fetchDashboard();
      } else {
        this.dashboardObj = this.utilityService.dashboardObj;
      }
      
    this.socketService.listen('allPortfolios').subscribe(response => {
      this.portfolioList = response;
    });


    if (this.authService.userObj.role == 'guest' && this.authService.userObj.isViewerAlso) {
      this.isViewer = true;
    }

    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      this.usersList = responseUsers;
      this.setFilterValues(this.filtersArray);
      this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });
    })

    
    

    this.socketService.listen('portfolioProjectsFilter').subscribe(projects => {
      // this.projectsList = [];
      this.projectsList = this.projectsList.concat(projects);
      this.populateValues();
      this.backupList = JSON.parse(JSON.stringify(this.projectsList));
      this.backupFiltered = JSON.parse(JSON.stringify(this.projectsList));
      this.dataGrid.instance.option('dataSource', this.projectsList);
      this.spinner.hide();

      if (this.dropdownPageIndex < (this.projectsCount / 50)) {
        this.dropdownPageIndex++;
        this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: this.filtersArray });
      }
    });

    this.socketService.listen('portfolioProjectsFilterCount').subscribe(count => {
      this.isResetPressed = false;
      //Limiting data to 400 records
      if (count < 400) {
        this.projectsCount = count;
      } else {
        this.projectsCount = 400;
      }

      this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: this.filtersArray });
    });

    this.socketService.listen("getSelectiveProjects").subscribe(response => {
      this.projectsSearchList = response;
      if (this.userIds.length > 0) {
        this.filtersArray = this.filtersArray;
        this.socketService.emitEvent('fetchMultipleUsers', { userIds: this.userIds });
      } else {
        this.setFilterValues(this.filtersArray);
        this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });
      }
    });

    // this.socketService.listen('allPortfolios').subscribe(response => {
    //   this.portfolioList = this.portfolioList.concat(response);
    // });

    // this.socketService.listen('allPrograms').subscribe(response => {
    //   this.partOfaProgramOptions = response;
    // });

    // this.fetchPortfoliosList();
    // this.fetchProgram();
    globalThis = this;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;

    this.stateStore = {
      enabled: true,
      storageKey: 'projectPortfolioList',
      type: 'localStorage',
      savingTimeout: 200
    };
    this.utilityService.breadcrumb = 'Projects';
  }

  ngOnInit() {
    this.utilityService.breadcrumbList = ['Dashboard', 'Projects'];
    this.spinner.show();
    this.statusOptions = [{ id: 0, name: 'Active' }, { id: 1, name: 'On Hold' }, { id: 2, name: 'Closed' }, { id: 3, name: 'Not Realized' }];
    this.subProjectsOptions = ['Include', 'Exclude'];
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;
    this.projectPhaseOptions = this.utilityService.projectPhaseOptions;
    this.itRelevantOptions = JSON.parse(JSON.stringify(this.utilityService.itRelevantOptions));
    this.itRelevantOptions.values.unshift({name: '-', id: undefined}); //Adding All option
    this.itPlatformOptions = this.utilityService.itPlatformOptions;
    this.technologyOptions = this.utilityService.technologyOptions ? this.utilityService.technologyOptions.values : [];

    this.apiService.get('/portfoliosPrograms').subscribe((response: any) => {
      this.portfolioList = this.portfolioList.concat(response.portfolios);
      this.partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
      this.partOfaProgramOptions = this.partOfaProgramOptions.concat(response.programs);

      let filtersArray = JSON.parse(localStorage.getItem("portfolioProjectsFilter"));

      if (filtersArray && filtersArray.length > 0) {
        let userIds = [];
        let projectIds = [];

        filtersArray.forEach(filter => {
          if (Object.keys(filter)[0] == 'projectSponsor.id') {
            userIds.push(filter['projectSponsor.id']);
          }
          if (Object.keys(filter)[0] == 'projectManager.id') {
            userIds.push(filter['projectManager.id']);
          }
          if (Object.keys(filter)[0] == 'project') {
            projectIds.push(filter['project']);
          }
        });

        this.filtersArray = filtersArray;
        this.userIds = userIds;

        if (projectIds.length > 0) {
          this.socketService.emitEvent("getSelectiveProjects", { ids: projectIds });
        } else {
          if (userIds.length > 0) {

            this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
          } else {
            this.setFilterValues(filtersArray);
            this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray });
          }
        }
      } else {
        this.statusFilter = ["Active"];
        let filter = this.setFilters();
        this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: filter });

        this.isResetPressed = false;
        this.spinner.hide();
      }
    })

    // this.socketService.emitEvent('portfolioProjectsCount', { userId: this.authService.userObj.id });
    // this.socketService.listen('portfolioProjectsCount').subscribe(count => {
    //   this.portfolioProjectsCount = count;
    //   this.socketService.emitEvent('portfolioProjectsIndex', { userId: this.authService.userObj.id, pageIndex: this.projectsPageIndex, pageSize: 20 });
    // });

    // this.projectsList = this.utilityService.portfolioProjects;
    // this.backUpList = JSON.parse(JSON.stringify(this.projectsList));
    // this.backupFiltered = JSON.parse(JSON.stringify(this.projectsList));
    // this.lastUpdated = this.utilityService.portfolioProjectsLastUpdated;
    // this.projectsProgress = this.utilityService.projectsLoadProgress;

    // this.utilityService.portfolioProjectsObservable.subscribe((data: any) => {
    //   this.projectsList = data.projectsList;
    //   this.backUpList = JSON.parse(JSON.stringify(this.projectsList));
    //   this.backupFiltered = JSON.parse(JSON.stringify(this.projectsList));
    //   this.lastUpdated = data.lastUpdated;
    //   this.projectsProgress = data.projectsLoadProgress
    // });
    // this.utilityService.emitPortfolioProjects();

    // this.socketService.listen(' ').subscribe(projects => {
    //   this.projectsList = projects;
    // });

    // this.socketService.listen('portfolioProjectsSearch').subscribe(projectsResult => {
    //   this.portfolioProjectsCount = projectsResult.count;
    //   this.projectsList = projectsResult.projects;
    // });

    // this.socketService.listen('portfolioProjectsSearchIndex').subscribe(projects => {
    //   this.projectsList = projects;
    // });

    // this.socketService.listen('portfolioProjectsFilter').subscribe(projects => {
    //   this.portfolioProjectsCount = projects.count;
    //   this.projectsList = projects.projects;
    // });

    // this.socketService.listen('portfolioProjectsFilterIndex').subscribe(projects => {
    //   this.projectsList = projects;
    // });

    // this.projectsSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
    //   if (value != undefined) {
    //     this.socketService.emitEvent('portfolioProjectsSearch', { userId: this.authService.userObj.id, search: value });
    //   }
    //   return [];
    // })).subscribe((response: any) => { }, error => {
    // });

  }

  // portfolioProjectsIndex(event) {
  //   if (this.portfolioProjectsSearch != '') {
  //     this.socketService.emitEvent('portfolioProjectsSearchIndex', { search: this.portfolioProjectsSearch, userId: this.authService.userObj.id, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  //   } else if (this.isFilterApplied) {
  //     let filtersArray = this.setFilters();
  //     this.socketService.emitEvent('portfolioProjectsFilterIndex', { userId: this.authService.userObj.id, filtersArray: filtersArray, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  //   } else {
  //     this.socketService.emitEvent('portfolioProjectsIndex', { userId: this.authService.userObj.id, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });

  //   }
  // }

  // onSearchChange(event) {
  //   if (this.isFilterApplied) {
  //     this.resetFilter();
  //   }
  //   this.projectsSubject.next(event.target.value)
  // }

  // refreshProjects() {
  //   this.projectsProgress = 0;
  //   this.utilityService.refreshProjects();
  //   this.utilityService.refreshDashboard();
  // }


  selectResizing(data) {
    this.columnResizingMode = data.value;
  }

  populateValues() {
    this.projectsList.forEach((report, idx) => {
      // populate itPlatfomrs

      if (this.itPlatformOptions && this.itPlatformOptions.values && report.itPlatform && report.itPlatform.length > 0) {
        let itPlatforms = [];

        report.itPlatform.forEach((itPlatform, idx) => {
          let itPlatformObj = this.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (itPlatformObj) {
            if (idx == report.itPlatform.length - 1) {
              itPlatforms.push({ id: itPlatformObj.id, name: itPlatformObj.name });
            } else {
              itPlatforms.push({ id: itPlatformObj.id, name: itPlatformObj.name, coma: ',' });
            }
          }
        });
        report.itPlatform = itPlatforms;
      }

      // populate technology

      if (report.technology && report.technology.length > 0) {
        let technologys = [];

        report.technology.forEach((technology, idx) => {
          let technologyObj = this.technologyOptions.find(val => val.id == technology);

          if (technologyObj) {
            if (idx == report.technology.length - 1) {
              technologys.push({ id: technologyObj.id, name: technologyObj.name });
            } else {
              technologys.push({ id: technologyObj.id, name: technologyObj.name, coma: ',' });
            }
          }
        });
        report.technology = technologys;
      }
    });
  }

  getProjectDetails(report) {
    if (report.project.projectOutline == undefined) {
      this.loadingVisible = true;
      this.apiService.get('/projects/' + report.project.id).subscribe((projectResponse: any) => {
        this.loadingVisible = false;
        let reportObj = this.projectsList.find(val => val.id == report.id);
        reportObj.project = projectResponse;
      });
    }
  }

  // fetchPortfoliosList() {
  //   this.socketService.emitEvent('allPortfolios', {});
  // }

  filterProjects(data) {
    // this.portfolioProjectsSearch = '';
    if (data.value.name != "All") {
      // let filter = [{ portfolio: data.value.id }];
      // this.socketService.emitEvent('portfolioProjectsFilter', { userId: this.authService.userObj.id, filtersArray: filter });
      // this.isFilterApplied = true;
      this.projectsList = JSON.parse(JSON.stringify(this.backUpList.filter(val => val.portfolio == undefined ? val.portfolio : val.portfolio.id == data.value.id)));
    } else {
      this.projectsList = JSON.parse(JSON.stringify(this.backUpList));
      // this.dataGrid.instance.clearFilter();
      // this.resetFilter();
    }
  }

  onExporting(e) {
    // e.component.beginUpdate();
  }

  onExported(e) {
    // this.projectsList = JSON.parse(JSON.stringify(this.backUpList));
    // this.dataGrid.instance.option('dataSource', this.projectsList);
    // e.component.endUpdate();
  }

  customizeExportData(cols, rows) {
    let overall, scope, cost, time, risk, forecastEndDate, plannedEndDate, startDate, endDate, reportingDate;

    cols.forEach((col, index) => {
      if (col.dataField == "overallStatus") {
        overall = index;
      } else if (col.dataField == "costStatus") {
        cost = index;
      } else if (col.dataField == "scopeStatus") {
        scope = index;
      } else if (col.dataField == "timeStatus") {
        time = index;
      } else if (col.dataField == "riskStatus") {
        risk = index;
      } else if (col.dataField == "forecastEndDate") {
        forecastEndDate = index;
      } else if (col.dataField == "startDate") {
        startDate = index;
      } else if (col.dataField == "endDate") {
        endDate = index;
      } else if (col.dataField == "plannedEndDate") {
        plannedEndDate = index;
      } else if (col.dataField == "statusReports[statusReports.length-1].reportingDate") {
        reportingDate = index;
      }
    });

    rows.forEach((row, index) => {
      let statusObj = row.key.statusReports[row.key.statusReports.length - 1];
      if (statusObj) {
        if (statusObj.overallStatus != undefined && overall != undefined)
          row.values[overall] = statusObj.overallStatus;
        if (statusObj.scopeStatus != undefined && scope != undefined)
          row.values[scope] = statusObj.scopeStatus;
        if (statusObj.costStatus != undefined && cost != undefined)
          row.values[cost] = statusObj.costStatus;
        if (statusObj.timeStatus != undefined && time != undefined)
          row.values[time] = statusObj.timeStatus;
        if (statusObj.riskStatus != undefined && risk != undefined)
          row.values[risk] = statusObj.riskStatus;
      }
      let pipe = new DatePipe('en');
      row.values[forecastEndDate] = pipe.transform(row.values[forecastEndDate], 'dd. MMM yyyy');
      row.values[startDate] = pipe.transform(row.values[startDate], 'dd. MMM yyyy');
      row.values[endDate] = pipe.transform(row.values[endDate], 'dd. MMM yyyy');
      row.values[plannedEndDate] = pipe.transform(row.values[plannedEndDate], 'dd. MMM yyyy');
      if (statusObj != undefined) {
        row.values[reportingDate] = pipe.transform(statusObj.reportingDate, 'dd. MMM yyyy');
      }
    });
  }

  openMultiProjectReport(template: TemplateRef<any>) {
    this.showPreview = false;
    this.multiProject = [];

    if (this.isFilterApplied == true) {
      (this.dataGrid.instance.getVisibleRows()).forEach(element => {
        this.multiProject.push(element.data);
      });
    } else {
      this.multiProject = this.projectsList;
    }

    setTimeout(() => {
      this.showPreview = true;
      $('#multiPDF').parent().parent().css('margin', "auto 10px");
      $('#multiPDF').parent().css('width', "1500px");
    }, 500);
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  projectSponsorValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.projectSponsorFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  projectSponsorOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectSponsorFilter != undefined) {
      this.projectsList = [];
      this.projectSponsorFilter = undefined;
      this.projSponsorEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectSponsorFilter = response;
      //       } else {
      //         this.projectSponsorFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectSponsorFilter = { id: '' };
      // }
    });
  }

  projectManagerValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.projectManagerFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  projectManagerOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectManagerFilter != undefined) {
      this.projectsList = [];
      this.projectManagerFilter = undefined;
      this.projManagerEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectManagerFilter = response;
      //       } else {
      //         this.projectManagerFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectManagerFilter = { id: '' };
      // }
    });
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  filter() {
    // this.isClickFilter = true;
    let filtersArray = this.setFilters();

    if (filtersArray.length > 0) {
      this.dropdownPageIndex = 1;
      this.projectsList = [];
      this.filtersArray = filtersArray;

      localStorage.setItem('portfolioProjectsFilter', JSON.stringify(filtersArray));

      this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray });
      this.spinner.show();
    } else {
      this.projectsList = [];
    }
    //this.portfolioChange();
    //this.subPortfolioChange();
    //this.programChange();
    //this.businessUnitChange();
    //this.businessAreaChange();
    //this.projectTypeChange();
    //this.projectCategoryChange();
    //this.reportingLevelChange();
    //this.statusChange();
    //this.phaseChange();
    //this.methodologyChange();
    // this.backupFiltered = JSON.parse(JSON.stringify(this.projectsList));


    // this.modalRef.hide();
  }

  setFilters() {
    let filtersArray = new Array;

    if (this.projectPhaseFilter) {
      filtersArray.push({ projectPhase: this.projectPhaseFilter.id })
    }
    if (this.projectMethodologyFilter) {
      filtersArray.push({ projectMethodology: this.projectMethodologyFilter.id })
    }
    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      filtersArray.push({ status: this.statusFilter })
    }
    if (this.reportingLevelFilter) {
      filtersArray.push({ reportingLevel: this.reportingLevelFilter.id })
    }
    if (this.projectCategoryFilter) {
      filtersArray.push({ classification: this.projectCategoryFilter.id })
    }
    if (this.projectTypeFilter) {
      filtersArray.push({ projectType: this.projectTypeFilter.id })
    }
    if (this.businessAreaFilter) {
      filtersArray.push({ businessArea: this.businessAreaFilter.id })
    }
    if (this.businessUnitFilter) {
      filtersArray.push({ businessUnit: this.businessUnitFilter.id })
    }
    if (this.programFilter) {
      filtersArray.push({ program: this.programFilter.id })
    }
    if (this.itRelevantFilter) {
      filtersArray.push({ itRelevant: this.itRelevantFilter })
    }
    if (this.portfolioFilter) {
      filtersArray.push({ portfolio: this.portfolioFilter.id })
    }
    if (this.subPortfolioFilter) {
      filtersArray.push({ subPortfolio: this.subPortfolioFilter.id })
    }
    if (this.itPlatformFilter && this.itPlatformFilter.length > 0) {
      filtersArray.push({ itPlatform: this.itPlatformFilter })
    }
    if (this.projectSponsorFilter) {
      filtersArray.push({ 'projectSponsor.id': this.projectSponsorFilter.id })
    }
    if (this.projectManagerFilter) {
      filtersArray.push({ 'projectManager.id': this.projectManagerFilter.id })
    }
    if (this.projectNameFilter) {
      filtersArray.push({ 'project': this.projectNameFilter.id })
    }

    this.isFilterApplied = true;
    return filtersArray;
  }

  setFilterValues(filterArray) {
    filterArray.forEach(filter => {
      switch (Object.keys(filter)[0]) {

        case 'portfolio':
          this.portfolioFilter = this.portfolioList.find(val => val.id == filter.portfolio)
          break;

        case 'subPortfolio':
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
          this.subPortfolioFilter = this.subPortfolioList.find(val => val.id == filter.subPortfolio)
          this.isSubportfolioFilter = true;
          this.isSubPortfolio = false;
          break;

        case 'program':
          this.programFilter = this.partOfaProgramOptions.find(val => val.id == filter.program)
          break;

        case 'status':
          this.statusFilter = filter.status;
          break;

        case 'itPlatform':
          this.itPlatformFilter = filter.itPlatform;
          break;

        case 'itRelevant':
          this.itRelevantFilter = filter.itRelevant;
          break;

        case 'projectManager.id':
          this.projectManagerFilter = this.usersList.find(val => val.id == filter['projectManager.id'])
          if (this.projectManagerFilter) {
            this.projManagerEmail = this.projectManagerFilter.email;
          }
          break;

        case 'projectSponsor.id':
          this.projectSponsorFilter = this.usersList.find(val => val.id == filter['projectSponsor.id'])
          if (this.projectSponsorFilter) {
            this.projSponsorEmail = this.projectSponsorFilter.email;
          }
          break;

        case 'project':
          this.projectNameFilter = this.projectsSearchList.find(val => val.id == filter['project'])
          if (this.projectNameFilter) {
            this.projectName = this.projectNameFilter.projectName;
          }
          break;


        default:
        // code block
      }
    });
  }

  projectsOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectNameFilter != undefined) {
      this.projectsList = [];
      this.projectNameFilter = undefined;
      this.projectName = '';
      this.filter();
    }
    this.apiService.get('/projects/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsSearchList = response;
    });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.projectNameFilter = e.itemData;
      this.filter();
    }
  }

  resetFilter() {
    this.dropdownPageIndex = 1;
    this.isResetPressed = true;
    this.filtersArray = [{ status: ['Active'] }];
    this.projectsList = [];
    this.portfolioFilter = '';
    this.subPortfolioFilter = '';
    this.programFilter = '';
    this.businessAreaFilter = '';
    this.businessUnitFilter = '';
    this.projectTypeFilter = '';
    this.projectCategoryFilter = '';
    this.reportingLevelFilter = '';
    this.statusFilter = ["Active"];
    this.projectPhaseFilter = '';
    this.projectMethodologyFilter = '';
    this.projectManagerFilter = '';
    this.projectSponsorFilter = '';
    this.itRelevantFilter = '';
    this.itPlatformFilter = [];
    this.projectNameFilter = '';
    this.projectName = '';
    this.projManagerEmail = '';
    this.projSponsorEmail = '';
    this.isSubPortfolio = true;
    this.isFilterApplied = false;
    localStorage.setItem("portfolioProjectsFilter", JSON.stringify(this.filtersArray));

    this.spinner.show();
    this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });

    let interval = setInterval(() => {
      if (this.isResetPressed == true) {
        clearInterval(interval);
        this.isResetPressed = false;
      }
    }, 500);

  }

  subProjectsChange() {
    if (this.subProjectsFilter == 'Include') {
      let tempArr = [];
      this.projectsList.forEach((project, index) => {
        project.subProjects.forEach(subProjectObj => {
          tempArr.push(subProjectObj);
        });
      });
      tempArr.forEach(element => {
        let subProjectObj = this.subProjectsList.filter(obj => obj.id == element.id);
        this.projectsList.push(subProjectObj[0]);
        subProjectObj = [];
      });

      this.isFilterApplied = true;
    }
  }

  portfolioChange() {
    if (this.portfolioFilter) {
      this.projectsList = this.projectsList.filter(reportObj => {
        if (reportObj.portfolio != undefined) {
          return reportObj.portfolio.id == this.portfolioFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  programChange() {
    if (this.programFilter) {
      this.projectsList = this.projectsList.filter(reportObj => {
        if (reportObj.program != undefined) {
          return reportObj.program.id == this.programFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  businessUnitChange() {
    if (this.businessUnitFilter) {
      let projectsBusinessUnit = this.projectsList.filter(reportObj => reportObj.businessUnit != undefined);
      if (projectsBusinessUnit != undefined && projectsBusinessUnit != null) {
        this.projectsList = projectsBusinessUnit.filter(reportObj => reportObj.businessUnit.name == this.businessUnitFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  businessAreaChange() {
    if (this.businessAreaFilter) {
      let projectsBusinessArea = this.projectsList.filter(reportObj => reportObj.businessArea != undefined);
      if (projectsBusinessArea != undefined && projectsBusinessArea != null) {
        this.projectsList = projectsBusinessArea.filter(reportObj => reportObj.businessArea.name == this.businessAreaFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  projectTypeChange() {
    if (this.projectTypeFilter) {
      let projectsProjectType = this.projectsList.filter(reportObj => reportObj.projectType != undefined);
      if (projectsProjectType != undefined && projectsProjectType != null) {
        this.projectsList = projectsProjectType.filter(reportObj => reportObj.projectType.name == this.projectTypeFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  projectCategoryChange() {
    if (this.projectCategoryFilter) {
      let projectsclassification = this.projectsList.filter(reportObj => reportObj.classification != undefined);
      if (projectsclassification != undefined && projectsclassification != null) {
        this.projectsList = projectsclassification.filter(reportObj => reportObj.classification.name == this.projectCategoryFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  reportingLevelChange() {
    if (this.reportingLevelFilter) {
      let projectsReportingLevel = this.projectsList.filter(reportObj => reportObj.reportingLevel != undefined);
      if (projectsReportingLevel != undefined && projectsReportingLevel != null) {
        this.projectsList = projectsReportingLevel.filter(reportObj => reportObj.reportingLevel.name == this.reportingLevelFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  statusChange() {
    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      this.projectsList = this.projectsList.filter(reportObj => reportObj.status == this.statusFilter);
      this.isFilterApplied = true;
    }
  }

  methodologyChange() {
    if (this.projectMethodologyFilter) {
      let projecstMethodology = this.projectsList.filter(reportObj => reportObj.projectMethodology != undefined);
      if (projecstMethodology != undefined && projecstMethodology != null) {
        this.projectsList = projecstMethodology.filter(reportObj => reportObj.projectMethodology.name == this.projectMethodologyFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  phaseChange() {
    if (this.projectPhaseFilter) {
      let projectsPhase = this.projectsList.filter(reportObj => reportObj.projectPhase != undefined);
      if (projectsPhase != undefined && projectsPhase != null) {
        this.projectsList = projectsPhase.filter(reportObj => reportObj.projectPhase.name == this.projectPhaseFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  itRelevantChange() {
    if (this.itRelevantFilter) {
      let itRelevant = this.projectsList.filter(reportObj => reportObj.itRelevant != undefined);
      if (itRelevant != undefined && itRelevant != null) {
        this.projectsList = itRelevant.filter(reportObj => reportObj.itRelevant.name == this.itRelevantFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  itPlatformChange() {
    if (this.itPlatformFilter && this.itPlatformFilter.length > 0) {
      let itPlatform = this.projectsList.filter(reportObj => reportObj.itPlatform != undefined);
      if (itPlatform != undefined && itPlatform != null) {
        this.projectsList = itPlatform.filter(reportObj => JSON.stringify(reportObj.itPlatform) == JSON.stringify(this.itPlatformFilter));
        this.isFilterApplied = true;
      }
    }
  }

  // fetchProgram() {
  //   this.socketService.emitEvent('allPrograms', {});
  // }

  openProjectReport(project) {
    let userObj = this.authService.userObj;

    if (project.confidential == "Yes") {
      let userObj = this.authService.userObj;
      if (userObj.email == project.pmoOfficer.email) {
        this.navigateToProject(project, false, true);
      } else if (userObj.email == project.projectSponsor.email) {
        this.navigateToProject(project, false, true);
      } else if (userObj.email == project.projectManager.email) {
        this.navigateToProject(project, false, true);
      } else {
        if (project.projectOrder.length > 0) {
          if (userObj.email == project.projectOrder.projectFico.email) {
            this.navigateToProject(project, false, true);
          } else {
            this.openConfidentialProjectAlert();
          }
        } else {
          this.openConfidentialProjectAlert();
        }
      }
    } else {
      if (userObj.isViewerAlso != true) {
        this.navigateToProject(project, false, true);
      } else {
        this.navigateToProject(project, false, false);
      }
    }
  }

  openConfidentialProjectAlert() {
    this.utilityService.showNotification(
      "Restricted Access",
      "This project is marked confidential.",
      "rgb(119,2,29)",
    );
  }

  navigateToProject(project, isTeamProject, isTeamEdit) {
    this.utilityService.isTeamProject = isTeamProject;
    this.utilityService.isTeamEdit = isTeamEdit;
    this.router.navigate(['projects/detail/' + project.id + '/overview']);
  }

  calculateCpiValue(e) {
    if (e.statusReports.length > 0)
      if (e.statusReports[e.statusReports.length - 1].EVA != undefined) {
        for (let i = e.statusReports[e.statusReports.length - 1].EVA.length - 1; i >= 0; i--) {
          if (e.statusReports[e.statusReports.length - 1].EVA[i].CPI == undefined) {
            continue;
          } else {
            return e.statusReports[e.statusReports.length - 1].EVA[i].CPI;
          }
        }
      }
  }

  calculateSPIValue(e) {
    if (e.statusReports.length > 0)
      if (e.statusReports[e.statusReports.length - 1].EVA != undefined) {
        for (let i = e.statusReports[e.statusReports.length - 1].EVA.length - 1; i >= 0; i--) {
          if (e.statusReports[e.statusReports.length - 1].EVA[i].SPI == undefined) {
            continue;
          } else {
            return e.statusReports[e.statusReports.length - 1].EVA[i].SPI;
          }
        }
      }
  }

  calculateEACValue(e) {
    if (e.statusReports.length > 0)
      if (e.statusReports[e.statusReports.length - 1].EVA != undefined) {
        for (let i = e.statusReports[e.statusReports.length - 1].EVA.length - 1; i >= 0; i--) {
          if (e.statusReports[e.statusReports.length - 1].EVA[i].EAC == undefined) {
            continue;
          } else {
            return e.statusReports[e.statusReports.length - 1].EVA[i].EAC;
          }
        }
      }
  }

  calculateTotalExposureValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].totalExposure;
    }
  }

  calculateRiskExposureVsCurrentBudgetValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].riskExposureVsCurrentBudget;
    }
  }

  calculatePlannedEndDateVsForecastDateValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].plannedDateVSForecastDate;
    }
  }

  calculateCurrentBudgetVsForecastValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].currentBudgetVSForecast;
    }
  }

  calculateManagementSummaryValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].managementSummary;
    }
  }

  calculatePercentageCompleteValue(e) {
    if (e.statusReports.length > 0) {
      return e.statusReports[e.statusReports.length - 1].percentageComplete;
    }
  }

  filterSubPortfolio() {
    if (this.portfolioFilter) {
      if (!this.isSubportfolioFilter) {
        this.subPortfolioFilter = '';

        if (this.portfolioFilter != "" && this.portfolioFilter.name != 'All') {
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
          this.subPortfolioFilter = this.subPortfolioList[0];
          if (this.subPortfolioList != undefined) {
            if (this.subPortfolioList.length > 0) {
              this.isSubPortfolio = false;
            }
          }
        } else {
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.isSubPortfolio = true;
        }
      } else {
        this.isSubportfolioFilter = false;
      }
    }
  }


  subPortfolioValueChanged() {
    if (this.subPortfolioFilter || this.portfolioFilter) {
      if (!this.isResetPressed) {
        this.filter();
      }
    }
  }

  programValueChanged() {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  subPortfolioChange() {
    if (this.subPortfolioFilter) {
      this.projectsList = this.projectsList.filter(reportObj => {
        if (reportObj.subPortfolio != undefined) {
          return reportObj.subPortfolio.id == this.subPortfolioFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  openOutlinePreview(outline) {
    this.loadingVisible = true;
    this.showOutlinePreview = false;
    this.apiService.get('/projectOutline/' + outline.id).subscribe(response => {
      this.outlinePreview = response;

      this.loadingVisible = false;

      setTimeout(() => {
        this.showOutlinePreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showOutlinePreview = false;
      });
    });
  }

  openOrderPreview(project, order) {
    this.showOrderPreview = false;
    order.projectId = project;
    order = this.utilityService.setOrderPdfValues(order);
    order.projectOrderId = order.id;
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openChangeRequestPreview(project, changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = project;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;
    this.changeRequestPreview.changeRequestId = changeRequest.id;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(project, closingReport) {
    this.showClosingPreview = false;
    closingReport.project = project;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;
    this.closingReportPreview.closingReportId = closingReport.id;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  onITPlatformSelectionChanged(e) {
    if (!this.isResetPressed) {

      this.filter();
    }
  }

  onStatusSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }


  ngOnDestroy() {
    this.socketService.removeListener('portfolioProjectsFilter');
    this.socketService.removeListener('fetchMultipleUsers');
    this.socketService.removeListener('getSelectiveProjects');
    this.socketService.removeListener("portfolioProjectsFilterCount");
    // this.socketService.removeListener("allPortfolios");
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }


}
