import { Injectable, OnInit } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Injectable()
export class UtilityService implements OnInit {
  selectedTab = "";
  reportsTab = "Overview";
  public breadcrumb: string;
  pmProgramsLength = 0;
  isProjectManagersProgramEmpty = true;
  companyNameOptions: any;
  mandatoryProjectOptions: any;
  businessUnitOptions: any;
  businessAreaOptions: any;
  businessSegmentOptions: any;
  reportingLevelOptions: any;
  classificationOptions: any;
  projectTypeOptions: any;
  strategicContributionOptions: any;
  feasibilityOptions: any;
  profitabilityOptions: any;
  projectMethodologyOptions: any;
  projectPhaseOptions: any;
  risksTopFiveRiskStrategySelectOptions: any;
  risksTopFiveReputationRiskSelectOptions: any;
  decisionTypeOptions: any;
  impactOptions: any;
  digitalizationTopicOptions;
  technologyOptions;
  itPlatformOptions;
  itRelevantOptions;
  digitalizationFocusOptions;
  typeOptions: any; //Documents
  numberOfProjectsPerBusinessAreaWithOverallStatus: any = [];
  projectsPerProjectTypeChart: any = [];
  numberOfProjectsPerBusinessUnitWithOverallStatus: any = [];
  projectsWithStrategicContribution: any = [];
  lessonsCategoryOptions: any;
  emailTemplates = [];
  usersList = [];
  PMOsList = [];
  langChanged: boolean = false;
  projectList = [];
  currentLanguage;
  public milestonesList: Array<any> = new Array();
  public risksList: Array<any> = new Array();
  public decisionsList: Array<any> = new Array();
  public measuresList: Array<any> = new Array();
  public evaList: Array<any> = new Array();
  public multiProjectReport: Array<any> = new Array();
  public projectBudgetCurrentYearList: Array<any> = new Array();
  public projectBudgetNextYearList: Array<any> = new Array();
  public projectActualBudgetList: Array<any> = new Array();
  public portfolioBudgetCurrentYearList: Array<any> = new Array();
  public portfolioBudgetNextYearList: Array<any> = new Array();
  public subPortfolioBudgetCurrentYearList: Array<any> = new Array();
  public subPortfolioBudgetNextYearList: Array<any> = new Array();
  public programBudgetCurrentYearList: Array<any> = new Array();
  public programBudgetNextYearList: Array<any> = new Array();
  public projectStatusReports: Array<any> = new Array();
  public currentReservesTrendAnalysis: Array<any> = new Array();
  public milestoneTrendAnalysis: Array<any> = new Array();
  public projectQuestions: Array<any> = new Array();
  public programDetails: Array<any> = new Array();
  public lessonsLearned: Array<any> = new Array();
  public pipelineProjectsList: Array<any> = new Array();
  public programAggregatedCost: Array<any> = new Array();
  public dependenciesList: Array<any> = new Array();
  dropdownPageIndex = 0;
  dropdownList = [];
  portfolioProjects = [];
  isTeamProject: boolean = false;
  isTeamSmallOrder: boolean = false;
  portfolioProjectsCount: number = 0;
  projectsPageIndex = 2;
  portfolioProjectsObservable = new Subject<any>();
  dashboardDataObservable = new Subject<any>();
  usersOpenOutlinesObservable = new Subject<any>();
  projectsLoadProgress = 0;
  dashboardObj: any = [];
  portfolioProjectsLastUpdated;
  dashboardFilterBtn: Subject<any> = new Subject();
  programDetail: Subject<any> = new Subject();
  projectDetail: Subject<any> = new Subject();
  isTeamEdit: boolean = false;
  portfolioTab: Subject<any> = new Subject();
  question;
  orderQuestion;
  changeRequestQuestion;
  closingQuestion;
  usersOpenOutlinesCount = 0;
  breadcrumbList = [];
  isCollapseSidebar: boolean = true;
  misc: any = {
    sidebar_mini_active: true
  };
  saneirungSubject = new Subject();

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router,
    private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.fetchPMOs();

    // Init language
    this.currentLanguage = localStorage.getItem('language');

    if (this.authService.getUserObj() != undefined) {
      if (this.authService.getUserObj().role != 'admin' && this.authService.getUserObj().role != 'pmo') {
        this.initializeDropdowns();
      }
    }

    this.apiService.get('/emailConfig').subscribe((response: any) => {
      this.emailTemplates = response;
    });

    this.socketService.listen('userOpenOutlinesCount').subscribe(count => {
      this.usersOpenOutlinesCount = count;
      this.usersOpenOutlinesObservable.next(count);
    });

    this.socketService.listen('portfolioProjectsCount').subscribe(count => {
      this.portfolioProjectsCount = count;
      this.projectsLoadProgress = (this.portfolioProjects.length * 100 / this.portfolioProjectsCount);
      this.portfolioProjectsLastUpdated = new Date();
      this.emitPortfolioProjects();
      if (count > 20) {
        this.socketService.emitEvent('portfolioProjectsIndex', { userId: this.authService.userObj.id, pageIndex: this.projectsPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('portfolioProjectsIndex').subscribe((response: any) => {
      this.portfolioProjects = this.portfolioProjects.concat(response);
      this.projectsLoadProgress = (this.portfolioProjects.length * 100 / this.portfolioProjectsCount)
      this.portfolioProjectsLastUpdated = new Date();
      this.emitPortfolioProjects();

      if (this.projectsPageIndex < (this.portfolioProjectsCount / 20)) {
        this.projectsPageIndex++;
        this.socketService.emitEvent('portfolioProjectsIndex', { userId: this.authService.userObj.id, pageIndex: this.projectsPageIndex, pageSize: 20 });
      } else {
        this.spinner.hide();
        this.portfolioProjectsLastUpdated = new Date();
        this.emitPortfolioProjects();
      }
    });
  }

  ngOnInit() { }

  initializeDropdowns() {
    this.apiService.get("/dropdown").subscribe((response: any) => {
      response.forEach(element => {
        if (element.field == "Mandatory Projects") {
          this.mandatoryProjectOptions = element;
        } else if (element.field == "Business Area") {
          this.businessAreaOptions = element;
        } else if (element.field == "Business Unit") {
          this.businessUnitOptions = element;
        } else if (element.field == "Business Segment") {
          this.businessSegmentOptions = element;
        } else if (element.field == "Reporting Level") {
          this.reportingLevelOptions = element;
        } else if (element.field == "Classification") {
          this.classificationOptions = element;
        } else if (element.field == "Project Type") {
          this.projectTypeOptions = element;
        } else if (element.field == "Strategic Contribution") {
          this.strategicContributionOptions = element;
        } else if (element.field == "Profitability") {
          this.profitabilityOptions = element;
        } else if (element.field == "Feasibility") {
          this.feasibilityOptions = element;
        } else if (element.field == "Project Phase") {
          this.projectPhaseOptions = element;
        } else if (element.field == "Risk Strategy") { //'Strategy'
          this.risksTopFiveRiskStrategySelectOptions = element;
        } else if (element.field == "Decision Type") {
          this.decisionTypeOptions = element;
        } else if (element.field == "Document Type") {
          this.typeOptions = element;
        } else if (element.field == "Project Methodology") {
          this.projectMethodologyOptions = element;
        } else if (element.field == "Lesson Category") {
          this.lessonsCategoryOptions = element;
        } else if (element.field == "Impact") {
          this.impactOptions = element;
        } else if (element.field == "Digitalization Topic") {
          this.digitalizationTopicOptions = element;
        } else if (element.field == "Technology") {
          this.technologyOptions = element;
        } else if (element.field == "Digitalization Focus") {
          this.digitalizationFocusOptions = element;
        } else if (element.field == "IT Platform") {
          this.itPlatformOptions = element;
        } else if (element.field == "IT Relevant") {
          this.itRelevantOptions = element;
        }

      });

      this.businessAreaOptions.values.forEach(area => {
        this.numberOfProjectsPerBusinessAreaWithOverallStatus.push({
          area: area.name,
          red: 0,
          green: 0,
          areaCount: 0,
          yellow: 0
        });
      });

      this.projectTypeOptions.values.forEach(area => {
        this.projectsPerProjectTypeChart.push({
          name: area.name,
          value: 0
        });
      });

      this.businessUnitOptions.values.forEach(area => {
        this.numberOfProjectsPerBusinessUnitWithOverallStatus.push({
          area: area.name,
          red: 0,
          green: 0,
          areaCount: 0,
          yellow: 0
        });
      });

      this.strategicContributionOptions.values.forEach(area => {
        this.projectsWithStrategicContribution.push({
          area: area.name,
          value: 0
        });
      });
    });
  }

  fetchUsers() {
    this.apiService.get('/user?limit=0').subscribe((response: any) => {
      this.usersList = response;
    });
  }

  fetchPMOs() {
    this.apiService.get('/getUsersFromRoles/PMO').subscribe((response: any) => {
      this.PMOsList = response;
    });
  }

  // get tables store state from localstorage
  getTablesState(): any {
    let approvals = localStorage.getItem('approvalsList');
    let portfolios = localStorage.getItem('portfolioList');
    let programProjects = localStorage.getItem('programProjectList');
    let programStatusReports = localStorage.getItem('programStatusReportList');
    let programs = localStorage.getItem('programsList');
    let projectPortfolios = localStorage.getItem('projectPortfolioList');
    let projectStatusReports = localStorage.getItem('projectStatusReportList');
    let projects = localStorage.getItem('projectsList');
    let subPortfolioBudget = localStorage.getItem('subPortfolioBudgetList');
    let teamProjects = localStorage.getItem('teamProjectsList');
    let pipelineProjects = localStorage.getItem('pipelineProjectsList');
    let programSmallOrders = localStorage.getItem('programSmallOrdersList');
    let smallOrderStatusReports = localStorage.getItem('smallOrderStatusReportList');
    let smallOrders = localStorage.getItem('smallOrdersList');
    let approvalsSmallOrder = localStorage.getItem('approvalsSmallOrderList');
    let smallOrdersPortfolio = localStorage.getItem('smallOrdersPortfolioList');

    let tablesState = {
      approvals, portfolios, programProjects, programStatusReports, programs, projectPortfolios, projectStatusReports,
      projects, subPortfolioBudget, teamProjects, pipelineProjects, programSmallOrders, smallOrderStatusReports,
      smallOrders, approvalsSmallOrder, smallOrdersPortfolio
    };

    return tablesState;
  }

  // set tables state in the localstorage
  setTablesState(tablesState) {
    if (tablesState != undefined && tablesState != null) {
      localStorage.setItem('approvalsList', tablesState.approvals);
      localStorage.setItem('portfolioList', tablesState.portfolios);
      localStorage.setItem('programProjectList', tablesState.programProjects);
      localStorage.setItem('programStatusReportList', tablesState.programStatusReports);
      localStorage.setItem('programsList', tablesState.programs);
      localStorage.setItem('projectPortfolioList', tablesState.projectPortfolios);
      localStorage.setItem('projectStatusReportList', tablesState.projectStatusReports);
      localStorage.setItem('projectsList', tablesState.projects);
      localStorage.setItem('subPortfolioBudgetList', tablesState.subPortfolioBudget);
      localStorage.setItem('teamProjectsList', tablesState.teamProjects);
      localStorage.setItem('pipelineProjectsList', tablesState.pipelineProjects);
      localStorage.setItem('programSmallOrdersList', tablesState.programSmallOrders);
      localStorage.setItem('smallOrderStatusReportList', tablesState.smallOrderStatusReports);
      localStorage.setItem('smallOrdersList', tablesState.smallOrders);
      localStorage.setItem('approvalsSmallOrderList', tablesState.approvalsSmallOrder);
      localStorage.setItem('smallOrdersPortfolioList', tablesState.smallOrdersPortfolio);
    }
  }

  //Fetching projects for portfolio
  fetchProjectsList() {
    this.apiService.get('/reports?limit=20').subscribe((reportsList: any) => {
      this.portfolioProjects = reportsList;
      this.emitPortfolioProjects();
      this.socketService.emitEvent('portfolioProjectsCount', { userId: this.authService.userObj.id });
    });
  }

  refreshDashboard() {
    localStorage.removeItem("dashboardData");
    this.spinner.show();
    this.fetchDashboard();
  }

  fetchDashboard() {
    if (localStorage.getItem("dashboardData") == undefined) {
      this.spinner.show();
      this.apiService.get('/getDashboardData').subscribe((response: any) => {
        this.dashboardObj = response;
        this.dashboardObj.lastUpdated = new Date();
        localStorage.setItem("dashboardData", JSON.stringify(this.dashboardObj));
        this.dashboardDataObservable.next(this.dashboardObj);
        this.spinner.hide();
      });
    } else {
      this.dashboardObj = JSON.parse(localStorage.getItem("dashboardData"));
      this.dashboardDataObservable.next(this.dashboardObj);
      this.spinner.hide();
    }
  }

  emitPortfolioProjects() {
    let portfolioProjectsData = {
      projectsList: this.portfolioProjects,
      lastUpdated: this.portfolioProjectsLastUpdated,
      projectsLoadProgress: this.projectsLoadProgress
    };
    this.portfolioProjectsObservable.next(portfolioProjectsData);
  }

  refreshProjects() {
    this.projectsPageIndex = 2;
    this.projectsLoadProgress = 0;
    this.fetchProjectsList();
  }

  getUsersOpenOutlinesCount() {
    this.socketService.emitEvent("userOpenOutlinesCount", { id: this.authService.userObj.id })
  }

  // set outline pdf ids values 
  setOutlinePdfValues(outline, flag: boolean = false): any {
    if (this.strategicContributionOptions == undefined) {
      this.apiService.get("/dropdown").subscribe((response: any) => {
        response.forEach(element => {
          if (element.field == "Mandatory Projects") {
            this.mandatoryProjectOptions = element;
          } else if (element.field == "Business Area") {
            this.businessAreaOptions = element;
          } else if (element.field == "Business Unit") {
            this.businessUnitOptions = element;
          } else if (element.field == "Business Segment") {
            this.businessSegmentOptions = element;
          } else if (element.field == "Reporting Level") {
            this.reportingLevelOptions = element;
          } else if (element.field == "Classification") {
            this.classificationOptions = element;
          } else if (element.field == "Project Type") {
            this.projectTypeOptions = element;
          } else if (element.field == "Strategic Contribution") {
            this.strategicContributionOptions = element;
          } else if (element.field == "Profitability") {
            this.profitabilityOptions = element;
          } else if (element.field == "Feasibility") {
            this.feasibilityOptions = element;
          } else if (element.field == "Project Phase") {
            this.projectPhaseOptions = element;
          } else if (element.field == "Risk Strategy") { //'Strategy'
            this.risksTopFiveRiskStrategySelectOptions = element;
          } else if (element.field == "Decision Type") {
            this.decisionTypeOptions = element;
          } else if (element.field == "Document Type") {
            this.typeOptions = element;
          } else if (element.field == "Project Methodology") {
            this.projectMethodologyOptions = element;
          } else if (element.field == "Lesson Category") {
            this.lessonsCategoryOptions = element;
          } else if (element.field == "Impact") {
            this.impactOptions = element;
          }
        });

        this.businessAreaOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessAreaWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.projectTypeOptions.values.forEach(area => {
          this.projectsPerProjectTypeChart.push({
            name: area.name,
            value: 0
          });
        });

        this.businessUnitOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessUnitWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.strategicContributionOptions.values.forEach(area => {
          this.projectsWithStrategicContribution.push({
            area: area.name,
            value: 0
          });
        });

        if (!flag) {
          if (location.href.includes('Preview')) {
            this.router.navigate(['outlinePreview/' + location.href.split('/').pop()]);
          } else if (location.href.includes('view/')) {
            this.router.navigate(['view/outline/' + location.href.split('/').pop()]);
          }
        } else {
          this.router.navigate([this.authService.previewLink]);
        }
      });
    } else {
      if (outline.strategicContribution.id == undefined) {
        outline.strategicContribution = this.strategicContributionOptions.values.find(x => x.id == outline.strategicContribution);
      }
      if (outline.feasibility.id == undefined) {
        outline.feasibility = this.feasibilityOptions.values.find(x => x.id == outline.feasibility);
      }
      if (outline.profitability.id == undefined) {
        outline.profitability = this.profitabilityOptions.values.find(x => x.id == outline.profitability);
      }

      if (outline.mandatoryProject.id == undefined) {
        outline.mandatoryProject = this.mandatoryProjectOptions.values.find(x => x.id == outline.mandatoryProject);
      }
      if (outline.classification.id == undefined) {
        outline.classification = this.classificationOptions.values.find(x => x.id == outline.classification);
      }
      if (outline.reportingLevel.id == undefined) {
        outline.reportingLevel = this.reportingLevelOptions.values.find(x => x.id == outline.reportingLevel);
      }
      if (outline.businessUnit.id == undefined) {
        outline.businessUnit = this.businessUnitOptions.values.find(x => x.id == outline.businessUnit);
      }
      if (outline.businessArea.id == undefined) {
        outline.businessArea = this.businessAreaOptions.values.find(x => x.id == outline.businessArea);
      }
      if (outline.businessSegment.id == undefined) {
        outline.businessSegment = this.businessSegmentOptions.values.find(x => x.id == outline.businessSegment);
      }
      if (outline.projectType.id == undefined) {
        outline.projectType = this.projectTypeOptions.values.find(x => x.id == outline.projectType);
      }
      if (outline.projectId.id == undefined) {
        outline.projectId = this.projectList.find(x => x.id == outline.projectId)
      }

      return outline;
    }
  }

  // set order pdf ids values
  setOrderPdfValues(order, flag: boolean = false): any {
    if (this.strategicContributionOptions == undefined) {
      this.apiService.get("/dropdown").subscribe((response: any) => {
        response.forEach(element => {
          if (element.field == "Mandatory Projects") {
            this.mandatoryProjectOptions = element;
          } else if (element.field == "Business Area") {
            this.businessAreaOptions = element;
          } else if (element.field == "Business Unit") {
            this.businessUnitOptions = element;
          } else if (element.field == "Business Segment") {
            this.businessSegmentOptions = element;
          } else if (element.field == "Reporting Level") {
            this.reportingLevelOptions = element;
          } else if (element.field == "Classification") {
            this.classificationOptions = element;
          } else if (element.field == "Project Type") {
            this.projectTypeOptions = element;
          } else if (element.field == "Strategic Contribution") {
            this.strategicContributionOptions = element;
          } else if (element.field == "Profitability") {
            this.profitabilityOptions = element;
          } else if (element.field == "Feasibility") {
            this.feasibilityOptions = element;
          } else if (element.field == "Project Phase") {
            this.projectPhaseOptions = element;
          } else if (element.field == "Risk Strategy") { //'Strategy'
            this.risksTopFiveRiskStrategySelectOptions = element;
          } else if (element.field == "Decision Type") {
            this.decisionTypeOptions = element;
          } else if (element.field == "Document Type") {
            this.typeOptions = element;
          } else if (element.field == "Project Methodology") {
            this.projectMethodologyOptions = element;
          } else if (element.field == "Lesson Category") {
            this.lessonsCategoryOptions = element;
          } else if (element.field == "Impact") {
            this.impactOptions = element;
          }
        });

        this.businessAreaOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessAreaWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.projectTypeOptions.values.forEach(area => {
          this.projectsPerProjectTypeChart.push({
            name: area.name,
            value: 0
          });
        });

        this.businessUnitOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessUnitWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.strategicContributionOptions.values.forEach(area => {
          this.projectsWithStrategicContribution.push({
            area: area.name,
            value: 0
          });
        });

        if (!flag) {
          if (location.href.includes('Preview')) {
            this.router.navigate(['orderPreview/' + location.href.split('/').pop()]);
          } else if (location.href.includes('Preview')) {
            this.router.navigate(['view/order/' + location.href.split('/').pop()]);
          }
        } else {
          this.router.navigate([this.authService.previewLink]);
        }
      });
    } else {
      if (order.strategicContribution) {
        if (order.strategicContribution.id == undefined) {
          order.strategicContribution = this.strategicContributionOptions.values.find(x => x.id == order.strategicContribution);
        }
      } else {
        order.strategicContribution = this.strategicContributionOptions.values.find(x => x.id == order.strategicContribution);
      }
      if (order.feasibility) {
        if (order.feasibility.id == undefined) {
          order.feasibility = this.feasibilityOptions.values.find(x => x.id == order.feasibility);
        }
      } else {
        order.feasibility = this.feasibilityOptions.values.find(x => x.id == order.feasibility);
      }
      if (order.profitability) {
        if (order.profitability.id == undefined) {
          order.profitability = this.profitabilityOptions.values.find(x => x.id == order.profitability);
        }
      } else {
        order.profitability = this.profitabilityOptions.values.find(x => x.id == order.profitability);
      }
      if (order.mandatoryProject) {
        if (order.mandatoryProject.id == undefined) {
          order.mandatoryProject = this.mandatoryProjectOptions.values.find(x => x.id == order.mandatoryProject);
        }
      } else {
        order.mandatoryProject = this.mandatoryProjectOptions.values.find(x => x.id == order.mandatoryProject);
      }
      if (order.classification) {
        if (order.classification.id == undefined) {
          order.classification = this.classificationOptions.values.find(x => x.id == order.classification);
        }
      } else {
        order.classification = this.classificationOptions.values.find(x => x.id == order.classification);
      }
      if (order.reportingLevel) {
        if (order.reportingLevel.id == undefined) {
          order.reportingLevel = this.reportingLevelOptions.values.find(x => x.id == order.reportingLevel);
        }
      } else {
        order.reportingLevel = this.reportingLevelOptions.values.find(x => x.id == order.reportingLevel);
      }
      if (order.businessUnit) {
        if (order.businessUnit.id == undefined) {
          order.businessUnit = this.businessUnitOptions.values.find(x => x.id == order.businessUnit);
        }
      } else {
        order.businessUnit = this.businessUnitOptions.values.find(x => x.id == order.businessUnit);
      }
      if (order.businessArea) {
        if (order.businessArea.id == undefined) {
          order.businessArea = this.businessAreaOptions.values.find(x => x.id == order.businessArea);
        }
      } else {
        order.businessArea = this.businessAreaOptions.values.find(x => x.id == order.businessArea);
      }
      if (order.businessSegment) {
        if (order.businessSegment.id == undefined) {
          order.businessSegment = this.businessSegmentOptions.values.find(x => x.id == order.businessSegment);
        }
      } else {
        order.businessSegment = this.businessSegmentOptions.values.find(x => x.id == order.businessSegment);
      }
      if (order.projectType) {
        if (order.projectType.id == undefined) {
          order.projectType = this.projectTypeOptions.values.find(x => x.id == order.projectType);
        }
      } else {
        order.projectType = this.projectTypeOptions.values.find(x => x.id == order.projectType);
      }

      if (order.projectId) {
        if (order.projectId.id == undefined) {
          order.projectId = this.projectList.find(x => x.id == order.projectId)
        }
      } else {
        order.projectId = this.projectList.find(x => x.id == order.projectId)
      }

      return order;
    }
  }

  // set change request pdf ids values by saad 
  setChangeRequestPdfValues(changeRequest, flag: boolean = false): any {
    if (this.strategicContributionOptions == undefined) {
      this.apiService.get("/dropdown").subscribe((response: any) => {
        response.forEach(element => {
          if (element.field == "Mandatory Projects") {
            this.mandatoryProjectOptions = element;
          } else if (element.field == "Business Area") {
            this.businessAreaOptions = element;
          } else if (element.field == "Business Unit") {
            this.businessUnitOptions = element;
          } else if (element.field == "Business Segment") {
            this.businessSegmentOptions = element;
          } else if (element.field == "Reporting Level") {
            this.reportingLevelOptions = element;
          } else if (element.field == "Classification") {
            this.classificationOptions = element;
          } else if (element.field == "Project Type") {
            this.projectTypeOptions = element;
          } else if (element.field == "Strategic Contribution") {
            this.strategicContributionOptions = element;
          } else if (element.field == "Profitability") {
            this.profitabilityOptions = element;
          } else if (element.field == "Feasibility") {
            this.feasibilityOptions = element;
          } else if (element.field == "Project Phase") {
            this.projectPhaseOptions = element;
          } else if (element.field == "Risk Strategy") { //'Strategy'
            this.risksTopFiveRiskStrategySelectOptions = element;
          } else if (element.field == "Decision Type") {
            this.decisionTypeOptions = element;
          } else if (element.field == "Document Type") {
            this.typeOptions = element;
          } else if (element.field == "Project Methodology") {
            this.projectMethodologyOptions = element;
          } else if (element.field == "Lesson Category") {
            this.lessonsCategoryOptions = element;
          } else if (element.field == "Impact") {
            this.impactOptions = element;
          }
        });

        this.businessAreaOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessAreaWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.projectTypeOptions.values.forEach(area => {
          this.projectsPerProjectTypeChart.push({
            name: area.name,
            value: 0
          });
        });

        this.businessUnitOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessUnitWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.strategicContributionOptions.values.forEach(area => {
          this.projectsWithStrategicContribution.push({
            area: area.name,
            value: 0
          });
        });

        if (!flag) {
          if (location.href.includes('Preview')) {
            this.router.navigate(['changeRequestPreview/' + location.href.split('/').pop()]);
          } else if (location.href.includes('view/')) {
            this.router.navigate(['view/changeRequest/' + location.href.split('/').pop()]);
          }
        } else {
          this.router.navigate([this.authService.previewLink])
        }
      });
    } else {
      if (changeRequest.strategicContribution.id == undefined) {
        changeRequest.strategicContribution = this.strategicContributionOptions.values.find(x => x.id == changeRequest.strategicContribution);
      }
      if (changeRequest.feasibility.id == undefined) {
        changeRequest.feasibility = this.feasibilityOptions.values.find(x => x.id == changeRequest.feasibility);
      }
      if (changeRequest.profitability.id == undefined) {
        changeRequest.profitability = this.profitabilityOptions.values.find(x => x.id == changeRequest.profitability);
      }
      if (changeRequest.mandatoryProject.id == undefined) {
        changeRequest.mandatoryProject = this.mandatoryProjectOptions.values.find(x => x.id == changeRequest.mandatoryProject);
      }
      if (changeRequest.classification.id == undefined) {
        changeRequest.classification = this.classificationOptions.values.find(x => x.id == changeRequest.classification);
      }
      if (changeRequest.reportingLevel.id == undefined) {
        changeRequest.reportingLevel = this.reportingLevelOptions.values.find(x => x.id == changeRequest.reportingLevel);
      }
      if (changeRequest.businessUnit.id == undefined) {
        changeRequest.businessUnit = this.businessUnitOptions.values.find(x => x.id == changeRequest.businessUnit);
      }
      if (changeRequest.businessArea.id == undefined) {
        changeRequest.businessArea = this.businessAreaOptions.values.find(x => x.id == changeRequest.businessArea);
      }
      if (changeRequest.businessSegment.id == undefined) {
        changeRequest.businessSegment = this.businessSegmentOptions.values.find(x => x.id == changeRequest.businessSegment);
      }
      if (changeRequest.projectType.id == undefined) {
        changeRequest.projectType = this.projectTypeOptions.values.find(x => x.id == changeRequest.projectType);
      }
      if (changeRequest.project.id == undefined) {
        if (typeof (changeRequest.project) == 'string') {
          changeRequest.project = this.projectList.find(x => x.id == changeRequest.project)
        }
      }
      if (changeRequest.project.projectReport.strategicContribution != undefined) {
        if (changeRequest.project.projectReport.strategicContribution.id == undefined) {
          changeRequest.project.projectReport.strategicContribution = this.strategicContributionOptions.values.find(x => x.id == changeRequest.project.projectReport.strategicContribution);
        }
      }
      if (changeRequest.project.projectReport.feasibility != undefined) {
        if (changeRequest.project.projectReport.feasibility.id == undefined) {
          changeRequest.project.projectReport.feasibility = this.feasibilityOptions.values.find(x => x.id == changeRequest.project.projectReport.feasibility);
        }
      }
      if (changeRequest.project.projectReport.profitability != undefined) {
        if (changeRequest.project.projectReport.profitability.id == undefined) {
          changeRequest.project.projectReport.profitability = this.profitabilityOptions.values.find(x => x.id == changeRequest.project.projectReport.profitability);
        }
      }

      return changeRequest;
    }
  }

  // set closing report pdf ids values by saad 
  setClosingReportPdfValues(closingReport, flag: boolean = false): any {
    if (this.strategicContributionOptions == undefined) {
      this.apiService.get("/dropdown").subscribe((response: any) => {
        response.forEach(element => {
          if (element.field == "Mandatory Projects") {
            this.mandatoryProjectOptions = element;
          } else if (element.field == "Business Area") {
            this.businessAreaOptions = element;
          } else if (element.field == "Business Unit") {
            this.businessUnitOptions = element;
          } else if (element.field == "Business Segment") {
            this.businessSegmentOptions = element;
          } else if (element.field == "Reporting Level") {
            this.reportingLevelOptions = element;
          } else if (element.field == "Classification") {
            this.classificationOptions = element;
          } else if (element.field == "Project Type") {
            this.projectTypeOptions = element;
          } else if (element.field == "Strategic Contribution") {
            this.strategicContributionOptions = element;
          } else if (element.field == "Profitability") {
            this.profitabilityOptions = element;
          } else if (element.field == "Feasibility") {
            this.feasibilityOptions = element;
          } else if (element.field == "Project Phase") {
            this.projectPhaseOptions = element;
          } else if (element.field == "Risk Strategy") { //'Strategy'
            this.risksTopFiveRiskStrategySelectOptions = element;
          } else if (element.field == "Decision Type") {
            this.decisionTypeOptions = element;
          } else if (element.field == "Document Type") {
            this.typeOptions = element;
          } else if (element.field == "Project Methodology") {
            this.projectMethodologyOptions = element;
          } else if (element.field == "Lesson Category") {
            this.lessonsCategoryOptions = element;
          } else if (element.field == "Impact") {
            this.impactOptions = element;
          }
        });

        this.businessAreaOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessAreaWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.projectTypeOptions.values.forEach(area => {
          this.projectsPerProjectTypeChart.push({
            name: area.name,
            value: 0
          });
        });

        this.businessUnitOptions.values.forEach(area => {
          this.numberOfProjectsPerBusinessUnitWithOverallStatus.push({
            area: area.name,
            red: 0,
            green: 0,
            areaCount: 0,
            yellow: 0
          });
        });

        this.strategicContributionOptions.values.forEach(area => {
          this.projectsWithStrategicContribution.push({
            area: area.name,
            value: 0
          });
        });

        if (!flag) {
          if (location.href.includes('Preview')) {
            this.router.navigate(['closingReportPreview/' + location.href.split('/').pop()]);
          } else if (location.href.includes('Preview')) {
            this.router.navigate(['view/closingReport/' + location.href.split('/').pop()]);
          }
        } else {
          this.router.navigate([this.authService.previewLink]);
        }
      });
    } else {
      if (closingReport.project.id == undefined) {
        closingReport.project = this.projectList.find(x => x.id == closingReport.project)
      }

      return closingReport;
    }
  }

  getOutlineQuestionsHtml(outlineQuestions, question): string {
    let questionsList = [
      {
        department: outlineQuestions[4].department,
        values: [
          {
            question: outlineQuestions[4].question,
            answer: question[0],
          },
        ]
      },
      {
        department: outlineQuestions[0].department,
        values: [
          {
            question: outlineQuestions[0].question,
            answer: question[1],
          }
        ]
      },
      {
        department: outlineQuestions[1].department,
        values: [
          {
            question: outlineQuestions[1].question,
            answer: question[2],
          }
        ]
      },
      {
        department: outlineQuestions[2].department,
        values: [
          {
            question: outlineQuestions[2].question,
            answer: question[3],
          }, {
            question: outlineQuestions[3].question,
            answer: question[4],
          },]
      },
      {
        department: outlineQuestions[5].department,
        values: [
          {
            question: outlineQuestions[5].question,
            answer: question[5],
          },
          {
            question: outlineQuestions[6].question,
            answer: question[6],
          },
        ]
      },
    ];

    let questionsHtml = '<br><br>';
    questionsList.map(val => {
      questionsHtml += `<h3>${val.department}</h3>`;
      val.values.map(vals => {
        questionsHtml += `
          <p><strong>${vals.question}</strong></p>
          <p>${vals.answer}</p>
        `;
      });
    });

    return questionsHtml;
  }

  getOrderQuestionsHtml(questionsList, question): string {
    let data = [
      {
        department: questionsList[0].department,
        values: [
          {
            question: questionsList[0].question,
            answer: question[0],
          },
        ]
      },
      {
        department: questionsList[1].department,
        values: [
          {
            question: questionsList[1].question,
            answer: question[1],
          },
          {
            question: questionsList[2].question,
            answer: question[2],
          },
          {
            question: questionsList[3].question,
            answer: question[3],
          },
        ]
      },
      {
        department: questionsList[4].department,
        values: [
          {
            question: questionsList[4].question,
            answer: question[4],
          }
        ]
      },
      {
        department: questionsList[5].department,
        values: [
          {
            question: questionsList[5].question,
            answer: question[5],
          },
          {
            question: questionsList[6].question,
            answer: question[6],
          },
          {
            question: questionsList[7].question,
            answer: question[7],
          },
        ]
      },
      {
        department: questionsList[8].department,
        values: [
          {
            question: questionsList[8].question,
            answer: question[8],
          },
        ]
      },
    ];

    let questionsHtml = '<br><br>';
    data.map(val => {
      questionsHtml += `<h3>${val.department}</h3>`;
      val.values.map(vals => {
        questionsHtml += `
          <p><strong>${vals.question}</strong>
          <p>${vals.answer}</p>
        `;
      });
    });

    return questionsHtml;
  }

  getChangeRequestQuestionsHtml(questionsList, question): string {
    let data = [
      {
        department: questionsList[5].department,
        values: [
          {
            question: questionsList[5].question,
            answer: question[0],
          },
        ]
      },
      {
        department: questionsList[0].department,
        values: [
          {
            question: questionsList[0].question,
            answer: question[1],
          },
        ]
      },
      {
        department: questionsList[1].department,
        values: [
          {
            question: questionsList[1].question,
            answer: question[2],
          },
          {
            question: questionsList[2].question,
            answer: question[3],
          },
          {
            question: questionsList[4].question,
            answer: question[4],
          },
        ]
      },
      {
        department: questionsList[3].department,
        values: [
          {
            question: questionsList[3].question,
            answer: question[5],
          },
        ]
      },
    ];

    let questionsHtml = '<br><br>';
    data.map(val => {
      questionsHtml += `<h3>${val.department}</h3>`;
      val.values.map(vals => {
        questionsHtml += `
          <p><strong>${vals.question}</strong>
          <p>${vals.answer}</p>
        `;
      });
    });

    return questionsHtml;
  }

  getClosingReportQuestionsHtml(questionsList, question): string {
    let data = [
      {
        department: questionsList[0].department,
        values: [
          {
            question: questionsList[0].question,
            answer: question[0],
          },
        ]
      },
      {
        department: questionsList[1].department,
        values: [
          {
            question: questionsList[1].question,
            answer: question[1],
          },
          {
            question: questionsList[2].question,
            answer: question[2],
          },
          {
            question: questionsList[3].question,
            answer: question[3],
          },
        ]
      },
      {
        department: questionsList[4].department,
        values: [
          {
            question: questionsList[4].question,
            answer: question[4],
          },
          {
            question: questionsList[5].question,
            answer: question[5],
          },
        ]
      },
    ];

    let questionsHtml = '<br><br>';
    data.map(val => {
      questionsHtml += `<h3>${val.department}</h3>`;
      val.values.map(vals => {
        questionsHtml += `
          <p><strong>${vals.question}</strong></p>
          <p>${vals.answer}</p>
        `;
      });
    });

    return questionsHtml;
  }

  initTabs() {
    $('.card-wizard').bootstrapWizard({
      'tabClass': 'nav nav-pills',
      'nextSelector': '.btn-next',
      'previousSelector': '.btn-previous',

      onNext: function (tab, navigation, index) {
        var $valid = $('.card-wizard form').valid();
        if (!$valid) {
          // $validator.focusInvalid();
          return false;
        }
      },

      // onInit: function (tab: any, navigation: any, index: any) {
      //   // check number of tabs and fill the entire row
      //   let $total = navigation.find('li').length;
      //   let $wizard = navigation.closest('.card-wizard');

      //   let $first_li = navigation.find('li:first-child a').html();
      //   let $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
      //   // if ($('.card-wizard .wizard-navigation .moving-tab').length == 0) {
      //   $('.card-wizard .wizard-navigation').append($moving_div);
      //   // }

      //   $total = $wizard.find('.nav li').length;
      //   let $li_width = 100 / $total;

      //   let total_steps = $wizard.find('.nav li').length;
      //   let move_distance = $wizard.width() / total_steps;
      //   let index_temp = index;
      //   let vertical_level = 0;

      //   let mobile_device = $(document).width() < 600 && $total > 3;

      //   if (mobile_device) {
      //     move_distance = $wizard.width() / 2;
      //     index_temp = index % 2;
      //     $li_width = 50;
      //   }

      //   $wizard.find('.nav li').css('width', $li_width + '%');

      //   let step_width = move_distance;
      //   move_distance = move_distance * index_temp;

      //   let $current = index + 1;

      //   if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
      //     move_distance -= 8;
      //   } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
      //     move_distance += 8;
      //   }

      //   if (mobile_device) {
      //     let x: any = index / 2;
      //     vertical_level = parseInt(x);
      //     vertical_level = vertical_level * 38;
      //   }

      //   $wizard.find('.moving-tab').css('width', step_width);
      //   $('.moving-tab').css({
      //     // 'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)', 
      //     'transform': 'translate3d(' + 0 + '%, ' + vertical_level + 'px, 0)',
      //     'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      //   });
      //   $('.moving-tab').css('transition', 'transform 0s');
      // },

      onTabShow: function (tab: any, navigation: any, index: any) {
        let $total = navigation.find('li').length;
        let $current = index + 1;

        const $wizard = navigation.closest('.card-wizard');

        // If it's the last tab then hide the last button and show the finish instead
        if ($current >= $total) {
          $($wizard).find('.btn-next').hide();
          $($wizard).find('.btn-finish').show();
        } else {
          $($wizard).find('.btn-next').show();
          $($wizard).find('.btn-finish').hide();
        }

        const button_text = navigation.find('li:nth-child(' + $current + ') a').html();

        setTimeout(function () {
          if (button_text != undefined) {
            if (button_text.includes('&amp;')) {
              let text = button_text.replace('&amp;', '&');
              $('.moving-tab').text(text);
            } else {
              $('.moving-tab').text(button_text);
            }
          }
        }, 150);

        const checkbox = $('.footer-checkbox');

        if (index !== 0) {
          $(checkbox).css({
            'opacity': '0',
            'visibility': 'hidden',
            'position': 'absolute'
          });
        } else {
          $(checkbox).css({
            'opacity': '1',
            'visibility': 'visible'
          });
        }
        $total = $wizard.find('.nav li').length;
        let $li_width = 100 / $total;

        let total_steps = $wizard.find('.nav li').length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        let mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find('.nav li').css('width', $li_width + '%');

        let step_width = move_distance;
        move_distance = move_distance * index_temp;

        $current = index + 1;

        if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
          move_distance -= 8;
        } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
          move_distance += 8;
        }

        if (mobile_device) {
          let x: any = index / 2;
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }

        $wizard.find('.moving-tab').css('width', step_width);
        $('.moving-tab').css({
          'transform': 'translate3d(' + move_distance + '%, ' + vertical_level + 'px, 0)',
          'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
        });
        setTimeout(() => {
          if ($('.moving-tab').length > 1) {
            $('.moving-tab')[$('.moving-tab').length - 1].remove();
          }
        }, 100);
      }
    });
  }

  showNotification(title: any, message: any, type: any) {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    $.notify({
      icon: 'notifications',
      message: message,
      title: title
    }, {
      type: type,
      timer: 3000,
      z_index: 1051,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  checkAllowedFileMimeTypes(type): boolean {
    let mimeTypesAllowed = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'image/jpeg',
      'image/png'
    ];

    let res = mimeTypesAllowed.find(val => val == type);
    return res ? true : false;
  }

  processExcelFile(excelData) {
    let uniqueProjectsIds = excelData.map((item: any) => item.Projekt).filter((value, index, self) => self.indexOf(value) === index).filter(ele => { return ele != "" && ele != null && ele != undefined })

    let projectBudgets = [];

    uniqueProjectsIds.forEach(projectID => {

      let importedProjects = [];

      let budget = { capex: { internal: 0, external: 0 }, opex: { internal: 0, external: 0 }, revenues: 0 }
      let projectName = "";
      let projectStatus;

      excelData.filter(record => record.Projekt == projectID).forEach(project => {
        let projectRaw = {};

        projectRaw['PSP-Element'] = project['PSP-Element'];
        projectRaw['Bezeichnung PSP'] = project['Bezeichnung PSP'];
        projectRaw['Profitcenter'] = project.Profitcenter[0] == "8" ? 'CAPEX' : 'OPEX';
        projectRaw['Währung PSP'] = project['Währung PSP'];
        projectRaw['Eigenleistungen Ist'] = project['Eigenleistungen Ist'];
        projectRaw['int Verr Eigenleistungen Ist'] = project['int.Verr. Eigenleistungen Ist'];
        projectRaw['Fahrzeuge Arb mittel Ist'] = project['Fahrzeuge/Arb.mittel Ist'];
        projectRaw['Material Fremdl üBA Ist'] = project['Material/Fremdl./üBA Ist'];
        projectRaw['Summe Kosten Ist'] = project['Summe Kosten Ist'];
        projectRaw['Summe Erlös Ist'] = project['Summe Erlös Ist'];
        importedProjects.push(projectRaw);

        let internalKeys = ["Eigenleistungen Ist", "int.Verr. Eigenleistungen Ist", "Fahrzeuge/Arb.mittel Ist"]
        budget.revenues += parseFloat(project["Summe Erlös Ist"])
        projectName = project["Bezeichnung Projekt"]
        projectStatus = project["Status Projekt"]

        if (project.Profitcenter[0] == "8") { //CAPEX
          budget.capex.internal += (parseFloat(project[internalKeys[0]]) + parseFloat(project[internalKeys[1]]) + parseFloat(project[internalKeys[2]]))
          budget.capex.external += parseFloat(project["Material/Fremdl./üBA Ist"]);
        } else if (project.Profitcenter[0] == "9") { //OPEX
          budget.opex.internal += (parseFloat(project[internalKeys[0]]) + parseFloat(project[internalKeys[1]]) + parseFloat(project[internalKeys[2]]))
          budget.opex.external += parseFloat(project["Material/Fremdl./üBA Ist"]);
        }
      });

      let projectBudget = [
        {
          costType: "External Costs",
          cost: budget.capex.external,
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          cost: budget.capex.internal,
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          cost: budget.opex.external,
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          cost: budget.opex.internal,
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          cost: budget.revenues,
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          cost: 0,
          id: 5,
          group: "Sonstiges",
        },
        {
          costType: "Total",
          cost: '',
          id: 6,
          group: "Sonstiges",
        }
      ];

      let projectItem = { projectId: projectID, cost: projectBudget, projectName, projectStatus: projectStatus, uploadDate: new Date(), projectRaw : importedProjects }
      projectBudgets.push(projectItem);
    });

    this.apiService.post('/dashboard/importExcelFinancials', projectBudgets).subscribe(res => {
      this.showNotification("Success", "File has been successfully Imported!", "success");
      this.spinner.hide()
    });
  }
}
