import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-subportfolio-decisions',
  templateUrl: './subportfolio-decisions.component.html',
  styleUrls: ['./subportfolio-decisions.component.scss']
})
export class SubportfolioDecisionsComponent implements OnInit, OnChanges {
  @Input() decisionsInputList;
  @Input() isMember;
  @Output() decisionsOutput = new EventEmitter<any>();

  decisionsList = [];
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  decisionForm: any = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '' };
  usersList;
  decisionTypeOptions;
  statusOptions;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isEdit = false;
  public reportRelevantDecisionCount: number = 0;
  private backupForm: any;
  helpGuide;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService) {
    this.usersList = this.utilityService.usersList;
    this.decisionTypeOptions = this.utilityService.decisionTypeOptions;
    this.statusOptions = ['Requested', 'Accepted', 'Denied'];

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.getHelpGuideData();
  }

  ngOnInit() {
    if (this.decisionsInputList != undefined) {
      this.decisionsList = this.decisionsInputList;
    }

    if (this.decisionsList == undefined) {
      this.decisionsList = [];
      this.reportRelevantDecisionCount = 0;
    }

    if (this.reportRelevantDecisionCount == undefined) {
      this.reportRelevantDecisionCount = 0;
    }

    if (this.decisionsList.length > 0) {
      this.reportRelevantDecisionCount = 0;
      for (let i = 0; i < this.decisionsList.length; i++) {
        if (this.decisionsList[i].report == true) {
          this.reportRelevantDecisionCount++;
        }
      }
    }
  }

  ngOnChanges(changes: any) {
    if (changes.decisionsInputList && changes.decisionsInputList.currentValue != undefined) {
      this.decisionsList = changes.decisionsInputList.currentValue;
      this.decisionsInputList = changes.decisionsInputList.currentValue;

      if (this.decisionsList.length > 0) {
        this.reportRelevantDecisionCount = 0;
        for (let i = 0; i < this.decisionsList.length; i++) {
          if (this.decisionsList[i].report == true) {
            this.reportRelevantDecisionCount++;
          }
        }
      }
    }
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Program Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
    this.modalService.onHidden.subscribe((reason: string) => {
      this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
    });
  }

  save() {
    if (this.decisionForm.decisionBy.azureId != undefined) {
      this.apiService.get('/user/search/' + this.decisionForm.decisionBy.name).retry(2).subscribe((response: any) => {
        let usersList = response;
        if (usersList.length > 0) {
          let getUser = usersList.find(val => val.email == this.decisionForm.decisionBy.email);
          if (getUser != undefined && getUser != null) {
            if (this.decisionsList.length == 0) {
              this.decisionForm.id = 1;
            } else {
              this.decisionForm.id = this.decisionsList[this.decisionsList.length - 1].id + 1;
            }

            if (this.decisionForm.report == true) {
              if (this.reportRelevantDecisionCount <= 2) {
                this.decisionsList.push(this.decisionForm);
                this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
                this.reportRelevantDecisionCount++;

                this.decisionsOutput.emit({ decisionsList: this.decisionsList });
                this.isEdit = false;
                this.dataGrid.instance.option('dataSource', this.decisionsList);
                this.showNotificationBox('Success', 'Decision Saved.', 'success');
                this.modalRef.hide();
              } else {
                this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
              }
            } else {
              this.decisionsList.push(this.decisionForm);
              this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };

              this.decisionsOutput.emit({ decisionsList: this.decisionsList });
              this.isEdit = false;
              this.dataGrid.instance.option('dataSource', this.decisionsList);
              this.showNotificationBox('Success', 'Decision Saved.', 'success');
              this.modalRef.hide();
            }
          } else {
            this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
          }
        } else {
          this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
        }
      });
    } else {
      this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
    }
  }

  edit() {
    this.apiService.get('/user/search/' + this.decisionForm.decisionBy.name).retry(2).subscribe((response: any) => {
      let usersList = response;
      if (usersList.length > 0) {
        let getUser = usersList.find(val => val.email == this.decisionForm.decisionBy.email);
        if (getUser != undefined && getUser != null) {
          if (this.decisionForm.report == true) {
            if (this.backupForm.report == true && this.decisionForm.report == true) {

              this.decisionsOutput.emit({ decisionsList: this.decisionsList });
              this.isEdit = false;
              this.dataGrid.instance.option('dataSource', this.decisionsList);
              this.showNotificationBox('Success', 'Decision Saved.', 'success');
              this.modalRef.hide();
            } else {
              if (this.reportRelevantDecisionCount <= 2) {
                if (this.backupForm.report == false && this.decisionForm.report == true) {
                  this.reportRelevantDecisionCount++;
                }

                this.decisionsOutput.emit({ decisionsList: this.decisionsList });
                this.isEdit = false;
                this.dataGrid.instance.option('dataSource', this.decisionsList);
                this.showNotificationBox('Success', 'Decision Saved.', 'success');
                this.modalRef.hide();
              } else {
                this.decisionForm.report = false;
                this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
              }
            }
          } else {
            if (this.backupForm.report == true && this.decisionForm.report == false) {
              this.reportRelevantDecisionCount--;
            }

            this.decisionsOutput.emit({ decisionsList: this.decisionsList });
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.decisionsList);
            this.showNotificationBox('Success', 'Decision Saved.', 'success');
            this.modalRef.hide();
          }
        } else {
          this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
        }
      } else {
        this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
      }
    });
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.decisionForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  valueChangeEvent(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.decisionForm.decisionBy = response;
          }
        });
      }
    }
  }

  onInputEvent(e) {
    this.apiService.get('/user/search/' + this.decisionForm.decisionBy.email).retry(2).subscribe(response => {
      this.usersList = response;
    });
  }

  decisionTypeValueChanged(e) {
    if (this.decisionTypeOptions != undefined) {
      this.decisionTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.decisionForm.decisionType = response;
        }
      });
    }
  }

  openDeleteDialog(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant decision cannot be deleted.', 'warning');
        } else {
          let idx;
          this.decisionsList.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });
          this.decisionsList.splice(idx, 1);

          // Updating id's 
          for (let index = 0; index < this.decisionsList.length; index++) {
            this.decisionsList[index].id = index + 1;
          }

          this.decisionsOutput.emit({ decisionsList: this.decisionsList });
          this.dataGrid.instance.option('dataSource', this.decisionsList);
          this.showNotificationBox('Success', 'Decision deleted.', 'success');
        }
      }
    });
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}