import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectChangeRequestRoutingModule } from './project-change-request-routing.module';
import { ProjectChangeRequestComponent } from './project-change-request.component';
import {
  DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxDateBoxModule,
  DxCheckBoxModule, DxProgressBarModule, DxChartModule, DxDropDownBoxModule,
  DxTextBoxModule, DxPopoverModule, DxRadioGroupModule, DxTreeViewModule
} from 'devextreme-angular';
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFModule } from 'app/pdf/pdf.module';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    ProjectChangeRequestRoutingModule,
    DxDataGridModule,
    I18nModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    PDFModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxProgressBarModule,
    DxChartModule,
    DxDropDownBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    CurrencyFormatterPipeModule,
    FileUploadModule,
    DxRadioGroupModule,
    ChartsModule,
    MaterialModule,
    NgxSpinnerModule,
    DxTreeViewModule,
    ClipboardModule
  ],
  declarations: [
    ProjectChangeRequestComponent,
  ]
})
export class ProjectChangeRequestModule { }
