import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxPieChartComponent } from 'devextreme-angular';
import { UtilityService } from 'app/services/utility.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { SocketService } from 'app/services/socket.service';
import { ApiService } from 'app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'app/services/auth.service';
declare var html2canvas: any;
declare var $: any;
// declare var dhx: any;
var globalThis;

@Component({
  selector: 'app-pdf-order',
  templateUrl: './pdf-order.component.html',
  styleUrls: ['./pdf-order.component.scss']
})
export class PdfOrderComponent implements OnInit {
  @Input() project: any = {};
  estimatedProjectCostChart = [];
  strategicContributionSlider = 0;
  feasibilitySlider = 0;
  profitabilitySlider = 0;
  @ViewChild('estimatedProjectCostChart', { static: false }) estimatedProjectCostChartElem: DxPieChartComponent;
  shapes = [];
  renderChart = true;
  trippleConstraint = {
    cost: 2,
    time: 4,
    scope: 3
  };
  public formObject: any;
  lineChartColors = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)"
    }
  ];
  public radarChartType: string = "radar";
  public radarChartLabels: string[] = [];
  dateDiffDays: any;
  dateDiffMonths: any;
  dateDiffYears: any;
  showLoading: boolean = false;
  diagram: any;
  diagramCont: any;
  itPlatform1;
  itPlatform2;
  itPlatform3;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private authService: AuthService, private utilityService: UtilityService, private localize: I18nPipe, private socket: SocketService, private apiService: ApiService, private spinner: NgxSpinnerService) {
    globalThis = this;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';

    this.formObject = new Object();
    this.formObject.radarChartData = [{
      data: [
        this.trippleConstraint.time,
        this.trippleConstraint.cost,
        this.trippleConstraint.scope
      ],
      label: this.localize.transform("Triple Constraint")
    }];

    this.radarChartLabels = [this.localize.transform("Time"), this.localize.transform("Cost"), this.localize.transform("Scope")];
  }

  ngOnInit() {
    console.log(this.project)
    if (this.project.costTypeTable) {
      this.estimatedProjectCostChart = [{
        name: 'CAPEX',
        value: +this.project.costTypeTable[0].budget + +this.project.costTypeTable[1].budget,
      }, {
        name: 'OPEX',
        value: +this.project.costTypeTable[2].budget + +this.project.costTypeTable[3].budget,
      }];
    }

    if (this.project.strategicContribution.name == "Low") {
      this.strategicContributionSlider = 33.33;
    } else if (this.project.strategicContribution.name == "Medium") {
      this.strategicContributionSlider = 66.67;
    } else if (this.project.strategicContribution.name == "High") {
      this.strategicContributionSlider = 100;
    }

    if (this.project.feasibility.name == "Low") {
      this.feasibilitySlider = 33.33;
    } else if (this.project.feasibility.name == "Medium") {
      this.feasibilitySlider = 66.67;
    } else if (this.project.feasibility.name == "High") {
      this.feasibilitySlider = 100;
    }

    if (this.project.profitability.name == "Low") {
      this.profitabilitySlider = 33.33;
    } else if (this.project.profitability.name == "Medium") {
      this.profitabilitySlider = 66.67;
    } else if (this.project.profitability.name == "High") {
      this.profitabilitySlider = 100;
    }

    // setTimeout(() => {
    //   this.estimatedProjectCostChartElem.instance.option('dataSource', this.estimatedProjectCostChart);
    // }, 100);

    // set project name according project id that exist in impactedByDependenciesTable.project
    let projectIds = [];
    for (let i = 0; i < this.project.impactedByDependenciesTable.length; i++) {
      if (this.project.impactedByDependenciesTable[i].project == 0) {
        this.project.impactedByDependenciesTable[i].project = 'None';
      }

      if (this.project.impactedByDependenciesTable[i].project != "" && this.project.impactedByDependenciesTable[i].project != undefined) {
        projectIds.push(this.project.impactedByDependenciesTable[i].project);
      }
    }

    this.socket.listen("getSelectiveProjects").subscribe(response => {
      response.forEach(project => {
        let index = this.project.impactedByDependenciesTable.findIndex(val => val.project == project.id);
        this.project.impactedByDependenciesTable[index].project = project;
      });
    });
    this.socket.emitEvent("getSelectiveProjects", { ids: projectIds });

    if (this.project.projectOrganizationChart != undefined) {
      this.shapes = this.project.projectOrganizationChart;
    }


    if (this.project.trippleConstraint != undefined) {
      this.trippleConstraint = this.project.trippleConstraint;
    }

    this.updateChart();

    let startDate: any = moment(this.project.startDate);
    let endDate: any = moment(this.project.endDate);
    this.dateDiffDays = endDate.diff(startDate, 'days');
    this.dateDiffMonths = endDate.diff(startDate, 'month');
    this.dateDiffYears = endDate.diff(startDate, 'years');

    if (this.project.itPlatform.length > 0) {
      this.project.itPlatform.forEach((itPlatform, idx) => {
        if (!itPlatform.id) {
          let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (idx == 0) {
            this.itPlatform1 = itPlatformObj.name;
          } else if (idx == 1) {
            this.itPlatform2 = itPlatformObj.name;
          } else if (idx == 2) {
            this.itPlatform3 = itPlatformObj.name;
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    // this.diagram = new dhx.Diagram("cont1", { type: "org", defaultShapeType: "svg-card" });
    // this.diagram.data.parse(this.projectOrganizationChartData);
    // this.diagramCont = document.querySelector("#diagram_wrapper1");

    // setTimeout(() => {
    //   $(this.diagramCont).find('.dhx_content_title').css('font-size', '12px');
    //   $(this.diagramCont).find('.dhx_diagram').css('overflow', 'hidden');
    // }, 100);
  }

  updateChart() {
    if (
      this.trippleConstraint.cost < 11 && this.trippleConstraint.cost > 0 && this.trippleConstraint.time > 0 && this.trippleConstraint.time < 11 &&
      this.trippleConstraint.scope > 0 && this.trippleConstraint.scope < 11) {
      this.formObject.radarChartData[0].data[1] = this.trippleConstraint.cost;
      this.formObject.radarChartData[0].data[0] = this.trippleConstraint.time;
      this.formObject.radarChartData[0].data[2] = this.trippleConstraint.scope;
      this.renderChart = false;
      setTimeout(() => {
        this.renderChart = true;
      }, 0);
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Values must be between 1-10."),
        "#fb3c4a",
      );
    }
  }

  // Chart events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  customizePoint(point) {
    if (point.argument == "CAPEX") {
      return {
        color: "rgb(117, 101, 164)"
      }
    } else if (point.argument == "OPEX") {
      return {
        color: "rgb(120, 182, 217)"
      }
    }
  }

  saveComments(obj, elem) {
    if (this.project && this.project.projectOrderId) {
      this.spinner.show();
      this.apiService.put('/projectOrder/' + this.project.projectOrderId, obj).subscribe(response => {
        this.notification("Success", "Comment Updated", "success");
        this.spinner.hide();
        elem.instance.hide();
      });
    } else {
      elem.instance.hide();
      this.notification("Failed", "Save your Order first to add comments", "warning");
    }

  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  download() {
    let imgWidth = 210;
    let pageHeight = 295; //295
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4'); //[200, 800]
    let element = $('#orderPDF');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    let copylinkElem = element.find('#copylink')[0];
    if (copylinkElem) {
      copylinkElem.remove();
    }

    this.showLoading = true;

    //SVG element
    let svgElement = element.find('#mainSVG')[0];
    svgElement.setAttribute("width", svgElement.getBoundingClientRect().width + "");
    svgElement.setAttribute("height", svgElement.getBoundingClientRect().height + "");
    svgElement.style.width = null;
    svgElement.style.height = null;


    html2canvas(element[0], { scale: 2.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position - 1, imgWidth, imgHeight); // + 33 , - 141
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position, imgWidth, imgHeight); //- 30, - 29
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 2, imgWidth, imgHeight); // - 47, - 10
      heightLeft -= pageHeight;

      // position = heightLeft - imgHeight;
      // pdf.addPage();
      // pdf.addImage(image, 'JPEG', 0, position + 4, imgWidth, imgHeight); // - 47, - 10
      // heightLeft -= pageHeight;

      pdf.save('Order Report.pdf');
      globalThis.showLoading = false;
      element.children().children().append(downloadElem);
      if (copylinkElem) {
        element.children().children().append(copylinkElem);
      }
    });
  }

}