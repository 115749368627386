import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-create-program-report',
  templateUrl: './create-program-report.component.html',
  styleUrls: ['./create-program-report.component.scss']
})
export class CreateProgramReportComponent implements OnInit {
  reportObj: any = {
    overallStatusComments: '',
    scopeStatusComments: '',
    costStatusComments: '',
    timeStatusComments: '',
  };
  conf;
  programDetailId;
  isNew = false;
  isPrev = false;
  reportId;
  options = ['Green', 'Yellow', 'Red'];
  riskOptions = ['Low', 'Medium', 'High'];
  programName = '';
  programId;
  isMember: boolean = false;
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  prevStatusReport;
  lastStatusReport;
  overallCommentsLimit: boolean = false;
  scopeCommentsLimit: boolean = false;
  costCommentsLimit: boolean = false;
  timeCommentsLimit: boolean = false;
  helpGuide;
  financialTrend = [];
  isReportingDateValid: boolean = true;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(public utilityService: UtilityService, private apiService: ApiService, private router: Router, private route: ActivatedRoute,
    private authService: AuthService, private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Create Report";

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.financialTrend = [{
      type: this.localize.transform("Original Budget"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Current Budget"),
      value: 0,
    }, {
      type: this.localize.transform("Actual Cost"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Forecast"),
      value: 0,
    },];

    this.reportObj.overallStatus = 'Select One';
    this.reportObj.scopeStatus = 'Select One';
    this.reportObj.costStatus = 'Select One';
    this.reportObj.timeStatus = 'Select One';
    this.reportObj.riskStatus = 'Select One';
    this.reportObj.percentageComplete = 0;

    this.getHelpGuideData();
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    let sub = this.route.params.subscribe(params => {
      this.programId = params.id;
      if (params.current != null && params.current != "null" && params.current != undefined) { //Edit
        this.reportId = params.current;
        this.apiService.get('/programStatusReports/' + params.current).subscribe((reportObj: any) => {
          this.reportObj = reportObj;
          this.programName = reportObj.program.programName;
          this.utilityService.breadcrumbList = ['My Programs', this.programName, 'Details', 'Status Report', 'Edit'];

          if (params.previous != null && params.previous != "null" && params.previous != undefined) {
            this.apiService.get('/programStatusReports/' + params.previous).subscribe((lastStatusReportObj: any) => {
              this.lastStatusReport = JSON.parse(JSON.stringify(lastStatusReportObj));
            });
          }
          this.spinner.hide();
        });
      } else { // New
        this.isNew = true;
        this.isPrev = true;
        this.programDetailId = params.id;

        if (params.previous == null || params.previous == "null" || params.previous == undefined) { // Case: No previous report
          this.apiService.get('/program/' + this.programDetailId).subscribe((response: any) => {
            this.programName = response.programName;
            this.utilityService.breadcrumbList = ['My Programs', this.programName, 'Details', 'Status Report', 'Create'];
            this.spinner.hide();
          });
        } else { // Case: Having previous report
          this.apiService.get('/programStatusReports/' + params.previous).subscribe((reportObj: any) => {
            this.reportObj = reportObj;
            this.lastStatusReport = JSON.parse(JSON.stringify(reportObj));
            delete (this.reportObj.id);
            this.programName = reportObj.program.programName;
            this.utilityService.breadcrumbList = ['My Programs', this.programName, 'Details', 'Status Report', 'Create'];
            this.spinner.hide();
          });
        }
      }
    });
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Program Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  calculateVarianceValues(report) {
    this.conf = <any>(this.authService.getUserObj()).configuration;
  }

  onStatusValueChanged(event) {
    this.calculateOverallStatus(event.value);
  }

  calculateOverallStatus(value) {
    if (this.options.indexOf(value) > this.options.indexOf(this.reportObj.overallStatus)) {
      this.reportObj.overallStatus = value;
    } else {
      //get biggest index
      for (let i = 2; i >= 0; i--) {
        if (this.reportObj.scopeStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        } else if (this.reportObj.timeStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        } else if (this.reportObj.costStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        }
      }
    }
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  submit() {
    if (!this.isReportingDateValid) {
      this.showNotification('Warning', 'Please enter reporting date in correct format (dd.MM.yyyy) first.', 'warning');
      return;
    }
    if (this.reportObj.reportingDate == "" || this.reportObj.reportingDate == null ||
      this.reportObj.reportingDate == undefined) {
      this.showNotification('Warning', 'Please select reporting date first.', 'warning');
      return;
    }
    if (this.reportObj.overallStatus == 'Select One') {
      this.showNotification('Warning', 'Please select overall status first.', 'warning');
      return;
    }
    if (this.reportObj.scopeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select scope status first.', 'warning');
      return;
    }
    if (this.reportObj.costStatus == 'Select One') {
      this.showNotification('Warning', 'Please select cost status first.', 'warning');
      return;
    }
    if (this.reportObj.timeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select time status first.', 'warning');
      return;
    }
    if (this.reportObj.riskStatus == 'Select One') {
      this.showNotification('Warning', 'Please select risk status first.', 'warning');
      return;
    }

    this.reportObj.isSubmitted = true;
    this.reportObj.status = 'Submitted';
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);

    if (this.programDetailId != undefined) {
      this.submitHelper(this.programDetailId);
    } else {
      this.apiService.get('/programStatusReports/' + this.reportId).subscribe((response: any) => {
        this.submitHelper(response.program.id);
      });
    }
  }

  submitHelper(id) {
    this.spinner.show();
    let obj: any = {};
    this.apiService.get('/program/' + id).subscribe((response: any) => {

      if (response.mileStonesValues != undefined) {
        this.reportObj.mileStonesValues = response.mileStonesValues;
      }
      if (response.risks != undefined) {
        this.reportObj.risks = response.risks;
      }
      if (response.measures != undefined) {
        this.reportObj.measures = response.measures;
      }
      if (response.decisions != undefined) {
        this.reportObj.decisions = response.decisions;
      }

      this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete).toFixed(0);

      if (response.isProgramCost) {
        if (response.programCostTable1 != undefined) {
          this.financialTrend[0].value = response.programCostTable1[6].originalBudget;
          this.financialTrend[1].value = response.programCostTable1[6].currentBudget;
          this.financialTrend[2].value = response.programCostTable1[6].actualCost;
          this.financialTrend[3].value = response.programCostTable1[6].forecast;
        }
      }
      else if (response.isAggregatedProgramCost) {
        if (response.aggregatedProgramTable1 != undefined) {
          this.financialTrend[0].value = response.aggregatedProgramTable1[6].originalBudget;
          this.financialTrend[1].value = response.aggregatedProgramTable1[6].currentBudget;
          this.financialTrend[2].value = response.aggregatedProgramTable1[6].actualCost;
          this.financialTrend[3].value = response.aggregatedProgramTable1[6].forecast;
        }
      }

      this.reportObj.financialTrend = this.financialTrend;

      if (this.reportObj.id == undefined && this.isNew == true) {
        this.reportObj.program = this.programDetailId;
        this.apiService.post('/programStatusReports', this.reportObj).subscribe(reportObj => {
          if (obj.id) {
            obj.hasDraftReport = false;
            this.apiService.put('/program/' + obj.id, obj).subscribe(res => {
              this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
              this.spinner.hide();
              this.router.navigate([`program/detail/${this.programDetailId}/overview`]);
            });
          } else {
            this.apiService.put('/program/' + id, {
              hasDraftReport: false,
            }).subscribe(response => {
              this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
              this.spinner.hide();
              this.router.navigate([`program/detail/${this.programDetailId}/overview`]);
            });
          }
        });
      } else {
        this.apiService.put('/programStatusReports/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
          if (obj.id) {
            obj.hasDraftReport = false;
            this.apiService.put('/program/' + obj.id, obj).subscribe(res => {
              this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
              this.spinner.hide();
              this.router.navigate([`program/detail/${this.reportObj.program.id != undefined ? this.reportObj.program.id : this.reportObj.program}/overview`]);
            });
          } else {
            this.apiService.put('/program/' + id, {
              hasDraftReport: false,
            }).subscribe(response => {
              this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
              this.spinner.hide();
              this.router.navigate([`program/detail/${this.reportObj.program.id != undefined ? this.reportObj.program.id : this.reportObj.program}/overview`]);
            });
          }
        });
      }
    });
  }

  save() {
    this.spinner.show();
    this.reportObj.status = 'Draft';
    this.reportObj.isSubmitted = false;
    this.reportObj.program = this.programDetailId;
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);

    let id = this.programDetailId ? this.programDetailId : this.reportObj.programId;
    this.apiService.get('/program/' + id).subscribe((response: any) => {
      if (this.programDetailId != undefined) {
        this.reportObj.programId = this.programDetailId;
      }

      if (response.mileStonesValues != undefined) {
        this.reportObj.mileStonesValues = response.mileStonesValues;
      }
      if (response.risks != undefined) {
        this.reportObj.risks = response.risks;
      }
      if (response.measures != undefined) {
        this.reportObj.measures = response.measures;
      }
      if (response.decisions != undefined) {
        this.reportObj.decisions = response.decisions;
      }

      if (response.isProgramCost) {
        if (response.programCostTable1 != undefined) {
          this.financialTrend[0].value = response.programCostTable1[6].originalBudget;
          this.financialTrend[1].value = response.programCostTable1[6].currentBudget;
          this.financialTrend[2].value = response.programCostTable1[6].actualCost;
          this.financialTrend[3].value = response.programCostTable1[6].forecast;
        }
      } else if (response.isAggregatedProgramCost) {
        if (response.aggregatedProgramTable1 != undefined) {
          this.financialTrend[0].value = response.aggregatedProgramTable1[6].originalBudget;
          this.financialTrend[1].value = response.aggregatedProgramTable1[6].currentBudget;
          this.financialTrend[2].value = response.aggregatedProgramTable1[6].actualCost;
          this.financialTrend[3].value = response.aggregatedProgramTable1[6].forecast;
        }
      }

      this.reportObj.financialTrend = this.financialTrend;

      this.saveHelper();
    });
  }

  saveHelper() {
    if (this.reportObj.id != undefined && this.isNew == false) {
      let id = this.reportObj.programId;
      this.apiService.put('/programStatusReports/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.reportObj.program = id;
        this.apiService.put('/program/' + id, {
          hasDraftReport: true,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Status Report has been saved.'), 'success');
          //this.router.navigate(['program/detail', { programId: id, tabIndex: 1 }]);
          this.spinner.hide();
        });
      });
    } else {
      if (this.isPrev == true) {
        delete (this.reportObj.id);
      }
      this.reportObj.program = this.programDetailId;
      this.apiService.post('/programStatusReports', this.reportObj).subscribe((reportObj: any) => {
        this.reportObj.id = reportObj.id;

        this.apiService.put('/program/' + this.programDetailId, {
          hasDraftReport: true,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Status Report has been saved.'), 'success');
          this.spinner.hide();
          if (this.programDetailId != undefined) {
            //this.router.navigate(['program/detail', { programId: this.programDetailId, tabIndex: 1 }]);
          }
        });
      });
    }
  }

  parseMilestoneValues(response): Object {
    let ms = response.mileStonesValues;
    let obj: any = {};
    obj.reportingDate = new Date(this.reportObj.reportingDate);

    obj.milestone1 = ms.milestone1Completed ? null : ms[`milestone1Date`];
    obj.milestone2 = ms.milestone2Completed ? null : ms[`milestone2Date`];
    obj.milestone3 = ms.milestone3Completed ? null : ms[`milestone3Date`];
    obj.milestone4 = ms.milestone4Completed ? null : ms[`milestone4Date`];
    obj.milestone5 = ms.milestone5Completed ? null : ms[`milestone5Date`];
    obj.milestone6 = ms.milestone6Completed ? null : ms[`milestone6Date`];
    obj.milestone7 = ms.milestone7Completed ? null : ms[`milestone7Date`];
    obj.milestone8 = ms.milestone8Completed ? null : ms[`milestone8Date`];
    obj.milestone9 = ms.milestone9Completed ? null : ms[`milestone9Date`];
    obj.milestone10 = ms.milestone10Completed ? null : ms[`milestone10Date`];

    if (response.milestoneTable != undefined) {
      response.milestoneTable.push(obj);
    } else {
      response.milestoneTable = [obj];
    }
    return response;
  }

  format(value) {
    return value + "%";
  }

  openPreview() {
    this.statusReportPreview = this.reportObj;
    this.prevStatusReport = this.lastStatusReport;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  backToProgramDetail() {
    this.router.navigate([`program/detail/${this.programId}/overview`]);
  }

  confirmation() {

    let msg = 'Changes are not possible once submitted. Are you sure you want to submit the report.';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.submit();
      }
    });
  }

  onKeyOverallStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 128) {
        e.preventDefault();
        this.overallCommentsLimit = true;
      } else {
        this.overallCommentsLimit = false;
      }
    } else {
      this.overallCommentsLimit = false;
    }
  }

  onKeyScopeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.scopeCommentsLimit = true;
      } else {
        this.scopeCommentsLimit = false;
      }
    } else {
      this.scopeCommentsLimit = false;
    }
  }

  onKeyCostStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.costCommentsLimit = true;
      } else {
        this.costCommentsLimit = false;
      }
    } else {
      this.costCommentsLimit = false;
    }
  }

  onKeyTimeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.timeCommentsLimit = true;
      } else {
        this.timeCommentsLimit = false;
      }
    } else {
      this.timeCommentsLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  // checkTextareaLength(e, line) {
  //   let lines = e.target.value.split(/\r*\n/);
  //   if (lines.length > line) {
  //     if (e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
  //       e.preventDefault();
  //     }
  //   }
  // }

  updateMTAGrid(e: any) {
  }


  updateTotalExposure(e) {
  }


  updateMTAGridDeletionCase(e: any) {
  }
}
