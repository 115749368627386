import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramDetailComponent } from './program-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ProgramDetailComponent,
    children: [
      {
        path: '',
        redirectTo: 'overview'
      },
      {
        path: ':id/overview',
        loadChildren: () => import('./program-overview/program-overview.module').then(mod => mod.ProgramOverviewModule)
      },
      {
        path: ':id/statusReport',
        loadChildren: () => import('./program-status-report/program-status-report.module').then(mod => mod.ProgramStatusReportModule)
      },
      {
        path: ':id/budget',
        loadChildren: () => import('./program-budget/program-budget.module').then(mod => mod.ProgramBudgetModule)
      },
      {
        path: ':id/projects',
        loadChildren: () => import('./program-projects-list/program-projects-list.module').then(mod => mod.ProgramProjectsListModule)
      },
      {
        path: ':id/gantt',
        loadChildren: () => import('./program-gantt-view/program-gantt-view.module').then(mod => mod.ProgramGanttViewModule)
      },
      {
        path: ':id/agregatedMilstones',
        loadChildren: () => import('./program-agregated-milstones/program-agregated-milstones.module').then(mod => mod.ProgramAgregatedMilstonesModule)
      },
      {
        path: ':id/agregatedRisks',
        loadChildren: () => import('./program-agregated-risks/program-agregated-risks.module').then(mod => mod.ProgramAgregatedRisksModule)
      }
    ]
  },
  {
    path: 'statusReport/:id/:previous',
    loadChildren: () => import('./create-program-report/create-program-report.module').then(mod => mod.CreateProgramReportModule)
  },
  {
    path: 'statusReport/:id/:previous/:current',
    loadChildren: () => import('./create-program-report/create-program-report.module').then(mod => mod.CreateProgramReportModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramDetailRoutingModule { }
