import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClipboardService } from 'ngx-clipboard';
declare var $: any;

var globalThis;

@Component({
  selector: 'app-create-order-report',
  templateUrl: './create-order-report.component.html',
  styleUrls: ['./create-order-report.component.scss']
})
export class CreateOrderReportComponent implements OnInit {
  reportObj: any = {
    managementSummary: '',
    scopeStatusComments: '',
    costStatusComments: '',
    timeStatusComments: '',
    problemsAndNecessaryDecisions: '',
    reportingDate: '',
    currentBudgetVsForecast: '',
  };
  conf;
  smallOrderId;
  isNew = false;
  isPrev = false;
  reportId;
  options = ['Green', 'Yellow', 'Red'];
  riskOptions = ['Low', 'Medium', 'High'];
  smallOrderObj: any = {
    startDate: '',
    endDate: '',
    forecastEndDate: ''
  };
  totalExposure = 0;
  totalTime;
  timeCompleted;
  endDateVSPlannedEndDate;
  currentBudgetVSOriginalBudget: any = 0;;
  currentBudgetVSActualCost;
  currentBudgetVSForecast;
  riskExposureVsCurrentBudget: any = 0;
  statusReportPreview;
  prevStatusReport;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  lastStatusReport;
  managementSummaryLimit: boolean = false;
  scopeCommentsLimit: boolean = false;
  costCommentsLimit: boolean = false;
  timeCommentsLimit: boolean = false;
  invalidChars = ["-", "+", "e",];
  helpGuide;
  public minDate: any;
  isReportingDateValid: boolean = true;
  isForecastEndDateValid: boolean = true;
  actualCostImported;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute,
    public authService: AuthService, private utilityService: UtilityService, private localize: I18nPipe,
    private spinner: NgxSpinnerService, private clipboardService: ClipboardService) {
    this.utilityService.breadcrumb = "Create Report";

    globalThis = this;
    this.reportObj.overallStatus = 'Select One';
    this.reportObj.scopeStatus = 'Select One';
    this.reportObj.costStatus = 'Select One';
    this.reportObj.timeStatus = 'Select One';
    this.reportObj.riskStatus = 'Select One';
    this.reportObj.percentageComplete = 0;
    this.reportObj.reportingDate = '';

    this.smallOrderObj.costTypeTable = [
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 0,
        group: 'CAPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 1,
        group: 'CAPEX'
      },
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 2,
        group: 'OPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 3,
        group: 'OPEX'
      },
      {
        costTypes: "Revenues",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 4,
        group: 'Sonstiges'
      },
      {
        costTypes: "Reserves",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 5,
        group: 'Sonstiges'
      },
      {
        costTypes: "Total",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 6,
        group: 'Sonstiges'
      },];
  }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    let sub = this.route.params.subscribe(params => {
      if (params.current != null && params.current != "null" && params.current != undefined) { //Edit
        this.smallOrderId = params.id;
        this.reportId = params.current;

        if (params.previous != null && params.previous != "null" && params.previous != undefined) {
          this.apiService.get('/smallOrder/statusReport/' + params.id + '/' + params.previous + '/' + params.current).subscribe((response: any) => {
            this.reportObj = response.currentStatusReport;
            this.smallOrderObj = response.smallOrder;
            this.utilityService.breadcrumbList = ['My Orders', this.smallOrderObj.name, 'Status Report', 'Edit'];

            this.actualCostImported = this.smallOrderObj.importedExcelFinancials;

            this.calculateRiskExposure();
            this.calculateVarianceValues(this.smallOrderObj);

            this.lastStatusReport = JSON.parse(JSON.stringify(response.prevStatusReport));
            this.minDate = moment(this.lastStatusReport.reportingDate).add(1, 'days');
            this.spinner.hide();
          });
        } else {
          this.apiService.get('/smallOrder/statusReport/' + params.id + '/' + params.current).subscribe((response: any) => {
            this.reportObj = response.statusReport;
            this.smallOrderObj = response.smallOrder;
            this.utilityService.breadcrumbList = ['My Orders', this.smallOrderObj.name, 'Status Report', 'Edit'];

            this.actualCostImported = this.smallOrderObj.importedExcelFinancials;
            
            this.calculateRiskExposure();
            this.calculateVarianceValues(this.smallOrderObj);
            this.spinner.hide();
          });
        }
      } else { // New
        this.isNew = true;
        this.isPrev = true;
        this.smallOrderId = params.id;

        if (params.previous == null || params.previous == "null" || params.previous == undefined) { // Case: No previous report
          this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe((response: any) => {
            this.smallOrderObj = response;
            this.utilityService.breadcrumbList = ['My Orders', this.smallOrderObj.name, 'Status Report', 'Create'];

            this.actualCostImported = this.smallOrderObj.importedExcelFinancials;

            //Update Total Exposure
            if (this.smallOrderObj.risks) {
              this.smallOrderObj.risks.forEach(val => {
                this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
              });
            }

            this.calculateVarianceValues(this.smallOrderObj);
            this.riskExposureVsCurrentBudget = ((this.totalExposure / this.smallOrderObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';

            this.spinner.hide();
          });
        } else { // Case: Having previous report
          this.apiService.get('/smallOrder/statusReport/' + params.id + '/' + params.previous).subscribe((response: any) => {
            this.reportObj = response.statusReport;
            this.lastStatusReport = JSON.parse(JSON.stringify(response.statusReport));
            delete (this.reportObj.id);
            this.minDate = moment(this.lastStatusReport.reportingDate).add(1, 'days');
            this.reportObj.reportingDate = moment(this.reportObj.reportingDate).add(1, 'days');

            this.smallOrderObj = response.smallOrder;
            this.utilityService.breadcrumbList = ['My Orders', this.smallOrderObj.name, 'Status Report', 'Create'];

            this.actualCostImported = this.smallOrderObj.importedExcelFinancials;

            //Update Total Exposure
            if (this.smallOrderObj.risks) {
              this.smallOrderObj.risks.forEach(val => {
                this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
              });
              this.riskExposureVsCurrentBudget = ((this.totalExposure / this.smallOrderObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
            }

            this.calculateVarianceValues(this.smallOrderObj);
            this.spinner.hide();
          });
        }
      }
    });
  }

  importedCostsValueCalculator(field) {
    let one: any = globalThis.actualCostImported.cost[0][field] * 1;
    let two: any = globalThis.actualCostImported.cost[1][field] * 1;
    let three: any = globalThis.actualCostImported.cost[2][field] * 1;
    let four: any = globalThis.actualCostImported.cost[3][field] * 1;
    let five: any = globalThis.actualCostImported.cost[4][field] * 1;
    let six: any = globalThis.actualCostImported.cost[5][field] * 1;

    let total = one + two + three + four + five + six;
    globalThis.actualCostImported.cost[6][field] = total.toFixed(2);;
    return total.toFixed(2);;
  }

  calculateImportedActualCostValue(e) {
     if (e.costType == "Total") {
      globalThis.actualCostImported.cost[6].cost = globalThis.importedCostsValueCalculator('cost');
      return globalThis.actualCostImported.cost[6].cost;
    } else {
      return e.cost ? e.cost : '';
    }
  }

  onCellPreparedImportedFinancials(e) {
    if (e.columnIndex > 1) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onCellDblClick(e){
    this.clipboardService.copyFromContent(e.value);
    this.showNotification('Success','Value Copied!', 'success')
  }

  calculateVarianceValues(report) {
    this.conf = <any>(this.authService.getUserObj()).configuration;
    this.smallOrderObj.currentBudgetVSForecast = ((this.smallOrderObj.costTypeTable[6].forecast - this.smallOrderObj.costTypeTable[6].currentBudget) / this.smallOrderObj.costTypeTable[6].currentBudget * 100).toFixed(2);
    let costVariance = parseFloat(this.smallOrderObj.currentBudgetVSForecast);

    if(this.conf.statusSetting) {
      if (costVariance >= 0) {
        if (costVariance <= this.conf.statusSetting.costStatus.positive.green) {
          this.reportObj.costStatus = 'Green';
        } else if (costVariance > this.conf.statusSetting.costStatus.positive.green && costVariance <= this.conf.statusSetting.costStatus.positive.yellow) {
          this.reportObj.costStatus = 'Yellow';
        } else if (costVariance > this.conf.statusSetting.costStatus.positive.yellow) { //&& costVariance <= this.conf.statusSetting.costStatus.positive.red) {
          this.reportObj.costStatus = 'Red';
        }
      } else if (costVariance < 0) {
        if (costVariance >= this.conf.statusSetting.costStatus.negative.green * -1) {
          this.reportObj.costStatus = 'Green';
        } else if (costVariance < this.conf.statusSetting.costStatus.negative.green * -1 && costVariance >= this.conf.statusSetting.costStatus.negative.yellow * -1) {
          this.reportObj.costStatus = 'Yellow';
        } else if (costVariance < this.conf.statusSetting.costStatus.negative.yellow * -1) { // && costVariance >= this.conf.statusSetting.costStatus.negative.red * -1) {
          this.reportObj.costStatus = 'Red';
        }
      }
    }

    this.calculateTimeStatus();
    this.calculateOverallStatus();
    this.calculateRiskStatus();
  }

  calculateTimeStatus() {
    let timeVariance = parseFloat(this.smallOrderObj.plannedDateVSForecastDate);

    if(this.conf.statusSetting) { 
      if (timeVariance >= 0) {
        if (timeVariance <= this.conf.statusSetting.timeStatus.positive.green) {
          this.reportObj.timeStatus = 'Green';
        } else if (timeVariance > this.conf.statusSetting.timeStatus.positive.green && timeVariance <= this.conf.statusSetting.timeStatus.positive.yellow) {
          this.reportObj.timeStatus = 'Yellow';
        } else if (timeVariance > this.conf.statusSetting.timeStatus.positive.yellow) { //&& timeVariance <= this.conf.statusSetting.timeStatus.positive.red) {
          this.reportObj.timeStatus = 'Red';
        }
      } else if (timeVariance < 0) {
        if (timeVariance >= this.conf.statusSetting.timeStatus.negative.green * -1) {
          this.reportObj.timeStatus = 'Green';
        } else if (timeVariance < this.conf.statusSetting.timeStatus.negative.green * -1 && timeVariance >= this.conf.statusSetting.timeStatus.negative.yellow * -1) {
          this.reportObj.timeStatus = 'Yellow';
        } else if (timeVariance < this.conf.statusSetting.timeStatus.negative.yellow * -1) { // && timeVariance >= this.conf.statusSetting.timeStatus.negative.red) {
          this.reportObj.timeStatus = 'Red';
        }
      }
    }
  }

  calculateOverallStatus() {
    if (this.conf.statusSetting != undefined) {
      if (this.conf.statusSetting.overallStatus == 'Follow the least favourable') {
        for (let i = 2; i >= 0; i--) {
          if (this.reportObj.scopeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.timeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.costStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.riskStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          }
        }
      } else if (this.conf.statusSetting.overallStatus == 'Follow the most favaourable') {
        for (let i = 0; i < 3; i++) {
          if (this.reportObj.scopeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.timeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.costStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.riskStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          }
        }
      }
    }
  }

  calculateRiskStatus() {
    let riskVariance = parseFloat(this.riskExposureVsCurrentBudget);

    if(this.conf.statusSetting) {
      if (riskVariance <= this.conf.statusSetting.riskStatus.low) {
        this.reportObj.riskStatus = 'Low';
      } else if (riskVariance > this.conf.statusSetting.riskStatus.low && riskVariance <= this.conf.statusSetting.riskStatus.medium) {
        this.reportObj.riskStatus = 'Medium';
      } else if (riskVariance > this.conf.statusSetting.riskStatus.medium) {
        this.reportObj.riskStatus = 'High';
      }
    }
  }

  submit() {
    if (!this.isReportingDateValid) {
      this.showNotification('Warning', 'Please enter reporting date in correct format (dd.MM.yyyy) first.', 'warning');
      return;
    }
    if (this.reportObj.reportingDate == "" || this.reportObj.reportingDate == null ||
      this.reportObj.reportingDate == undefined) {
      this.showNotification('Warning', 'Please select reporting date first.', 'warning');
      return;
    }
    if (this.reportObj.scopeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select scope status first.', 'warning');
      return;
    }
    if (!this.isForecastEndDateValid) {
      this.showNotification('Warning', 'Please enter forecast end date in correct format (dd.MM.yyyy) first.', 'warning');
      return;
    }
    if (this.smallOrderObj.forecastEndDate == "" || this.smallOrderObj.forecastEndDate == null ||
      this.smallOrderObj.forecastEndDate == undefined) {
      this.showNotification('Warning', 'Please select forecast end date first.', 'warning');
      return;
    }
    if (this.reportObj.managementSummary == "" || this.reportObj.managementSummary == undefined ||
      this.reportObj.managementSummary == null) {
      this.showNotification('Warning', 'Please enter management summary first.', 'warning');
      return;
    }

    this.spinner.show();
    this.smallOrderObj.hasDraftReport = false;
    this.apiService.put('/smallOrder/' + this.smallOrderId, this.smallOrderObj).subscribe(response => {
      if (this.reportObj.scopeStatus != 'Select One' && this.reportObj.riskStatus != 'Select One') {
        this.reportObj.isSubmitted = true;
        this.reportObj.status = 'Submitted';
        this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);
        this.calculateVarianceValues(this.smallOrderObj);

        this.submitHelper(response);
      } else {
        this.showNotification('Error', 'Please fill in all the mandatory fields.', 'warning');
      }
    });
  }

  submitHelper(response) {
    this.reportObj.mileStonesValues = response.mileStonesValues;
    this.reportObj.risks = response.risks;
    this.reportObj.measures = response.measures;
    this.reportObj.decisions = response.decisions;
    this.reportObj.totalExposure = this.totalExposure;
    this.reportObj.costTypeTable = response.costTypeTable;
    this.reportObj.startDate = response.startDate;
    this.reportObj.endDate = response.endDate;
    this.reportObj.orderManager = response.orderManager.id;
    this.reportObj.currentBudgetVSForecast = this.currentBudgetVSForecast;
    this.reportObj.plannedDateVSForecastDate = this.smallOrderObj.plannedDateVSForecastDate;
    this.reportObj.riskExposureVsCurrentBudget = this.riskExposureVsCurrentBudget;

    if (response.businessArea != undefined) {
      this.reportObj.businessArea = response.businessArea.name;
    }
    if (response.strategicContribution != undefined) {
      this.reportObj.strategicContribution = response.strategicContribution.name;
    }

    if (response.feasibility != undefined) {
      this.reportObj.feasibility = response.feasibility.name;
    }
    if (response.profitability != undefined) {
      this.reportObj.profitability = response.profitability.name;
    }
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete).toFixed(0);
    this.reportObj.submittedDate = new Date().toISOString();

    if (this.reportObj.id == undefined && this.isNew == true) {
      this.reportObj.smallOrder = this.smallOrderId;
      this.apiService.post('/smallOrderStatusReport', this.reportObj).subscribe(reportObj => {
        this.showNotification('Submit Success', 'Status Report has been created.', 'success');
        this.spinner.hide();
        this.router.navigate([`smallOrder/${this.smallOrderId}/statusReport`]);
      });
    } else {
      this.reportObj.smallOrder = this.smallOrderId;
      this.apiService.put('/smallOrderStatusReport/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.showNotification('Submit Success', 'Status Report has been created.', 'success');
        this.spinner.hide();
        this.router.navigate([`smallOrder/${this.smallOrderId}/statusReport`]);
      });
    }
  }

  saveReport() {
    this.spinner.show();
    this.smallOrderObj.hasDraftReport = true;
    this.apiService.put('/smallOrder/' + this.smallOrderId, this.smallOrderObj).subscribe(response => {
      this.save(response);
    });
  }

  save(response) {
    this.reportObj.status = 'Draft';
    this.reportObj.isSubmitted = false;
    this.reportObj.smallOrder = this.smallOrderId;
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);
    this.calculateVarianceValues(this.smallOrderObj);
    this.calculateRiskExposure();

    this.reportObj.mileStonesValues = response.mileStonesValues;
    this.reportObj.risks = response.risks;
    this.reportObj.measures = response.measures;
    this.reportObj.decisions = response.decisions;
    this.reportObj.costTypeTable = response.costTypeTable;
    this.reportObj.startDate = response.startDate;
    this.reportObj.endDate = response.endDate;
    this.reportObj.totalExposure = this.totalExposure;
    this.reportObj.orderManager = response.orderManager.id;
    this.reportObj.currentBudgetVSForecast = this.currentBudgetVSForecast;
    this.reportObj.plannedDateVSForecastDate = this.smallOrderObj.plannedDateVSForecastDate;
    this.reportObj.riskExposureVsCurrentBudget = this.riskExposureVsCurrentBudget;

    if (response.businessArea != undefined) {
      this.reportObj.businessArea = response.businessArea.name;
    }
    if (response.strategicContribution != undefined) {
      this.reportObj.strategicContribution = response.strategicContribution.name;
    }
    this.reportObj.projectPhase = response.projectPhase;
    if (response.feasibility != undefined) {
      this.reportObj.feasibility = response.feasibility.name;
    }
    if (response.profitability != undefined) {
      this.reportObj.profitability = response.profitability.name;
    }
    this.saveHelper();
  }

  saveHelper() {
    if (this.reportObj.id != undefined && this.isNew == false) {
      this.apiService.put('/smallOrderStatusReport/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.showNotification('Success', 'Status Report has been saved.', 'success');
        this.spinner.hide();
      });
    } else {
      if (this.isPrev == true) {
        delete (this.reportObj.id);
        delete (this.reportObj.submittedDate);
      }
      this.reportObj.smallOrder = this.smallOrderId;
      this.apiService.post('/smallOrderStatusReport', this.reportObj).subscribe((reportObj: any) => {
        this.reportObj.id = reportObj.id;
        this.showNotification('Success', 'Status Report has been saved.', 'success');
        this.spinner.hide();
      });
    }
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  parseMilestoneValues(response): Object {
    let ms = JSON.parse(JSON.stringify(response.mileStonesValues));

    if (this.lastStatusReport != undefined) {
      if (this.lastStatusReport.mileStonesValues != undefined) {
        if (this.lastStatusReport.mileStonesValues.length > 0) {
          for (let i = 0; i < this.lastStatusReport.mileStonesValues.length; i++) {
            let objArr = ms.find(val => val.id == this.lastStatusReport.mileStonesValues[i].id);
            let index = ms.indexOf(objArr);
            if (this.lastStatusReport.mileStonesValues[i].status == 'Completed' &&
              objArr.id == this.lastStatusReport.mileStonesValues[i].id) {
              ms.splice(index, 1);
            }
          }
        }
      }
    }

    let obj: any = {};
    obj.reportingDate = new Date(this.reportObj.reportingDate);

    let index = 0;
    ms.map(mile => {
      // if (mile.report == true) {
      obj['milestone' + (mile.id)] = mile.dueDate;     // index + 1
      index++;
      // }
    });

    if (response.milestoneTable != undefined) {
      response.milestoneTable.push(obj);
    } else {
      response.milestoneTable = [obj];
    }
    return response;
  }

  format(value) {
    return value + "%";
  }

  confirmation() {

    let msg = 'Changes are not possible once submitted. Are you sure you want to submit the report.';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.submit();
      }
    });
  }

  onCellPreparedFinancials(e) {
    if (e.columnIndex > 3) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.columnIndex == 2 && e.rowIndex == 8) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingCostTypeTable(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
      // e.editorOptions.placeholder = "0.00 CHF";

      if (e.row.rowIndex == 8 && e.caption == 'Actual Cost') {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateActualCostValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.actualCost ? Math.abs(e.actualCost) : '';
    } else if (e.costTypes == "Total") {
      globalThis.smallOrderObj.costTypeTable[6].actualCost = globalThis.projectCostsValueCalculator('actualCost');
      globalThis.calculateVariance();
      return globalThis.smallOrderObj.costTypeTable[6].actualCost;
    } else {
      return e.actualCost ? e.actualCost : '';
    }
  }

  calculateForecastValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.smallOrderObj.costTypeTable[6].forecast = globalThis.projectCostsValueCalculator('forecast');
      return globalThis.smallOrderObj.costTypeTable[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  calculateCurrentBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.smallOrderObj.costTypeTable[6].currentBudget = globalThis.projectCostsValueCalculator('currentBudget');
      return globalThis.smallOrderObj.costTypeTable[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  calculateOriginalBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.smallOrderObj.costTypeTable[6].originalBudget = globalThis.projectCostsValueCalculator('originalBudget');
      return globalThis.smallOrderObj.costTypeTable[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  onRowPreparedFinancials(e) {
    if (e.rowIndex > 8) {
      e.rowElement.style.backgroundColor = "#ddd";
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.smallOrderObj.costTypeTable[0][field] * 1;
    let two: any = globalThis.smallOrderObj.costTypeTable[1][field] * 1;
    let three: any = globalThis.smallOrderObj.costTypeTable[2][field] * 1;
    let four: any = globalThis.smallOrderObj.costTypeTable[3][field] * 1;
    let five: any = globalThis.smallOrderObj.costTypeTable[4][field] * 1;
    let six: any = globalThis.smallOrderObj.costTypeTable[5][field] * 1;
    let total = one + two + three + four - five + six;
    globalThis.smallOrderObj.costTypeTable[6][field] = total;
    return total;
  }

  calculateVariance() {
    if (this.smallOrderObj.costTypeTable[6].originalBudget * 1 != 0) {
      this.smallOrderObj.currentBudgetVSOriginalBudget = (((this.smallOrderObj.costTypeTable[6].currentBudget * 1) - (this.smallOrderObj.costTypeTable[6].originalBudget * 1)) / (this.smallOrderObj.costTypeTable[6].originalBudget * 1) * 100).toFixed(2);
    }
    if (this.smallOrderObj.costTypeTable[6].currentBudget * 1 != 0) {
      this.smallOrderObj.currentBudgetVSActualCost = (((this.smallOrderObj.costTypeTable[6].currentBudget * 1) - (this.smallOrderObj.costTypeTable[6].actualCost * 1)) / (this.smallOrderObj.costTypeTable[6].currentBudget * 1) * 100).toFixed(2);
      this.smallOrderObj.currentBudgetVSForecast = (((this.smallOrderObj.costTypeTable[6].forecast * 1) - (this.smallOrderObj.costTypeTable[6].currentBudget * 1)) / (this.smallOrderObj.costTypeTable[6].currentBudget * 1) * 100).toFixed(2);
    }
    this.currentBudgetVSOriginalBudget = this.smallOrderObj.currentBudgetVSOriginalBudget ? this.smallOrderObj.currentBudgetVSOriginalBudget + '%' : '0%';
    this.currentBudgetVSActualCost = this.smallOrderObj.currentBudgetVSActualCost ? this.smallOrderObj.currentBudgetVSActualCost + '%' : '0%';
    this.currentBudgetVSForecast = this.smallOrderObj.currentBudgetVSForecast ? this.smallOrderObj.currentBudgetVSForecast + '%' : '0%';
  }

  forecastValueChange(e) {
    this.calculateDatePercentages();
    this.calculateTimeStatus();
  }

  calculateDatePercentages() {
    let endDate: any = moment(this.smallOrderObj.endDate);
    let startDate: any = moment(this.smallOrderObj.startDate);
    let plannedDate: any = moment(this.smallOrderObj.plannedEndDate);
    let forecastDate: any = moment(this.smallOrderObj.forecastEndDate);
    let now = moment(Date.now());

    this.totalTime = plannedDate.diff(startDate, 'days') + ' days';
    this.timeCompleted = Math.abs(100 - (Math.round((plannedDate.diff(startDate, 'days') - now.diff(startDate, 'days')) / plannedDate.diff(startDate, 'days') * 100))) + '%';
    this.endDateVSPlannedEndDate = Math.round((plannedDate.diff(startDate, 'days') - endDate.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100) + '%';
    if (this.smallOrderObj.forecastEndDate != undefined) {
      this.smallOrderObj.plannedDateVSForecastDate = Math.round((plannedDate.diff(forecastDate, 'days') / plannedDate.diff(startDate, 'days')) * -100) + '%';
    }
  }

  updateTotalExposure(e) {
    this.smallOrderObj.risks = e.risks;
    this.totalExposure = 0;

    if (this.smallOrderObj.risks) {
      this.smallOrderObj.risks.forEach(val => {
        this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
      });
    }

    this.riskExposureVsCurrentBudget = ((this.totalExposure / this.smallOrderObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
  }

  updateMTAGrid(e) {
    this.smallOrderObj.mileStonesValues = e.mileStonesValues;
  }

  updateMeasureGrid(e) {
    this.smallOrderObj.measures = e.measures;
  }

  updateDecisionGrid(e) {
    this.smallOrderObj.decisions = e.decisions;
  }

  updateMTAGridDeletionCase(e: any) {
    this.smallOrderObj.mileStonesValues = e.mileStonesValues;
  }

  openPreview() {
    this.statusReportPreview = this.reportObj;
    this.prevStatusReport = this.lastStatusReport;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  calculateRiskExposure() {
    this.totalExposure = 0;
    if (this.smallOrderObj.risks) {
      this.smallOrderObj.risks.forEach(val => {
        this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
      });
    }
    this.riskExposureVsCurrentBudget = ((this.totalExposure / this.smallOrderObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
  }

  backToProjectDetail() {
    this.router.navigate([`smallOrder/${this.smallOrderId}/details/statusReport`]);
  }

  onKeyManagementSummaryInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 68) {
        e.preventDefault();
        this.managementSummaryLimit = true;
      } else {
        this.managementSummaryLimit = false;
      }
    } else {
      this.managementSummaryLimit = false;
    }
  }

  onKeyScopeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.scopeCommentsLimit = true;
      } else {
        this.scopeCommentsLimit = false;
      }
    } else {
      this.scopeCommentsLimit = false;
    }
  }

  onKeyCostStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.costCommentsLimit = true;
      } else {
        this.costCommentsLimit = false;
      }
    } else {
      this.costCommentsLimit = false;
    }
  }

  onKeyTimeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.timeCommentsLimit = true;
      } else {
        this.timeCommentsLimit = false;
      }
    } else {
      this.timeCommentsLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  onKeyDown(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }
}
