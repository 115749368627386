import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from './../../services/api.service';
import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';
declare var $: any;
var globalThis;


@Component({
  selector: 'app-project-closing-report',
  templateUrl: './project-closing-report.component.html',
  styleUrls: ['./project-closing-report.component.scss']
})
export class ProjectClosingReportComponent implements OnInit, OnDestroy {
  projSponsorEmail;
  projFicoEmail;
  submitFlag = false;
  eventType = '';
  formObject: any = {
    projectManager: {
      email: ''
    },
    pmoOfficer: {
      id: ''
    },
    projectSponsor: {
      id: ''
    },
    projectFico: {
      id: ''
    },
    lessonsLearned: []
  };
  pendingForm = {
    pendings: '',
    description: ''
  };
  public steps = [
    {
      key: "step1",
      title: "Acheivement of Objectives",
      valid: false,
      checked: false,
      submitted: false
    },
    {
      key: "step2",
      title: "Use",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step3",
      title: "Deviations",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step4",
      title: "Lessons Learned",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step5",
      title: "Pending",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step6",
      title: "Submit",
      valid: true,
      checked: false,
      submitted: false
    },
  ];
  public activeStep = this.steps[0];
  PMOsList = [];
  usersList = [];
  public saveFlag = false;
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  attachment1;
  attachment2;
  showNotification = false;
  projectId;
  projectObj: any;
  closingReportId;
  companyNameOptions;
  projectGoalsRadioGroupItems = [];
  public modalRef: BsModalRef;
  lessonsForm: any = {
    lessonCategory: {
      id: ''
    },
    description: '',
    report: true
  };
  backupForm;
  lessonCategorySelectOptions: any = [];
  lessonTypeSelectOptions: any = [];
  isEdit = false;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  reportRelevantlessonCount = 0;
  @ViewChild("popUpBenefits", { static: false }) popUpBenefits;
  isEditBenefit = false;
  popUpConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public benefitForm: any = {
    description: '',
    report: false
  };
  benefitTypeSelectOptions: any = [];
  reportRelevantBenefitCount = 0;
  backupBenefitForm;
  showClosingPreview: boolean = false;
  @ViewChild("lgClosingModal", { static: false }) lgClosingModal;
  @ViewChild("pendingFormTemplate", { static: false }) pendingFormTemplate;
  closingReportObj: any = [];
  public questionOptions;
  closingReportQuestions: any = [{}, {}, {}, {}, {}, {}];
  closingReportHelpGuide: any = [];
  @ViewChild('form', { static: false }) form: NgForm;
  isFormSaveSubmit: boolean = false;
  reasonForDeviationGoalsLimit: boolean = false;
  reasonForDeviationQualitativeLimit: boolean = false;
  reasonForDeviationCostLimit: boolean = false;
  additionalInfoLimit: boolean = false;
  isTeamProject = false;
  projectSubportfolio;
  isStepValidated = [true, true, true, true, true, true];
  isSavedPressed: boolean = false;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';
  groups = [];
  groupsHolder = [];
  portfolio;
  questionAnswersBackup = [];

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute,
    private utilityService: UtilityService, private modalService: BsModalService, private localize: I18nPipe,
    private authService: AuthService, private spinner: NgxSpinnerService, private socketService: SocketService) {
    this.utilityService.breadcrumb = "Closing Report";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    globalThis = this;

    this.formObject = new ClosingReport();
    this.formObject.reasonForDeviationBenefitsDeviations = '';
    this.formObject.projectManager = {
      email: ''
    };
    this.formObject.pmoOfficer = {
      id: ''
    };
    this.formObject.projectSponsor = {
      id: ''
    };
    this.formObject.projectFico = {
      id: ''
    };
    this.formObject.pmoFormalControl = "PMO";
    this.formObject.projectSponsorApproval = "Sponsor";
    this.formObject.projectFicoApproval = "FIKO";
    this.formObject.lessonsLearned = [];
    this.formObject.question = ['', '', '', '', '', ''];
    this.formObject.quantitativeBenefit = [];
    this.projectGoalsRadioGroupItems = [
      { text: this.localize.transform("Fullfilled"), id: 1 },
      { text: this.localize.transform("Partially Fullfilled"), id: 2 },
      { text: this.localize.transform("Not Fullfilled"), id: 3 },
    ];

    this.socketService.listen('questionsByFormName').subscribe(response => {
      for (let i = 0; i < response.questions.length; i++) {
        if (response.questions[i].department == 'Compliance') {
          this.closingReportQuestions[0] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling') {
          this.closingReportQuestions[1] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 1) {
          this.closingReportQuestions[2] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 2) {
          this.closingReportQuestions[3] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement' && response.questions[i].count == 1) {
          this.closingReportQuestions[4] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement' && response.questions[i].count == 2) {
          this.closingReportQuestions[5] = response.questions[i];
        }
      }
    });

    this.socketService.listen('helpGuideByFormName').subscribe(response => {
      this.closingReportHelpGuide = response.fields;
    });

    this.socketService.listen('fetchClosingReport').subscribe(response => {
      this.projectObj = response.project;

      this.fetchPortfolio(response.detail.portfolio.id);

      if (this.closingReportId != 0) {
        this.utilityService.breadcrumbList = ['My Projects', 'Edit Closing Report'];
        this.formObject = response.closingReport;
        this.formObject.projectName = this.projectObj.projectName;
        this.formObject.projectManager = response.project.user;

        this.questionAnswersBackup = response.closingReport.portfolioClosingReportQuestion;

        setTimeout(() => {
          this.formObject.portfolioClosingReportQuestion = this.questionAnswersBackup;
        }, 1000)

        this.getProjectDetailData(response.detail);

        this.reportRelevantlessonCount = response.closingReport.reportRelevantlessonCount;

        let attach1: any = document.getElementById("attact1-span");
        let attach2: any = document.getElementById("attact2-span");
        if (
          this.formObject.attachments[0].originalName != undefined) {
          attach1.value = this.formObject.attachments[0].originalName;
          this.attachment1 = this.formObject.attachments[0];
        }
        if (this.formObject.attachments[1].originalName != undefined) {
          attach2.value = this.formObject.attachments[1].originalName;
          this.attachment2 = this.formObject.attachments[1];
        }
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }

        if (response.detail && response.detail.subPortfolio) {
          this.projectSubportfolio = response.detail.subPortfolio;
        }

        // response.detail.costTypeTable.map((val, index) => {
        //   this.formObject.costDeviations[index].should = val.currentBudget;
        //   this.formObject.costDeviations[index].is = val.actualCost;
        // });

        this.spinner.hide();
      } else {
        this.utilityService.breadcrumbList = ['My Projects', 'Create Closing Report'];
        this.formObject.projectName = this.projectObj.projectName;
        this.formObject.projectManager = response.detail.projectManager;
        this.formObject.goals = response.detail.goals;
        this.formObject.deliverables = response.detail.deliverables;
        this.formObject.lessonsLearned = response.detail.lessonsLearned;
        this.formObject.quantitativeBenefit = response.detail.quantitativeBenefit;
        this.formObject.kpisTable = response.detail.kpisTable;
        delete (this.formObject.kpisTable[1]);
        delete (this.formObject.kpisTable[4]);
        this.formObject.plannedEndDate = response.detail.plannedEndDate;
        this.formObject.pmoOfficer = response.detail.pmoOfficer;
        this.formObject.projectSponsor = response.detail.projectSponsor;
        this.formObject.projectFico = response.detail.projectFico;
        this.formObject.currency = response.detail.currency;
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }

        if (response.detail && response.detail.subPortfolio) {
          this.projectSubportfolio = response.detail.subPortfolio;
        }

        response.detail.costTypeTable.map((val, index) => {
          this.formObject.costDeviations[index].should = val.currentBudget;
          this.formObject.costDeviations[index].is = val.actualCost;
        });
        this.spinner.hide();
      }
    });

    this.socketService.listen('fetchReport').subscribe(reportResponse => {
      this.formObject.projectManager = reportResponse.projectManager;

      this.getProjectDetailData(reportResponse);

      let obj = {
        status: "Draft",
        docType: "Closing Report",
        isSubmitted: false,
        isApproved: false,
        workflowStatus: "Closing Report has not been submitted yet.",
        ...this.formObject,
        reportRelevantlessonCount: this.reportRelevantlessonCount,
        endDateOnSubmit: new Date().toISOString(),
        deliverables: this.formObject.deliverables,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : ""
        ],
        project: this.projectId
      };
      this.formObject.attachments = obj.attachments;

      if (this.closingReportId != 0) {
        this.apiService.put("/closingReport/" + this.closingReportId, obj).subscribe(projectResponse => {
          this.notificationBox("Success", "Closing Report has been saved.", "success");
          // this.projectObj = projectResponse;
          this.saveFlag = false;
          this.isFormSaveSubmit = true;
          this.spinner.hide();
        });
      } else {
        this.apiService.post("/closingReport", obj).subscribe((projectResponse: any) => {
          this.saveFlag = false;
          this.isFormSaveSubmit = true;
          this.closingReportId = projectResponse.id;
          this.spinner.hide();
          this.notificationBox("Success", "Closing Report has been saved.", "success");
        });
      }
    });

    this.getClosingReportQuestions();
    this.getClosingReportHelpGuide();
    this.getProjectInfo();
    this.fetchPMOs();
    this.fetchUsers();
  }

  fetchPortfolio(id) {
    this.apiService.get('/portfolio/' + id).subscribe((portfolio: any) => {
      this.portfolio = portfolio;
      this.formObject.portfolioClosingReportQuestion = [];
      this.groups = [];
      this.groupsHolder = [];
      if (portfolio.questions && portfolio.questions.length > 0) {
        portfolio.questions.forEach((element, index) => {
          this.formObject.portfolioClosingReportQuestion[index] = '';
          this.groupsHolder[element.group] = element.email;
        });
        this.groups = Object.keys(this.groupsHolder)
      }
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.lessonCategorySelectOptions = this.utilityService.lessonsCategoryOptions;
    this.lessonTypeSelectOptions = ["Positive", "Negative"];
    this.benefitTypeSelectOptions = ['Quantitative', 'Qualitative'];
    this.questionOptions = ["Yes", "No"];
    this.formObject.costDeviations = [
      {
        costElements: "External Costs",
        should: "",
        is: '',
        group: "CAPEX",
      },
      {
        costElements: "Internal Costs",
        should: "",
        is: '',
        group: "CAPEX",
      },
      {
        costElements: "External Costs",
        should: "",
        is: '',
        group: "OPEX",
      },
      {
        costElements: "Internal Costs",
        should: "",
        is: '',
        group: "OPEX",
      },
      {
        costElements: "Revenues",
        should: "",
        is: '',
        group: "Sonstiges",
      },
      {
        costElements: "Reserve",
        should: "",
        is: '',
        group: "Sonstiges",
      },
      {
        costElements: "Total",
        should: "",
        is: '',
        group: "Sonstiges",
      }
    ];

    this.formObject.pendingsTable = [
      {
        id: 1,
        pendings: '',
        description: ''
      },
      {
        id: 2,
        pendings: '',
        description: ''
      },
      {
        id: 3,
        pendings: '',
        description: ''
      },
      {
        id: 4,
        pendings: '',
        description: ''
      },
      {
        id: 5,
        pendings: '',
        description: ''
      },
      {
        id: 6,
        pendings: '',
        description: ''
      },
    ];

    this.formObject.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.deliverables = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.quantitativeBenefit = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.kpisTable = [
      {
        description: "Cost Savings",
        value: "",
        type: "Per Year"
      },
      {
        description: "NPV",
        value: "",
        type: ""
      },
      {
        description: "Additional Revenues",
        value: "",
        type: "Per Year"
      },
      {
        description: "Payback duration (Discounted)",
        value: "",
        type: "Years"
      },
      {
        description: "IRR",
        value: "",
        type: "%"
      }
    ];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getClosingReportQuestions(): void {
    this.socketService.emitEvent('questionsByFormName', { formName: 'Closing Report' });
  }

  getClosingReportHelpGuide(): void {
    this.socketService.emitEvent('helpGuideByFormName', { formName: 'Closing Report' });
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      if (this.utilityService.isTeamProject == true) {
        this.isTeamProject = true;
        this.utilityService.isTeamProject = false;
      }

      this.closingReportId = params.crId;
      this.projectId = params.id;

      this.socketService.emitEvent('fetchClosingReport', { projectId: this.projectId, closingReportId: this.closingReportId });
    });
  }

  setActiveStep(step) {
    this.activeStep = step;
  }

  prevStep() {
    let idx = this.steps.indexOf(this.activeStep);
    if (idx > 0) {
      this.activeStep = this.steps[idx - 1];
    }

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  nextStep() {
    this.activeStep = this.steps[this.steps.indexOf(this.activeStep) + 1];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  fetchPMOs() {
    this.PMOsList = this.utilityService.PMOsList;
  }

  fetchUsers() {
    this.usersList = this.utilityService.usersList;
  }

  submit() {
    this.submitFlag = true;
    this.eventType = "submit";

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.showNotificationBox('Step 1: Please Enter Project Name First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.showNotificationBox('Step 1: Please Select a Project Manager First.');
      this.submitFlag = false;
      return;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (goalsCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter goals first.');
      this.submitFlag = false;
      return;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (deliverablesCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter deliverables first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.reasonForDeviationProjectDeliveryObjects == undefined || this.formObject.reasonForDeviationProjectDeliveryObjects == "") {
      this.showNotificationBox('Step 1: Please Enter Reason For Deviation First.')
      this.submitFlag = false;
      return;
    }
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (quantativeBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter qualitative benefits first.');
      this.submitFlag = false;
      return;
    }
    // let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value.length > 0 && val.option != undefined && val.option != "");
    // if (qualitativeBenefitsCheck.length == 0) {
    //   this.showNotificationBox('Step 2: Please enter quantitative benefits first.');
    //   this.submitFlag = false;
    //   return;
    // }
    if (this.formObject.reasonForDeviationBenefitsDeviations == undefined || this.formObject.reasonForDeviationBenefitsDeviations == "") {
      this.showNotificationBox('Step 2: Please Enter Reason For Deviation First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.reasonForDeviationTimeDeviations == undefined || this.formObject.reasonForDeviationTimeDeviations == "") {
      this.showNotificationBox('Step 3: Please Enter Reason For Deviation First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.lessonsLearned != undefined) {
      let lessonsLearnedCheck = this.formObject.lessonsLearned.filter(val => val.description.length > 0);
      if (lessonsLearnedCheck.length == 0) {
        this.showNotificationBox('Step 4: Please enter Lessons Learned first.');
        this.submitFlag = false;
        return;
      }
    }
    else {
      this.showNotificationBox('Step 4: Please enter Lessons Learned first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.portfolioClosingReportQuestion && this.formObject.portfolioClosingReportQuestion.length > 0) {
      for (let i = 0; i < this.formObject.portfolioClosingReportQuestion.length; i++) {
        if (this.formObject.portfolioClosingReportQuestion[i] == "") {
          this.showNotificationBox('Step 6: Please fill all questions first.');
          this.submitFlag = false;
          return;
        }
      }
    }

    if (this.formObject.question[0] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[1] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[2] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[3] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[4] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[5] == "") {
      this.showNotificationBox('Step 6: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.showNotificationBox('Step 6: Please Select a PMO First.');
      this.submitFlag = false;
      return;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.showNotificationBox('Step 6: Please enter a valid email address for project sponsor.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.showNotificationBox('Step 6: Please Select a Project Sponsor First.');
      this.submitFlag = false;
      return;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.showNotificationBox('Step 6: Please enter a valid email address for project fico.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.showNotificationBox('Step 6: Please Select a Project Fico First.');
      this.submitFlag = false;
      return;
    }

    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("submit");
    } else {
      this.submitHelper();
    }
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  submitHelper() {
    this.spinner.show();
    let obj = {
      ...this.formObject,
      status: 'Submitted',
      isSubmitted: true,
      isApproved: false,
      version: this.formObject.version != undefined ? this.formObject.version + 1 : 1,
      date: new Date().toISOString(),
      endDateOnSubmit: new Date().toISOString(),
      reportRelevantlessonCount: this.reportRelevantlessonCount,
      attachment1: this.attachment1 ? this.attachment1 : "",
      attachment2: this.attachment2 ? this.attachment2 : "",
      attachments: [
        this.attachment1 ? this.attachment1 : "",
        this.attachment2 ? this.attachment2 : ""
      ],
      project: this.projectId
    };

    this.formObject.question = this.formObject.question.map(val => this.localize.transform(val));
    let questionsHtml: string = this.utilityService.getClosingReportQuestionsHtml(this.closingReportQuestions, this.formObject.question);
    let portfolioQuestionsHtml: string = this.getPortfolioQuestionsHTML();

    if (this.closingReportId == 0) {
      let projectObj = {
        closingReportApproved: false,
        status: "Submitted",
        docType: 'Closing Report',
        workflowStatus: 'Closing Report has been sent to PMO for approval.',
        version: 1,
        closingReportSubmitted: true,
        isPMOApprovedClosingReport: false,
        isSponsorApprovedClosingReport: false,
      };

      this.apiService.post("/project/closingReport/approval", {
        obj: obj,
        projectId: this.projectId,
        projectObj: projectObj,
        formObject: this.formObject
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${response.id}" target="_blank">${'Abschlussbericht /Closing Report : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.projectSubportfolio && this.projectSubportfolio.projectNotificationEmails && this.projectSubportfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.projectSubportfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.projectSubportfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/closingReport/${response.id}" target="_blank">${'Abschlussbericht /Closing Report : ' + this.formObject.projectName}</a>`+ portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Closing Report has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    } else {
      this.apiService.put("/project/closingReport/approval", {
        obj: obj,
        projectId: this.projectId,
        closingReportId: this.closingReportId,
        formObject: this.formObject
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${response.id}" target="_blank">${'Abschlussbericht /Closing Report : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.projectSubportfolio && this.projectSubportfolio.projectNotificationEmails && this.projectSubportfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.projectSubportfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.projectSubportfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/closingReport/${response.id}" target="_blank">${'Abschlussbericht /Closing Report : ' + this.formObject.projectName}</a>`+ portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Closing Report has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    }
  }

  validateStepsOnSave() {
    this.isStepValidated = [true, true, true, true, true, true];

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.isStepValidated[0] = false;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (goalsCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (deliverablesCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.reasonForDeviationProjectDeliveryObjects == undefined || this.formObject.reasonForDeviationProjectDeliveryObjects == "") {
      this.isStepValidated[0] = false;
    }

    //Step 2
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
    if (quantativeBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }

    if (this.formObject.reasonForDeviationBenefitsDeviations == undefined || this.formObject.reasonForDeviationBenefitsDeviations == "") {
      this.isStepValidated[1] = false;
    }

    //Step 3
    if (this.formObject.reasonForDeviationTimeDeviations == undefined || this.formObject.reasonForDeviationTimeDeviations == "") {
      this.isStepValidated[2] = false;
    }

    //Step 4
    if (this.formObject.lessonsLearned != undefined) {
      let lessonsLearnedCheck = this.formObject.lessonsLearned.filter(val => val.description.length > 0);
      if (lessonsLearnedCheck.length == 0) {
        this.isStepValidated[3] = false;
      }
    }
    else {
      this.isStepValidated[3] = false;
    }

    //Step 6
    if (this.formObject.question[0] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.question[1] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.question[2] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.question[3] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.question[4] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.question[5] == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.isStepValidated[5] = false;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.isStepValidated[5] = false;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.isStepValidated[5] = false;
    }
  }

  getPortfolioQuestionsHTML() {
    let questionsHtml = '';

    if (this.portfolio.questions && this.portfolio.questions.length > 0 && this.groups.length > 0) {
      questionsHtml = '<br><br>';
      this.formObject.portfolioClosingReportQuestion = this.formObject.portfolioClosingReportQuestion.map(val => this.localize.transform(val));

      this.groups.map(department => {
        questionsHtml += `<h3>${department}</h3>`;

        this.portfolio.questions.map((vals, i) => {
          if (vals.group == department) {
            questionsHtml += `
              <p><strong>${vals.question}</strong></p>
              <p>${this.formObject.portfolioClosingReportQuestion[i]}</p>
            `;
          }
        });
      })
    }

    return questionsHtml;
  }

  saveForm(e) {
    this.saveFlag = true;
    this.eventType = "save";

    this.isSavedPressed = true;
    this.validateStepsOnSave();

    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("save");
    } else {
      this.saveHelper();
    }
  }

  saveHelper() {
    this.spinner.show();
    this.socketService.emitEvent('fetchReport', { id: this.projectObj.projectReport.id });
  }

  getProjectDetailData(report) {
    this.formObject.goals[0].description = report.goals[0].description;
    this.formObject.goals[1].description = report.goals[1].description;
    this.formObject.goals[2].description = report.goals[2].description;
    this.formObject.goals[3].description = report.goals[3].description;
    this.formObject.goals[4].description = report.goals[4].description;

    this.formObject.deliverables[0].description = report.deliverables[0].description;
    this.formObject.deliverables[1].description = report.deliverables[1].description;
    this.formObject.deliverables[2].description = report.deliverables[2].description;
    this.formObject.deliverables[3].description = report.deliverables[3].description;
    this.formObject.deliverables[4].description = report.deliverables[4].description;

    //this.formObject.lessonsLearned = report.lessonsLearned;
    this.formObject.quantitativeBenefit[0].description = report.quantitativeBenefit[0].description;
    this.formObject.quantitativeBenefit[1].description = report.quantitativeBenefit[1].description;
    this.formObject.quantitativeBenefit[2].description = report.quantitativeBenefit[2].description;
    this.formObject.quantitativeBenefit[3].description = report.quantitativeBenefit[3].description;
    this.formObject.quantitativeBenefit[4].description = report.quantitativeBenefit[4].description;

    delete (report.kpisTable[1]);
    delete (report.kpisTable[4]);
    this.formObject.kpisTable[0].value = report.kpisTable[0].value;
    this.formObject.kpisTable[2].value = report.kpisTable[2].value;
    this.formObject.kpisTable[3].value = report.kpisTable[3].value;

    this.formObject.plannedEndDate = report.plannedEndDate;
    //this.formObject.additionalInformation = report.additionalInformation;
    this.formObject.currency = report.currency;
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  uploadAttachments(type: string) {
    this.showNotification = true;
    if (this.formObject.isAttachement1Added) {
      this.uploader.uploadAll();
    }
    if (this.formObject.isAttachement2Added) {
      this.uploader1.uploadAll();
    }
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        if (this.formObject.isAttachement1Added == false && (this.formObject.isAttachement2Added == false || this.formObject.isAttachement2Added == undefined)) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        if (this.formObject.isAttachement2Added == false && (this.formObject.isAttachement1Added == false || this.formObject.isAttachement1Added == undefined)) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  pmoOfficerValueChanged(e) {
    if (this.PMOsList != undefined) {
      this.PMOsList.forEach(response => {
        if (response.id == e.value) {
          this.formObject.pmoOfficer = response;
        }
      });
    }
  }

  projectSponsorValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.projectSponsor = response;
        }
      });
    }
  }

  projectFicoValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.projectFico = response;
        }
      });
    }
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectSponsor = response;
            } else {
              this.formObject.projectSponsor = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectSponsor = { id: '' };
      }
    });
  }

  projectFicoOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectFico = response;
            } else {
              this.formObject.projectFico = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectFico = { id: '' };
      }
    });
  }

  onEditorPreparingCostDeviationTable(e) {
    if (e.parentType == "dataRow") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateTotalValueForShould(e) {
    if (e.costElements == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("should");
      }
    } else if (e.costElements == "Revenues") {
      return e.should ? Math.abs(e.should) * -1 : "";
    } else {
      return e.should ? e.should : "";
    }
  }

  calculateTotalValueForIs(e) {
    if (e.costElements == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("is");
      }
    } else if (e.costElements == "Revenues") {
      return e.is ? Math.abs(e.is) * -1 : "";
    } else {
      return e.is ? e.is : "";
    }
  }

  onEditorPreparingKPIsTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Period") {
        e.editorOptions.placeholder = this.localize.transform("Payback Period");
      }
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.formObject.costDeviations[0][field] * 1;
    let two: any = globalThis.formObject.costDeviations[1][field] * 1;
    let three: any = globalThis.formObject.costDeviations[2][field] * 1;
    let four: any = globalThis.formObject.costDeviations[3][field] * 1;
    let five: any = globalThis.formObject.costDeviations[4][field] * 1;
    let six: any = globalThis.formObject.costDeviations[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.costDeviations[6][field] = total;
    return total;
  }

  onRowPrepared(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onRowPreparedDisableTable(e) {
    e.rowElement.style.backgroundColor = '#f5f5f5';
  }

  onCellPreparedDisabledTable(e) {
    if (e.columnIndex > 1) {
      e.cellElement.style.background = 'white';
    }
  }

  onCellPrepared(e) {
    if (e.columnIndex == 2) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
      e.cellElement.style.borderLeft = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  //##############################################################################
  // Lessons Configurations

  lessonCategoryValueChanged(e) {
    if (this.lessonCategorySelectOptions != undefined) {
      this.lessonCategorySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.lessonsForm.lessonCategory = response;
        }
      });
    }
  }

  addLesson() {
    if (this.formObject.lessonsLearned != undefined) {
      if (this.formObject.lessonsLearned.length == 0) {
        this.lessonsForm.id = 1;
      } else {
        this.lessonsForm.id = this.formObject.lessonsLearned[this.formObject.lessonsLearned.length - 1].id + 1;
      }
    } else {
      this.formObject.lessonsLearned = [];
      this.lessonsForm.id = 1;
    }

    if (this.lessonsForm.report == true) {
      if (this.reportRelevantlessonCount < 5) {
        this.formObject.lessonsLearned.push(this.lessonsForm);
        this.resetForm();
        this.reportRelevantlessonCount++;
        this.isEdit = false;
        this.modalRef.hide();
      } else {
        this.notificationBox("Warning", "Report relevant lessons can be maximum of 5.", "warning");
      }
    } else {
      this.formObject.lessonsLearned.push(this.lessonsForm);
      this.resetForm();
      this.isEdit = false;
      this.modalRef.hide();
    }
  }

  editLesson() {
    if (this.lessonsForm.report == true) {
      if (this.reportRelevantlessonCount < 5) {
        if (this.backupForm.report == false && this.lessonsForm.report == true) {
          this.reportRelevantlessonCount++;
        }
        this.isEdit = false;
        this.modalRef.hide();
      } else {
        this.notificationBox("Warning", "Report relevant lessons can be maximum of 5.", "warning");
      }
    } else {
      if (this.backupForm.report == true && this.lessonsForm.report == false) {
        this.reportRelevantlessonCount--;
      }
      this.isEdit = false;
      this.modalRef.hide();
    }
  }

  openModal(template: TemplateRef<any>, state) {

    if (state == 'New') {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == 'Edit') {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.lessonsForm = {
      lessonCategory: {
        id: ''
      },
      description: '',
      report: true
    };
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.lessonsForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, 'Edit');
  }

  openDeleteDialog(template, obj) {
    if (obj.report == true) {
      this.notificationBox("Warning", "Report relevant lesson cannot be deleted.", "warning");
    } else {
      let idx;
      this.formObject.lessonsLearned.forEach((val, index) => {
        if (val.id == obj.id) {
          idx = index;
        }
      });
      this.formObject.lessonsLearned.splice(idx, 1);

      // Updating id's 
      for (let index = 0; index < this.formObject.lessonsLearned.length; index++) {
        this.formObject.lessonsLearned[index].id = index + 1;
      }
    }
  }


  preview(): void {
    this.showClosingPreview = true;

    //Setting Redundant Values
    if (this.projectObj != undefined) {
      this.closingReportObj = this.formObject;
      let project = this.projectObj;
      this.closingReportObj = { ...this.closingReportObj, project };//final as an object

      if (this.closingReportId && this.closingReportId != 0) {
        this.closingReportObj.closingReportId = this.closingReportId;
      }

      this.lgClosingModal.onHidden.subscribe((reason: string) => {
        this.showClosingPreview = false;
      });
      this.lgClosingModal.show();
    }
  }

  pendingsRowClick(e) {
    this.pendingForm = e.key;
    this.modalRef = this.modalService.show(this.pendingFormTemplate, this.config);
  }

  deleteAttachment(type: string) {

    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.formObject.attachments[0] = "";

              this.apiService.put('/closingReport/' + this.projectObj.closingReport[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.formObject.attachments[1] = "";

              this.apiService.put('/closingReport/' + this.projectObj.closingReport[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        }
      }
    });
  }

  public showAttachmentDeleteButton(type: string): boolean {
    if (this.formObject.attachments != undefined) {
      if (this.formObject.attachments.length > 0) {
        if (type == '1') {
          return (this.formObject.id != undefined && this.formObject.attachments[0] != "");
        } else {
          return (this.formObject.id != undefined && this.formObject.attachments[1] != "");
        }
      } else {
        return false;
      }
    }
  }

  onKeyReasonForDeviationProjectDeliveryObjectsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 208) {
        e.preventDefault();
        this.reasonForDeviationGoalsLimit = true;
      } else {
        this.reasonForDeviationGoalsLimit = false;
      }
    } else {
      this.reasonForDeviationGoalsLimit = false;
    }
  }

  onKeyReasonForDeviationTimeDeviationsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 208) {
        e.preventDefault();
        this.reasonForDeviationCostLimit = true;
      } else {
        this.reasonForDeviationCostLimit = false;
      }
    } else {
      this.reasonForDeviationCostLimit = false;
    }
  }

  onKeyReasonForDeviationBenefitsDeviationsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 208) {
        e.preventDefault();
        this.reasonForDeviationQualitativeLimit = true;
      } else {
        this.reasonForDeviationQualitativeLimit = false;
      }
    } else {
      this.reasonForDeviationQualitativeLimit = false;
    }
  }

  onKeyAdditionalInformationInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.additionalInfoLimit = true;
      } else {
        this.additionalInfoLimit = false;
      }
    } else {
      this.additionalInfoLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  // checkTextareaLength(e, line) {
  //   let lines = e.target.value.split(/\r*\n/);
  //   if (lines.length > line) {
  //     if (e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
  //       e.preventDefault();
  //     }
  //   }
  // }

  canDeactivate(): boolean {
    if (this.isFormSaveSubmit == true) {
      return false;
    }
    else {
      return true;
    }
  }

  checkValidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (goalsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (deliverablesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (quantativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Lessons Learned') {
      let lessonsLearnedCheck = this.formObject.lessonsLearned ? this.formObject.lessonsLearned.filter(val => val.description.length > 0) : [];
      if (lessonsLearnedCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkInvalidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (goalsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (deliverablesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0 && val.option != undefined && val.option != "");
      if (quantativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Lessons Learned') {
      let lessonsLearnedCheck = this.formObject.lessonsLearned ? this.formObject.lessonsLearned.filter(val => val.description.length > 0) : [];
      if (lessonsLearnedCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('fetchClosingReport');
    this.socketService.removeListener('sendEmail');
    this.socketService.removeListener('questionsByFormName');
    this.socketService.removeListener('helpGuideByFormName');
    this.socketService.removeListener('fetchReport');
  }
}

class ClosingReport {
  companyName: string = "";
  projectName: string = "";
  reasonForDeviationBenefitsDeviations: string = "";
  reasonForDeviationProjectDeliveryObjects: string = "";
  lessonsLearned: string = "";
  quantitativeBenefit: string = "";
  reasonForDeviationTimeDeviations: string = "";
  additionalInformation: string = "";
  startDate: Date;
  endDate: Date;
  plannedEndDate: Date;
  endDateOnSubmit: Date;
}

