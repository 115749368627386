import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { SocketService } from 'app/services/socket.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss']
})
export class ThirdPartyComponent implements OnInit, OnDestroy {
  dataList: Array<any> = [];
  formObj = {};
  partiesCount: number = 0;
  public modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(private apiService: ApiService, private modalService: BsModalService, private utilityService: UtilityService,
    private localize: I18nPipe, private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Third Party';
    this.utilityService.breadcrumbList = ['Settings', 'Third Party'];

    this.socketService.emitEvent('thirdPartiesCount', {});

    this.socketService.listen('thirdPartiesCount').subscribe(count => {
      this.partiesCount = count;
      this.socketService.emitEvent('thirdPartiesIndex', { pageIndex: 1, pageSize: 10 });
    });

    this.socketService.listen('thirdPartiesIndex').subscribe(response => {
      this.dataList = response;
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.show();
  }

  onPageChange(event) {
    this.socketService.emitEvent('thirdPartiesIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.formObj = obj;
    this.openModal(template);
  }

  save(form) {
    this.spinner.show();
    if (form.id == undefined) {
      this.apiService.post('/thirdParties/register', form).subscribe((response: any) => {
        this.dataList.push(response.data);
        this.showNotification('Success', 'Application added successfully.');
        this.closeModal();
        this.spinner.hide();
      });
    } else {
      let secret = form.secret;
      let applicationId = form.applicationId;
      delete (form.secret);
      delete (form.applicationId);

      this.apiService.put('/thirdParties/' + form.id, form).subscribe((response: any) => {
        form.secret = secret;
        form.applicationId = applicationId;

        this.showNotification('Success', 'Application edited successfully.');
        this.closeModal();
	this.spinner.hide();
      });
    }
  }

  closeModal() {
    this.modalRef.hide();
    this.resetInputFields();
  }

  resetInputFields() {
    this.formObj = {};
  }

  showNotification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'success',
    );
  }

  ngOnDestroy() {
    this.socketService.removeListener('thirdPartiesCount');
    this.socketService.removeListener('thirdPartiesIndex');
  }

}
