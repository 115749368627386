import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectGanttChartRoutingModule } from './project-gantt-chart-routing.module';
import { ProjectGanttChartComponent } from './project-gantt-chart.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxDataGridModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxAutocompleteModule, DxChartModule, DxScrollViewModule } from 'devextreme-angular';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FormsModule } from '@angular/forms';
import { ProjectBudgetModule } from 'app/components/project-budget/project-budget.module';
import { MaterialModule } from 'app/material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    ProjectGanttChartRoutingModule,
    I18nModule,
    DxDataGridModule,
    DxCheckBoxModule,
    CurrencyFormatterPipeModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxChartModule,
    ProjectBudgetModule,
    MaterialModule,
    DxAutocompleteModule,
    DragDropModule,
    DxScrollViewModule,
  ],
  declarations: [ProjectGanttChartComponent],
  exports: [ProjectGanttChartComponent]
})
export class ProjectGanttChartModule { }
