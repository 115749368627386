import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  tabs: FormGroup;
  projectReportId;
  tabsEN = [];
  tabsDE = [];
  projectId;
  public isTeamProject: boolean = false;
  projectName = '';
  projectObj;

  constructor(private apiService: ApiService, public route: ActivatedRoute, public utilityService: UtilityService, private formBuilder: FormBuilder,
  ) {
    this.utilityService.breadcrumb = "Project Details";
    this.utilityService.reportsTab = "Overview";
  }

  ngOnInit() {
    this.getProjectInfo();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.tabs = this.formBuilder.group({
    });

    this.utilityService.projectDetail.subscribe(projectDetail => {
      this.projectReportId = projectDetail.projectReportId;
      this.projectId = projectDetail.projectId;
      this.isTeamProject = projectDetail.isTeamProject;
      this.projectName = projectDetail.projectName;

      if (projectDetail.tabIndex != undefined) {
        if (projectDetail.tabIndex * 1 == 1) {
          setTimeout(() => {
            $('app-project-detail .nav-item a[href="#status"]').click();
          }, 1000);
        }
        if (projectDetail.tabIndex * 1 == 2) {
          setTimeout(() => {
            $('app-project-detail .nav-item a[href="#gantt"]').click();
          }, 1000);
        }
      }
    });

    this.utilityService.saneirungSubject.subscribe(isView => {
      if (isView != this.projectObj.isViewSanierung) {
        this.projectObj.isViewSanierung = isView;
        window.location.reload();
      }
    })
  }

  getProjectInfo() {
    this.route.params.subscribe(params => {
      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.projectObj = projectData;
        setTimeout(() => {
          this.utilityService.initTabs();
        }, 1000)
      });
    });
  }
}
