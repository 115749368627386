import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { I18nService } from 'app/shared/i18n/i18n.service';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { SocketService } from 'app/services/socket.service';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare const $: any;

//Metadata
interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  show?: boolean;
  defaultClass: string;
}

interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  show?: boolean;
}

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  //Menu Items
  public menuItems: RouteInfo[];
  langs: Array<any>;
  user: any;
  externalLink: string;
  openOutlinesCount = 0;
  roleFlagAdminPmo: boolean;
  roleFlagViewer: boolean;
  isAdmin: boolean;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  switch: any;
  usersList = [];
  vacationBackupUser;
  isVacationON: boolean = false;
  isSaveDisabled: boolean = true;
  backupUserEmail = '';
  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  startDate;
  endDate;
  lastVacationId;
  minimumDate = '';
  startDateChangeFlag = false;
  isVacationSwitch = false;

  constructor(private datePipe: DatePipe, private socketService: SocketService, private localize: I18nPipe, private spinner: NgxSpinnerService, public i18nService: I18nService, private authService: AuthService, public utilityService: UtilityService, private apiService: ApiService, private modalService: BsModalService) {
    this.langs = new Array();
    this.langs = [
      {
        lang: 'English',
        code: 'EN',
        key: 'us',
        isActive: false
      },
      {
        lang: 'Deutsch',
        code: 'DE',
        key: 'de',
        isActive: false
      },
      // {
      //   lang: 'French',
      //   code: 'FR',
      //   key: 'fr',
      //   isActive: false
      // },
    ];

    this.getLastActiveVacation();
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  ngOnInit() {
    this.openOutlinesCount = this.utilityService.usersOpenOutlinesCount;
    this.utilityService.usersOpenOutlinesObservable.subscribe(count => {
      this.openOutlinesCount = count;
    });

    this.utilityService.getUsersOpenOutlinesCount();

    this.isAdmin = this.authService.userObj.role == 'admin';
    this.roleFlagAdminPmo = this.isAdmin || this.authService.userObj.role == 'pmo';
    this.roleFlagViewer = (this.authService.userObj.role == 'guest' && this.authService.userObj.isViewerAlso) || this.authService.userObj.isSubportfolioManager ? true : false;
    this.menuItems = [
      {
        path: '/news',
        title: 'oneView News',
        type: 'link',
        icontype: 'fa-regular fa-newspaper',
        show: true,
        defaultClass: '',
      },
      {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'fa-regular fa-table-layout',    //'dashboard',
        show: this.roleFlagAdminPmo || this.roleFlagViewer,
        defaultClass: '',
      },
      {
        path: '',
        title: 'My Workspace',
        type: 'sub',
        show: true,
        defaultClass: 'show',
        icontype: 'fa-regular fa-grid-2',   // -outline
        collapse: 'device_hub',
        children: [
          { path: 'subportfolios', title: 'Subportfolios', ab: 'S', show: true },
          { path: 'program', title: 'Programs', ab: 'P', show: true },
          { path: 'projects', title: 'Projects', ab: 'PJ', show: true },
          { path: 'smallOrder', title: 'Orders', ab: 'O', show: true }
        ]
      },
      {
        path: '/approvals',
        title: 'Approvals',
        type: 'link',
        show: true,
        defaultClass: '',
        icontype: 'fa-regular fa-circle-check'   //'grain'
      },
      {
        path: '/settings',
        title: 'Settings',
        type: 'sub',
        show: this.roleFlagAdminPmo,
        defaultClass: '',
        icontype: 'fa-regular fa-gear',   // -outline
        collapse: 'settings',
        children: [
          { path: 'currency', title: 'Currency', ab: 'C', show: this.isAdmin },
          { path: 'email-settings', title: 'Email Settings', ab: 'ES', show: this.isAdmin },
          { path: 'errors-log', title: 'Errors Log', ab: 'EL', show: this.isAdmin },
          { path: 'field-administration', title: 'Field Administration', ab: 'FA', show: this.isAdmin },
          { path: 'help-guide', title: 'Help Guide', ab: 'HG', show: this.isAdmin },
          { path: 'news', title: 'News', ab: 'N', show: this.isAdmin },
          { path: 'questions', title: 'Questions', ab: 'Q', show: this.isAdmin },
          { path: 'roles-and-permissions', title: 'Roles & Permissions', ab: 'R&P', show: this.isAdmin },
          { path: 'small-order-templates', title: 'Small Order Templates', ab: 'SOT', show: this.isAdmin },
          // { path: 'strategic-business-drivers', title: 'Strategic Business Drivers', ab: 'SBD', show: this.isAdmin },
          { path: 'status-calculation', title: 'Status Calculation', ab: 'SC', show: this.isAdmin },
          { path: 'third-party', title: 'Third Party', ab: 'TP', show: this.isAdmin },
          { path: 'translation', title: 'Translation', ab: 'T', show: this.isAdmin },
          { path: 'user-report', title: 'User Report', ab: 'UR', show: this.isAdmin },
          { path: 'view-as', title: 'View As', ab: 'VA', show: this.isAdmin },
          { path: 'workflow', title: 'Workflow', ab: 'W', show: this.roleFlagAdminPmo },
          { path: 'yearly-budget', title: 'Yearly Budget', ab: 'YB', show: this.isAdmin },
        ]
      },
      // {
      //   path: '/pages',
      //   title: 'Pages',
      //   type: 'sub',
      //   icontype: 'image',
      //   collapse: 'pages',
      //   children: [
      //     { path: 'pricing', title: 'Pricing', ab: 'P' },
      //     { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
      //     { path: 'login', title: 'Login Page', ab: 'LP' },
      //     { path: 'register', title: 'Register Page', ab: 'RP' },
      //     { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
      //     { path: 'user', title: 'User Page', ab: 'UP' }
      //   ]
      // }
    ];

    this.user = {
      username: this.authService.userObj.name, //this.authService.userObj.email,
      picture: "assets/img/male.png",
      activity: 12
    }

    let langKey = localStorage.getItem('language');
    this.langs.forEach((obj) => {
      if (obj.key == langKey) {
        obj.isActive = true;
      }
    });
    this.setSettingsMenu(langKey);
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 1, suppressScrollX: true });
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  setLanguage(obj) {
    this.langs.map(val => {
      if (val.key == obj.key) {
        val.isActive = true;
      } else {
        val.isActive = false;
      }
    });
    this.i18nService.setLanguage(obj);
    this.setSettingsMenu(obj.key);
  }

  openVacationMenu() {
    this.openModal(this.template);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  userOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;

      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.vacationBackupUser = response;
            } else {
              this.vacationBackupUser = undefined;
            }
          });
        }
      } else {
        this.vacationBackupUser = undefined;
      }
    });
  }

  userValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.vacationBackupUser = response;
          }
        });
      }
    }
  }

  saveVacationMode() {
    if (this.vacationBackupUser == undefined || this.backupUserEmail == '' || this.backupUserEmail == null) {
      this.showNotificationBox("Warning", 'Please select a Backup User.', "warning")
      return;
    }
    if (!this.isStartDateValid) {
      this.showNotificationBox("Warning", 'Please enter start date in correct format (dd.MM.yyyy) first.', "warning")
      return;
    }
    if (this.startDate == undefined) {
      this.showNotificationBox("Warning", 'Please select a start date first.', "warning");
      return;
    }
    if (!this.isEndDateValid) {
      this.showNotificationBox("Warning", 'Please enter end date in correct format (dd.MM.yyyy) first.', "warning")
      return;
    }
    if (this.endDate == undefined) {
      this.showNotificationBox("Warning", 'Please select a end date first.', "warning");
      return;
    }

    this.spinner.show();
    this.apiService.post('/vacationmode', {
      user: this.authService.userObj.id,
      backupUser: this.vacationBackupUser.id,
      startDate: this.startDate,
      endDate: this.endDate,
      isVacationActive: true
    }).subscribe(response => {
      this.getLastActiveVacation();
      this.socketService.emitEvent('sendEmail', {
        email: this.vacationBackupUser.email,
        subject: `oneView: Stellvertretung für ${this.authService.userObj.name} aktiviert`,
        message: `Guten Tag <br><br>
        Sie sind als Stellvertreter für ${this.authService.userObj.name} in oneView eingetragen. <br><br>
        Von ${this.datePipe.transform(this.startDate, 'dd. MMM yyyy')} bis ${this.datePipe.transform(this.endDate, 'dd. MMM yyyy')} werden sämtliche an ${this.authService.userObj.name} gerichtete Aufgaben innerhalb des Genehmiungsworklows von oneView an Sie weitergeleitet. Die Verantwortung der Bearbeitung der Workflowaufgaben liegt in diesem Zeitraum bei Ihnen. Für Fragen zum Tool stehen Ihnen die jeweiligen GB PMO' zur Verfügung.`
      });
      this.spinner.hide();
      this.modalRef.hide();
      this.showNotificationBox("Success", "Vacation Mode turned on!", "success")
    })
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title),
      this.localize.transform(msg), type);
  }

  getLastActiveVacation() {
    this.apiService.get('/getLastActiveVacationRecord/' + this.authService.userObj.id).subscribe((res: any) => {
      if (res != null) {
        this.isVacationON = true;
        this.isVacationSwitch = true;
        this.vacationBackupUser = res.backupUser;
        this.backupUserEmail = this.vacationBackupUser.email;
        this.startDate = res.startDate;
        this.endDate = res.endDate;
        this.lastVacationId = res.id;
      }
    })
  }

  switchValueChanged(e) {
    let newValue = e.target.checked;
    let elem: any = document.getElementById("vacationswitch")

    if (this.isVacationON && newValue == false && this.lastVacationId != undefined) {
      elem.checked = true;
      swal({
        title: `${this.localize.transform('Confirmation')}`,
        text: `${this.localize.transform("Do you want to turn off Vacation mode?")}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: `${this.localize.transform('Yes')}`,
        cancelButtonText: `${this.localize.transform('No')}`,
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.apiService.put('/vacationmode/' + this.lastVacationId, { isVacationActive: false }).subscribe(res => {
            this.spinner.hide();
            elem.checked = false;
            this.isVacationON = false;
            this.isVacationSwitch = false;
            this.vacationBackupUser = undefined;
            this.backupUserEmail = undefined;
            this.startDate = null;
            this.endDate = null;
            this.lastVacationId = undefined;
            this.modalRef.hide();
            this.showNotificationBox("Success", "Vacation mode turned off", "success");
          })
        }
      });
      return
    }

    this.isVacationON = newValue;
  }

  startDateValueChanged(e) {
    this.minimumDate = this.startDate;
    this.endDate = this.minimumDate;
  }

  endDateValueChanged(e) {
    this.endDate = e.value;
  }

  logout() {
    let tablesState = this.utilityService.getTablesState();
    this.apiService.put('/user/' + this.authService.userObj.id, {
      tablesState: tablesState
    }).subscribe((response: any) => {
      this.authService.logout();
    });
  }

  openExternalLink() {
    window.open(this.authService.userObj.configuration.externalHelpLink, "_blank")
  }

  openLessonsExternalLink() {
    window.open(this.authService.userObj.configuration.lessonsExternalLink, "_blank")
  }

  setSettingsMenu(lang) {
    if (this.menuItems && this.menuItems.length > 4)
      if (lang == 'de') {
        this.menuItems[4].children = [
          { path: 'email-settings', title: 'Email Settings', ab: 'ES', show: this.isAdmin },
          { path: 'errors-log', title: 'Errors Log', ab: 'EL', show: this.isAdmin },
          { path: 'field-administration', title: 'Field Administration', ab: 'FA', show: this.isAdmin },
          { path: 'questions', title: 'Questions', ab: 'Q', show: this.isAdmin },
          { path: 'help-guide', title: 'Help Guide', ab: 'HG', show: this.isAdmin },
          { path: 'yearly-budget', title: 'Yearly Budget', ab: 'YB', show: this.isAdmin },
          { path: 'news', title: 'News', ab: 'N', show: this.isAdmin },
          { path: 'roles-and-permissions', title: 'Roles & Permissions', ab: 'R&P', show: this.isAdmin },
          { path: 'small-order-templates', title: 'Small Order Templates', ab: 'SOT', show: this.isAdmin },
          // { path: 'strategic-business-drivers', title: 'Strategic Business Drivers', ab: 'SBD', show: this.isAdmin },
          { path: 'status-calculation', title: 'Status Calculation', ab: 'SC', show: this.isAdmin },
          { path: 'third-party', title: 'Third Party', ab: 'TP', show: this.isAdmin },
          { path: 'translation', title: 'Translation', ab: 'T', show: this.isAdmin },
          { path: 'user-report', title: 'User Report', ab: 'UR', show: this.isAdmin },
          { path: 'view-as', title: 'View As', ab: 'VA', show: this.isAdmin },
          { path: 'workflow', title: 'Workflow', ab: 'W', show: this.roleFlagAdminPmo },
          { path: 'currency', title: 'Currency', ab: 'C', show: this.isAdmin }
        ];
      } else {
        this.menuItems[4].children = [
          { path: 'currency', title: 'Currency', ab: 'C', show: this.isAdmin },
          { path: 'email-settings', title: 'Email Settings', ab: 'ES', show: this.isAdmin },
          { path: 'errors-log', title: 'Errors Log', ab: 'EL', show: this.isAdmin },
          { path: 'field-administration', title: 'Field Administration', ab: 'FA', show: this.isAdmin },
          { path: 'help-guide', title: 'Help Guide', ab: 'HG', show: this.isAdmin },
          { path: 'news', title: 'News', ab: 'N', show: this.isAdmin },
          { path: 'questions', title: 'Questions', ab: 'Q', show: this.isAdmin },
          { path: 'roles-and-permissions', title: 'Roles & Permissions', ab: 'R&P', show: this.isAdmin },
          { path: 'small-order-templates', title: 'Small Order Templates', ab: 'SOT', show: this.isAdmin },
          // { path: 'strategic-business-drivers', title: 'Strategic Business Drivers', ab: 'SBD', show: this.isAdmin },
          { path: 'status-calculation', title: 'Status Calculation', ab: 'SC', show: this.isAdmin },
          { path: 'third-party', title: 'Third Party', ab: 'TP', show: this.isAdmin },
          { path: 'translation', title: 'Translation', ab: 'T', show: this.isAdmin },
          { path: 'user-report', title: 'User Report', ab: 'UR', show: this.isAdmin },
          { path: 'view-as', title: 'View As', ab: 'VA', show: this.isAdmin },
          { path: 'workflow', title: 'Workflow', ab: 'W', show: this.roleFlagAdminPmo },
          { path: 'yearly-budget', title: 'Yearly Budget', ab: 'YB', show: this.isAdmin }
        ];
      }
  }
}
