import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  pastNews: any = [];
  upcomingNews: any = [];
  constructor(private utilityService: UtilityService, private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "oneView News";
    this.utilityService.breadcrumbList = ['oneView News'];

    this.spinner.show();
    this.socketService.emitEvent('newsByType', {});
    this.socketService.listen('newsByType').subscribe(data => {
      this.pastNews = data.pastNews;
      this.upcomingNews = data.upcomingNews;
      this.spinner.hide();
    });
  }

  ngOnInit() {
  }

}
