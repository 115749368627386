import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { UtilityService } from 'app/services/utility.service';
import { BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';

let globalThis;

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  projectsSearchList: any = [];
  projectObj: any;
  reportObj: any;
  showForm: boolean = false;
  isStartDateValid: boolean = true;
  isOriginalEndDateValid: boolean = true;
  isPlannedEndDateValid: boolean = true;

  constructor(private utilityService: UtilityService, private modalService: BsModalService, private apiService: ApiService,
    private localize: I18nPipe, private route: ActivatedRoute,  private spinner: NgxSpinnerService, private socketService: SocketService) {
    this.utilityService.breadcrumb = 'Details';
    this.utilityService.breadcrumbList = ['Settings', 'View As', 'Project', 'Details'];
    globalThis = this;
  }

  ngOnInit() {
    this.socketService.listen('searchOpenDetailsProjects').subscribe(response => {
      this.projectsSearchList = response;
    });
  }

  projectsOnInputEvent(e) {
    if(e.event.delegateTarget.value == undefined || e.event.delegateTarget.value == '' || e.event.delegateTarget.value == null) {
      this.showForm = false;
    }

    this.socketService.emitEvent('searchOpenDetailsProjects', { search: e.event.delegateTarget.value });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.spinner.show();
      this.apiService.get('/projects/' + e.itemData.id).subscribe(response => {
        this.projectObj = response;
        this.reportObj = this.projectObj.projectReport;
        this.setData();
        this.showForm = true;
        this.spinner.hide();
      })
    }
  }

  setData() {
    if (!this.reportObj.goals) {
      this.reportObj.goals = [
        {
          id: 1,
          description: ''
        },
        {
          id: 2,
          description: ''
        },
        {
          id: 3,
          description: ''
        },
        {
          id: 4,
          description: ''
        },
        {
          id: 5,
          description: ''
        },
      ];
    }
    
    if (!this.reportObj.deliverables) {
      this.reportObj.deliverables = [
        {
          id: 1,
          description: ''
        },
        {
          id: 2,
          description: ''
        },
        {
          id: 3,
          description: ''
        },
        {
          id: 4,
          description: ''
        },
        {
          id: 5,
          description: ''
        }
      ];
    }

    if (!this.reportObj.outOfScope) {
      this.reportObj.outOfScope = [
        {
          id: 1,
          description: ''
        },
        {
          id: 2,
          description: ''
        },
        {
          id: 3,
          description: ''
        },
        {
          id: 4,
          description: ''
        },
        {
          id: 5,
          description: ''
        },
      ];
    }

    if (!this.reportObj.quantitativeBenefit) {
      this.reportObj.quantitativeBenefit = [
        {
          id: 1,
          description: ''
        },
        {
          id: 2,
          description: ''
        },
        {
          id: 3,
          description: ''
        },
        {
          id: 4,
          description: ''
        },
        {
          id: 5,
          description: ''
        },
      ];
    }

    if (!this.reportObj.kpisTable) {
      this.reportObj.kpisTable = [
        {
          description: "Cost Savings",
          value: "",
          type: "Per Year"
        },
        {
          description: "NPV",
          value: "",
          type: ""
        },
        {
          description: "Add.Revenue",
          value: "",
          type: "Per Year"
        },
        {
          description: "Payback duration (Discounted)",
          value: "",
          type: "Years"
        },
        {
          description: "IRR",
          value: "",
          type: "%"
        }
      ];
    }

    if (!this.reportObj.costTypeTable) {
      this.reportObj.costTypeTable = [
        {
          costTypes: "External Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 0,
          group: 'CAPEX'
        },
        {
          costTypes: "Internal Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 1,
          group: 'CAPEX'
        },
        {
          costTypes: "External Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 2,
          group: 'OPEX'
        },
        {
          costTypes: "Internal Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 3,
          group: 'OPEX'
        },
        {
          costTypes: "Revenues",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 4,
          group: 'Sonstiges'
        },
        {
          costTypes: "Reserves",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 5,
          group: 'Sonstiges'
        },
        {
          costTypes: "Total",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 6,
          group: 'Sonstiges'
        },];
    }
  }

  onEditorPreparingKPIsTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Duration") {
        e.editorOptions.placeholder = this.localize.transform("Payback Duration");
      }
    }
  }

  onCellPreparedFinancials(e) {
    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingCostTypeTable(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  onRowPreparedFinancials(e) {
    if (e.rowIndex > 8) {
      e.rowElement.style.backgroundColor = "#ddd";
    }
  }

  calculateForecastValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.reportObj.costTypeTable[6].forecast = globalThis.projectCostsValueCalculator('forecast');
      return globalThis.reportObj.costTypeTable[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  calculateCurrentBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.reportObj.costTypeTable[6].currentBudget = globalThis.projectCostsValueCalculator('currentBudget');
      return globalThis.reportObj.costTypeTable[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  calculateOriginalBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.reportObj.costTypeTable[6].originalBudget = globalThis.projectCostsValueCalculator('originalBudget');
      return globalThis.reportObj.costTypeTable[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.reportObj.costTypeTable[0][field] * 1;
    let two: any = globalThis.reportObj.costTypeTable[1][field] * 1;
    let three: any = globalThis.reportObj.costTypeTable[2][field] * 1;
    let four: any = globalThis.reportObj.costTypeTable[3][field] * 1;
    let five: any = globalThis.reportObj.costTypeTable[4][field] * 1;
    let six: any = globalThis.reportObj.costTypeTable[5][field] * 1;
    let total = one + two + three + four - five + six;
    globalThis.reportObj.costTypeTable[6][field] = total;
    return total;
  }

  checkValidation() {
    if (this.reportObj.projectName == undefined || this.reportObj.projectName == '') {
      this.showNotification("Warning", "Please Enter Project Name First.", "warning");
      return false;
    }
    if (!this.isStartDateValid) {
      this.showNotification("Warning", 'Please enter start date in correct format (dd.MM.yyyy) first.', "warning")
      return false;
    }
    if (this.reportObj.startDate == undefined) {
      this.showNotification("Warning", 'Please select a start date first.', "warning");
      return false;
    }
    if (!this.isOriginalEndDateValid) {
      this.showNotification("Warning", 'Please enter original end date in correct format (dd.MM.yyyy) first.', "warning")
      return false;
    }
    if (this.reportObj.endDate == undefined) {
      this.showNotification("Warning", 'Please select a original end date first.', "warning");
      return false;
    }
    if (!this.isPlannedEndDateValid) {
      this.showNotification("Warning", 'Please enter planned end date in correct format (dd.MM.yyyy) first.', "warning")
      return false;
    }
    if (this.reportObj.plannedEndDate == undefined) {
      this.showNotification("Warning", 'Please select a planned end date first.', "warning");
      return false;
    }
    else {
      return true;
    }
  }

  editProjectReport() {
    if (this.checkValidation()) {
      this.spinner.show();
      this.apiService.put('/reports/' + this.reportObj.id, {
        projectName: this.reportObj.projectName,
        goals: this.reportObj.goals,
        deliverables: this.reportObj.deliverables,
        outOfScope: this.reportObj.outOfScope,
        quantitativeBenefit: this.reportObj.quantitativeBenefit,
        kpisTable: this.reportObj.kpisTable,
        costTypeTable: this.reportObj.costTypeTable,
        startDate: this.reportObj.startDate,
        endDate: this.reportObj.endDate,
        plannedEndDate: this.reportObj.plannedEndDate
      }).subscribe((response: any) => {
        this.reportObj = response;
        this.spinner.hide();
        this.showNotification('Success', 'Project detail updated successfully.', 'success');
      });
    }
  }

  enableStatusReport() {
    this.spinner.show();
    this.apiService.put('/reports/' + this.reportObj.id, {
      hasDraftReport: false
    }).subscribe((response: any) => {
      this.spinner.hide();
      this.showNotification('Success', 'Status report has been enabled.', 'success');
    });
  }

  showNotification(title, message, type) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      type,
    );
  }

  ngOnDestroy() {
    this.socketService.removeListener('searchOpenDetailsProjects');
  }
}
