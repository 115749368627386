import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectHistoryRoutingModule } from './project-history-routing.module';
import { ProjectHistoryComponent } from './project-history.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    ProjectHistoryRoutingModule,
    DxDataGridModule,
    I18nModule,
    NgxSpinnerModule
  ],
  declarations: [
    ProjectHistoryComponent,
  ]
})
export class ProjectHistoryModule { }
