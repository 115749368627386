import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatterPortfolio'
})
export class CurrencyFormatterPortfolioPipe implements PipeTransform {

  transform(value: number,
    currencySign: string = '',
    decimalLength: number = 2,
    chunkDelimiter: string = "'",
    decimalDelimiter: string = '.',
    chunkLength: number = 3): string {
    // value /= 100;

    let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    let num: any = (value * 1).toFixed(Math.max(0, ~~decimalLength));
    let answer = currencySign + (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);

    return answer;
  }

}
