import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as svg from 'save-svg-as-png';

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {

  @Input() dataSource;
  @Input() isChartDisabled: boolean;

  title = 'Svg-diagram';
  selectedElement = null;
  currentX = 0;
  currentY = 0;
  idCounter;
  isDrawingConnector: boolean = false;
  connectorID;
  connectorX;
  connectorY;
  clickedElement = null;
  clickedElementId = null;
  isShapeClicked: boolean = false;
  isConnectorShapeClicked: boolean = false;
  minimumEllipseHeight: number = 20;
  minimumEllipseWidth: number = 50;
  minimumRectangleHeight: number = 40;
  minimumRectangleWidth: number = 80;
  isTextFieldVisible: boolean = true;
  isDraggable = true;
  isResizeable = false;
  shapes = [];
  fontSizeOptions = ['8pt', '9pt', '10pt', '11pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '36pt', '48pt', '72pt'];
  selectedFont = this.fontSizeOptions[3];
  selectedColor = '#ffffff';
  fontSelectedColor = "#333333";
  selectedConnector = "Orthogonal";
  orientation;
  selectedElementId;
  distanceMatrix;
  distanceMatrixVertical;
  showScaleLine: boolean = false;
  showScaleLineVertical: boolean = false;

  constructor() {
    document.body.style.zoom = "99.7%"
  }

  ngOnInit(): void {
    this.shapes = this.dataSource && this.dataSource.length > 0 ? this.dataSource : [
      {
        "id": 1,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 404,
        "y": 45,
        "rx": 10,
        "ry": 10,
        "width": 161,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#e0e8ff",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektauftraggeber"
      },
      {
        "id": 2,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 377,
        "y": 136,
        "rx": 10,
        "ry": 10,
        "width": 215,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#fbf598",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "PSA"
      },
      {
        "id": 3,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 411,
        "y": 218,
        "rx": 10,
        "ry": 10,
        "width": 148,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#97e7c1",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektleiter"
      },
      {
        "id": 4,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 150,
        "y": 320,
        "rx": 10,
        "ry": 10,
        "width": 145,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#FFFFFF",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektteam"
      },
      {
        "id": 5,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 330,
        "y": 320,
        "rx": 10,
        "ry": 10,
        "width": 143,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#FFFFFF",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektteam"
      },
      {
        "id": 6,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 510,
        "y": 320,
        "rx": 10,
        "ry": 10,
        "width": 138,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#FFFFFF",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektteam"
      },
      {
        "id": 7,
        "type": "rectangle",
        "class": "shape",
        "text": "Name",
        "x": 690,
        "y": 320,
        "rx": 10,
        "ry": 10,
        "width": 138,
        "height": 62,
        "fontSize": "11pt",
        "backgroundColor": "#FFFFFF",
        "fontColor": "#333333",
        "isTextBold": true,
        "title": "Projektteam"
      },
      {
        "id": 8,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 1,
        "sinkId": 2,
        "sourceIndex": 0,
        "sinkIndex": 1
      },
      {
        "id": 9,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 2,
        "sinkId": 3,
        "sourceIndex": 1,
        "sinkIndex": 2
      },
      {
        "id": 10,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 3,
        "sinkId": 4,
        "sourceIndex": 2,
        "sinkIndex": 3
      },
      {
        "id": 11,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 3,
        "sinkId": 5,
        "sourceIndex": 2,
        "sinkIndex": 4
      },
      {
        "id": 12,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 3,
        "sinkId": 6,
        "sourceIndex": 2,
        "sinkIndex": 5
      },
      {
        "id": 13,
        "type": "connector",
        "connectorType": "Orthogonal",
        "sourceId": 3,
        "sinkId": 7,
        "sourceIndex": 2,
        "sinkIndex": 6
      }
    ];

    if (this.shapes.length > 0) {
      this.idCounter = this.shapes[this.shapes.length - 1].id + 1;
    } else {
      this.idCounter = 0;
    }
  }

  addRectangle() {
    let shape1 = { id: this.idCounter, type: 'rectangle', class: 'shape', text: 'Name', title: 'Title', x: 50, y: 100, rx: 10, ry: 10, width: 100, height: 62, fontSize: '11pt', backgroundColor: '#ffffff', fontColor: '#333333', isTextBold: false }
    this.shapes.push(shape1)
    this.idCounter++;
  }

  addCircle() {
    let shape1 = { id: this.idCounter, type: 'ellipse', class: 'shape', text: 'Name', title: 'Title', cx: 90, cy: 220, rx: 50, ry: 31, fontSize: '11pt', backgroundColor: '#ffffff', fontColor: '#333333', isTextBold: false }
    this.shapes.push(shape1)
    this.idCounter++;
  }

  drawConnector(shape, srcIndex) {
    this.isDrawingConnector = true;

    this.connectorX = shape.type == 'rectangle' ? shape.x + (shape.width / 2) : shape.cx;
    this.connectorY = shape.type == 'rectangle' ? shape.y + shape.height : shape.cy + shape.ry;

    let tempShape = { id: 1000111, type: 'rectangle', x: this.connectorX, y: this.connectorY, width: 0 };
    this.shapes.push(tempShape);

    let connectorShape = { id: this.idCounter, type: 'connector', connectorType: this.selectedConnector, sourceId: shape.id, sinkId: null, sourceIndex: srcIndex, sinkIndex: this.shapes.length - 1, sinkConnectorNumber: null }
    this.connectorID = this.idCounter;
    this.shapes.push(connectorShape)
    this.idCounter++;
  }

  finishConnector(shape, indx, sinkConnectorNumber) {
    if (this.isDrawingConnector) {
      let connector = this.shapes.find(shape => { return shape.id == this.connectorID });
      connector.sinkIndex = indx;
      connector.sinkId = shape.id;
      connector.sinkConnectorNumber = sinkConnectorNumber;

      let tempShapeIndex = this.shapes.findIndex(shape => shape.id == 1000111);
      this.shapes.splice(tempShapeIndex, 1);
      this.isDrawingConnector = false;
    }
  }

  updateIndexes() {
    this.shapes.forEach(shape => {
      if (shape.type == 'connector') {
        let sourceIndex = this.shapes.findIndex(elem => elem.id == shape.sourceId);
        let sinkIndex = this.shapes.findIndex(elem => elem.id == shape.sinkId);

        shape.sourceIndex = sourceIndex;
        shape.sinkIndex = sinkIndex;
      }
    })
  }

  mouseUpSVG() {
    if (this.isDrawingConnector) {
      let connectorIndex = this.shapes.findIndex(shape => { return shape.id == this.connectorID });
      this.shapes.splice(connectorIndex, 1)

      let tempShapeIndex = this.shapes.findIndex(shape => shape.id == 1000111);
      this.shapes.splice(tempShapeIndex, 1);
      this.isDrawingConnector = false;
    }
    this.isResizeable = false;
    this.selectedElement = null;
    this.selectedElementId = null;
  }

  mouseMoveSVGEvent(e) {
    if (this.isDrawingConnector) {
      let tempShape = this.shapes.find(shape => { return shape.id == 1000111 });

      tempShape.x = e.layerX;
      tempShape.y = e.layerY;
    }


    if (!this.isDraggable && this.isResizeable) {
      let selectedShape = this.shapes.find(shape => { return shape.id == this.clickedElementId });

      if (this.orientation == 'vertical') {

        if (selectedShape.type == 'ellipse') {

          if (selectedShape.ry > this.minimumEllipseHeight) {
            selectedShape.ry = e.offsetY - selectedShape.cy;
          } else {
            selectedShape.ry = this.minimumEllipseHeight + 1;
          }

        } else if (selectedShape.type == 'rectangle') {
          if (selectedShape.height > this.minimumRectangleHeight) {
            selectedShape.height = e.offsetY - selectedShape.y;
          } else {
            selectedShape.height = this.minimumRectangleHeight + 1;
          }
        }

      } else if (this.orientation == 'horizontal') {
        if (selectedShape.type == 'ellipse') {

          if (selectedShape.rx > this.minimumEllipseWidth) {
            selectedShape.rx = e.offsetX - selectedShape.cx;
          } else {
            selectedShape.rx = this.minimumEllipseWidth + 1;
          }

        } else if (selectedShape.type == 'rectangle') {
          if (selectedShape.width > this.minimumRectangleWidth) {
            selectedShape.width = e.offsetX - selectedShape.x;
          } else {
            selectedShape.width = this.minimumRectangleWidth + 1;
          }

        }
      }
    }


    if (this.selectedElement && this.selectedElementId != undefined) {
      let selectedShape = this.shapes.find(shape => { return shape.id == this.selectedElementId });

      if (selectedShape.type == 'rectangle') {
        var dx = parseInt(this.selectedElement.getAttribute("x")) + e.clientX - this.currentX;
        var dy = parseInt(this.selectedElement.getAttribute("y")) + e.clientY - this.currentY;
        this.currentX = e.clientX;
        this.currentY = e.clientY;
        selectedShape.x = dx;
        selectedShape.y = dy;

        // Horizontal Alignment Calculations
        this.distanceMatrix = [];
        let j = 0;
        for (let i = 0; i < this.shapes.length; i++) {
          let shape = this.shapes[i];
          if (shape.id != selectedShape.id) {
            if (shape.type == 'rectangle') {
              let distanceEuclidean = Math.sqrt(Math.pow((selectedShape.y - shape.y), 2));
              this.distanceMatrix[j] = { index: i, distance: distanceEuclidean };
            }
            if (shape.type == 'ellipse') {
              let distanceEuclidean = Math.sqrt(Math.pow((selectedShape.y - (shape.cy - shape.ry)), 2));
              this.distanceMatrix[j] = { index: i, distance: distanceEuclidean };
            }
            j++;
          }
        }
        this.distanceMatrix.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

        if (this.distanceMatrix[0].distance < 10) {
          this.showScaleLine = true;
          let scaleLine = this.shapes.find(shapeObj => shapeObj.id == 1111111 && shapeObj.type == 'scale');
          if (scaleLine) {
            scaleLine.x1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].x : this.shapes[this.distanceMatrix[0].index].cx;
            scaleLine.y1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);
            scaleLine.x2 = selectedShape.x;
            scaleLine.y2 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);

          } else {
            let x1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].x : this.shapes[this.distanceMatrix[0].index].cx;
            let y1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);

            let tempShape = { id: 1111111, type: 'scale', x1, y1, x2: selectedShape.x, y2: y1 };
            this.shapes.push(tempShape);
          }
        } else {
          this.showScaleLine = false;
        }

        // Vertical Alignment Calculations
        this.distanceMatrixVertical = [];
        let k = 0;
        for (let i = 0; i < this.shapes.length; i++) {
          let shape = this.shapes[i];
          if (shape.id != selectedShape.id) {
            if (shape.type == 'rectangle') {
              let distanceEuclidean = Math.sqrt(Math.pow(((selectedShape.x + (selectedShape.width / 2)) - (shape.x + (shape.width / 2))), 2));
              this.distanceMatrixVertical[k] = { index: i, distance: distanceEuclidean };
            }
            if (shape.type == 'ellipse') {
              let distanceEuclidean = Math.sqrt(Math.pow(((selectedShape.x + (selectedShape.width / 2)) - shape.cx), 2));
              this.distanceMatrixVertical[k] = { index: i, distance: distanceEuclidean };
            }
            k++;
          }
        }
        this.distanceMatrixVertical.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

        if (this.distanceMatrixVertical[0].distance < 10) {
          this.showScaleLineVertical = true;
          let scaleLine = this.shapes.find(shapeObj => shapeObj.id == 1111222 && shapeObj.type == 'scaleVertical');
          if (scaleLine) {
            scaleLine.x1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            scaleLine.y1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrixVertical[0].index].y : (this.shapes[this.distanceMatrixVertical[0].index].cy - this.shapes[this.distanceMatrixVertical[0].index].ry);
            scaleLine.x2 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            scaleLine.y2 = selectedShape.y;

          } else {
            let x1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            let y1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrixVertical[0].index].y : (this.shapes[this.distanceMatrixVertical[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);

            let tempShape = { id: 1111222, type: 'scaleVertical', x1, y1, x2: x1, y2: selectedShape.y };
            this.shapes.push(tempShape);
          }
        } else {
          this.showScaleLineVertical = false;
        }


      } else if (selectedShape.type == 'ellipse') {
        var dx = parseInt(this.selectedElement.getAttribute("cx")) + e.clientX - this.currentX;
        var dy = parseInt(this.selectedElement.getAttribute("cy")) + e.clientY - this.currentY;
        this.currentX = e.clientX;
        this.currentY = e.clientY;

        selectedShape.cx = dx;
        selectedShape.cy = dy;

        // Horizontal Alignment Calculations
        this.distanceMatrix = [];
        let j = 0;
        for (let i = 0; i < this.shapes.length; i++) {
          let shape = this.shapes[i];
          if (shape.id != selectedShape.id) {
            if (shape.type == 'rectangle') {
              let distanceEuclidean = Math.sqrt(Math.pow(((selectedShape.cy - selectedShape.ry) - shape.y), 2));
              this.distanceMatrix[j] = { index: i, distance: distanceEuclidean };
            }
            if (shape.type == 'ellipse') {
              let distanceEuclidean = Math.sqrt(Math.pow(((selectedShape.cy - selectedShape.ry) - (shape.cy - shape.ry)), 2));
              this.distanceMatrix[j] = { index: i, distance: distanceEuclidean };
            }
            j++;
          }
        }
        this.distanceMatrix.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

        if (this.distanceMatrix[0].distance < 10) {
          this.showScaleLine = true;
          let scaleLine = this.shapes.find(shapeObj => shapeObj.id == 1111111 && shapeObj.type == 'scale');
          if (scaleLine) {
            scaleLine.x1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].x : this.shapes[this.distanceMatrix[0].index].cx;
            scaleLine.y1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);
            scaleLine.x2 = selectedShape.cx;
            scaleLine.y2 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);

          } else {
            let x1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].x : this.shapes[this.distanceMatrix[0].index].cx;
            let y1 = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);

            let tempShape = { id: 1111111, type: 'scale', x1, y1, x2: selectedShape.cx, y2: y1 };
            this.shapes.push(tempShape);
          }
        } else {
          this.showScaleLine = false;
        }

        // Vertical Alignment Calculations
        this.distanceMatrixVertical = [];
        let k = 0;
        for (let i = 0; i < this.shapes.length; i++) {
          let shape = this.shapes[i];
          if (shape.id != selectedShape.id) {
            if (shape.type == 'rectangle') {
              let distanceEuclidean = Math.sqrt(Math.pow((selectedShape.cx - (shape.x + (shape.width / 2))), 2));
              this.distanceMatrixVertical[k] = { index: i, distance: distanceEuclidean };
            }
            if (shape.type == 'ellipse') {
              let distanceEuclidean = Math.sqrt(Math.pow((selectedShape.cx - shape.cx), 2));
              this.distanceMatrixVertical[k] = { index: i, distance: distanceEuclidean };
            }
            k++;
          }
        }
        this.distanceMatrixVertical.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

        if (this.distanceMatrixVertical[0].distance < 10) {
          this.showScaleLineVertical = true;
          let scaleLine = this.shapes.find(shapeObj => shapeObj.id == 1111222 && shapeObj.type == 'scaleVertical');
          if (scaleLine) {
            scaleLine.x1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            scaleLine.y1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrixVertical[0].index].y : (this.shapes[this.distanceMatrixVertical[0].index].cy);
            scaleLine.x2 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            scaleLine.y2 = selectedShape.cy;

          } else {
            let x1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : this.shapes[this.distanceMatrixVertical[0].index].cx;
            let y1 = this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrixVertical[0].index].y : (this.shapes[this.distanceMatrixVertical[0].index].cy);

            let tempShape = { id: 1111222, type: 'scaleVertical', x1, y1, x2: x1, y2: selectedShape.cy };
            this.shapes.push(tempShape);
          }
        } else {
          this.showScaleLineVertical = false;
        }
      }
    }
  }

  //When mouse pressed
  mouseDownOnShape(e, id) {
    this.currentX = e.clientX;
    this.currentY = e.clientY;
    this.selectedElement = e.target;
    this.selectedElementId = id;
  }

  //When mouse released
  mouseUpOnShape(e, id) {
    this.selectedElement = null;

    if (this.showScaleLine) {
      let selectedShape = this.shapes.find(shapeObj => shapeObj.id == id);
      if (selectedShape.type == 'rectangle') {
        selectedShape.y = this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry);
      } else {
        selectedShape.cy = (this.shapes[this.distanceMatrix[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrix[0].index].y : (this.shapes[this.distanceMatrix[0].index].cy - this.shapes[this.distanceMatrix[0].index].ry)) + selectedShape.ry;
      }
    }

    if (this.showScaleLineVertical) {
      let selectedShape = this.shapes.find(shapeObj => shapeObj.id == id);
      if (selectedShape.type == 'rectangle') {
        selectedShape.x = (this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? (this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2)) : (this.shapes[this.distanceMatrixVertical[0].index].cx)) - (selectedShape.width / 2);
      } else {
        selectedShape.cx = (this.shapes[this.distanceMatrixVertical[0].index].type == 'rectangle' ? this.shapes[this.distanceMatrixVertical[0].index].x + (this.shapes[this.distanceMatrixVertical[0].index].width / 2) : (this.shapes[this.distanceMatrixVertical[0].index].cx));
      }
    }

    this.showScaleLine = false;
    this.showScaleLineVertical = false;

    // this.isDraggable = true;
  }

  mouseMoveOnShape(e, id) {
    // if (this.selectedElement && id != undefined) {
    //   let selectedShape = this.shapes.find(shape => { return shape.id == id });

    //   if (selectedShape.type == 'rectangle') {
    //     var dx = parseInt(this.selectedElement.getAttribute("x")) + e.clientX - this.currentX;
    //     var dy = parseInt(this.selectedElement.getAttribute("y")) + e.clientY - this.currentY;
    //     this.currentX = e.clientX;
    //     this.currentY = e.clientY;

    //     selectedShape.x = dx;
    //     selectedShape.y = dy;
    //   } else if (selectedShape.type == 'ellipse') {
    //     var dx = parseInt(this.selectedElement.getAttribute("cx")) + e.clientX - this.currentX;
    //     var dy = parseInt(this.selectedElement.getAttribute("cy")) + e.clientY - this.currentY;
    //     this.currentX = e.clientX;
    //     this.currentY = e.clientY;

    //     selectedShape.cx = dx;
    //     selectedShape.cy = dy;
    //   }
    // }
  }

  svgClicked(event) {
    if (!this.isShapeClicked) {
      if (this.clickedElement != null) {
        this.clickedElement.classList.remove("shape-active");
        this.clickedElement = null;
        this.clickedElementId = null;
        this.isConnectorShapeClicked = false;
      }
    } else {
      this.isShapeClicked = false;
    }
  }

  shapeClicked(event, id, type) {
    this.isShapeClicked = true;
    this.clickedElementId = id;

    if (type == 'connector') {
      this.isConnectorShapeClicked = true;
      let selectedShape = this.shapes.find(shape => shape.id == this.clickedElementId);
      this.selectedConnector = selectedShape.connectorType;
    } else {
      this.isConnectorShapeClicked = false;
      let selectedShape = this.shapes.find(shape => shape.id == this.clickedElementId);
      this.selectedFont = selectedShape.fontSize;
      this.selectedColor = selectedShape.backgroundColor;
      this.fontSelectedColor = selectedShape.fontColor;
    }

    if (this.clickedElement == null) {
      this.clickedElement = event.target;
      if (type != 'connector')
        this.clickedElement.classList.add("shape-active");
    } else {
      this.clickedElement.classList.remove("shape-active");
      this.clickedElement = event.target;
      if (type != 'connector')
        this.clickedElement.classList.add("shape-active");
    }
  }

  //Deleting Shape
  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Delete') {
      if (this.clickedElement != null) {
        //Deleting Shape Itself
        let selectedShapeIndex = this.shapes.findIndex(shape => { return shape.id == this.clickedElementId });
        let shapeType = this.shapes[selectedShapeIndex].type;
        this.shapes.splice(selectedShapeIndex, 1);

        // Deleting all the associated Connectors
        if (shapeType != 'connector') {
          let connectorIndexes = this.shapes.map((elem, indx) => {
            return elem.type == 'connector' && (elem.sourceIndex == selectedShapeIndex || elem.sinkIndex == selectedShapeIndex) ? indx : '';
          }).filter(String);

          connectorIndexes.forEach((connectorIndex: number) => {
            this.shapes.splice(connectorIndex, 1);
          });
        }

        //Finally updating indexes in connectors
        this.updateIndexes();
      }
    }
  }

  // Resize events

  mousedownEventResize(e) {
    this.isDraggable = false;
    this.isResizeable = true;
  }

  mousemoveEventResize(e, id, orientation) {
    this.orientation = orientation;

    // let selectedShape = this.shapes.find(shape => { return shape.id == id });

    // if (!this.isDraggable && this.isResizeable) {
    //   if (orientation == 'vertical') {

    //     if (selectedShape.type == 'ellipse') {

    //       if (selectedShape.ry > this.minimumEllipseHeight) {
    //         selectedShape.ry = e.offsetY - selectedShape.cy;
    //       } else {
    //         selectedShape.ry = this.minimumEllipseHeight + 1;
    //       }

    //     } else if (selectedShape.type == 'rectangle') {
    //       if (selectedShape.height > this.minimumRectangleHeight) {
    //         selectedShape.height = e.offsetY - selectedShape.y;
    //       } else {
    //         selectedShape.height = this.minimumRectangleHeight + 1;
    //       }
    //     }

    //   } else if (orientation == 'horizontal') {
    //     if (selectedShape.type == 'ellipse') {

    //       if (selectedShape.rx > this.minimumEllipseWidth) {
    //         selectedShape.rx = e.offsetX - selectedShape.cx;
    //       } else {
    //         selectedShape.rx = this.minimumEllipseWidth + 1;
    //       }

    //     } else if (selectedShape.type == 'rectangle') {
    //       if (selectedShape.width > this.minimumRectangleWidth) {
    //         selectedShape.width = e.offsetX - selectedShape.x;
    //       } else {
    //         selectedShape.width = this.minimumRectangleWidth + 1;
    //       }

    //     }
    //   }
    // }
  }

  popoverClosed() {
    this.isTextFieldVisible = false;
  }

  popoverShowing() {
    this.isTextFieldVisible = true;
  }

  async exportToPNG() {
    document.getElementById('backgroundGrid').style.display = 'none';
    await svg.saveSvgAsPng(document.getElementById('mainSVG'), "Org-chart.png");
    document.getElementById('backgroundGrid').style.display = 'block';
  }

  fontChanged(font) {
    let selectedElement = this.shapes.find(shape => shape.id == this.clickedElementId);
    if (selectedElement != null && selectedElement.type != 'connector')
      selectedElement.fontSize = font;
    this.selectedFont = font;
  }

  colorChanged() {
    let selectedElement = this.shapes.find(shape => shape.id == this.clickedElementId);
    if (selectedElement != null && selectedElement.type != 'connector')
      selectedElement.backgroundColor = this.selectedColor;
  }

  fontColorChanged() {
    let selectedElement = this.shapes.find(shape => shape.id == this.clickedElementId);
    if (selectedElement != null && selectedElement.type != 'connector')
      selectedElement.fontColor = this.fontSelectedColor;
  }

  fontBoldChanged() {
    let selectedElement = this.shapes.find(shape => shape.id == this.clickedElementId);
    if (selectedElement != null && selectedElement.type != 'connector')
      selectedElement.isTextBold = !selectedElement.isTextBold;
  }

  connectorChanged(connector) {
    this.selectedConnector = connector;
    let selectedElement = this.shapes.find(shape => shape.id == this.clickedElementId);
    if (selectedElement != null && selectedElement.type == 'connector')
      selectedElement.connectorType = connector;
  }

  export() {
    return this.shapes;
  }

  clearAll() {
    this.shapes = [];
  }
}
