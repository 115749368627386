import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-subportfolio-measures',
  templateUrl: './subportfolio-measures.component.html',
  styleUrls: ['./subportfolio-measures.component.scss']
})
export class SubportfolioMeasuresComponent implements OnInit, OnChanges {
  public measuresList: Array<any> = [];
  @Input() measuresInputList;
  @Input() isMember;
  @Output() updateMeasuresOutput = new EventEmitter<any>();

  public modalRef: BsModalRef;
  public isEdit: boolean = false;
  private config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public measureForm: any = {
    description: '',
    measures: ''
  };
  public impactOptions: Array<any>;
  public impactOnStatusOptions: Array<any>;
  private backupForm: any;
  public reportRelevantMeasuresCount: number = 0;
  @ViewChild(DxDataGridComponent, { static: false }) private dataGrid: DxDataGridComponent;
  helpGuide;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService) {
    this.impactOptions = ['Scope', 'Cost', 'Time', 'Measures'];
    this.impactOnStatusOptions = ['Green', 'Yellow', 'Red'];

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.getHelpGuideData();
  }

  ngOnInit() {
    if (this.measuresInputList != undefined) {
      this.measuresList = this.measuresInputList;
    }

    if (this.measuresList == undefined) {
      this.measuresList = [];
      this.reportRelevantMeasuresCount = 0;
    }

    if (this.reportRelevantMeasuresCount == undefined) {
      this.reportRelevantMeasuresCount = 0;
    }

    if (this.measuresList.length > 0) {
      this.reportRelevantMeasuresCount = 0;
      for (let i = 0; i < this.measuresList.length; i++) {
        if (this.measuresList[i].report == true) {
          this.reportRelevantMeasuresCount++;
        }
      }
    }
  }

  ngOnChanges(changes: any) {
    if (changes.measuresInputList && changes.measuresInputList.currentValue != undefined) {
      this.measuresInputList = changes.measuresInputList.currentValue;
      this.measuresList = changes.measuresInputList.currentValue;

      if (this.measuresList.length > 0) {
        this.reportRelevantMeasuresCount = 0;
        for (let i = 0; i < this.measuresList.length; i++) {
          if (this.measuresList[i].report == true) {
            this.reportRelevantMeasuresCount++;
          }
        }
      }
    }
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Program Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  onEditorPreparingMeasuresTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'id') {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = 'Enter your description';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'extentOfLosses') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = 'CHF';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.measureForm = {
      report: false,
      description: '',
      measures: ''
    };
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.measureForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  openDeleteDialog(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant measure cannot be deleted.', 'warning');
        } else {
          let idx;
          this.measuresList.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });

          this.measuresList.splice(idx, 1);
          //Updating id's 
          for (let index = 0; index < this.measuresList.length; index++) {
            this.measuresList[index].id = index + 1;
          }

          this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
          this.dataGrid.instance.option('dataSource', this.measuresList);
          this.showNotificationBox('Success', 'Measure deleted.', 'success');
        }
      }
    });
  }

  impactValueChanged(e) {
    if (this.impactOptions != undefined) {
      this.impactOptions.forEach(response => {
        if (response == e.value) {
          this.measureForm.impact = response;
        }
      });
    }
  }

  impactOnStatusValueChanged(e) {
    if (this.impactOnStatusOptions != undefined) {
      this.impactOnStatusOptions.forEach(response => {
        if (response == e.value) {
          this.measureForm.impactOnStatus = response;
        }
      });
    }
  }

  save() {
    if (this.measuresList.length == 0) {
      this.measureForm.id = 1;
    } else {
      this.measureForm.id = this.measuresList[this.measuresList.length - 1].id + 1;
    }

    if (this.measureForm.report == true) {
      if (this.reportRelevantMeasuresCount <= 2) {
        this.measuresList.push(this.measureForm);
        this.resetForm();
        this.reportRelevantMeasuresCount++;
        this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.measuresList);
        this.modalRef.hide();
      } else {
        this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
      }
    } else {
      this.measuresList.push(this.measureForm);
      this.resetForm();
      this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
      this.isEdit = false;
      this.dataGrid.instance.option('dataSource', this.measuresList);
      this.modalRef.hide();
    }
  }

  edit() {
    if (this.measureForm.report == true) {
      if (this.backupForm.report == true && this.measureForm.report == true) {

        this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.measuresList);
        this.modalRef.hide();

      } else {
        if (this.reportRelevantMeasuresCount <= 2) {
          if (this.backupForm.report == false && this.measureForm.report == true) {
            this.reportRelevantMeasuresCount++;
          }

          this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.measuresList);
          this.modalRef.hide();
        }
        else {
          this.measureForm.report = false;
          this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
        }
      }
    } else {
      if (this.backupForm.report == true && this.measureForm.report == false) {
        this.reportRelevantMeasuresCount--;
      }

      this.updateMeasuresOutput.emit({ measuresList: this.measuresList });
      this.isEdit = false;
      this.dataGrid.instance.option('dataSource', this.measuresList);
      this.modalRef.hide();
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}