import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfOutlineViewRoutingModule } from './pdf-outline-view-routing.module';
import { PdfOutlineViewComponent } from './pdf-outline-view.component';
import { PDFModule } from 'app/pdf/pdf.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    PdfOutlineViewRoutingModule,
    PDFModule,
    NgxSpinnerModule,
    I18nModule
  ],
  declarations: [PdfOutlineViewComponent]
})
export class PdfOutlineViewModule { }
