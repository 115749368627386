import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  public selectedTab: string = 'currency';
  public tabs;
  permittedEmailForLogs = "pmo@luftmatrazetoutlook.onmicrosoft.com";
  userEmail: string = '';

  constructor(private formBuilder: FormBuilder, private utilityService: UtilityService, private route: ActivatedRoute, private authService: AuthService) {
    this.userEmail = this.authService.userObj.email;
  }

  ngOnInit() {
    this.tabs = this.formBuilder.group({
    });
  }

  ngAfterViewInit() {
    this.utilityService.initTabs();
  }

  tabsItemClick(e) {
    this.selectedTab = e;
  }

}
