import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallOrderStatusReportRoutingModule } from './small-order-status-report-routing.module';
import { SmallOrderStatusReportComponent } from './small-order-status-report.component';
import {
  DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxDateBoxModule,
  DxCheckBoxModule, DxProgressBarModule, DxChartModule, DxDropDownBoxModule, DxTreeViewModule,
  DxTextBoxModule, DxPopoverModule
} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { PdfSmallOrderStatusReportModule } from '../pdf-small-order-status-report/pdf-small-order-status-report.module';
import { ModalModule } from 'ngx-bootstrap';
// import { PDFProjectStatusReportModule } from 'app/projects/project-detail/pdf-project-status-report/pdf-project-status-report.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    SmallOrderStatusReportRoutingModule,
    DxDataGridModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxProgressBarModule,
    ModalModule,
    DxChartModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxTextBoxModule,
    DxPopoverModule,
    // PDFProjectStatusReportModule,
    NgxSpinnerModule,
    PdfSmallOrderStatusReportModule
  ],
  declarations: [
    SmallOrderStatusReportComponent
  ]
})
export class SmallOrderStatusReportModule { }
