import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectEVAComponent } from './project-eva.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectEVAComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectEVARoutingModule { }
