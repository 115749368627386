import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs-compat/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs-compat/operators/distinctUntilChanged';
import { switchMap } from 'rxjs-compat/operators/switchMap';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {
  programsList = [];
  programsCount: number = 0;
  programsSearch: any = '';
  programsPageIndex = 2;
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  isPm: boolean = false;
  programsSubject = new Subject<any>();
  stateStore;
  isVisible: boolean = false;

  constructor(public utilityService: UtilityService, private authService: AuthService, private apiService: ApiService, private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Programs";

    if (this.authService.userObj.role == "admin" || this.authService.userObj.role == "pmo") {
      this.isPm = false;
    } else {
      this.isPm = true;
    }
    this.fetchPrograms();

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.stateStore = {
      enabled: true,
      storageKey: 'programsList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.utilityService.breadcrumbList = ['Dashboard', 'Programs'];
    this.socketService.listen('programsCount').subscribe(count => {
      this.programsCount = count;

      if (count > 20) {
        this.socketService.emitEvent('programsIndex', { pageIndex: this.programsPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('programsIndex').subscribe((response: any) => {
      this.programsList = this.programsList.concat(response);

      if (this.programsPageIndex < (this.programsCount / 20)) {
        this.programsPageIndex++;
        this.socketService.emitEvent('programsIndex', { pageIndex: this.programsPageIndex, pageSize: 20 });
      }
    });

  }

  fetchPrograms() {
    this.spinner.show();

    // let isGuestAllowed = this.authService.userObj.role == "guest" && this.authService.userObj.isViewerAlso;
    // if (this.authService.userObj.role == "pmo" || this.authService.userObj.role == "admin" || isGuestAllowed) {
    this.apiService.get('/program?limit=20').subscribe((response: any) => {
      this.programsList = response;
      this.socketService.emitEvent('programsCount', {});
      this.isVisible = true;
      this.spinner.hide();
    });
    // } else {
    //   this.apiService.get('/getProgramsByUser/' + this.authService.userObj.id).subscribe((response: any) => {
    //     this.programsList = response;
    //   });
    // }
  }

  refreshGrid() {
    this.dataGrid.instance.option('dataSource', this.programsList);
  }


  ngOnDestroy() {
    this.socketService.removeListener('programsCount');
    this.socketService.removeListener('programsIndex');
    this.socketService.removeListener('programsSearchIndex');
    this.socketService.removeListener('programsSearch');
  }

}
