import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectDetailsRoutingModule } from './project-details-routing.module';
import { ProjectDetailsComponent } from './project-details.component';
import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule, DxCheckBoxModule, DxTagBoxModule, DxAutocompleteModule, DxDropDownBoxModule, DxTreeViewModule, DxDateBoxModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';

@NgModule({
  declarations: [ProjectDetailsComponent],
  imports: [
    CommonModule,
    ProjectDetailsRoutingModule,
    DxDataGridModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxAutocompleteModule,
    MaterialModule,
    PDFModule,
    ModalModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    CurrencyFormatterPipeModule,
    DxDateBoxModule
  ]
})
export class ProjectDetailsModule { }
