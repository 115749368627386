import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { DxDataGridModule, DxScrollViewModule } from 'devextreme-angular';
import { I18nModule } from "../shared/i18n/i18n.module";
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFModule } from 'app/pdf/pdf.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    DxDataGridModule,
    I18nModule,
    ModalModule,
    DxScrollViewModule,
    FormsModule,
    ReactiveFormsModule,
    PDFModule,
    MaterialModule,
    ClipboardModule,
    NgxSpinnerModule
  ],
  declarations: [
    ProjectsComponent,
  ],
  providers: [DatePipe]
})
export class ProjectsModule { }
