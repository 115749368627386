import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import swal from 'sweetalert2';
declare var moment: any;

@Component({
  selector: 'app-project-sanierung',
  templateUrl: './project-sanierung.component.html',
  styleUrls: ['./project-sanierung.component.scss']
})
export class ProjectSanierungComponent implements OnInit {
  sanierungArray = [];
  public modalRef: BsModalRef;
  projectReportId;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  isEdit = false;

  sanierungForm: any = {
    date: '',
    costType: '',
    was: '',
    plannedCost: 0,
    actualCost: 0,
    paybackPlannedCost: 0,
    paybackAmount: 0,
    comments: ''
  }

  constructor(public route: ActivatedRoute, private localize: I18nPipe, public utilityService: UtilityService, private modalService: BsModalService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectReportId = params.id;
      this.fetchSenierung();
    })
  }

  save() {
    if (this.sanierungForm.date == '' || this.sanierungForm.date == null) {
      this.showNotification('Warning', 'Please Enter valid Date.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == '' || this.sanierungForm.costType == null) {
      this.showNotification('Warning', 'Please enter cost type.', 'warning');
      return;
    }
    if (this.sanierungForm.was == '' || this.sanierungForm.was == null) {
      this.showNotification('Warning', 'Please enter cost description.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Kostenplanung' && this.sanierungForm.plannedCost == 0 || this.sanierungForm.plannedCost == null) {
      this.showNotification('Warning', 'Please enter planned cost.', 'warning');
      return;
    }

    if (this.sanierungForm.costType == 'Kostenplanung' && this.sanierungForm.actualCost == 0 || this.sanierungForm.actualCost == null) {
      this.showNotification('Warning', 'Please enter actual cost.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Rückzahlungsplanung' && this.sanierungForm.paybackPlannedCost == 0 || this.sanierungForm.paybackPlannedCost == null) {
      this.showNotification('Warning', 'Please enter payback planned cost.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Rückzahlungsplanung' && this.sanierungForm.paybackAmount == 0 || this.sanierungForm.paybackAmount == null) {
      this.showNotification('Warning', 'Please enter payback ammount.', 'warning');
      return;
    }

    this.apiService.post('/sanierung', { ...this.sanierungForm, projectReport: this.projectReportId }).subscribe(res => {
      this.showNotification('Success', 'Saved', 'success');
      this.modalRef.hide();
      this.resetForm();
      this.fetchSenierung();
    });
  }

  update() {
    if (this.sanierungForm.date == '' || this.sanierungForm.date == null) {
      this.showNotification('Warning', 'Please Enter valid Date.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == '' || this.sanierungForm.costType == null) {
      this.showNotification('Warning', 'Please enter cost type.', 'warning');
      return;
    }
    if (this.sanierungForm.was == '' || this.sanierungForm.was == null) {
      this.showNotification('Warning', 'Please enter cost description.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Kostenplanung' && this.sanierungForm.plannedCost == 0 || this.sanierungForm.plannedCost == null) {
      this.showNotification('Warning', 'Please enter planned cost.', 'warning');
      return;
    }

    if (this.sanierungForm.costType == 'Kostenplanung' && this.sanierungForm.actualCost == 0 || this.sanierungForm.actualCost == null) {
      this.showNotification('Warning', 'Please enter actual cost.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Rückzahlungsplanung' && this.sanierungForm.paybackPlannedCost == 0 || this.sanierungForm.paybackPlannedCost == null) {
      this.showNotification('Warning', 'Please enter payback planned cost.', 'warning');
      return;
    }
    if (this.sanierungForm.costType == 'Rückzahlungsplanung' && this.sanierungForm.paybackAmount == 0 || this.sanierungForm.paybackAmount == null) {
      this.showNotification('Warning', 'Please enter payback ammount.', 'warning');
      return;
    }

    this.apiService.put('/sanierung/' + this.sanierungForm.id, { ...this.sanierungForm }).subscribe(res => {
      this.showNotification('Success', 'Updated', 'success');
      this.modalRef.hide();
      this.resetForm();
      this.fetchSenierung();
    });
  }

  fetchSenierung() {
    this.apiService.get(`/sanierung?where={"projectReport":"${this.projectReportId}"}`).subscribe((res: any) => {
      this.sanierungArray = res;
    })
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.sanierungForm = JSON.parse(JSON.stringify(obj));
    this.sanierungForm.date = moment(obj.date).format('yyyy-MM-DD');
    this.openModal(template, "Edit");
  }

  resetForm() {
    this.sanierungForm = {
      date: '',
      costType: '',
      was: '',
      plannedCost: 0,
      actualCost: 0,
      paybackPlannedCost: 0,
      paybackAmount: 0,
      comments: ''
    };
  }

  deleteItem(item) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('Do you want to delete this item?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/sanierung/' + item.id).subscribe(res => {
          this.showNotification('Success', 'Deleted', 'success');
          this.fetchSenierung();
        })
      }
    });
  }

  actionEvent(event, key) {
    this.sanierungForm[key] = event.target.value != 'Select' ? event.target.value : '';
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

}