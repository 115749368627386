import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProjectChecklistComponent } from './project-checklist.component';
import { DxDataGridModule, DxAutocompleteModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule} from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProjectChecklistRoutingModule } from './project-checklist-routing.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    ProjectChecklistRoutingModule,
    DxAutocompleteModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule
  ],
  declarations: [
    ProjectChecklistComponent
  ]
})
export class ProjectChecklistModule { }
