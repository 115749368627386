import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateOrderReportRoutingModule } from './create-order-report-routing.module';
import { CreateOrderReportComponent } from './create-order-report.component';
import {
  DxDataGridModule, DxDateBoxModule, DxSliderModule, DxRadioGroupModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxAutocompleteModule
} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ModalModule } from 'ngx-bootstrap';
// import { PDFProjectStatusReportModule } from 'app/projects/project-detail/pdf-project-status-report/pdf-project-status-report.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrderDecisionsModule } from './order-decisions/order-decisions.module';
import { OrderMeasuresModule } from './order-measures/order-measures.module';
import { OrderMilestonesModule } from './order-milestones/order-milestones.module';
import { OrderRisksModule } from './order-risks/order-risks.module';
import { PdfSmallOrderStatusReportModule } from '../pdf-small-order-status-report/pdf-small-order-status-report.module';

@NgModule({
  imports: [
    CommonModule,
    CreateOrderReportRoutingModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxSliderModule,
    DxRadioGroupModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxAutocompleteModule,
    ModalModule,
    // PDFProjectStatusReportModule,
    NgxSpinnerModule,
    OrderDecisionsModule,
    OrderMeasuresModule,
    OrderMilestonesModule,
    OrderRisksModule,
    PdfSmallOrderStatusReportModule
  ],
  declarations: [
    CreateOrderReportComponent
  ]
})
export class CreateOrderReportModule { }
