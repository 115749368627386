
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxSelectBoxModule, DxRadioGroupModule, DxTextBoxModule, DxDataGridModule, DxCheckBoxModule, DxTabPanelModule, DxDateBoxModule } from 'devextreme-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { PDFModule } from 'app/pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';
import { NewsComponent } from './news/news.component';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    SettingsRoutingModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    DxSelectBoxModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTabPanelModule,
    DxDateBoxModule,
    FileUploadModule,
    MaterialModule,
    PDFModule,
    ModalModule,
    NgxSpinnerModule
  ],
  declarations: [
    SettingsComponent 
  ]
})
export class SettingsModule { }
