import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SmallOrdersLessonsLearnedComponent } from './small-orders-lessons-learned.component';

const routes: Routes = [
  {
    path: '',
    component: SmallOrdersLessonsLearnedComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmallOrdersLessonsLearnedRoutingModule { }
