import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-project-lessons-learned',
  templateUrl: './project-lessons-learned.component.html',
  styleUrls: ['./project-lessons-learned.component.scss']
})
export class ProjectLessonsLearnedComponent implements OnInit {
  @Input() isProjectUneditable;
  projectObj: any = { lessonsLearned: [] };
  isEdit = false;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  lessonsForm: any = {
    lessonCategory: {
      id: ''
    },
    description: '',
    report: false
  };
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  backupForm;
  reportRelevantlessonCount = 0;
  lessonCategorySelectOptions;
  lessonTypeSelectOptions;
  reportId;
  isMember;
  isVisible: boolean = false;
  isTeamProject: boolean = false;

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    this.lessonTypeSelectOptions = ["Positive", "Negative"];
  }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.reportId = params.id;
      this.isTeamProject = this.utilityService.isTeamProject;
      this.lessonCategorySelectOptions = this.utilityService.lessonsCategoryOptions;

      this.apiService.get('/reports/' + this.reportId).subscribe(response => {
        this.projectObj = response;
        this.utilityService.breadcrumbList = ['My Projects', this.projectObj.projectName, 'Details', 'Lessons'];
        if (this.projectObj.lessonsLearned == undefined) {
          this.projectObj.lessonsLearned = [];
        } else {
          this.reportRelevantlessonCount = this.projectObj.reportRelevantlessonCount;
        }
        this.isVisible = true;
        this.spinner.hide();
      });
    });
  }

  save() {
    if (this.reportId != undefined) {
      if (this.projectObj.lessonsLearned.length == 0) {
        this.lessonsForm.id = 1;
      } else {
        this.lessonsForm.id = this.projectObj.lessonsLearned[this.projectObj.lessonsLearned.length - 1].id + 1;
      }

      if (this.lessonsForm.report == true) {
        this.projectObj.lessonsLearned.push(this.lessonsForm);
        this.resetForm();
        if (this.reportRelevantlessonCount < 10) {
          this.reportRelevantlessonCount++;
          this.spinner.show();
          this.apiService.put('/reports/' + this.reportId, {
            reportRelevantlessonCount: this.reportRelevantlessonCount,
            lessonsLearned: this.projectObj.lessonsLearned
          }).subscribe(response => {
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.projectObj.lessonsLearned);
            this.modalRef.hide();
            this.spinner.hide();
          });
        } else {
          this.showNotificationBox('Warning', 'Report relevant lessons can be maximum of 10.', 'warning');
        }
      } else {
        this.projectObj.lessonsLearned.push(this.lessonsForm);
        this.resetForm();
        this.spinner.show();
        this.apiService.put('/reports/' + this.reportId, {
          reportRelevantlessonCount: this.reportRelevantlessonCount,
          lessonsLearned: this.projectObj.lessonsLearned
        }).subscribe(response => {
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.projectObj.lessonsLearned);
          this.modalRef.hide();
          this.spinner.hide();
        });
      }
    }
  }

  edit() {
    if (this.lessonsForm.report == true) {
      if (this.reportRelevantlessonCount < 10) {
        if (this.backupForm.report == false && this.lessonsForm.report == true) {
          this.reportRelevantlessonCount++;
        }
        this.spinner.show();
        this.apiService.put('/reports/' + this.reportId, {
          reportRelevantlessonCount: this.reportRelevantlessonCount,
          lessonsLearned: this.projectObj.lessonsLearned
        }).subscribe(response => {
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.projectObj.lessonsLearned);
          this.modalRef.hide();
          this.spinner.hide();
        });
      }
    } else {
      if (this.backupForm.report == true && this.lessonsForm.report == false) {
        this.reportRelevantlessonCount--;
      }

      this.apiService.put('/reports/' + this.reportId, {
        reportRelevantlessonCount: this.reportRelevantlessonCount,
        lessonsLearned: this.projectObj.lessonsLearned
      }).subscribe(response => {
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.projectObj.lessonsLearned);
        this.modalRef.hide();
        this.spinner.hide();
      });
    }
  }

  openModal(template: TemplateRef<any>, state) {

    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.lessonsForm = {
      lessonCategory: {
        id: ''
      },
      description: '',
      report: false
    };
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.lessonsForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  openDeleteDialog(template, obj) {
    if (obj.report == true) {
      this.showNotificationBox('Warning', 'Report relevant lesson cannot be deleted.', 'warning');
    } else {
      let idx;
      this.projectObj.lessonsLearned.forEach((val, index) => {
        if (val.id == obj.id) {
          idx = index;
        }
      });
      this.projectObj.lessonsLearned.splice(idx, 1);

      // Updating id's 
      for (let index = 0; index < this.projectObj.lessonsLearned.length; index++) {
        this.projectObj.lessonsLearned[index].id = index + 1;
      }

      this.apiService.put('/reports/' + this.reportId, {
        lessonsLearned: this.projectObj.lessonsLearned,
        reportRelevantlessonCount: this.reportRelevantlessonCount
      }).subscribe(response => {
        this.dataGrid.instance.option('dataSource', this.projectObj.lessonsLearned);
      });
    }
  }

  lessonCategoryValueChanged(e) {
    if (this.lessonCategorySelectOptions != undefined) {
      this.lessonCategorySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.lessonsForm.lessonCategory = response;
        }
      });
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
