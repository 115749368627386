import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-program-agregated-risks',
  templateUrl: './program-agregated-risks.component.html',
  styleUrls: ['./program-agregated-risks.component.scss']
})
export class ProgramAgregatedRisksComponent implements OnInit {
  agregatedRisksList = [];
  reportId;
  isVisible: boolean = false;

  constructor(private apiService: ApiService, private authService: AuthService, private spinner: NgxSpinnerService,
   private route: ActivatedRoute, private utilityService: UtilityService) { }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.reportId = params.id;

      this.apiService.get('/program/' + this.reportId).subscribe((response: any) => {
        this.utilityService.breadcrumbList = ['My Programs', response.programName, 'Details', 'Risks'];
        response.reports.forEach((report, index) => {
          report.risks.forEach(risk => {
            if (risk.description != "") {
              let obj: any = {};
              obj.projectName = report.projectName;
              obj.description = risk.description;
              if (risk.riskStrategy.name != undefined) {
                obj.riskStrategy = risk.riskStrategy;
              } else {
                obj.riskStrategy = { name: "" };
              }
              obj.probability = risk.probability;
              obj.riskExposure = risk.riskExposure;
              this.agregatedRisksList.push(obj);
            }
          });
        });
        this.isVisible = true;
        this.spinner.hide();
      });
    });
  }

  onEditorPreparingRisksTopFiveTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'projectName') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }
}
