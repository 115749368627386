import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-project-phases-financials',
  templateUrl: './project-phases-financials.component.html',
  styleUrls: ['./project-phases-financials.component.scss']
})
export class ProjectPhasesFinancialsComponent implements OnInit {
  phaseData = [];
  isDataLoaded: boolean = false;

  constructor(private apiService: ApiService, public route: ActivatedRoute, private utilityService: UtilityService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumbList[3] = 'Phases Financials';

  }

  ngOnInit() {
    this.spinner.show();
    this.fetchPhasesReports()
  }

  fetchPhasesReports() {
    this.route.params.subscribe(params => {
      this.apiService.get('/reports/getPhasesReportsByParentProject/' + params.id).subscribe((response: any) => {
        this.isDataLoaded = true;
        this.spinner.hide();
        response.forEach(project => {
          if (project.costTypeTable && project.costTypeTable.length > 0) {
            this.phaseData.push({ projectName: project.projectName, costTypeTable: project.costTypeTable })
          }
        });
      });
    });
  }

  onCellPreparedFinancials(e) {
  }

  onRowPreparedFinancials(e) {
    e.rowElement.style.backgroundColor = "#ddd";
  }

  onEditorPreparingCostTypeTable(e) {
  }

}
