import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OrdersListWithPortfolioComponent } from './orders-list-with-portfolio.component';
import { DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxScrollViewModule, DxTextBoxModule, DxProgressBarModule, DxDropDownBoxModule, DxTreeViewModule, DxTagBoxModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { OrdersListWithPortfolioRoutingModule } from './orders-list-with-portfolio-routing.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    DxSelectBoxModule,
    ModalModule,
    CurrencyFormatterPipeModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxProgressBarModule,
    OrdersListWithPortfolioRoutingModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxTagBoxModule,
    DxAutocompleteModule,
    PDFModule,
    ClipboardModule
  ],
  declarations: [
    OrdersListWithPortfolioComponent
  ]
})
export class OrdersListWithPortfolioModule { }
