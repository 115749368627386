import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;


@Component({
  selector: 'app-create-program',
  templateUrl: './create-program.component.html',
  styleUrls: ['./create-program.component.scss']
})
export class CreateProgramComponent implements OnInit {
  usersList = [];
  formObject: any = {
    programSponsor: {
      id: 0
    },
    programManager: {
      id: 0
    }
  };
  programId;
  isEdit = false;
  statusOptions = [];
  @ViewChild(DxSelectBoxComponent, { static: false }) managerSelect: DxSelectBoxComponent;
  isLocked: boolean = false;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private utilityService: UtilityService,
    private localize: I18nPipe, private spinner: NgxSpinnerService, private authService: AuthService) {
    let sub = this.route.params.subscribe(params => {
      this.programId = params.programId;
      if (this.programId != undefined) {
        this.utilityService.breadcrumb = "Edit Program";

        this.isEdit = true;

        this.apiService.get('/program/' + this.programId).subscribe(response => {
          this.formObject = response;
          //this.managerSelect.instance.option('value', response.programManager.id);
        });
      } else {
        this.utilityService.breadcrumb = "Create Program";
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.formObject.purpose = "";

    this.statusOptions = ['Active', 'On Hold', 'Closed', 'Cancelled'];
  }

  create() {
    this.isLocked = true;
    if (this.formObject.programName != undefined && this.formObject.programName != "") {
      if (this.formObject.purpose != undefined && this.formObject.purpose != "") {
        if (this.formObject.programManager != undefined && this.formObject.programManager.email != "" && this.formObject.programManager.email != undefined) {
          if (this.formObject.programSponsor != undefined && this.formObject.programSponsor.email != "" && this.formObject.programSponsor.email != undefined) {
            this.formObject.hasDraftReport = false;
            this.formObject.isProgramCost = false;
            this.formObject.isAggregatedProgramCost = true;
            this.spinner.show();

            this.apiService.post('/program', this.formObject).subscribe((response: any) => {
              this.apiService.post('/createProgramBudget', { programId: response.id }).subscribe(budgetResponse => {
                this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Program has been saved.'), 'success');
                this.isLocked = false;
                this.spinner.hide();
                this.router.navigate(['/program']);
              });
            });
          }
          else {
            this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Project Sponsor First.'), 'warning');
            this.isLocked = false;
          }
        } else {
          this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Project Manager First.'), 'warning');
          this.isLocked = false;
        }
      } else {
        this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Purpose First.'), 'warning');
        this.isLocked = false;
      }
    } else {
      this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Program Name First.'), 'warning');
      this.isLocked = false;
    }
  }

  save() {
    this.isLocked = true;
    if (this.formObject.programName != undefined && this.formObject.programName != "") {
      if (this.formObject.purpose != undefined && this.formObject.purpose != "") {
        if (this.formObject.programManager != undefined && this.formObject.programManager.email != "" && this.formObject.programManager.email != undefined) {
          if (this.formObject.programSponsor != undefined && this.formObject.programSponsor.email != "" && this.formObject.programSponsor.email != undefined) {
            delete (this.formObject.reports);
            //let pm = this.usersList.filter(user => user.id == this.formObject.programManager.id)[0];
            //this.formObject.programManager = pm;
            this.apiService.put('/program/' + this.programId, this.formObject).subscribe(response => {
              this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Program has been saved.'), 'success');
              this.isLocked = false;
              this.router.navigate(['/program']);
            });
          }
          else {
            this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Project Sponsor First.'), 'warning');
            this.isLocked = false;
          }
        } else {
          this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Project Manager First.'), 'warning');
          this.isLocked = false;
        }
      } else {
        this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Purpose First.'), 'warning');
        this.isLocked = false;
      }
    } else {
      this.utilityService.showNotification(this.localize.transform('Warning'), this.localize.transform('Select a Program Name First.'), 'warning');
      this.isLocked = false;
    }
  }

  programManagerValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.programManager = response;
        }
      });
    }
  }

  programSponsorValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.programSponsor = response;
        }
      });
    }
  }

  projectManagerOnInputEvent(e) {
    this.apiService.get('/user/search/' + this.formObject.programManager.email).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.programSponsor = response;
            } else {
              this.formObject.programSponsor = { id: '' };
            }
          });
        }
      } else {
        this.formObject.programSponsor = { id: '' };
      }
    });
  }
}
