import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';

import { ApprovalsRoutingModule } from './approvals-routing.module';
import { ApprovalsComponent } from './approvals.component';
import { ModalModule } from 'ngx-bootstrap';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxDataGridModule, DxScrollViewModule, DxAutocompleteModule, DxSelectBoxModule, DxDropDownBoxModule, DxTreeViewModule, DxTextBoxModule, DxTagBoxModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PDFModule } from 'app/pdf/pdf.module';
import { MaterialModule } from 'app/material.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';

@NgModule({
  imports: [
    CommonModule,
    ApprovalsRoutingModule,
    ModalModule.forRoot(),
    I18nModule,
    DxDataGridModule,
    DxScrollViewModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    CurrencyFormatterPipeModule,
    DxTreeViewModule,
    FormsModule,
    MaterialModule,
    PDFModule,
    ClipboardModule,
    NgxSpinnerModule,
    MaterialModule,
    DxTextBoxModule,
    DxTagBoxModule
  ],
  declarations: [
    ApprovalsComponent,
  ]
})
export class ApprovalsModule { }
