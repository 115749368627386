/* Excel Point No 4 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfProgramtStatusReportViewRoutingModule } from './pdf-program-status-report-view-routing.module';
import { PdfProgramStatusReportViewComponent } from './pdf-program-status-report-view.component';
import { PDFProgramStatusReportModule } from 'app/programs/program-detail/pdf-program-status-report/pdf-program-status-report.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    PdfProgramtStatusReportViewRoutingModule,
    PDFProgramStatusReportModule,
    NgxSpinnerModule,
    I18nModule
  ],
  declarations: [PdfProgramStatusReportViewComponent]
})
export class PdfProgramStatusReportViewModule { }
/* Excel Point No 4 */
