import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-field-administration',
  templateUrl: './field-administration.component.html',
  styleUrls: ['./field-administration.component.scss']
})
export class FieldAdministrationComponent implements OnInit {
  dataList = [];
  formObj: any = {};
  isEdit = false;
  public modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public inputField = '';
  public inputFieldFr = '';
  public inputFieldDe = '';
  editId = '';

  constructor(private modalService: BsModalService, private utilityService: UtilityService, private apiService: ApiService,
    private localize: I18nPipe, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Field Administration';
    this.utilityService.breadcrumbList = ['Settings', 'Field Administration'];

    this.dataList = [
      {
        field: 'Mandatory Projects',
        data: this.utilityService.mandatoryProjectOptions
      },
      {
        field: 'Business Area',
        data: this.utilityService.businessAreaOptions
      },
      {
        field: 'Business Unit',
        data: this.utilityService.businessUnitOptions
      },
      {
        field: 'Business Segment',
        data: this.utilityService.businessSegmentOptions
      },
      {
        field: 'Reporting Level',
        data: this.utilityService.reportingLevelOptions
      },
      {
        field: 'Classification',
        data: this.utilityService.classificationOptions
      },
      {
        field: 'Project Type',
        data: this.utilityService.projectTypeOptions
      },
      {
        field: 'Strategic Contribution',
        data: this.utilityService.strategicContributionOptions
      },
      {
        field: 'Profitability',
        data: this.utilityService.profitabilityOptions
      },
      {
        field: 'Feasibility',
        data: this.utilityService.feasibilityOptions
      },
      {
        field: 'Project Phase',
        data: this.utilityService.projectPhaseOptions
      },
      {
        field: 'Risk Category',
        data: this.utilityService.risksTopFiveRiskStrategySelectOptions
      },
      {
        field: 'Decision Type',
        data: this.utilityService.decisionTypeOptions
      },
      {
        field: 'Document Type',
        data: this.utilityService.typeOptions
      },
      {
        field: 'Project Methodology',
        data: this.utilityService.projectMethodologyOptions
      },
      {
        field: 'Lessons Category',
        data: this.utilityService.lessonsCategoryOptions
      },
      {
        field: 'Impact',
        data: this.utilityService.impactOptions
      },
      {
        field: 'Digitalization Topic',
        data: this.utilityService.digitalizationTopicOptions
      },
      {
        field: 'Technology',
        data: this.utilityService.technologyOptions
      },
      {
        field: 'Digitalization Focus',
        data: this.utilityService.digitalizationFocusOptions
      },
      {
        field: 'IT Platform',
        data: this.utilityService.itPlatformOptions
      },
      {
        field: 'IT Relevant',
        data: this.utilityService.itRelevantOptions
      }
    ];
  }

  ngOnInit() {
  }

  openEditDialog(template, obj) {
    if (obj.data != undefined) {
      if (obj.data.values != undefined) {
        if (obj.data.values.length > 0) {
          for (let i = 0; i < obj.data.values.length; i++) {
            if (obj.data.values[i] == undefined) {
              obj.data.values.splice(i, 1);
              i--;
            }
          }
        }
      }
    }

    this.formObj = obj;
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    if (!this.isEdit) {
      // this.formObj = {
      //   report: false,
      //   riskCategory: {
      //     id: ''
      //   }
      // };
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  addItemToList(e, obj) {
    let array;

    if (obj.data.field == "Company Name") {
      this.utilityService.companyNameOptions.values.push({
        name: e.target.value,
        position: this.utilityService.companyNameOptions.values[this.utilityService.companyNameOptions.values.length - 1].position + 1
      });
      array = this.utilityService.companyNameOptions.values;
    } else if (obj.data.field == "Mandatory Projects") {
      this.utilityService.mandatoryProjectOptions.values.push({
        name: e.target.value,
        position: this.utilityService.mandatoryProjectOptions.values[this.utilityService.mandatoryProjectOptions.values.length - 1].position + 1
      });
      array = this.utilityService.mandatoryProjectOptions.values;
    } else if (obj.data.field == "Business Area") {
      this.utilityService.businessAreaOptions.values.push({
        name: e.target.value,
        position: this.utilityService.businessAreaOptions.values[this.utilityService.businessAreaOptions.values.length - 1].position + 1
      });
      array = this.utilityService.businessAreaOptions.values;
    } else if (obj.data.field == "Business Unit") {
      this.utilityService.businessUnitOptions.values.push({
        name: e.target.value,
        position: this.utilityService.businessUnitOptions.values[this.utilityService.businessUnitOptions.values.length - 1].position + 1
      });
      array = this.utilityService.businessUnitOptions.values;
    } else if (obj.data.field == "Business Segment") {
      this.utilityService.businessSegmentOptions.values.push({
        name: e.target.value,
        position: this.utilityService.businessSegmentOptions.values[this.utilityService.businessSegmentOptions.values.length - 1].position + 1
      });
      array = this.utilityService.businessSegmentOptions.values;
    } else if (obj.data.field == "Reporting Level") {
      this.utilityService.reportingLevelOptions.values.push({
        name: e.target.value,
        position: this.utilityService.reportingLevelOptions.values[this.utilityService.reportingLevelOptions.values.length - 1].position + 1
      });
      array = this.utilityService.reportingLevelOptions.values;
    } else if (obj.data.field == "Classification") {
      this.utilityService.classificationOptions.values.push({
        name: e.target.value,
        position: this.utilityService.classificationOptions.values[this.utilityService.classificationOptions.values.length - 1].position + 1
      });
      array = this.utilityService.classificationOptions.values;
    } else if (obj.data.field == "Project Type") {
      this.utilityService.projectTypeOptions.values.push({
        name: e.target.value,
        position: this.utilityService.projectTypeOptions.values[this.utilityService.projectTypeOptions.values.length - 1].position + 1
      });
      array = this.utilityService.projectTypeOptions.values;
    } else if (obj.data.field == "Strategic Contribution") {
      this.utilityService.strategicContributionOptions.values.push({
        name: e.target.value,
        position: this.utilityService.strategicContributionOptions.values[this.utilityService.strategicContributionOptions.values.length - 1].position + 1
      });
      array = this.utilityService.strategicContributionOptions.values;
    } else if (obj.data.field == "Profitability") {
      this.utilityService.profitabilityOptions.values.push({
        name: e.target.value,
        position: this.utilityService.profitabilityOptions.values[this.utilityService.profitabilityOptions.values.length - 1].position + 1
      });
      array = this.utilityService.profitabilityOptions.values;
    } else if (obj.data.field == "Feasibility") {
      this.utilityService.feasibilityOptions.values.push({
        name: e.target.value,
        position: this.utilityService.feasibilityOptions.values[this.utilityService.feasibilityOptions.values.length - 1].position + 1
      });
      array = this.utilityService.feasibilityOptions.values;
    } else if (obj.data.field == "Project Phase") {
      this.utilityService.projectPhaseOptions.values.push({
        name: e.target.value,
        position: this.utilityService.projectPhaseOptions.values[this.utilityService.projectPhaseOptions.values.length - 1].position + 1
      });
      array = this.utilityService.projectPhaseOptions.values;
    } else if (obj.data.field == "Risk Category") {
      this.utilityService.risksTopFiveRiskStrategySelectOptions.values.push({
        name: e.target.value,
        position: this.utilityService.risksTopFiveRiskStrategySelectOptions.values[this.utilityService.risksTopFiveRiskStrategySelectOptions.values.length - 1].position + 1
      });
      array = this.utilityService.risksTopFiveRiskStrategySelectOptions.values;
    } else if (obj.data.field == "Decision Type") {
      this.utilityService.decisionTypeOptions.values.push({
        name: e.target.value,
        position: this.utilityService.decisionTypeOptions.values[this.utilityService.decisionTypeOptions.values.length - 1].position + 1
      });
      array = this.utilityService.decisionTypeOptions.values;
    } else if (obj.data.field == "Document Type") {
      this.utilityService.typeOptions.values.push({
        name: e.target.value,
        position: this.utilityService.typeOptions.values[this.utilityService.typeOptions.values.length - 1].position + 1
      });
      array = this.utilityService.typeOptions.values;
    } else if (obj.data.field == "Project Methodology") {
      this.utilityService.projectMethodologyOptions.values.push({
        name: e.target.value,
        position: this.utilityService.projectMethodologyOptions.values[this.utilityService.projectMethodologyOptions.values.length - 1].position + 1
      });
      array = this.utilityService.projectMethodologyOptions.values;
    } else if (obj.data.field == "Lesson Category") {
      this.utilityService.lessonsCategoryOptions.values.push({
        name: e.target.value,
        position: this.utilityService.lessonsCategoryOptions.values[this.utilityService.lessonsCategoryOptions.values.length - 1].position + 1
      });
      array = this.utilityService.lessonsCategoryOptions.values;
    } else if (obj.data.field == "Impact") {
      this.utilityService.impactOptions.values.push({
        name: e.target.value,
        position: this.utilityService.impactOptions.values[this.utilityService.impactOptions.values.length - 1].position + 1
      });
      array = this.utilityService.impactOptions.values;
    } else if (obj.data.field == "Digitalization Topic") {
      this.utilityService.digitalizationTopicOptions.values.push({
        name: e.target.value,
        position: this.utilityService.digitalizationTopicOptions.values[this.utilityService.digitalizationTopicOptions.values.length - 1].position + 1
      });
      array = this.utilityService.digitalizationTopicOptions.values;
    } else if (obj.data.field == "Technology") {
      this.utilityService.technologyOptions.values.push({
        name: e.target.value,
        position: this.utilityService.technologyOptions.values[this.utilityService.technologyOptions.values.length - 1].position + 1
      });
      array = this.utilityService.technologyOptions.values;
    } else if (obj.data.field == "Digitalization Focus") {
      this.utilityService.digitalizationFocusOptions.values.push({
        name: e.target.value,
        position: this.utilityService.digitalizationFocusOptions.values[this.utilityService.digitalizationFocusOptions.values.length - 1].position + 1
      });
      array = this.utilityService.digitalizationFocusOptions.values;
    } else if (obj.data.field == "IT Platform") {
      this.utilityService.itPlatformOptions.values.push({
        name: e.target.value,
        position: this.utilityService.itPlatformOptions.values[this.utilityService.itPlatformOptions.values.length - 1].position + 1
      });
      array = this.utilityService.itPlatformOptions.values;
    } else if (obj.data.field == "IT Relevant") {
      this.utilityService.itRelevantOptions.values.push({
        name: e.target.value,
        position: this.utilityService.itRelevantOptions.values[this.utilityService.itRelevantOptions.values.length - 1].position + 1
      });
      array = this.utilityService.itRelevantOptions.values;
    }

    //this.inputField = '';
    return array;
  }

  showNotification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'success',
    );
  }

  save(obj) {
    if (this.inputField == '' || this.inputField == undefined || this.inputFieldDe == ''
      || this.inputFieldDe == undefined || this.inputFieldFr == '' || this.inputFieldFr == undefined) {
      this.showNotification('Warning', 'Please fill all language fields.');
    } else {
      if (this.inputField == '') {
        return;
      }
      if (this.isEdit == false) {
        let values = this.addItemToList({ target: { value: this.inputField } }, obj);
        this.spinner.show();
        this.apiService.put('/dropdown/' + obj.data.id, {
          values: values,
          de: this.inputFieldDe,
          fr: this.inputFieldFr
        }).subscribe((response: any) => {
          this.inputField = null;
          this.inputFieldDe = null;
          this.inputFieldFr = null;

          if (obj.data.field == "Company Name") {
            this.utilityService.companyNameOptions = response.updatedDropdown;
            this.dataList[0].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Mandatory Projects") {
            this.utilityService.mandatoryProjectOptions = response.updatedDropdown;
            this.dataList[0].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Business Area") {
            this.utilityService.businessAreaOptions = response.updatedDropdown;
            this.dataList[1].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Business Unit") {
            this.utilityService.businessUnitOptions = response.updatedDropdown;
            this.dataList[2].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Business Segment") {
            this.utilityService.businessSegmentOptions = response.updatedDropdown;
            this.dataList[3].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Reporting Level") {
            this.utilityService.reportingLevelOptions = response.updatedDropdown;
            this.dataList[4].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Classification") {
            this.utilityService.classificationOptions = response.updatedDropdown;
            this.dataList[5].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Project Type") {
            this.utilityService.projectTypeOptions = response.updatedDropdown;
            this.dataList[6].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Strategic Contribution") {
            this.utilityService.strategicContributionOptions = response.updatedDropdown;
            this.dataList[7].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Profitability") {
            this.utilityService.profitabilityOptions = response.updatedDropdown;
            this.dataList[8].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Feasibility") {
            this.utilityService.feasibilityOptions = response.updatedDropdown;
            this.dataList[9].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Project Phase") {
            this.utilityService.projectPhaseOptions = response.updatedDropdown;
            this.dataList[10].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Risk Category") {
            this.utilityService.risksTopFiveRiskStrategySelectOptions = response.updatedDropdown;
            this.dataList[11].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Decision Type") {
            this.utilityService.decisionTypeOptions = response.updatedDropdown;
            this.dataList[12].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Document Type") {
            this.utilityService.typeOptions = response.updatedDropdown;
            this.dataList[13].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Project Methodology") {
            this.utilityService.projectMethodologyOptions = response.updatedDropdown;
            this.dataList[14].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Lesson Category") {
            this.utilityService.lessonsCategoryOptions = response.updatedDropdown;
            this.dataList[15].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Impact") {
            this.utilityService.impactOptions = response.updatedDropdown;
            this.dataList[16].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Digitalization Topic") {
            this.utilityService.digitalizationTopicOptions = response.updatedDropdown;
            this.dataList[17].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Technology") {
            this.utilityService.technologyOptions = response.updatedDropdown;
            this.dataList[18].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "Digitalization Focus") {
            this.utilityService.digitalizationFocusOptions = response.updatedDropdown;
            this.dataList[19].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "IT Platform") {
            this.utilityService.itPlatformOptions = response.updatedDropdown;
            this.dataList[20].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          } else if (obj.data.field == "IT Relevant") {
            this.utilityService.itRelevantOptions = response.updatedDropdown;
            this.dataList[21].data = response.updatedDropdown;
            this.formObj.data = response.updatedDropdown;
          }
	  this.spinner.hide();
        });
      } else {
        this.edit(obj);
      }
    }
  }

  deleteItem(id, obj) {
    this.spinner.show();
    this.apiService.delete('/dropdownMapper/' + id).subscribe(response => {
      if (obj.data.field == "Company Name") {
        let objArr = this.utilityService.companyNameOptions.values.filter(val => val.id == id);
        let index = this.utilityService.companyNameOptions.values.indexOf(objArr[0]);
        this.utilityService.companyNameOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.companyNameOptions;
        this.dataList[0].data = this.utilityService.companyNameOptions;
      }
      else if (obj.data.field == "Mandatory Projects") {
        let objArr = this.utilityService.mandatoryProjectOptions.values.filter(val => val.id == id);
        let index = this.utilityService.mandatoryProjectOptions.values.indexOf(objArr[0]);
        this.utilityService.mandatoryProjectOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.mandatoryProjectOptions;
        this.dataList[0].data = this.utilityService.mandatoryProjectOptions;
      } else if (obj.data.field == "Business Area") {
        let objArr = this.utilityService.businessAreaOptions.values.filter(val => val.id == id);
        let index = this.utilityService.businessAreaOptions.values.indexOf(objArr[0]);
        this.utilityService.businessAreaOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.businessAreaOptions;
        this.dataList[1].data = this.utilityService.businessAreaOptions;
      } else if (obj.data.field == "Business Unit") {
        let objArr = this.utilityService.businessUnitOptions.values.filter(val => val.id == id);
        let index = this.utilityService.businessUnitOptions.values.indexOf(objArr[0]);
        this.utilityService.businessUnitOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.businessUnitOptions;
        this.dataList[2].data = this.utilityService.businessUnitOptions;
      } else if (obj.data.field == "Business Segment") {
        let objArr = this.utilityService.businessSegmentOptions.values.filter(val => val.id == id);
        let index = this.utilityService.businessSegmentOptions.values.indexOf(objArr[0]);
        this.utilityService.businessSegmentOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.businessSegmentOptions;
        this.dataList[3].data = this.utilityService.businessSegmentOptions;
      } else if (obj.data.field == "Reporting Level") {
        let objArr = this.utilityService.reportingLevelOptions.values.filter(val => val.id == id);
        let index = this.utilityService.reportingLevelOptions.values.indexOf(objArr[0]);
        this.utilityService.reportingLevelOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.reportingLevelOptions;
        this.dataList[4].data = this.utilityService.reportingLevelOptions;
      } else if (obj.data.field == "Classification") {
        let objArr = this.utilityService.classificationOptions.values.filter(val => val.id == id);
        let index = this.utilityService.classificationOptions.values.indexOf(objArr[0]);
        this.utilityService.classificationOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.classificationOptions;
        this.dataList[5].data = this.utilityService.classificationOptions;
      } else if (obj.data.field == "Project Type") {
        let objArr = this.utilityService.projectTypeOptions.values.filter(val => val.id == id);
        let index = this.utilityService.projectTypeOptions.values.indexOf(objArr[0]);
        this.utilityService.projectTypeOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.projectTypeOptions;
        this.dataList[6].data = this.utilityService.projectTypeOptions;
      } else if (obj.data.field == "Strategic Contribution") {
        let objArr = this.utilityService.strategicContributionOptions.values.filter(val => val.id == id);
        let index = this.utilityService.strategicContributionOptions.values.indexOf(objArr[0]);
        this.utilityService.strategicContributionOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.strategicContributionOptions;
        this.dataList[7].data = this.utilityService.strategicContributionOptions;
      } else if (obj.data.field == "Profitability") {
        let objArr = this.utilityService.profitabilityOptions.values.filter(val => val.id == id);
        let index = this.utilityService.profitabilityOptions.values.indexOf(objArr[0]);
        this.utilityService.profitabilityOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.profitabilityOptions;
        this.dataList[8].data = this.utilityService.profitabilityOptions;
      } else if (obj.data.field == "Feasibility") {
        let objArr = this.utilityService.feasibilityOptions.values.filter(val => val.id == id);
        let index = this.utilityService.feasibilityOptions.values.indexOf(objArr[0]);
        this.utilityService.feasibilityOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.feasibilityOptions;
        this.dataList[9].data = this.utilityService.feasibilityOptions;
      } else if (obj.data.field == "Project Phase") {
        let objArr = this.utilityService.projectPhaseOptions.values.filter(val => val.id == id);
        let index = this.utilityService.projectPhaseOptions.values.indexOf(objArr[0]);
        this.utilityService.projectPhaseOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.projectPhaseOptions;
        this.dataList[10].data = this.utilityService.projectPhaseOptions;
      } else if (obj.data.field == "Risk Category") {
        let objArr = this.utilityService.risksTopFiveRiskStrategySelectOptions.values.filter(val => val.id == id);
        let index = this.utilityService.risksTopFiveRiskStrategySelectOptions.values.indexOf(objArr[0]);
        this.utilityService.risksTopFiveRiskStrategySelectOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.risksTopFiveRiskStrategySelectOptions;
        this.dataList[11].data = this.utilityService.risksTopFiveRiskStrategySelectOptions;
      } else if (obj.data.field == "Decision Type") {
        let objArr = this.utilityService.decisionTypeOptions.values.filter(val => val.id == id);
        let index = this.utilityService.decisionTypeOptions.values.indexOf(objArr[0]);
        this.utilityService.decisionTypeOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.decisionTypeOptions;
        this.dataList[12].data = this.utilityService.decisionTypeOptions;
      } else if (obj.data.field == "Document Type") {
        let objArr = this.utilityService.typeOptions.values.filter(val => val.id == id);
        let index = this.utilityService.typeOptions.values.indexOf(objArr[0]);
        this.utilityService.typeOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.typeOptions;
        this.dataList[13].data = this.utilityService.typeOptions;
      } else if (obj.data.field == "Project Methodology") {
        let objArr = this.utilityService.projectMethodologyOptions.values.filter(val => val.id == id);
        let index = this.utilityService.projectMethodologyOptions.values.indexOf(objArr[0]);
        this.utilityService.projectMethodologyOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.projectMethodologyOptions;
        this.dataList[14].data = this.utilityService.projectMethodologyOptions;
      } else if (obj.data.field == "Lesson Category") {
        let objArr = this.utilityService.lessonsCategoryOptions.values.filter(val => val.id == id);
        let index = this.utilityService.lessonsCategoryOptions.values.indexOf(objArr[0]);
        this.utilityService.lessonsCategoryOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.lessonsCategoryOptions;
        this.dataList[15].data = this.utilityService.lessonsCategoryOptions;
      } else if (obj.data.field == "Impact") {
        let objArr = this.utilityService.impactOptions.values.filter(val => val.id == id);
        let index = this.utilityService.impactOptions.values.indexOf(objArr[0]);
        this.utilityService.impactOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.impactOptions;
        this.dataList[16].data = this.utilityService.impactOptions;
      } else if (obj.data.field == "Digitalization Topic") {
        let objArr = this.utilityService.digitalizationTopicOptions.values.filter(val => val.id == id);
        let index = this.utilityService.digitalizationTopicOptions.values.indexOf(objArr[0]);
        this.utilityService.digitalizationTopicOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.digitalizationTopicOptions;
        this.dataList[17].data = this.utilityService.digitalizationTopicOptions;
      } else if (obj.data.field == "Technology") {
        let objArr = this.utilityService.technologyOptions.values.filter(val => val.id == id);
        let index = this.utilityService.technologyOptions.values.indexOf(objArr[0]);
        this.utilityService.technologyOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.technologyOptions;
        this.dataList[18].data = this.utilityService.technologyOptions;
      } else if (obj.data.field == "Digitalization Focus") {
        let objArr = this.utilityService.digitalizationFocusOptions.values.filter(val => val.id == id);
        let index = this.utilityService.digitalizationFocusOptions.values.indexOf(objArr[0]);
        this.utilityService.digitalizationFocusOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.digitalizationFocusOptions;
        this.dataList[19].data = this.utilityService.digitalizationFocusOptions;
      } else if (obj.data.field == "IT Platform") {
        let objArr = this.utilityService.itPlatformOptions.values.filter(val => val.id == id);
        let index = this.utilityService.itPlatformOptions.values.indexOf(objArr[0]);
        this.utilityService.itPlatformOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.itPlatformOptions;
        this.dataList[20].data = this.utilityService.itPlatformOptions;
      } else if (obj.data.field == "IT Relevant") {
        let objArr = this.utilityService.itRelevantOptions.values.filter(val => val.id == id);
        let index = this.utilityService.itRelevantOptions.values.indexOf(objArr[0]);
        this.utilityService.itRelevantOptions.values.splice(index, 1);
        this.formObj.data = this.utilityService.itRelevantOptions;
        this.dataList[21].data = this.utilityService.itRelevantOptions;
      }
      this.spinner.hide();
    });
  }

  edit(obj) {
    console.log('Edit');
    this.spinner.show();
    this.apiService.put('/dropdownMapper/' + this.editId, {
      dropdown: {
        name: this.inputField
      },
      de: this.inputFieldDe,
      fr: this.inputFieldFr
    }).subscribe((response: any) => {
      if (obj.data.field == "Company Name") {
        let objArr = this.utilityService.companyNameOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.companyNameOptions.values.indexOf(objArr[0]);
        this.utilityService.companyNameOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.companyNameOptions;
        this.dataList[0].data = this.utilityService.companyNameOptions;
      }
      else if (obj.data.field == "Mandatory Projects") {
        let objArr = this.utilityService.mandatoryProjectOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.mandatoryProjectOptions.values.indexOf(objArr[0]);
        this.utilityService.mandatoryProjectOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.mandatoryProjectOptions;
        this.dataList[0].data = this.utilityService.mandatoryProjectOptions;
      } else if (obj.data.field == "Business Area") {
        let objArr = this.utilityService.businessAreaOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.businessAreaOptions.values.indexOf(objArr[0]);
        this.utilityService.businessAreaOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.businessAreaOptions;
        this.dataList[1].data = this.utilityService.businessAreaOptions;
      } else if (obj.data.field == "Business Unit") {
        let objArr = this.utilityService.businessUnitOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.businessUnitOptions.values.indexOf(objArr[0]);
        this.utilityService.businessUnitOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.businessUnitOptions;
        this.dataList[2].data = this.utilityService.businessUnitOptions;
      } else if (obj.data.field == "Business Segment") {
        let objArr = this.utilityService.businessSegmentOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.businessSegmentOptions.values.indexOf(objArr[0]);
        this.utilityService.businessSegmentOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.businessSegmentOptions;
        this.dataList[3].data = this.utilityService.businessSegmentOptions;
      } else if (obj.data.field == "Reporting Level") {
        let objArr = this.utilityService.reportingLevelOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.reportingLevelOptions.values.indexOf(objArr[0]);
        this.utilityService.reportingLevelOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.reportingLevelOptions;
        this.dataList[4].data = this.utilityService.reportingLevelOptions;
      } else if (obj.data.field == "Classification") {
        let objArr = this.utilityService.classificationOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.classificationOptions.values.indexOf(objArr[0]);
        this.utilityService.classificationOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.classificationOptions;
        this.dataList[5].data = this.utilityService.classificationOptions;
      } else if (obj.data.field == "Project Type") {
        let objArr = this.utilityService.projectTypeOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.projectTypeOptions.values.indexOf(objArr[0]);
        this.utilityService.projectTypeOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.projectTypeOptions;
        this.dataList[6].data = this.utilityService.projectTypeOptions;
      } else if (obj.data.field == "Strategic Contribution") {
        let objArr = this.utilityService.strategicContributionOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.strategicContributionOptions.values.indexOf(objArr[0]);
        this.utilityService.strategicContributionOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.strategicContributionOptions;
        this.dataList[7].data = this.utilityService.strategicContributionOptions;
      } else if (obj.data.field == "Profitability") {
        let objArr = this.utilityService.profitabilityOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.profitabilityOptions.values.indexOf(objArr[0]);
        this.utilityService.profitabilityOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.profitabilityOptions;
        this.dataList[8].data = this.utilityService.profitabilityOptions;
      } else if (obj.data.field == "Feasibility") {
        let objArr = this.utilityService.feasibilityOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.feasibilityOptions.values.indexOf(objArr[0]);
        this.utilityService.feasibilityOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.feasibilityOptions;
        this.dataList[9].data = this.utilityService.feasibilityOptions;
      } else if (obj.data.field == "Project Phase") {
        let objArr = this.utilityService.projectPhaseOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.projectPhaseOptions.values.indexOf(objArr[0]);
        this.utilityService.projectPhaseOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.projectPhaseOptions;
        this.dataList[10].data = this.utilityService.projectPhaseOptions;
      } else if (obj.data.field == "Risk Category") {
        let objArr = this.utilityService.risksTopFiveRiskStrategySelectOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.risksTopFiveRiskStrategySelectOptions.values.indexOf(objArr[0]);
        this.utilityService.risksTopFiveRiskStrategySelectOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.risksTopFiveRiskStrategySelectOptions;
        this.dataList[11].data = this.utilityService.risksTopFiveRiskStrategySelectOptions;
      } else if (obj.data.field == "Decision Type") {
        let objArr = this.utilityService.decisionTypeOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.decisionTypeOptions.values.indexOf(objArr[0]);
        this.utilityService.decisionTypeOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.decisionTypeOptions;
        this.dataList[12].data = this.utilityService.decisionTypeOptions;
      } else if (obj.data.field == "Document Type") {
        let objArr = this.utilityService.typeOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.typeOptions.values.indexOf(objArr[0]);
        this.utilityService.typeOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.typeOptions;
        this.dataList[13].data = this.utilityService.typeOptions;
      } else if (obj.data.field == "Project Methodology") {
        let objArr = this.utilityService.projectMethodologyOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.projectMethodologyOptions.values.indexOf(objArr[0]);
        this.utilityService.projectMethodologyOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.projectMethodologyOptions;
        this.dataList[14].data = this.utilityService.projectMethodologyOptions;
      } else if (obj.data.field == "Lesson Category") {
        let objArr = this.utilityService.lessonsCategoryOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.lessonsCategoryOptions.values.indexOf(objArr[0]);
        this.utilityService.lessonsCategoryOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.lessonsCategoryOptions;
        this.dataList[15].data = this.utilityService.lessonsCategoryOptions;
      } else if (obj.data.field == "Impact") {
        let objArr = this.utilityService.impactOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.impactOptions.values.indexOf(objArr[0]);
        this.utilityService.impactOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.impactOptions;
        this.dataList[16].data = this.utilityService.impactOptions;
      } else if (obj.data.field == "Digitalization Topic") {
        let objArr = this.utilityService.digitalizationTopicOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.digitalizationTopicOptions.values.indexOf(objArr[0]);
        this.utilityService.digitalizationTopicOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.digitalizationTopicOptions;
        this.dataList[17].data = this.utilityService.digitalizationTopicOptions;
      } else if (obj.data.field == "Technology") {
        let objArr = this.utilityService.technologyOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.technologyOptions.values.indexOf(objArr[0]);
        this.utilityService.technologyOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.technologyOptions;
        this.dataList[18].data = this.utilityService.technologyOptions;
      } else if (obj.data.field == "Digitalization Focus") {
        let objArr = this.utilityService.digitalizationFocusOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.digitalizationFocusOptions.values.indexOf(objArr[0]);
        this.utilityService.digitalizationFocusOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.digitalizationFocusOptions;
        this.dataList[19].data = this.utilityService.digitalizationFocusOptions;
      } else if (obj.data.field == "IT Platform") {
        let objArr = this.utilityService.itPlatformOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.itPlatformOptions.values.indexOf(objArr[0]);
        this.utilityService.itPlatformOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.itPlatformOptions;
        this.dataList[20].data = this.utilityService.itPlatformOptions;
      } else if (obj.data.field == "IT Relevant") {
        let objArr = this.utilityService.itRelevantOptions.values.filter(val => val.id == this.editId);
        let index = this.utilityService.itRelevantOptions.values.indexOf(objArr[0]);
        this.utilityService.itRelevantOptions.values[index] = response.data[0];
        this.formObj.data = this.utilityService.itRelevantOptions;
        this.dataList[21].data = this.utilityService.itRelevantOptions;
      }

      this.isEdit = false;
      this.editId = '';
      this.inputField = '';
      this.inputFieldDe = '';
      this.inputFieldFr = '';
      this.spinner.hide();
    });
  }

  editItem(item, obj) {
    this.spinner.show();
    this.apiService.get('/dropdownMapper/' + item.id).subscribe((response: any) => {
      this.isEdit = true;
      this.inputField = item.name;
      this.editId = item.id;

      this.inputFieldDe = response.de;
      this.inputFieldFr = response.fr;
      this.spinner.hide();
    });
  }

  resetInputFields() {
    this.isEdit = false;
    this.inputField = null;
    this.inputFieldDe = null;
    this.inputFieldFr = null;
  }

  closeModel() {
    this.modalRef.hide();
    this.resetInputFields();
  }

  savePosition() {
    this.spinner.show();
    this.apiService.post('/dropdownMapper/position', this.formObj.data.values).subscribe(response => {
      this.showNotification('Success', 'Position updated successfully.')
      this.spinner.hide();
    });
  }

  moveRowDown(id) {
    const index = this.formObj.data.values.findIndex(val => val.id == id);

    if (index < this.formObj.data.values.length - 1) {
      this.formObj.data.values[index].position++;
      this.formObj.data.values[index + 1].position--;
      const temp = this.formObj.data.values[index];
      this.formObj.data.values[index] = this.formObj.data.values[index + 1];
      this.formObj.data.values[index + 1] = temp;
    }
  }

  moveRowUp(id) {
    const index = this.formObj.data.values.findIndex(val => val.id == id);

    if (index > 0) {
      this.formObj.data.values[index].position--;
      this.formObj.data.values[index - 1].position++;
      const temp = this.formObj.data.values[index];
      this.formObj.data.values[index] = this.formObj.data.values[index - 1];
      this.formObj.data.values[index - 1] = temp;
    }
  }

}
