import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'currency',
        loadChildren: () => import('./currency/currency.module').then(mod => mod.CurrencyModule),
      },
      {
        path: 'strategic-business-drivers',
        loadChildren: () => import('./strategic-business-drivers/strategic-business-drivers.module').then(mod => mod.StrategicBusinessDriversModule),
      },
      {
        path: 'status-calculation',
        loadChildren: () => import('./status-calculation/status-calculation.module').then(mod => mod.StatusCalculationModule),
      },
      {
        path: 'roles-and-permissions',
        loadChildren: () => import('./roles-and-permissions/roles-and-permissions.module').then(mod => mod.RolesAndPermissionsModule),
      },
      {
        path: 'field-administration',
        loadChildren: () => import('./field-administration/field-administration.module').then(mod => mod.FieldAdministrationModule),
      },
      {
        path: 'email-settings',
        loadChildren: () => import('./email-settings/email-settings.module').then(mod => mod.EmailSettingsModule),
      },
      {
        path: 'user-report',
        loadChildren: () => import('./user-report/user-report.module').then(mod => mod.UserReportModule),
      },
      {
        path: 'help-guide',
        loadChildren: () => import('./help-guide/help-guide.module').then(mod => mod.HelpGuideModule),
      },
      {
        path: 'questions',
        loadChildren: () => import('./questions/questions.module').then(mod => mod.QuestionsModule),
      },
      {
        path: 'workflow',
        loadChildren: () => import('./workflow/workflow.module').then(mod => mod.WorkflowModule),
      },
      {
        path: 'third-party',
        loadChildren: () => import('./third-party/third-party.module').then(mod => mod.ThirdPartyModule),
      },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(mod => mod.NewsModule),
      },
      {
        path: 'errors-log',
        loadChildren: () => import('./errors-log/errors-log.module').then(mod => mod.ErrorsLogModule),
      },
      {
        path: 'yearly-budget',
        loadChildren: () => import('./yearly-budget/yearly-budget.module').then(mod => mod.YearlyBudgetModule)
      },
      {
        path: 'translation',
        loadChildren: () => import('./translation/translation.module').then(mod => mod.TranslationModule),
      },
      {
        path: 'small-order-templates',
        loadChildren: () => import('./small-order-links/small-order-links.module').then(mod => mod.SmallOrderLinksModule),
      }
    ],
    data: {
      allowedRoles: ['pmo', 'admin']
    },
  },
  {
    path: 'view-as',
    loadChildren: () => import('./view-as-projects/view-as-projects.module').then(mod => mod.ViewAsProjectsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
