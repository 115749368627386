import { Component, OnInit, Input } from '@angular/core';
declare var html2canvas: any;
// declare var jsPDF: any;
// import * as html2canvas from 'jspdf';
import * as jsPDF from 'jspdf';
declare var $: any;


@Component({
  selector: 'app-pdf-multi-project-report',
  templateUrl: './pdf-multi-project-report.component.html',
  styleUrls: ['./pdf-multi-project-report.component.scss']
})
export class PdfMultiProjectReportComponent implements OnInit {
  @Input() projects;

  constructor() { }

  ngOnInit() {
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

  download() {
    let imgWidth = 420;
    let pageHeight = 292; //295
    let position = 0;

    const pdf = new jsPDF('l', 'mm', 'a3'); //[200, 800] || 'a3'
    let element = $('#pdfBody').find('.dx-scrollable-content');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    html2canvas(element[0], { scale: 1.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;

      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/png");
      pdf.addImage(image, 0, position, imgWidth, imgHeight + 7);//- 55
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(image, 'PNG', 0, position, imgWidth, imgHeight); //- 28
        heightLeft -= pageHeight;
      }

      pdf.save('Multi Project Report.pdf');
      $('#down-append').append(downloadElem);
    });
  }
}
