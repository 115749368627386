import { Component, OnInit, Renderer, ViewChild, ElementRef, TemplateRef, OnDestroy, } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, } from '@angular/common';
import { UtilityService } from 'app/services/utility.service';
import { AuthService } from 'app/services/auth.service';
import { ApiService } from 'app/services/api.service';
import { SocketService } from 'app/services/socket.service';
import { filter } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { I18nPipe } from '../i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
var globalThis;

declare var $: any;
@Component({
  selector: 'app-navbar-cmp',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  unseen: any = 0;
  notificationsList: any = [];
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  userName: string;
  userMessage: string;
  @ViewChild('app-navbar-cmp', { static: false }) button: any;
  @ViewChild('seenBadge', { static: false }) seenbadge: any;
  public modalRef: BsModalRef;
  projectsList = [];
  isUserPrivileged = false;
  attachmentFile = '';

  constructor(location: Location, private renderer: Renderer, private element: ElementRef, private router: Router,
   public utilityService: UtilityService, public authService: AuthService, private apiService: ApiService, 
   private socketService: SocketService, private modalService: BsModalService, private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.userName = authService.userObj.name;
    globalThis = this;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (this.utilityService.misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      this.utilityService.misc.sidebar_mini_active = false;
      this.utilityService.isCollapseSidebar = false;
    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');
        
        globalThis.utilityService.misc.sidebar_mini_active = true;
        globalThis.utilityService.isCollapseSidebar = false;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    // const simulateWindowResize = setInterval(function () {
    //   window.dispatchEvent(new Event('resize'));
    // }, 180);

    // // we stop the simulation of Window Resize after the animations are completed
    // setTimeout(function () {
    //   clearInterval(simulateWindowResize);
    // }, 1000);
  }

  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');

    } else {
      setTimeout(function () {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      this.utilityService.misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });

    this.fetchNotifications(5);

    if (this.authService.userObj.role == "pmo" || this.authService.userObj.role == "admin") {
      this.isUserPrivileged = true;
      this.socketService.getNotifications().subscribe((notification: any) => {
        if (this.notificationsList.length > 5) {
          this.notificationsList.pop();
        }
        this.notificationsList.unshift(notification);
        this.unseen++;
      })
    }

    this.socketService.listen('notifyAdminsbyEmail').subscribe(response => {
      if (response.message.includes('Email sent')) {
        this.utilityService.showNotification(
          this.localize.transform("Success"),
          this.localize.transform("Issue has been reported"),
          "success",
        );
      } else {
        this.utilityService.showNotification(
          this.localize.transform("Failed"),
          this.localize.transform("Issue could not be reported"),
          "danger",
        );
      }
      this.spinner.hide();
      this.modalRef.hide();
    });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.router.navigate([`/projects/detail/${e.itemData.id}/overview`])
    }
  }

  projectsOnInputEvent(e) {
    this.apiService.get('/reports/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsList = response;
    });
  }

  notifyAdmins() {
    if (this.userMessage != '' && this.userMessage != undefined) {
      this.spinner.show();

      if (this.attachmentFile != undefined && this.attachmentFile != '') {
        let attachment: any = new FormData();
        attachment.append('attachment', this.attachmentFile);

        this.apiService.post('/notifyAdmins', attachment).subscribe((response: any) => {
          this.attachmentFile = '';
          let obj: any = document.getElementById("attact-span");
          obj.value = '';
          let attachments = [response];

          this.socketService.emitEvent('notifyAdminsbyEmail', {
            user: {
              name: this.authService.userObj.name,
              email: this.authService.userObj.email
            },
            message: this.userMessage,
            attachments: attachments
          });
          this.userMessage = "";
        });
      } else {
        this.socketService.emitEvent('notifyAdminsbyEmail', {
          user: {
            name: this.authService.userObj.name,
            email: this.authService.userObj.email
          },
          message: this.userMessage
        });
        this.userMessage = "";
      }
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please describe the problem first."),
        "warning",
      );
    }
  }


  openModal(template: TemplateRef<any>) {
    this.userMessage = "";
    this.attachmentFile = '';
    this.modalRef = this.modalService.show(template);
  }

  fetchNotifications(limit) {
    if (this.authService.userObj.role == "pmo" || this.authService.userObj.role == "admin") {
      this.apiService.get('/getNotifications?limit=' + limit).subscribe((response: any) => {
        this.notificationsList = response.allNotifications;
        this.unseen = response.unseen;
      });
    }
  }

  seenNotifications() {
    this.apiService.get('/seennotifications').subscribe((response: any) => {
      this.fetchNotifications(5);
      this.seenbadge.instance.option('hidden', true)
    });
  }

  async onFileSelected(event) {
    this.attachmentFile = event.target.files[0];
    let obj: any = document.getElementById("attact-span");
    let obj2: any = document.getElementById("pmt-upload");
    obj.value = obj2.value;
  }

  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  };

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
        $layer.remove();
      }

      setTimeout(function () {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function () { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  }

  getTitle() {
    // let titlee: any = this.location.prepareExternalUrl(this.location.path());
    // for (let i = 0; i < this.listTitles.length; i++) {
    //   if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
    //     return this.listTitles[i].title;
    //   } else if (this.listTitles[i].type === "sub") {
    //     for (let j = 0; j < this.listTitles[i].children.length; j++) {
    //       let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
    //       if (subtitle === titlee) {
    //         return this.listTitles[i].children[j].title;
    //       }
    //     }
    //   }
    // }
    return this.utilityService.breadcrumb;
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  ngOnDestroy() {
    this.socketService.removeListener('notifyAdminsbyEmail');
  }
}
