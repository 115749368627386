import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import swal from 'sweetalert2';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { ClipboardService } from 'ngx-clipboard';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-sub-portfolio-status-report',
  templateUrl: './sub-portfolio-status-report.component.html',
  styleUrls: ['./sub-portfolio-status-report.component.scss']
})
export class SubPortfolioStatusReportComponent implements OnInit {
  statusReportTable: any = [];
  subPortfolioObj: any = {
    hasDraftReport: false
  }
  prevStatusReport;
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;

  subPortfolio;
  FrontEnd_PATH;

  constructor(public route: ActivatedRoute, private apiService: ApiService, private router: Router,
    private localize: I18nPipe, private authService: AuthService, private clipboardService: ClipboardService, private spinner: NgxSpinnerService, private utilityService: UtilityService) {
    this.route.params.subscribe(params => {
      if (params.subPortfolio != undefined) {
        this.subPortfolio = params.subPortfolio;
      }
    });

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Status Report'];
    this.apiService.get('/subPortfolio/' + this.subPortfolio).subscribe((response: any) => {
      this.subPortfolioObj = response;

      if (response.hasDraftReport) {
        this.subPortfolioObj.hasDraftReport = response.hasDraftReport;
      }

      this.apiService.get('/subportfolio/statusReports/' + this.subPortfolio).subscribe(response => {
        this.statusReportTable = response;
        this.spinner.hide();
      });
    });

  }

  actionEvent(event, item) {
    let elem: any = document.getElementById('status-' + item.key.id);

    if (event.target.value == "Edit") {
      if (this.authService.userObj.role == 'admin' || this.authService.userObj.role == 'pmo' || (this.subPortfolioObj.subPortfolioManager && this.subPortfolioObj.subPortfolioManager.email == this.authService.userObj.email)) {
        this.router.navigate(['dashboard/portfolio/subPortfolio/'+ this.subPortfolio +'/details/statusReport/createStatusReport/' + this.subPortfolio, { reportId: item.key.id, subPortfolio: this.subPortfolio, lastStatus: this.statusReportTable.length > 1 ? this.statusReportTable[this.statusReportTable.length - 2].id : null }]);
        elem.options.selectedIndex = 0;
      }
    } else if (event.target.value == "View") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        this.openStatusPreview(item.key, item.component.getVisibleRows()[item.rowIndex - 1].key);
      } else {
        this.openStatusPreview(item.key, {});
      }
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "Delete") {
      if (this.authService.userObj.role == 'admin' || this.authService.userObj.role == 'pmo' || (this.subPortfolioObj.subPortfolioManager && this.subPortfolioObj.subPortfolioManager.email == this.authService.userObj.email)) {
        this.deleteReportConfirmation(item.key.id);
        elem.options.selectedIndex = 0;
      }
    } else if (event.target.value == "copy") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        //
        let text = `${this.FrontEnd_PATH}/view/subportfolio/statusReport/${item.component.getVisibleRows()[item.rowIndex - 1].key.id}/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      } else {
        let text = `${this.FrontEnd_PATH}/view/subportfolio/statusReport/0/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      }
      elem.options.selectedIndex = 0;
    }
  }

  openStatusPreview(outline, prev) {
    this.statusReportPreview = outline;
    this.prevStatusReport = prev;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  deleteReportConfirmation(id) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/subportfolioStatusReport/' + id).subscribe(response => {    // statusReports
          let data = this.statusReportTable.filter(report => report.id != id);
          this.statusReportTable = data;

          this.apiService.put('/subPortfolio/' + this.subPortfolio, {
            hasDraftReport: false,
          }).subscribe(response => {
            this.subPortfolioObj.hasDraftReport = false;
            this.createReportDisableChecker();
            swal(
              {
                title: `${this.localize.transform('Success')}`,
                text: `${this.localize.transform('Project Report has been Deleted.')}`,
                type: 'success',
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              }
            );
          });
        });
      }
    });
  }

  createReportDisableChecker(): boolean {
    if ((this.subPortfolioObj.hasDraftReport == true)) {
      return true;
    } else if (this.authService.userObj.role != 'admin' && this.authService.userObj.role != 'pmo' && (this.subPortfolioObj.subPortfolioManager && this.subPortfolioObj.subPortfolioManager.email != this.authService.userObj.email)) {
      return true;
    } else {
      return false;
    }
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

}