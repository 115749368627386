import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxChartComponent, DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var moment: any;

let globalThis;

@Component({
  selector: 'app-project-eva',
  templateUrl: './project-eva.component.html',
  styleUrls: ['./project-eva.component.scss']
})
export class ProjectEVAComponent implements OnInit, AfterViewInit {
  evaList = [];
  @Input() isMember;
  @Output() updateReport = new EventEmitter<any>();
  @Input() isProjectUneditable;
  @ViewChild('earnedValueAnalysis', { static: false }) earnedValueAnalysisElem: DxChartComponent;
  @ViewChild('performanceIndex', { static: false }) performanceIndexElem: DxChartComponent;
  evaDateDurationChecked: boolean = false;
  isDateDurationDisabled: boolean = true;
  evaStartDate = new Date;
  evaEndDate: any;
  minimumDate = '';
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isChartShow: boolean = false;
  reportId;
  report;
  isVisible: boolean = false;
  isEVAStartDateValid: boolean = true;
  isEVAEndDateValid: boolean = true;

  constructor(private apiService: ApiService, private utilityService: UtilityService,
    public authService: AuthService, private localize: I18nPipe, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    globalThis = this;
  }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.reportId = params.id;

      this.apiService.get('/reports/' + this.reportId).subscribe((projectData: any) => {
        this.report = projectData;
        this.utilityService.breadcrumbList = ['My Projects', this.report.projectName, 'Details', 'EVA'];

        if (this.report.EVA != undefined) {
          this.evaList = this.report.EVA;
        }

    if (this.report.evaDateDurationChecked != undefined) {
      this.evaDateDurationChecked = this.report.evaDateDurationChecked;
    }

    if (this.report.evaStartDate != undefined) {
      this.evaStartDate = this.report.evaStartDate
    }
    if (this.report.evaEndDate != undefined) {
      this.evaEndDate = this.report.evaEndDate
    }

    this.checkDateDurationEnablity();

        this.minimumDate = moment(this.evaStartDate).add('days', 1);

        this.isVisible = true;
        this.spinner.hide();
      });
    });
  }

  ngAfterViewInit(): void {
    this.isChartShow = true;
  }

  evaValidation(): boolean {
    if (!this.isEVAStartDateValid) {
      this.showNotificationBox('Please enter eva start date in correct format (dd.MM.yyyy) first.')
      return false;
    }
    if (!this.isEVAEndDateValid) {
      this.showNotificationBox('Please enter eva end date in correct format (dd.MM.yyyy) first.')
      return false;
    }
    else {
      return true;
    }
  }

  saveEVA() {
   this.spinner.show();
   
    if (this.evaValidation()) {
      this.report.evaDateDurationChecked = this.evaDateDurationChecked;
      this.report.evaStartDate = this.evaStartDate;
      this.report.evaEndDat = this.evaEndDate;

      this.apiService.put('/reports/' + this.report.id, {
        EVA: this.evaList,
        evaDateDurationChecked: this.evaDateDurationChecked,
        evaStartDate: this.evaStartDate,
        evaEndDate: this.evaEndDate
      }).subscribe(response => {
        this.updateReport.emit({
          EVA: this.evaList
        });
        this.refreshCharts();

        this.utilityService.showNotification(this.localize.transform("Save Success"),
          this.localize.transform("EVA has been saved."), "success");
	  this.spinner.hide();
      });
    }
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  calculateCV(e) {
    if (globalThis.evaList[e.index] != undefined) {
      if (e.EV != 0 || e.AC != 0) {
        let val: any = ((e.EV * 1) - (e.AC * 1)).toFixed(2);
        globalThis.evaList[e.index].CV = val * 1 == 0 ? null : val * 1;
      } else {
        globalThis.evaList[e.index].CV = "";
      }
    }
    let val: any = ((e.EV * 1) - (e.AC * 1)).toFixed(2);
    return val * 1 == 0 ? null : val * 1;
  }

  calculateSV(e) {
    let val: any = ((e.EV * 1) - (e.PV * 1)).toFixed(2);
    if (globalThis.evaList[e.index] != undefined)
      if (e.EV != 0 || e.PV != 0) {
        globalThis.evaList[e.index].SV = val * 1 == 0 ? null : val * 1;
      } else {
        globalThis.evaList[e.index].SV = "";
      }
    return val * 1 == 0 ? null : val * 1
  }

  calculateCPI(e) {
    if ((e.AC * 1) != 0) {
      let val: any = ((e.EV * 1) / (e.AC * 1)).toFixed(2);
      if (globalThis.evaList[e.index] != undefined)
        if (e.EV != 0 && e.AC != 0) {
          globalThis.evaList[e.index].CPI = val * 1 == 0 ? null : val * 1;
        } else {
          globalThis.evaList[e.index].CPI = "";
        }
      return val * 1 == 0 ? null : val * 1;
    }
    else {
      return (e.EV * 1) == 0 ? null : (e.EV * 1).toFixed(2);
    }
  }

  calculateSPI(e) {
    if ((e.PV * 1) != 0) {
      let val: any = ((e.EV * 1) / (e.PV * 1)).toFixed(2);
      if (globalThis.evaList[e.index] != undefined) {
        if (e.EV != 0 && e.PV != 0) {
          globalThis.evaList[e.index].SPI = val * 1 == 0 ? null : val * 1;
        } else {
          globalThis.evaList[e.index].SPI = "";
        }
      }
      return val * 1 == 0 ? null : val * 1;
    }
    else
      return (e.EV * 1) == 0 ? null : (e.EV * 1).toFixed(2);
  }

  calculateETC(e) {
    let val: any = ((e.EAC * 1) - (e.AC * 1)).toFixed(2);
    if (globalThis.evaList[e.index] != undefined) {
      if (e.EAC != 0 || e.AC != 0) {
        globalThis.evaList[e.index].ETC = val * 1 == 0 ? null : val * 1;
      } else {
        globalThis.evaList[e.index].ETC = "";
      }
    }
    return val * 1 == 0 ? null : val * 1;
  }

  calculateEAC(e) {
    if ((e.CPI * 1) != 0) {
      let val: any = ((e.BAC * 1) / (e.CPI * 1)).toFixed(2);
      if (globalThis.evaList[e.index] != undefined) {
        if (e.BAC != 0 && e.CPI != 0) {
          globalThis.evaList[e.index].EAC = val * 1 == 0 ? null : val * 1;
        } else {
          globalThis.evaList[e.index].EAC = "";
        }
      }
      return val * 1 == 0 ? null : val * 1;
    }
    else
      return (e.BAC * 1) == 0 ? null : (e.BAC * 1).toFixed(2);
  }

  calculateVAC(e) {
    let val: any = ((e.BAC * 1) - (e.EAC * 1)).toFixed(2);
    if (globalThis.evaList[e.index] != undefined) {
      if (e.BAC != 0 || e.EAC != 0) {
        globalThis.evaList[e.index].VAC = val * 1 == 0 ? null : val * 1;
      } else {
        globalThis.evaList[e.index].VAC = "";
      }
    }
    return val * 1 == 0 ? null : val * 1;
  }

  refreshCharts() {
    this.earnedValueAnalysisElem.instance.option('dataSource', this.evaList);
    this.performanceIndexElem.instance.option('dataSource', this.evaList);
  }

  onRowUpdated(e) {
    if (this.evaList[e.key.index][Object.keys(e.data)[0]] == "0" || this.evaList[e.key.index][Object.keys(e.data)[0]] == 0) {
      this.evaList[e.key.index][Object.keys(e.data)[0]] = null;
    }
  }

  onCellPrepared(e) {
    if (e.columnIndex > 6) {
      e.cellElement.style.backgroundColor = "#f7f5f5";
    }

    //Header Hints
    if (e.rowType == 'header') {
      e.cellElement.mousemove = function () {
        if (e.cellElement.lastElementChild.innerHTML == 'EV') {
          e.cellElement.setAttribute('title', `To report the accomplishments of the project, you must apply Earned Value (EV) to the figures and calculations in the project. EV is the quantification of the “worth” of the work done to date. In other words, EV tells you, in physical terms, what the project has accomplished. As with PV and AC, EV can be presented in a Cumulative and Current fashion. Cumulative EV is the sum of the budget for the activities accomplished to date. 
          Planned Value (PV) is determined by the cost and schedule baseline. Actual Cost (AC) is determined by the actual cost incurred on the project. Earned Value (EV) tells you, in physical terms, what the project accomplished.
          Please enter the cumulative earned value to date.
          
          Example: Project duration 3 months with actual cost to date is 16000 USD.
          Jan 2017 = 9500
          Feb 2017 = 13000
          Mar 2017 = 16000
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'PV') {
          e.cellElement.setAttribute('title', `Planned Value (PV) describes how far along project work is supposed to be at any given point in the project schedule and cost estimate. Cost and Schedule baseline refers to the physical work scheduled and the approved budget to accomplish the scheduled work.
          Cumulative PV is the sum of the approved budget for activities scheduled to be performed to date. Please enter the cumulative planned value.
          
          Example: Project duration 3 months with total planned budget of 16000 USD.
          Jan 2017 = 10000
          Feb 2017 = 12000
          Mar 2017 = 16000
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'CV') {
          e.cellElement.setAttribute('title', `Cost Variance = Earned Value – Actual Cost`);
        } else if (e.cellElement.lastElementChild.innerHTML == 'SV') {
          e.cellElement.setAttribute('title', `Schedule Variance = Earned Value – Planned Value`);
        } else if (e.cellElement.lastElementChild.innerHTML == 'CPI') {
          e.cellElement.setAttribute('title', `Cost Performance Index = Earned Value / Actual Cost
          CPI > 1 = Best result because project is under budget e.g 1.10
          CPI = 1 = Project is on budget
          CPI <1 = Project is over budget e.g 0.80
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'SPI') {
          e.cellElement.setAttribute('title', `Schedule Performance Index	= Earned Value / Planned Value
          SPI > 1 = Best result because project is ahead of schedule e.g 1.10
          SPI = 1 = Project is on schedule
          SPI <1 = Project is behind schedule e.g 0.80
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'EAC') {
          e.cellElement.setAttribute('title', `Estimate at Completion = Actual Cost + Management ETC)
        Management ETC is added to the to-date cost (AC) to determine the final EAC.
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'ETC') {
          e.cellElement.setAttribute('title', `Estimated to Completion = Bottom up cost estimation (Management ETC)
        On activity level, find the cost of each activity for the remaining work, and add them to get the total cost of the remaining work.
        There is no formula for the bottom up cost estimation technique.
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'VAC') {
          e.cellElement.setAttribute('title', `Variance at Completion (VAC) = BAC - EAC 
        Is the difference between the Budget at Completion (BAC) and the Estimate at Completion.
          
          Negative VAC = Overrun Project
          Positive VAC   = Underrun Project
          `);
        } else if (e.cellElement.lastElementChild.innerHTML == 'BAC') {
          e.cellElement.setAttribute('title', `Budget at Completion (BAC) is the sum of all budgets allocated to a project scope. The Project BAC must always equal the Project Total PV. If they are not equal, your earned value calculations and analysis will be inaccurate.`);
        } else if (e.cellElement.lastElementChild.innerHTML == 'AC') {
          e.cellElement.setAttribute('title', `Actual Cost (AC), is actual expenditures, is the cost incurred for executing work on a project. This figure tells you what you have spent and, as with Planned Value, can be looked at in terms of cumulative and current. Cumulative AC is the sum of the actual cost for activities performed to date. Please enter the cumulative actual cost.
          Example: Project duration 3 months with actual cost to date is 16000 USD.
          Jan 2017 = 8000
          Feb 2017 = 11000
          Mar 2017 = 16000
          `);
        }
      };
      e.cellElement.mousemove();
    }
  }

  startDateValueChanged(e) {
    this.minimumDate = moment(this.evaStartDate).add('days', 1);

    if (this.evaList.length > 0) {
      let prevStartDate = moment(this.evaList[0].month);
      let dateStart = moment(this.evaStartDate);

      if (dateStart > prevStartDate) {
        let startDate = dateStart.toString();
        let index = this.evaList.find(val => val.month == startDate).index;
        if (index != 0) {
          index = index - 1;
        }
        if (index > -1) {
          for (let i = index; i >= 0; i--) {
            if (this.evaList[i].month == startDate) {
              break;
            }
            this.evaList.splice(i, 1);
          }
        }

        for (let j = 0; j < this.evaList.length; j++) {
          this.evaList[j].index = j;
        }

        this.dataGrid.instance.option('dataSource', this.evaList);
      }
      else {
        let datePrevStart = prevStartDate;//.add(-1, 'month');
        let idx = this.evaList.length - 1;

        while (datePrevStart >= dateStart) {
          datePrevStart.add(-1, 'month');
          this.evaList.unshift({
            month: datePrevStart.toString(),
            PV: null,
            BAC: null,
            EV: null,
            AC: null,
            CV: null,
            SV: null,
            CPI: null,
            SPI: null,
            ETC: null,
            EAC: null,
            VAC: null,
            index: ++idx,
          });
        }

        for (let j = 0; j < this.evaList.length; j++) {
          this.evaList[j].index = j;
        }

        this.dataGrid.instance.option('dataSource', this.evaList);
      }
    }
  }

  endDateValueChanged(e) {
    if (this.evaList.length > 0) {
      let prevEndDate = moment(this.evaList[this.evaList.length - 1].month).add(1, 'month');
      let dateEnd = moment(this.evaEndDate);

      if (dateEnd > prevEndDate) {
        let datePrevEnd = prevEndDate;//.add(1, 'month');
        let idx = this.evaList.length - 1;

        while (datePrevEnd <= dateEnd) {
          this.evaList.push({
            month: datePrevEnd.toString(),
            PV: null,
            BAC: null,
            EV: null,
            AC: null,
            CV: null,
            SV: null,
            CPI: null,
            SPI: null,
            ETC: null,
            EAC: null,
            VAC: null,
            index: ++idx,
          });
          datePrevEnd.add(1, 'month');
        }
        this.dataGrid.instance.option('dataSource', this.evaList);
      }
      else if (dateEnd < prevEndDate) {
        let newEndDate = moment(this.evaEndDate).toString();
        let index = this.evaList.find(val => val.month == newEndDate).index;
        index++;
        if (index > 0) {
          for (let i = index; i < this.evaList.length;) {
            this.evaList.splice(i, 1);
          }
        }
        this.dataGrid.instance.option('dataSource', this.evaList);
      }
    }
    else {
      let dateStart = moment(this.evaStartDate);
      let dateEnd = moment(this.evaEndDate);

      let idx = 0;
      while (dateStart <= dateEnd) {
        this.evaList.push({
          month: dateStart.toString(),
          PV: null,
          BAC: null,
          EV: null,
          AC: null,
          CV: null,
          SV: null,
          CPI: null,
          SPI: null,
          ETC: null,
          EAC: null,
          VAC: null,
          index: idx++,
        });
        dateStart.add(1, 'month');
      }
    }
  }

  DateDurationCheckChanged(e) {
    this.checkDateDurationEnablity();
  }

  checkDateDurationEnablity() {
    if (this.evaDateDurationChecked == true) {
      this.isDateDurationDisabled = false;
    }
    else {
      this.isDateDurationDisabled = true;
    }
  }
}
