import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs-compat/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs-compat/operators/distinctUntilChanged';
import { switchMap } from 'rxjs-compat/operators/switchMap';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  projectsList;
  portfolioList;
  backupList;
  stateStore;
  portfoliosCount: number = 0;
  portfoliosSearch: any = '';
  portfolioSubject = new Subject<any>();
  isPrivileged = false;
  isVisible: boolean = false;
  userRole;
  portfolioPageIndex = 2;

  constructor(private apiService: ApiService, private utilityService: UtilityService, private route: ActivatedRoute,
    private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Portfolio';

    if (authService.userObj.role == 'admin' || authService.userObj.role == 'pmo') {
      this.isPrivileged = true;
    } else {
      this.isPrivileged = false;
    }

    this.stateStore = {
      enabled: true,
      storageKey: 'portfolioList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.userRole = this.authService.userObj.role;
  }

  ngOnInit() {
    this.utilityService.breadcrumbList = ['Dashboard', 'Portfolios'];
    this.utilityService.portfolioTab.next({ tabIndex: 5 });

    this.socketService.listen('portfoliosCount').subscribe(count => {
      this.portfoliosCount = count;

      if (count > 20) {
        this.socketService.emitEvent('portfoliosIndex', { pageIndex: this.portfolioPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('portfoliosIndex').subscribe((response: any) => {
      this.portfolioList = this.portfolioList.concat(response);

      if (this.portfolioPageIndex < (this.portfoliosCount / 20)) {
        this.portfolioPageIndex++;
        this.socketService.emitEvent('portfoliosIndex', { pageIndex: this.portfolioPageIndex, pageSize: 20 });
      }
    });

    this.fetchPortfolios();
  }

  fetchPortfolios() {
    this.spinner.show();
    this.apiService.get('/portfolio?limit=20').subscribe(response => {
      this.portfolioList = response;
      this.socketService.emitEvent('portfoliosCount', {});
      this.isVisible = true;
      this.spinner.hide();
    })
  }

  filterProjects(data) {
    this.dataGrid.instance.filter(["portfolio.name", "=", data.value.name]);
  }

  ngOnDestroy() {
    this.socketService.removeListener('portfoliosCount');
    this.socketService.removeListener('portfoliosIndex');
    this.socketService.removeListener('portfoliosSearchIndex');
    this.socketService.removeListener('portfoliosSearch');
  }
}
