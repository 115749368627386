import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxPieChartComponent } from 'devextreme-angular';
import * as jsPDF from 'jspdf';
import { ApiService } from '../../services/api.service';
declare var $: any;
declare var html2canvas: any;
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
let globalThis;


@Component({
  selector: 'app-pdf-small-order',
  templateUrl: './pdf-small-order.component.html',
  styleUrls: ['./pdf-small-order.component.scss']
})
export class PdfSmallOrderComponent implements OnInit {
  @Input() project: any = {};
  estimatedProjectCostChart = [];
  strategicContributionSlider = 0;
  feasibilitySlider = 0;
  profitabilitySlider = 0;
  @ViewChild('estimatedProjectCostChart', { static: false }) estimatedProjectCostChartElem: DxPieChartComponent;
  projectName = '';
  dateDiffDays: any;
  dateDiffMonths: any;
  dateDiffYears: any;
  showLoading: boolean = false;
  itPlatform1;
  itPlatform2;
  itPlatform3;

  constructor(private apiService: ApiService, private authService: AuthService, private utilityService: UtilityService) {
    globalThis = this;
  }

  ngOnInit() {
    this.projectName = this.project.name;

    if (this.project.fundsApprovedForInitiationTable) {
      this.project.fundsApprovedForInitiationTableData = this.project.fundsApprovedForInitiationTable;
    }

    if (this.project.budget) {
      this.estimatedProjectCostChart = [{
        name: 'CAPEX',
        value: +this.project.budget[0].budget + +this.project.budget[1].budget,
      }, {
        name: 'OPEX',
        value: +this.project.budget[2].budget + +this.project.budget[3].budget,
      }];
    }

    if (this.project.strategicContribution) {
      if (this.project.strategicContribution.name == "Low") {
        this.strategicContributionSlider = 33.33;
      } else if (this.project.strategicContribution.name == "Medium") {
        this.strategicContributionSlider = 66.67;
      } else if (this.project.strategicContribution.name == "High") {
        this.strategicContributionSlider = 100;
      }
    }

    if (this.project.feasibility.name == "Low") {
      this.feasibilitySlider = 33.33;
    } else if (this.project.feasibility.name == "Medium") {
      this.feasibilitySlider = 66.67;
    } else if (this.project.feasibility.name == "High") {
      this.feasibilitySlider = 100;
    }

    if (this.project.profitability.name == "Low") {
      this.profitabilitySlider = 33.33;
    } else if (this.project.profitability.name == "Medium") {
      this.profitabilitySlider = 66.67;
    } else if (this.project.profitability.name == "High") {
      this.profitabilitySlider = 100;
    }

    let startDate: any = moment(this.project.startDate);
    let endDate: any = moment(this.project.endDate);
    this.dateDiffDays = endDate.diff(startDate, 'days');
    this.dateDiffMonths = endDate.diff(startDate, 'month');
    this.dateDiffYears = endDate.diff(startDate, 'years');

    setTimeout(() => {
      this.estimatedProjectCostChartElem.instance.option('dataSource', this.estimatedProjectCostChart);
    }, 100);

    if (this.project.projectId == undefined) {
      this.apiService.get('/projectOutline/' + this.project.id).subscribe((response: any) => {
        this.project.projectId = response.projectId;
      });
    }

    if (this.project.itPlatform.length > 0) {
      this.project.itPlatform.forEach((itPlatform, idx) => {
        if (!itPlatform.id) {
          let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (idx == 0) {
            this.itPlatform1 = itPlatformObj.name;
          } else if (idx == 1) {
            this.itPlatform2 = itPlatformObj.name;
          } else if (idx == 2) {
            this.itPlatform3 = itPlatformObj.name;
          }
        }
      });
    }
  }

  customizePoint(point) {
    if (point.argument == "CAPEX") {
      return {
        color: "rgb(117, 101, 164)"
      }
    } else if (point.argument == "OPEX") {
      return {
        color: "rgb(120, 182, 217)"
      }
    }
  }

  download() {
    let imgWidth = 210;
    let pageHeight = 250; //295
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4'); //[200, 800]

    let element = $('#smallOrderPDF');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    this.showLoading = true;

    html2canvas(element[0], { scale: 2.5 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position - 45, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      pdf.save('Small Order.pdf');
      globalThis.showLoading = false;
      element.children().children().append(downloadElem);
    });
  }

  onEditorPreparingGoalsTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  onEditorPreparingDeliverablesTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  onEditorPreparingOutOfScopeTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  attachmentChecker(name: string): boolean {
    if (name == "" || name == undefined) {
      return false;
    } else {
      return true;
    }
  }
}
