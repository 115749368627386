import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-project-history',
  templateUrl: './project-history.component.html',
  styleUrls: ['./project-history.component.scss']
})
export class ProjectHistoryComponent implements OnInit {
  historyList = [];

  constructor(private route: ActivatedRoute, private apiService: ApiService, 
    private utilityService: UtilityService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'History';
   }


   ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      if (params.id != undefined) {
        this.apiService.get('/reports/' + params.id).subscribe((response: any) => {
          this.apiService.get('/projects/' + response.project.id).subscribe((projectResponse: any) => {
            this.historyList = projectResponse.history;
            this.utilityService.breadcrumbList = ['My Projects', projectResponse.projectName, 'History'];
            this.spinner.hide();
          });
        });
      }
    });
  }
}
