import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;
  private accessToken: string;
  // public BASE_PATH = "https://localhost:5000";
  // public BASE_PATH = "https://109.203.124.158:5000";
  public BASE_PATH = "https://109.203.126.97:5000";
  // public BASE_PATH = "https://5.77.39.140:5000";
  // public BASE_PATH = "https://megowork.co.uk:5000";
  // public BASE_PATH = "https://159.100.251.162:5000"; // Prod 2
  // public BASE_PATH = "https://socket.bkw-oneview.com";

  constructor() {
    if (this.accessToken == undefined) {
      this.accessToken = localStorage.getItem('token');
    }

    this.socket = io(this.BASE_PATH, {
      query: { token: this.accessToken }
    });

    this.socket.on('connect', () => {
      console.log('Connected to server with ID: ' + this.socket);
    });
  }

  public getNotifications = () => {
    return Observable.create((observer) => {
      this.socket.on('notification', (message) => {
        observer.next(message);
      });
    });
  }

  listen(event: string): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });
    });
  }

  removeListener(event: string): void {
    this.socket.removeListener(event);
  }

  emitEvent(eventName: any, data: any) {
    this.socket.emit(eventName, data);
  }
}
