import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxPieChartComponent } from 'devextreme-angular';
import * as jsPDF from 'jspdf';
import { ApiService } from '../../services/api.service';
declare var $: any;
declare var html2canvas: any;
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
let globalThis;

@Component({
  selector: 'app-pdf-outline',
  templateUrl: './pdf-outline.component.html',
  styleUrls: ['./pdf-outline.component.scss']
})
export class PdfOutlineComponent implements OnInit {
  @Input() project: any = {};
  estimatedProjectCostChart = [];
  strategicContributionSlider = 0;
  feasibilitySlider = 0;
  profitabilitySlider = 0;
  @ViewChild('estimatedProjectCostChart', { static: false }) estimatedProjectCostChartElem: DxPieChartComponent;
  projectName = '';
  dateDiffDays: any;
  dateDiffMonths: any;
  dateDiffYears: any;
  showLoading: boolean = false;
  itPlatform1;
  itPlatform2;
  itPlatform3;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private authService: AuthService, private utilityService: UtilityService, private spinner: NgxSpinnerService, private localize: I18nPipe) {
    globalThis = this;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
  }

  ngOnInit() {
    this.projectName = this.project.projectName || this.project.projectId.projectName;

    if (this.project.fundsApprovedForInitiationTable) {
      this.project.fundsApprovedForInitiationTableData = this.project.fundsApprovedForInitiationTable;
    }

    if (this.project.estimatedProjectTable) {

      this.estimatedProjectCostChart = [{
        name: 'CAPEX',
        value: +this.project.estimatedProjectTable[0].budget + +this.project.estimatedProjectTable[1].budget,
      }, {
        name: 'OPEX',
        value: +this.project.estimatedProjectTable[2].budget + +this.project.estimatedProjectTable[3].budget,
      }];
    }

    if (this.project.strategicContribution) {
      if (this.project.strategicContribution.name == "Low") {
        this.strategicContributionSlider = 33.33;
      } else if (this.project.strategicContribution.name == "Medium") {
        this.strategicContributionSlider = 66.67;
      } else if (this.project.strategicContribution.name == "High") {
        this.strategicContributionSlider = 100;
      }
    }

    if (this.project.feasibility.name == "Low") {
      this.feasibilitySlider = 33.33;
    } else if (this.project.feasibility.name == "Medium") {
      this.feasibilitySlider = 66.67;
    } else if (this.project.feasibility.name == "High") {
      this.feasibilitySlider = 100;
    }

    if (this.project.profitability.name == "Low") {
      this.profitabilitySlider = 33.33;
    } else if (this.project.profitability.name == "Medium") {
      this.profitabilitySlider = 66.67;
    } else if (this.project.profitability.name == "High") {
      this.profitabilitySlider = 100;
    }

    let startDate: any = moment(this.project.startDate);
    let endDate: any = moment(this.project.endDate);
    this.dateDiffDays = endDate.diff(startDate, 'days');
    this.dateDiffMonths = endDate.diff(startDate, 'month');
    this.dateDiffYears = endDate.diff(startDate, 'years');

    setTimeout(() => {
      this.estimatedProjectCostChartElem.instance.option('dataSource', this.estimatedProjectCostChart);
    }, 100);

    if (this.project.projectId == undefined) {
      this.apiService.get('/projectOutline/' + this.project.id).subscribe((response: any) => {
        this.project.projectId = response.projectId;
      });
    }

    if (this.project.itPlatform.length > 0) {
      this.project.itPlatform.forEach((itPlatform, idx) => {
        if (!itPlatform.id) {
          let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (idx == 0) {
            this.itPlatform1 = itPlatformObj.name;
          } else if (idx == 1) {
            this.itPlatform2 = itPlatformObj.name;
          } else if (idx == 2) {
            this.itPlatform3 = itPlatformObj.name;
          }
        }
      });
    }
  }

  customizePoint(point) {
    if (point.argument == "CAPEX") {
      return {
        color: "rgb(117, 101, 164)"
      }
    } else if (point.argument == "OPEX") {
      return {
        color: "rgb(120, 182, 217)"
      }
    }
  }

  saveComments(obj, elem) {
    if (this.project.id) {
      this.spinner.show();
      this.apiService.put('/projectOutline/' + this.project.id, obj).subscribe(response => {
        this.notification("Success", "Comment Updated", "success");
        this.spinner.hide();
        elem.instance.hide();
      });
    } else {
      elem.instance.hide();
      this.notification("Failed", "Save your outline first to add comments", "warning");
    }

  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  download() {
    let imgWidth = 210;
    let pageHeight = 295; //295
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4'); //[200, 800]
    let element = $('#outlinePDF');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    let copylinkElem = element.find('#copylink')[0];
    if (copylinkElem) {
      copylinkElem.remove();
    }

    this.showLoading = true;

    html2canvas(element[0], { scale: 2.5 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position, imgWidth, imgHeight); //  - 73
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 3, imgWidth, imgHeight); // - 35, - 10
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 6, imgWidth, imgHeight); // - 35, - 10
      heightLeft -= pageHeight;

      pdf.save('Outline Report.pdf');
      globalThis.showLoading = false;
      element.children().children().append(downloadElem);
      if (copylinkElem) {
        element.children().children().append(copylinkElem);
      }
    });
  }

  onEditorPreparingGoalsTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  onEditorPreparingDeliverablesTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  onEditorPreparingOutOfScopeTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";

      e.editorOptions.onValueChanged = function (ea) {
        e.setValue(ea.value);
      };
    }
  }

  attachmentChecker(name: string): boolean {
    if (name == "" || name == undefined) {
      return false;
    } else {
      return true;
    }
  }
}
