import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'app/services/notifications.service';
declare var $: any;

@Component({
  selector: 'app-change-request-preview',
  templateUrl: './change-request-preview.component.html',
  styleUrls: ['./change-request-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeRequestPreviewComponent implements OnInit {
  id: any;
  changeRequestPreview;
  changeRequestObj;
  disableBtn: boolean = true
  showChangePreview = false;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  commentsArr = [];
  commentMsg = '';
  commentsProjectId;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private authService: AuthService, private modalService: BsModalService,
    private spinner: NgxSpinnerService, private notificationService: NotificationsService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.openChangeRequestPreview();
    });
  }

  openChangeRequestPreview() {
    this.showChangePreview = false;
    this.apiService.get('/outlineApproval/' + this.id).subscribe((response: any) => {
      this.changeRequestObj = response
      this.showComments();

      if (response.changeRequest.project.projectReport == undefined) {
        this.apiService.get('/projects/' + response.changeRequest.project).subscribe(projectResponse => {
          response.changeRequest.project = projectResponse;
          this.apiService.get('/reports/' + response.changeRequest.project.projectReport.id).subscribe(reportResponse => {
            response.changeRequest.project.projectReport = reportResponse;
            response.changeRequest = this.utilityService.setChangeRequestPdfValues(response.changeRequest);
            this.changeRequestPreview = response.changeRequest;

            setTimeout(() => {
              this.showChangePreview = true;
            }, 100);

            setTimeout(() => {
              if (this.changeRequestObj.isFreezed == true) {
                this.disableBtn = true;
              } else {
                this.disableBtn = false;
              }
            }, 100);

            this.spinner.hide();

            this.modalRef = this.modalService.show(this.lgChangeModal, this.config);
            this.modalService.onHidden.subscribe((reason: string) => {
              this.showChangePreview = false;
            });
          });
        });
      } else {
        this.apiService.get('/reports/' + response.project.projectReport).subscribe((reportObj: any) => {
          response.changeRequest.project.projectReport = reportObj;
          response.changeRequest = this.utilityService.setChangeRequestPdfValues(response.changeRequest);
          this.changeRequestPreview = response.changeRequest;

          setTimeout(() => {
            this.showChangePreview = true;
          }, 100);

          setTimeout(() => {
            document.getElementById("download").style.display = "none";

            if (this.changeRequestObj.isFreezed == true) {
              this.disableBtn = true
            } else {
              this.disableBtn = false;
            }
          }, 100);

          this.spinner.hide();

          this.modalRef = this.modalService.show(this.lgChangeModal, this.config);
          this.modalService.onHidden.subscribe((reason: string) => {
            this.showChangePreview = false;
          });
        });
      }
    });
  }

  approve(): void {
    this.spinner.show();
    this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
      status: 'Approved',
      isFreezed: true,
      // overallStatus: "Approved"
    }).subscribe((approvedProj: any) => {
      this.changeRequestObj = approvedProj;
      this.notificationService.createNotification(this.changeRequestObj.project.id, 'Change Request', 'approved', this.changeRequestObj.assignedTo.id);
      if (approvedProj.sentTo == "PMO") {
        this.apiService.get('/changeRequest/' + approvedProj.changeRequest.id).subscribe((crResponse: any) => {
          this.apiService.post('/outlineApproval', {
            changeRequest: approvedProj.changeRequest,
            projectStatus: 'Submitted',
            overallStatus: "Submitted",
            assignedTo: crResponse.projectSponsor.id,
            project: approvedProj.project.id,
            docType: 'Change Request',
            sentTo: "Sponsor",
            isFreezed: false,
            uid: approvedProj.uid,
            version: approvedProj.changeRequest.version,
            crNo: approvedProj.crNo,
            isPMOApprovedChangeRequest: true,
            pmoApprovedChangeRequestDate: new Date().toISOString(),
          }).subscribe((newApproval: any) => {
            this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
              workflowStatus: 'Change Request has been sent to Project Sponsor for approval.',
              pmoApprovedChangeRequestDate: new Date().toISOString(),
              isPMOApprovedChangeRequest: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
                pmoApprovedChangeRequestDate: new Date().toISOString(),
                isPMOApprovedChangeRequest: true,
                status: 'Submitted',
              }).subscribe(changeRequestResponse => {
                this.apiService.put('/outlineApproval/' + newApproval.id, {
                  changeRequest: changeRequestResponse,
                }).subscribe((newApprovalResponse: any) => {
                  this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                    changeRequest: changeRequestResponse,
                    isPMOApprovedChangeRequest: true,
                    projectStatus: 'Submitted',
                    pmoApprovedChangeRequestDate: new Date().toISOString(),
                    overallStatus: "Submitted"
                  }).subscribe((approvalResponse: any) => {
                    let projectResponse = Object.assign({}, response);
                    delete (projectResponse.projectOutline);
                    delete (projectResponse.projectOrder);
                    delete (projectResponse.history);
                    delete (projectResponse.changeRequests);
                    delete (projectResponse.closingReport);
                    delete (projectResponse.approvals);
                    delete (projectResponse.user);
                    delete (projectResponse.subPortfolio);
                    let approvedchangeRequest = approvalResponse.changeRequest;
                    approvedchangeRequest.projectId = projectResponse;
                    approvedchangeRequest.projectId.isPMOApprovedChangeRequest = true;
                    approvedchangeRequest.status = 'Submitted';
                    this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                      changeRequest: approvedchangeRequest
                    }).subscribe((resp: any) => {
                      this.disableBtn = true;
                      this.notification('Success', "Change Request has been sent to Project Sponsor for approval.");
                      this.spinner.hide();
                      this.apiService.post('/sendEmail', {
                        email: newApproval.changeRequest.projectSponsor.email,
                        subject: "oneView: Projekt Genehmigungsworkflow",
                        message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                        <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${newApproval.id}" target="_blank">${'Änderungsantrag / Change Request : ' + response.projectName}</a>`
                      }).subscribe(msg => { });
                    });
                  });
                });
              });
            });
          });
        });
      } else if (approvedProj.sentTo == "Sponsor") {
        this.apiService.get('/changeRequest/' + approvedProj.changeRequest.id).subscribe((crResponse: any) => {
          this.apiService.post('/outlineApproval', {
            changeRequest: approvedProj.changeRequest,
            status: "Open",
            projectStatus: 'Submitted',
            overallStatus: "Submitted",
            assignedTo: crResponse.projectFico.id,
            project: approvedProj.project.id,
            docType: 'Change Request',
            sentTo: "FICO",
            isFreezed: false,
            uid: approvedProj.uid,
            version: approvedProj.changeRequest.version,
            crNo: approvedProj.crNo,
            isPMOApprovedChangeRequest: true,
            isSponsorApprovedChangeRequest: true,
            pmoApprovedChangeRequestDate: this.changeRequestObj.pmoApprovedChangeRequestDate,
            sponsorApprovedChangeRequestDate: new Date().toISOString(),
          }).subscribe((newApproval: any) => {
            this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
              workflowStatus: 'Change Request has been sent to Financial Manager for approval.',
              sponsorApprovedChangeRequestDate: new Date().toISOString(),
              isSponsorApprovedChangeRequest: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
                sponsorApprovedChangeRequestDate: new Date().toISOString(),
                isSponsorApprovedChangeRequest: true,
                status: 'Submitted'
              }).subscribe(changeRequestResponse => {
                this.apiService.put('/outlineApproval/' + newApproval.id, {
                  changeRequest: changeRequestResponse,
                }).subscribe((newApprovalResponse: any) => {
                  this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                    changeRequest: changeRequestResponse,
                    isPMOApprovedChangeRequest: true,
                    isSponsorApprovedChangeRequest: true,
                    projectStatus: 'Submitted',
                    sponsorApprovedChangeRequestDate: new Date().toISOString(),
                    overallStatus: "Submitted"
                  }).subscribe((approvalResponse: any) => {
                    let projectResponse = Object.assign({}, response);
                    delete (projectResponse.projectOutline);
                    delete (projectResponse.projectOrder);
                    delete (projectResponse.history);
                    delete (projectResponse.changeRequests);
                    delete (projectResponse.closingReport);
                    delete (projectResponse.approvals);
                    delete (projectResponse.user);
                    delete (projectResponse.subPortfolio);
                    let approvedchangeRequest = approvalResponse.changeRequest;
                    approvedchangeRequest.projectId = projectResponse;
                    approvedchangeRequest.projectId.isSponsorApprovedChangeRequest = true;
                    approvedchangeRequest.status = 'Submitted';
                    this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                      changeRequest: approvedchangeRequest
                    }).subscribe((resp: any) => {
                      this.apiService.post('/updatepreviousapproval/', {
                        query: {
                          uid: resp.uid,
                          docType: 'Change Request',
                          sentTo: 'PMO',
                          version: resp.version,
                          crNo: resp.crNo,
                        },
                        projectItem: {
                          changeRequest: approvedchangeRequest,
                          isSponsorApprovedChangeRequest: true,
                          projectStatus: 'Submitted',
                          sponsorApprovedChangeRequestDate: new Date().toISOString(),
                          overallStatus: "Submitted"
                        }
                      }).subscribe((resp: any) => {
                      });
                    });
                    this.disableBtn = true;
                    this.notification('Success', "Change Request has been sent to Financial Manager for approval.");
                    this.spinner.hide();
                    this.apiService.post('/sendEmail', {
                      email: newApproval.changeRequest.projectSponsor.email,
                      subject: "oneView: Projekt Genehmigungsworkflow",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                      <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${newApproval.id}" target="_blank">${'Änderungsantrag / Change Request : ' + response.projectName}</a>`
                    }).subscribe(msg => { });
                  });
                });
              });
            });
          });
        });
      } else if (approvedProj.sentTo == "FICO") {
        this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
          status: 'Approved',
          workflowStatus: 'Change Request has been approved.',
          changeRequestMade: false,
          changeRequestApproved: true,
          ficoApprovedChangeRequestDate: new Date().toISOString(),
          isFicoApprovedChangeRequest: true
        }).subscribe((project: any) => {
          this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
            isApproved: true,
            ficoApprovedChangeRequestDate: new Date().toISOString(),
            isFicoApprovedChangeRequest: true
          }).subscribe(CrResponse => {
            this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
              changeRequest: CrResponse,
              isPMOApprovedChangeRequest: true,
              isSponsorApprovedChangeRequest: true,
              isFicoApprovedChangeRequest: true,
              projectStatus: 'Approved',
              ficoApprovedChangeRequestDate: new Date().toISOString(),
              overallStatus: "Approved"
            }).subscribe((approvalResponse: any) => {
              let projectResponse = Object.assign({}, project);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedchangeRequest = approvalResponse.changeRequest;
              approvedchangeRequest.projectId = projectResponse;
              this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                changeRequest: approvedchangeRequest
              }).subscribe((resp: any) => {
                this.apiService.post('/updatepreviousapproval/', {
                  query: {
                    uid: resp.uid,
                    docType: 'Change Request',
                    version: resp.version,
                    crNo: resp.crNo,
                  },
                  projectItem: {
                    changeRequest: approvedchangeRequest,
                    isFicoApprovedChangeRequest: true,
                    projectStatus: 'Approved',
                    ficoApprovedChangeRequestDate: new Date().toISOString(),
                    overallStatus: "Approved"
                  }
                }).subscribe((resp: any) => {
                  this.changeRequestReportOverwriter(approvedProj, project);
                });
              });
            });
          });
        });
      }
    });
  }

  onHold(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
      status: 'On Hold',
      workflowStatus: 'Change Request has been put on hold.',
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
        status: 'On Hold',
        projectStatus: 'On Hold',
        overallStatus: "On Hold"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedchangeRequest = appRes.changeRequest;
        approvedchangeRequest.projectId = projectResponse;
        approvedchangeRequest.status = 'On Hold';
        this.changeRequestObj.status = appRes.status;
        this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
          changeRequest: approvedchangeRequest
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Change Request',
              version: resp.version,
              crNo: resp.crNo,
            },
            projectItem: { changeRequest: approvedchangeRequest, projectStatus: 'On Hold', overallStatus: "On Hold" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.changeRequestObj.project.id, 'Change Request', 'held', this.changeRequestObj.assignedTo.id);
            this.apiService.put('/changeRequest/' + response.changeRequests[response.changeRequests.length - 1].id, {
              status: 'On Hold',
            }).subscribe(changeRes => {
              this.apiService.post('/sendEmail', {
                email: appRes.changeRequest.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request On Hold')[0].text + `<br>` +
                  'Änderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
              }).subscribe(msg => { });
              this.notification('Success', "Change Request has been put on hold.");
              this.spinner.hide();
            });
          });
        });
      });
    });
  }

  returnToSender(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
      status: 'Returned',
      changeRequestMade: false,
      changeRequestReturned: true,
      workflowStatus: 'Change Request has been returned back from approver.',
      changeRequestReturnDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
        status: 'Returned',
        projectStatus: 'Returned',
        changeRequestReturned: true,
        isFreezed: true,
        changeRequestReturnDate: new Date().toISOString(),
        overallStatus: "Returned"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedchangeRequest = appRes.changeRequest;

        let embeddedChangeRequest = appRes.changeRequest;

        approvedchangeRequest = projectResponse;
        approvedchangeRequest.status = 'Returned';
        approvedchangeRequest.workflowStatus = 'Change Request has been returned back from approver.'
        approvedchangeRequest.changeRequestReturnDate = new Date().toISOString();

        embeddedChangeRequest.project.status = 'Returned';
        embeddedChangeRequest.project.workflowStatus = 'Change Request has been returned back from approver.'
        embeddedChangeRequest.project.changeRequestReturnDate = new Date().toISOString();

        this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
          changeRequest: approvedchangeRequest
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Change Request',
              version: resp.version,
              crNo: resp.crNo
            },
            projectItem: { changeRequest: embeddedChangeRequest, project: approvedchangeRequest, projectStatus: 'Returned', changeRequestReturnDate: new Date().toISOString(), overallStatus: "Returned" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.changeRequestObj.project.id, 'Change Request', 'returned', this.changeRequestObj.assignedTo.id);
            this.changeRequestObj.status = appRes.status;
            this.apiService.put('/changeRequest/' + this.changeRequestObj.changeRequest.id, {
              isApproved: false,
              isSubmitted: true,
              isEditable: true,
              status: 'Returned',
              // isPMOApprovedChangeRequest: false,
              // isSponsorApprovedChangeRequest: false,
            }).subscribe(crResponse => {
              this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
                changeRequest: crResponse,
              }).subscribe((approvalResponse: any) => {
                this.disableBtn = true;
                this.apiService.post('/sendEmail', {
                  email: appRes.changeRequest.projectManager.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Returned')[0].text + `<br>` +
                    'Änderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
                }).subscribe(msg => { });
                this.notification('Success', "Change Request has been returned to sender.");
                this.spinner.hide();
              });
            });
          });
        });
      });
    });
  }

  reject(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.changeRequestObj.project.id, {
      status: 'Rejected',
      workflowStatus: 'Change Request has been rejected.',
      changeRequestMade: false,
      changeRequestRejectDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
        status: 'Rejected',
        projectStatus: 'Rejected',
        isFreezed: true,
        changeRequestRejectDate: new Date().toISOString(),
        overallStatus: "Rejected"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedchangeRequest = appRes.changeRequest;
        approvedchangeRequest.projectId = projectResponse;
        approvedchangeRequest.project.changeRequestRejectDate = new Date().toISOString();
        approvedchangeRequest.status = 'Rejected';
        this.apiService.put('/outlineApproval/' + this.changeRequestObj.id, {
          changeRequest: approvedchangeRequest
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Change Request',
              version: resp.version,
              crNo: resp.crNo
            },
            projectItem: { changeRequest: approvedchangeRequest, projectStatus: 'Rejected', changeRequestRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.changeRequestObj.project.id, 'Change Request', 'rejected', this.changeRequestObj.assignedTo.id);
            this.changeRequestObj.status = appRes.status;
            this.apiService.put('/changeRequest/' + this.changeRequestObj.changeRequest.id, {
              isApproved: true,
              isSubmitted: true,
              isEditable: false,
              status: 'Rejected',
            }).subscribe(crResponse => {
              this.disableBtn = true;
              this.apiService.post('/sendEmail', {
                email: appRes.changeRequest.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Rejected')[0].text + `<br>` +
                  'Änderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
              }).subscribe(msg => { });
              this.notification('Success', "Change Request has been rejected.");
              this.spinner.hide();
            });
          });
        });
      });
    });
  }

  changeRequestReportOverwriter(approvedProj, project) {
    let budgetTable = project.changeRequests[project.changeRequests.length - 1].effectsOnProjectBudget;
    let newEndDate = project.changeRequests[project.changeRequests.length - 1].newEndDate;
    delete (project.changeRequests[project.changeRequests.length - 1].actualProjectBudget);
    delete (project.changeRequests[project.changeRequests.length - 1].effectsOnProjectBudget);
    delete (project.changeRequests[project.changeRequests.length - 1].newEndDate);
    delete (project.changeRequests[project.changeRequests.length - 1].attachment1);
    delete (project.changeRequests[project.changeRequests.length - 1].attachment2);
    delete (project.changeRequests[project.changeRequests.length - 1].attachments);
    delete (project.changeRequests[project.changeRequests.length - 1].isAttachement1Added);
    delete (project.changeRequests[project.changeRequests.length - 1].isAttachement2Added);

    let obj: any = {
      projectName: project.projectName,
      ...project.changeRequests[project.changeRequests.length - 1],
      status: 'Active',
    };

    delete (obj.question);
    obj.orderQuestion = project.changeRequests[project.changeRequests.length - 1].orderQuestion;
    obj.plannedEndDate = newEndDate;
    obj.forecastEndDate = newEndDate;

    if (project.subPortfolio.id != project.projectReport.subPortfolio) {
      let budget = [
        {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          group: "Sonstiges",
          id: 5,
        }, {
          costType: "Total",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 6,
          group: "Sonstiges",
        },
      ];

      this.apiService.delete('/projectBudgetCost/project/' + project.id).subscribe((deleteResponce: any) => {
        this.apiService.post('/ProjectBudgetCost', {
          portfolioBudgetYear: project.subPortfolio.currentYear,
          project: project.id,
          budget: budget
        }).subscribe((projectBudgetCostResponce: any) => { });
      });
    }

    this.apiService.get('/reports/' + project.projectReport.id).subscribe((response: any) => {
      let costTypeTable = response.costTypeTable;

      budgetTable.forEach((value, index) => {
        costTypeTable[index].forecast = value.budget;
        costTypeTable[index].currentBudget = value.budget;
      });
      obj.costTypeTable = costTypeTable;

      this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
        this.apiService.post('/history', {
          date: Date.now(),
          project: project.id,
          document: 'Change Request',
          status: 'Approved'
        }).subscribe(historyResponse => {
          this.disableBtn = true;
          this.apiService.post('/sendEmail', {
            email: approvedProj.changeRequest.projectManager.email,
            subject: "oneView: Projekt Genehmigungsworkflow",
            message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Approval Confirmation')[0].text + `<br>` +
              'Änderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
          }).subscribe(msg => { });
          this.notification('Success', "Change Request has been approved.");
          this.spinner.hide();
        });
      });
    });
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }

  showComments(): void {
    this.commentsArr = [];
    this.commentsArr = this.changeRequestObj.project.comments;
    this.commentsProjectId = this.changeRequestObj.project.id;
  }

  saveComments(e) {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';
      setTimeout(() => {
        this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      }, 100);

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe(response => {
        // this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      });
    }
  }

  scrollToBottom(e) {
    setTimeout(() => {
      e.component.scrollBy($('.dx-scrollview-content').height());
    }, 100);
  }
}
