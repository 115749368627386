import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfProjectStatusReportViewRoutingModule } from './pdf-project-status-report-view-routing.module';
import { PdfProjectStatusReportViewComponent } from './pdf-project-status-report-view.component';
import { PDFProjectStatusReportModule } from 'app/projects/project-detail/pdf-project-status-report/pdf-project-status-report.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    PdfProjectStatusReportViewRoutingModule,
    PDFProjectStatusReportModule,
    NgxSpinnerModule,
    I18nModule
  ],
  declarations: [PdfProjectStatusReportViewComponent]
})
export class PdfProjectStatusReportViewModule { }
