import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-sub-portfolio-risks',
  templateUrl: './sub-portfolio-risks.component.html',
  styleUrls: ['./sub-portfolio-risks.component.scss']
})
export class SubPortfolioRisksComponent implements OnInit, OnDestroy {
  agregatedRisksList = [];
  subportfolioId;

  constructor(private apiService: ApiService, private authService: AuthService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private socketService: SocketService, private utilityService: UtilityService) { }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Risks'];

    let sub = this.route.params.subscribe(params => {
      this.subportfolioId = params.subPortfolio;

      this.socketService.listen('subportfolioReports').subscribe(reports => {
        reports.forEach((report, index) => {
          if (report.status == 'Active') {
            report.risks.forEach(risk => {
              if (risk.description != "") {
                let obj: any = {};
                obj.projectName = report.projectName;
                obj.description = risk.description;
                if (risk.riskStrategy.name != undefined) {
                  obj.riskStrategy = risk.riskStrategy;
                } else {
                  obj.riskStrategy = { name: "" };
                }
                obj.probability = risk.probability;
                obj.riskExposure = risk.riskExposure;
                this.agregatedRisksList.push(obj);
              }
            });
          }
        });
        this.spinner.hide();

      })

      this.socketService.emitEvent('subportfolioReports', { id: this.subportfolioId })
    });

  }

  onEditorPreparingRisksTopFiveTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'projectName') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('subportfolioReports');
  }
}
