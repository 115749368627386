import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClosingReportPreviewRoutingModule } from './closing-report-preview-routing.module';
import { ClosingReportPreviewComponent } from './closing-report-preview.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule } from '@angular/forms';
import { DxScrollViewModule } from 'devextreme-angular';
import { PDFModule } from 'app/pdf/pdf.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    ClosingReportPreviewRoutingModule,
    I18nModule,
    FormsModule,
    DxScrollViewModule,
    PDFModule,
    NgxSpinnerModule
  ],
  declarations: [ClosingReportPreviewComponent]
})
export class ClosingReportPreviewModule { }
