import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import * as XLSX from 'xlsx';
import { ApiService } from 'app/services/api.service';
import { DatePipe } from '@angular/common';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import { SocketService } from 'app/services/socket.service';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent implements OnInit, OnDestroy {
  public generalList: Array<any> = new Array();
  public milestonesList: Array<any> = new Array();
  public risksList: Array<any> = new Array();
  public decisionsList: Array<any> = new Array();
  public measuresList: Array<any> = new Array();
  public evaList: Array<any> = new Array();
  public multiProjectReport: Array<any> = new Array();
  public projectBudgetCurrentYearList: Array<any> = new Array();
  public projectBudgetNextYearList: Array<any> = new Array();
  public projectActualBudgetList: Array<any> = new Array();
  public portfolioBudgetCurrentYearList: Array<any> = new Array();
  public portfolioBudgetNextYearList: Array<any> = new Array();
  public subPortfolioBudgetCurrentYearList: Array<any> = new Array();
  public subPortfolioBudgetNextYearList: Array<any> = new Array();
  public programBudgetCurrentYearList: Array<any> = new Array();
  public programBudgetNextYearList: Array<any> = new Array();
  public projectStatusReports: Array<any> = new Array();
  public currentReservesTrendAnalysis: Array<any> = new Array();
  public milestoneTrendAnalysis: Array<any> = new Array();
  public projectQuestions: Array<any> = new Array();
  public programDetails: Array<any> = new Array();
  public lessonsLearned: Array<any> = new Array();
  public pipelineProjectsList: Array<any> = new Array();
  public programAggregatedCost: Array<any> = new Array();
  public dependenciesList: Array<any> = new Array();
  public documentsList: Array<any> = new Array();
  isReady: boolean = false;
  outlineQuestions;
  orderQuestions;
  changeReqQuestions;
  closingQuestions;
  FrontEnd_PATH;

  constructor(private utilityService: UtilityService, private apiService: ApiService, private datePipe: DatePipe, private localize: I18nPipe, private authService: AuthService, private socketService: SocketService) {
    // this.utilityService.breadcrumb = 'Excel Export';
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.generalList.push({
      date: this.datePipe.transform(Date.now(), 'dd.MMMM.yyyy'),
      time: moment(new Date()).format('h:mm:ss a')
    });

    this.apiService.get('/questions').subscribe((response: any) => {
      this.outlineQuestions = response.find(val => val.name == 'Project Outline').questions;
      this.orderQuestions = response.find(val => val.name == 'Project Order').questions;
      this.changeReqQuestions = response.find(val => val.name == 'Change Request').questions;
      this.closingQuestions = response.find(val => val.name == 'Closing Report').questions;

      this.apiService.get("/reports?limit=0").subscribe((response: any) => {
        response.forEach(reportObj => {
          let milestones = reportObj.mileStonesValues;
          if (Array.isArray(milestones)) {
            milestones.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
              val.dueDate = this.datePipe.transform(val.dueDate, 'dd.MMMM.yyyy')
            });
            this.utilityService.milestonesList.push(...milestones);
            this.milestonesList = this.utilityService.milestonesList;
          }

          let risks = reportObj.risks;
          if (risks != undefined) {
            risks.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
            });
            this.utilityService.risksList.push(...risks);
            this.risksList = this.utilityService.risksList;
          }

          let decisions = reportObj.decisions;
          if (decisions != undefined) {
            decisions.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
              val.date = this.datePipe.transform(val.date, 'dd.MMMM.yyyy')
            });
            this.utilityService.decisionsList.push(...decisions);
            this.decisionsList = this.utilityService.decisionsList;
          }

          let measures = reportObj.measures;
          if (measures != undefined) {
            measures.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
            });
            this.utilityService.measuresList.push(...measures);
            this.measuresList = this.utilityService.measuresList;
          }

          let eva = reportObj.EVA;
          if (eva != undefined) {
            eva.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
            });
            this.utilityService.evaList.push(...eva);
            this.evaList = this.utilityService.evaList;
          }

          let projectBudgetCurrentYear = reportObj.budgetPlanningTable1;
          if (projectBudgetCurrentYear != undefined) {
            projectBudgetCurrentYear.forEach((val, idx) => {
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
              val.projectName = reportObj.projectName;
              if (reportObj.psp) {
                val.psp1 = reportObj.psp[0].psp;
                val.psp2 = reportObj.psp[1].psp;
                val.psp3 = reportObj.psp[2].psp;
              }
              val.businessArea = reportObj.businessArea ? reportObj.businessArea.name : '';
              val.businessUnit = reportObj.businessUnit ? reportObj.businessUnit.name : '';
              val.businessSegment = reportObj.businessSegment ? reportObj.businessSegment.name : '';
              val.portfolioName = reportObj.portfolio ? reportObj.portfolio.name : '';
              val.subPortfolio = reportObj.subPortfolio;
            });
            this.utilityService.projectBudgetCurrentYearList.push(...projectBudgetCurrentYear);
            this.projectBudgetCurrentYearList = this.utilityService.projectBudgetCurrentYearList;
          }

          let projectBudgetNextYear = reportObj.budgetPlanningTable2;
          if (projectBudgetNextYear != undefined) {
            projectBudgetNextYear.forEach((val, idx) => {
              delete (val.actualCost);
              delete (val.forecast);
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
              val.projectName = reportObj.projectName;
              val.status = reportObj.status;
              if (reportObj.psp) {
                val.psp1 = reportObj.psp[0].psp;
                val.psp2 = reportObj.psp[1].psp;
                val.psp3 = reportObj.psp[2].psp;
              }
              val.businessArea = reportObj.businessArea ? reportObj.businessArea.name : '';
              val.businessUnit = reportObj.businessUnit ? reportObj.businessUnit.name : '';
              val.businessSegment = reportObj.businessSegment ? reportObj.businessSegment.name : '';
              val.portfolioName = reportObj.portfolio ? reportObj.portfolio.name : '';
              val.subPortfolio = reportObj.subPortfolio;
            });
            this.utilityService.projectBudgetNextYearList.push(...projectBudgetNextYear);
            this.projectBudgetNextYearList = this.utilityService.projectBudgetNextYearList;
          }

          let projectActualBudget = reportObj.actualCostTable;
          if (projectActualBudget != undefined) {
            projectActualBudget.forEach((val, idx) => {
              delete (val.actualBudget);
              val.reportId = reportObj.id;
              val.projectId = reportObj.uid;
              val.projectName = reportObj.projectName;
            });
            this.utilityService.projectActualBudgetList.push(...projectActualBudget);
            this.projectActualBudgetList = this.utilityService.projectActualBudgetList;
          }

          let lessonsLearned = reportObj.lessonsLearned;
          let arr = [];
          if (lessonsLearned != undefined) {
            lessonsLearned.forEach((val, idx) => {
              arr.push({
                description: val.description,
                type: val.lessonType,
                category: val.lessonCategory.name,
                projectId: reportObj.uid,
                projectName: reportObj.projectName,
                projectType: reportObj.projectType
              });
            });
            this.utilityService.lessonsLearned.push(...arr);
            this.lessonsLearned = this.utilityService.lessonsLearned;
          }

          let technology = [];
          if (reportObj.technology) {
            if (this.utilityService.technologyOptions) {
              this.utilityService.technologyOptions.values.map(techObj => {
                let obj = reportObj.technology.find(val => {
                  if (val == techObj.id) {
                    technology.push(techObj.name);
                  }
                });
              });
            }
          }

          // populate itPlatform dropdown value
          let itPlatforms = '';
          if (reportObj.itPlatform) {
            if (reportObj.itPlatform.length > 0) {
              reportObj.itPlatform.forEach((itPlatform, idx) => {
                let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

                if (itPlatformObj) {
                  if (idx == 0) {
                    itPlatforms = itPlatformObj.name;
                  } else {
                    itPlatforms = itPlatforms + ', ' + itPlatformObj.name;
                  }
                }
              });
            }
          }

          this.multiProjectReport.push({
            id: reportObj.uid,
            projectName: reportObj.projectName,
            projectManager: reportObj.projectManager.name,
            projectSponsor: reportObj.projectSponsor.name,
            portfolio: reportObj.portfolio ? this.localize.transform(reportObj.portfolio.name) : '',
            subPortfolio: reportObj.subPortfolio ? reportObj.subPortfolio.name : '',
            projectPhase: reportObj.projectPhase ? this.localize.transform(reportObj.projectPhase.name) : '',
            businessSegment: reportObj.businessSegment ? reportObj.businessSegment.name : '',
            reportingLevel: reportObj.reportingLevel ? reportObj.reportingLevel.name : '',
            businessUnit: reportObj.businessUnit ? reportObj.businessUnit.name : '',
            businessArea: reportObj.businessArea ? reportObj.businessArea.name : '',
            portfolioId: reportObj.portfolio ? reportObj.portfolio.id : '',
            portfolioName: reportObj.portfolio ? reportObj.portfolio.name : '',
            strategicContribution: reportObj.strategicContribution ? reportObj.strategicContribution.name : '',
            profitability: reportObj.profitability ? reportObj.profitability.name : '',
            itRelevant: reportObj.itRelevant ? reportObj.itRelevant.name : '',
            itPlatform: itPlatforms, //reportObj.itPlatform != undefined ? reportObj.itPlatform.name : '',
            projectMethodology: reportObj.projectMethodology ? reportObj.projectMethodology.name : '',
            confidential: reportObj.confidential,
            reportStatus: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].status : '',
            overallStatus: reportObj.statusReports.length > 0 ? this.statusConverter(reportObj.statusReports[reportObj.statusReports.length - 1].overallStatus) : '',
            scopeStatus: reportObj.statusReports.length > 0 ? this.statusConverter(reportObj.statusReports[reportObj.statusReports.length - 1].scopeStatus) : '',
            costStatus: reportObj.statusReports.length > 0 ? this.statusConverter(reportObj.statusReports[reportObj.statusReports.length - 1].costStatus) : '',
            timeStatus: reportObj.statusReports.length > 0 ? this.statusConverter(reportObj.statusReports[reportObj.statusReports.length - 1].timeStatus) : '',
            riskStatus: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].riskStatus : '',
            psp: reportObj.psp ? reportObj.psp[0].psp : '',
            currency: reportObj.currency,
            status: reportObj.status,
            projectClassification: reportObj.classification.name,
            bkwShare: reportObj.bkwShare,
            IRR: reportObj.kpisTable[4].value,
            GwH: reportObj.GwH,
            digitalizationFocus: reportObj.digitalizationFocus != undefined ? reportObj.digitalizationFocus.name : '',
            digitalizationTopic: reportObj.digitalizationTopic != undefined ? reportObj.digitalizationTopic.name : '',
            technology: technology.join(', '),
            purpose: reportObj.purpose,
            SPI: reportObj.EVA ? reportObj.EVA.length > 0 ? reportObj.EVA[reportObj.EVA.length - 1].SPI : '' : '',
            riskExposureVsCurrentBudget: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].riskExposureVsCurrentBudget : '',
            totalExposure: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].totalExposure : '',
            plannedEndDateVsForecastEndDate: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].plannedDateVSForecastDate : '',
            currentBudgetVSForecast: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].currentBudgetVSForecast : '',
            currentBudgetVSOriginalBudget: reportObj.currentBudgetVSOriginalBudget,
            endDateVSPlannedEndDate: reportObj.endDateVSPlannedEndDate,
            EAC: reportObj.EVA ? reportObj.EVA.length > 0 ? reportObj.EVA[reportObj.EVA.length - 1].EAC : '' : '',
            CPI: reportObj.EVA ? reportObj.EVA.length > 0 ? reportObj.EVA[reportObj.EVA.length - 1].CPI : '' : '',
            percentageComplete: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].percentageComplete : '',
            managementSummary: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].managementSummary : '',
            scopeStatusComments: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].scopeStatusComments : '',
            costStatusComments: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].costStatusComments : '',
            timeStatusComments: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].timeStatusComments : '',
            forecastEndDate: this.datePipe.transform(reportObj.forecastEndDate, 'dd.MMMM.yyyy'),
            plannedEndDate: this.datePipe.transform(reportObj.plannedEndDate, 'dd.MMMM.yyyy'),
            startDate: this.datePipe.transform(reportObj.startDate, 'dd.MMMM.yyyy'),
            endDate: this.datePipe.transform(reportObj.endDate, 'dd.MMMM.yyyy'),
            forecast: reportObj.costTypeTable[6].forecast,
            currentBudget: reportObj.costTypeTable[6].currentBudget,
            originalBudget: reportObj.costTypeTable[6].originalBudget,
            actualCost: reportObj.costTypeTable[6].actualCost,
            programName: reportObj.program ? reportObj.program.programName : '',
            reportingDate: reportObj.statusReports.length > 0 ? this.datePipe.transform(reportObj.statusReports[reportObj.statusReports.length - 1].reportingDate, 'dd.MMMM.yyyy') : '',
          });
          this.utilityService.multiProjectReport = this.multiProjectReport;

          if (reportObj.statusReports != undefined) {
            if (reportObj.statusReports.length > 0) {
              reportObj.statusReports.forEach(statusReportObj => {
                this.projectStatusReports.push({
                  projectId: reportObj.project.uid,
                  projectName: reportObj.projectName,
                  reportingDate: this.datePipe.transform(statusReportObj.reportingDate, 'dd.MMMM.yyyy'),
                  submittedDate: statusReportObj.submittedDate != undefined ? this.datePipe.transform(statusReportObj.submittedDate, 'dd.MMMM.yyyy') : '',
                  actualCost: statusReportObj.costTypeTable[6].actualCost,
                  forecast: statusReportObj.costTypeTable[6].forecast,
                  originalBudget: statusReportObj.costTypeTable[6].originalBudget,
                  currentBudget: statusReportObj.costTypeTable[6].currentBudget,
                });
              });
              this.utilityService.projectStatusReports = this.projectStatusReports;
            }
          }

          if (reportObj.milestoneTable) {
            reportObj.milestoneTable.map(obj => {
              obj.reportingDate = this.datePipe.transform(obj.reportingDate, 'dd.MMMM.yyyy');
              if (Object.keys(obj).length > 1) {
                for (let i = 1; i < Object.keys(obj).length; i++) {
                  obj[`milestone${i}`] = this.datePipe.transform(obj[`milestone${i}`], 'dd.MMMM.yyyy')
                }
              }

              this.milestoneTrendAnalysis.push({
                ...obj,
                projectId: reportObj.uid,
                projectName: reportObj.projectName
              });
            });
            this.utilityService.milestoneTrendAnalysis = this.milestoneTrendAnalysis;
          }

          reportObj.currentReserveHistory.map(obj => {
            this.currentReservesTrendAnalysis.push({
              reportingDate: this.datePipe.transform(obj.date, 'dd.MMMM.yyyy'),
              currentReserve: obj.value,
              projectId: reportObj.uid,
              projectName: reportObj.projectName
            });
          });
          this.utilityService.currentReservesTrendAnalysis = this.currentReservesTrendAnalysis;


          let outlineQues = [];
          reportObj.question.forEach((val, idx) => {
            outlineQues.push({
              department: this.outlineQuestions[idx].department,
              question: this.outlineQuestions[idx].question,
              answer: val,
              projectId: reportObj.uid,
              projectName: reportObj.projectName,
              document: 'Outline'
            });
          });
          let orderQues = [];
          if (reportObj.orderQuestion) {
            reportObj.orderQuestion.forEach((val, idx) => {
              orderQues.push({
                department: this.orderQuestions[idx].department,
                question: this.orderQuestions[idx].question,
                answer: val,
                projectId: reportObj.uid,
                projectName: reportObj.projectName,
                document: 'Order'
              });
            });
          }
          let changeQues = [];
          if (reportObj.changeRequestQuestion) {
            reportObj.changeRequestQuestion.forEach((val, idx) => {
              changeQues.push({
                department: this.changeReqQuestions[idx].department,
                question: this.changeReqQuestions[idx].question,
                answer: val,
                projectId: reportObj.uid,
                projectName: reportObj.projectName,
                document: 'Change Request'
              });
            });
          }
          let closingQuestions = [];
          if (reportObj.closingQuestion) {
            reportObj.closingQuestion.forEach((val, idx) => {
              closingQuestions.push({
                department: this.closingQuestions[idx].department,
                question: this.closingQuestions[idx].question,
                answer: val,
                projectId: reportObj.uid,
                projectName: reportObj.projectName,
                document: 'Closing Report'
              });
            });
          }
          this.projectQuestions.push(...outlineQues, ...orderQues, ...changeQues, ...closingQuestions);
          this.utilityService.projectQuestions = this.projectQuestions;

          let dependencies = [];
          if (reportObj.impactedByDependenciesTable) {
            reportObj.impactedByDependenciesTable.forEach(val => {
              let projectId = val.project.id != undefined ? val.project.id : val.project;
              // let dependeeProject = this.utilityService.projectList.find(obj => obj.id == projectId);

              dependencies.push({
                projectId: reportObj.uid,
                projectName: reportObj.projectName,
                description: val.description,
                impact: val.impact != undefined ? this.localize.transform(val.impact.name) : '',
                project: projectId
              });
            });
            this.utilityService.dependenciesList.push(...dependencies);
            this.dependenciesList = this.utilityService.dependenciesList;
          }

        });

        this.apiService.get("/portfolio?limit=0").subscribe((portfolios: any) => {
          portfolios.forEach(portfolio => {
            let portfolioBudgetCurrentYear = portfolio.portfolioBudgetingList != undefined ? portfolio.portfolioBudgetingList.portfolioBudgetCurrentYear : [];
            if (portfolioBudgetCurrentYear != undefined) {
              portfolioBudgetCurrentYear.forEach((val, idx) => {
                val.portfolioId = portfolio.id;
                val.portfolioName = portfolio.name;
              });
              this.utilityService.portfolioBudgetCurrentYearList.push(...portfolioBudgetCurrentYear);
              this.portfolioBudgetCurrentYearList = this.utilityService.portfolioBudgetCurrentYearList;
            }

            let portfolioBudgetNextYear = portfolio.portfolioBudgetingList != undefined ? portfolio.portfolioBudgetingList.portfolioBudgetNextYear : [];
            if (portfolioBudgetNextYear != undefined) {
              portfolioBudgetNextYear.forEach((val, idx) => {
                delete (val.actualCost);
                delete (val.forecast);
                val.portfolioId = portfolio.id;
                val.portfolioName = portfolio.name;
              });
              this.utilityService.portfolioBudgetNextYearList.push(...portfolioBudgetNextYear);
              this.portfolioBudgetNextYearList = this.utilityService.portfolioBudgetNextYearList;
            }

            // Current Year
            if (portfolio.subPortfolioBudgetingList != undefined) {
              portfolio.subPortfolioBudgetingList.forEach(subPortBudgetObj => {
                let subPortfolioBudgetCurrentYear = subPortBudgetObj.subPortfolioBudgetCurrentYear;
                if (subPortfolioBudgetCurrentYear != undefined) {
                  subPortfolioBudgetCurrentYear.forEach((val, idx) => {
                    val.portfolioId = portfolio.id;
                    val.portfolioName = portfolio.name;
                    val.subPortfolio = subPortBudgetObj.subPortfolio;
                    val.pspCurrentYear = subPortBudgetObj.pspCurrentYear;
                  });
                  this.utilityService.subPortfolioBudgetCurrentYearList.push(...subPortfolioBudgetCurrentYear);
                }
              });
              this.subPortfolioBudgetCurrentYearList = this.utilityService.subPortfolioBudgetCurrentYearList;

              // Next Year
              portfolio.subPortfolioBudgetingList.forEach(subPortBudgetObj => {
                let subPortfolioBudgetNextYear = subPortBudgetObj.subPortfolioBudgetNextYear;
                if (subPortfolioBudgetNextYear != undefined) {
                  subPortfolioBudgetNextYear.forEach((val, idx) => {
                    delete (val.actualCost);
                    delete (val.forecast);
                    val.portfolioId = portfolio.id;
                    val.portfolioName = portfolio.name;
                    val.subPortfolio = subPortBudgetObj.subPortfolio;
                    val.pspNextYear = subPortBudgetObj.pspNextYear;
                  });
                  this.utilityService.subPortfolioBudgetNextYearList.push(...subPortfolioBudgetNextYear);
                }
              });
              this.subPortfolioBudgetNextYearList = this.utilityService.subPortfolioBudgetNextYearList;
            }
          });

          this.apiService.get("/program?limit=0").subscribe((programs: any) => {
            programs.forEach(program => {
              let programBudgetCurrentYear = program.programBudgetCurrentYear;
              if (programBudgetCurrentYear != undefined) {
                programBudgetCurrentYear.forEach((val, idx) => {
                  val.programId = program.uid;
                  val.programName = program.programName;
                });
                this.utilityService.programBudgetCurrentYearList.push(...programBudgetCurrentYear);
                this.programBudgetCurrentYearList = this.utilityService.programBudgetCurrentYearList;
              }

              let programBudgetNextYear = program.programBudgetNextYear;
              if (programBudgetNextYear != undefined) {
                programBudgetNextYear.forEach((val, idx) => {
                  delete (val.actualCost);
                  delete (val.forecast);
                  val.programId = program.uid;
                  val.programName = program.programName;
                });
                this.utilityService.programBudgetNextYearList.push(...programBudgetNextYear);
                this.programBudgetNextYearList = this.utilityService.programBudgetNextYearList;
              }

              this.programDetails.push({
                programName: program.programName,
                purpose: program.purpose,
                startDate: this.datePipe.transform(program.startDate, 'dd.MMMM.yyyy'),
                endDate: this.datePipe.transform(program.startDate, 'dd.MMMM.yyyy'),
                overallStatus: program.statusReports ? program.statusReports.length > 0 ? this.statusConverter(program.statusReports[program.statusReports.length - 1].overallStatus) : '' : '',
                overallStatusComments: program.statusReports ? program.statusReports.length > 0 ? program.statusReports[program.statusReports.length - 1].overallStatusComments : '' : '',
                scopeStatus: program.statusReports ? program.statusReports.length > 0 ? this.statusConverter(program.statusReports[program.statusReports.length - 1].scopeStatus) : '' : '',
                scopeStatusComments: program.statusReports ? program.statusReports.length > 0 ? program.statusReports[program.statusReports.length - 1].scopeStatusComments : '' : '',
                costStatus: program.statusReports ? program.statusReports.length > 0 ? this.statusConverter(program.statusReports[program.statusReports.length - 1].costStatus) : '' : '',
                costStatusComments: program.statusReports ? program.statusReports.length > 0 ? program.statusReports[program.statusReports.length - 1].costStatusComments : '' : '',
                timeStatus: program.statusReports ? program.statusReports.length > 0 ? this.statusConverter(program.statusReports[program.statusReports.length - 1].timeStatus) : '' : '',
                timeStatusComments: program.statusReports ? program.statusReports.length > 0 ? program.statusReports[program.statusReports.length - 1].timeStatusComments : '' : '',
              });
              this.utilityService.programDetails = this.programDetails;

              let aggregatedProgramTable1 = program.aggregatedProgramTable1;
              if (program.aggregatedProgramTable1) {
                aggregatedProgramTable1.forEach(obj => {
                  obj.programName = program.programName;
                });
                this.programAggregatedCost.push(...aggregatedProgramTable1)
              }
              this.utilityService.programAggregatedCost = this.programAggregatedCost;
            });

            this.apiService.get('/getSubmittedProjects').subscribe((pipelineProjects: any) => {
              pipelineProjects.forEach(pipelineProject => {
                let totalBudget;
                let budget;
                let businessUnit;
                let businessArea;
                let fico;
                if (pipelineProject.docType == 'Outline') {
                  budget = pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].estimatedProjectTable[6].budget : '';
                  totalBudget = pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].fundsApprovedForInitiationTable[6].budget : '';
                  businessArea = this.utilityService.businessAreaOptions.values.find(val => val.id == pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].businessArea : { name: '' }).name
                  businessUnit = this.utilityService.businessUnitOptions.values.find(val => val.id == pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].businessUnit : { name: '' }).name;
                } else {
                  totalBudget = pipelineProject.projectOrder[0].costTypeTable[6].budget;
                  businessArea = this.utilityService.businessAreaOptions.values.find(val => val.id == pipelineProject.projectOrder[0].businessArea).name;
                  businessUnit = this.utilityService.businessUnitOptions.values.find(val => val.id == pipelineProject.projectOrder[0].businessUnit).name;
                  fico = pipelineProject.projectOrder[0].projectFico.name;
                }

                this.pipelineProjectsList.push({
                  projectId: pipelineProject.uid,
                  projectName: pipelineProject.projectName,
                  purpose: pipelineProject.projectReport ? pipelineProject.projectReport.purpose : '',
                  projectManager: pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].projectManager.name : '',
                  projectSponsor: pipelineProject.projectOutline[0] ? pipelineProject.projectOutline[0].projectSponsor.name : '',
                  projectFico: fico,
                  businessUnit,
                  businessArea,
                  totalBudget,
                  budget,
                  portfolio: pipelineProject.subPortfolio ? pipelineProject.subPortfolio.portfolio : '',
                  subPortfolio: pipelineProject.subPortfolio ? pipelineProject.subPortfolio.name : '',
                });
                this.utilityService.pipelineProjectsList = this.pipelineProjectsList;
              });

              this.socketService.emitEvent('getOutlinesByPMO', {});
            });
          });
        });
      });
    });

    this.socketService.listen('getOutlinesByPMO').subscribe(approvals => {
      approvals.map(val => {
        let link;
        let businessArea;
        let businessUnit;
        let projectManager;

        if (val.docType == 'Outline') {
          if (val.projectOutline.businessArea.name == undefined) {
            businessArea = this.utilityService.businessAreaOptions.values.find(value => value.id == val.projectOutline.businessArea)
            businessUnit = this.utilityService.businessUnitOptions.values.find(value => value.id == val.projectOutline.businessUnit)
          } else {
            businessArea = val.projectOutline.businessArea;
            businessUnit = val.projectOutline.businessUnit;
          }
          projectManager = val.projectOutline.projectManager.name;
          link = `${this.FrontEnd_PATH}/view/outline/${val.id}`;
        } else if (val.docType == 'Order') {
          if (val.projectOrder.businessArea.name == undefined) {
            businessArea = this.utilityService.businessAreaOptions.values.find(value => value.id == val.projectOrder.businessArea);
            businessUnit = this.utilityService.businessUnitOptions.values.find(value => value.id == val.projectOrder.businessUnit);
          } else {
            businessArea = val.projectOrder.businessArea;
            businessUnit = val.projectOrder.businessUnit;
          }
          projectManager = val.projectOrder.projectManager.name;
          link = `${this.FrontEnd_PATH}/view/order/${val.id}`;
        } else if (val.docType == 'Change Request') {
          if (val.changeRequest.businessArea != undefined) {
            if (val.changeRequest.businessArea.name == undefined) {
              businessArea = this.utilityService.businessAreaOptions.values.find(value => value.id == val.changeRequest.businessArea)
              businessUnit = this.utilityService.businessUnitOptions.values.find(value => value.id == val.changeRequest.businessUnit)
            } else {
              businessArea = val.changeRequest.businessArea;
              businessUnit = val.changeRequest.businessUnit;
            }
          }
          projectManager = val.changeRequest.projectManager ? val.changeRequest.projectManager.name : '';
          link = `${this.FrontEnd_PATH}/view/changeRequest/${val.id}`;
        } else if (val.docType == 'Closing Report') {
          projectManager = val.closingReport.projectManager ? val.closingReport.projectManager.name : '';
          link = `${this.FrontEnd_PATH}/view/closingReport/${val.id}`;
        }

        this.documentsList.push({
          uid: val.uid,
          projectName: val.project ? val.project.projectName : '',
          docType: this.localize.transform(val.docType),
          status: val.overallStatus,
          version: val.version,
          businessArea: businessArea ? businessArea.name : '',
          businessUnit: businessUnit ? businessUnit.name : '',
          projectManager,
          link
        });
      });

      this.isReady = true;
    });
  }

  ngOnInit() {
  }

  statusConverter(status): number {
    if (status == 'Green') {
      return 1;
    } else if (status == 'Red') {
      return 3;
    } else if (status == "Yellow") {
      return 2;
    }
  }

  jsonToExcel() {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    const generalsheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.generalList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, generalsheet, 'General');

    const milestonesheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.milestonesList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, milestonesheet, 'Milestones');

    const riskSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.risksList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, riskSheet, 'Risks');

    const decisionsSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.decisionsList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, decisionsSheet, 'Decisions');

    const measuresSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.measuresList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, measuresSheet, 'Measures');

    const evaSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.evaList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, evaSheet, 'EVA');

    const projectBudgetCurrentYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectBudgetCurrentYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, projectBudgetCurrentYearSheet, 'Project Budget Current Year');

    const projectBudgetNextYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectBudgetNextYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, projectBudgetNextYearSheet, 'Project Budget Next Year');

    const projectActualBudgetSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectActualBudgetList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, projectActualBudgetSheet, 'Project Actual Budget');

    const lessonsLearnedSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lessonsLearned, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, lessonsLearnedSheet, 'Lessons Learned');

    const portfolioBudgetCurrentYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.portfolioBudgetCurrentYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, portfolioBudgetCurrentYearSheet, 'Portfolio Budget Current Year');

    const portfolioBudgetNextYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.portfolioBudgetNextYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, portfolioBudgetNextYearSheet, 'Portfolio Budget Next Year');

    const subPortfolioBudgetCurrentYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.subPortfolioBudgetCurrentYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, subPortfolioBudgetCurrentYearSheet, 'Sub-Port Budget Current Year');

    const subPortfolioBudgetNextYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.subPortfolioBudgetNextYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, subPortfolioBudgetNextYearSheet, 'Sub-Port Budget Next Year');

    const programBudgetCurrentYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.programBudgetCurrentYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, programBudgetCurrentYearSheet, 'Program Budget Current Year');

    const programBudgetNextYearSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.programBudgetNextYearList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, programBudgetNextYearSheet, 'Program Budget Next Year');

    const multiProjectReportSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.multiProjectReport, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, multiProjectReportSheet, 'Multi Project Report');

    const programDetailsSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.programDetails, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, programDetailsSheet, 'Program Details');

    const programAggregatedCostSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.programAggregatedCost, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, programAggregatedCostSheet, 'Program Aggregated Cost');

    const projectStatusReportSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectStatusReports, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, projectStatusReportSheet, 'Status History');

    const currentReservesTrendAnalysisSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.currentReservesTrendAnalysis, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, currentReservesTrendAnalysisSheet, 'Current Reserves');

    const milestoneTrendAnalysisSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.milestoneTrendAnalysis, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, milestoneTrendAnalysisSheet, 'Milestone Trend Analysis');

    const projectQuestionsSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectQuestions, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, projectQuestionsSheet, 'Project Questions');

    const pipelineProjectsSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.pipelineProjectsList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, pipelineProjectsSheet, 'Pipeline Projects');

    const dependenciesSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dependenciesList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, dependenciesSheet, 'Dependencies');

    const documentsSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.documentsList, { cellDates: true });
    XLSX.utils.book_append_sheet(workbook, documentsSheet, 'Documents');

    XLSX.writeFile(workbook, `Excel-Dump-${new Date().getTime()}.xlsx`);
  }

  ngOnDestroy() {
    this.socketService.removeListener('getOutlinesByPMO');
  }

}
