import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-view-as-projects',
  templateUrl: './view-as-projects.component.html',
  styleUrls: ['./view-as-projects.component.scss']
})
export class ViewAsProjectsComponent implements OnInit, AfterViewInit {
  progSponsorEmail;
  tabs: FormGroup

  constructor(public utilityService: UtilityService, private apiService: ApiService, public authService: AuthService,
    private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
    private localize: I18nPipe, private clipboardService: ClipboardService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "View As";
  }

  ngOnInit() {
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.tabs = this.formBuilder.group({
    });
  }

  ngAfterViewInit() {
    this.utilityService.initTabs();
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
