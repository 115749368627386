import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {

    test: Date = new Date();
    isVisible: boolean = true;

    ngOnInit(): void {
        if (window.location.href.includes("/view/outline/") || window.location.href.includes("/view/order/") || window.location.href.includes("/view/changeRequest/") || window.location.href.includes("/view/closingReport/")
        || window.location.href.includes("/outlinePreview/") || window.location.href.includes("/orderPreview/") || window.location.href.includes("/changeRequestPreview/") || window.location.href.includes("/closingReportPreview/")) {
            this.isVisible = false;
        }
    }
}
