import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmallOrderOrgChartComponent } from './small-order-org-chart.component';


const routes: Routes = [
  {
    path: '',
    component: SmallOrderOrgChartComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmallOrderOrgChartRoutingModule { }
