import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';


@Component({
  selector: 'app-program-projects-list',
  templateUrl: './program-projects-list.component.html',
  styleUrls: ['./program-projects-list.component.scss']
})
export class ProgramProjectsListComponent implements OnInit, OnDestroy {
  reportsList = [];
  stateStore;
  idsArray = [];
  reportsCount: number = 0;
  reportsSearch: string = '';
  projectsSubject = new Subject<any>();
  reportId;
  // isVisible: boolean = false;
  programSmallOrdersList: any = [];
  smallOrderStateStore;
  dropdownPageIndex = 1;

  constructor(private apiService: ApiService, private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private utilityService: UtilityService) {
    this.stateStore = {
      enabled: true,
      storageKey: 'programProjectList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.smallOrderStateStore = {
      enabled: true,
      storageKey: 'programSmallOrdersList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    let sub = this.route.params.subscribe(params => {
      this.reportId = params.id;
      this.apiService.get('/program/' + this.reportId).subscribe((response: any) => {
        this.utilityService.breadcrumbList = ['My Programs', response.programName, 'Details', 'Projects & Orders'];
        this.spinner.hide();
      });
    });

    this.socketService.listen('portfolioProjectsFilterCount').subscribe(count => {
      //Limiting data to 400 records
      if (count < 400) {
        this.reportsCount = count;
      } else {
        this.reportsCount = 400;
      }

      this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: [{ program: this.reportId }] });
    });

    this.socketService.listen('portfolioProjectsFilter').subscribe(projects => {
      this.reportsList = this.reportsList.concat(projects);
      this.spinner.hide();

      if (this.dropdownPageIndex < (this.reportsCount / 50)) {
        this.dropdownPageIndex++;
        this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: [{ program: this.reportId }] });
      }
    });

    this.socketService.listen('smallOrdersFilter').subscribe(orders => {
      this.programSmallOrdersList = orders;
      this.spinner.hide();
    });

    this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: [{ program: this.reportId }] });
    this.socketService.emitEvent('smallOrdersFilter', { pageIndex: 1, pageSize: 50, filtersArray: [{ program: this.reportId }] });
  }

  ngOnDestroy() {
    this.socketService.removeListener('portfolioProjectsFilterCount');
    this.socketService.removeListener('portfolioProjectsFilter');
    this.socketService.removeListener('smallOrdersFilter');
  }

}
