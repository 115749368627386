import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallOrdersLessonsLearnedRoutingModule } from './small-orders-lessons-learned-routing.module';
import { SmallOrdersLessonsLearnedComponent } from './small-orders-lessons-learned.component';
import { DxAutocompleteModule, DxCheckBoxModule, DxDataGridModule, DxPopoverModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { MaterialModule } from 'app/material.module';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    SmallOrdersLessonsLearnedComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    DxAutocompleteModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    SmallOrdersLessonsLearnedRoutingModule
  ]
})
export class SmallOrdersLessonsLearnedModule { }
