import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';
import { DxScrollViewComponent } from 'devextreme-angular';
import { NotificationsService } from 'app/services/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-as',
  templateUrl: './view-as.component.html',
  styleUrls: ['./view-as.component.scss']
})
export class ViewAsComponent implements OnInit, OnDestroy {

  projectsSearchList;
  projectsList;
  teamProjectsList = [];
  approvalsList;
  smallOrdersList = [];
  subportfoliosList = [];
  programsList = [];
  smallOrderPreview;
  usersList;
  approvalsCount = 0;
  projectsCount = 0;
  smallOrdersCount = 0;
  subportfoliosCount = 0;
  programsCount = 0;
  approvalsPageIndex = 2;
  projectsPageIndex = 2;
  smallOrdersPageIndex = 2;
  subportfoliosPageIndex = 2;
  programsPageIndex = 2;
  selectedUserId;
  showPreview = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  showComments = false;
  showSmallOrderPreview = false;
  outlinePreview;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  commentsArr = [];
  commentsProjectId;
  commentMsg = '';
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('lgOrderModal', { static: false }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  @ViewChild('commentsModal', { static: false }) public commentsModal;
  @ViewChild('lgModalSmallOrder', { static: false }) public lgModalSmallOrder;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  FrontEnd_PATH;
  BASE_PATH = '';
  attachmentQueryParams = '';

  constructor(private router: Router, private utilityService: UtilityService, private apiService: ApiService, private spinner: NgxSpinnerService, public authService: AuthService, private socketService: SocketService, private notificationService: NotificationsService) {
    this.utilityService.breadcrumb = 'View As';
    this.utilityService.breadcrumbList = ['Settings', 'View As', 'Project'];
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
  }

  ngOnInit() {
    this.socketService.listen('approvalsCount').subscribe(count => {
      this.approvalsCount = count;

      if (this.approvalsCount > 10) {
        this.socketService.emitEvent('approvalsIndex', { userId: this.selectedUserId, pageIndex: this.approvalsPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('approvalsIndex').subscribe(approvals => {
      this.approvalsList = this.approvalsList.concat(approvals);

      if (this.approvalsPageIndex < (this.approvalsCount / 10)) {
        this.approvalsPageIndex++;
        this.socketService.emitEvent('approvalsIndex', { userId: this.selectedUserId, pageIndex: this.approvalsPageIndex, pageSize: 10 });
      }
    });


    this.socketService.listen('projectsCount').subscribe(count => {
      this.projectsCount = count;

      if (count > 10) {
        this.socketService.emitEvent('projectsIndex', { userId: this.selectedUserId, pageIndex: this.projectsPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('projectsIndex').subscribe((response: any) => {
      let assignedProjectsValues = this.assignProjectsValues(response);
      this.projectsList = this.projectsList.concat(assignedProjectsValues);

      if (this.projectsPageIndex < (this.projectsCount / 10)) {
        this.projectsPageIndex++;
        this.socketService.emitEvent('projectsIndex', { userId: this.selectedUserId, pageIndex: this.projectsPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('smallOrdersByUserCount').subscribe(count => {
      this.smallOrdersCount = count;

      if (count > 10) {
        this.socketService.emitEvent('smallOrdersByUserIndex', { userId: this.selectedUserId, pageIndex: this.smallOrdersPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('smallOrdersByUserIndex').subscribe((response: any) => {
      this.smallOrdersList = this.smallOrdersList.concat(response);

      if (this.smallOrdersPageIndex < (this.smallOrdersCount / 10)) {
        this.smallOrdersPageIndex++;
        this.socketService.emitEvent('smallOrdersByUserIndex', { userId: this.selectedUserId, pageIndex: this.smallOrdersPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('subportfoliosByUserCount').subscribe(count => {
      this.subportfoliosCount = count;

      if (count > 10) {
        this.socketService.emitEvent('subportfoliosByUser', { id: this.selectedUserId, pageIndex: this.subportfoliosPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('subportfoliosByUser').subscribe((response: any) => {
      this.subportfoliosList = this.subportfoliosList.concat(response);

      if (this.subportfoliosPageIndex < (this.subportfoliosCount / 10)) {
        this.subportfoliosPageIndex++;
        this.socketService.emitEvent('subportfoliosByUser', { id: this.selectedUserId, pageIndex: this.subportfoliosPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('programsCount').subscribe(count => {
      this.programsCount = count;

      if (count > 10) {
        this.socketService.emitEvent('programsIndex', { userId: this.selectedUserId, pageIndex: this.programsPageIndex, pageSize: 10 });
      }
    });

    this.socketService.listen('programsIndex').subscribe((response: any) => {
      this.programsList = this.programsList.concat(response);

      if (this.programsPageIndex < (this.programsCount / 10)) {
        this.programsPageIndex++;
        this.socketService.emitEvent('programsIndex', { userId: this.selectedUserId, pageIndex: this.programsPageIndex, pageSize: 10 });
      }
    });

  }

  assignProjectsValues(response) {
    response.forEach((project, index) => {
      if (project.docType == 'Outline') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.projectOutline[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.projectOutline[0].projectSponsor;
        }
      } else if (project.docType == 'Order') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.projectOrder[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.projectOrder[0].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.projectOrder[0].projectFico;
        }
      } else if (project.docType == 'Change Request') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.changeRequests[project.changeRequests.length - 1].projectFico;
        }
      } else if (project.docType == 'Closing Report') {
        if (project.workflowStatus.includes('PMO')) {
          response[index].holder = project.closingReport[0].pmoOfficer;
        } else if (project.workflowStatus.includes('Sponsor')) {
          response[index].holder = project.closingReport[0].projectSponsor;
        } else if (project.workflowStatus.includes('FICO')) {
          response[index].holder = project.closingReport[0].projectFico;
        }
      }
    });

    return response;
  }

  showChangeRequest(data) {
    let flag;
    if (data.key.closingReport) {
      flag = data.key.closingReport.length > 0 ?
        (data.key.closingReport[0].isApproved == true ? false : (data.key.closingReport[0].isSubmitted == true ?
          false : true)) : true;
    }

    if (flag) {
      if (data.key.changeRequests.length > 0) {
        return !data.key.changeRequestMade;
      } else {
        return true;
      }
    } else {
      return flag;
    }
  }

  projectsOnInputEvent(e) {
    this.apiService.get('/projects/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsSearchList = response;
    });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.spinner.show();
      this.apiService.get('/projects/' + e.itemData.id).subscribe(response => {
        this.apiService.get('/getOutlinesByProject/' + e.itemData.id + '?limit=0').subscribe(approvals => {
          this.projectsList = [];
          this.projectsList = this.assignProjectsValues([response]);
          this.approvalsList = approvals;
          this.spinner.hide();
        })
      })
    }
  }

  userOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  userValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.spinner.show();
      this.selectedUserId = e.itemData.id;
      this.approvalsPageIndex = 2;
      this.projectsPageIndex = 2;
      this.smallOrdersPageIndex = 2;
      this.subportfoliosPageIndex = 2;
      this.programsPageIndex = 2;

      this.fetchTeamProjects(e.itemData.id);
      this.apiService.get('/projects/userProjects/' + e.itemData.id + '?limit=10').subscribe(response => {
        this.apiService.get('/getOutlinesByUser/' + e.itemData.id + '?limit=10').subscribe(approvals => {
          this.apiService.get('/smallOrder/user/' + e.itemData.id + '?limit=10').subscribe((responseOrders: any) => {
            this.apiService.get('/subPortfolio/user/' + e.itemData.id + '?limit=10').subscribe((responseSubportfolio: any) => {
              this.apiService.get('/getProgramsByUser/' + e.itemData.id + '?limit=10').subscribe((responsePrograms: any) => {
                this.projectsList = [];
                this.approvalsList = [];
                this.smallOrdersList = [];
                this.subportfoliosList = [];
                this.programsList = [];
                this.projectsList = this.assignProjectsValues(response);
                this.approvalsList = approvals;
                this.smallOrdersList = responseOrders;
                this.subportfoliosList = responseSubportfolio;
                this.programsList = responsePrograms;

                this.socketService.emitEvent('approvalsCount', { userId: e.itemData.id });
                this.socketService.emitEvent('projectsCount', { userId: e.itemData.id });
                this.socketService.emitEvent('smallOrdersByUserCount', { userId: e.itemData.id });
                this.socketService.emitEvent('subportfoliosByUserCount', { id: e.itemData.id });
                this.socketService.emitEvent('programsCount', { userId: e.itemData.id });
                this.spinner.hide();
              });
            });
          });
        })
      })
    }
  }

  fetchTeamProjects(userId) {
    this.apiService.get('/user/' + userId + '/teams?limit=350').subscribe((teams: any) => {
      if (teams.length > 0) {
        this.teamProjectsList = [];

        teams.forEach((team, index) => {
          if (team.project && team.project != "undefined") {
            this.apiService.get('/projects/' + team.project).subscribe((responseProject: any) => {
              if (responseProject.projectReport && responseProject.projectReport.status == 'Active') {
                this.apiService.get('/getRight/' + responseProject.id + '/' + userId).subscribe((right: any) => {
                  responseProject.isView = right.isView;
                  responseProject.isEdit = right.isEdit;

                  this.teamProjectsList.push(responseProject);

                  if (index == teams.length - 1) {
                    this.teamProjectsList.forEach((project, index) => {
                      this.apiService.get('/projectOutline/' + project.projectOutline[0].id).subscribe((outlineResponse: any) => {
                        project.projectOutline[0] = outlineResponse;
                        if (project.projectOrder[0] != undefined && project.projectOrder[0] != null) {
                          this.apiService.get('/projectOrder/' + project.projectOrder[0].id).subscribe((orderResponse: any) => {
                            project.projectOrder[0] = orderResponse;
                          });
                        }
                        if (project.changeRequests != undefined && project.changeRequests != null) {
                          if (project.changeRequests.length > 0) {
                            project.changeRequests.forEach((changeRequest, index) => {
                              this.apiService.get('/changeRequest/' + project.changeRequests[index].id).subscribe((changeRequestResponse: any) => {
                                project.changeRequests[index] = changeRequestResponse;
                              });
                            });
                          }
                        }
                        if (project.closingReport[0] != undefined && project.closingReport[0] != null) {
                          this.apiService.get('/closingReport/' + project.closingReport[0].id).subscribe((closingReportResponse: any) => {
                            project.closingReport[0] = closingReportResponse;
                          });
                        }
                        if (project.docType == 'Outline') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.projectOutline[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.projectOutline[0].projectSponsor;
                          }
                        } else if (project.docType == 'Order') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.projectOrder[0].projectFico;
                          }
                        } else if (project.docType == 'Change Request') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.changeRequests[project.changeRequests.length - 1].projectFico;
                          }
                        } else if (project.docType == 'Closing Report') {
                          if (project.workflowStatus.includes('PMO')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].pmoOfficer;
                          } else if (project.workflowStatus.includes('Sponsor')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].projectSponsor;
                          } else if (project.workflowStatus.includes('FICO')) {
                            this.teamProjectsList[index].holder = project.closingReport[0].projectFico;
                          }
                        }
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  openOutlinePreview(outline) {
    this.spinner.show();
    this.showPreview = false;
    outline.projectOutline[0].projectName = outline.projectName;
    this.apiService.get('/projectOutline/' + outline.projectOutline[0].id).subscribe(response => {
      this.outlinePreview = response;
      this.spinner.hide();

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  openOrderPreview(project, order) {
    this.showOrderPreview = false;
    order.projectId = project;
    order = this.utilityService.setOrderPdfValues(order);
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openChangeRequestPreview(project, changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = project;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(project, closingReport) {
    this.showClosingPreview = false;
    closingReport.project = project;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  openComments(projectObj) {
    this.showComments = false;
    this.commentsArr = projectObj.comments;
    this.commentsProjectId = projectObj.id;

    setTimeout(() => {
      this.showComments = true;
    }, 100);
    this.commentsModal.show();
    this.commentsModal.onHidden.subscribe((reason: string) => {
      this.showComments = false;
      this.commentsArr = [];
    });
  }

  // saveComments(e) {
  //   if (this.commentsArr == undefined) {
  //     this.commentsArr = [];
  //   }
  //   if (this.commentMsg != '') {
  //     this.commentsArr.push({
  //       message: this.commentMsg,
  //       time: Date.now(),
  //       user: this.authService.userObj.email
  //     });
  //     this.commentMsg = '';
  //     setTimeout(() => {
  //       this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
  //     }, 100);

  //     this.apiService.put('/projects/' + this.commentsProjectId, {
  //       comments: this.commentsArr
  //     }).subscribe((response: any) => {
  //       // Update the existing grid
  //       this.projectsList.forEach((project, index) => {
  //         if (this.commentsProjectId == project.id) {
  //           this.projectsList[index].comments = response.comments;
  //         }
  //       });
  //       this.notificationService.createNotification(this.commentsProjectId, '', 'comment', this.authService.userObj.id);
  //       // this.refreshGrid();
  //     });
  //   }
  // }

  calculateCellValueSubmissionDate(e) {
    if (e.docType == "Outline") {
      if (e.projectOutline) {
        return e.projectOutline.createdAt;
      }
    }
    else if (e.docType == "Order")
      return e.projectOrder.createdAt;
    else if (e.docType == "Change Request") {
      return e.changeRequest.createdAt;
    } else {
      return e.closingReport.createdAt;
    }
  }

  navigateToProjectDetails(routeObj, isTeamProject, isTeamEdit) {
    this.utilityService.isTeamProject = isTeamProject;
    this.utilityService.isTeamEdit = isTeamEdit;
    this.router.navigate(routeObj);
  }

  //Approvals Functions

  openOutlinePreviewApproval(outline, name) {
    this.showPreview = false;
    outline = this.utilityService.setOutlinePdfValues(outline);
    this.outlinePreview = outline;
    this.outlinePreview.projectName = name;
    this.showPreview = true;
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  openOrderPreviewApproval(outline) {
    this.spinner.show();
    this.showOrderPreview = false;

    outline.projectOrder = this.utilityService.setOrderPdfValues(outline.projectOrder);

    if (outline.projectOrder.projectId && outline.projectOrder.projectId.id) {
      this.orderPreview = outline.projectOrder;

      setTimeout(() => {
        this.showOrderPreview = true;
      }, 100);
      this.spinner.hide();
      this.lgOrderModal.show();
      this.lgOrderModal.onHidden.subscribe((reason: string) => {
        this.showOrderPreview = false;
      });
    } else {
      this.apiService.get('/projects/' + outline.projectOrder.projectId.id).subscribe((project: any) => {
        outline.projectOrder.projectId = project;

        this.orderPreview = outline.projectOrder;

        setTimeout(() => {
          this.showOrderPreview = true;
        }, 100);
        this.spinner.hide();
        this.lgOrderModal.show();
        this.lgOrderModal.onHidden.subscribe((reason: string) => {
          this.showOrderPreview = false;
        });
      });
    }
  }

  openChangeRequestPreviewApproval(changeRequestObj) {
    this.spinner.show();
    this.showChangePreview = false;
    let projectId = changeRequestObj.changeRequest.project.id ? changeRequestObj.changeRequest.project.id : changeRequestObj.changeRequest.project;

    if (changeRequestObj.changeRequest.project && changeRequestObj.changeRequest.project.id) {
      if (changeRequestObj.changeRequest.project.projectReport && changeRequestObj.changeRequest.project.projectReport.id) {
        changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
        this.changeRequestPreview = changeRequestObj.changeRequest;

        setTimeout(() => {
          this.showChangePreview = true;
        }, 100);
        this.spinner.hide();
        this.lgChangeModal.show();
        this.lgChangeModal.onHidden.subscribe((reason: string) => {
          this.showChangePreview = false;
        });

      } else {
        this.apiService.get('/reports/' + changeRequestObj.changeRequest.project.projectReport).subscribe(response => {
          changeRequestObj.changeRequest.project.projectReport = response;
          changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
          this.changeRequestPreview = changeRequestObj.changeRequest;

          setTimeout(() => {
            this.showChangePreview = true;
          }, 100);
          this.spinner.hide();
          this.lgChangeModal.show();
          this.lgChangeModal.onHidden.subscribe((reason: string) => {
            this.showChangePreview = false;
          });
        });
      }
    } else {
      this.apiService.get('/projects/' + projectId).subscribe((project: any) => {
        changeRequestObj.changeRequest.project = project;

        if (changeRequestObj.changeRequest.project.projectReport == undefined) {
          this.apiService.get('/projects/' + changeRequestObj.changeRequest.project).subscribe((projectResponse: any) => {
            this.apiService.get('/reports/' + projectResponse.projectReport.id).subscribe(response => {
              changeRequestObj.changeRequest.project = projectResponse;
              changeRequestObj.changeRequest.project.projectReport = response;
              changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
              this.changeRequestPreview = changeRequestObj.changeRequest;

              setTimeout(() => {
                this.showChangePreview = true;
              }, 100);
              this.spinner.hide();
              this.lgChangeModal.show();
              this.lgChangeModal.onHidden.subscribe((reason: string) => {
                this.showChangePreview = false;
              });
            });
          });
        } else {
          if (typeof (changeRequestObj.changeRequest.project.projectReport) == 'string') {
            this.apiService.get('/reports/' + changeRequestObj.changeRequest.project.projectReport).subscribe(response => {
              changeRequestObj.changeRequest.project.projectReport = response;

              changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
              this.changeRequestPreview = changeRequestObj.changeRequest;

              setTimeout(() => {
                this.showChangePreview = true;
              }, 100);
              this.spinner.hide();
              this.lgChangeModal.show();
              this.lgChangeModal.onHidden.subscribe((reason: string) => {
                this.showChangePreview = false;
              });
            });
          } else {
            changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
            this.changeRequestPreview = changeRequestObj.changeRequest;

            setTimeout(() => {
              this.showChangePreview = true;
            }, 100);
            this.spinner.hide();
            this.lgChangeModal.show();
            this.lgChangeModal.onHidden.subscribe((reason: string) => {
              this.showChangePreview = false;
            });
          }
        }

      });
    }
  }

  openClosingReportPreviewApproval(outline) {
    this.spinner.show();
    this.showClosingPreview = false;

    let projectId = outline.closingReport.project.id ? outline.closingReport.project.id : outline.closingReport.project;

    if (outline.closingReport.project && outline.closingReport.project.id) {
      if (outline.closingReport.project.projectReport && outline.closingReport.project.projectReport.id) {
        this.closingReportPreview = outline.closingReport;

        setTimeout(() => {
          this.showClosingPreview = true;
        }, 100);
        this.spinner.hide();
        this.lgClosingModal.show();
        this.lgClosingModal.onHidden.subscribe((reason: string) => {
          this.showClosingPreview = false;
        });

      } else {
        this.apiService.get('/reports/' + outline.closingReport.project.projectReport).subscribe(response => {
          outline.closingReport.project.projectReport = response;
          this.closingReportPreview = outline.closingReport;

          setTimeout(() => {
            this.showClosingPreview = true;
          }, 100);
          this.spinner.hide();
          this.lgClosingModal.show();
          this.lgClosingModal.onHidden.subscribe((reason: string) => {
            this.showClosingPreview = false;
          });
        });
      }
    } else {
      this.apiService.get('/projects/' + projectId).subscribe((project: any) => {
        outline.closingReport.project = project;

        if (outline.closingReport.project.projectReport.id == undefined) {
          this.apiService.get('/reports/' + outline.closingReport.project.projectReport).subscribe(response => {
            outline.closingReport.project.projectReport = response;
            this.closingReportPreview = outline.closingReport;

            setTimeout(() => {
              this.showClosingPreview = true;
            }, 100);
            this.spinner.hide();
            this.lgClosingModal.show();
            this.lgClosingModal.onHidden.subscribe((reason: string) => {
              this.showClosingPreview = false;
            });
          });
        }
        else {
          this.closingReportPreview = outline.closingReport;
          setTimeout(() => {
            this.showClosingPreview = true;
          }, 100);
          this.spinner.hide();
          this.lgClosingModal.show();
          this.lgClosingModal.onHidden.subscribe((reason: string) => {
            this.showClosingPreview = false;
          });
        }
      });
    }
  }

  openSmallOrderPreview(smallOrder) {
    this.showSmallOrderPreview = false;
    this.smallOrderPreview = smallOrder;

    setTimeout(() => {
      this.showSmallOrderPreview = true;
    }, 100);
    this.lgModalSmallOrder.show();
    this.lgModalSmallOrder.onHidden.subscribe((reason: string) => {
      this.showSmallOrderPreview = false;
    });
  }

  ngOnDestroy() {
    this.socketService.removeListener('approvalsIndex');
    this.socketService.removeListener('approvalsCount');
    this.socketService.removeListener('projectsCount');
    this.socketService.removeListener('projectsIndex');
    this.socketService.removeListener('smallOrdersByUserCount');
    this.socketService.removeListener('smallOrdersByUserIndex');
    this.socketService.removeListener('subportfoliosByUserCount');
    this.socketService.removeListener('subportfoliosByUser');
    this.socketService.removeListener('programsCount');
    this.socketService.removeListener('programsIndex');
  }


}
