import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pdf-program-status-report-view',
  templateUrl: './pdf-program-status-report-view.component.html',
  styleUrls: ['./pdf-program-status-report-view.component.scss']
})
export class PdfProgramStatusReportViewComponent implements OnInit {
  statusReportPreview = {};
  prevStatusReport = {};
  showPreview: boolean = false;
  @ViewChild('popUp', {static: false}) public lgModal;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  public modalRef: BsModalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private modalService: BsModalService, private authService: AuthService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.openPreview(params['prev'], params['new']);
    });
  }

  openPreview(prevId, newId) {
    this.apiService.get('/programStatusReports/' + newId).subscribe(newResponse => {
      this.statusReportPreview = newResponse;

      if (prevId != '0') {
        this.apiService.get('/programStatusReports/' + prevId).subscribe(prevResponse => {
          this.prevStatusReport = prevResponse;

          setTimeout(() => {
            this.showPreview = true;
          }, 100);

          this.spinner.hide();

          this.modalRef = this.modalService.show(this.lgModal, this.config);
          this.modalService.onHidden.subscribe((reason: string) => {
            this.showPreview = false;
          });
        });
      } else {
        setTimeout(() => {
          this.showPreview = true;
        }, 100);
        this.spinner.hide();
        this.modalRef = this.modalService.show(this.lgModal, this.config);
        this.modalService.onHidden.subscribe((reason: string) => {
          this.showPreview = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }
}
