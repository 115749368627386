import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {
  usersList = [];
  user;

  constructor(private apiService: ApiService, private authService: AuthService, private utilityService: UtilityService,
    private localize: I18nPipe) {
    this.apiService.get('/getUserReport').subscribe((response: any) => {
      this.usersList = response;
    });

    this.utilityService.breadcrumb = 'User Report';
    this.utilityService.breadcrumbList = ['Settings', 'User Report'];
    this.user = authService.userObj.email;
  }

  ngOnInit() {
  }

  resetCount() {
    this.apiService.get('/resetCount').subscribe(response => {
      this.notification('Success', 'Active Users Count has been reset.');
    });
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }
}
