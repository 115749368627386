import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutRoutingModule } from './admin-layout-routing.module';
import { AdminLayoutComponent } from './admin-layout.component';
import { SidebarModule } from 'app/sidebar/sidebar.module';
import { FooterModule } from 'app/shared/footer/footer.module';
import { NavbarModule } from 'app/shared/navbar/navbar.module';
import { SocketService } from 'app/services/socket.service';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    AdminLayoutRoutingModule,
    SidebarModule,
    FooterModule,
    NavbarModule,
    I18nModule
  ],
  declarations: [AdminLayoutComponent],
  providers: [SocketService]
})

export class AdminLayoutModule { }
