import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallOrderLinksRoutingModule } from './small-order-links-routing.module';
import { SmallOrderLinksComponent } from './small-order-links.component';
import { DxAutocompleteModule, DxCheckBoxModule, DxDataGridModule, DxSelectBoxModule, DxTagBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SmallOrderLinksComponent
  ],
  imports: [
    CommonModule,
    SmallOrderLinksRoutingModule,
    DxDataGridModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxAutocompleteModule
  ]
})
export class SmallOrderLinksModule { }
