import { NgModule } from "@angular/core";

import { LanguageSelectorComponent } from "./language-selector/language-selector.component";
import { I18nPipe } from "./i18n.pipe";
import { I18nMultiPipe } from './i18n-multiple.pipe';
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LanguageSelectorComponent,
    I18nPipe,
    I18nMultiPipe
  ],
  exports: [LanguageSelectorComponent, I18nPipe, I18nMultiPipe],
  providers: [I18nPipe, I18nMultiPipe]

})
export class I18nModule { }
