import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfSmallOrderStatusReportComponent } from './pdf-small-order-status-report.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { DxPieChartModule, DxSliderModule, DxChartModule } from 'devextreme-angular';
import { ChartsModule } from 'ng2-charts';
import { ShortNumberPipeModule } from 'app/shared/short-number-pipe/short-numberPipe.module';



@NgModule({
  declarations: [PdfSmallOrderStatusReportComponent],
  imports: [
    CommonModule,
    CurrencyFormatterPipeModule,
    DxPieChartModule,
    DxSliderModule,
    DxChartModule,
    ChartsModule,
    I18nModule,
    ShortNumberPipeModule
  ],
  exports:[
    PdfSmallOrderStatusReportComponent
  ]
})
export class PdfSmallOrderStatusReportModule { }
