import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { UtilityService } from './services/utility.service';
import { debug } from 'util';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;

  constructor(private authService: AuthService, public router: Router, private apiService: ApiService,
    private utilityService: UtilityService) {
    if (this.authService.isLoggedIn) {
      if (location.href.includes('Preview')) {
        let hash = location.href.split('/');

        if (location.href.includes('outlinePreview')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setOutlinePdfValues({});
          } else {
            this.router.navigate(['outlinePreview/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('orderPreview')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setOrderPdfValues({});
          } else {
            this.router.navigate(['orderPreview/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('changeRequestPreview')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setChangeRequestPdfValues({});
          } else {
            this.router.navigate(['changeRequestPreview/' + hash[hash.length - 1]]);
          }
        } else {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setClosingReportPdfValues({});
          } else {
            this.router.navigate(['closingReportPreview/' + hash[hash.length - 1]]);
          }
        }
      } else if (location.href.includes('view/')) {
        let hash = location.href.split('/');

        if (location.href.includes('outline')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setOutlinePdfValues({});
          } else {
            this.router.navigate(['view/outline/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('order')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setOrderPdfValues({});
          } else {
            this.router.navigate(['view/order/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('changeRequest')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setChangeRequestPdfValues({});
          } else {
            this.router.navigate(['view/changeRequest/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('closingReport')) {
          if (this.utilityService.strategicContributionOptions == undefined) {
            this.utilityService.setClosingReportPdfValues({});
          } else {
            this.router.navigate(['view/closingReport/' + hash[hash.length - 1]]);
          }
        } else if (location.href.includes('project/statusReport')) {
          this.router.navigate(['view/project/statusReport/' + hash[hash.length - 2], hash[hash.length - 1]]);
        } else if (location.href.includes('program/statusReport')) {
          this.router.navigate(['view/program/statusReport/' + hash[hash.length - 2], hash[hash.length - 1]]);
        } 
        // else {
        //   this.router.navigate(['news']);
        // }
      } 
      // else {
      //   this.router.navigate(['news']);
      // }
    } else {
      if (location.href.includes('auth')) {
        if (!location.href.includes('login')) {
          if (!location.href.includes('signout')) {

            // Checking for PDF View components redirect URLs
            if (location.href.includes('view/') == true) {
              this.authService.isPreview = true;
              this.authService.previewLink = location.href.split('?redirectTo=').pop();
              this.router.navigate(['auth/' + location.href.split('?redirectTo=')[0].split('/').pop()])
            }

            let id = window.location.href.split('/').pop();
            this.apiService.get('/user/' + id).subscribe(response => {
              this.authService.isLoggedIn = true;
              this.authService.userObj = response;
              this.apiService.get('/getGlobalConfigurations').subscribe(response => {
                this.authService.userObj.configuration = response;
                localStorage.setItem('userObj', JSON.stringify(this.authService.userObj));
                this.utilityService.setTablesState(this.authService.userObj.tablesState);
                // if (this.authService.userObj.role == 'admin' || this.authService.userObj.role == 'pmo') {
                //   this.router.navigate(['/news']);
                // } else {
                  this.router.navigate(['/news']);
                // }
              });
            });
          } else {
            this.router.navigate(['auth/login']);
          }
        } else {
          this.router.navigate(['auth/login']);
        }
      }
    }
  }

  getLocale() {
    var locale = localStorage.getItem("language");
    return locale != null ? (locale == 'us' ? 'en' : locale) : "en";
  }

  ngOnInit() {
  }
}
