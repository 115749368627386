import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxSelectBoxComponent, DxTreeViewComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { retry } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'ts-xlsx';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit, AfterViewInit {
  artDerMassnahmen = ['Fischaufstieg', 'Fischabstieg', 'Fischgängigkeit' ,'Geschiebesanierung', 'Schwall/Sunk' ]
  Gesellschaft = ['BIK', 'BKW' , 'KWS' , 'SKW' , 'SON (steht für Sonstige)']

  spLink: string = '';
  projSponsorEmail;
  projFicoEmail;
  tabs: FormGroup;
  projectBudget: any = [];
  additionalColumns: any = [];
  projectObj: any = {
    uid: '',
    portfolio: {
      id: ''
    },
    projectBudgetOriginal: "",
    projectManager: {
      id: 0,
      email: ''
    },
    pmoOfficer: '',
    projectSponsor: '',
    projectFico: '',
    kpisTable: [{}, {}, {}, {}, { value: '' }],
    program: '',
    threeYearsBudgetBreakdown: [],
    mandatoryProject: {
      id: ""
    },
    businessArea: {
      id: ""
    },
    businessUnit: {
      id: ""
    },
    businessSegment: {
      id: ""
    },
    reportingLevel: {
      id: ""
    },
    classification: {
      id: ""
    },
    projectType: {
      id: ""
    },
    strategicContribution: {
      id: ""
    },
    profitability: {
      id: ""
    },
    feasibility: {
      id: ""
    },
    projectMethodology: {
      id: ""
    },
    projectPhase: {
      id: ""
    },
    digitalizationTopic: {
      id: ""
    },
    digitalizationFocus: {
      id: ""
    },
    itPlatform: [],
    itRelevant: {
      id: ""
    },
    subPortfolio: {
      id: ''
    }
  };
  PMOsList;
  usersList;
  businessUnitOptions;
  partOfaProgramOptions = [];
  businessAreaOptions;
  businessSegmentOptions;
  reportingLevelOptions;
  classificationOptions;
  projectTypeOptions;
  digitalizationTopicOptions;
  digitalizationFocusOptions;
  itPlatformOptions;
  itRelevantOptions;
  technologyOptions;
  strategyContributionOptions;
  feasibilityOptions;
  profitabilityOptions;
  projectMethodologyOptions;
  projectPhaseOptions;
  currencyOptions = [];
  isAProgramOptions = [];
  subProjectsOptions = [];
  statusOptions;
  tabsOptions = [];
  statusReportTable = [];
  projectReportId;
  timeCompleted;
  endDateVSPlannedEndDate;
  forecastEndDate = '';
  plannedDateVSForecastDate;
  totalTime;
  portfolioList = [];
  subPortfolioList = [];
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: true }) public lgModal;
  @ViewChild('lgOrderModal', { static: true }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: true }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: true }) public lgClosingModal;
  tabsEN = [];
  tabsDE = [];
  milestoneTab = true;
  evaTab = true;
  decisionsTab = true;
  lessonsTab = true;
  documentsTab = true;
  teamTab = true;
  @ViewChild('tabsElem', { static: false }) tabsElem;
  tabIndex = 0;
  currentBudgetVSOriginalBudget = '0';
  currentBudgetVSActualCost = '0';
  currentBudgetVSForecast = '0';
  isMember: boolean = false;
  @ViewChild('projectManagerSelect', { static: false }) projectManagerSelect: DxSelectBoxComponent;
  prevStatusReport;
  projectSponsorBackup;
  pmoBackup;
  confidentialOptions;
  evaOptions;
  isProjectUneditable: boolean = false;

  portfolioData;
  hasPortfolio: boolean = false;
  hasPortfolioSaved: boolean = false;
  technology: string[];
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  invalidChars = [
    "-",
    "+",
    "e",
  ];
  FrontEnd_PATH;
  projectFicoBackup;
  projectId;
  public isTeamEdit: boolean = false;
  public isTeamProject: boolean = false;
  public enableCreateReportButton: boolean = false;
  projectSubPortfolioBackup;
  projectBudgetList: any = [];
  stateStore;
  tabsItemId;
  treeViewObj;
  projectDocuments;
  showOutlinePreview: boolean = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  loadingVisible = false;
  outlinePreview;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  BASE_PATH;
  attachmentQueryParams = '';
  projectSuffix = "";
  isPrivileged: boolean = false;
  arrayBuffer: any;
  file: File;

  constructor(private apiService: ApiService, public authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private formBuilder: FormBuilder, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Project Details";
    this.utilityService.reportsTab = "Overview";
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;

    globalThis = this;
    this.getProjectInfo();
    this.fetchPMOs();
    this.fetchUsers();
    this.fetchPrograms();

    if (authService.userObj.role == 'admin' || authService.userObj.role == 'pmo') {
      this.isPrivileged = true;
    }

    this.projectObj.psp = [
      {
        psp: "",
        description: ""
      },
      {
        psp: "",
        description: ""
      },
      {
        psp: "",
        description: ""
      },
      {
        psp: "",
        description: ""
      },
      {
        psp: "",
        description: ""
      }
    ];

    this.stateStore = {
      enabled: true,
      storageKey: 'projectStatusReportList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    // this.tabs = this.formBuilder.group({
    // });

    this.currencyOptions = ['CHF', 'EUR', 'USD'];
    this.isAProgramOptions = ['Yes', 'No'];
    this.statusOptions = ['Active', 'On Hold', 'Not Realized', 'Closed'];
    this.tabsOptions = ['Milestones', 'EVA', 'Decisions', 'Lessons', 'Documents', 'Team'];
    this.confidentialOptions = ['Yes', 'No'];
    this.evaOptions = ['Yes', 'No'];

    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessSegmentOptions = this.utilityService.businessSegmentOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.strategyContributionOptions = this.utilityService.strategicContributionOptions;
    this.feasibilityOptions = this.utilityService.feasibilityOptions;
    this.profitabilityOptions = this.utilityService.profitabilityOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;
    this.projectPhaseOptions = this.utilityService.projectPhaseOptions;
    this.digitalizationTopicOptions = this.utilityService.digitalizationTopicOptions
    this.digitalizationFocusOptions = this.utilityService.digitalizationFocusOptions
    this.itPlatformOptions = this.utilityService.itPlatformOptions
    this.itRelevantOptions = this.utilityService.itRelevantOptions
    this.technologyOptions = this.utilityService.technologyOptions
  }

  ngAfterViewInit() {
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      this.isTeamProject = this.utilityService.isTeamProject;
      this.isTeamEdit = this.utilityService.isTeamEdit;

      this.spinner.show();
      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.apiService.get('/projects/' + projectData.projectId).subscribe((projectRes: any) => {
          this.projectDocuments = projectRes;
          this.utilityService.breadcrumbList = ['My Projects', this.projectDocuments.projectName, 'Details', 'Overview'];

          // Check for user roles
          if (!this.isTeamEdit) {
            let user = this.authService.userObj;
            if (user.email == projectData.pmoOfficer.email
              || user.email == projectData.projectManager.email
              || user.email == projectData.projectSponsor.email
              || user.email == projectData.projectFico.email
              || user.role == 'admin'
              || user.role == 'pmo') {
              this.isTeamEdit = true;
              this.utilityService.isTeamEdit = true;
            }
          }

          this.apiService.get('/getActivePortfolios').subscribe((portfolioList: any) => {

            this.projectReportId = params.id;
            this.projectId = projectData.project.id;
            this.portfolioList = portfolioList;

            this.utilityService.question = projectData.question ? projectData.question : [];
            this.utilityService.orderQuestion = projectData.orderQuestion ? projectData.orderQuestion : [];
            this.utilityService.changeRequestQuestion = projectData.changeRequestQuestion ? projectData.changeRequestQuestion : [];
            this.utilityService.closingQuestion = projectData.closingQuestion ? projectData.closingQuestion : [];

            if (this.projectBudget != undefined && this.projectBudget.portfolioBudgetYear != undefined) {
              this.additionalColumns = this.projectBudget.portfolioBudgetYear.additionalColumns ? this.projectBudget.portfolioBudgetYear.additionalColumns : [];
            }

            if (this.authService.userObj.role == 'guest' && projectData.projectManager.email != this.authService.userObj.email) {
              this.isProjectUneditable = true;
            }

            {
              if (projectData.portfolio == undefined) {
                projectData.portfolio = this.projectObj.portfolio;
              } else {
                this.subPortfolioList = this.portfolioList.find(val => val.id == projectData.portfolio.id).subPortfolio; //projectData.portfolio.subPortfolio;
              }

              if (projectData.subPortfolio == undefined || projectData.subPortfolio == null) {
                projectData.subPortfolio = this.projectObj.subPortfolio;
              }
              if (projectData.program == undefined) {
                projectData.program = this.projectObj.program;
              }
              if (projectData.businessArea == undefined) {
                projectData.businessArea = {
                  id: ''
                };
              }
              if (projectData.businessUnit == undefined) {
                projectData.businessUnit = {
                  id: ''
                };
              }
              if (projectData.businessSegment == undefined) {
                projectData.businessSegment = {
                  id: ''
                };
              }
              if (projectData.reportingLevel == undefined) {
                projectData.reportingLevel = {
                  id: ''
                };
              }
              if (projectData.classification == undefined) {
                projectData.classification = {
                  id: ''
                };
              }
              if (projectData.projectType == undefined) {
                projectData.projectType = {
                  id: ''
                };
              }
              if (projectData.strategicContribution == undefined) {
                projectData.strategicContribution = {
                  id: ''
                };
              }
              if (projectData.profitability == undefined) {
                projectData.profitability = {
                  id: ''
                };
              }
              if (projectData.feasibility == undefined) {
                projectData.feasibility = {
                  id: ''
                };
              }
              if (projectData.projectPhase == undefined) {
                projectData.projectPhase = {
                  id: ''
                };
              }
              if (projectData.projectMethodology == undefined) {
                projectData.projectMethodology = {
                  id: ''
                };
              }
              if (projectData.digitalizationTopic == undefined) {
                projectData.digitalizationTopic = {
                  id: ''
                };
              }
              if (projectData.digitalizationFocus == undefined) {
                projectData.digitalizationFocus = {
                  id: ''
                };
              }
              if (projectData.itPlatform == undefined) {
                projectData.itPlatform = [];
              }
              if (projectData.itRelevant == undefined) {
                projectData.itRelevant = {
                  id: ''
                };
              }
              if (projectData.technology != undefined) {
                this.technology = projectData.technology
              }
              if (projectData.projectFico == undefined) {
                projectData.projectFico = {
                  id: ''
                };
              }

              if (projectData.bkwShare == undefined) {
                projectData.bkwShare = 100;
              }

              if (projectData.psp == undefined) {
                projectData.psp = [
                  {
                    psp: "",
                    description: ""
                  },
                  {
                    psp: "",
                    description: ""
                  },
                  {
                    psp: "",
                    description: ""
                  },
                  {
                    psp: "",
                    description: ""
                  },
                  {
                    psp: "",
                    description: ""
                  }
                ];
              }

              if (projectData.GwH == undefined) {
                projectData.GwH = "";
              }

              if (projectData.evaCondition == undefined) {
                projectData.evaCondition = 'No';
              }
            }

            this.projectObj = projectData;

            if (projectRes.isPhase) {
              let sepIndex = this.projectObj.projectName.lastIndexOf("-");
              this.projectSuffix = this.projectObj.projectName.substring(sepIndex);
              this.projectObj.projectName = this.projectObj.projectName.substring(0, sepIndex);
            }

            if (this.projectObj.documentLink == undefined || this.projectObj.documentLink == '') {
              this.spLink = '';
            } else {
              this.spLink = this.projectObj.documentLink;
            }

            if (this.projectObj.projectSponsor && this.projectObj.projectSponsor.email != '') {
              this.projSponsorEmail = this.projectObj.projectSponsor.email;
            }

            if (this.projectObj.projectFico && this.projectObj.projectFico.email != '') {
              this.projFicoEmail = this.projectObj.projectFico.email;
            }

            this.projectSponsorBackup = this.projectObj.projectSponsor.id;
            this.pmoBackup = this.projectObj.pmoOfficer.id;
            if (this.projectObj.projectFico.id != undefined) {
              this.projectFicoBackup = this.projectObj.projectFico.id;
            }

            if (this.projectObj.subPortfolio.id != undefined) {
              this.projectSubPortfolioBackup = this.projectObj.subPortfolio;
            }


            this.utilityService.projectDetail.next({
              projectReportId: this.projectReportId,
              projectId: this.projectId,
              isTeamProject: this.isTeamProject,
              projectName: this.projectObj.projectName
            });

            this.spinner.hide();
          });

        })

      });
    });
  }

  onYearChanged(event) {
    this.projectBudget = event.value;
    this.additionalColumns = event.value.portfolioBudgetYear.additionalColumns ? this.projectBudget.portfolioBudgetYear.additionalColumns : [];
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    if (this.file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.spinner.show();
      this.readFile();
    } else {
      this.showNotificationBox("Failed", "Please upload a valid Excel File!", "warning");
    }
  }

  readFile() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.utilityService.processExcelFile(excelData);
    }

    fileReader.readAsArrayBuffer(this.file);
  }

  fetchPMOs() {
    this.PMOsList = this.utilityService.PMOsList;
  }

  fetchUsers() {
    this.usersList = this.utilityService.usersList;
  }

  fetchPrograms() {
    this.apiService.get('/getActivePrograms').subscribe((response: any) => {
      this.partOfaProgramOptions = response;
    });
  }

  isWebURL(value) {
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(value)
  }

  isWebViewUrl(value) {
    let regexp = /^(http|https):///
    return regexp.test(value)
  }

  redirectToLink() {
    if (this.spLink) {
      window.open(this.spLink, "_blank");
    }
  }

  calculateDatePercentages() {
    let endDate: any = moment(this.projectObj.endDate);
    let startDate: any = moment(this.projectObj.startDate);
    let plannedDate: any = moment(this.projectObj.plannedEndDate);
    let forecastDate: any = moment(this.projectObj.forecastEndDate);
    let now = moment(Date.now());

    this.totalTime = endDate.diff(startDate, 'days') + ' days';
    this.timeCompleted = (100 - (Math.round((endDate.diff(startDate, 'days') - now.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100))) + '%';
    this.endDateVSPlannedEndDate = Math.round((plannedDate.diff(startDate, 'days') - endDate.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100) + '%';
    if (this.projectObj.forecastEndDate != undefined) {
      this.plannedDateVSForecastDate = Math.round((plannedDate.diff(forecastDate, 'days') / plannedDate.diff(startDate, 'days')) * -100) + '%';
    }
  }

  projectManagerSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.usersList != undefined && this.usersList.length > 0) {
        this.usersList.forEach(user => {
          if (user.email == e.value) {
            this.projectObj.projectManager = user;
          }
        });
      }
    }
  }

  projectSponsorSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(user => {
          if (user.email == e.value) {
            this.projectObj.projectSponsor = user;
          }
        });
      }
    }
  }

  projectFicoSelectvalueChanged(e) {
    if (e.value != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(user => {
          if (user.email == e.value) {
            this.projectObj.projectFico = user;
          }
        });
      }
    }
  }

  projectFicoOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).pipe(retry(2)).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.projectObj.projectFico = response;
            } else {
              this.projectObj.projectFico = { id: '' };
            }
          });
        }
      } else {
        this.projectObj.projectFico = { id: '' };
      }
    });
  }

  pmoSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.PMOsList != undefined) {
        this.PMOsList.forEach(user => {
          if (user.id == e.value) {
            this.projectObj.pmoOfficer = user;
          }
        });
      }
    }
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(portfolio => {
          if (portfolio.id == e.value) {
            this.projectObj.portfolio = portfolio;
            this.subPortfolioList = portfolio.subPortfolio != undefined ? portfolio.subPortfolio : [];
          }
        });
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.projectObj.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  programSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.partOfaProgramOptions != undefined) {
        this.partOfaProgramOptions.forEach(user => {
          if (user.id == e.value) {
            this.projectObj.program = user;
          }
        });
      }
    }
  }

  saveOverview() {
    this.spinner.show();

    if (this.projectDocuments.isPhase) {
      this.projectObj.projectName = this.projectObj.projectName + this.projectSuffix;
    }

    if (this.spLink != '' && this.isWebURL(this.spLink)) {
      this.projectObj.documentLink = this.isWebViewUrl(this.spLink) ? this.spLink : `http://${this.spLink}`;

      this.apiService.put('/projects/' + this.projectObj.projectId, {
        documentLink: this.projectObj.documentLink
      }).subscribe(response => { });
    }

    if (this.projectSubPortfolioBackup == undefined || this.projectSubPortfolioBackup.id != this.projectObj.subPortfolio.id) {
      let budget = [
        {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          group: "Sonstiges",
          id: 5,
        }, {
          costType: "Total",
          budget: '',
          thereofICT: '',
          actualCost: "",
          forecast: "",
          id: 6,
          group: "Sonstiges",
        },
      ];

      this.apiService.post('/projectBudgetCost/yearlyBudget/switch', {
        project: this.projectObj.project.id,
        budget,
        subPortfolio: this.projectObj.subPortfolio.id
      }).subscribe(response => {
        console.log(response);
      }, error => {
        console.log(error.message);
      });

    }

    this.calculateDatePercentages();

    this.projectObj.technology = this.technology;

    if (this.pmoBackup != this.projectObj.pmoOfficer.id) {
      this.apiService.post('/updateApprovalOwner', {
        prev: this.pmoBackup,
        project: this.projectObj.project.id,
        new: this.projectObj.pmoOfficer.id,
        sentTo: 'PMO'
      }).subscribe(response => {
        this.pmoBackup = this.projectObj.pmoOfficer.id;
      });
    }

    if (this.projectSponsorBackup != this.projectObj.projectSponsor.id) {
      this.apiService.post('/updateApprovalOwner', {
        prev: this.projectSponsorBackup,
        project: this.projectObj.project.id,
        new: this.projectObj.projectSponsor.id,
        sentTo: 'Sponsor'
      }).subscribe(response => {
        this.projectSponsorBackup = this.projectObj.projectSponsor.id;

        //Add new Sponsor to Team
        let teamId, teamUsers = [];
        this.apiService.get('/project/team/' + this.projectObj.project.id).subscribe((projectTeam: any) => {
          if (projectTeam.teamId != null && projectTeam.teamId != undefined) {
            teamId = projectTeam.teamId;
            teamUsers = projectTeam.teamUsers;
          }

          if (this.projectObj.projectSponsor.id != '' && this.projectObj.projectSponsor.id != undefined && this.projectObj.projectSponsor.id != null &&
            this.projectObj.projectSponsor.email != '' && this.projectObj.projectSponsor.email != undefined && this.projectObj.projectSponsor.name != null) {
            if (teamUsers.find(val => val.id == this.projectObj.projectSponsor.id) != undefined) {
              // this.spinner.hide();
            } else {
              if (teamId != undefined && teamId != null) {
                let teamUsersIds = this.getteamUsersIds(teamUsers);
                teamUsersIds.push(this.projectObj.projectSponsor.id);
                this.apiService.put('/team/' + teamId, {
                  users: teamUsersIds,
                }).subscribe((responseTeam: any) => {
                  this.apiService.post('/rights', {
                    user: this.projectObj.projectSponsor.id,
                    project: this.projectObj.project.id,
                    isView: true,
                    isEdit: true
                  }).subscribe((responseRights: any) => {
                    // this.spinner.hide();
                  });
                });
              } else {
                this.apiService.post('/team', {
                  users: [this.projectObj.projectSponsor.id],
                  project: this.projectObj.project.id
                }).subscribe((responseTeam: any) => {
                  teamId = responseTeam.id;
                  this.apiService.post('/rights', {
                    user: this.projectObj.projectSponsor.id,
                    project: this.projectObj.project.id,
                    isView: true,
                    isEdit: true
                  }).subscribe((responseRights: any) => {
                    // this.spinner.hide();
                  });
                });
              }
            }
          } else {
            // this.spinner.hide();
          }
        });
      });
    }

    if (this.projectFicoBackup != this.projectObj.projectFico.id) {
      this.apiService.post('/updateApprovalOwner', {
        prev: this.projectFicoBackup,
        project: this.projectObj.project.id,
        new: this.projectObj.projectFico.id,
        sentTo: 'FICO'
      }).subscribe(responce => {
        this.projectFicoBackup = this.projectObj.projectFico.id;

        //Add new FICO to Team
        let teamId, teamUsers = [];
        this.apiService.get('/project/team/' + this.projectObj.project.id).subscribe((projectTeam: any) => {
          if (projectTeam.teamId != null && projectTeam.teamId != undefined) {
            teamId = projectTeam.teamId;
            teamUsers = projectTeam.teamUsers;
          }

          if (this.projectObj.projectFico.id != '' && this.projectObj.projectFico.id != undefined && this.projectObj.projectFico.id != null &&
            this.projectObj.projectFico.email != '' && this.projectObj.projectFico.email != undefined && this.projectObj.projectFico.name != null) {
            if (teamUsers.find(val => val.id == this.projectObj.projectFico.id) != undefined) {
              // this.spinner.hide();
            } else {
              if (teamId != undefined && teamId != null) {
                let teamUsersIds = this.getteamUsersIds(teamUsers);
                teamUsersIds.push(this.projectObj.projectFico.id);
                this.apiService.put('/team/' + teamId, {
                  users: teamUsersIds,
                }).subscribe((responseTeam: any) => {
                  this.apiService.post('/rights', {
                    user: this.projectObj.projectFico.id,
                    project: this.projectObj.project.id,
                    isView: true,
                    isEdit: true
                  }).subscribe((responseRights: any) => {
                    // this.spinner.hide();
                  });
                });
              } else {
                this.apiService.post('/team', {
                  users: [this.projectObj.projectFico.id],
                  project: this.projectObj.project.id
                }).subscribe((responseTeam: any) => {
                  teamId = responseTeam.id;
                  this.apiService.post('/rights', {
                    user: this.projectObj.projectFico.id,
                    project: this.projectObj.project.id,
                    isView: true,
                    isEdit: true
                  }).subscribe((responseRights: any) => {
                    // this.spinner.hide();
                  });
                });
              }
            }
          } else {
            // this.spinner.hide();
          }
        });
      });
    }

    if (this.projectObj.user == undefined) {
      this.projectObj.user = this.projectObj.projectManager;
    }

    if (this.projectObj.projectManager.id != this.projectObj.user.id) {
      if (this.projectObj.projectManager.role == 'guest') {
        this.apiService.put('/user/' + this.projectObj.projectManager.id, {
          role: 'pm'
        }).subscribe(userResponse => {
          this.projectObj.projectManager = userResponse;
          this.apiService.put('/projects/' + this.projectObj.project.id, {
            user: this.projectObj.projectManager.id
          }).subscribe((response: any) => {
            let user = this.projectObj.user;
            this.projectObj.user = this.projectObj.projectManager.id;
            this.projectObj.project.user = response.user;

            this.apiService.put('/reports/' + this.projectObj.id, this.projectObj).subscribe(updatedObj => {
              this.projectObj.user = user;
              this.spinner.hide();
              this.showNotificationBox('Save Success', 'Project Details has been saved.', 'success');
              if (this.projectDocuments.isPhase) {
                let sepIndex = this.projectObj.projectName.lastIndexOf("-");
                this.projectObj.projectName = this.projectObj.projectName.substring(0, sepIndex);
              }
            });
          });
        });
      } else {
        this.apiService.put('/projects/' + this.projectObj.project.id, {
          user: this.projectObj.projectManager.id
        }).subscribe((response: any) => {
          let user = this.projectObj.user;
          this.projectObj.user = this.projectObj.projectManager.id;
          this.projectObj.project.user = response.user;

          this.apiService.put('/reports/' + this.projectObj.id, this.projectObj).subscribe(updatedObj => {
            this.projectObj.user = user;
            this.spinner.hide();
            this.showNotificationBox('Save Success', 'Project Details has been saved.', 'success');
            this.utilityService.saneirungSubject.next(this.projectObj.isViewSanierung);
            if (this.projectDocuments.isPhase) {
              let sepIndex = this.projectObj.projectName.lastIndexOf("-");
              this.projectObj.projectName = this.projectObj.projectName.substring(0, sepIndex);
            }
          });
        });
      }
    } else {
      let user = this.projectObj.user;
      this.projectObj.user = this.projectObj.projectManager.id;
      this.apiService.put('/reports/' + this.projectObj.id, this.projectObj).subscribe(updatedObj => {
        this.projectObj.user = user;
        this.spinner.hide();
        this.showNotificationBox('Save Success', 'Project Details has been saved.', 'success');
        this.utilityService.saneirungSubject.next(this.projectObj.isViewSanierung);
        if (this.projectDocuments.isPhase) {
          let sepIndex = this.projectObj.projectName.lastIndexOf("-");
          this.projectObj.projectName = this.projectObj.projectName.substring(0, sepIndex);
        }
      });
    }
  }

  getteamUsersIds(teamUsers): any {
    let teamUsersIds = [];
    teamUsers.forEach(team => {
      teamUsersIds.push(team.user.id);
    });
    return teamUsersIds;
  }

  openOutlinePreview(outline) {
    this.loadingVisible = true;
    this.showOutlinePreview = false;
    this.apiService.get('/projectOutline/' + outline.id).subscribe(response => {
      this.outlinePreview = response;

      this.loadingVisible = false;

      setTimeout(() => {
        this.showOutlinePreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showOutlinePreview = false;
      });
    });
  }

  openOrderPreview(order) {
    this.showOrderPreview = false;
    order.projectId = this.projectDocuments;
    order = this.utilityService.setOrderPdfValues(order);
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openChangeRequestPreview(changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = this.projectDocuments;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(closingReport) {
    this.showClosingPreview = false;
    closingReport.project = this.projectDocuments;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  calculateCurrentYearBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.currentYear ? (Math.abs(e.currentYear) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.projectObj.threeYearsBudgetBreakdown[6].currentYear = globalThis.threeYearsBudgetCalculator('currentYear');
      return globalThis.projectObj.threeYearsBudgetBreakdown[6].currentYear;
    } else {
      return e.currentYear ? e.currentYear : '';
    }
  }

  calculateYear2BudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.yearTwo ? (Math.abs(e.yearTwo) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.projectObj.threeYearsBudgetBreakdown[6].yearTwo = globalThis.threeYearsBudgetYear2Calculator('yearTwo');
      return globalThis.projectObj.threeYearsBudgetBreakdown[6].yearTwo;
    } else {
      return e.yearTwo ? e.yearTwo : '';
    }
  }

  calculateYear3BudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.yearThree ? (Math.abs(e.yearThree) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.projectObj.threeYearsBudgetBreakdown[6].yearThree = globalThis.threeYearsBudgetYear3Calculator('yearThree');
      return globalThis.projectObj.threeYearsBudgetBreakdown[6].yearThree;
    } else {
      return e.yearThree ? e.yearThree : '';
    }
  }


  threeYearsBudgetCalculator(field) {
    let one: any = globalThis.projectObj.threeYearsBudgetBreakdown[0][field] * 1;
    let two: any = globalThis.projectObj.threeYearsBudgetBreakdown[1][field] * 1;
    let three: any = globalThis.projectObj.threeYearsBudgetBreakdown[2][field] * 1;
    let four: any = globalThis.projectObj.threeYearsBudgetBreakdown[3][field] * 1;
    let five: any = globalThis.projectObj.threeYearsBudgetBreakdown[4][field] * 1;
    let six: any = globalThis.projectObj.threeYearsBudgetBreakdown[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  threeYearsBudgetYear2Calculator(field) {
    let one: any = globalThis.projectObj.threeYearsBudgetBreakdown[0][field] * 1;
    let two: any = globalThis.projectObj.threeYearsBudgetBreakdown[1][field] * 1;
    let three: any = globalThis.projectObj.threeYearsBudgetBreakdown[2][field] * 1;
    let four: any = globalThis.projectObj.threeYearsBudgetBreakdown[3][field] * 1;
    let five: any = globalThis.projectObj.threeYearsBudgetBreakdown[4][field] * 1;
    let six: any = globalThis.projectObj.threeYearsBudgetBreakdown[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  threeYearsBudgetYear3Calculator(field) {
    let one: any = globalThis.projectObj.threeYearsBudgetBreakdown[0][field] * 1;
    let two: any = globalThis.projectObj.threeYearsBudgetBreakdown[1][field] * 1;
    let three: any = globalThis.projectObj.threeYearsBudgetBreakdown[2][field] * 1;
    let four: any = globalThis.projectObj.threeYearsBudgetBreakdown[3][field] * 1;
    let five: any = globalThis.projectObj.threeYearsBudgetBreakdown[4][field] * 1;
    let six: any = globalThis.projectObj.threeYearsBudgetBreakdown[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  tabsItemClick(e) {
    this.utilityService.reportsTab = e;
    if (this.utilityService.reportsTab == "Time*") {
      this.calculateDatePercentages();
    }
  }

  updateReportForEVA(e: any) {
    this.projectObj.EVA = e.EVA;
  }

  tabsChnageEvent(e) {
    this.projectObj.tabs = e.value;
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.businessUnit = response;
        }
      });
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.businessArea = response;
        }
      });
    }
  }

  businessSegmentValueChanged(e) {
    if (this.businessSegmentOptions != undefined) {
      this.businessSegmentOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.businessSegment = response;
        }
      });
    }
  }

  reportingLevelValueChanged(e) {
    if (this.reportingLevelOptions != undefined) {
      this.reportingLevelOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.reportingLevel = response;
        }
      });
    }
  }

  classificationValueChanged(e) {
    if (this.classificationOptions != undefined) {
      this.classificationOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.classification = response;
        }
      });
    }
  }

  projectTypeValueChanged(e) {
    if (this.projectTypeOptions != undefined) {
      this.projectTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.projectType = response;
        }
      });
    }
  }

  digitalizationTopicValueChanged(e) {
    if (this.digitalizationTopicOptions != undefined) {
      this.digitalizationTopicOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.digitalizationTopic = response;
        }
      });
    }
  }

  digitalizationFocusValueChanged(e) {
    if (this.digitalizationFocusOptions != undefined) {
      this.digitalizationFocusOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.digitalizationFocus = response;
        }
      });
    }
  }

  itPlatformValueChanged(e) {
    if (this.itPlatformOptions != undefined) {
      this.itPlatformOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.itPlatform = response;
        }
      });
    }
  }

  itRelevantValueChanged(e) {
    if (this.itRelevantOptions != undefined) {
      this.itRelevantOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.itRelevant = response;
        }
      });
    }
  }

  strategicContributionValueChanged(e) {
    if (this.strategyContributionOptions != undefined) {
      this.strategyContributionOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.strategicContribution = response;
        }
      });
    }
  }

  feasibilityValueChanged(e) {
    if (this.feasibilityOptions != undefined) {
      this.feasibilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.feasibility = response;
        }
      });
    }
  }

  profitabilityValueChanged(e) {
    if (this.profitabilityOptions != undefined) {
      this.profitabilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.profitability = response;
        }
      });
    }
  }

  projectMethodologyValueChanged(e) {
    if (this.projectMethodologyOptions != undefined) {
      this.projectMethodologyOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.projectMethodology = response;
        }
      });
    }
  }

  projectPhaseValueChanged(e) {
    if (this.projectPhaseOptions != undefined) {
      this.projectPhaseOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.projectObj.projectPhase = response;
        }
      });
    }
  }

  createReportDisableChecker() {
    let flag = false;
    if (this.projectObj.hasDraftReport == true) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.projectObj.status == 'Closed') {
      flag = true;
    }

    return flag;
  }

  projectManagerOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).pipe(retry(2)).subscribe(response => {
      this.usersList = response;
    });
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).pipe(retry(2)).subscribe((response: any) => {
      this.usersList = response;

      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.projectObj.projectSponsor = response;
            } else {
              this.projectObj.projectSponsor = { id: '' };
            }
          });
        }
      } else {
        this.projectObj.projectSponsor = { id: '' };
      }
    });
  }

  syncTreeViewSelection(e, type) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (type == 'itPlatform') {
      if (component) {
        component.unselectAll();
      }

      if (this.projectObj.itPlatform) {
        this.projectObj.itPlatform.forEach((function (value) {
          component.selectItem(value);
        }).bind(this));
      }
    } else if (type == 'technology') {
      if (component) {
        component.unselectAll();
      }

      if (this.technology) {
        this.technology.forEach((function (value) {
          component.selectItem(value);
        }).bind(this));
      }
    }
  }

  getSelectedItemsKeys(items) {
    let result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e, type) {
    const nodes = e.component.getNodes();

    if (type == 'itPlatform') {
      let selectedItems = nodes.filter(val => val.selected == true);
      if (selectedItems.length > 3) {
        this.showNotificationBox('Warning', 'It Platform can select maximum no of 3.', 'warning');
      } else {
        this.projectObj.itPlatform = this.getSelectedItemsKeys(nodes);
      }
    } else if (type == 'technology') {
      this.technology = this.getSelectedItemsKeys(nodes);
    }
  }

  onEditorPreparingPSP(e) {
    if (e.parentType == "dataRow" && e.dataField == "psp") {
      e.editorOptions.maxLength = 22;
      e.editorOptions.placeholder = this.localize.transform("PSP");
    }
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 30;
      e.editorOptions.placeholder = this.localize.transform("Description");
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  onKeyNumberField(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }
}
