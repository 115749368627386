import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfClosingReportViewRoutingModule } from './pdf-closing-report-view-routing.module';
import { PdfClosingReportViewComponent } from './pdf-closing-report-view.component';
import { PDFModule } from 'app/pdf/pdf.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    PdfClosingReportViewRoutingModule,
    PDFModule,
    NgxSpinnerModule,
    I18nModule
  ],
  declarations: [PdfClosingReportViewComponent]
})
export class PdfClosingReportViewModule { }
