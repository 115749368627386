import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';
import { DxPivotGridComponent, DxChartComponent } from 'devextreme-angular';
import { locale } from "devextreme/localization";
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';

@Component({
  selector: 'app-sub-portfolio-analytics',
  templateUrl: './sub-portfolio-analytics.component.html',
  styleUrls: ['./sub-portfolio-analytics.component.scss']
})
export class SubPortfolioAnalyticsComponent implements OnInit {
  subportfolioId;
  @ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;
  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;
  pivotGridDataSource: any;
  projectYearlyBudgets: any = [];

  constructor(private apiService: ApiService, private authService: AuthService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private socketService: SocketService, private utilityService: UtilityService, private localize: I18nPipe) {
      this.customizeTooltip = this.customizeTooltip.bind(this);
     }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Analytics'];
    locale("en");

    let sub = this.route.params.subscribe(params => {
      this.subportfolioId = params.subPortfolio;

      this.apiService.get(`/subPortfolio/project/year/budget/${this.subportfolioId}`).subscribe((response: any) => {
        this.projectYearlyBudgets = response;
        this.pivotGridDataSource = {
          fields: [{
            caption: this.localize.transform("Project Name"),
            width: 120,
            dataField: "name",
            area: "row",
            sortBySummaryField: "Total"
          }, {
            caption: this.localize.transform("Status"),
            dataField: "status",
            width: 150,
            area: "filter",
            filterValues: ['Active']
          }, {
            caption: this.localize.transform("Year"),
            dataField: "year",
            // dataType: "date",
            area: "column"
          },
          {
            caption: this.localize.transform("Yearly Budget (CHF)"),
            dataField: "budget",
            dataType: "number",
            summaryType: "sum",
            format: { useGrouping: true, minimumSignificantDigits: 3 },
            area: "data"
          }],
          store: this.projectYearlyBudgets
        }
        this.spinner.hide();
      });
    });
  }

  ngAfterViewInit() {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false
    });
  }

  onCellPreparedPivotGrid(e) {
    if (e.cellElement.innerText == "Grand Total") {
      e.cellElement.innerText = this.localize.transform("Total");
    }
  }

  customizeTooltip(args) {
    return {
      html: args.seriesName + " | Total<div class='currency'>" + args.valueText + "</div>"
    };
  }
}
