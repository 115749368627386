import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'app/services/notifications.service';
declare var $: any;

@Component({
  selector: 'app-closing-report-preview',
  templateUrl: './closing-report-preview.component.html',
  styleUrls: ['./closing-report-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClosingReportPreviewComponent implements OnInit {
  id: any;
  closingReportPreview;
  closingReportObj;
  disableBtn: boolean = true
  showClosingPreview = false;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  commentsArr = [];
  commentMsg = '';
  commentsProjectId;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private authService: AuthService, private modalService: BsModalService,
    private spinner: NgxSpinnerService, private notificationService: NotificationsService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.openClosingReportPreview();
    });
  }

  openClosingReportPreview() {
    this.showClosingPreview = false;
    this.apiService.get('/outlineApproval/' + this.id).subscribe((response: any) => {
      if (response.closingReport.project.projectReport == undefined) {
        this.apiService.get('/projects/' + response.closingReport.project).subscribe(projectResponse => {
          response.closingReport.project = projectResponse;
          this.apiService.get('/reports/' + response.closingReport.project.projectReport.id).subscribe(reportResponse => {
            response.closingReport.project.projectReport = reportResponse;
            this.closingReportObj = response;
            this.showComments();
            this.closingReportPreview = response.closingReport;

            setTimeout(() => {
              this.showClosingPreview = true;
            }, 100);

            setTimeout(() => {

              document.getElementById("download").style.display = "none";

              if (this.closingReportObj.isFreezed == true) {
                this.disableBtn = true
              }
              else {
                this.disableBtn = false;
              }
            }, 100);

            this.spinner.hide();

            this.modalRef = this.modalService.show(this.lgClosingModal, this.config);
            this.modalService.onHidden.subscribe((reason: string) => {
              this.showClosingPreview = false;
            });
          });
        });
      }
      else {
        this.closingReportObj = response
        this.showComments();
        this.closingReportPreview = response.closingReport;

        setTimeout(() => {
          this.showClosingPreview = true;
        }, 100);

        setTimeout(() => {
          if (this.closingReportObj.isFreezed == true) {
            this.disableBtn = true
          }
          else {
            this.disableBtn = false;
          }
        }, 100);

        this.spinner.hide();

        this.modalRef = this.modalService.show(this.lgClosingModal, this.config);
        this.modalService.onHidden.subscribe((reason: string) => {
          this.showClosingPreview = false;
        });
      }
    });
  }


  approve(): void {
    this.spinner.show();
    this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
      status: 'Approved',
      isFreezed: true,
      // overallStatus: "Approved"
    }).subscribe((approvedProj: any) => {
      this.closingReportObj.status = approvedProj.status;
      this.notificationService.createNotification(this.closingReportObj.project.id, 'Closing Report', 'approved', this.closingReportObj.assignedTo.id);
      if (approvedProj.sentTo == "PMO") {
        this.apiService.get('/closingReport/' + approvedProj.closingReport.id).subscribe((crResponse: any) => {
          this.apiService.post('/outlineApproval', {
            closingReport: approvedProj.closingReport,
            status: "Open",
            projectStatus: 'Submitted',
            overallStatus: "Submitted",
            assignedTo: crResponse.projectSponsor.id,
            project: approvedProj.project.id,
            docType: 'Closing Report',
            sentTo: "Sponsor",
            isFreezed: false,
            uid: approvedProj.uid,
            version: approvedProj.closingReport.version,
            isPMOApprovedClosingReport: true,
            pmoApprovedClosingReportDate: new Date().toISOString()
          }).subscribe((newApproval: any) => {
            this.apiService.put('/projects/' + this.closingReportObj.project.id, {
              workflowStatus: 'Closing Report has been sent to Project Sponsor for approval.',
              pmoApprovedClosingReportDate: new Date().toISOString(),
              isPMOApprovedClosingReport: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              let projectResponse = Object.assign({}, response);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedclosingReport = approvedProj.closingReport;
              approvedclosingReport.project = projectResponse;
              approvedclosingReport.project.isPMOApprovedClosingReport = true;
              approvedclosingReport.status = 'Submitted';

              let projectObj = approvedProj.project;
              projectObj = projectResponse;
              projectObj.isPMOApprovedClosingReport = true;
              projectObj.status = 'Submitted';

              this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
                project: projectObj,
                closingReport: { closingReport: approvedclosingReport },
                isPMOApprovedClosingReport: true,
                projectStatus: 'Submitted',
                overallStatus: "Submitted",
                pmoApprovedClosingReportDate: new Date().toISOString(),
              }).subscribe((resp: any) => {
                this.disableBtn = true;
                this.notification('Success', "Closing Report has been sent to Project Sponsor for approval.");
              this.spinner.hide();
                this.apiService.post('/sendEmail', {
                  email: newApproval.closingReport.projectSponsor.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${newApproval.id}" target="_blank">${'Abschlussbericht / Closing Report : ' + response.projectName}</a>`
                }).subscribe(msg => { });
              });
            });
          });
        });
      } else if (approvedProj.sentTo == "Sponsor") {
        this.apiService.get('/closingReport/' + approvedProj.closingReport.id).subscribe((crResponse: any) => {
          this.apiService.post('/outlineApproval', {
            closingReport: approvedProj.closingReport,
            status: "Open",
            projectStatus: 'Submitted',
            overallStatus: "Submitted",
            assignedTo: crResponse.projectFico.id,
            project: approvedProj.project.id,
            docType: 'Closing Report',
            sentTo: "FICO",
            isFreezed: false,
            uid: approvedProj.uid,
            version: approvedProj.closingReport.version,
            isPMOApprovedClosingReport: true,
            isSponsorApprovedClosingReport: true,
            pmoApprovedClosingReportDate: this.closingReportObj.pmoApprovedClosingReportDate,
            sponsorApprovedClosingReportDate: new Date().toISOString(),
          }).subscribe((newApproval: any) => {
            this.apiService.put('/projects/' + this.closingReportObj.project.id, {
              workflowStatus: 'Closing Report has been sent to Financial Manager for approval.',
              sponsorApprovedClosingReportDate: new Date().toISOString(),
              isSponsorApprovedClosingReport: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              let projectResponse = Object.assign({}, response);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedclosingReport = approvedProj.closingReport;
              approvedclosingReport.project = projectResponse;
              approvedclosingReport.project.isSponsorApprovedClosingReport = true;
              approvedclosingReport.status = 'Submitted';
              this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
                closingReport: approvedclosingReport,
                isPMOApprovedClosingReport: true,
                isSponsorApprovedClosingReport: true,
                projectStatus: 'Submitted',
                overallStatus: "Submitted",
                sponsorApprovedClosingReportDate: new Date().toISOString()
              }).subscribe((resp: any) => {
                this.apiService.post('/updatepreviousapproval/', {
                  query: {
                    uid: resp.uid,
                    docType: 'Closing Report',
                    version: resp.version
                  },
                  projectItem: { 
                    closingReport: approvedclosingReport, 
                    isSponsorApprovedClosingReport: true,
                    projectStatus: 'Submitted',
                    sponsorApprovedClosingReportDate: new Date().toISOString(),
                    overallStatus: "Submitted" 
                  }
                }).subscribe((resp: any) => {
                  this.disableBtn = true;
                  this.notification('Success', "Closing Report has been sent to Financial Manager for approval.");
              this.spinner.hide();
                  this.apiService.post('/sendEmail', {
                    email: newApproval.closingReport.projectFico.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${newApproval.id}" target="_blank">${'Abschlussbericht / Closing Report : ' + response.projectName}</a>`
                  }).subscribe(msg => { });
                });
              });
            });
          });
        });
      } else if (approvedProj.sentTo == "FICO") {
        this.apiService.put('/projects/' + this.closingReportObj.project.id, {
          status: 'Approved',
          workflowStatus: 'Closing Report has been approved.',
          closingReportApproved: false,
          isClosed: true,
          ficoApprovedClosingReportDate: new Date().toISOString(),
          isFicoApprovedClosingReport: true
        }).subscribe((project: any) => {
          let projectResponse = Object.assign({}, project);
          delete (projectResponse.projectOutline);
          delete (projectResponse.projectOrder);
          delete (projectResponse.history);
          delete (projectResponse.changeRequests);
          delete (projectResponse.closingReport);
          delete (projectResponse.approvals);
          delete (projectResponse.user);
          delete (projectResponse.subPortfolio);
          let approvedclosingReport = approvedProj.closingReport;
          approvedclosingReport.project = projectResponse;
          this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
            closingReport: approvedclosingReport,
            isPMOApprovedClosingReport: true,
            isSponsorApprovedClosingReport: true,
            isFicoApprovedClosingReport: true,
            projectStatus: 'Approved',
            overallStatus: "Approved",
            ficoApprovedClosingReportDate: new Date().toISOString(),
          }).subscribe((resp: any) => {
            this.apiService.post('/updatepreviousapproval/', {
              query: {
                uid: resp.uid,
                docType: 'Closing Report',
                version: resp.version
              },
              projectItem: { 
                closingReport: approvedclosingReport, 
                isFicoApprovedClosingReport: true,
                projectStatus: 'Approved',
                ficoApprovedClosingReportDate: new Date().toISOString(),
                overallStatus: "Approved" 
              }
            }).subscribe((resp: any) => {
              this.apiService.put('/closingReport/' + this.closingReportObj.closingReport.id, {
                isApproved: true,
                // isSubmitted: true,
                status: 'Approved',
              }).subscribe((crResponse: any) => {
                this.disableBtn = true;
                this.closingReportOverwrite(approvedProj, project);
                this.notification('Success', "Closing Report has been approved.");
            this.spinner.hide();
                this.apiService.post('/sendEmail', {
                  email: crResponse.projectFico.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Approval Confirmation')[0].text + `<br>` +
                    'Abschlussbericht / Closing Report : ' + project.projectName + '<br><br><br>'
                }).subscribe(msg => { });
              });
            });
          });
        });
      }
    });
  }

  onHold(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.closingReportObj.project.id, {
      status: 'On Hold',
      workflowStatus: 'Closing Report has been put on hold.',
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
        status: 'On Hold',
        overallStatus: "On Hold"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedclosingReport = appRes.closingReport;
        approvedclosingReport.project = projectResponse;
        approvedclosingReport.status = 'On Hold';
        this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
          closingReport: approvedclosingReport,
          status: 'On Hold',
          projectStatus: 'On Hold',
          overallStatus: "On Hold"
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Closing Report',
              version: resp.version
            },
            projectItem: { closingReport: approvedclosingReport, projectStatus: 'On Hold', overallStatus: "On Hold" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.closingReportObj.project.id, 'Closing Report', 'held', this.closingReportObj.assignedTo.id);
            this.closingReportObj.status = appRes.status;
            this.apiService.put('/closingReport/' + this.closingReportObj.closingReport.id, {
              status: 'On Hold'
            }).subscribe((crResponse: any) => {
              this.apiService.post('/sendEmail', {
                email: appRes.closingReport.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report On Hold')[0].text + `<br>` +
                  'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
              }).subscribe(msg => { });
              this.notification('Success', "Closing Report has been put on hold.");
	      this.spinner.hide();
            });
          });
        });
      });
    });
  }

  returnToSender(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.closingReportObj.project.id, {
      status: 'Returned',
      changeRequestMade: false,
      workflowStatus: 'Closing Report has been returned back from approver.',
      closingReportSubmitted: true,
      // isPMOApprovedClosingReport: false,
      // isSponsorApprovedClosingReport: false,
      closingReportReturned: true,
      closingReportReturnDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
        status: 'Returned',
        projectStatus: 'Returned',
        isFreezed: true,
        closingReportReturnDate: new Date().toISOString(),
        overallStatus: "Returned"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedclosingReport = appRes.closingReport;
        approvedclosingReport.project.status = 'Returned';
        approvedclosingReport.project.closingReportReturnDate = new Date().toISOString();
        approvedclosingReport.project.workflowStatus = 'Closing Report has been returned back from approver.'
        this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
          closingReport: approvedclosingReport
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Closing Report',
              version: resp.version
            },
            projectItem: { closingReport: approvedclosingReport, projectStatus: 'Returned', closingReportReturnDate: new Date().toISOString(), overallStatus: "Returned" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.closingReportObj.project.id, 'Closing Report', 'returned', this.closingReportObj.assignedTo.id);
            this.closingReportObj.status = appRes.status;
            this.apiService.put('/closingReport/' + this.closingReportObj.closingReport.id, {
              isApproved: false,
              isSubmitted: false,
              status: 'Returned',
            }).subscribe(crResponse => {
              this.disableBtn = true;
              this.apiService.post('/sendEmail', {
                email: appRes.closingReport.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Returned')[0].text + `<br>` +
                  'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
              }).subscribe(msg => { });
              this.notification('Success', "Closing Report has been returned to sender.");
	      this.spinner.hide();
            });
          });
        });
      });
    });
  }

  Reject(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.closingReportObj.project.id, {
      status: 'Rejected',
      workflowStatus: 'Closing Report has been rejected.',
      changeRequestMade: false,
      closingReportRejectDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
        status: 'Rejected',
        projectStatus: 'Rejected',
        isFreezed: true,
        closingReportRejectDate: new Date().toISOString(),
        overallStatus: "Rejected"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedclosingReport = appRes.closingReport;
        approvedclosingReport.project = projectResponse;
        approvedclosingReport.status = 'Rejected'
        this.apiService.put('/outlineApproval/' + this.closingReportObj.id, {
          closingReport: approvedclosingReport
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Closing Report',
              version: resp.version
            },
            projectItem: { closingReport: approvedclosingReport, projectStatus: 'Rejected', closingReportRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.closingReportObj.project.id, 'Closing Report', 'rejected', this.closingReportObj.assignedTo.id);
            this.apiService.put('/closingReport/' + this.closingReportObj.closingReport.id, {
              isApproved: false,
              isSubmitted: true,
              status: 'Rejected',
            }).subscribe(crResponse => {
              this.disableBtn = true;
              this.apiService.post('/sendEmail', {
                email: appRes.closingReport.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Rejected')[0].text + `<br>` +
                  'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
              }).subscribe(msg => { });
              this.notification('Success', "Closing Report has been rejected.");
	      this.spinner.hide();
            });
          });
        });
      });
    });
  }

  closingReportOverwrite(approvedProj, project) {
    let obj: any = {
      lessonsLearned: project.closingReport.lessonsLearned,
      status: 'Closed',
    };

    let projectPhaseOptions = this.utilityService.projectPhaseOptions;
    let projectPhase;
    
    if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
      projectPhase = projectPhaseOptions.values.find(val => val.name == 'Closing').id;
    }
    obj.projectPhase = projectPhase;

    this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
      this.apiService.post('/history', {
        date: Date.now(),
        project: project.id,
        document: 'Closing Report',
        status: 'Approved'
      }).subscribe(historyResponse => {
        this.notification('Success', "Closing Report has been approved.");
        this.apiService.post('/sendEmail', {
          email: approvedProj.closingReport.projectManager.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + `<br>` +
            'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
        }).subscribe(msg => { });
      });
    });
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }

  showComments(): void {
    this.commentsArr = [];
    this.commentsArr = this.closingReportObj.project.comments;
    this.commentsProjectId = this.closingReportObj.project.id;
  }

  saveComments(e) {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';
      setTimeout(() => {
        this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      }, 100);

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe(response => {
        // this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      });
    }
  }

  scrollToBottom(e) {
    setTimeout(() => {
      e.component.scrollBy($('.dx-scrollview-content').height());
    }, 100);
  }
}
