import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout.component';
import { RolesGuard } from 'app/gaurds/roles.guard';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'news',
    pathMatch: 'full'
  },
  {
    path: 'news',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'viewer', 'guest', 'subPortfolioManager']
    },
    loadChildren: () => import('../../news/news.module').then(mod => mod.NewsModule)
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'viewer', 'subPortfolioManager']
    },
    loadChildren: () => import('../../dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'settings',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['admin', 'pmo']
    },
    loadChildren: () => import('../../settings/settings.module').then(mod => mod.SettingsModule)
  },
  {
    path: 'program',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    loadChildren: () => import('../../programs/programs.module').then(mod => mod.ProgramsModule)
  },
  {
    path: 'subportfolios',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    loadChildren: () => import('../../subportfolios/subportfolios.module').then(mod => mod.SubportfoliosModule)
  },
  {
    path: 'projects',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    loadChildren: () => import('../../projects/projects.module').then(mod => mod.ProjectsModule)
  },
  {
    path: 'smallOrder',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    loadChildren: () => import('../../small-orders/small-orders.module').then(mod => mod.SmallOrdersModule)
  },
  {
    path: 'approvals',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    loadChildren: () => import('../../approvals/approvals.module').then(mod => mod.ApprovalsModule)
  },
  {
    path: 'notifications',
    component: AdminLayoutComponent,
    canActivate: [RolesGuard],
    data: {
      allowedRoles: ['pmo', 'admin']
    },
    loadChildren: () => import('../../notifications/notifications.module').then(mod => mod.NotificationsModule),
  },
  {
    path: 'outlinePreview/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../pdfPreview/outline-preview/outline-preview.module').then(mod => mod.OutlinePreviewModule)
  },
  {
    path: 'orderPreview/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../pdfPreview/order-preview/order-preview.module').then(mod => mod.OrderPreviewModule)
  },
  {
    path: 'changeRequestPreview/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../pdfPreview/change-request-preview/change-request-preview.module').then(mod => mod.ChangeRequestPreviewModule)
  },
  {
    path: 'closingReportPreview/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../pdfPreview/closing-report-preview/closing-report-preview.module').then(mod => mod.ClosingReportPreviewModule)
  },
  {
    path: 'view/outline/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-outline-view/pdf-outline-view.module').then(mod => mod.PdfOutlineViewModule)
  },
  {
    path: 'view/smallorder/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-small-order-view/pdf-small-order-view.module').then(mod => mod.PdfSmallOrderViewModule)
  },
  {
    path: 'view/order/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-order-view/pdf-order-view.module').then(mod => mod.PdfOrderViewModule)
  },
  {
    path: 'view/changeRequest/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-change-request-view/pdf-change-request-view.module').then(mod => mod.PdfChangeRequestViewModule)
  },
  {
    path: 'view/closingReport/:id',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-closing-report-view/pdf-closing-report-view.module').then(mod => mod.PdfClosingReportViewModule)
  },
  {
    path: 'view/project/statusReport/:prev/:new',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-project-status-report-view/pdf-project-status-report-view.module').then(mod => mod.PdfProjectStatusReportViewModule)
  },
  {
    path: 'view/program/statusReport/:prev/:new',
    component: AdminLayoutComponent,
    loadChildren: () => import('../../view/pdf-program-status-report-view/pdf-program-status-report-view.module').then(mod => mod.PdfProgramStatusReportViewModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminLayoutRoutingModule { }