import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'app/services/notifications.service';
declare var $: any;

@Component({
  selector: 'app-outline-preview',
  templateUrl: './outline-preview.component.html',
  styleUrls: ['./outline-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutlinePreviewComponent implements OnInit {
  id: any;
  outlinePreview;
  outlineObj;
  disableBtn: boolean = true;
  public showPreview = false;
  @ViewChild('popUp', { static: false }) public lgModal;
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };
  public modalRef: BsModalRef;
  commentsArr = [];
  commentMsg = '';
  commentsProjectId;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private authService: AuthService, private modalService: BsModalService,
    private spinner: NgxSpinnerService, private notificationService: NotificationsService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.openOutlinePreview();
    });
  }

  openOutlinePreview() {
    this.showPreview = false;
    this.apiService.get('/outlineApproval/' + this.id).subscribe((response: any) => {
      this.outlineObj = response;

      this.showComments();
      response.projectOutline = this.utilityService.setOutlinePdfValues(response.projectOutline);
      this.outlinePreview = response.projectOutline;

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      setTimeout(() => {
        document.getElementById("download").style.display = "none";
      }, 100);

      if (this.outlineObj.isFreezed == true) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }

      this.spinner.hide();

      this.modalRef = this.modalService.show(this.lgModal, this.config);
      this.modalService.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  approve(): void {
    this.spinner.show();
    this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
      status: 'Approved',
      isFreezed: true,
      // overallStatus: "Approved"
    }).subscribe((approvedProj: any) => {
      this.outlineObj.status = approvedProj.status;
      this.outlineObj = approvedProj;
      this.notificationService.createNotification(this.outlineObj.project.id, 'Project Outline', 'approved', this.outlineObj.assignedTo.id);
      if (approvedProj.sentTo == "PMO") {
        this.apiService.post('/outlineApproval', {
          projectOutline: approvedProj.projectOutline,
          status: "Open",
          overallStatus: "Submitted",
          projectStatus: 'Submitted',
          assignedTo: approvedProj.projectOutline.projectSponsor.id,
          project: approvedProj.project.id,
          docType: 'Outline',
          sentTo: "Sponsor",
          isFreezed: false,
          uid: approvedProj.uid,
          version: approvedProj.projectOutline.version,
          isPMOApprovedOutline: true,
          pmoApprovedOutlineDate: new Date().toISOString(),
        }).subscribe((newApproval: any) => {
          this.apiService.put('/projects/' + this.outlineObj.project.id, {
            workflowStatus: 'Outline has been sent to Project Sponsor for approval.',
            pmoApprovedOutlineDate: new Date().toISOString(),
            isPMOApprovedOutline: true,
            status: 'Submitted'
          }).subscribe((response: any) => {
            let projectResp = Object.assign({}, response);
            delete (projectResp.projectOutline);
            delete (projectResp.projectOrder);
            delete (projectResp.history);
            delete (projectResp.changeRequests);
            delete (projectResp.closingReport);
            delete (projectResp.approvals);
            delete (projectResp.user);
            delete (projectResp.subPortfolio);
            let approvedOutline = approvedProj.projectOutline;
            approvedOutline.projectId = projectResp;
            approvedOutline.projectId.isPMOApprovedOutline = true;
            approvedOutline.status = 'Submitted';
            this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
              projectOutline: approvedOutline,
              isPMOApprovedOutline: true,
              projectStatus: 'Submitted',
              overallStatus: "Submitted",
              pmoApprovedOutlineDate: new Date().toISOString(),
            }).subscribe((resp: any) => {
              this.disableBtn = true;
              this.notification('Success', "Outline has been sent to Project Sponsor for approval.");
              this.spinner.hide();
              this.apiService.post('/sendEmail', {
                email: newApproval.projectOutline.projectSponsor.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/outlinePreview/${newApproval.id}" target="_blank">${'Projektskizze / Project Outline : ' + response.projectName}</a>`
              }).subscribe(msg => { });
            });
          });
        });
      } else if (approvedProj.sentTo == "Sponsor") {
        this.apiService.put('/projects/' + this.outlineObj.project.id, {
          outlineApproved: true,
          status: 'Approved',
          workflowStatus: 'Outline has been approved.',
          sponsorApprovedDate: new Date().toISOString(),
          isSponsorApproved: true
        }).subscribe((project: any) => {
          let projectResponse = Object.assign({}, project);
          delete (projectResponse.projectOutline);
          delete (projectResponse.projectOrder);
          delete (projectResponse.history);
          delete (projectResponse.changeRequests);
          delete (projectResponse.closingReport);
          delete (projectResponse.approvals);
          delete (projectResponse.user);
          delete (projectResponse.subPortfolio);
          let approvedOutineNew = approvedProj.projectOutline;
          approvedOutineNew.projectId = projectResponse;
          approvedOutineNew.status = 'Approved'
          this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
            projectOutline: approvedOutineNew,
            overallStatus: "Approved",
            isPMOApprovedOutline: true,
            isSponsorApproved: true,
            sponsorApprovedDate: new Date().toISOString(),
            projectStatus: 'Submitted',
          }).subscribe((resp: any) => {
            this.apiService.post('/updatepreviousapproval/', {
              query: {
                uid: resp.uid,
                docType: 'Outline',
                sentTo: 'PMO',
                version: resp.version
              },
              projectItem: {
                projectOutline: approvedOutineNew,
                isSponsorApproved: true,
                projectStatus: 'Approved',
                sponsorApprovedDate: new Date().toISOString(),
                overallStatus: "Approved"
              }
            }).subscribe((resp: any) => {

              let budget = [
                {
                  costType: "External Costs",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 0,
                  group: "CAPEX",
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 1,
                  group: "CAPEX",
                }, {
                  costType: "External Costs",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 2,
                  group: "OPEX"
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 3,
                  group: "OPEX"
                }, {
                  costType: "Revenues",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 4,
                  group: "Sonstiges",
                }, {
                  costType: "Reserves",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  group: "Sonstiges",
                  id: 5,
                }, {
                  costType: "Total",
                  budget: '',
                  thereofIT: '',
                  currentBudget: "",
                  yearlyForecast: '',
                  thereofITForecast: '',
                  remainingProjectBudget: "",
                  id: 6,
                  group: "Sonstiges",
                },
              ];

              this.apiService.post('/projectBudgetCost/createForSubportfolio', {
                subPortfolio: project.subPortfolio.id,
                project: project.id,
                budget: budget
              }).subscribe((projectBudgetCostResponce: any) => {

                let startDate = project.projectOutline[0].startOfProjectOrder;
                let endDate = project.projectOutline[0].initiationApprovalDate;
                project.projectOutline[0].plannedEndDate = project.projectOutline[0].initiationApprovalDate;

                let costTypeTable = [
                  {
                    costTypes: "External Costs",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 0,
                    group: 'CAPEX',
                  },
                  {
                    costTypes: "Internal Costs",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 1,
                    group: 'CAPEX',
                  },
                  {
                    costTypes: "External Costs",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 2,
                    group: 'OPEX',
                  },
                  {
                    costTypes: "Internal Costs",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 3,
                    group: 'OPEX',
                  },
                  {
                    costTypes: "Revenues",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 4,
                    group: 'Sonstiges',
                  },
                  {
                    costTypes: "Reserves",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 5,
                    group: 'Sonstiges',
                  },
                  {
                    costTypes: "Total",
                    actualCost: '',
                    forecast: '',
                    currentBudget: "",
                    originalBudget: '',
                    id: 6,
                    group: 'Sonstiges',
                  },];

                project.projectOutline[0].fundsApprovedForInitiationTable.forEach((value, index) => {
                  costTypeTable[index].forecast = value.budget;
                  costTypeTable[index].currentBudget = value.budget;
                  costTypeTable[index].originalBudget = value.budget;
                });

                //delete (project.projectOutline[0].estimatedProjectTable);
                let estimatedProjectCostTableOutline = project.projectOutline[0].estimatedProjectTable;
                let projectBudgetCurrent = project.projectOutline[0].fundsApprovedForInitiationTable[4].total;
                delete (project.projectOutline[0].fundsApprovedForInitiationTable);

                let risks = [
                  {
                    id: 1,
                    name: '',
                    description: '',
                    probability: '',
                    extentOfLosses: '',
                    riskExposure: '',
                    riskStrategy: '',
                    reputationRisks: '',
                    measures: '',
                    report: true,
                  }, {
                    id: 2,
                    name: '',
                    description: '',
                    probability: '',
                    extentOfLosses: '',
                    riskExposure: '',
                    riskStrategy: '',
                    reputationRisks: '',
                    measures: '',
                    report: true,
                  }, {
                    id: 3,
                    name: '',
                    description: '',
                    probability: '',
                    extentOfLosses: '',
                    riskExposure: '',
                    riskStrategy: '',
                    reputationRisks: '',
                    measures: '',
                    report: true,
                  }, {
                    id: 4,
                    name: '',
                    description: '',
                    probability: '',
                    extentOfLosses: '',
                    riskExposure: '',
                    riskStrategy: '',
                    reputationRisks: '',
                    measures: '',
                    report: true,
                  }, {
                    id: 5,
                    name: '',
                    description: '',
                    probability: '',
                    extentOfLosses: '',
                    riskExposure: '',
                    riskStrategy: '',
                    reputationRisks: '',
                    measures: '',
                    report: true,
                  },];

                let mileStonesValues = [
                  {
                    id: 1,
                    name: '',
                    description: "",
                    dueDate: '',
                    report: true
                  },
                  {
                    id: 2,
                    name: '',
                    description: "",
                    dueDate: '',
                    report: true
                  },
                  {
                    id: 3,
                    name: '',
                    description: "",
                    dueDate: '',
                    report: true
                  },
                  {
                    id: 4,
                    name: '',
                    description: "",
                    dueDate: '',
                    report: true
                  },
                  {
                    id: 5,
                    name: '',
                    description: "",
                    dueDate: '',
                    report: true
                  }
                ];
                let actualCostTable = [{
                  costType: "External Costs",
                  actualCost: '',
                  id: 0,
                  group: "CAPEX",
                  actualBudget: '',
                }, {
                  costType: "Internal Costs",
                  actualCost: '',
                  id: 1,
                  group: "CAPEX",
                  actualBudget: '',
                }, {
                  costType: "External Costs",
                  actualCost: '',
                  id: 2,
                  group: "OPEX",
                  actualBudget: '',
                }, {
                  costType: "Internal Costs",
                  actualCost: '',
                  id: 3,
                  group: "OPEX",
                  actualBudget: '',
                }, {
                  costType: "Revenues",
                  actualCost: '',
                  id: 4,
                  group: "Sonstiges",
                  actualBudget: '',
                }, {
                  costType: "Reserves",
                  actualCost: '',
                  group: "Sonstiges",
                  actualBudget: '',
                  id: 5,
                }, {
                  costType: "Total",
                  actualCost: '',
                  id: 6,
                  group: "Sonstiges",
                  actualBudget: '',
                },];
                let budgetPlanningTable1 = [{
                  costType: "External Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 0,
                  group: "CAPEX",
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 1,
                  group: "CAPEX",
                }, {
                  costType: "External Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 2,
                  group: "OPEX"
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 3,
                  group: "OPEX"
                }, {
                  costType: "Revenues",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 4,
                  group: "Sonstiges",
                }, {
                  costType: "Reserves",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  group: "Sonstiges",
                  id: 5,
                }, {
                  costType: "Total",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 6,
                  group: "Sonstiges",
                },];
                let budgetPlanningTable2 = [{
                  costType: "External Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 0,
                  group: "CAPEX",
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 1,
                  group: "CAPEX",
                }, {
                  costType: "External Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 2,
                  group: "OPEX"
                }, {
                  costType: "Internal Costs",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 3,
                  group: "OPEX"
                }, {
                  costType: "Revenues",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 4,
                  group: "Sonstiges",
                }, {
                  costType: "Reserves",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  group: "Sonstiges",
                  id: 5,
                }, {
                  costType: "Total",
                  budget: '',
                  thereofICT: '',
                  actualCost: "",
                  forecast: "",
                  id: 6,
                  group: "Sonstiges",
                },];

                project.projectOutline[0].risksTable.forEach((element, index) => {
                  risks[index].description = element.description
                });

                delete (project.projectOutline[0].attachment1);
                delete (project.projectOutline[0].attachment2);
                delete (project.projectOutline[0].attachments);
                delete (project.projectOutline[0].isAttachement1Added);
                delete (project.projectOutline[0].isAttachement2Added);
                let outlineStartDate = project.projectOutline[0].startDate;
                let outlineEndDate = project.projectOutline[0].endDate;
                delete (project.projectOutline[0].startDate);
                delete (project.projectOutline[0].endDate);
                delete (project.projectOutline[0].startOfProjectOrder);
                delete (project.projectOutline[0].initiationApprovalDate);

                let projectPhaseOptions = this.utilityService.projectPhaseOptions;
                let projectPhase;
                if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
                  projectPhase = projectPhaseOptions.values.find(val => val.name == 'Initiating').id;
                }

                this.apiService.post('/reports', {
                  uid: project.uid,
                  ...project.projectOutline[0],
                  projectName: project.projectName,
                  user: project.user.id,
                  startDate: startDate,
                  endDate: endDate,
                  outlineStartDate,
                  outlineEndDate,
                  project: this.outlineObj.project.id,
                  currency: 'CHF',
                  status: 'Active',
                  projectPhase: projectPhase ? projectPhase : undefined,
                  program: project.projectOutline[0].program,
                  projectBudgetCurrent: projectBudgetCurrent,
                  costTypeTable: costTypeTable,
                  estimatedProjectCostTableOutline: estimatedProjectCostTableOutline,
                  currentReserveHistory: [],
                  risks: risks,
                  mileStonesValues: mileStonesValues,
                  reportRelevantRisksCount: 0,
                  actualCostTable,
                  budgetPlanningTable1,
                  budgetPlanningTable2,
                }).subscribe((report: any) => {
                  this.apiService.put('/projects/' + this.outlineObj.project.id, {
                    projectReport: report.id
                  }).subscribe((response: any) => {
                    let status = '';
                    if (project.user.role != 'admin' || project.user.role != 'pmo') {
                      status = 'pm';
                    } else {
                      status = project.user.role;
                    }

                    this.apiService.put('/user/' + project.user.id, {
                      status: status
                    }).subscribe((userResponse: any) => {
                      this.apiService.post('/history', {
                        date: Date.now(),
                        project: project.id,
                        document: 'Project Outline',
                        status: 'Approved'
                      }).subscribe(historyResponse => {
                        this.apiService.post('/team', {
                          users: [report.projectSponsor.id],
                          project: project.id
                        }).subscribe((responseTeam: any) => {
                          let teamId = responseTeam.id;
                          this.apiService.post('/rights', {
                            user: report.projectSponsor.id,
                            project: project.id,
                            isView: true,
                            isEdit: true
                          }).subscribe((responseRights: any) => {
                            this.disableBtn = true;
                            this.notification('Success', "Outline has been approved.");
                            this.apiService.put('/projectOutline/' + project.projectOutline[0].id, {
                              status: 'Approved'
                            }).subscribe(outlineRes => {
                              this.spinner.hide();
                              this.apiService.post('/sendEmail', {
                                email: userResponse.email,
                                subject: "oneView: Projekt Genehmigungsworkflow",
                                message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Approval Confirmation')[0].text + `<br>` +
                                  'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
                              }).subscribe(msg => { });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      }
    });
  }

  onHold(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.outlineObj.project.id, {
      status: 'On Hold',
      workflowStatus: 'Outline has been put on hold.'
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
        status: 'On Hold',
        projectStatus: 'On Hold',
        isFreezed: false,
        overallStatus: "On Hold"
      }).subscribe((appRes: any) => {
        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedOutineNew = appRes.projectOutline;
        approvedOutineNew.projectId = projectResponse;
        approvedOutineNew.status = 'On Hold';
        this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
          projectOutline: approvedOutineNew
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Outline',
              version: resp.version
            },
            projectItem: { projectOutline: approvedOutineNew, projectStatus: 'On Hold', overallStatus: "On Hold" }
          }).subscribe((resp: any) => { });

          this.notificationService.createNotification(this.outlineObj.project.id, 'Project Outline', 'held', this.outlineObj.assignedTo.id);
          this.outlineObj.status = appRes.status;
          this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
            status: 'On Hold'
          }).subscribe(outlineRes => {
            this.apiService.post('/sendEmail', {
              email: appRes.projectOutline.projectManager.email,
              subject: "oneView: Projekt Genehmigungsworkflow",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline On Hold')[0].text + `<br>` +
                'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
            }).subscribe(msg => { });
            this.notification('Success', "Outline has been put on hold.");
            this.spinner.hide();
          });
        });
      });
    });
  }

  returntoSender(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.outlineObj.project.id, {
      status: 'Returned',
      isFreezed: false,
      outlineReturned: true,
      outlineSubmitted: true,
      // isPMOApprovedOutline: false,
      workflowStatus: 'Outline has been returned from approver.',
      outlineReturnDate: new Date().toISOString(),
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
        status: 'Returned',
        isFreezed: true,
        outlineReturned: true,
        projectStatus: 'Returned',
        overallStatus: "Returned",
        outlineReturnDate: new Date().toISOString()
      }).subscribe((appRes: any) => {
        let approvedOutineNew = appRes.projectOutline;
        approvedOutineNew.projectId.status = 'Returned';
        approvedOutineNew.status = 'Returned';
        approvedOutineNew.projectId.workflowStatus = 'Outline has been returned from approver.'
        this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
          projectOutline: approvedOutineNew
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Outline',
              version: resp.version
            },
            projectItem: { projectOutline: approvedOutineNew, projectStatus: 'Returned', outlineReturnDate: new Date().toISOString(), overallStatus: "Returned" }
          }).subscribe((resp: any) => { });

          this.notificationService.createNotification(this.outlineObj.project.id, 'Project Outline', 'returned', this.outlineObj.assignedTo.id);
          this.outlineObj.status = appRes.status;
          this.disableBtn = true;

          this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
            status: 'Returned'
          }).subscribe(outlineRes => {
            this.apiService.post('/sendEmail', {
              email: appRes.projectOutline.projectManager.email,
              subject: "oneView: Projekt Genehmigungsworkflow",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Returned')[0].text + `<br>` +
                'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
            }).subscribe(msg => { });
            this.notification('Success', "Outline has been returned to sender.");
            this.spinner.hide();
          });
        });
      });
    });
  }

  reject(): void {
    this.spinner.show();
    this.apiService.put('/projects/' + this.outlineObj.project.id, {
      status: 'Rejected',
      isFreezed: true,
      //outlineSubmitted: false,
      workflowStatus: 'Outline has been Rejected.',
      outlineRejectDate: new Date().toISOString()
    }).subscribe((response: any) => {
      this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
        status: 'Rejected',
        projectStatus: 'Rejected',
        isFreezed: true,
        outlineRejectDate: new Date().toISOString(),
        overallStatus: "Rejected"
      }).subscribe((appRes: any) => {

        let projectResponse = Object.assign({}, response);
        delete (projectResponse.projectOutline);
        delete (projectResponse.projectOrder);
        delete (projectResponse.history);
        delete (projectResponse.changeRequests);
        delete (projectResponse.closingReport);
        delete (projectResponse.approvals);
        delete (projectResponse.user);
        delete (projectResponse.subPortfolio);
        let approvedOutineNew = appRes.projectOutline;
        approvedOutineNew.projectId = projectResponse;

        this.apiService.put('/outlineApproval/' + this.outlineObj.id, {
          projectOutline: approvedOutineNew
        }).subscribe((resp: any) => {
          this.apiService.post('/updatepreviousapproval/', {
            query: {
              uid: resp.uid,
              docType: 'Outline',
              version: resp.version
            },
            projectItem: { projectOutline: approvedOutineNew, projectStatus: 'Rejected', outlineRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
          }).subscribe((resp: any) => {
            this.notificationService.createNotification(this.outlineObj.project.id, 'Project Outline', 'rejected', this.outlineObj.assignedTo.id);
            this.outlineObj.status = appRes.status;
            this.disableBtn = true;

            this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
              status: 'Rejected'
            }).subscribe(outlineRes => {
              this.apiService.post('/sendEmail', {
                email: appRes.projectOutline.projectManager.email,
                subject: "oneView: Projekt Genehmigungsworkflow",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Rejected')[0].text + `<br>` +
                  'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
              }).subscribe(msg => { });
              this.notification('Success', "Outline has been Rejected.");
              this.spinner.hide();
            });
          });
        });
      });
    });
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }

  showComments(): void {
    this.commentsArr = [];
    this.commentsArr = this.outlineObj.project.comments;
    this.commentsProjectId = this.outlineObj.project.id;
  }

  saveComments(e) {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';

      setTimeout(() => {
        this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      }, 100);

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe(response => {
        // this.scrollView.instance.scrollBy($('.dx-scrollview-content').height());
      });
    }
  }

  scrollToBottom(e) {
    setTimeout(() => {
      e.component.scrollBy($('.dx-scrollview-content').height());
    }, 100);
  }
}
