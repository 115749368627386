import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    NewsRoutingModule,
    DxDataGridModule,
    I18nModule,
    NgxSpinnerModule
  ],
  declarations: [NewsComponent]
})
export class NewsModule { }
