import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { UtilityService } from 'app/services/utility.service';
import { BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-project-assignment',
  templateUrl: './project-assignment.component.html',
  styleUrls: ['./project-assignment.component.scss']
})
export class ProjectAssignmentComponent implements OnInit, OnDestroy {
  modalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  reportsList: any = [];
  reportsCount: number = 0;
  reportsPageIndex = 2;
  reportObj;
  portfolioList = [];
  subPortfolioList = [];
  itPlatformOptions = [];
  technologyOptions = [];
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  reportsSearch: string = ''

  constructor(private utilityService: UtilityService, private modalService: BsModalService, private apiService: ApiService,
    private localize: I18nPipe, private route: ActivatedRoute,  private spinner: NgxSpinnerService, private socketService: SocketService) {
    this.utilityService.breadcrumb = 'Project Assignment';
    this.utilityService.breadcrumbList = ['Settings', 'View As', 'Project', 'Assignment'];
    globalThis = this;
  }

  ngOnInit() {
    this.spinner.show();
    this.itPlatformOptions = this.utilityService.itPlatformOptions ? this.utilityService.itPlatformOptions.values : [];
    this.technologyOptions = this.utilityService.technologyOptions ? this.utilityService.technologyOptions.values : [];

    this.socketService.emitEvent('allReportsCount', {});

    this.socketService.listen('allReportsCount').subscribe(count => {
      this.reportsCount = count;

      // if (this.reportsCount > 10) {
      //   this.socketService.emitEvent('allReportsIndex', { pageIndex: this.reportsPageIndex, pageSize: 10});
      // }
    });

    this.socketService.listen('allReportsIndex').subscribe((response: any) => {
      this.reportsList = response;
      // this.reportsList = this.reportsList.concat(response);
      this.populateValues();
 
      setTimeout(() => {
        $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
      }, 200);

      // if (this.reportsPageIndex < (this.reportsCount / 10)) {
      //   this.reportsPageIndex++;
      //   this.socketService.emitEvent('allReportsIndex', { pageIndex: this.reportsPageIndex, pageSize: 10});
      // }
    });

    this.socketService.listen('reportsSearchIndex').subscribe(response => {
      this.reportsList = response;
      this.populateValues();

      setTimeout(() => {
        $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
      }, 200);
    });

    this.socketService.listen('reportsSearch').subscribe(reportsResult => {
      this.reportsCount = reportsResult.count;
      this.reportsList = reportsResult.reports;
      this.populateValues();
    });

    this.socketService.listen('activePortfolios').subscribe(response => {
      this.portfolioList = response;
      this.getProjectReports();
    });

    this.socketService.emitEvent('activePortfolios', {});
  }

  getProjectReports() {
    // this.reportsPageIndex = 2;
    this.apiService.get('/reports/index?limit=10').subscribe((response: any) => {
      this.reportsList = response;
      this.populateValues();
      // this.socketService.emitEvent('allReportsCount', {});
      this.spinner.hide();
    });
  }

  populateValues() {
    this.reportsList.forEach((report, idx) => {
      // populate subportfolio
      if(report.subPortfolio && report.subPortfolio.id == undefined) {
        if(report.portfolio && report.portfolio.id) {
          let subPortfolioList = this.portfolioList.find(val => val.id == report.portfolio.id).subPortfolio;
          if(subPortfolioList.length > 0) {
            report.subPortfolio = subPortfolioList.find(val => val.id == report.subPortfolio);
          }
        }
      }

      // populate itPlatfomrs
      if (!report.itPlatforms) {
        if (report.itPlatform && report.itPlatform.length > 0) {
          let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }];

          report.itPlatform.forEach((itPlatform, idx) => {
            let itPlatformObj = this.itPlatformOptions.find(val => val.id == itPlatform);

            if (itPlatformObj) {
              itPlatforms[idx].id = itPlatformObj.id;
              itPlatforms[idx].name = itPlatformObj.name;
            }
          });
          report.itPlatforms = itPlatforms;
        }
      }

      // populate technology
      if (!report.technologys) {
        if (report.technology && report.technology.length > 0) {
          let technologys = [];

          report.technology.forEach((technology, idx) => {
            let technologyObj = this.technologyOptions.find(val => val.id == technology);

            if (technologyObj) {
              if(idx == report.technology.length - 1) {
                technologys.push({id: technologyObj.id, name: technologyObj.name});
              } else {
                technologys.push({id: technologyObj.id, name: technologyObj.name, coma: ','});
              }
            }
          });
          report.technologys = technologys;
        }
      }
    });
  }

  onSearchChange(event) {
    this.socketService.emitEvent('reportsSearch', { search: event.target.value });
  }

  onPageChange(event) {
    if (this.reportsSearch == '') {
      this.socketService.emitEvent('allReportsIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    } else {
      this.socketService.emitEvent('reportsSearchIndex', { search: this.reportsSearch, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }


  openEditDialog(template, obj) {
    this.reportObj = obj;

    if (this.reportObj.portfolio == undefined) {
      this.reportObj.portfolio = { id: '' };
    } else {
      this.subPortfolioList = this.portfolioList.find(val => val.id == this.reportObj.portfolio.id).subPortfolio;
    }

    if (this.reportObj.subPortfolio == undefined || this.reportObj.subPortfolio == null) {
      this.reportObj.subPortfolio = { id: '' };
    } else if (this.reportObj.subPortfolio.id == undefined) {
      if (this.subPortfolioList.length > 0) {
        this.reportObj.subPortfolio = this.subPortfolioList.find(val => val.id == this.reportObj.subPortfolio);
      }
    }

    if (this.reportObj.itPlatform == undefined) {
      this.reportObj.itPlatform = [];
    }

    if (this.reportObj.technology == undefined) {
      this.reportObj.technology = [];
    }

    this.openModal(template);
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(portfolio => {
          if (portfolio.id == e.value) {
            this.reportObj.portfolio = portfolio;
            this.subPortfolioList = portfolio.subPortfolio != undefined ? portfolio.subPortfolio : [];
          }
        });
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.reportObj.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  syncTreeViewSelection(e, type) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (type == 'itPlatform') {
      if (component) {
        component.unselectAll();
      }

      if (this.reportObj.itPlatform) {
        this.reportObj.itPlatform.forEach((function (value) {
          component.selectItem(value);
        }).bind(this));
      }
    } else if (type == 'technology') {
      if (component) {
        component.unselectAll();
      }

      if (this.reportObj.technology) {
        this.reportObj.technology.forEach((function (value) {
          component.selectItem(value);
        }).bind(this));
      }
    }
  }

  getSelectedItemsKeys(items) {
    let result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e, type) {
    const nodes = e.component.getNodes();

    if (type == 'itPlatform') {
      let selectedItems = nodes.filter(val => val.selected == true);
      if (selectedItems.length > 3) {
        this.showNotification('Warning', 'It Platform can select maximum no of 3.', 'warning');
      } else {
        this.reportObj.itPlatform = this.getSelectedItemsKeys(nodes);
      }
    } else if (type == 'technology') {
      this.reportObj.technology = this.getSelectedItemsKeys(nodes);
    }
  }

  editProjectReport() {
    this.spinner.show();
    this.apiService.put('/reports/' + this.reportObj.id, {
      portfolio: this.reportObj.portfolio.id != '' ? this.reportObj.portfolio.id : undefined,
      subPortfolio: this.reportObj.subPortfolio.id != '' ? this.reportObj.subPortfolio : undefined,
      itPlatform: this.reportObj.itPlatform,
      technology: this.reportObj.technology
    }).subscribe((response: any) => {
      // this.getProjectReports();
      let idx  = this.reportsList.findIndex(val => val.id == response.id);
      this.reportsList[idx] = response;
      this.populateValues();
      this.spinner.hide();
      this.closeModel();
      this.showNotification('Success', 'Project detail updated successfully.', 'success');
    });
  }

  showNotification(title, message, type) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      type,
    );
  }

  closeModel(){
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.socketService.removeListener('allReportsCount');
    this.socketService.removeListener('allReportsIndex');
    this.socketService.removeListener('activePortfolios');
    this.socketService.removeListener('reportsSearchIndex');
    this.socketService.removeListener('activePortfolios');
  }
}
