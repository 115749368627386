import { Component, OnInit, ViewChild, ElementRef, OnDestroy, TemplateRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment';
import { UtilityService } from 'app/services/utility.service';
import { SocketService } from 'app/services/socket.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DxTreeViewComponent } from 'devextreme-angular';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
declare var gantt: any;
var globalThis;


@Component({
  selector: 'app-portfolio-gantt-view',
  templateUrl: './portfolio-gantt-view.component.html',
  styleUrls: ['./portfolio-gantt-view.component.scss']
})
export class PortfolioGanttViewComponent implements OnInit, OnDestroy {
  tasks: Task[] = [];
  links = [];
  ganttChart = false;
  portfolioList = [{ id: undefined, name: 'All' }];
  subPortfolioList = [{ id: undefined, name: 'All' }];
  isSubPortfolio: boolean = true;
  reportsList: any = [];
  partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
  isResetPressed: boolean = true;
  reportsCount: number = 0;
  portfoliosCount: number = 0;
  reportsListIndex: number = 1;
  portfolioListIndex: number = 1;
  backupList;
  modalRef: BsModalRef;
  // isClickFilter: boolean = false;
  subPortfolioFilter;
  portfolioFilter;
  programFilter;
  businessUnitFilter;
  businessAreaFilter;
  projectTypeFilter;
  projectCategoryFilter;
  reportingLevelFilter;
  statusFilter;
  projectPhaseFilter;
  projectMethodologyFilter;
  itRelevantFilter;
  itPlatformFilter;
  businessUnitOptions;
  businessAreaOptions;
  projectPhaseOptions;
  projectMethodologyOptions;
  projectTypeOptions;
  reportingLevelOptions;
  statusOptions;
  classificationOptions;
  subProjectsOptions;
  itRelevantOptions;
  itPlatformOptions;
  isFilterApplied: boolean = false;
  portfolioProjectsSearch: any = '';
  backupFiltered;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  isDropDownBoxOpened = false;
  usersList = [];
  projSponsorEmail;
  projectSponsorFilter;
  projectManagerFilter;
  projectNameFilter;
  filtersArray;
  projManagerEmail;
  projectsSearchList = [];
  projectName;
  public selectedFilter: string = 'year';
  userIds = [];
  isSubportfolioFilter: boolean = false;
  treeViewObj;
  projectsCount: any = 0;
  dropdownPageIndex = 1;
  @ViewChild("gantt", { static: true }) ganttContainer: ElementRef;

  constructor(private apiService: ApiService, private localize: I18nPipe, private utilityService: UtilityService, private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService, private modalService: BsModalService) {
    // this.socketService.emitEvent('allReportsCount', {});
    // this.spinner.show();
    globalThis = this;

    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      this.usersList = responseUsers;
      this.setFilterValues(this.filtersArray);
      this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });
    });

    this.socketService.listen('portfolioProjectsFilter').subscribe(projects => {
      gantt.clearAll();
      // this.reportsList = [];
      this.reportsList = this.reportsList.concat(projects);
      this.backupList = JSON.parse(JSON.stringify(this.reportsList));
      this.backupFiltered = JSON.parse(JSON.stringify(this.reportsList));
      this.tasks = [];
      this.spinner.hide();
      this.reset();

      if (this.dropdownPageIndex < (this.projectsCount / 50)) {
        this.dropdownPageIndex++;
        this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: this.filtersArray });
      }
    });

    this.socketService.listen('portfolioProjectsFilterCount').subscribe(count => {
      this.isResetPressed = false;
      //Limiting data to 400 records
      if (count < 400) {
        this.projectsCount = count;
      } else {
        this.projectsCount = 400;
      }

      this.socketService.emitEvent('portfolioProjectsFilter', { pageIndex: this.dropdownPageIndex, pageSize: 50, filtersArray: this.filtersArray });
    });

    this.socketService.listen("getSelectiveProjects").subscribe(response => {
      this.projectsSearchList = response;
      if (this.userIds.length > 0) {
        this.filtersArray = this.filtersArray;
        this.socketService.emitEvent('fetchMultipleUsers', { userIds: this.userIds });
      } else {
        this.setFilterValues(this.filtersArray);
        this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });
      }
    });

    // this.socketService.listen('allReportsCount').subscribe(reportsCount => {
    //   this.reportsCount = reportsCount;
    //   this.socketService.emitEvent('allReportsIndex', { pageIndex: this.reportsListIndex, pageSize: 10 });
    // });

    // this.socketService.listen('allPortfolios').subscribe(data => {
    //   this.portfolioList = this.portfolioList.concat(data);
    //   this.fetchProgram();
    // });

    // this.socketService.listen('allReportsIndex').subscribe(data => {
    //   data.forEach(dataElement => {
    //     this.reportsList.push(dataElement);
    //   });
    //   this.reportsListIndex++;
    //   if (this.reportsListIndex <= Math.ceil(this.reportsCount / 10)) {
    //     this.socketService.emitEvent('allReportsIndex', { pageIndex: this.reportsListIndex, pageSize: 10 })
    //   } else {
    //     this.reset();
    //     this.spinner.hide();
    //   }
    // });

    // this.socketService.listen('allPrograms').subscribe(response => {
    //   this.partOfaProgramOptions = [{ id: '', programName: 'All' }];
    //   this.partOfaProgramOptions = this.partOfaProgramOptions.concat(response);


    // });
    this.utilityService.breadcrumb = 'Gantt View';
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Dashboard', 'Gantt View'];
    this.statusOptions = [{ id: 0, name: 'Active' }, { id: 1, name: 'On Hold' }, { id: 2, name: 'Closed' }];
    this.subProjectsOptions = ['Include', 'Exclude'];
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;
    this.projectPhaseOptions = this.utilityService.projectPhaseOptions;
    this.itRelevantOptions = this.utilityService.itRelevantOptions;
    this.itPlatformOptions = this.utilityService.itPlatformOptions;

    // this.socketService.emitEvent('allPortfolios', {});
    this.apiService.get('/portfoliosPrograms').subscribe((response: any) => {
      this.portfolioList = this.portfolioList.concat(response.portfolios);
      this.partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
      this.partOfaProgramOptions = this.partOfaProgramOptions.concat(response.programs);

      let filtersArray = JSON.parse(localStorage.getItem("portfolioGanttFilter"));

      if (filtersArray && filtersArray.length > 0) {
        let userIds = [];
        let projectIds = [];

        filtersArray.forEach(filter => {
          if (Object.keys(filter)[0] == 'projectSponsor.id') {
            userIds.push(filter['projectSponsor.id']);
          }
          if (Object.keys(filter)[0] == 'projectManager.id') {
            userIds.push(filter['projectManager.id']);
          }
          if (Object.keys(filter)[0] == 'project') {
            projectIds.push(filter['project']);
          }
        });

        this.filtersArray = filtersArray;
        this.userIds = userIds;

        if (projectIds.length > 0) {
          this.socketService.emitEvent("getSelectiveProjects", { ids: projectIds });
        } else {
          if (userIds.length > 0) {

            this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
          } else {
            this.setFilterValues(filtersArray);
            this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray });
          }
        }
      } else {
        this.statusFilter = ["Active"];
        let filter = this.setFilters();
        this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: filter });

        this.isResetPressed = false;
        this.spinner.hide();
      }
    })

    gantt.config.xml_date = "%d.%m.%Y";
    gantt.config.date_grid = "%d.%m.%Y";
    gantt.config.smart_scales = false;
    gantt.config.drag_move = false;
    gantt.config.drag_progress = false;
    gantt.config.drag_resize = false;
    gantt.config.readonly = true;
    gantt.config.scale_unit = "year";
    gantt.config.date_scale = "%M, %Y";
    gantt.config.chartTitle = "Reports Gantt View";
    gantt.config.heading_label = "Project Name";
    gantt.config.autofit = true;

    gantt.config.columns = [
      { name: "text", label: this.localize.transform("Project Name"), tree: true, align: "left", min_width: 150, width: 250, resize: true },
      { name: "start_date", label: this.localize.transform("Start Date"), align: "center", width: 120, resize: true },
      { name: "end_date", label: this.localize.transform("End Date"), align: "center", width: 120, resize: true },
      {
        name: "progress", label: this.localize.transform("Progress"), align: "center", width: 100, resize: true, template: function (task) {
          return (Math.floor(task.progress * 100) || 0) + '%';
        }
      },
    ];
    gantt.config.keep_grid_width = false;
    gantt.config.grid_resize = true;

    gantt.init(this.ganttContainer.nativeElement);
    gantt.clearAll();
  }

  fetchProjectsList() {
    this.apiService.get('/reports?limit=0').subscribe(reportsList => {
      this.reportsList = reportsList;
      this.reset();
    });
  }

  fetchProgram() {
    this.socketService.emitEvent('allPrograms', {});
  }

  projectSponsorValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.projectSponsorFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  projectSponsorOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectSponsorFilter != undefined) {
      this.reportsList = [];
      this.projectSponsorFilter = undefined;
      this.projSponsorEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectSponsorFilter = response;
      //       } else {
      //         this.projectSponsorFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectSponsorFilter = { id: '' };
      // }
    });
  }

  projectManagerValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.projectManagerFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  projectManagerOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectManagerFilter != undefined) {
      this.reportsList = [];
      this.projectManagerFilter = undefined;
      this.projManagerEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectManagerFilter = response;
      //       } else {
      //         this.projectManagerFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectManagerFilter = { id: '' };
      // }
    });
  }

  setFilters() {
    let filtersArray = new Array;

    if (this.projectPhaseFilter) {
      filtersArray.push({ projectPhase: this.projectPhaseFilter.id })
    }
    if (this.projectMethodologyFilter) {
      filtersArray.push({ projectMethodology: this.projectMethodologyFilter.id })
    }
    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      filtersArray.push({ status: this.statusFilter })
    }
    if (this.reportingLevelFilter) {
      filtersArray.push({ reportingLevel: this.reportingLevelFilter.id })
    }
    if (this.projectCategoryFilter) {
      filtersArray.push({ classification: this.projectCategoryFilter.id })
    }
    if (this.projectTypeFilter) {
      filtersArray.push({ projectType: this.projectTypeFilter.id })
    }
    if (this.businessAreaFilter) {
      filtersArray.push({ businessArea: this.businessAreaFilter.id })
    }
    if (this.businessUnitFilter) {
      filtersArray.push({ businessUnit: this.businessUnitFilter.id })
    }
    if (this.programFilter) {
      filtersArray.push({ program: this.programFilter.id })
    }
    if (this.itRelevantFilter) {
      filtersArray.push({ itRelevant: this.itRelevantFilter.id })
    }
    if (this.portfolioFilter) {
      filtersArray.push({ portfolio: this.portfolioFilter.id })
    }
    if (this.subPortfolioFilter) {
      filtersArray.push({ subPortfolio: this.subPortfolioFilter.id })
    }
    if (this.itPlatformFilter && this.itPlatformFilter.length > 0) {
      filtersArray.push({ itPlatform: this.itPlatformFilter })
    }
    if (this.projectSponsorFilter) {
      filtersArray.push({ 'projectSponsor.id': this.projectSponsorFilter.id })
    }
    if (this.projectManagerFilter) {
      filtersArray.push({ 'projectManager.id': this.projectManagerFilter.id })
    }
    if (this.projectNameFilter) {
      filtersArray.push({ 'project': this.projectNameFilter.id })
    }

    this.isFilterApplied = true;
    return filtersArray;
  }

  setFilterValues(filterArray) {
    filterArray.forEach(filter => {
      switch (Object.keys(filter)[0]) {

        case 'portfolio':
          this.portfolioFilter = this.portfolioList.find(val => val.id == filter.portfolio)
          break;

        case 'subPortfolio':
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
          this.subPortfolioFilter = this.subPortfolioList.find(val => val.id == filter.subPortfolio)
          this.isSubportfolioFilter = true;
          this.isSubPortfolio = false;
          break;

        case 'program':
          this.programFilter = this.partOfaProgramOptions.find(val => val.id == filter.program)
          break;

        case 'status':
          this.statusFilter = filter.status;
          break;

        case 'itPlatform':
          this.itPlatformFilter = filter.itPlatform;
          break;

        case 'projectManager.id':
          this.projectManagerFilter = this.usersList.find(val => val.id == filter['projectManager.id'])
          if (this.projectManagerFilter) {
            this.projManagerEmail = this.projectManagerFilter.email;
          }
          break;

        case 'projectSponsor.id':
          this.projectSponsorFilter = this.usersList.find(val => val.id == filter['projectSponsor.id'])
          if (this.projectSponsorFilter) {
            this.projSponsorEmail = this.projectSponsorFilter.email;
          }
          break;

        case 'project':
          this.projectNameFilter = this.projectsSearchList.find(val => val.id == filter['project'])
          if (this.projectNameFilter) {
            this.projectName = this.projectNameFilter.projectName;
          }
          break;


        default:
        // code block
      }

    });

  }

  filter() {
    // this.isClickFilter = true;
    let filtersArray = this.setFilters();

    if (filtersArray.length > 0) {
      this.dropdownPageIndex = 1;
      this.reportsList = [];
      this.filtersArray = filtersArray;

      localStorage.setItem('portfolioGanttFilter', JSON.stringify(filtersArray));

      this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray });
      this.spinner.show();
    } else {
      gantt.clearAll();
      this.reportsList = [];
      this.tasks = [];
      this.reset();
    }
  }

  resetFilter() {
    this.dropdownPageIndex = 1;
    this.isResetPressed = true;
    this.filtersArray = [{ status: ['Active'] }];

    this.reportsList = [];
    this.portfolioFilter = '';
    this.subPortfolioFilter = '';
    this.programFilter = '';
    this.businessAreaFilter = '';
    this.businessUnitFilter = '';
    this.projectTypeFilter = '';
    this.projectCategoryFilter = '';
    this.reportingLevelFilter = '';
    this.statusFilter = ["Active"];
    this.projectPhaseFilter = '';
    this.projectMethodologyFilter = '';
    this.projectManagerFilter = '';
    this.projectSponsorFilter = '';
    this.itRelevantFilter = '';
    this.itPlatformFilter = [];
    this.projectNameFilter = '';
    this.projectName = '';
    this.projManagerEmail = '';
    this.projSponsorEmail = '';
    this.isSubPortfolio = true;
    this.isFilterApplied = false;
    localStorage.setItem("portfolioGanttFilter", JSON.stringify(this.filtersArray));

    this.spinner.show();
    this.socketService.emitEvent('portfolioProjectsFilterCount', { filtersArray: this.filtersArray });


    gantt.clearAll();
    // this.tasks = [];

    let interval = setInterval(() => {
      if (this.isResetPressed == true) {
        clearInterval(interval);
        this.isResetPressed = false;
      }
    }, 500);

  }

  projectsOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectNameFilter != undefined) {
      this.reportsList = [];
      this.projectNameFilter = undefined;
      this.projectName = '';
      this.filter();
    }
    this.apiService.get('/projects/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsSearchList = response;
    });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.projectNameFilter = e.itemData;
      this.filter();
    }
  }

  fetchPortfoliosList() {
    this.apiService.get('/portfolio').subscribe(portfolioList => {
      Array.prototype.push.apply(this.portfolioList, portfolioList);
    });
  }

  checkProgress(element): any {
    if (element.statusReports != undefined) {
      if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].percentageComplete != undefined) {
        return element.statusReports[element.statusReports.length - 1].percentageComplete / 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.itPlatformFilter) {
      this.itPlatformFilter.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.showNotificationBox('It Platform can select maximum no of 3.');
    } else {
      this.itPlatformFilter = this.getSelectedItemsKeys(nodes);
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  filterProjects(dataObj) {
    if (dataObj.value.name != "All") {
      this.subPortfolioList = [];
      this.subPortfolioList = [{ id: undefined, name: "All" }];
      Array.prototype.push.apply(this.subPortfolioList, dataObj.value.subPortfolio);
      if (this.subPortfolioList.length > 0) {
        this.isSubPortfolio = false;
      }
      this.tasks = [];
      this.reportsList.forEach(reportObj => {
        if (reportObj.portfolio != undefined) {
          if (reportObj.portfolio.id == dataObj.value.id) {
            let progress = this.checkProgress(reportObj);
            this.tasks.push({
              id: reportObj.id,
              text: reportObj.projectName,
              start_date: moment(reportObj.startDate).format('DD/MM/YYYY'),
              end_date: moment(reportObj.plannedEndDate).format('DD/MM/YYYY'),
              progress: progress //reportObj.statusReports != undefined ? reportObj.statusReports[reportObj.statusReports.length - 1].percentageComplete / 100 : 0,
            });    // reportObj.statusReports.length > 0
          }
        }
      });

      let [data, links] = [this.tasks, this.links];
      gantt.clearAll();
      gantt.parse({ data, links });
      gantt.render();
    } else {
      let [data, links] = [JSON.parse(JSON.stringify(this.backupList)), this.links];
      gantt.clearAll();
      gantt.parse({ data, links });
      gantt.render();
      this.subPortfolioList = [];
      this.subPortfolioList = [{ id: undefined, name: "All" }];
      this.isSubPortfolio = true;
    }
  }

  filterSubProjects(dataObj) {
    if (dataObj.value.name != "All") {
      this.tasks = [];
      this.reportsList.forEach(reportObj => {
        if (reportObj.subPortfolio != undefined) {
          if (reportObj.subPortfolio.id == dataObj.value.id) {
            let progress = this.checkProgress(reportObj);
            this.tasks.push({
              id: reportObj.id,
              text: reportObj.projectName,
              start_date: moment(reportObj.startDate).format('DD/MM/YYYY'),
              end_date: moment(reportObj.plannedEndDate).format('DD/MM/YYYY'),
              progress: progress //reportObj.statusReports != undefined ? reportObj.statusReports[reportObj.statusReports.length - 1].percentageComplete / 100 : 0,
            });
          }
        }
      });

      let [data, links] = [this.tasks, this.links];
      gantt.clearAll();
      gantt.parse({ data, links });
      gantt.render();
    } else {
      this.tasks = [];
      this.subPortfolioList.forEach((subPortfolioObj: any) => {
        this.reportsList.forEach(reportObj => {
          if (reportObj.subPortfolio != undefined) {
            if (reportObj.subPortfolio.id == subPortfolioObj.id) {
              let progress = this.checkProgress(reportObj);
              this.tasks.push({
                id: reportObj.id,
                text: reportObj.projectName,
                start_date: moment(reportObj.startDate).format('DD/MM/YYYY'),
                end_date: moment(reportObj.plannedEndDate).format('DD/MM/YYYY'),
                progress: progress //reportObj.statusReports != undefined ? reportObj.statusReports[reportObj.statusReports.length - 1].percentageComplete / 100 : 0,
              });
            }
          }
        });
      });

      let [data, links] = [this.tasks, this.links];
      gantt.clearAll();
      gantt.parse({ data, links });
      gantt.render();
    }
  }

  switchDateScale(value: string) {
    this.selectedFilter = value;
    gantt.config.scale_unit = value;
    gantt.render();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  filterSubPortfolio() {
    if (this.portfolioFilter) {

      if (!this.isSubportfolioFilter) {
        this.subPortfolioFilter = '';

        if (this.portfolioFilter != "" && this.portfolioFilter.name != 'All') {
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
          this.subPortfolioFilter = this.subPortfolioList[0];
          if (this.subPortfolioList != undefined) {
            if (this.subPortfolioList.length > 0) {
              this.isSubPortfolio = false;
            }
          }
        } else {
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.isSubPortfolio = true;
        }
      } else {
        this.isSubportfolioFilter = false;
      }
    }
  }

  subPortfolioValueChanged() {
    if (this.subPortfolioFilter || this.portfolioFilter) {
      if (!this.isResetPressed) {
        this.filter();
      }
    }
  }

  programValueChanged() {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  onITPlatformSelectionChanged(e) {
    if (!this.isResetPressed) {

      this.filter();
    }
  }

  onStatusSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  reset() {
    this.reportsList.forEach(element => {
      this.tasks.push({
        id: element.id,
        text: element.projectName,
        start_date: moment(element.startDate).format('DD/MM/YYYY'),
        end_date: moment(element.plannedEndDate).format('DD/MM/YYYY'),
        progress: element.statusReports.length > 0 ? element.statusReports[element.statusReports.length - 1].percentageComplete / 100 : 0,
      });
    });
    let [data, links] = [this.tasks, this.links];
    this.backupList = JSON.parse(JSON.stringify(data));
    gantt.parse({ data });
  }

  ngOnDestroy() {
    this.socketService.removeListener('portfolioProjectsFilter');
    this.socketService.removeListener('fetchMultipleUsers');
    this.socketService.removeListener('getSelectiveProjects');
    this.socketService.removeListener('portfolioProjectsFilterCount');
    // this.socketService.removeListener('allPrograms');
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }



}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  text?: string;
  progress: number;
  duration?: number;
}