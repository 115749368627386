import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubPortfolioStatusReportComponent } from './sub-portfolio-status-report.component';

const routes: Routes = [
  {
    path: '',
    component: SubPortfolioStatusReportComponent
  },
  {
    path: 'createStatusReport/:subPortfolio',
    loadChildren: () => import('./create-status-report/create-status-report.module').then(mod => mod.CreateStatusReportModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubPortfolioStatusReportRoutingModule { }
