import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgramStatusReportRoutingModule } from './program-status-report-routing.module';
import { ProgramStatusReportComponent } from './program-status-report.component';
import {
  DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxDateBoxModule,
  DxCheckBoxModule, DxProgressBarModule, DxChartModule
} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ModalModule } from 'ngx-bootstrap';
import { MaterialModule } from 'app/material.module';
import { PDFProgramStatusReportModule } from 'app/programs/program-detail/pdf-program-status-report/pdf-program-status-report.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    ProgramStatusReportRoutingModule,
    DxDataGridModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxProgressBarModule,
    ModalModule,
    DxChartModule,
    MaterialModule,
    PDFProgramStatusReportModule,
    FileUploadModule,
    NgxSpinnerModule
  ],
  declarations: [
    ProgramStatusReportComponent
  ]
})
export class ProgramStatusReportModule { }
