import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortfolioBudgetRoutingModule } from './portfolio-budget-routing.module';
import { PortfolioBudgetComponent } from './portfolio-budget.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxDataGridModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxTextAreaModule, DxDateBoxModule, DxDropDownBoxModule, DxTreeViewModule, DxAutocompleteModule } from 'devextreme-angular';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FormsModule } from '@angular/forms';
import { ProjectBudgetModule } from 'app/components/project-budget/project-budget.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap';
import { MaterialModule } from 'app/material.module';

@NgModule({
  imports: [
    CommonModule,
    PortfolioBudgetRoutingModule,
    I18nModule,
    DxDataGridModule,
    DxCheckBoxModule,
    CurrencyFormatterPipeModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxTextAreaModule,
    ProjectBudgetModule,
    MatPaginatorModule,
    NgxSpinnerModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    ModalModule,
    DxAutocompleteModule,
    MaterialModule
  ],
  declarations: [PortfolioBudgetComponent,]
})
export class PortfolioBudgetModule { }
