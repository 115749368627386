import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-small-order-budget',
  templateUrl: './small-order-budget.component.html',
  styleUrls: ['./small-order-budget.component.scss']
})
export class SmallOrderBudgetComponent implements OnInit {
  orderBudget: any = [];
  additionalColumns: any = [];
  projectReportId;
  FrontEnd_PATH;
  projectFicoBackup;
  projectId;
  orderBudgetList: any = [];
  showGrid: boolean = true;

  constructor(private apiService: ApiService, private authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    globalThis = this;
    this.utilityService.breadcrumb = "Order Budget";
    this.getProjectInfo();

    this.calculateyearlyForecastValue = this.calculateyearlyForecastValue.bind(this);
    this.calculatethereofITForecastValue = this.calculatethereofITForecastValue.bind(this);
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      this.apiService.get('/orderBudgetCost/order/' + params.id).subscribe((responseBudget: any) => {

        this.projectReportId = params.id;
        this.orderBudgetList = responseBudget;
        this.orderBudget = responseBudget[0];
        this.utilityService.breadcrumbList = ['My Orders', this.orderBudget.order ? this.orderBudget.order.name : '', 'Budget'];
        if (this.orderBudget != undefined && this.orderBudget.portfolioBudgetYear != undefined) {
          this.additionalColumns = this.orderBudget.portfolioBudgetYear.additionalColumns ? this.orderBudget.portfolioBudgetYear.additionalColumns : [];
        }

        this.spinner.hide();
      });
    });
  }

  onYearChanged(event) {
    this.showGrid = false;
    this.spinner.show();
    this.orderBudget = event.value;

    this.orderBudget.budget.forEach(smallOrderBudget => {
      if (smallOrderBudget.order && smallOrderBudget.order.costTypeTable) {
        for (let i = 0; i < 7; i++) {
          smallOrderBudget.budget[i].remainingProjectBudget = parseInt(smallOrderBudget.order.costTypeTable[i].currentBudget || 0) - parseInt(smallOrderBudget.order.costTypeTable[i].actualCost || 0);
        }
      }
    });

    this.additionalColumns = event.value.portfolioBudgetYear.additionalColumns ? this.orderBudget.portfolioBudgetYear.additionalColumns : [];
  
    setTimeout(() => {
      this.showGrid = true;
      this.spinner.hide();
    }, 500);
  }

  saveProjectBudget() {
    this.spinner.show();
    this.apiService.put('/orderBudgetCost/' + this.orderBudget.id, { budget: this.orderBudget.budget }).subscribe(res => {
      this.showNotificationBox('Save Success', 'Order Budget has been saved.', 'success');
      this.spinner.hide();
    });
  }

  calculateyearlyForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.yearlyForecast ? (Math.abs(e.yearlyForecast) * -1) : '';
    } else if (e.costType == "Total") {
      this.orderBudget.budget[6].yearlyForecast = this.projectCostsValueCalculator('yearlyForecast', this.orderBudget.budget);
      return this.orderBudget.budget[6].yearlyForecast;
    } else {
      return e.yearlyForecast ? e.yearlyForecast : '';
    }
  }

  calculatethereofITForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.thereofITForecast ? (Math.abs(e.thereofITForecast) * -1) : '';
    } else if (e.costType == "Total") {
      this.orderBudget.budget[6].thereofITForecast = this.projectCostsValueCalculator('thereofITForecast', this.orderBudget.budget);
      return this.orderBudget.budget[6].thereofITForecast;
    } else {
      return e.thereofITForecast ? e.thereofITForecast : '';
    }
  }


  onRowPreparedBudgetPlanning1(e) {
    this.setRowIndex9(e);
  }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onEditorPreparingBudgetPlanning1(e) {
    this.editRowIndex9(e);
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  onCellPreparedBudgetPlanning1(e) {
    if (e.columnIndex > 1 && e.columnIndex < 3) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.columnIndex > 4) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  projectCostsValueCalculator(field, dataSource) {
    let calculationArray = [];

    for (let i = 0; i < 6; i++) {
      calculationArray[i] = dataSource[i][field] * 1;
    }

    let total = calculationArray[0] + calculationArray[1] + calculationArray[2] + calculationArray[3] - calculationArray[4] + calculationArray[5];
    return total;
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}

