import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
declare var $: any;
import * as moment from 'moment'
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-portfolio',
  templateUrl: './create-portfolio.component.html',
  styleUrls: ['./create-portfolio.component.scss']
})
export class CreatePortfolioComponent implements OnInit {
  formObject: any = {
    businessArea: '',
    subPortfolio: []
  };
  isAdmin: boolean = false;
  businessUnitOptions: any = [];
  businessAreaOptions: any = [];
  responsibleOptions = [];
  radioGroupItems = [];
  portfolioId;
  isEdit = false;
  public modalRef: BsModalRef;
  subPortfolioForm = {
    id: '',
    name: '',
    subportfolioManager: {
      id: ''
    },
    additionalSubPortfolioManager: {
      id: ''
    },
    statusReportReminder: '',
    projectNotificationEmails: []
  };

  subportfolioManagerEmail;
  additionalSubPortfolioManagerEmail;
  isEditsubPortfolio = false;
  backupForm;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  usersList;
  usersList1;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  statusReportReminderList: any = [];
  isReminderDateValid: boolean = true;
  reminderDate;
  isEditDate: boolean = false;
  dateIndex;
  projectNotificationEmail;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute,
    private utilityService: UtilityService, private modalService: BsModalService, private localize: I18nPipe,
    private authService: AuthService, private spinner: NgxSpinnerService) {
    this.fetchPMOList();
    this.usersList = utilityService.usersList;
    this.usersList1 = utilityService.usersList;
    this.isAdmin = authService.userObj.role == 'admin' ? true : false;
    let sub = this.route.params.subscribe(params => {
      this.portfolioId = params.id;

      if (this.portfolioId != undefined) {
        this.spinner.show();
        this.utilityService.breadcrumb = 'Edit Portfolio';
        this.utilityService.breadcrumbList = ['Portfolios', 'Edit Portfolio'];
        this.isEdit = true;
        this.apiService.get('/portfolio/' + this.portfolioId).subscribe((response: any) => {
          this.formObject = response;
          // this.formObject.subPortfolio = this.formObject.subPortfolio != undefined ? this.formObject.subPortfolio : [];
          this.spinner.hide();
        });
      } else {
        this.utilityService.breadcrumb = 'Create Portfolio';
        this.utilityService.breadcrumbList = ['Portfolios', 'Create Portfolio'];
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.formObject = {
      businessArea: '',
      name: '',
      subPortfolio: []
    };

    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.radioGroupItems = ['Active', 'Inactive'];
    // this.statusReportReminderList = ['Every 35 days', 'Every 65 days', 'Every 95 days'];
  }


  valueChangeEvent(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.subPortfolioForm.subportfolioManager = response;
          }
        });
      }
    }
  }

  valueChangeEvent1(e) {
    if (this.usersList1.length > 0) {
      if (this.usersList1 != undefined) {
        this.usersList1.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.subPortfolioForm.additionalSubPortfolioManager = response;
          }
        });
      }
    }
  }

  onInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.target.value).retry(2).subscribe(response => {
      this.usersList = response;
    });
  }

  onInputEvent1(e) {
    this.apiService.get('/user/search/' + e.event.target.value).retry(2).subscribe(response => {
      this.usersList1 = response;
    });
  }

  valueChangeEvent2(e) {
    if (this.usersList1.length > 0) {
      if (this.usersList1 != undefined) {
        this.usersList1.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.subPortfolioForm.projectNotificationEmails.push({email: response.email, isITRelevant: false})
            this.projectNotificationEmail = '';
          }
        });
      }
    }
  }

  deleteNotificationEmail(index){
    this.subPortfolioForm.projectNotificationEmails.splice(index, 1);
  }

  submit() {
    if (this.formObject.name != "" && this.formObject.department != "" && this.formObject.businessArea != ""
      && this.formObject.responsible != "" && this.formObject.status != undefined) {
      // this.formObject.subPortfolioBudgetCurrentYear = [{
      //   costType: "External Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 0,
      //   thereOfICT: '',
      //   group: "CAPEX",
      // }, {
      //   costType: "Internal Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 1,
      //   thereOfICT: '',
      //   group: "CAPEX",
      // }, {
      //   costType: "External Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 2,
      //   thereOfICT: '',
      //   group: "OPEX"
      // }, {
      //   costType: "Internal Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 3,
      //   thereOfICT: '',
      //   group: "OPEX"
      // }, {
      //   costType: "Revenues",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 4,
      //   thereOfICT: '',
      //   group: "OTHERS",
      // }, {
      //   costType: "Reserves",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   group: "OTHERS",
      //   thereOfICT: '',
      //   id: 5,
      // }, {
      //   costType: "Total",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   id: 6,
      //   thereOfICT: '',
      //   group: "OTHERS",
      // },];

      // this.formObject.subPortfolioBudgetNextYear = [{
      //   costType: "External Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 0,
      //   group: "CAPEX",
      // }, {
      //   costType: "Internal Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 1,
      //   group: "CAPEX",
      // }, {
      //   costType: "External Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 2,
      //   group: "OPEX"
      // }, {
      //   costType: "Internal Costs",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 3,
      //   group: "OPEX"
      // }, {
      //   costType: "Revenues",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 4,
      //   group: "OTHERS",
      // }, {
      //   costType: "Reserves",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   group: "OTHERS",
      //   id: 5,
      // }, {
      //   costType: "Total",
      //   budget: "",
      //   assigned: "",
      //   remaining: "",
      //   remainingPercent: "",
      //   actualCost: "",
      //   forecast: "",
      //   thereofICT: '',
      //   id: 6,
      //   group: "OTHERS",
      // },];

      this.spinner.show();
      this.apiService.post('/portfolio', this.formObject).subscribe(portfolioObj => {
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Portfolio has been created")}`,
          "success"
        );
        this.spinner.hide();
        this.router.navigate(['/dashboard/portfolio']);
      });
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please select all fields."),
        "warning",
      );
    }
  }

  fetchPMOList() {
    this.responsibleOptions = this.utilityService.PMOsList;
  }

  onValueChanged(e) {
    this.formObject.status = e.value;
  }

  save() {
    if (this.formObject.name != "" && this.formObject.department != ""
      && this.formObject.businessArea != "" && this.formObject.responsible != "" && this.formObject.status != undefined) {
      this.spinner.show();
      this.apiService.put('/portfolio/' + this.portfolioId, this.formObject).subscribe(portfolioObj => {
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Portfolio has been saved")}`,
          "success",
        );
        this.spinner.hide();
        this.router.navigate(['/dashboard/portfolio']);
      });
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please select all fields."),
        "warning",
      );
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessArea = response;
        }
      });
    }
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessUnit = response;
        }
      });
    }
  }

  saveSubPortfolio() {
    if (this.subPortfolioForm.name != "") {
      this.apiService.post('/subPortfolio', {
        name: this.subPortfolioForm.name,
        portfolio: this.formObject.id,
        subPortfolioManager: this.subPortfolioForm.subportfolioManager,
        additionalSubPortfolioManager: this.subPortfolioForm.additionalSubPortfolioManager,
        statusReportReminder: Array.isArray(this.statusReportReminderList) ? this.statusReportReminderList : [],
        projectNotificationEmails: this.subPortfolioForm.projectNotificationEmails
      }).subscribe((subPortfolioObj: any) => {
        this.formObject.subPortfolio.push(subPortfolioObj);
        this.resetForm();
        this.isEditsubPortfolio = false;
        this.dataGrid.instance.option('dataSource', this.formObject.subPortfolio);
        this.modalRef.hide();
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Sub Portfolio has been created")}`,
          "success",
        );
      });
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please add name of Sub Portfolio."),
        "warning",
      );
      return;
    }

    if (this.subPortfolioForm.subportfolioManager.id == "") {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please select Sub Portfolio Manager."),
        "warning",
      );
      return;
    }
  }

  resetForm() {
    this.subPortfolioForm = {
      id: '',
      name: '',
      subportfolioManager: {
        id: ''
      },
      additionalSubPortfolioManager: {
        id: ''
      },
      statusReportReminder: '',
      projectNotificationEmails: []
    };
    this.subportfolioManagerEmail = '';
    this.additionalSubPortfolioManagerEmail = '';
    this.statusReportReminderList = [];
    this.reminderDate = '';
    this.isEditDate = false;
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == 'New') {
      this.isEditsubPortfolio = false;
      this.resetForm();
    }

    if (state == 'Edit') {
      if (!this.isEditsubPortfolio) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEditsubPortfolio = true;
    this.isEditDate = false;
    this.reminderDate = '';
    this.subPortfolioForm = obj;
    this.subportfolioManagerEmail = obj.subPortfolioManager ? obj.subPortfolioManager.email : '';
     this.additionalSubPortfolioManagerEmail = obj.additionalSubPortfolioManager ? obj.additionalSubPortfolioManager.email : '';
    this.statusReportReminderList = obj.statusReportReminder ? Array.isArray(obj.statusReportReminder) ? obj.statusReportReminder : [] : []; 
    this.subPortfolioForm.projectNotificationEmails = obj.projectNotificationEmails ? obj.projectNotificationEmails : []; 
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, 'Edit');
  }

  onDelete(template, obj) {

    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/subPortfolio/' + obj.id).subscribe(subPortfolioObj => {
          let idx = this.formObject.subPortfolio.findIndex(val => val.id == obj.id);
          this.formObject.subPortfolio.splice(idx, 1);
          this.dataGrid.instance.option('dataSource', this.formObject.subPortfolio);
          this.utilityService.showNotification(
            `${this.localize.transform("Save Success")}`,
            `${this.localize.transform("Sub Portfolio have been deleted")}`,
            "success",
          );
        });
      }
    });
  }

  editSubPortfolio() {
    if (this.subPortfolioForm.name != "") {
      this.apiService.put('/subPortfolio/edit/' + this.subPortfolioForm.id, {
        name: this.subPortfolioForm.name,
        subPortfolioManager: this.subPortfolioForm.subportfolioManager,
        additionalSubPortfolioManager: this.subPortfolioForm.additionalSubPortfolioManager,
        statusReportReminder: Array.isArray(this.statusReportReminderList) ? this.statusReportReminderList : [],
        projectNotificationEmails: this.subPortfolioForm.projectNotificationEmails  
      }).subscribe((subPortfolioObj: any) => {
        let idx = this.formObject.subPortfolio.findIndex(val => val.id == subPortfolioObj.id);
        this.formObject.subPortfolio[idx] = subPortfolioObj;
        this.isEditsubPortfolio = false;
        this.resetForm();
        this.dataGrid.instance.option('dataSource', this.formObject.subPortfolio);
        this.modalRef.hide();
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Sub Portfolio have been saved")}`,
          "success",
        );
      });
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please add name of Sub Portfolio."),
        "warning",
      );
    }
  }

  addReminderDate() {
    if(this.statusReportReminderList && this.statusReportReminderList.length == 12) {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("You can select maximum 12 dates."),
        "warning",
      );
      return;
    } 
    if (!this.isReminderDateValid) {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please enter reminder date in correct format (dd.MM.yyyy) first."),
        "warning",
      );
      return;
    }
    if (this.reminderDate == undefined || this.reminderDate == '') {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please select a reminder date first."),
        "warning",
      );
      return;
    }
    else {
      this.statusReportReminderList.push(this.reminderDate);
      this.reminderDate = ''; 
      this.utilityService.showNotification(
        this.localize.transform("Success"),
        this.localize.transform("Date added successfully."),
        "success",
      );
    }
  }

  editDate(idx) {
    this.isEditDate = true;
    this.dateIndex = idx;
    this.reminderDate = this.statusReportReminderList[idx];
  }

  deleteDate(idx) {
    this.statusReportReminderList.splice(idx, 1);
    this.utilityService.showNotification(
      this.localize.transform("Success"),
      this.localize.transform("Date deleted successfully."),
      "success",
    );
  }

  editReminderDate() {
    if (!this.isReminderDateValid) {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please enter reminder date in correct format (dd.MM.yyyy) first."),
        "warning",
      );
      return;
    }
    if (this.reminderDate == undefined || this.reminderDate == '') {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please select a reminder date first."),
        "warning",
      );
      return;
    }
    else {
      this.statusReportReminderList[this.dateIndex] = this.reminderDate;
      this.isEditDate = false;
      this.utilityService.showNotification(
        this.localize.transform("Success"),
        this.localize.transform("Date updated successfully."),
        "success",
      );
      this.reminderDate = '';
    }
  }
}
