import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgChartComponent } from './org-chart.component';
import { DxPopoverModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [OrgChartComponent],
  imports: [
    CommonModule,
    FormsModule,
    DxPopoverModule
  ],
  exports: [
    OrgChartComponent
  ]
})
export class OrgChartModule { }
