import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-imported-sap',
  templateUrl: './imported-sap.component.html',
  styleUrls: ['./imported-sap.component.scss']
})
export class ImportedSapComponent implements OnInit {

  projectsRawImported;
  public isTeamEdit: boolean = false;
  public isTeamProject: boolean = false;
  projectId;
  projectReportId;
  projectInfo;
  stateStore;

  constructor(private apiService: ApiService, public authService: AuthService, private spinner: NgxSpinnerService, public utilityService: UtilityService,
    public route: ActivatedRoute) { }

  ngOnInit() {
    this.getProjectInfo();

    this.stateStore = {
      enabled: true,
      storageKey: 'importedSapData',
      type: 'localStorage',
      savingTimeout: 200
    };
  }


  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      this.isTeamProject = this.utilityService.isTeamProject;
      this.isTeamEdit = this.utilityService.isTeamEdit;

      this.spinner.show();
      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.utilityService.breadcrumbList = ['My Projects', projectData.projectName, 'Details', 'SAP AC'];
        this.spinner.hide();

        this.projectInfo = projectData.importedExcelFinancials;
        this.projectReportId = params.id;
        this.projectId = projectData.project.id;

        this.projectsRawImported = projectData.importedExcelFinancials.projectRaw;
        this.setTotal();

        // Check for user roles
        if (!this.isTeamEdit) {
          let user = this.authService.userObj;
          if (user.email == projectData.pmoOfficer.email
            || user.email == projectData.projectManager.email
            || user.email == projectData.projectSponsor.email
            || user.email == projectData.projectFico.email
            || user.role == 'admin'
            || user.role == 'pmo') {
            this.isTeamEdit = true;
            this.utilityService.isTeamEdit = true;
          }
        }

        this.utilityService.projectDetail.next({
          projectReportId: this.projectReportId,
          projectId: this.projectId,
          isTeamProject: this.isTeamProject,
          projectName: projectData.projectName,
          tabIndex: 2
        });

      });
    });
  }

  onCellPreparedFinancials(e) {
    if (e.rowIndex == (this.projectsRawImported.length - 1)) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
        e.cellElement.style.backgroundColor = "#ddd";
    }
  }

  setTotal() {
    let total = { 'Eigenleistungen Ist': 0, 'int Verr Eigenleistungen Ist': 0, 'Fahrzeuge Arb mittel Ist': 0, 'Material Fremdl üBA Ist': 0, 'Summe Kosten Ist': 0, 'Summe Erlös Ist': 0 };
    this.projectsRawImported.forEach(project => {
      total['Eigenleistungen Ist'] += project['Eigenleistungen Ist'];
      total['int Verr Eigenleistungen Ist'] += project['int Verr Eigenleistungen Ist'];
      total['Fahrzeuge Arb mittel Ist'] += project['Fahrzeuge Arb mittel Ist'];
      total['Material Fremdl üBA Ist'] += project['Material Fremdl üBA Ist'];
      total['Summe Kosten Ist'] += project['Summe Kosten Ist'];
      total['Summe Erlös Ist'] += project['Summe Erlös Ist'];
    });

    total['Eigenleistungen Ist'] = parseFloat(total['Eigenleistungen Ist'].toFixed(2));
    total['int Verr Eigenleistungen Ist'] = parseFloat(total['int Verr Eigenleistungen Ist'].toFixed(2));
    total['Fahrzeuge Arb mittel Ist'] = parseFloat(total['Fahrzeuge Arb mittel Ist'].toFixed(2));
    total['Material Fremdl üBA Ist'] = parseFloat(total['Material Fremdl üBA Ist'].toFixed(2));
    total['Summe Kosten Ist'] = parseFloat(total['Summe Kosten Ist'].toFixed(2));
    total['Summe Erlös Ist'] = parseFloat(total['Summe Erlös Ist'].toFixed(2));

    this.projectsRawImported.push(total);
  }

}
