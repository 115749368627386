import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { SocketService } from 'app/services/socket.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-yearly-budget',
  templateUrl: './yearly-budget.component.html',
  styleUrls: ['./yearly-budget.component.scss']
})
export class YearlyBudgetComponent implements OnInit, OnDestroy {
  budgetColumnsList = [];
  isEditColumn = false;
  columnInputField: String = '';
  modalRef: BsModalRef;
  configurations: any = {};
  editedColumn: any;
  @ViewChild('columnPopUp', { static: false }) columnPopUp: TemplateRef<any>;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  yearlyBudgetStore;

  // Portfolio list
  portfoliosCount: number = 0;
  portfoliosSearch: any = '';
  portfolioSubject = new Subject<any>();
  portfolioList;
  budgetYearsList = [];
  allBudgetYears = [];

  constructor(private modalService: BsModalService, private apiService: ApiService, private utilityService: UtilityService,
    private localize: I18nPipe, private socketService: SocketService) {
    this.utilityService.breadcrumb = 'Yearly Budget';
    this.utilityService.breadcrumbList = ['Settings', 'Yearly Budget'];

    this.apiService.get('/getAllSubportfolioYears').subscribe((response: any) => {
      this.allBudgetYears = response;
    });

    this.yearlyBudgetStore = {
      enabled: true,
      storageKey: 'yearlyBudgetList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.apiService.get('/getGlobalConfigurations').subscribe((response: any) => {
      this.configurations = response;
      if (response.yearlyBudgetColumns) {
        this.budgetColumnsList = response.yearlyBudgetColumns;
      }
    });

    // Portfolio
    this.socketService.emitEvent('portfoliosCount', {});
    this.socketService.emitEvent('portfoliosIndex', { pageIndex: 1, pageSize: 10 });

    this.socketService.listen('portfoliosCount').subscribe(count => {
      this.portfoliosCount = count;
    });

    this.socketService.listen('portfoliosIndex').subscribe((response: any) => {
      this.portfolioList = response;
    });

    this.socketService.listen('portfoliosSearchIndex').subscribe(portfolios => {
      this.portfolioList = portfolios;
    });

    this.socketService.listen('portfoliosSearch').subscribe(portfoliosResult => {
      this.portfoliosCount = portfoliosResult.count;
      this.portfolioList = portfoliosResult.portfolios;
    });

    this.portfolioSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
      if (value != undefined) {
        this.socketService.emitEvent('portfoliosSearch', { search: value });
      }
      return [];
    })).subscribe((response: any) => { }, error => {
    });
  }

  saveColumn() {
    if(this.columnInputField != '' && this.columnInputField != null && this.columnInputField.indexOf('.') < 0) {
      if (this.isEditColumn) {
        this.editSaveColumn();
      } else {
  
        let isColumnExist = false;
        this.budgetColumnsList.forEach(element => {
          if (element == this.columnInputField) {
            isColumnExist = true;
          }
        })
  
        if (isColumnExist) {
          this.utilityService.showNotification(
            this.localize.transform("Warning"),
            this.localize.transform("Column with this name already Exists."),
            "warning",
          );
          return;
        }
  
        this.budgetColumnsList.push(this.columnInputField);
        this.apiService.put('/configurations/' + this.configurations.id, {
          yearlyBudgetColumns: this.budgetColumnsList
        }).subscribe(response => {
          this.columnInputField = '';
          this.modalRef.hide();
          this.utilityService.showNotification(
            this.localize.transform("Success"),
            this.localize.transform("Column Added"),
            "success",
          );
        });
      }
    } else {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Please enter column name first that does not contain any '.' symbol"),
        "warning",
      );
    }
  }

  editSaveColumn() {
    let index = this.budgetColumnsList.findIndex(itemm => itemm == this.editedColumn);
    this.budgetColumnsList[index] = this.columnInputField;

    this.apiService.put('/configurations/' + this.configurations.id, {
      yearlyBudgetColumns: this.budgetColumnsList
    }).subscribe((response) => {
      this.isEditColumn = false;
      this.columnInputField = '';
      this.modalRef.hide();
      this.utilityService.showNotification(
        `${this.localize.transform("Update Success")}`,
        `${this.localize.transform("Column has been Updated.")}`,
        "success",
      );
    });
  }

  editItem(item, formObj) {
    this.isEditColumn = true;
    this.columnInputField = item;
    this.editedColumn = item;
    this.openModal(this.columnPopUp);
  }

  resetInputFields() {
    this.columnInputField = '';
    this.isEditColumn = false;
  }

  onModalClose() {
    this.resetInputFields();
    this.modalRef.hide()
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  deleteItem(item) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to delete this column?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        let index = this.budgetColumnsList.findIndex(itemm => itemm == item);
        this.budgetColumnsList.splice(index, 1);
        this.apiService.put('/configurations/' + this.configurations.id, {
          yearlyBudgetColumns: this.budgetColumnsList
        }).subscribe((response) => {
          this.utilityService.showNotification(
            `${this.localize.transform("Delete Success")}`,
            `${this.localize.transform("Column has been Deleted.")}`,
            "success",
          );
        });
      }
    });
  }

  // Portfolio section
  onSearchChange(event) {
    this.portfolioSubject.next(event.target.value)
  }

  onPageChange(event) {
    if (this.portfoliosSearch == '') {
      this.socketService.emitEvent('portfoliosIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    } else {
      this.socketService.emitEvent('portfoliosSearchIndex', { search: this.portfoliosSearch, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    }
  }

  fixBudget(budgetYear) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(`Do you want to fix the budget for ${budgetYear.year}?`)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.post('/portfolioBudgetYear/fixYearlyBudget', {
          budgetYear: budgetYear.id
        }).subscribe((response: any) => {
          console.log('response', response);
          this.utilityService.showNotification(
            `${this.localize.transform("Success")}`,
            `${this.localize.transform("Budget has been fixed successfully.")}`,
            "success",
          );
        }, (error) => {
          this.utilityService.showNotification(
            this.localize.transform("Error"),
            this.localize.transform(error),
            "danger",
          );
        })
      }
    });
  }

  fixAllBudgets(year) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(`Do you want to fix All the Budgets for ${year}?`)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.post('/portfolioBudgetYear/fixAllYearlyBudget', {year}).subscribe((response: any) => {
          this.utilityService.showNotification(
            `${this.localize.transform("Success")}`,
            `${this.localize.transform("Budget has been fixed successfully.")}`,
            "success",
          );
        }, (error) => {
          this.utilityService.showNotification(
            this.localize.transform("Error"),
            this.localize.transform(error),
            "danger",
          );
        })
      }
    });
  }

  loadSubportfolioBudgetYears(item) {
    this.apiService.get('/portfolioBudgetYear/' + item.id).subscribe((years: any) => {
      this.budgetYearsList = years;
    })
  }

  ngOnDestroy() {
    this.socketService.removeListener('portfoliosCount');
    this.socketService.removeListener('portfoliosIndex');
    this.socketService.removeListener('portfoliosSearchIndex');
    this.socketService.removeListener('portfoliosSearch');
  }
}
