import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-status-calculation',
  templateUrl: './status-calculation.component.html',
  styleUrls: ['./status-calculation.component.scss']
})
export class StatusCalculationComponent implements OnInit {
  overallStatusOptions;
  statusObj;
  riskStatus;

  constructor(private apiService: ApiService, private authService: AuthService, private utilityService: UtilityService,
    private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Status Calculation';
    this.utilityService.breadcrumbList = ['Settings', 'Status Calculation'];

    this.statusObj = {
      costStatus: {
        positive: {},
        negative: {}
      },
      timeStatus: {
        positive: {},
        negative: {}
      },
      overallStatus: {},
      riskStatus: {
        low: '',
        medium: '',
        high: ''
      }
    };

    this.riskStatus = {
      low: '',
      medium: '',
      high: ''
    }

    this.apiService.get('/configurations/' + this.authService.userObj.configuration.id).subscribe((response: any) => {
      if (response.statusSetting != undefined) {
        this.statusObj = response.statusSetting;
        if (response.statusSetting.riskStatus != undefined) {
          this.riskStatus = response.statusSetting.riskStatus;
        }
      }
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.overallStatusOptions = ['Manual', 'Follow the least favourable', 'Follow the most favaourable'];
  }

  save() {
    this.spinner.show();
    this.statusObj.riskStatus = this.riskStatus;
    this.apiService.put('/configurations/' + this.authService.userObj.configuration.id, {
      statusSetting: this.statusObj
    }).subscribe(response => {
      this.authService.userObj.configuration = response;
      this.authService.setUserObj(this.authService.userObj);

      this.utilityService.showNotification(
        this.localize.transform("Save Success"),
        this.localize.transform("Status Setting has been saved"),
        "success",
      );
      this.spinner.hide();
    });
  }

}
