import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrderMeasuresComponent } from './order-measures.component';
import { DxDataGridModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxAutocompleteModule, DxDateBoxModule } from 'devextreme-angular';
import { I18nModule } from "app/shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxAutocompleteModule,
    ModalModule,
    DxDateBoxModule
  ],
  declarations: [
    OrderMeasuresComponent
  ],
  exports: [
    OrderMeasuresComponent
  ]
})
export class OrderMeasuresModule { }
