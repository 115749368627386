import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from "./i18n.service";

@Pipe({
  name: 'i18nmulti',
  pure: false
})
export class I18nMultiPipe implements PipeTransform {

  constructor(public i18nService: I18nService) { }

  transform(phrase: any, args?: any): any {
    let values;
    if (typeof phrase == 'string') {
      values = phrase.split(',');
    } else {
      values = phrase;
    }
    let temp: any = [];

    if (values) {
      values.map(val => temp.push(this.i18nService.getTranslation(val)));
    }
    temp = temp.join(', ');
    return temp;
  }

}
