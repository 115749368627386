import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateProgramRoutingModule } from './create-program-routing.module';
import { CreateProgramComponent } from './create-program.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DxAutocompleteModule, DxSelectBoxModule} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    CreateProgramRoutingModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    DxAutocompleteModule,
    DxSelectBoxModule
  ],
  declarations: [
    CreateProgramComponent
  ]
})
export class CreateProgramModule { }
