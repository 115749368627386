import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClipboardService } from 'ngx-clipboard';
declare var $: any;

var globalThis;

@Component({
  selector: 'app-create-project-report',
  templateUrl: './create-project-report.component.html',
  styleUrls: ['./create-project-report.component.scss']
})
export class CreateProjectReportComponent implements OnInit {
  reportObj: any = {
    overallStatusComments: '',
    scopeStatusComments: '',
    costStatusComments: '',
    timeStatusComments: '',
    resourcesStatusComments: '',
    managementSummary: '',
    problemsAndNecessaryDecisions: '',
    reportingDate: '',
    currentBudgetVsForecast: '',
  };
  conf;
  projectReportId;
  isNew = false;
  isPrev = false;
  reportId;
  options = ['Green', 'Yellow', 'Red'];
  riskOptions = ['Low', 'Medium', 'High'];
  projectDetailObj: any = {
    startDate: '',
    endDate: '',
    plannedEndDate: '',
    forecastEndDate: '',
    projectPhase: {
      id: ''
    },
  };
  isMember: boolean = false;
  projectPhaseOptions;
  totalExposure = 0;
  totalTime;
  timeCompleted;
  endDateVSPlannedEndDate;
  currentBudgetVSOriginalBudget: any = 0;;
  currentBudgetVSActualCost;
  currentBudgetVSForecast;
  riskExposureVsCurrentBudget: any = 0;
  statusReportPreview;
  prevStatusReport;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  lastStatusReport;
  managementSummaryLimit: boolean = false;
  scopeCommentsLimit: boolean = false;
  costCommentsLimit: boolean = false;
  timeCommentsLimit: boolean = false;
  pmoCommentsLimit: boolean = false;
  invalidChars = ["-", "+", "e",];
  helpGuide;
  public minDate: any;
  isReportingDateValid: boolean = true;
  isForecastEndDateValid: boolean = true;
  actualCostImported;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private clipboardService: ClipboardService,
    public authService: AuthService, private utilityService: UtilityService, private localize: I18nPipe,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Create Report";

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    globalThis = this;
    this.reportObj.overallStatus = 'Select One';
    this.reportObj.scopeStatus = 'Select One';
    this.reportObj.costStatus = 'Select One';
    this.reportObj.timeStatus = 'Select One';
    this.reportObj.resourcesStatus = 'Select One';
    this.reportObj.riskStatus = 'Select One';
    this.reportObj.percentageComplete = 0;
    this.reportObj.reportingDate = '';
    this.reportObj.currentReserves = '';
    this.reportObj.obligation = '';

    this.projectDetailObj.costTypeTable = [
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 0,
        group: 'CAPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 1,
        group: 'CAPEX'
      },
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 2,
        group: 'OPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 3,
        group: 'OPEX'
      },
      {
        costTypes: "Revenues",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 4,
        group: 'Sonstiges'
      },
      {
        costTypes: "Reserves",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 5,
        group: 'Sonstiges'
      },
      {
        costTypes: "Total",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 6,
        group: 'Sonstiges'
      },];

    this.projectPhaseOptions = this.utilityService.projectPhaseOptions;

    this.getHelpGuideData();
  }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    let sub = this.route.params.subscribe(params => {
      if (params.current != null && params.current != "null" && params.current != undefined) { //Edit
        this.reportId = params.current;
        this.apiService.get('/statusReports/' + params.current).subscribe((reportObj: any) => {
          this.reportObj = reportObj;
          // this.minDate = moment(this.reportObj.reportingDate).add(1, 'days');
          this.spinner.hide();

          this.apiService.get('/reports/' + reportObj.projectReport.id).subscribe((reportResponse: any) => {
            if (reportResponse.projectPhase == undefined) {
              reportResponse.projectPhase = {
                id: ''
              };
            }

            this.projectDetailObj = reportResponse;
            this.projectReportId = this.projectDetailObj.id;
            this.utilityService.breadcrumbList = ['My Projects', this.projectDetailObj.projectName, 'Details', 'Status Report', 'Edit'];

            this.actualCostImported = this.projectDetailObj.importedExcelFinancials;

            //Update Total Exposure
            this.calculateRiskExposure();

            this.calculateVarianceValues(reportObj.projectReport);

            if (params.previous != null && params.previous != "null" && params.previous != undefined) {
              this.apiService.get('/statusReports/' + params.previous).subscribe((lastStatusReportObj: any) => { // Case in edit mode if having previous report.
                this.minDate = moment(lastStatusReportObj.reportingDate).add(1, 'days');
                this.lastStatusReport = JSON.parse(JSON.stringify(lastStatusReportObj));
              });
            }
            this.spinner.hide();
          });
        });
      } else { // New
        this.isNew = true;
        this.isPrev = true;
        this.projectReportId = params.id;

        if (params.previous == null || params.previous == "null" || params.previous == undefined) { // Case: No previous report
          this.apiService.get('/reports/' + this.projectReportId).subscribe((response: any) => {
            this.spinner.hide();
            if (response.projectPhase == undefined) {
              response.projectPhase = {
                id: ''
              };
            }

            this.projectDetailObj = response;
            this.checkMember(this.projectDetailObj);
            this.utilityService.breadcrumbList = ['My Projects', this.projectDetailObj.projectName, 'Details', 'Status Report', 'Create'];
            //Update Total Exposure
            this.projectDetailObj.risks.forEach(val => {
              this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
            });

            this.actualCostImported = this.projectDetailObj.importedExcelFinancials;

            this.calculateVarianceValues(response);
            this.riskExposureVsCurrentBudget = ((this.totalExposure / this.projectDetailObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';

            this.projectDetailObj.threeYearsBudgetBreakdown = this.projectDetailObj.threeYearsBudgetBreakdown ? this.projectDetailObj.threeYearsBudgetBreakdown : this.threeYearsInit();
            this.spinner.hide();
          });
        } else { // Case: Having previous report
          this.apiService.get('/statusReports/' + params.previous).subscribe((reportObj: any) => {
            this.spinner.hide();
            this.reportObj = reportObj;
            this.lastStatusReport = JSON.parse(JSON.stringify(reportObj));
            delete (this.reportObj.id);
            this.minDate = moment(this.lastStatusReport.reportingDate).add(1, 'days');
            this.reportObj.reportingDate = moment(this.reportObj.reportingDate).add(1, 'days');

            if (reportObj.projectReport.projectPhase == undefined) {
              reportObj.projectReport.projectPhase = {
                id: ''
              };
            }

            this.apiService.get('/reports/' + reportObj.projectReport.id).subscribe(reportResponse => {
              this.projectDetailObj = reportResponse;
              this.checkMember(this.projectDetailObj);
              this.utilityService.breadcrumbList = ['My Projects', this.projectDetailObj.projectName, 'Details', 'Status Report', 'Create'];

              this.actualCostImported = this.projectDetailObj.importedExcelFinancials;

              //Update Total Exposure
              if (this.projectDetailObj.risks) {
                this.projectDetailObj.risks.forEach(val => {
                  this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
                });
                this.riskExposureVsCurrentBudget = ((this.totalExposure / this.projectDetailObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
              }

              this.projectDetailObj.threeYearsBudgetBreakdown = this.projectDetailObj.threeYearsBudgetBreakdown ? this.projectDetailObj.threeYearsBudgetBreakdown : this.threeYearsInit();
              this.calculateVarianceValues(reportObj.projectReport);
              this.spinner.hide();
            });
          });
        }
      }
    });
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Project Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  onCellDblClick(e){
    this.clipboardService.copyFromContent(e.value);
    this.showNotification('Success','Value Copied!', 'success')
  }

  checkMember(projectData) {
    if (projectData.team != undefined) {
      projectData.team.forEach(member => {
        if (member.name.id == this.authService.userObj.id) {
          if (member.permission == 'Edit') {
            this.isMember = true;
            if (projectData.user.id == member.name.id) {
              this.isMember = false;
            }
          }
        }
      });
    }
  }

  threeYearsInit() {
    return [{
      costTypes: "OPEX External Cost",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "OPEX Internal Cost",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "CAPEX External Cost",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "CAPEX Internal Cost",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "Revenues",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "Reserves",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    }, {
      costTypes: "Total",
      currentYear: '',
      yearTwo: '',
      yearThree: '',
    },];
  }

  calculateVarianceValues(report) {
    this.conf = <any>(this.authService.getUserObj()).configuration;
    this.projectDetailObj.currentBudgetVSForecast = ((this.projectDetailObj.costTypeTable[6].forecast - this.projectDetailObj.costTypeTable[6].currentBudget) / this.projectDetailObj.costTypeTable[6].currentBudget * 100).toFixed(2);
    let costVariance = parseFloat(this.projectDetailObj.currentBudgetVSForecast);

    if (costVariance >= 0) {
      if (costVariance <= this.conf.statusSetting.costStatus.positive.green) {
        this.reportObj.costStatus = 'Green';
      } else if (costVariance > this.conf.statusSetting.costStatus.positive.green && costVariance <= this.conf.statusSetting.costStatus.positive.yellow) {
        this.reportObj.costStatus = 'Yellow';
      } else if (costVariance > this.conf.statusSetting.costStatus.positive.yellow) { //&& costVariance <= this.conf.statusSetting.costStatus.positive.red) {
        this.reportObj.costStatus = 'Red';
      }
    } else if (costVariance < 0) {
      if (costVariance >= this.conf.statusSetting.costStatus.negative.green * -1) {
        this.reportObj.costStatus = 'Green';
      } else if (costVariance < this.conf.statusSetting.costStatus.negative.green * -1 && costVariance >= this.conf.statusSetting.costStatus.negative.yellow * -1) {
        this.reportObj.costStatus = 'Yellow';
      } else if (costVariance < this.conf.statusSetting.costStatus.negative.yellow * -1) { // && costVariance >= this.conf.statusSetting.costStatus.negative.red * -1) {
        this.reportObj.costStatus = 'Red';
      }
    }

    this.calculateTimeStatus();
    this.calculateOverallStatus();
    this.calculateRiskStatus();
  }

  calculateTimeStatus() {
    let timeVariance = parseFloat(this.projectDetailObj.plannedDateVSForecastDate);

    if (timeVariance >= 0) {
      if (timeVariance <= this.conf.statusSetting.timeStatus.positive.green) {
        this.reportObj.timeStatus = 'Green';
      } else if (timeVariance > this.conf.statusSetting.timeStatus.positive.green && timeVariance <= this.conf.statusSetting.timeStatus.positive.yellow) {
        this.reportObj.timeStatus = 'Yellow';
      } else if (timeVariance > this.conf.statusSetting.timeStatus.positive.yellow) { //&& timeVariance <= this.conf.statusSetting.timeStatus.positive.red) {
        this.reportObj.timeStatus = 'Red';
      }
    } else if (timeVariance < 0) {
      if (timeVariance >= this.conf.statusSetting.timeStatus.negative.green * -1) {
        this.reportObj.timeStatus = 'Green';
      } else if (timeVariance < this.conf.statusSetting.timeStatus.negative.green * -1 && timeVariance >= this.conf.statusSetting.timeStatus.negative.yellow * -1) {
        this.reportObj.timeStatus = 'Yellow';
      } else if (timeVariance < this.conf.statusSetting.timeStatus.negative.yellow * -1) { // && timeVariance >= this.conf.statusSetting.timeStatus.negative.red) {
        this.reportObj.timeStatus = 'Red';
      }
    }
  }

  calculateOverallStatus() {
    if (this.conf.statusSetting != undefined) {
      if (this.conf.statusSetting.overallStatus == 'Follow the least favourable') {
        for (let i = 2; i >= 0; i--) {
          if (this.reportObj.scopeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.timeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.costStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.riskStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          }
        }
      } else if (this.conf.statusSetting.overallStatus == 'Follow the most favaourable') {
        for (let i = 0; i < 3; i++) {
          if (this.reportObj.scopeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.timeStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.costStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          } else if (this.reportObj.riskStatus == this.options[i]) {
            this.reportObj.overallStatus = this.options[i];
            break;
          }
        }
      }
    }
  }

  calculateRiskStatus() {
    let riskVariance = parseFloat(this.riskExposureVsCurrentBudget);

    if (riskVariance <= this.conf.statusSetting.riskStatus.low) {
      this.reportObj.riskStatus = 'Low';
    } else if (riskVariance > this.conf.statusSetting.riskStatus.low && riskVariance <= this.conf.statusSetting.riskStatus.medium) {
      this.reportObj.riskStatus = 'Medium';
    } else if (riskVariance > this.conf.statusSetting.riskStatus.medium) { //&& riskVariance <= this.conf.statusSetting.riskStatus.high) {
      this.reportObj.riskStatus = 'High';
    }
  }

  submit() {
    if (!this.isReportingDateValid) {
      this.showNotification('Warning', 'Please enter reporting date in correct format (dd.MM.yyyy) first.', 'warning')
      return;
    }
    if (this.reportObj.reportingDate == "" || this.reportObj.reportingDate == null ||
      this.reportObj.reportingDate == undefined) {
      this.showNotification('Warning', 'Please select reporting date first.', 'warning');
      return;
    }
    if (this.projectDetailObj.projectPhase.id == '') {
      this.showNotification('Warning', 'Please select project phase first.', 'warning');
      return;
    }
    if (this.reportObj.scopeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select scope status first.', 'warning');
      return;
    }
    if (!this.isForecastEndDateValid) {
      this.showNotification('Warning', 'Please enter forecast end date in correct format (dd.MM.yyyy) first.', 'warning')
      return;
    }
    if (this.projectDetailObj.forecastEndDate == "" || this.projectDetailObj.forecastEndDate == null ||
      this.projectDetailObj.forecastEndDate == undefined) {
      this.showNotification('Warning', 'Please select forecast end date first.', 'warning');
      return;
    }
    if (this.reportObj.managementSummary == "") {
      this.showNotification('Warning', 'Please enter management summary first.', 'warning');
      return;
    }

    this.projectDetailObj.hasDraftReport = false;
    this.apiService.put('/reports/' + this.projectReportId, this.projectDetailObj).subscribe(response => {
      if (this.reportObj.scopeStatus != 'Select One' && this.reportObj.riskStatus != 'Select One') {
        this.reportObj.isSubmitted = true;
        this.reportObj.status = 'Submitted';
        this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);
        this.calculateVarianceValues(this.projectDetailObj);

        if (this.projectReportId != undefined) {
          this.submitHelper(this.projectReportId);
        } else {
          this.apiService.get('/statusReports/' + this.reportId).subscribe((response: any) => {
            this.submitHelper(response.projectReport.id);
          });
        }
      } else {
        this.showNotification('Error', 'Please fill in all the mandatory fields.', 'warning');
      }
    });
  }

  submitHelper(id) {
    this.spinner.show();
    let obj: any = {};
    this.apiService.get('/reports/' + id).subscribe((response: any) => {
      this.reportObj.EVA = response.EVA;

      let flag = false;
      if (response.mileStonesValues) {
        if (Object.keys(response.mileStonesValues).length > 0) {
          obj = this.parseMilestoneValues(response);
          flag = true;
        }
      }
      delete (obj.statusReports);
      this.reportObj.MTAChart = [];
      if (obj.milestoneTable) {
        let milestonesCopy = JSON.parse(JSON.stringify(obj.milestoneTable));

        if (obj.milestoneTable) {
          for (let x = obj.milestoneTable.length - obj.milestoneTable.length >= 7 ? obj.milestoneTable.length - 8 : 0; x <= obj.milestoneTable.length - 1; x++) {
            if (obj.milestoneTable[x] != null) {
              this.reportObj.MTAChart.push(milestonesCopy[x]);
            }
          }
        }
      }

      // Delete milestone not relevant to report
      if (response.mileStonesValues) {
        for (let i = 0; i < response.mileStonesValues.length; i++) {
          if (response.mileStonesValues[i].report == false) {
            this.reportObj.MTAChart.forEach((val, index) => {
              delete (this.reportObj.MTAChart[index][`milestone${i + 1}`]);
            });
          }
        }
      }

      if (response.currentReserveHistory) {
        response.currentReserveHistory.push({
          date: this.reportObj.reportingDate,
          value: this.reportObj.currentReserves
        });
      }

      this.reportObj.currentReserveHistory = response.currentReserveHistory;
      this.reportObj.mileStonesValues = response.mileStonesValues;
      this.reportObj.risks = response.risks;
      this.reportObj.totalExposure = this.totalExposure;
      this.reportObj.costTypeTable = response.costTypeTable;
      this.reportObj.startDate = response.startDate;
      this.reportObj.endDate = response.endDate;
      this.reportObj.projectManager = response.projectManager;
      this.reportObj.currentBudgetVSForecast = this.currentBudgetVSForecast;
      this.reportObj.plannedDateVSForecastDate = this.projectDetailObj.plannedDateVSForecastDate;
      this.reportObj.riskExposureVsCurrentBudget = this.riskExposureVsCurrentBudget;
      if (response.evaCondition == undefined) {
        this.reportObj.evaCondition = 'No';
      }
      else {
        this.reportObj.evaCondition = response.evaCondition;
      }
      if (response.bkwShare == undefined) {
        this.reportObj.bkwShare = 100;
      }
      else {
        this.reportObj.bkwShare = response.bkwShare;
      }

      if (response.businessArea != undefined) {
        this.reportObj.businessArea = response.businessArea.name;
      }
      if (response.strategicContribution != undefined) {
        this.reportObj.strategicContribution = response.strategicContribution.name;
      }
      this.reportObj.projectPhase = response.projectPhase;
      if (response.feasibility != undefined) {
        this.reportObj.feasibility = response.feasibility.name;
      }
      if (response.profitability != undefined) {
        this.reportObj.profitability = response.profitability.name;
      }
      this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete).toFixed(0);
      this.reportObj.submittedDate = new Date().toISOString();
      this.reportObj.forecastEndDate = this.projectDetailObj.forecastEndDate;

      this.apiService.post('/history', {
        date: Date.now(),
        project: response.project.id,
        document: 'Status Report',
        status: this.reportObj.overallStatus
      }).subscribe(historyResponse => {
        if (this.reportObj.id == undefined && this.isNew == true) {
          this.reportObj.projectReport = this.projectReportId;

          this.apiService.post('/statusReports', this.reportObj).subscribe(reportObj => {
            if (obj.id) {
              this.apiService.put('/reports/' + obj.id, obj).subscribe(res => {
                this.showNotification('Submit Success', 'Status Report has been created.', 'success');
                this.spinner.hide();
                this.router.navigate([`projects/detail/${this.projectReportId}/statusReport`]);
              });
            } else {
              this.showNotification('Submit Success', 'Status Report has been created.', 'success');
              this.spinner.hide();
              this.router.navigate([`projects/detail/${this.projectReportId}/statusReport`]);
            }
          });
        } else {
          this.apiService.put('/statusReports/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
            if (obj.id) {
              this.apiService.put('/reports/' + obj.id, obj).subscribe(res => {
                this.showNotification('Submit Success', 'Status Report has been created.', 'success');
                this.spinner.hide();
                this.router.navigate([`projects/detail/${this.reportObj.projectReport.id ? this.reportObj.projectReport.id : this.reportObj.projectReport}/statusReport`]);
              });
            } else {
              this.showNotification('Submit Success', 'Status Report has been created.', 'success');
              this.spinner.hide();
              this.router.navigate([`projects/detail/${this.reportObj.projectReport.id}/statusReport`]);
            }
          });
        }
      });
    });
  }

  save() {
    this.reportObj.status = 'Draft';
    this.reportObj.isSubmitted = false;
    this.reportObj.projectReport = this.projectReportId;
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);
    this.calculateVarianceValues(this.projectDetailObj);
    this.calculateRiskExposure();

    let id = this.projectReportId ? this.projectReportId : this.reportObj.projectReportId;
    this.apiService.get('/reports/' + id).subscribe((response: any) => {
      this.reportObj.forecastEndDate = this.projectDetailObj.forecastEndDate;
      this.reportObj.EVA = response.EVA;
      if (this.projectReportId != undefined) {
        this.reportObj.projectReportId = this.projectReportId;
      }

      let obj: any = {};
      let flag = false;
      if (response.mileStonesValues) {
        if (Object.keys(response.mileStonesValues).length > 0) {
          obj = this.parseMilestoneValues(response);
          flag = true;
        }
      }
      delete (obj.statusReports);
      this.reportObj.MTAChart = [];
      if (obj.milestoneTable) {
        let milestonesCopy = JSON.parse(JSON.stringify(obj.milestoneTable));

        if (obj.milestoneTable) {
          for (let x = obj.milestoneTable.length - obj.milestoneTable.length >= 7 ? obj.milestoneTable.length - 8 : 0; x <= obj.milestoneTable.length - 1; x++) {
            if (obj.milestoneTable[x] != null) {
              this.reportObj.MTAChart.push(milestonesCopy[x]);
            }
          }
        }
      }

      // Delete milestone not relevant to report
      if (response.mileStonesValues) {
        for (let i = 0; i < response.mileStonesValues.length; i++) {
          if (response.mileStonesValues[i].report == false) {
            this.reportObj.MTAChart.forEach((val, index) => {
              delete (this.reportObj.MTAChart[index][`milestone${i + 1}`]);
            });
          }
        }
        this.reportObj.mileStonesValues = response.mileStonesValues;
      }

      this.reportObj.risks = response.risks;
      this.reportObj.costTypeTable = response.costTypeTable;
      this.reportObj.startDate = response.startDate;
      this.reportObj.endDate = response.endDate;
      this.reportObj.totalExposure = this.totalExposure;
      this.reportObj.projectManager = response.projectManager;
      this.reportObj.currentBudgetVSForecast = this.currentBudgetVSForecast;
      this.reportObj.plannedDateVSForecastDate = this.projectDetailObj.plannedDateVSForecastDate;
      this.reportObj.riskExposureVsCurrentBudget = this.riskExposureVsCurrentBudget;
      if (response.evaCondition == undefined) {
        this.reportObj.evaCondition = 'No';
      }
      else {
        this.reportObj.evaCondition = response.evaCondition;
      }
      if (response.bkwShare == undefined) {
        this.reportObj.bkwShare = 100;
      }
      else {
        this.reportObj.bkwShare = response.bkwShare;
      }

      if (response.businessArea != undefined) {
        this.reportObj.businessArea = response.businessArea.name;
      }
      if (response.strategicContribution != undefined) {
        this.reportObj.strategicContribution = response.strategicContribution.name;
      }
      this.reportObj.projectPhase = response.projectPhase;
      if (response.feasibility != undefined) {
        this.reportObj.feasibility = response.feasibility.name;
      }
      if (response.profitability != undefined) {
        this.reportObj.profitability = response.profitability.name;
      }
      this.saveHelper();
    });
  }

  saveReport() {
    this.spinner.show();
    this.projectDetailObj.hasDraftReport = true;
    this.apiService.put('/reports/' + this.projectReportId, this.projectDetailObj).subscribe(response => {
      this.save();
    });
  }

  saveHelper() {
    if (this.reportObj.id != undefined && this.isNew == false) {
      this.apiService.put('/statusReports/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.showNotification('Success', 'Status Report has been saved.', 'success');
        this.spinner.hide();
      });
    } else {
      if (this.isPrev == true) {
        delete (this.reportObj.id);
        delete (this.reportObj.submittedDate);
      }
      this.reportObj.projectReport = this.projectReportId;
      this.apiService.post('/statusReports', this.reportObj).subscribe((reportObj: any) => {
        this.reportObj.id = reportObj.id;
        this.showNotification('Success', 'Status Report has been saved.', 'success');
        this.spinner.hide();
      });
    }
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  parseMilestoneValues(response): Object {
    let ms = JSON.parse(JSON.stringify(response.mileStonesValues));

    if (this.lastStatusReport != undefined) {
      if (this.lastStatusReport.mileStonesValues != undefined) {
        if (this.lastStatusReport.mileStonesValues.length > 0) {
          for (let i = 0; i < this.lastStatusReport.mileStonesValues.length; i++) {
            let objArr = ms.find(val => val.id == this.lastStatusReport.mileStonesValues[i].id);
            let index = ms.indexOf(objArr);

            if (objArr != undefined) {
              if (this.lastStatusReport.mileStonesValues[i].status == 'Completed' &&
                objArr.id == this.lastStatusReport.mileStonesValues[i].id) {
                ms.splice(index, 1);
              }
            }
          }
        }
      }
    }

    let obj: any = {};
    obj.reportingDate = new Date(this.reportObj.reportingDate);

    let index = 0;
    ms.map(mile => {
      // if (mile.report == true) {
      obj['milestone' + (mile.id)] = mile.dueDate;     // index + 1
      index++;
      // }
    });

    if (response.milestoneTable != undefined) {
      response.milestoneTable.push(obj);
    } else {
      response.milestoneTable = [obj];
    }
    return response;
  }

  format(value) {
    return value + "%";
  }

  confirmation() {

    let msg = 'Changes are not possible once submitted. Are you sure you want to submit the report.';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.submit();
      }
    });
  }

  onCellPreparedFinancials(e) {
    if (e.columnIndex > 3) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.columnIndex == 2 && e.rowIndex == 8) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onCellPreparedImportedFinancials(e) {
    if (e.columnIndex > 1) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingCostTypeTable(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
      // e.editorOptions.placeholder = "0.00 CHF";

      if (e.row.rowIndex == 8 && e.caption == 'Actual Cost') {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateActualCostValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.projectDetailObj.costTypeTable[6].actualCost = globalThis.projectCostsValueCalculator('actualCost');
      globalThis.calculateVariance();
      return globalThis.projectDetailObj.costTypeTable[6].actualCost;
    } else {
      return e.actualCost ? e.actualCost : '';
    }
  }

  calculateForecastValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.projectDetailObj.costTypeTable[6].forecast = globalThis.projectCostsValueCalculator('forecast');
      return globalThis.projectDetailObj.costTypeTable[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  calculateCurrentBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.projectDetailObj.costTypeTable[6].currentBudget = globalThis.projectCostsValueCalculator('currentBudget');
      return globalThis.projectDetailObj.costTypeTable[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  calculateOriginalBudgetValue(e) {
    if (e.costTypes == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costTypes == "Total") {
      globalThis.calculateVariance();
      globalThis.projectDetailObj.costTypeTable[6].originalBudget = globalThis.projectCostsValueCalculator('originalBudget');
      return globalThis.projectDetailObj.costTypeTable[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  onRowPreparedFinancials(e) {
    if (e.rowIndex > 8) {
      e.rowElement.style.backgroundColor = "#ddd";
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.projectDetailObj.costTypeTable[0][field] * 1;
    let two: any = globalThis.projectDetailObj.costTypeTable[1][field] * 1;
    let three: any = globalThis.projectDetailObj.costTypeTable[2][field] * 1;
    let four: any = globalThis.projectDetailObj.costTypeTable[3][field] * 1;
    let five: any = globalThis.projectDetailObj.costTypeTable[4][field] * 1;
    let six: any = globalThis.projectDetailObj.costTypeTable[5][field] * 1;
    let total = one + two + three + four - five + six;
    globalThis.projectDetailObj.costTypeTable[6][field] = total;
    return total;
  }

  importedCostsValueCalculator(field) {
    let one: any = globalThis.actualCostImported.cost[0][field] * 1;
    let two: any = globalThis.actualCostImported.cost[1][field] * 1;
    let three: any = globalThis.actualCostImported.cost[2][field] * 1;
    let four: any = globalThis.actualCostImported.cost[3][field] * 1;
    let five: any = globalThis.actualCostImported.cost[4][field] * 1;
    let six: any = globalThis.actualCostImported.cost[5][field] * 1;

    let total = one + two + three + four + five + six;
    globalThis.actualCostImported.cost[6][field] = total.toFixed(2);;
    return total.toFixed(2);;
  }

  calculateImportedActualCostValue(e) {
     if (e.costType == "Total") {
      globalThis.actualCostImported.cost[6].cost = globalThis.importedCostsValueCalculator('cost');
      return globalThis.actualCostImported.cost[6].cost;
    } else {
      return e.cost ? e.cost : '';
    }
  }

  calculateVariance() {
    if (this.projectDetailObj.costTypeTable[6].originalBudget * 1 != 0) {
      this.projectDetailObj.currentBudgetVSOriginalBudget = (((this.projectDetailObj.costTypeTable[6].currentBudget * 1) - (this.projectDetailObj.costTypeTable[6].originalBudget * 1)) / (this.projectDetailObj.costTypeTable[6].originalBudget * 1) * 100).toFixed(2);
    }
    if (this.projectDetailObj.costTypeTable[6].currentBudget * 1 != 0) {
      this.projectDetailObj.currentBudgetVSActualCost = (((this.projectDetailObj.costTypeTable[6].currentBudget * 1) - (this.projectDetailObj.costTypeTable[6].actualCost * 1)) / (this.projectDetailObj.costTypeTable[6].currentBudget * 1) * 100).toFixed(2);
      this.projectDetailObj.currentBudgetVSForecast = (((this.projectDetailObj.costTypeTable[6].forecast * 1) - (this.projectDetailObj.costTypeTable[6].currentBudget * 1)) / (this.projectDetailObj.costTypeTable[6].currentBudget * 1) * 100).toFixed(2);
    }
    this.currentBudgetVSOriginalBudget = this.projectDetailObj.currentBudgetVSOriginalBudget ? this.projectDetailObj.currentBudgetVSOriginalBudget + '%' : '0%';
    this.currentBudgetVSActualCost = this.projectDetailObj.currentBudgetVSActualCost ? this.projectDetailObj.currentBudgetVSActualCost + '%' : '0%';
    this.currentBudgetVSForecast = this.projectDetailObj.currentBudgetVSForecast ? this.projectDetailObj.currentBudgetVSForecast + '%' : '0%';
  }

  forecastValueChange(e) {
    this.calculateDatePercentages();
    this.calculateTimeStatus();
  }

  calculateDatePercentages() {
    let endDate: any = moment(this.projectDetailObj.endDate);
    let startDate: any = moment(this.projectDetailObj.startDate);
    let plannedDate: any = moment(this.projectDetailObj.plannedEndDate);
    let forecastDate: any = moment(this.projectDetailObj.forecastEndDate);
    let now = moment(Date.now());

    this.totalTime = plannedDate.diff(startDate, 'days') + ' days';
    this.timeCompleted = Math.abs(100 - (Math.round((plannedDate.diff(startDate, 'days') - now.diff(startDate, 'days')) / plannedDate.diff(startDate, 'days') * 100))) + '%';
    this.endDateVSPlannedEndDate = Math.round((plannedDate.diff(startDate, 'days') - endDate.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100) + '%';
    if (this.projectDetailObj.forecastEndDate != undefined) {
      this.projectDetailObj.plannedDateVSForecastDate = Math.round((plannedDate.diff(forecastDate, 'days') / plannedDate.diff(startDate, 'days')) * -100) + '%';
    }
  }

  projectPhaseValueChanged(e) {
    if (this.projectPhaseOptions != undefined) {
      this.projectPhaseOptions.values.forEach(response => {
        if (response != undefined) {
          if (response.id == e.value) {
            this.projectDetailObj.projectPhase = response;
          }
        }
      });
    }
  }

  updateTotalExposure(e) {
    this.projectDetailObj.risks = e.risks;
    this.totalExposure = 0;

    this.projectDetailObj.risks.forEach(val => {
      this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
    });
    this.riskExposureVsCurrentBudget = ((this.totalExposure / this.projectDetailObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
  }

  updateMTAGrid(e) {
    this.projectDetailObj.mileStonesValues = e.mileStonesValues;
  }

  updateMeasureGrid(e) {
    this.projectDetailObj.measures = e.measures;
  }

  updateDecisionGrid(e) {
    this.projectDetailObj.decisions = e.decisions;
  }

  updateMTAGridDeletionCase(e: any) {
    this.projectDetailObj.mileStonesValues = e.mileStonesValues;

    if (this.projectDetailObj.milestoneTable.length > 0) {
      for (let i = 0; i < this.projectDetailObj.milestoneTable.length; i++) {
        for (let j = e.index; j < this.projectDetailObj.milestoneTable.length; j++) {
          if ((j + 2) <= this.projectDetailObj.milestoneTable.length) {
            this.projectDetailObj.milestoneTable[i]['milestone' + (j + 1)] = this.projectDetailObj.milestoneTable[i]['milestone' + (j + 2)];
          }
        }
      }
    }
  }

  openPreview() {
    this.statusReportPreview = this.reportObj;
    this.prevStatusReport = this.lastStatusReport;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  calculateRiskExposure() {
    this.totalExposure = 0;
    this.projectDetailObj.risks.forEach(val => {
      this.totalExposure += val.riskExposure == "" ? 0 : val.riskExposure;
    });
    this.riskExposureVsCurrentBudget = ((this.totalExposure / this.projectDetailObj.costTypeTable[6].currentBudget) * 100).toFixed(2) + '%';
  }

  backToProjectDetail() {
    this.router.navigate([`projects/detail/${this.projectReportId}/statusReport`]);
  }

  onKeyManagementSummaryInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 68) {
        e.preventDefault();
        this.managementSummaryLimit = true;
      } else {
        this.managementSummaryLimit = false;
      }
    } else {
      this.managementSummaryLimit = false;
    }
  }

  onKeyScopeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.scopeCommentsLimit = true;
      } else {
        this.scopeCommentsLimit = false;
      }
    } else {
      this.scopeCommentsLimit = false;
    }
  }

  onKeyCostStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.costCommentsLimit = true;
      } else {
        this.costCommentsLimit = false;
      }
    } else {
      this.costCommentsLimit = false;
    }
  }

  onKeyTimeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.timeCommentsLimit = true;
      } else {
        this.timeCommentsLimit = false;
      }
    } else {
      this.timeCommentsLimit = false;
    }
  }

  onKeyPMOCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 68) {
        e.preventDefault();
        this.pmoCommentsLimit = true;
      } else {
        this.pmoCommentsLimit = false;
      }
    } else {
      this.pmoCommentsLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  onKeyDown(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }
}
