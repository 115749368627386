import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import { ApiService } from 'app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
declare var html2canvas: any;
declare var $: any;
let globalThis;

@Component({
  selector: 'app-pdf-closing-report',
  templateUrl: './pdf-closing-report.component.html',
  styleUrls: ['./pdf-closing-report.component.scss']
})
export class PdfClosingReportComponent implements OnInit {
  @Input() public closingReport;

  datePercentage;
  costDateList = [];
  showLoading: boolean = false;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private authService: AuthService, private apiService: ApiService, private spinner: NgxSpinnerService, private localize: I18nPipe, private utilityService: UtilityService) {
    globalThis = this;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
  }

  ngOnInit() {
    if (this.closingReport.project.projectReport.startDate && this.closingReport.plannedEndDate && this.closingReport.endDateOnSubmit) {
      let plannedEndDate: any = moment(this.closingReport.plannedEndDate);
      let startDate: any = moment(this.closingReport.project.projectReport.startDate);
      let endDateOnSubmit: any = moment(this.closingReport.endDateOnSubmit);
      this.datePercentage = Math.round((plannedEndDate.diff(startDate, 'days') - endDateOnSubmit.diff(startDate, 'days')) /
        endDateOnSubmit.diff(startDate, 'days') * 100) + '%';
    }

    if (this.closingReport.plannedEndDate && this.closingReport.endDateOnSubmit) {
      let obj1 = { date: moment(new Date(this.closingReport.plannedEndDate)).format('DD. MMM YYYY'), value: this.closingReport.costDeviations[6].should };
      let obj2 = { date: moment(this.closingReport.endDateOnSubmit).format('DD. MMM YYYY'), value: this.closingReport.costDeviations[6].is }
      this.costDateList[0] = { ...obj1 };
      this.costDateList[1] = { ...obj2 };
    }
  }


  saveComments(obj, elem) {
    if (this.closingReport && this.closingReport.closingReportId) {
      this.spinner.show();
      this.apiService.put('/closingReport/' + this.closingReport.closingReportId, obj).subscribe(response => {
        this.notification("Success", "Comment Updated", "success");
        this.spinner.hide();
        elem.instance.hide();
      });
    } else {
      elem.instance.hide();
      this.notification("Failed", "Save your Closing Report first to add comments", "warning");
    }
  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  download() {
    let imgWidth = 210;
    let pageHeight = 295; //295
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4'); //[200, 800]
    let element = $('#closingReportPDF');
    let downloadElem = element.find('#download')[0];
    downloadElem.remove();

    let copylinkElem = element.find('#copylink')[0];
    if (copylinkElem) {
      copylinkElem.remove();
    }

    this.showLoading = true;

    html2canvas(element[0], { scale: 2.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      var image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 1, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 1, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // position = heightLeft - imgHeight;
      // pdf.addPage();
      // pdf.addImage(image, 'PNG', 0, position + 7, imgWidth, imgHeight - 10);
      // heightLeft -= pageHeight;

      pdf.save('Closing Report.pdf');
      globalThis.showLoading = false;
      element.children().children().append(downloadElem);
      if (copylinkElem) {
        element.children().children().append(copylinkElem);
      }
    });
  }
}