import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FileUploader } from "ng2-file-upload";
import { locale } from "devextreme/localization";
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import 'rxjs/operator/retry';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService } from 'ngx-bootstrap';
import { SocketService } from 'app/services/socket.service';
declare var $: any;

var globalThis;

@Component({
  selector: 'app-project-outline',
  templateUrl: './project-outline.component.html',
  styleUrls: ['./project-outline.component.scss'],
})
export class ProjectOutlineComponent implements OnInit, OnDestroy {
  projectObj;
  projSponsorEmail;
  maxDate = new Date(2029, 11, 31);
  currentDate: any = `${new Date().getUTCDate().toString()}/${new Date().getMonth().toString()}/${new Date().getFullYear().toString()}`;
  formObject: any = {
    projectManager: {
      email: ""
    },
    program: {
      id: ""
    }
  };
  mandatoryProjectOptions;
  businessUnitOptions;
  businessAreaOptions;
  businessSegmentOptions;
  reportingLevelOptions;
  classificationOptions;
  projectTypeOptions;
  programOptions;
  strategicContributionOptions;
  feasibilityOptions;
  profitabilityOptions;
  fundsApprovedForInitiationTableData;
  projectManagerInOptions;
  projectSponsorApprovalOptions;
  PMOsList = [];
  usersList = [];
  showLoader;
  attachment1;
  attachment2;
  @ViewChild("lgModal", { static: false }) lgModal;
  @ViewChild("popUp", { static: false }) popUp;
  @ViewChild("goalsPopUp", { static: false }) goalsPopUp;
  @ViewChild("deliverablePopUp", { static: false }) deliverablePopUp;
  @ViewChild("outOfScopePopUp", { static: false }) outOfScopePopUp;
  @ViewChild("riskPopUp", { static: false }) riskPopUp;
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public submitFlag = false;
  public saveFlag = false;
  eventType = "";
  showOutlinePreview = false;
  showNotification = false;
  outlineHolderProjectId = '';
  projectSponsor;
  currentEditingCellRowIndex;
  public benefitForm: any = {
    description: '',
    report: false
  };
  benefitTypeSelectOptions = [];
  reportRelevantBenefitCount = 0;
  backupBenefitForm;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isEditBenefit = false;
  popUpConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  minimumDate = '';
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  goalsForm: any = {
    id: '',
    description: '',
  };
  backupGoalsForm;
  isEditGoal: boolean = false;
  riskForm: any = {
    id: '',
    description: ''
  };
  isEditRisk: boolean = false;
  backupRiskForm;
  isEditDeliverable: boolean = false;
  deliverableForm: any = {
    id: '',
    description: '',
  };
  backupDeliverableForm;
  isEditOutOfScope: boolean = false;
  outOfScopeForm: any = {
    id: '',
    description: '',
  };
  backupOutOfScopeForm;
  currencyOptions;
  questionOptions;
  startDateChangeFlag = false;
  startOfProjectOrderChangeFlag = false;
  initiationApprovalDateMinimumDate;
  @ViewChild('form', { static: false }) form: NgForm;
  outlineQuestions: any = [{}, {}, {}, {}, {}, {}, {}];
  outlineHelpGuide: any = [];
  isFormSaveSubmit: boolean = false;
  currentSituationLimit: boolean = false;
  additionalInfoLimit: boolean = false;
  portfolioList = [];
  subPortfolioList = [];
  itPlatformOptions;
  itRelevantOptions;
  projectMethodologyOptions;
  confidentialOptions;
  isTeamProject = false;
  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  isOrderStartDateValid: boolean = true;
  isOrderEndDateValid: boolean = true;
  fetchType = 'get';
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeViewObj;
  isStepValidated = [true, true, true, true];
  isSavedPressed: boolean = false;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';
  groups = [];
  groupsHolder = [];

  constructor(private apiService: ApiService,
    public router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private modalService: BsModalService,
    private localize: I18nPipe,
    private spinner: NgxSpinnerService,
    private socketService: SocketService) {
    this.utilityService.breadcrumb = "Project Outline";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    globalThis = this;

    this.socketService.listen('activePortfolios').subscribe(response => {
      this.portfolioList = response;
    });

    this.formObject = new OutlineForm();
    this.formObject.quantitativeBenefit = [
      {
        id: 1,
        description: ""
      },
      {
        id: 2,
        description: ""
      },
      {
        id: 3,
        description: ""
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];
    this.formObject.projectManager = {
      email: ""
    };
    this.formObject.mandatoryProject = {
      id: ""
    };
    this.formObject.portfolio = {
      id: ""
    }
    this.formObject.subPortfolio = {
      id: ""
    }
    this.formObject.businessArea = {
      id: ""
    };
    this.formObject.businessUnit = {
      id: ""
    };
    this.formObject.businessSegment = {
      id: ""
    };
    this.formObject.reportingLevel = {
      id: ""
    };
    this.formObject.classification = {
      id: ""
    };
    this.formObject.projectType = {
      id: ""
    };
    this.formObject.strategicContribution = {
      id: ""
    };
    this.formObject.profitability = {
      id: ""
    };
    this.formObject.feasibility = {
      id: ""
    };
    this.formObject.program = {
      id: ""
    };
    this.formObject.projectSponsor = {
      id: ""
    };
    this.formObject.pmoOfficer = {
      id: ""
    };
    this.formObject.itPlatform = [];
    this.formObject.itRelevant = {
      id: ""
    };
    this.formObject.projectMethodology = {
      id: ""
    };

    this.formObject.isAttachement1Added = false;
    this.formObject.isAttachement2Added = false;
    this.formObject.question = ['', '', '', '', '', '', ''];

    locale("de");

    this.socketService.listen('usersFromRoles').subscribe(usersList => {
      this.utilityService.PMOsList = usersList;
      this.PMOsList = this.utilityService.PMOsList;
    });

    this.socketService.listen('fetchProjectOutline').subscribe(response => {
      if (this.fetchType == 'get') {
        this.projectObj = response.project;
        this.outlineHolderProjectId = response.project.id;
        this.projectObj.projectOutline[0] = response.projectOutline;
        this.setData();
        this.spinner.hide();
      } else if (this.fetchType == 'save') {
        this.notificationBox("Success", "Outline has been saved.", "success");
        this.formObject.id = response.project.id; //projectResponse.id;
        this.outlineHolderProjectId = response.project.id;
        this.projectObj = response.project;
        this.projectObj.projectOutline[0] = response.projectOutline;
        this.setData();
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();

        let endDate = this.formObject.endDate;
        setTimeout(() => {
          this.formObject.endDate = endDate;
        }, 100);
      }
    });

    this.socketService.listen('activePrograms').subscribe(response => {
      this.programOptions = response;
    });

    this.fetchPMOs();
    this.fetchUsers();
    this.getProjectInfo();
    this.fetchPrograms();
    this.selectFile();
    this.selectFile1();

    this.formObject.risksTableData = [
      {
        id: 1,
        description: ""
      },
      {
        id: 2,
        description: ""
      },
      {
        id: 3,
        description: ""
      },
      {
        id: 4,
        description: ""
      },
      {
        id: 5,
        description: ""
      }
    ];

    this.formObject.kpiTableData = [
      {
        description: "Cost Savings",
        value: "",
        type: "Per Year"
      },
      {
        description: "NPV",
        value: "",
        type: ""
      },
      {
        description: "Additional Revenues",
        value: "",
        type: "Per Year"
      },
      {
        description: "Payback duration (Discounted)",
        value: "",
        type: "Years"
      },
      {
        description: "IRR",
        value: "",
        type: "%"
      }
    ];

    this.formObject.estimatedProjectCostTableData = [
      {
        costTypes: "External Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.involvedPartnersTable = [
      {
        totalManDays: "",
        externalPartners: ""
      },
      {
        totalManDays: "",
        externalPartners: ""
      },
      {
        totalManDays: "",
        externalPartners: ""
      },
      {
        totalManDays: "",
        externalPartners: ""
      },
      {
        totalManDays: "",
        externalPartners: ""
      }
    ];

    this.formObject.fundsApprovedForInitiationTableData = [
      {
        costTypes: "External Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.deliverables = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.outOfScope = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.deliverablesInitiationPhase = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.resourceInitiationPhase = [
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      }
    ];
  }
 
  ngOnInit() {

    this.spinner.show();

    this.projectManagerInOptions = ["PMO", "Customer", "FIKO"];
    this.currencyOptions = ['CHF', 'EUR', 'USD'];
    this.confidentialOptions = ['Yes', 'No'];
    this.benefitTypeSelectOptions = ['Quantitative', 'Qualitative'];
    this.questionOptions = ["Yes", "No"];
    this.mandatoryProjectOptions = this.utilityService.mandatoryProjectOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessSegmentOptions = this.utilityService.businessSegmentOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.strategicContributionOptions = this.utilityService.strategicContributionOptions;
    this.feasibilityOptions = this.utilityService.feasibilityOptions;
    this.profitabilityOptions = this.utilityService.profitabilityOptions;
    this.itPlatformOptions = this.utilityService.itPlatformOptions;
    this.itRelevantOptions = this.utilityService.itRelevantOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;
    this.benefitTypeSelectOptions = ['Quantitative', 'Qualitative'];
    this.questionOptions = ["Yes", "No"];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.socketService.listen('questionsByFormName').subscribe(response => {
      for (let i = 0; i < response.questions.length; i++) {
        if (response.questions[i].department == 'Compliance') {
          this.outlineQuestions[4] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling') {
          this.outlineQuestions[0] = response.questions[i];
        }
        if (response.questions[i].department == 'Human Resources') {
          this.outlineQuestions[1] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 1) {
          this.outlineQuestions[2] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 2) {
          this.outlineQuestions[3] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement' && response.questions[i].count == 1) {
          this.outlineQuestions[5] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement' && response.questions[i].count == 2) {
          this.outlineQuestions[6] = response.questions[i];
        }
      }
    });

    this.socketService.listen('helpGuideByFormName').subscribe(response => {
      this.outlineHelpGuide = response.fields;
    });

    this.getOulineQuestions();
    this.getOutlineHelpGuide();
  }


  getOulineQuestions(): void {
    this.socketService.emitEvent('questionsByFormName', { formName: 'outline' });
  }

  getOutlineHelpGuide(): void {
    this.socketService.emitEvent('helpGuideByFormName', { formName: 'outline' });
  }

  public steps = [
    {
      key: "step1",
      title: "Description",
      valid: false,
      checked: false,
      submitted: false
    },
    {
      key: "step2",
      title: "Benefits & Resources",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step3",
      title: "Categories",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step4",
      title: "Submit Request",
      valid: true,
      checked: false,
      submitted: false
    }
  ];

  public activeStep = this.steps[0];

  setActiveStep(step) {
    this.activeStep = step;
  }

  prevStep() {
    let idx = this.steps.indexOf(this.activeStep);
    if (idx > 0) {
      this.activeStep = this.steps[idx - 1];
    }
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  nextStep() {
    this.activeStep = this.steps[this.steps.indexOf(this.activeStep) + 1];
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  // custom change detection
  ngDoCheck() {
  }

  calculateResourcesTotalValue(e) {
    if (e.costTypes == "Total") {
      globalThis.formObject.estimatedProjectCostTableData[4].total =
        globalThis.projectCostsValueCalculator("capex") +
        globalThis.projectCostsValueCalculator("opex");
      globalThis.formObject.estimatedProjectCostTableData[4].opex = globalThis.projectCostsValueCalculator("opex");
      globalThis.formObject.estimatedProjectCostTableData[4].capex = globalThis.projectCostsValueCalculator("capex");
      return globalThis.formObject.estimatedProjectCostTableData[4].total;
    } else if (e.costTypes == "Revenues") {
      globalThis.formObject.estimatedProjectCostTableData[2].total =
        Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
      return Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
    } else {
      if (e.costTypes == "External Costs") {
        globalThis.formObject.estimatedProjectCostTableData[0].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Internal Costs") {
        globalThis.formObject.estimatedProjectCostTableData[1].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Reserve") {
        globalThis.formObject.estimatedProjectCostTableData[3].total =
          e.capex * 1 + e.opex * 1;
      }
      return e.capex * 1 + e.opex * 1;
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.formObject.estimatedProjectCostTableData[0][field] * 1;
    let two: any = globalThis.formObject.estimatedProjectCostTableData[1][field] * 1;
    let three: any = globalThis.formObject.estimatedProjectCostTableData[2][field] * 1;
    let four: any = globalThis.formObject.estimatedProjectCostTableData[3][field] * 1;
    let five: any = globalThis.formObject.estimatedProjectCostTableData[4][field] * 1;
    let six: any = globalThis.formObject.estimatedProjectCostTableData[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.estimatedProjectCostTableData[6][field] = total;
    return total;
  }

  calculateResourcesTotalValueForCapex(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? Math.abs(e.budget) * -1 : "";
    } else {
      return e.budget;
    }
  }

  calculateResourcesTotalValueForOpex(e) {
    if (e.costTypes == "Total (CHF)") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("opex");
      }
    } else if (e.costTypes == "Revenues") {
      return e.opex ? Math.abs(e.opex) * -1 : "";
    } else {
      return e.opex ? e.opex : "";
    }
  }

  calculateTotalValueFundsApprovedTable(e) {
    if (e.costTypes == "Total") {
      globalThis.formObject.fundsApprovedForInitiationTableData[4].total =
        globalThis.initiationApprovedCostsValueCalculator("capex") +
        globalThis.initiationApprovedCostsValueCalculator("opex");
      globalThis.formObject.fundsApprovedForInitiationTableData[4].opex = globalThis.initiationApprovedCostsValueCalculator(
        "opex"
      );
      globalThis.formObject.fundsApprovedForInitiationTableData[4].capex = globalThis.initiationApprovedCostsValueCalculator(
        "capex"
      );
      return globalThis.formObject.fundsApprovedForInitiationTableData[4].total;
    } else if (e.costTypes == "Revenues") {
      globalThis.formObject.fundsApprovedForInitiationTableData[2].total =
        Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
      return Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
    } else {
      if (e.costTypes == "External Costs") {
        globalThis.formObject.fundsApprovedForInitiationTableData[0].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Internal Costs") {
        globalThis.formObject.fundsApprovedForInitiationTableData[1].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Reserve") {
        globalThis.formObject.fundsApprovedForInitiationTableData[3].total =
          e.capex * 1 + e.opex * 1;
      }
      return e.capex * 1 + e.opex * 1;
    }
  }

  onEditorPreparingFundsApprovedTable(e) {
    if (e.parentType == "dataRow") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  fetchPMOs() {
    if (this.utilityService.PMOsList.length == 0) {
      this.socketService.emitEvent('usersFromRoles', { role: 'PMO' });
    } else {
      this.PMOsList = this.utilityService.PMOsList;
    }
  }

  fetchUsers() {
    // this.apiService.get("/user").subscribe(usersList => {
    this.usersList = this.utilityService.usersList;
    // });
  }

  startDateValueChanged(e) {
    if (this.startDateChangeFlag == true) {
      this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
      this.formObject.endDate = this.minimumDate;
    } else {
      this.startDateChangeFlag = true;
      if (this.formObject.id == undefined) {
        this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
        this.formObject.endDate = this.minimumDate;
      }
    }

  }

  endDateValueChanged(e) {
    this.formObject.endDate = e.value;
  }

  currentSituationChange(event) {
    console.log(event);
  }

  onEditorPreparingEstimatedProjectCostsTable(e) {
    if (e.parentType == "dataRow") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  onEditorPreparingInvolvedPartnersTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "externalPartners") {
      e.editorOptions.maxLength = 20;
      e.editorOptions.placeholder = this.localize.transform("Involved Partners");
    }
    if (e.parentType == "dataRow" && e.dataField == "totalManDays") {
      e.editorOptions.placeholder = this.localize.transform("Person-Days");
    }
  }

  onEditorPreparingKPIsTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Period") {
        e.editorOptions.placeholder = this.localize.transform("Payback Period");
      }
    }
  }

  initiationApprovalDateValueChanged(e) {
    this.formObject.initiationApprovalDate = e.value;
  }

  customRisks(cellinfo) {
    if (cellinfo.value) {
      return cellinfo.value;
    } else {
      return "Describe your risk here";
    }
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      if (this.utilityService.isTeamProject == true) {
        this.isTeamProject = true;
        this.utilityService.isTeamProject = false;
      }

      this.socketService.emitEvent('activePortfolios', {});

      if (params.id != undefined) {
        this.utilityService.breadcrumbList = ['My Projects', 'Edit Outline'];
        this.socketService.emitEvent('fetchProjectOutline', { id: params.id });
      } else {
        this.utilityService.breadcrumbList = ['My Projects', 'Create Outline'];
        this.formObject.projectManager = this.authService.userObj;
        this.spinner.hide();
      }
    });
  }

  setData() {
    if (this.projectObj.projectOutline[0].mandatoryProject == undefined) {
      this.projectObj.projectOutline[0].mandatoryProject = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].itPlatform == undefined) {
      this.projectObj.projectOutline[0].itPlatform = [];
    }
    if (this.projectObj.projectOutline[0].itRelevant == undefined) {
      this.projectObj.projectOutline[0].itRelevant = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].portfolio == undefined) {
      this.projectObj.projectOutline[0].portfolio = {
        id: ''
      }

    }
    if (this.projectObj.projectOutline[0].subPortfolio == undefined) {
      this.projectObj.projectOutline[0].subPortfolio = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].businessUnit == undefined) {
      this.projectObj.projectOutline[0].businessUnit = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].businessArea == undefined) {
      this.projectObj.projectOutline[0].businessArea = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].businessSegment == undefined) {
      this.projectObj.projectOutline[0].businessSegment = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].reportingLevel == undefined) {
      this.projectObj.projectOutline[0].reportingLevel = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].classification == undefined) {
      this.projectObj.projectOutline[0].classification = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].projectType == undefined) {
      this.projectObj.projectOutline[0].projectType = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].strategicContribution == undefined) {
      this.projectObj.projectOutline[0].strategicContribution = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].feasibility == undefined) {
      this.projectObj.projectOutline[0].feasibility = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].profitability == undefined) {
      this.projectObj.projectOutline[0].profitability = {
        id: ''
      }
    }
    if (this.projectObj.projectOutline[0].projectMethodology == undefined) {
      this.projectObj.projectOutline[0].projectMethodology = {
        id: ''
      }
    }

    this.formObject.id = this.projectObj.id;
    this.formObject.projectName = this.projectObj.projectName;
    this.formObject.projectManager = this.projectObj.projectOutline[0].projectManager;
    this.formObject.projectSponsor = this.projectObj.projectOutline[0].projectSponsor;
    this.formObject.startDate = this.projectObj.projectOutline[0].startDate;

    if (this.formObject.startDate != undefined && this.formObject.startDate != null && this.formObject.startDate != "") {
      this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
      this.formObject.endDate = this.minimumDate;
    }

    this.formObject.endDate = this.projectObj.projectOutline[0].endDate;
    this.formObject.currentSituation = this.projectObj.projectOutline[0].currentSituation;
    this.formObject.goals = this.projectObj.projectOutline[0].goals;
    this.formObject.deliverables = this.projectObj.projectOutline[0].deliverables;
    if(this.projectObj.projectOutline[0].deliverablesInitiationPhase){
      this.formObject.deliverablesInitiationPhase = this.projectObj.projectOutline[0].deliverablesInitiationPhase;
    }
    if(this.projectObj.projectOutline[0].resourceInitiationPhase){
      this.formObject.resourceInitiationPhase = this.projectObj.projectOutline[0].resourceInitiationPhase;
    }
    this.formObject.outOfScope = this.projectObj.projectOutline[0].outOfScope;
    this.formObject.risksTableData = this.projectObj.projectOutline[0].risksTable;
    this.formObject.quantitativeBenefit = this.projectObj.projectOutline[0].quantitativeBenefit;
    this.formObject.kpiTableData = this.projectObj.projectOutline[0].kpisTable;
    this.formObject.currency = this.projectObj.projectOutline[0].currency;
    this.formObject.estimatedProjectCostTableData = this.projectObj.projectOutline[0].estimatedProjectTable;
    this.formObject.involvedPartnersTable = this.projectObj.projectOutline[0].involvedPartnersTable;
    this.formObject.mandatoryProject = this.projectObj.projectOutline[0].mandatoryProject;
    this.formObject.itPlatform = this.projectObj.projectOutline[0].itPlatform;
    this.formObject.itRelevant = this.projectObj.projectOutline[0].itRelevant;
    this.formObject.portfolio = this.projectObj.projectOutline[0].portfolio;
    this.formObject.subPortfolio = this.projectObj.projectOutline[0].subPortfolio;
    this.formObject.businessUnit = this.projectObj.projectOutline[0].businessUnit;
    this.formObject.businessArea = this.projectObj.projectOutline[0].businessArea;
    this.formObject.businessSegment = this.projectObj.projectOutline[0].businessSegment;
    this.formObject.reportingLevel = this.projectObj.projectOutline[0].reportingLevel;
    this.formObject.classification = this.projectObj.projectOutline[0].classification;
    this.formObject.projectType = this.projectObj.projectOutline[0].projectType;
    this.formObject.program = this.projectObj.projectOutline[0].program;
    this.formObject.strategicContribution = this.projectObj.projectOutline[0].strategicContribution;
    this.formObject.feasibility = this.projectObj.projectOutline[0].feasibility;
    this.formObject.profitability = this.projectObj.projectOutline[0].profitability;
    this.formObject.projectMethodology = this.projectObj.projectOutline[0].projectMethodology;
    this.formObject.confidential = this.projectObj.projectOutline[0].confidential;
    this.formObject.comment = this.projectObj.projectOutline[0].comment;
    this.formObject.initiationApproval = this.projectObj.projectOutline[0].initiationApproval;
    this.formObject.fundsApprovedForInitiationTableData = this.projectObj.projectOutline[0].fundsApprovedForInitiationTable;
    this.formObject.additionalInformation = this.projectObj.projectOutline[0].additionalInformation;
    this.formObject.pmoOfficer = this.projectObj.projectOutline[0].pmoOfficer;
    this.formObject.projectSponsor = this.projectObj.projectOutline[0].projectSponsor;
    this.formObject.question = this.projectObj.projectOutline[0].question;
    this.formObject.startOfProjectOrder = this.projectObj.projectOutline[0].startOfProjectOrder;
    setTimeout(()=> {
      this.formObject.portfolioQuestions = this.projectObj.projectOutline[0].portfolioQuestions;
    }, 1000)

    if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
      this.projSponsorEmail = this.formObject.projectSponsor.email;
    }

    if (this.formObject.startOfProjectOrder != undefined && this.formObject.startOfProjectOrder != null && this.formObject.startOfProjectOrder != "") {
      this.initiationApprovalDateMinimumDate = moment(this.formObject.startOfProjectOrder).add('days', 1).format('MMM DD, YYYY');
      this.formObject.initiationApprovalDate = this.initiationApprovalDateMinimumDate;
    }

    this.formObject.initiationApprovalDate = this.projectObj.projectOutline[0].initiationApprovalDate;
    this.formObject.purpose = this.projectObj.projectOutline[0].purpose;
    this.formObject.attachments = this.projectObj.projectOutline[0].attachments;
    let attach1: any = document.getElementById("attact1-span");
    let attach2: any = document.getElementById("attact2-span");
    if (
      this.projectObj.projectOutline[0].attachments[0].originalName != undefined
    ) {
      attach1.value = this.projectObj.projectOutline[0].attachments[0].originalName;
      this.attachment1 = this.projectObj.projectOutline[0].attachments[0];
    }
    if (
      this.projectObj.projectOutline[0].attachments[1].originalName != undefined
    ) {
      attach2.value = this.projectObj.projectOutline[0].attachments[1].originalName;
      this.attachment2 = this.projectObj.projectOutline[0].attachments[1];
    }
  }

  submit(e) {
    this.submitFlag = true;
    this.eventType = "submit";

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.showNotificationBox('Step 1: Please Enter Project Name First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.purpose == '' || this.formObject.purpose == undefined) {
      this.showNotificationBox('Step 1: Please Enter Purpose First.')
      this.submitFlag = false;
      return;
    }
    // let purposeCheck = this.formObject.purpose.filter(val => val.description.length > 0);
    // if (purposeCheck.length == 0) {
    //   this.showNotificationBox('Step 1: Please Enter Purpose First.');
    //   this.submitFlag = false;
    //   return;
    // }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.showNotificationBox('Step 1: Please Select a Project Manager First.');
      this.submitFlag = false;
      return;
    }
    if (!this.isStartDateValid) {
      this.showNotificationBox('Step 1: Please enter start date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.startDate == undefined) {
      this.showNotificationBox('Step 1: Please select a start date first.');
      this.submitFlag = false;
      return;
    }
    if (!this.isEndDateValid) {
      this.showNotificationBox('Step 1: Please enter end date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.endDate == undefined) {
      this.showNotificationBox('Step 1: Please select a end date first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.currentSituation == "") {
      this.showNotificationBox('Step 1: Please fill current situation first.');
      this.submitFlag = false;
      return;
    }
    // let currentSituationCheck = this.formObject.currentSituation.filter(val => val.description.length > 0);
    // if (currentSituationCheck.length == 0) {
    //   this.showNotificationBox('Step 1: Please fill current situation first.');
    //   this.submitFlag = false;
    //   return;
    // }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter goals first.');
      this.submitFlag = false;
      return;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter deliverables first.');
      this.submitFlag = false;
      return;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter out of scope first.');
      this.submitFlag = false;
      return;
    }
    // let risksCheck = this.formObject.risksTableData.filter(val => val.description.length > 0);
    // if (risksCheck.length == 0) {
    //   this.showNotificationBox('Please enter a risk first.');
    //   this.submitFlag = false;
    //   return;
    // }
    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.showNotificationBox('Step 2: Please select a currency first.');
      this.submitFlag = false;
      return;
    }
    // let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
    // if (quantativeBenefitsCheck.length == 0) {
    //   this.showNotificationBox('Please enter qualitative benefits first.');
    //   this.submitFlag = false;
    //   return;
    // }

    // let qualitativeBenefitsCheck = this.formObject.kpiTableData.filter(val => val != null && val.value.length > 0);
    // if (qualitativeBenefitsCheck.length == 0) {
    //   this.showNotificationBox('Please enter quantitative benefits first.');
    //   this.submitFlag = false;
    //   return;
    // }
    if (this.formObject.estimatedProjectCostTableData[6].budget == 0) {
      this.showNotificationBox('Step 2: Please fill estimated project cost.');
      this.submitFlag = false;
      return;
    }
    let involvedPartnersBenefitsCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
    if (involvedPartnersBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter involved partners first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.mandatoryProject.id == "") {
      this.showNotificationBox('Step 3: Please select mandatory project first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.portfolio.id == "") {
      this.showNotificationBox('Step 3: Please select portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.subPortfolio.id == "") {
      this.showNotificationBox('step 3: Please select sub-portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessUnit.id == "") {
      this.showNotificationBox('Step 3: Please select business unit first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessArea.id == "") {
      this.showNotificationBox('Step 3: Please select business area first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessSegment.id == "") {
      this.showNotificationBox('Step 3: Please select business segment first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.reportingLevel.id == "") {
      this.showNotificationBox('Step 3: Please select reporting level first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.classification.id == "") {
      this.showNotificationBox('Step 3: Please select classification first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectType.id == "") {
      this.showNotificationBox('Step 3: Please select project type first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.program.programName != "None") {
      if (this.formObject.program.id == "") {
        this.showNotificationBox('Step 3: Please select program first.');
        this.submitFlag = false;
        return;
      }
    }
    if (this.formObject.strategicContribution.id == "") {
      this.showNotificationBox('Step 3: Please select strategic contribution first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.feasibility.id == "") {
      this.showNotificationBox('Step 3: Please select feasibility first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.profitability.id == "") {
      this.showNotificationBox('Step 3: Please select profitability first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.itRelevant.id == "") {
      this.showNotificationBox('step 3: Please select IT Relevant.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.showNotificationBox('step 3: Please select IT Platform.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectMethodology.id == "") {
      this.showNotificationBox('step 3: Please select Project Methodology.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.showNotificationBox('step 3: Please select confidential option.');
      this.submitFlag = false;
      return;
    }
    if (!this.isOrderStartDateValid) {
      this.showNotificationBox('Step 4: Please enter start of creation project order date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.startOfProjectOrder == undefined) {
      this.showNotificationBox('Step 4: Please select Start of creation of Project Order date first.');
      this.submitFlag = false;
      return;
    }
    if (!this.isOrderEndDateValid) {
      this.showNotificationBox('Step 4: Please enter end of creation project order date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.initiationApprovalDate == undefined) {
      this.showNotificationBox('Step 4: Please select End of creation of project order date first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.startOfProjectOrder != undefined && this.formObject.initiationApprovalDate != undefined) {
      if (moment(this.formObject.startOfProjectOrder) >= moment(this.formObject.initiationApprovalDate)) {
        this.showNotificationBox('Step 4: The date for `End of Creation of Project Order` must be after the date for `Start of Creation of Project Order`.');
        this.submitFlag = false;
        return;
      }
    }
    if (this.formObject.fundsApprovedForInitiationTableData[6].budget == 0) {
      this.showNotificationBox('Step 4: Please fill funds required for initiation table first.');
      this.submitFlag = false;
      return;
    }
    // if (this.formObject.additionalInformation == "") {
    //   this.showNotificationBox('Please fill additional information first.');
    //   this.submitFlag = false;
    //   return;
    // }
    if (this.formObject.portfolioQuestions && this.formObject.portfolioQuestions.length > 0) {
      for (let i = 0; i < this.formObject.portfolioQuestions.length; i++) {
        if (this.formObject.portfolioQuestions[i] == "") {
          this.showNotificationBox('Step 4: Please fill all questions first.');
          this.submitFlag = false;
          return;
        }
      }
    }
    if (this.formObject.question[0] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[1] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[2] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[3] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[4] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[5] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[6] == "") {
      this.showNotificationBox('Step 4: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.pmoOfficer.id == "") {
      this.showNotificationBox('Step 4: Please Select a PMO First.');
      this.submitFlag = false;
      return;
    }

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.showNotificationBox('Step 4: Please enter a valid email address for project sponsor.');
      this.submitFlag = false;
      return;
    }

    if (this.formObject.projectSponsor.id == "") {
      this.showNotificationBox('Step 4: Please Select a Project Sponsor First.');
      this.submitFlag = false;
      return;
    }

    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("submit");
    } else {
      this.submitHelper();
    }
  }

  submitHelper() {
    this.spinner.show();
    let obj = {
      outlineSubmitted: true,
      outlineApproved: false,
      orderSubmitted: false,
      orderApproved: false,
      isSponsorApproved: false,
      projectName: this.formObject.projectName,
      status: "Submitted",
      workflowStatus: "Outline has been sent to PMO for approval.",
      docType: "Outline",
      isFreezed: true,
      user: this.authService.userObj.id,
      subPortfolio: this.formObject.subPortfolio,
      startDate: this.formObject.startDate,
      endDate: this.formObject.endDate,
      isPMOApprovedOutline: false,
      projectOutline: {
        id: this.projectObj ? this.projectObj.projectOutline[0].id : undefined,
        version: this.projectObj ? this.projectObj.projectOutline[0].version : 1,
        status: "Submitted",
        projectName: this.formObject.projectName,
        projectManager: this.formObject.projectManager,
        projectSponsor: this.formObject.projectSponsor,
        startDate: this.formObject.startDate,
        endDate: this.formObject.endDate,
        date: new Date().toISOString(),
        currentSituation: this.formObject.currentSituation,
        goals: this.formObject.goals,
        deliverables: this.formObject.deliverables,
        deliverablesInitiationPhase: this.formObject.deliverablesInitiationPhase,
        resourceInitiationPhase: this.formObject.resourceInitiationPhase,
        outOfScope: this.formObject.outOfScope,
        risksTable: this.formObject.risksTableData,
        quantitativeBenefit: this.formObject.quantitativeBenefit,
        kpisTable: this.formObject.kpiTableData,
        currency: this.formObject.currency,
        estimatedProjectTable: this.formObject.estimatedProjectCostTableData,
        involvedPartnersTable: this.formObject.involvedPartnersTable,
        mandatoryProject: this.formObject.mandatoryProject,
        portfolio: this.formObject.portfolio,
        subPortfolio: this.formObject.subPortfolio,
        itPlatform: this.getItPlatform(), //this.formObject.itPlatform,
        itRelevant: this.formObject.itRelevant,
        businessUnit: this.formObject.businessUnit,
        businessArea: this.formObject.businessArea,
        businessSegment: this.formObject.businessSegment,
        reportingLevel: this.formObject.reportingLevel,
        classification: this.formObject.classification,
        projectType: this.formObject.projectType,
        program: this.formObject.program,
        strategicContribution: this.formObject.strategicContribution,
        feasibility: this.formObject.feasibility,
        profitability: this.formObject.profitability,
        projectMethodology: this.formObject.projectMethodology,
        confidential: this.formObject.confidential,
        comment: this.formObject.comment,
        initiationApprovalDate: this.formObject.initiationApprovalDate,
        initiationApproval: this.formObject.initiationApproval,
        fundsApprovedForInitiationTable: this.formObject.fundsApprovedForInitiationTableData,
        additionalInformation: this.formObject.additionalInformation,
        designatedProjectManager: this.formObject.designatedProjectManager,
        pmoOfficer: this.formObject.pmoOfficer,
        projectSponsorClient: this.formObject.projectSponsorClient,
        projectManagerIn: this.formObject.projectManagerIn,
        pmoFormalControl: this.formObject.pmoFormalControl,
        projectSponsorApproval: this.formObject.projectSponsorApproval,
        question: this.formObject.question,
        startOfProjectOrder: this.formObject.startOfProjectOrder,
        purpose: this.formObject.purpose,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : ""
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added
      }
    };
    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0) {
      obj.projectOutline['portfolioQuestions'] = this.formObject.portfolioQuestions;
    }

    this.formObject.question = this.formObject.question.map(val => this.localize.transform(val));
    let questionsHtml: string = this.utilityService.getOutlineQuestionsHtml(this.outlineQuestions, this.formObject.question);
    let portfolioQuestionsHtml: string = this.getPortfolioQuestionsHTML();

    if (this.formObject.id == undefined || this.formObject.id == "") {
      this.apiService.post('/submitOutline', obj).subscribe((response: any) => {
        this.notificationBox("Success", "Outline has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;

        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
           <a href="${this.apiService.FrontEndPATH}/outlinePreview/${response.approvalId}" target="_blank">${'Projektskizze / Project Outline : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/outline/${response.approvalId}" target="_blank">${'Projektskizze / Project Outline : ' + this.formObject.projectName}</a>` + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    } else {
      if (this.projectObj != undefined) {
        if (this.projectObj.isFreezed == false) {
          if (obj.projectOutline.version) {
            obj.projectOutline.version++;
          } else {
            obj.projectOutline.version = 1;
          }
        }
      }

      this.apiService.post('/submitOutlineUpdateCase/' + this.formObject.id, obj).subscribe((response: any) => {
        this.notificationBox("Success", "Outline has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;

        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
           <a href="${this.apiService.FrontEndPATH}/outlinePreview/${response.approvalId}" target="_blank">${'Projektskizze / Project Outline : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/outline/${response.approvalId}" target="_blank">${'Projektskizze / Project Outline : ' + this.formObject.projectName}</a>`  + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    }
  }

  validateStepsOnSave() {
    this.isStepValidated = [true, true, true, true];
    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.isStepValidated[0] = false;
    }

    if (this.formObject.purpose == '' || this.formObject.purpose == undefined) {
      this.isStepValidated[0] = false;
    }

    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.isStepValidated[0] = false;
    }
    if (!this.isStartDateValid) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.startDate == undefined) {
      this.isStepValidated[0] = false;
    }
    if (!this.isEndDateValid) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.endDate == undefined) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.currentSituation == "") {
      this.isStepValidated[0] = false;
    }

    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.isStepValidated[0] = false;
    }

    //Step 1 end

    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.isStepValidated[1] = false;
    }

    if (this.formObject.estimatedProjectCostTableData[6].budget == 0) {
      this.isStepValidated[1] = false;
    }
    let involvedPartnersBenefitsCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
    if (involvedPartnersBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }

    //Step 2 end

    if (this.formObject.mandatoryProject.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.portfolio.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.subPortfolio.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.businessUnit.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.businessArea.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.businessSegment.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.reportingLevel.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.classification.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.projectType.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.program.programName != "None") {
      if (this.formObject.program.id == "") {
        this.isStepValidated[2] = false;
      }
    }
    if (this.formObject.strategicContribution.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.feasibility.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.profitability.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.itRelevant.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.projectMethodology.id == "") {
      this.isStepValidated[2] = false;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.isStepValidated[2] = false;
    }

    // //step 3 end

    if (!this.isOrderStartDateValid) {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.startOfProjectOrder == undefined) {
      this.isStepValidated[3] = false;
    }
    if (!this.isOrderEndDateValid) {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.initiationApprovalDate == undefined) {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.startOfProjectOrder != undefined && this.formObject.initiationApprovalDate != undefined) {
      if (moment(this.formObject.startOfProjectOrder) >= moment(this.formObject.initiationApprovalDate)) {
        this.isStepValidated[3] = false;
      }
    }
    if (this.formObject.fundsApprovedForInitiationTableData[6].budget == 0) {
      this.isStepValidated[3] = false;
    }

    if (this.formObject.question[0] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[1] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[2] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[3] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[4] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[5] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.question[6] == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.pmoOfficer.id == "") {
      this.isStepValidated[3] = false;
    }

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.isStepValidated[3] = false;
    }

    if (this.formObject.projectSponsor.id == "") {
      this.isStepValidated[3] = false;
    }

    //step 4 end
  }

  getPortfolioQuestionsHTML() {
    let questionsHtml = '';

    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0 && this.groups.length > 0) {
      questionsHtml = '<br><br>';
      this.formObject.portfolioQuestions = this.formObject.portfolioQuestions.map(val => this.localize.transform(val));

      this.groups.map(department => {
        questionsHtml += `<h3>${department}</h3>`;

        this.formObject.portfolio.questions.map((vals, i) => {
          if (vals.group == department) {
            questionsHtml += `
              <p><strong>${vals.question}</strong></p>
              <p>${this.formObject.portfolioQuestions[i]}</p>
            `;
          }
        });
      })
    }

    return questionsHtml;
  }

  saveForm(e) {
    this.saveFlag = true;
    this.eventType = "save";

    this.isSavedPressed = true;
    this.validateStepsOnSave();

    if (this.formObject.projectName == undefined || this.formObject.projectName == '') {
      this.notificationBox("Warning", "Step1: Please Enter Project Name First.", "warning");
      this.saveFlag = false;
      return;
    }

    if (this.formObject.startOfProjectOrder != undefined && this.formObject.initiationApprovalDate != undefined) {
      if (moment(this.formObject.startOfProjectOrder) >= moment(this.formObject.initiationApprovalDate)) {
        this.showNotificationBox('Step 4: End of creation of project order date must be less then start of creation of project order date.');
        this.saveFlag = false;
        return;
      }
    }

    if (this.formObject.isAttachement1Added == true || this.formObject.isAttachement2Added == true) {
      this.uploadAttachments("save");
    } else {
      this.saveHelper();
    }
  }

  saveHelper() {
    this.spinner.show();

    let obj = {
      outlineSubmitted: false,
      outlineApproved: false,
      orderSubmitted: false,
      orderApproved: false,
      projectName: this.formObject.projectName,
      docType: "Outline",
      status: "Draft",
      workflowStatus: "Outline has not been submitted yet.",
      user: this.authService.userObj.id,
      subPortfolio: this.formObject.subPortfolio,
      startDate: this.formObject.startDate,
      endDate: this.formObject.initiationApprovalDate,
      projectOutline: {
        id: this.projectObj ? this.projectObj.projectOutline[0].id : undefined,
        version: this.projectObj ? this.projectObj.projectOutline[0].version : 1,
        status: "Draft",
        projectName: this.formObject.projectName,
        projectManager: this.formObject.projectManager,
        projectSponsor: this.formObject.projectSponsor,
        startDate: this.formObject.startDate,
        endDate: this.formObject.endDate,
        purpose: this.formObject.purpose,
        // date: new Date().toISOString(),
        currentSituation: this.formObject.currentSituation,
        goals: this.formObject.goals,
        deliverables: this.formObject.deliverables,
        deliverablesInitiationPhase: this.formObject.deliverablesInitiationPhase,
        resourceInitiationPhase: this.formObject.resourceInitiationPhase,
        outOfScope: this.formObject.outOfScope,
        risksTable: this.formObject.risksTableData,
        quantitativeBenefit: this.formObject.quantitativeBenefit,
        kpisTable: this.formObject.kpiTableData,
        currency: this.formObject.currency,
        estimatedProjectTable: this.formObject.estimatedProjectCostTableData,
        involvedPartnersTable: this.formObject.involvedPartnersTable,
        mandatoryProject: this.formObject.mandatoryProject,
        itPlatform: this.getItPlatform(), //this.formObject.itPlatform,
        itRelevant: this.formObject.itRelevant,
        portfolio: this.formObject.portfolio,
        subPortfolio: this.formObject.subPortfolio,
        businessUnit: this.formObject.businessUnit,
        businessArea: this.formObject.businessArea,
        businessSegment: this.formObject.businessSegment,
        reportingLevel: this.formObject.reportingLevel,
        classification: this.formObject.classification,
        projectType: this.formObject.projectType,
        program: this.formObject.program,
        strategicContribution: this.formObject.strategicContribution,
        feasibility: this.formObject.feasibility,
        profitability: this.formObject.profitability,
        projectMethodology: this.formObject.projectMethodology,
        confidential: this.formObject.confidential,
        comment: this.formObject.comment,
        initiationApprovalDate: this.formObject.initiationApprovalDate,
        initiationApproval: this.formObject.initiationApproval,
        fundsApprovedForInitiationTable: this.formObject.fundsApprovedForInitiationTableData,
        additionalInformation: this.formObject.additionalInformation,
        designatedProjectManager: this.formObject.designatedProjectManager,
        pmoOfficer: this.formObject.pmoOfficer,
        projectSponsorClient: this.formObject.projectSponsorClient,
        projectManagerIn: this.formObject.projectManagerIn,
        pmoFormalControl: this.formObject.pmoFormalControl,
        projectSponsorApproval: this.formObject.projectSponsorApproval,
        question: this.formObject.question,
        startOfProjectOrder: this.formObject.startOfProjectOrder,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : ""
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added
      }
    };

    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0) {
      obj.projectOutline['portfolioQuestions'] = this.formObject.portfolioQuestions;
    }
    this.formObject.attachments = obj.projectOutline.attachments;

    if (this.formObject.id == undefined) {
      this.apiService.post("/projects", obj).subscribe((projectResponse: any) => {
        this.fetchType = 'save';
        this.socketService.emitEvent('fetchProjectOutline', { id: projectResponse.id });
      });
    } else {
      let outline = obj.projectOutline;
      delete obj.projectOutline;

      this.apiService.put("/project/outline", {
        projectId: this.formObject.id,
        project: obj,
        outline: outline
      }).subscribe(projectResponse => {
        this.notificationBox("Success", "Outline has been saved.", "success");
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
      });
    }
  }

  getItPlatform(): any {
    if (this.formObject.itPlatform.length > 0) {
      this.formObject.itPlatform.forEach((itPlatform, idx) => {
        if (itPlatform.id) {
          this.formObject.itPlatform[idx] = itPlatform.id;
        }
      });
    }
    return this.formObject.itPlatform;
  }

  uploadAttachments(type: string) {
    this.showNotification = true;
    if (this.formObject.isAttachement1Added) {
      this.attachment1Uploaded = false;
      this.uploader.uploadAll();
    }
    if (this.formObject.isAttachement2Added) {
      this.attachment2Uploaded = false;
      this.uploader1.uploadAll();
    }
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);

      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        this.attachment1Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  onEditorPreparinginvolvedPartnerTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "externalPartners") {
      e.editorOptions.placeholder = this.localize.transform("Involved Partners");
      e.editorOptions.maxLength = 20;
    }
    if (e.parentType == "dataRow" && e.dataField != "externalPartners") {
      e.editorOptions.placeholder = this.localize.transform("Person-Days");
    }
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        this.attachment2Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  setCommentsData() {
    if (this.projectObj != undefined) {
      this.formObject.status = this.projectObj.status;
      this.formObject.purposeComments = this.projectObj.projectOutline[0].purposeComments;
      this.formObject.currentSituationComments = this.projectObj.projectOutline[0].currentSituationComments;
      this.formObject.goalsComments = this.projectObj.projectOutline[0].goalsComments;
      this.formObject.deliverablesComments = this.projectObj.projectOutline[0].deliverablesComments;
      this.formObject.outOfScopeComments = this.projectObj.projectOutline[0].outOfScopeComments;
      this.formObject.qualitativeBenefitsComments = this.projectObj.projectOutline[0].qualitativeBenefitsComments;
      this.formObject.qualitativeQuantitativeComments = this.projectObj.projectOutline[0].qualitativeQuantitativeComments;
      this.formObject.involvedPartnersComments = this.projectObj.projectOutline[0].involvedPartnersComments;
      this.formObject.risksComments = this.projectObj.projectOutline[0].risksComments;
      this.formObject.fundsRequiredComments = this.projectObj.projectOutline[0].fundsRequiredComments;
      this.formObject.categoriesComments = this.projectObj.projectOutline[0].categoriesComments;
      this.formObject.additionalInfoComments = this.projectObj.projectOutline[0].additionalInfoComments;
      this.formObject.deliverablesInitiationPhaseComments = this.projectObj.projectOutline[0].deliverablesInitiationPhaseComments;
      this.formObject.resourceInitiationPhaseComments = this.projectObj.projectOutline[0].resourceInitiationPhaseComments;
    }
  }

  preview() {
    this.showOutlinePreview = true;

    //Setting Redundant Values
    if (this.projectObj != undefined) {
      this.formObject.id = this.projectObj.projectOutline[0].id;
    }
    this.formObject.kpisTable = this.formObject.kpiTableData;
    this.formObject.estimatedProjectTable = this.formObject.estimatedProjectCostTableData;
    this.formObject.involvedPartnersTable = this.formObject.involvedPartnersTable;
    this.formObject.risksTable = this.formObject.risksTableData;

    this.setCommentsData();

    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showOutlinePreview = false;
      if (this.projectObj != undefined) {
        this.formObject.id = this.outlineHolderProjectId;
      }
    });
    this.lgModal.show();
  }

  fetchPrograms() {
    this.socketService.emitEvent('activePrograms', {});
  }

  onCellPrepared(e) {
    // if (e.columnIndex == 3) {
    //   e.cellElement.style.backgroundColor = "#eee";
    // }
    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  partOfAProgramValueChanged(e) {
    if (this.programOptions != undefined) {
      this.programOptions.forEach(response => {
        if (response.id == e.value) {
          this.formObject.program = response;
          delete this.formObject.program.reports;
        }
      });
    }
  }

  pmoOfficerValueChanged(e) {
    if (this.PMOsList != undefined) {
      this.PMOsList.forEach(response => {
        if (response.id == e.value) {
          this.formObject.pmoOfficer = response;
        }
      });
    }
  }

  projectSponsorValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.formObject.projectSponsor = response;
          }
        });
      }
    }
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {

        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectSponsor = response;
            } else {
              this.formObject.projectSponsor = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectSponsor = { id: '' };
      }
    });
  }

  mandatoryProjectValueChanged(e) {
    if (this.mandatoryProjectOptions != undefined) {
      this.mandatoryProjectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.mandatoryProject = response;
        }
      });
    }
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(res => {
          if (res.id == e.value) {
            this.formObject.portfolio = res;
            this.formObject.portfolioQuestions = [];
            this.groups = [];
            this.groupsHolder = [];
            if (this.formObject.portfolio.questions.length > 0) {
              this.formObject.portfolio.questions.forEach((element, index) => {
                this.formObject.portfolioQuestions[index] = '';
                this.groupsHolder[element.group] = element.email;
              });
              this.groups = Object.keys(this.groupsHolder)
            }
            this.subPortfolioList = res.subPortfolio
            if (this.formObject.subPortfolio && this.formObject.subPortfolio.id) {
              let subPortfolio = this.subPortfolioList.find(val => val.id == this.formObject.subPortfolio.id);
              if (!subPortfolio) {
                this.formObject.subPortfolio = { id: '' };
              }
            }
          }
        })
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.formObject.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  itPlatformValueChanged(e) {
    if (this.itPlatformOptions != undefined) {
      this.itPlatformOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itPlatform = response;
        }
      });
    }
  }

  itRelevantValueChanged(e) {
    if (this.itRelevantOptions != undefined) {
      this.itRelevantOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itRelevant = response;
        }
      });
    }
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessUnit = response;
        }
      });
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessArea = response;
        }
      });
    }
  }

  businessSegmentValueChanged(e) {
    if (this.businessSegmentOptions != undefined) {
      this.businessSegmentOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessSegment = response;
        }
      });
    }
  }

  reportingLevelValueChanged(e) {
    if (this.reportingLevelOptions != undefined) {
      this.reportingLevelOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.reportingLevel = response;
        }
      });
    }
  }

  classificationValueChanged(e) {
    if (this.classificationOptions != undefined) {
      this.classificationOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.classification = response;
        }
      });
    }
  }

  projectTypeValueChanged(e) {
    if (this.projectTypeOptions != undefined) {
      this.projectTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectType = response;
        }
      });
    }
  }

  strategicContributionValueChanged(e) {
    if (this.strategicContributionOptions != undefined) {
      this.strategicContributionOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.strategicContribution = response;
        }
      });
    }
  }

  feasibilityValueChanged(e) {
    if (this.feasibilityOptions != undefined) {
      this.feasibilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.feasibility = response;
        }
      });
    }
  }

  profitabilityValueChanged(e) {
    if (this.profitabilityOptions != undefined) {
      this.profitabilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.profitability = response;
        }
      });
    }
  }

  projectMethodologyValueChanged(e) {
    if (this.projectMethodologyOptions != undefined) {
      this.projectMethodologyOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectMethodology = response;
        }
      });
    }
  }

  confidentialValueChanged(e) {
    if (this.confidentialOptions != undefined) {
      this.confidentialOptions.forEach(response => {
        if (response == e.value) {
          this.formObject.confidential = response;
        }
      });
    }
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.formObject.itPlatform) {
      this.formObject.itPlatform.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.showNotificationBox('step 3: It Platform can select maximum no of 3.');
    } else {
      this.formObject.itPlatform = this.getSelectedItemsKeys(nodes);
    }
  }

  calculateTotalValueForCapexFundsApprovedTable(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.initiationApprovedCostsValueCalculator("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? (Math.abs(e.budget)) * -1 : "";
    } else {
      return e.budget ? e.budget : "";
    }
  }

  calculateTotalValueForOpexFundsApprovedTable(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.initiationApprovedCostsValueCalculator("opex");
      }
    } else if (e.costTypes == "Revenues") {
      return e.opex ? Math.abs(e.opex) * -1 : "";
    } else {
      return e.opex ? e.opex : "";
    }
  }

  initiationApprovedCostsValueCalculator(field) {
    let one: any = globalThis.formObject.fundsApprovedForInitiationTableData[0][field] * 1;
    let two: any = globalThis.formObject.fundsApprovedForInitiationTableData[1][field] * 1;
    let three: any = globalThis.formObject.fundsApprovedForInitiationTableData[2][field] * 1;
    let four: any = globalThis.formObject.fundsApprovedForInitiationTableData[3][field] * 1;
    let five: any = globalThis.formObject.fundsApprovedForInitiationTableData[4][field] * 1;
    let six: any = globalThis.formObject.fundsApprovedForInitiationTableData[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.fundsApprovedForInitiationTableData[6][field] = total;
    return total;
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  public showAttachmentDeleteButton(type: string): boolean {
    if (this.formObject.attachments != undefined) {
      if (this.formObject.attachments.length > 0) {
        if (type == '1') {
          return (this.formObject.id != undefined && this.formObject.attachments[0] != "");
        } else {
          return (this.formObject.id != undefined && this.formObject.attachments[1] != "");
        }
      } else {
        return false;
      }
    }
  }

  deleteAttachment(type: string) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.formObject.attachments[0] = "";

              this.apiService.put('/projectOutline/' + this.projectObj.projectOutline[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.formObject.attachments[1] = "";

              this.apiService.put('/projectOutline/' + this.projectObj.projectOutline[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        }
      }
    });
  }

  creationOfProjectOrderValueChanged(e) {
    if (this.startOfProjectOrderChangeFlag == true) {
      this.initiationApprovalDateMinimumDate = moment(this.formObject.startOfProjectOrder).add('days', 1).format('MMM DD, YYYY');
      this.formObject.initiationApprovalDate = this.initiationApprovalDateMinimumDate;
    } else {
      this.startOfProjectOrderChangeFlag = true;
      if (this.formObject.id == undefined) {
        this.initiationApprovalDateMinimumDate = moment(this.formObject.startOfProjectOrder).add('days', 1).format('MMM DD, YYYY');
        this.formObject.initiationApprovalDate = this.initiationApprovalDateMinimumDate;
      }
    }
  }

  canDeactivate(): boolean {
    if (this.isFormSaveSubmit == true) {
      return false;
    }
    else {
      return true; //this.form.touched;
    }
  }

  onKeyPurposeInfo(e) {
    if (e.key == 'Enter') {
      e.preventDefault();
    }
  }

  onKeyCurrentSituationInfo(e) {
    // console.log(e.target.scrollHeight);

    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 264) {
        // if(e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))){
        //   e.preventDefault();
        // }
        e.preventDefault();
        this.currentSituationLimit = true;
        // if(e.target.scrollHeight >= 170){
        //   //console.log(lines[lines.length - 1].length);
        //   if(lines[lines.length - 1].length > 99){
        //     e.preventDefault();
        //   }
        // }
      } else {
        this.currentSituationLimit = false;
      }
    } else {
      this.currentSituationLimit = false;
    }

    // if (lines.length > 7) {
    //   if (e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
    //     e.preventDefault();
    //   }
    // }
  }

  onKeyAdditionalInfo(e) {
    // let lines = e.target.value.split(/\r*\n/);
    // if (lines.length > 4) {
    //   if (e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
    //     e.preventDefault();
    //   }
    // }

    let lines = e.target.value.split(/\r*\n/);

    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        // if(e.key == 'Enter' || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))){
        //   e.preventDefault();
        // }
        e.preventDefault();
        this.additionalInfoLimit = true;
        // if(e.target.scrollHeight >= 110){
        //   //console.log(lines[lines.length - 1].length);
        //   if(lines[lines.length - 1].length > 59){
        //     e.preventDefault();
        //   }
        // }
      } else {
        this.additionalInfoLimit = false;
      }
    } else {
      this.additionalInfoLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  checkValidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'involvedPartners') {
      let involvedPartnersTableCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
      if (involvedPartnersTableCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkInvalidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'involvedPartners') {
      let involvedPartnersTableCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
      if (involvedPartnersTableCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('usersFromRoles');
    this.socketService.removeListener('fetchProjectOutline');
    this.socketService.removeListener('activePrograms');
    this.socketService.removeListener('sendEmail');
    this.socketService.removeListener('questionsByFormName');
    this.socketService.removeListener('helpGuideByFormName');
    this.socketService.removeListener('activePortfolios');
  }
}

class OutlineForm {
  id: string = undefined;
  projectName: string = "";
  projectManager: any;
  startDate: Date;
  endDate: Date;
  date: Date;
  purpose: string = "";
  currentSituation: string = "";
  goals: string = "";
  deliverables: string = "";
  outOfScope: string = "";
  quantitativeBenefit: string = "";
  mandatoryProject: string;
  businessUnit: string;
  businessArea: string;
  businessSegment: string;
  reportingLevel: string;
  classification: string;
  projectType: string;
  program: string;
  strategicContribution: string;
  feasibility: string;
  profitability: string;
  comment: string = "";
  initiationApproval: string = "";
  initiationApprovalDate: Date;
  additionalInformation: string = "";
  designatedProjectManager: string;
  pmoOfficer: string;
  projectSponsorClient: string;
  projectManagerIn: string;
  pmoFormalControl: string = "PMO";
  projectSponsorApproval: string = "Sponsor";
  projectId?: string;
  projectSponsor: string;
  version: number;
  kpiTableData: Array<any>;
  isAttachement1Added: boolean;
  isAttachement2Added: boolean;
}
