import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { I18nService } from 'app/shared/i18n/i18n.service';

declare var $: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;

  constructor(private element: ElementRef, private authService: AuthService, public router: Router,
    private apiService: ApiService, private utilityService: UtilityService, private localize: I18nPipe,
    public i18nService: I18nService, private route: ActivatedRoute) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;

    let id = location.href.split('/').pop();
    if (!this.authService.isAuthenticated()) {
      if (id != 'login' && id != 'logout') {
        this.apiService.get('/authenticate/' + id).subscribe((response: any) => {
          this.authService.userObj = response.userObj;
          localStorage.setItem('token', response.token);
          localStorage.setItem('accessToken', response.accessToken);

          this.apiService.get(`/notification/lang/de`).subscribe((data: any) => {
            this.i18nService.triggerChangeDetection(data, 'de');

            this.apiService.get('/getGlobalConfigurations').subscribe(configResponse => {
              this.authService.userObj.configuration = configResponse;
              localStorage.setItem('userObj', JSON.stringify(this.authService.userObj));
              this.utilityService.setTablesState(this.authService.userObj.tablesState);
              this.utilityService.showNotification(
                this.localize.transform("Success"),
                this.localize.transform("For best results please use Google Chrome Browser."),
                "success",
              );

              // Checks if the requested URL is a redirect to PDF
              if (this.authService.isPreview) {
                this.authService.isPreview = false;
                this.initiateDependencies();
              } else {
                this.router.navigate(['news']);
              }
            });
          });
        });
      } else {
        if (this.authService.isPreview) {
          this.authService.isPreview = false;
          this.apiService.login(this.authService.previewLink);
        } else {
          this.apiService.login();
        }
      }
    } else {
      this.router.navigate(['news']);
    }
  }

  initiateDependencies() {
    if (this.authService.previewLink.includes('outline')) {
      if (this.utilityService.strategicContributionOptions == undefined) {
        this.utilityService.setOutlinePdfValues({}, true);
      } else {
        this.router.navigate([this.authService.previewLink]);
      }
    } else if (this.authService.previewLink.includes('order')) {
      if (this.utilityService.strategicContributionOptions == undefined) {
        this.utilityService.setOrderPdfValues({}, true);
      } else {
        this.router.navigate([this.authService.previewLink]);
      }
    } else if (this.authService.previewLink.includes('changeRequest')) {
      if (this.utilityService.strategicContributionOptions == undefined) {
        this.utilityService.setChangeRequestPdfValues({}, true);
      } else {
        this.router.navigate([this.authService.previewLink]);
      }
    } else if (this.authService.previewLink.includes('closingReport')) {
      if (this.utilityService.strategicContributionOptions == undefined) {
        this.utilityService.setClosingReportPdfValues({}, true);
      } else {
        this.router.navigate([this.authService.previewLink]);
      }
    } else if (this.authService.previewLink.includes('project/statusReport')) {
      this.router.navigate([this.authService.previewLink]);
    } else if (this.authService.previewLink.includes('program/statusReport')) {
      this.router.navigate([this.authService.previewLink]);
    }
  }

  ngOnInit() {
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);
  }

  sidebarToggle() {
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName('body')[0];
    var sidebar = document.getElementsByClassName('navbar-collapse')[0];
    if (this.sidebarVisible == false) {
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }
}
