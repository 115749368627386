import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderPreviewRoutingModule } from './order-preview-routing.module';
import { OrderPreviewComponent } from './order-preview.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { FormsModule } from '@angular/forms';
import { DxScrollViewModule } from 'devextreme-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    OrderPreviewRoutingModule,
    I18nModule,
    PDFModule,
    FormsModule,
    DxScrollViewModule,
    NgxSpinnerModule
  ],
  declarations: [OrderPreviewComponent]
})
export class OrderPreviewModule { }
