import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkflowRoutingModule } from './workflow-routing.module';
import { WorkflowComponent } from './workflow.component';
import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule, DxCheckBoxModule, DxTagBoxModule, DxAutocompleteModule, DxProgressBarModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [WorkflowComponent],
  imports: [
    CommonModule,
    WorkflowRoutingModule,
    DxDataGridModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxAutocompleteModule,
    MaterialModule,
    PDFModule,
    ModalModule,
    DxProgressBarModule
  ]
})
export class WorkflowModule { }
