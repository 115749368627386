import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'app/services/api.service';
import { BsModalService } from 'ngx-bootstrap';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-roles-and-permissions',
  templateUrl: './roles-and-permissions.component.html',
  styleUrls: ['./roles-and-permissions.component.scss']
})
export class RolesAndPermissionsComponent implements OnInit {
  usersList: Array<any>;
  rolesList: Array<string>;
  user;
  role;
  isEdit = false;
  userForm: any = {
    isPmoAlso: false,
    isViewerAlso: false
  };
  modalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isAdmin: boolean = false;
  public query: string = '';

  constructor(private apiService: ApiService, private modalService: BsModalService, private authService: AuthService,
    private utilityService: UtilityService, private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Roles & Permission';
    this.utilityService.breadcrumbList = ['Settings', 'Roles & Permission'];
  }

  ngOnInit() {
    this.rolesList = ['pmo', 'guest'];
    if (this.authService.userObj.role == 'admin') {
      this.rolesList.push('admin');
      this.isAdmin = true;
    }
  }

  assign() {
    this.apiService.put('/user/' + this.user, {
      role: this.role.toLowerCase()
    }).subscribe(response => {
      this.utilityService.showNotification(
        this.localize.transform("Save Success"),
        this.localize.transform("Selected role has been assigned to the user"),
        "success",
      );
    });
  }

  addUser(template: TemplateRef<any>) {
    if (this.isEdit) {
      this.isEdit = false;
    }
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    if (!this.isEdit) {
      this.userForm = {
        isPmoAlso: false,
        isViewerAlso: false
      };
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.userForm = obj;
    this.openModal(template);
  }

  save() {
    this.spinner.show();
    // if (this.reportId != undefined) {
    let obj: any = {
      email: this.userForm.email,
      role: this.userForm.role.toLowerCase(),
      username: this.userForm.username,
      password: this.userForm.password,
    };
    if (this.userForm.isPmoAlso == true && this.isAdmin == true && obj.role == 'admin') {
      obj.isPmoAlso = true;
    }
    if (this.userForm.isViewerAlso == true && this.isAdmin == true && obj.role == 'guest') {
      obj.isViewerAlso = true;
    }
    this.userForm.role = this.userForm.role.toLowerCase();
    this.usersList.push(this.userForm);
    this.userForm = {
      isPmoAlso: false,
      isViewerAlso: false
    };

    this.apiService.post('/user', obj).subscribe((response: any) => {
      let userObj = this.authService.getUserObj();
      if (userObj.id == response.id) {
        response.configuration = userObj.configuration;
        this.authService.userObj = response;
        this.authService.setUserObj(response);
      }

      this.isEdit = false;
      this.dataGrid.instance.option('dataSource', this.usersList);
      this.modalRef.hide();
      this.spinner.hide();
    });
  }

  edit(id) {
    this.spinner.show();
    let obj: any = {
      email: this.userForm.email,
      role: this.userForm.role.toLowerCase(),
      username: this.userForm.username,
      password: this.userForm.password,
      isPmoAlso: false,
      isViewerAlso: false
    };

    if (this.userForm.isPmoAlso == true && this.isAdmin == true && obj.role == 'admin') {
      obj.isPmoAlso = true;
    }
    if (this.userForm.isViewerAlso == true && this.isAdmin == true && obj.role == 'guest') {
      obj.isViewerAlso = true;
    }


    this.apiService.put('/user/' + id, obj).subscribe((response: any) => {
      let userObj = this.authService.getUserObj();
      if (userObj.id == response.id) {
        response.configuration = userObj.configuration;
        this.authService.userObj = response;
        this.authService.setUserObj(response);
      }
      this.utilityService.fetchPMOs();

      this.isEdit = false;
      this.dataGrid.instance.option('dataSource', this.usersList);
      this.modalRef.hide();
      this.spinner.hide();
    });
  }

  onQueryChange(e) {
    this.apiService.get('/user/search/' + this.query).subscribe((response: any) => {
      this.usersList = response;
    });
  }
}
