import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
let globalThis;
declare var $: any;
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';
import { DxTreeViewComponent } from 'devextreme-angular';

@Component({
  selector: 'app-project-change-request',
  templateUrl: './project-change-request.component.html',
  styleUrls: ['./project-change-request.component.scss']
})
export class ProjectChangeRequestComponent implements OnInit, OnDestroy {
  projSponsorEmail;
  projFicoEmail;
  formObject: any = {
    companyName: {
      id: ''
    },
    projectManager: {
      email: ''
    },
    pmoOfficer: {
      id: ''
    },
    projectSponsor: {
      id: ''
    },
    projectFico: {
      id: ''
    },
  };
  public steps = [
    {
      key: "step1",
      title: "Description",
      valid: false,
      checked: false,
      submitted: false
    },
    {
      key: "step2",
      title: "Effects",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step3",
      title: "Consequences",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step4",
      title: "Categories",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step5",
      title: "Submit Request",
      valid: true,
      checked: false,
      submitted: false
    }
  ];
  public activeStep = this.steps[0];
  companyNameOptions;
  trippleConstraint = {
    cost: 2,
    time: 4,
    scope: 3
  };
  public radarChartLabels: string[] = [];
  lineChartColors = [
    {
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)"
    }
  ];
  public radarChartType: string = "radar";
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  attachment1;
  attachment2;
  renderChart = true;
  radarChartOptions;
  PMOsList = [];
  usersList = [];
  showNotification = false;
  eventType = "";
  public saveFlag = false;
  public submitFlag = false;
  projectObj: any = {};
  changeRequestId: any = '';
  projectId = '';
  public questionOptions: Array<any> = [];
  mandatoryProjectOptions;
  businessUnitOptions;
  businessAreaOptions;
  businessSegmentOptions;
  reportingLevelOptions;
  classificationOptions;
  projectTypeOptions;
  programOptions;
  strategicContributionOptions;
  feasibilityOptions;
  profitabilityOptions;
  currencyOptions;
  showChangePreview: boolean = false;
  @ViewChild("lgChangeModal", { static: false }) lgChangeModal;
  changeRequestObj: any = [];
  changeRequestQuestions: any = [{}, {}, {}, {}, {}, {}];
  changeRequestHelpGuide: any = [];
  @ViewChild('form', { static: false }) form: NgForm;
  isFormSaveSubmit: boolean = false;
  reasonsForChangeRequestLimit: boolean = false;
  consequencesOfNonImplementationLimit: boolean = false;
  possibleAlternativesLimit: boolean = false;
  additionalInfoLimit: boolean = false;
  minimumDate = '';
  prioritizationOld: any = {};
  portfolioList = [];
  subPortfolioList = [];
  itPlatformOptions;
  itRelevantOptions;
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  projectMethodologyOptions;
  confidentialOptions;
  isTeamProject = false;
  isNewEndDateValid: boolean = true;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeViewObj;
  crNo = 0;
  isStepValidated = [true, true, true, true, true];
  isSavedPressed: boolean = false;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';
  groups = [];
  groupsHolder = [];
  questionAnswersBackup = [];

  constructor(private utilityService: UtilityService, private apiService: ApiService,
    private router: Router, private route: ActivatedRoute, private localize: I18nPipe,
    private authService: AuthService, private spinner: NgxSpinnerService, private socketService: SocketService) {
    this.utilityService.breadcrumb = "Change Request";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    globalThis = this;

    this.formObject = new ChangeRequest();
    this.formObject.mandatoryProject = {
      id: ""
    };
    this.formObject.portfolio = {
      id: ""
    }
    this.formObject.subPortfolio = {
      id: ""
    }
    this.formObject.businessArea = {
      id: ""
    };
    this.formObject.businessUnit = {
      id: ""
    };
    this.formObject.businessSegment = {
      id: ""
    };
    this.formObject.reportingLevel = {
      id: ""
    };
    this.formObject.classification = {
      id: ""
    };
    this.formObject.projectType = {
      id: ""
    };
    this.formObject.strategicContribution = {
      id: ""
    };
    this.formObject.profitability = {
      id: ""
    };
    this.formObject.feasibility = {
      id: ""
    };
    this.formObject.program = {
      id: ""
    };
    this.formObject.radarChartData = [
      {
        data: [
          this.trippleConstraint.time,
          this.trippleConstraint.cost,
          this.trippleConstraint.scope
        ],
        label: this.localize.transform("Triple Constraint")
      }
    ];
    this.radarChartLabels = [this.localize.transform("Time"), this.localize.transform("Cost"), this.localize.transform("Scope")];

    this.formObject.companyName = {
      id: ''
    };
    this.formObject.projectManager = {
      email: ''
    };
    this.formObject.pmoOfficer = {
      id: ''
    };
    this.formObject.projectSponsor = {
      id: ''
    };
    this.formObject.projectFico = {
      id: ''
    };
    this.formObject.itPlatform = [];
    this.formObject.itRelevant = {
      id: ""
    };
    this.formObject.projectMethodology = {
      id: ""
    };
    this.formObject.changeRequestQuestion = ['', '', '', '', '', ''];

    this.socketService.listen('questionsByFormName').subscribe(response => {
      for (let i = 0; i < response.questions.length; i++) {
        if (response.questions[i].department == 'Compliance') {
          this.changeRequestQuestions[5] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling') {
          this.changeRequestQuestions[0] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 1) {
          this.changeRequestQuestions[1] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 2) {
          this.changeRequestQuestions[2] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 3) {
          this.changeRequestQuestions[4] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement' && response.questions[i].count == 1) {
          this.changeRequestQuestions[3] = response.questions[i];
        }
      }
    });

    this.socketService.listen('helpGuideByFormName').subscribe(response => {
      this.changeRequestHelpGuide = response.fields;
    });

    this.socketService.listen('activePrograms').subscribe(response => {
      this.programOptions = response;
    });

    this.socketService.listen('fetchChangeRequest').subscribe(response => {
      this.projectObj = response.project;
      if (this.changeRequestId == undefined || this.changeRequestId == 0) {
        this.utilityService.breadcrumbList = ['My Projects', 'Create Change Request'];
        this.crNo = this.projectObj.changeRequests.length + 1;
        this.formObject.projectName = this.projectObj.projectName;
        this.formObject.projectManager = response.detail.projectManager;
        this.formObject.startDate = response.detail.startDate;
        this.prioritizationOld = {
          strategicContribution: response.detail.strategicContribution,
          feasibility: response.detail.feasibility,
          profitability: response.detail.profitability,
        };

        if (this.formObject.startDate != undefined && this.formObject.startDate != null && this.formObject.startDate != "") {
          this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
        }

        this.formObject.endDate = response.detail.endDate;
        this.formObject.goals = response.detail.goals;
        this.formObject.deliverables = response.detail.deliverables;
        this.formObject.outOfScope = response.detail.outOfScope;
        this.formObject.currency = response.detail.currency;
        this.formObject.quantitativeBenefit = response.detail.quantitativeBenefit;
        this.formObject.kpisTable = response.detail.kpisTable;
        this.formObject.plannedEndDate = response.detail.plannedEndDate;
        this.formObject.mandatoryProject = response.detail.mandatoryProject;
        this.formObject.itPlatform = response.detail.itPlatform;
        this.formObject.itRelevant = response.detail.itRelevant;
        this.formObject.portfolio = response.detail.portfolio;
        this.formObject.subPortfolio = response.detail.subPortfolio;
        this.formObject.businessUnit = response.detail.businessUnit;
        this.formObject.businessArea = response.detail.businessArea;
        this.formObject.businessSegment = response.detail.businessSegment;
        this.formObject.reportingLevel = response.detail.reportingLevel;
        this.formObject.classification = response.detail.classification;
        this.formObject.projectType = response.detail.projectType;
        this.formObject.program = response.detail.program;
        this.formObject.strategicContribution = response.detail.strategicContribution;
        this.formObject.feasibility = response.detail.feasibility;
        this.formObject.profitability = response.detail.profitability;
        this.formObject.projectMethodology = response.detail.projectMethodology;
        this.formObject.confidential = response.detail.confidential;
        //this.formObject.additionalInformation = response.detail.additionalInformation;
        this.trippleConstraint = response.detail.trippleConstraint;
        // this.formObject.changeRequestQuestion = response.detail.changeRequestQuestion;
        this.formObject.pmoOfficer = response.detail.pmoOfficer;
        this.formObject.projectSponsor = response.detail.projectSponsor;
        this.formObject.projectFico = response.detail.projectFico;
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }
        this.updateChart();

        response.detail.costTypeTable.map((val, index) => {
          this.formObject.actualProjectBudget[index].budget = val.currentBudget;
          this.formObject.effectsOnProjectBudget[index].budget = val.currentBudget;
        });

        if (this.projectObj.changeRequests.length == 0) { // First Change Request
          // this.formObject.reasonForChangeRequest = response.detail.currentSituation;
        } else {  // Non-First Change Request
          this.formObject.reasonForChangeRequest = response.detail.reasonForChangeRequest;
          this.formObject.consequencesOfNonImplementation = response.detail.consequencesOfNonImplementation;
          this.formObject.possibleAlternatives = response.detail.possibleAlternatives;
        }

        this.formObject.kpisTable.map((val, index) => {
          this.formObject.kpisTable[index].valueOld = val.value;
        });
        this.spinner.hide();
      } else {
        this.utilityService.breadcrumbList = ['My Projects', 'Edit Change Request'];
        this.crNo = this.projectObj.changeRequests.length;
        this.formObject = response.changeRequest;
        this.formObject.projectManager = response.project.user;
        this.questionAnswersBackup = response.changeRequest.portfolioChangeRequestQuestion;

        setTimeout(() => {
          this.formObject.portfolioChangeRequestQuestion = this.questionAnswersBackup;
        }, 1000)

        if (this.formObject.startDate != undefined && this.formObject.startDate != null && this.formObject.startDate != "") {
          this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
        }

        this.trippleConstraint = response.changeRequest.trippleConstraint;

        let attach1: any = document.getElementById("attact1-span");
        let attach2: any = document.getElementById("attact2-span");
        if (
          this.formObject.attachments[0].originalName != undefined) {
          attach1.value = this.formObject.attachments[0].originalName;
          this.attachment1 = this.formObject.attachments[0];
        }
        if (this.formObject.attachments[1].originalName != undefined) {
          attach2.value = this.formObject.attachments[1].originalName;
          this.attachment2 = this.formObject.attachments[1];
        }
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }
        this.updateChart();
        this.spinner.hide();
      }
    });

    this.socketService.listen('activePortfolios').subscribe(response => {
      this.portfolioList = response;
    });

    this.getChangeRequestQuestions();
    this.getClosingReportHelpGuide();
    this.getProjectInfo();
    this.fetchPMOs();
    this.fetchUsers();
    this.fetchPrograms();
  }

  ngOnInit() {
    this.spinner.show();
    this.questionOptions = ["Yes", "No"];
    this.currencyOptions = ['CHF', 'EUR', 'USD'];
    this.confidentialOptions = ['Yes', 'No'];
    this.mandatoryProjectOptions = this.utilityService.mandatoryProjectOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessSegmentOptions = this.utilityService.businessSegmentOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.strategicContributionOptions = this.utilityService.strategicContributionOptions;
    this.feasibilityOptions = this.utilityService.feasibilityOptions;
    this.profitabilityOptions = this.utilityService.profitabilityOptions;
    this.itPlatformOptions = this.utilityService.itPlatformOptions;
    this.itRelevantOptions = this.utilityService.itRelevantOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;

    this.formObject.actualProjectBudget = [
      {
        costTypes: "External Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.effectsOnProjectBudget = [
      {
        costTypes: "External Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.deliverables = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.outOfScope = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.quantitativeBenefit = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.kpisTable = [
      {
        description: "Cost Savings",
        value: "",
        type: "Per Year"
      },
      {
        description: "NPV",
        value: "",
        type: ""
      },
      {
        description: "Additional Revenues",
        value: "",
        type: "Per Year"
      },
      {
        description: "Payback duration (Discounted)",
        value: "",
        type: "Years"
      },
      {
        description: "IRR",
        value: "",
        type: "%"
      }
    ];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }


  getChangeRequestQuestions(): void {
    this.socketService.emitEvent('questionsByFormName', { formName: 'Change Request' });
  }

  getClosingReportHelpGuide(): void {
    this.socketService.emitEvent('helpGuideByFormName', { formName: 'Change Request' });
  }

  fetchPrograms() {
    this.socketService.emitEvent('activePrograms', {});
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      if (this.utilityService.isTeamProject == true) {
        this.isTeamProject = true;
        this.utilityService.isTeamProject = false;
      }

      this.changeRequestId = params.crId;
      this.projectId = params.id;

      this.socketService.emitEvent('activePortfolios', {});
      this.socketService.emitEvent('fetchChangeRequest', { projectId: this.projectId, changeRequestId: this.changeRequestId });
    });
  }

  setActiveStep(step) {
    this.activeStep = step;
  }

  prevStep() {
    let idx = this.steps.indexOf(this.activeStep);
    if (idx > 0) {
      this.activeStep = this.steps[idx - 1];
    }

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  nextStep() {
    this.activeStep = this.steps[this.steps.indexOf(this.activeStep) + 1];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  onEditorPreparingProjectCostTable(e) {
    if (e.parentType == "dataRow") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onRowPreparedActualProjectBudgetTable(e) {
    e.rowElement.style.backgroundColor = '#f5f5f5';
    if (e.rowIndex == 9) {
      e.rowElement.style.fontWeight = 700;
    }
  }

  onCellPreparedEffects(e) {
    // if (e.columnIndex == 3) {
    //   e.cellElement.style.backgroundColor = "#f7f5f5";
    // }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onCellPrepared(e) {
    // if (e.columnIndex == 3) {
    //   e.cellElement.style.backgroundColor = "#f7f5f5";
    // }
  }

  calculateResourcesTotalValueForCapex(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? Math.abs(e.budget) * -1 : "";
    } else {
      return e.budget ? e.budget : "";
    }
  }

  calculateResourcesTotalValueForCapexNew(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculatorNew("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? Math.abs(e.budget) * -1 : "";
    } else {
      return e.budget ? e.budget : "";
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.formObject.actualProjectBudget[0][field] * 1;
    let two: any = globalThis.formObject.actualProjectBudget[1][field] * 1;
    let three: any = globalThis.formObject.actualProjectBudget[2][field] * 1;
    let four: any = globalThis.formObject.actualProjectBudget[3][field] * 1;
    let five: any = globalThis.formObject.actualProjectBudget[4][field] * 1;
    let six: any = globalThis.formObject.actualProjectBudget[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.actualProjectBudget[6][field] = total;
    return total;
  }

  projectCostsValueCalculatorNew(field) {
    let one: any = globalThis.formObject.effectsOnProjectBudget[0][field] * 1;
    let two: any = globalThis.formObject.effectsOnProjectBudget[1][field] * 1;
    let three: any = globalThis.formObject.effectsOnProjectBudget[2][field] * 1;
    let four: any = globalThis.formObject.effectsOnProjectBudget[3][field] * 1;
    let five: any = globalThis.formObject.effectsOnProjectBudget[4][field] * 1;
    let six: any = globalThis.formObject.effectsOnProjectBudget[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.effectsOnProjectBudget[6][field] = total;
    return total;
  }

  onEditorPreparingKPIsTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Period") {
        e.editorOptions.placeholder = this.localize.transform("Payback Period");
      }
    }
  }

  companyNameValueChanged(e) {
    if (this.companyNameOptions != undefined) {
      this.companyNameOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.companyName = response;
        }
      });
    }
  }

  updateChart() {
    if (this.trippleConstraint != undefined) {
      if (
        this.trippleConstraint.cost < 11 &&
        this.trippleConstraint.cost > 0 &&
        this.trippleConstraint.time > 0 &&
        this.trippleConstraint.time < 11 &&
        this.trippleConstraint.scope > 0 &&
        this.trippleConstraint.scope < 11
      ) {
        this.formObject.radarChartData[0].data[1] = this.trippleConstraint.cost;
        this.formObject.radarChartData[0].data[0] = this.trippleConstraint.time;
        this.formObject.radarChartData[0].data[2] = this.trippleConstraint.scope;
        this.renderChart = false;
        setTimeout(() => {
          this.renderChart = true;
        }, 0);
      } else {
        this.notificationBox("Warning", "Values must be between 1-10.", "warning");
      }
    } else {
      this.trippleConstraint = {
        cost: 2,
        time: 4,
        scope: 3
      };
    }
  }

  pmoOfficerValueChanged(e) {
    if (this.PMOsList != undefined) {
      this.PMOsList.forEach(response => {
        if (response.id == e.value) {
          this.formObject.pmoOfficer = response;
        }
      });
    }
  }

  projectSponsorValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.projectSponsor = response;
        }
      });
    }
  }

  projectFicoValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.projectFico = response;
        }
      });
    }
  }

  fetchPMOs() {
    this.PMOsList = this.utilityService.PMOsList;
  }

  fetchUsers() {
    this.usersList = this.utilityService.usersList;
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        this.attachment1Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        this.attachment2Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  validateStepsOnSave() {
    this.isStepValidated = [true, true, true, true, true];

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.reasonForChangeRequest == '' || this.formObject.reasonForChangeRequest == undefined) {
      this.isStepValidated[0] = false;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.isStepValidated[0] = false;
    }

    //Step 2
    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.isStepValidated[1] = false;
    }
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
    if (quantativeBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }
    let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
    if (qualitativeBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.actualProjectBudget[6].budget == 0) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.effectsOnProjectBudget[6].budget == 0) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.startDate == undefined) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.endDate == undefined) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.plannedEndDate == undefined) {
      this.isStepValidated[1] = false;
    }
    if (!this.isNewEndDateValid) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.newEndDate == undefined) {
      this.isStepValidated[1] = false;
    }

    //step 3
    if (this.formObject.consequencesOfNonImplementation == "" || this.formObject.consequencesOfNonImplementation == undefined) {
      this.isStepValidated[2] = false;
    }

    //step 4
    if (this.formObject.mandatoryProject.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.portfolio.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.subPortfolio.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.businessUnit.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.businessArea.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.businessSegment.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.reportingLevel.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.classification.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.projectType.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.program.programName != "None") {
      if (this.formObject.program.id == "") {
        this.isStepValidated[3] = false;
      }
    }
    if (this.formObject.strategicContribution.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.feasibility.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.profitability.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.itRelevant.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.projectMethodology.id == "") {
      this.isStepValidated[3] = false;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.isStepValidated[3] = false;
    }

    //step 5
    if (this.formObject.changeRequestQuestion[0] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.changeRequestQuestion[1] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.changeRequestQuestion[2] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.changeRequestQuestion[3] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.changeRequestQuestion[4] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.changeRequestQuestion[5] == "") {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.isStepValidated[4] = false;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.isStepValidated[4] = false;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.isStepValidated[4] = false;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.isStepValidated[4] = false;
    }
  }

  getPortfolioQuestionsHTML() {
    let questionsHtml = '';

    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0 && this.groups.length > 0) {
      questionsHtml = '<br><br>';
      this.formObject.portfolioChangeRequestQuestion = this.formObject.portfolioChangeRequestQuestion.map(val => this.localize.transform(val));

      this.groups.map(department => {
        questionsHtml += `<h3>${department}</h3>`;

        this.formObject.portfolio.questions.map((vals, i) => {
          if (vals.group == department) {
            questionsHtml += `
              <p><strong>${vals.question}</strong></p>
              <p>${this.formObject.portfolioChangeRequestQuestion[i]}</p>
            `;
          }
        });
      })
    }

    return questionsHtml;
  }

  saveForm(e) {
    this.saveFlag = true;
    this.submitFlag = true;
    this.eventType = "save";

    this.isSavedPressed = true;
    this.validateStepsOnSave();

    if (
      this.formObject.isAttachement1Added == true || this.formObject.isAttachement2Added == true) {
      this.uploadAttachments("save");
    } else {
      this.saveHelper();
    }
  }

  saveHelper() {
    this.spinner.show();

    let obj = {
      status: "Draft",
      isSubmitted: false,
      isEditable: true,
      docType: "Change Request",
      isApproved: false,
      workflowStatus: "Change Request has not been submitted yet.",
      ...this.formObject,
      itPlatform: this.getItPlatform(),
      trippleConstraint: this.trippleConstraint,
      attachment1: this.attachment1 ? this.attachment1 : "",
      attachment2: this.attachment2 ? this.attachment2 : "",
      attachments: [
        this.attachment1 ? this.attachment1 : "",
        this.attachment2 ? this.attachment2 : ""
      ],
      project: this.projectId,
      prioritizationOld: Object.keys(this.prioritizationOld).length > 0 ? this.prioritizationOld : this.formObject.prioritizationOld
    };

    if (this.changeRequestId != 0 && this.changeRequestId != undefined && this.changeRequestId * 1 != 0) {
      this.apiService.put("/changeRequest/" + this.changeRequestId, obj).subscribe(projectResponse => {
        this.notificationBox("Success", "Change Request has been saved.", "success");
        this.projectObj = projectResponse;
        this.saveFlag = false;
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
      });
    } else {
      this.apiService.post("/changeRequest", obj).subscribe((projectResponse: any) => {
        this.changeRequestId = projectResponse.id;
        this.router.navigate([`projects/changeRequest/${this.projectId}/${this.changeRequestId}`]);

        this.notificationBox("Success", "Change Request has been saved.", "success");
        this.saveFlag = false;
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
      });
    }
  }

  getItPlatform(): any {
    if (this.formObject.itPlatform.length > 0) {
      this.formObject.itPlatform.forEach((itPlatform, idx) => {
        if (itPlatform.id) {
          this.formObject.itPlatform[idx] = itPlatform.id;
        }
      });
    }
    return this.formObject.itPlatform;
  }

  submit() {
    this.submitFlag = true;
    this.saveFlag = true;
    this.newMethod().eventType = "submit";

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.showNotificationBox('Step 1: Please Enter Project Name First.')
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.showNotificationBox('Step 1: Please Select a Project Manager First.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.reasonForChangeRequest == '' || this.formObject.reasonForChangeRequest == undefined) {
      this.showNotificationBox('Step 1: Please Enter reason for change request First.')
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter goals first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter deliverables first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter out of scope first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.showNotificationBox('Step 2: Please select a currency first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
    if (quantativeBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter qualitative benefits first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
    if (qualitativeBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter quantitative benefits first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.actualProjectBudget[6].budget == 0) {
      this.showNotificationBox('Step 2: Please fill actual project budget.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.effectsOnProjectBudget[6].budget == 0) {
      this.showNotificationBox('Step 2: Please fill effects on project budget.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.startDate == undefined) {
      this.showNotificationBox('Step 2: Please select a start date first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.endDate == undefined) {
      this.showNotificationBox('Step 2: Please select a end date first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.plannedEndDate == undefined) {
      this.showNotificationBox('Step 2: Please select a planned end date first.');
      this.submitFlag = false;
      return;
    }
    if (!this.isNewEndDateValid) {
      this.showNotificationBox('Step 2: Please enter new end date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.newEndDate == undefined) {
      this.showNotificationBox('Step 2: Please select a new end date.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.consequencesOfNonImplementation == "" || this.formObject.consequencesOfNonImplementation == undefined) {
      this.showNotificationBox('Step 3: Please enter a consequences Of non implementation.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    // if (this.formObject.possibleAlternatives == "" || this.formObject.possibleAlternatives == undefined) {
    //   this.showNotificationBox('Please enter a possible alternatives.');
    //   this.submitFlag = false;
    // this.saveFlag = false;
    //   return;
    // }
    if (this.formObject.mandatoryProject.id == "") {
      this.showNotificationBox('Step 4: Please select mandatory project first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.portfolio.id == "") {
      this.showNotificationBox('Step 3: Please select portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.subPortfolio.id == "") {
      this.showNotificationBox('step 3: Please select sub-portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessUnit.id == "") {
      this.showNotificationBox('Step 4: Please select business unit first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.businessArea.id == "") {
      this.showNotificationBox('Step 4: Please select business area first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.businessSegment.id == "") {
      this.showNotificationBox('Step 4: Please select business segment first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.reportingLevel.id == "") {
      this.showNotificationBox('Step 4: Please select reporting level first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.classification.id == "") {
      this.showNotificationBox('Step 4: Please select classification first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.projectType.id == "") {
      this.showNotificationBox('Step 4: Please select project type first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.program.programName != "None") {
      if (this.formObject.program.id == "") {
        this.showNotificationBox('Step 4: Please select program first.');
        this.submitFlag = false;
        this.saveFlag = false;
        return;
      }
    }
    if (this.formObject.strategicContribution.id == "") {
      this.showNotificationBox('Step 4: Please select strategic contribution first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.feasibility.id == "") {
      this.showNotificationBox('Step 4: Please select feasibility first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.profitability.id == "") {
      this.showNotificationBox('Step 4: Please select profitability first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.itRelevant.id == "") {
      this.showNotificationBox('step 4: Please select IT Relevant');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.showNotificationBox('step 4: Please select IT platform');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.projectMethodology.id == "") {
      this.showNotificationBox('step 4: Please select Project Methodology.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.showNotificationBox('step 4: Please select confidential option.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.portfolioChangeRequestQuestion && this.formObject.portfolioChangeRequestQuestion.length > 0) {
      for (let i = 0; i < this.formObject.portfolioChangeRequestQuestion.length; i++) {
        if (this.formObject.portfolioChangeRequestQuestion[i] == "") {
          this.showNotificationBox('Step 5: Please fill all questions first.');
          this.submitFlag = false;
          return;
        }
      }
    }

    if (this.formObject.changeRequestQuestion[0] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.changeRequestQuestion[1] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.changeRequestQuestion[2] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.changeRequestQuestion[3] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.changeRequestQuestion[4] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.changeRequestQuestion[5] == "") {
      this.showNotificationBox('Step 5: Please fill all questions first.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.showNotificationBox('Step 5: Please Select a PMO First.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.showNotificationBox('Step 5: Please enter a valid email address for project sponsor.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.showNotificationBox('Step 5: Please Select a Project Sponsor First.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.showNotificationBox('Step 5: Please enter a valid email address for project fico.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.showNotificationBox('Step 5: Please Select a Project Fico First.');
      this.submitFlag = false;
      this.saveFlag = false;
      return;
    }
    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("submit");
    } else {
      this.submitHelper();
    }
  }

  private newMethod() {
    return this;
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  submitHelper() {
    this.spinner.show();
    let version: any;

    if (this.changeRequestId == 0 || this.changeRequestId == undefined || this.changeRequestId * 1 == 0) {
      version = 1;
    } else {
      version = this.formObject.version != undefined ? this.formObject.version + 1 : 1;
    }

    let obj: any = {
      status: "Submitted",
      docType: "Change Request",
      ...this.formObject,
      itPlatform: this.getItPlatform(),
      isApproved: false,
      isSubmitted: true,
      isEditable: false,
      version: version,
      crNo: this.crNo,
      workflowStatus: "Change Request has been submitted.",
      isPMOApprovedChangeRequest: false,
      isSponsorApprovedChangeRequest: false,
      date: new Date().toISOString(),
      trippleConstraint: this.trippleConstraint,
      attachment1: this.attachment1 ? this.attachment1 : "",
      attachment2: this.attachment2 ? this.attachment2 : "",
      attachments: [
        this.attachment1 ? this.attachment1 : "",
        this.attachment2 ? this.attachment2 : ""
      ],
      project: this.projectId,
      prioritizationOld: Object.keys(this.prioritizationOld).length > 0 ? this.prioritizationOld : this.formObject.prioritizationOld
    };

    this.formObject.changeRequestQuestion = this.formObject.changeRequestQuestion.map(val => this.localize.transform(val));
    let questionsHtml: string = this.utilityService.getChangeRequestQuestionsHtml(this.changeRequestQuestions, this.formObject.changeRequestQuestion);
    let portfolioQuestionsHtml: string = this.getPortfolioQuestionsHTML();

    if (this.changeRequestId == 0) {
      let projectObj = {
        changeRequestMade: true,
        docType: 'Change Request',
        changeRequestApproved: false,
        workflowStatus: 'Change Request has been sent to PMO for approval.',
        version: 1,
        status: "Submitted",
        subPortfolio: this.formObject.subPortfolio,
        startDate: obj.newEndDate
      };

      this.apiService.post("/project/changeRequest/approval", {
        obj: obj,
        projectId: this.projectId,
        projectObj: projectObj,
        formObject: this.formObject
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${response.id}" target="_blank">${'Änderungsantrag / Change Request : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/changeRequest/${response.id}" target="_blank">${'Änderungsantrag / Change Request : ' + this.formObject.projectName}</a>` + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Change Request has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    } else {
      this.apiService.put("/project/changeRequest/approval", {
        obj: obj,
        projectId: this.projectId,
        changeRequestId: this.changeRequestId,
        formObject: this.formObject
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${response.id}" target="_blank">${'Änderungsantrag / Change Request : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/changeRequest/${response.id}" target="_blank">${'Änderungsantrag / Change Request : ' + this.formObject.projectName}</a>` + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Change Request has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    }
  }

  uploadAttachments(type: string) {
    this.showNotification = true;
    if (this.formObject.isAttachement1Added) {
      this.attachment1Uploaded = false;
      this.uploader.uploadAll();
    }
    if (this.formObject.isAttachement2Added) {
      this.attachment2Uploaded = false;
      this.uploader1.uploadAll();
    }
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectSponsor = response;
            } else {
              this.formObject.projectSponsor = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectSponsor = { id: '' };
      }
    });
  }

  projectFicoOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectFico = response;
            } else {
              this.formObject.projectFico = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectFico = { id: '' };
      }
    });
  }

  mandatoryProjectValueChanged(e) {
    if (this.mandatoryProjectOptions != undefined) {
      this.mandatoryProjectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.mandatoryProject = response;
        }
      });
    }
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(res => {
          if (res.id == e.value) {
            this.formObject.portfolio = res;
            this.formObject.portfolioChangeRequestQuestion = [];
            this.groups = [];
            this.groupsHolder = [];
            if (this.formObject.portfolio.questions.length > 0) {
              this.formObject.portfolio.questions.forEach((element, index) => {
                this.formObject.portfolioChangeRequestQuestion[index] = '';
                this.groupsHolder[element.group] = element.email;
              });
              this.groups = Object.keys(this.groupsHolder)
            }

            this.subPortfolioList = res.subPortfolio
            if (this.formObject.subPortfolio && this.formObject.subPortfolio.id) {
              let subPortfolio = this.subPortfolioList.find(val => val.id == this.formObject.subPortfolio.id);
              if (!subPortfolio) {
                this.formObject.subPortfolio = { id: '' };
              }
            }
          }
        })
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.formObject.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  itPlatformValueChanged(e) {
    if (this.itPlatformOptions != undefined) {
      this.itPlatformOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itPlatform = response;
        }
      });
    }
  }

  itRelevantValueChanged(e) {
    if (this.itRelevantOptions != undefined) {
      this.itRelevantOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itRelevant = response;
        }
      });
    }
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessUnit = response;
        }
      });
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessArea = response;
        }
      });
    }
  }

  businessSegmentValueChanged(e) {
    if (this.businessSegmentOptions != undefined) {
      this.businessSegmentOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessSegment = response;
        }
      });
    }
  }

  reportingLevelValueChanged(e) {
    if (this.reportingLevelOptions != undefined) {
      this.reportingLevelOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.reportingLevel = response;
        }
      });
    }
  }

  classificationValueChanged(e) {
    if (this.classificationOptions != undefined) {
      this.classificationOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.classification = response;
        }
      });
    }
  }

  projectTypeValueChanged(e) {
    if (this.projectTypeOptions != undefined) {
      this.projectTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectType = response;
        }
      });
    }
  }

  strategicContributionValueChanged(e) {
    if (this.strategicContributionOptions != undefined) {
      this.strategicContributionOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.strategicContribution = response;
        }
      });
    }
  }

  feasibilityValueChanged(e) {
    if (this.feasibilityOptions != undefined) {
      this.feasibilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.feasibility = response;
        }
      });
    }
  }

  profitabilityValueChanged(e) {
    if (this.profitabilityOptions != undefined) {
      this.profitabilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.profitability = response;
        }
      });
    }
  }

  projectMethodologyValueChanged(e) {
    if (this.projectMethodologyOptions != undefined) {
      this.projectMethodologyOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectMethodology = response;
        }
      });
    }
  }

  confidentialValueChanged(e) {
    if (this.confidentialOptions != undefined) {
      this.confidentialOptions.forEach(response => {
        if (response == e.value) {
          this.formObject.confidential = response;
        }
      });
    }
  }

  partOfAProgramValueChanged(e) {
    if (this.programOptions != undefined) {
      this.programOptions.forEach(response => {
        if (response.id == e.value) {
          this.formObject.program = response;
          delete this.formObject.program.reports;
        }
      });
    }
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.formObject.itPlatform) {
      this.formObject.itPlatform.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.showNotificationBox('step 4: It Platform can select maximum no of 3.');
    } else {
      this.formObject.itPlatform = this.getSelectedItemsKeys(nodes);
    }
  }

  preview() {
    this.showChangePreview = true;

    //Setting Redundant Values
    if (this.projectObj != undefined) {
      this.changeRequestObj = this.formObject;
      if (this.projectObj.attachments != undefined) {
        this.changeRequestObj.attachments = this.projectObj.attachments;
      }

      if (this.projectObj.projectReport != undefined) {
        if (this.changeRequestObj.project != undefined) {
          this.changeRequestObj.project.projectReport = this.projectObj.projectReport;
        } else {
          this.changeRequestObj.project = {};
          this.changeRequestObj.project.projectReport = this.projectObj.projectReport;
        }

        this.changeRequestObj.trippleConstraint = this.trippleConstraint;
        this.changeRequestObj = this.utilityService.setChangeRequestPdfValues(this.changeRequestObj);
      }

      if (this.changeRequestObj.project.id != undefined) {
        if (typeof (this.changeRequestObj.project.projectReport) == 'string') {
          this.changeRequestObj.project = this.utilityService.projectList.find(x => x.id == this.changeRequestObj.project.id)
        }
      }

      if (this.changeRequestId && this.changeRequestId != 0) {
        this.changeRequestObj.changeRequestId = this.changeRequestId;
      }

      this.lgChangeModal.onHidden.subscribe((reason: string) => {
        this.showChangePreview = false;
        if (this.projectObj != undefined) {
          //this.formObject.id = this.outlineHolderProjectId;
        }
      });
      this.lgChangeModal.show();
    }
  }

  public showAttachmentDeleteButton(type: string): boolean {
    if (this.formObject.attachments != undefined) {
      if (this.formObject.attachments.length > 0) {
        if (type == '1') {
          return (this.formObject.id != undefined && this.formObject.attachments[0] != "");
        } else {
          return (this.formObject.id != undefined && this.formObject.attachments[1] != "");
        }
      } else {
        return false;
      }
    }
  }


  deleteAttachment(type: string) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.formObject.attachments[0] = "";

              this.apiService.put('/changeRequest/' + this.projectObj.changeRequests[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.formObject.attachments[1] = "";

              this.apiService.put('/changeRequest/' + this.projectObj.changeRequests[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        }
      }
    });
  }

  onKeyReasonForChangeRequestInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 166) {
        e.preventDefault();
        this.reasonsForChangeRequestLimit = true;
      } else {
        this.reasonsForChangeRequestLimit = false;
      }
    } else {
      this.reasonsForChangeRequestLimit = false;
    }
  }

  onKeyConsequencesOfNonImplementationInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.consequencesOfNonImplementationLimit = true;
      } else {
        this.consequencesOfNonImplementationLimit = false;
      }
    } else {
      this.consequencesOfNonImplementationLimit = false;
    }
  }

  onKeyPossibleAlternativesInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.possibleAlternativesLimit = true;
      } else {
        this.possibleAlternativesLimit = false;
      }
    } else {
      this.possibleAlternativesLimit = false;
    }
  }

  onKeyAdditionalInformationInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.additionalInfoLimit = true;
      } else {
        this.additionalInfoLimit = false;
      }
    } else {
      this.additionalInfoLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  canDeactivate(): boolean {
    if (this.isFormSaveSubmit == true) {
      return false;
    }
    else {
      return true;
    }
  }

  checkValidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Quantitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkInvalidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Quantitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('activePrograms');
    this.socketService.removeListener('fetchChangeRequest');
    this.socketService.removeListener('sendEmail');
    this.socketService.removeListener('questionsByFormName');
    this.socketService.removeListener('helpGuideByFormName');
    this.socketService.removeListener('activePortfolios');
  }
}

class ChangeRequest {
  companyName: string = "";
  projectName: string = "";
  reasonForChangeRequest: string = "";
  consequencesOfNonImplementation: string = "";
  possibleAlternatives: string = "";
  additionalInformation: string = "";
  startDate: Date;
  endDate: Date;
  originalEnding: Date;
  plannedEndDate: Date;
  newEndDate: Date;
}

