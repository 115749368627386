import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateStatusReportRoutingModule } from './create-status-report-routing.module';
import { CreateStatusReportComponent } from './create-status-report.component';
import {
  DxDataGridModule, DxDateBoxModule, DxSliderModule, DxRadioGroupModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxAutocompleteModule, DxChartModule
} from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ModalModule } from 'ngx-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SubportfolioRisksComponent } from './subportfolio-risks/subportfolio-risks.component';
import { SubportfolioMilestonesComponent } from './subportfolio-milestones/subportfolio-milestones.component';
import { SubportfolioMeasuresComponent } from './subportfolio-measures/subportfolio-measures.component';
import { SubportfolioDecisionsComponent } from './subportfolio-decisions/subportfolio-decisions.component';
import { PdfSubPortfolioStatusReportModule } from '../pdf-sub-portfolio-status-report/pdf-sub-portfolio-status-report.module';

@NgModule({
  declarations: [CreateStatusReportComponent, SubportfolioRisksComponent, SubportfolioMilestonesComponent, SubportfolioMeasuresComponent, SubportfolioDecisionsComponent],
  imports: [
    CommonModule,
    CreateStatusReportRoutingModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxSliderModule,
    DxRadioGroupModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxAutocompleteModule,
    DxChartModule,
    ModalModule,
    NgxSpinnerModule,
    PdfSubPortfolioStatusReportModule
  ]
})
export class CreateStatusReportModule { }
