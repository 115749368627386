import { UtilityService } from 'app/services/utility.service';
import { Component, OnInit, OnDestroy, ElementRef, AfterContentChecked, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import swal from 'sweetalert2';
import { SocketService } from 'app/services/socket.service';
import { NotificationsService } from 'app/services/notifications.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs-compat/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs-compat/operators/distinctUntilChanged';
import { switchMap } from 'rxjs-compat/operators/switchMap';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
declare var moment: any;
var globalThis;

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent implements OnInit, OnDestroy {
  approvalsList = [];
  approvalsCount: any = 0;
  approvalsSearch: any = '';
  approvalsSubject = new Subject<any>();
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('smallOrdersGrid', { static: false }) ordersGrid: DxDataGridComponent;
  selectedAction = '';
  isResetPressed: boolean = false;
  outlinePreview;
  showPreview;
  showSmallOrderPreview = false;
  showOrderPreview = false;
  blockDropdown = false;
  orderPreview;
  smallOrderPreview;
  BASE_PATH = '';
  attachmentQueryParams = '';
  FrontEnd_PATH = '';
  showComments = false;
  commentsArr = [];
  commentMsg = '';
  commentsProjectId;
  showChangePreview = false;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  changeRequestPreview;
  showClosingPreview = false;
  closingReportPreview;
  treeViewObj;
  usersList = [];
  filtersArray;
  filtersArrayOrders;
  projectSponsorFilter;
  projectManagerFilter;
  projectNameFilter;
  projManagerEmail;
  projSponsorEmail;
  projectsSearchList = [];
  projectName;
  statusOptions;
  statusOptionsOrders;
  documentTypeOptions;
  decisionOptions;
  statusFilter;
  statusFilterOrder = [];
  isFilterApplied: boolean = false;
  documentTypeFilter;
  decisionFilter;
  userIds = [];
  orderIds = [];
  ordersSearchList = [];
  orderNameFilter;
  orderName;
  orderManagerEmail;
  orderSponsorEmail;
  orderManagerFilter;
  orderSponsorFilter
  @ViewChild('statusTreeView', { static: false }) treeViewStatus: DxTreeViewComponent;
  @ViewChild('documentTreeView', { static: false }) treeViewDocument: DxTreeViewComponent;
  @ViewChild('decisionTreeView', { static: false }) treeViewDecision: DxTreeViewComponent;
  @ViewChild('orderStatusTree', { static: false }) treeViewOrderStatus: DxTreeViewComponent;
  modalRef: BsModalRef;
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('orderModal', { static: false }) public orderModal;
  @ViewChild('commentsModal', { static: false }) public commentsModal;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  @ViewChild('smallOrderModal', { static: false }) public smallOrderModal;

  dropdownPageIndex = 1;
  approvalsListChunks = [];
  stateStore;
  smallOrderStateStore;
  approvalsSmallOrderList: any = [];
  smallOrdersSponsorCount: number = 0;
  smallOrdersSponsorPageIndex = 2;
  start;
  end;

  constructor(private apiService: ApiService, public authService: AuthService, private el: ElementRef,
    private utilityService: UtilityService, private localize: I18nPipe, private modalService: BsModalService,
    private notificationService: NotificationsService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    globalThis = this;

    // this.fetchProjects();
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';;
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;
    this.utilityService.breadcrumb = 'Approvals';
    this.utilityService.breadcrumbList = ['Approvals'];
    this.stateStore = {
      enabled: true,
      storageKey: 'approvalsList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.smallOrderStateStore = {
      enabled: true,
      storageKey: 'approvalsSmallOrderList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {
    this.spinner.show();
    this.statusOptions = [{ id: 0, name: 'Submitted' }, { id: 1, name: 'Approved' }, { id: 2, name: 'Returned' }, { id: 3, name: 'Rejected' }, { id: 4, name: 'On Hold' }, { id: 5, name: 'Recalled' }];
    this.statusOptionsOrders = [{ id: 1, name: 'Start' }, { id: 2, name: 'Closed' }];
    this.documentTypeOptions = [{ id: 0, name: 'Outline' }, { id: 1, name: 'Order' }, { id: 2, name: 'Change Request' }, { id: 3, name: 'Closing Report' }];
    this.decisionOptions = [{ id: 0, name: 'Open' }, { id: 1, name: 'Approved' }, { id: 2, name: 'Returned' }, { id: 3, name: 'Rejected' }, { id: 4, name: 'On Hold' }];


    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      this.usersList = responseUsers;
      this.setFilterValues(this.filtersArray);
      this.filtersArray.push({ assignedTo: this.authService.userObj.id })
      this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray: this.filtersArray });
    });

    this.socketService.listen('ApprovalsFilterCount').subscribe(count => {
      //Limiting data to 100 records
      if (count < 100) {
        this.approvalsCount = count;
      } else {
        this.approvalsCount = 100;
      }

      this.socketService.emitEvent('ApprovalsFilter', { userId: this.authService.userObj.id, pageIndex: this.dropdownPageIndex, pageSize: 20, filtersArray: this.filtersArray });
    });

    this.socketService.listen('ApprovalsFilter').subscribe(projects => {
      this.approvalsList = this.approvalsList.concat(projects);
      this.dataGrid.instance.option('dataSource', this.approvalsList);
      this.spinner.hide();

      if (this.dropdownPageIndex < (this.approvalsCount / 20)) {
        this.dropdownPageIndex++;
        this.socketService.emitEvent('ApprovalsFilter', { userId: this.authService.userObj.id, pageIndex: this.dropdownPageIndex, pageSize: 20, filtersArray: this.filtersArray });
      }
    });

    this.socketService.listen('smallOrdersFilter').subscribe(projects => {
      this.approvalsSmallOrderList = projects;
      this.ordersGrid.instance.option('dataSource', this.approvalsSmallOrderList);
      this.spinner.hide();
    });

    this.socketService.listen("getSelectiveProjects").subscribe(response => {
      this.projectsSearchList = response;
      if (this.userIds.length > 0) {
        this.filtersArray = this.filtersArray;
        this.socketService.emitEvent('fetchMultipleUsers', { userIds: this.userIds });
      } else {
        this.setFilterValues(this.filtersArray);
        this.filtersArray.push({ assignedTo: this.authService.userObj.id })
        this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray: this.filtersArray });
      }
    });

    this.socketService.listen("getSelectiveOrders").subscribe(response => {
      this.ordersSearchList = response;

      this.setFilterValuesOrder(this.filtersArrayOrders);
      let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
      let finalFilter = this.filtersArrayOrders.concat(defaultFilter);

      this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });
    });

    let filtersArray = JSON.parse(localStorage.getItem("ApprovalsFilter")) || [];

    if (filtersArray && filtersArray.length > 0) {
      let userIds = [];
      let projectIds = [];

      filtersArray.forEach(filter => {
        if (Object.keys(filter)[0] == 'or') {
          if (Object.keys(filter['or'][0])[0] == 'projectOutline.projectManager.id') {
            userIds.push(filter['or'][0]['projectOutline.projectManager.id']);
          } else {
            userIds.push(filter['or'][0]['projectOutline.projectSponsor.id']);
          }
        }
        if (Object.keys(filter)[0] == 'project') {
          projectIds.push(filter['project']);
        }
      });

      this.filtersArray = filtersArray;
      this.userIds = userIds;

      if (projectIds.length > 0) {
        this.socketService.emitEvent("getSelectiveProjects", { ids: projectIds });
      } else {
        if (userIds.length > 0) {

          this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
        } else {
          this.setFilterValues(filtersArray);
          filtersArray.push({ assignedTo: this.authService.userObj.id })
          this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray });
        }
      }

    } else {
      this.decisionFilter = ['Open'];
      let filterVal = this.setFilters();
      this.filtersArray = filterVal;
      filterVal.push({ assignedTo: this.authService.userObj.id })
      this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray: filterVal });
      this.spinner.hide();
    }


    //For Orders, Setting filter 
    let filtersArrayOrders = JSON.parse(localStorage.getItem('approvalsSmallOrdersFilter')) || [];

    if (filtersArrayOrders && filtersArrayOrders.length > 0) {

      this.filtersArrayOrders = filtersArrayOrders;
      let orderIds = [];
      let userIds = [];

      filtersArrayOrders.forEach(filter => {
        if (Object.keys(filter)[0] == 'orderManager') {
          userIds.push(filter['orderManager']);
        }

        if (Object.keys(filter)[0] == 'id') {
          orderIds.push(filter['id']);
        }
      });

      if (userIds.length > 0) {
        this.apiService.get('/user/' + userIds[0]).subscribe(user => {
          this.usersList.push(user);
          if (orderIds.length > 0) {
            this.socketService.emitEvent("getSelectiveOrders", { ids: orderIds });
          } else {
            this.setFilterValuesOrder(this.filtersArrayOrders);
            let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
            let finalFilter = this.filtersArrayOrders.concat(defaultFilter);
            this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });
          }
        })
      } else {
        if (orderIds.length > 0) {
          this.socketService.emitEvent("getSelectiveOrders", { ids: orderIds });
        } else {
          this.setFilterValuesOrder(this.filtersArrayOrders);
          let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
          let finalFilter = this.filtersArrayOrders.concat(defaultFilter);
          this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });
        }
      }
    } else {
      this.statusFilterOrder = ['Start'];
      let filterVal = this.setFiltersOrders();
      let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
      let finalFilter = filterVal.concat(defaultFilter);
      this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });
      this.spinner.hide();
    }


    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    // this.socketService.listen('approvalsCount').subscribe(count => {
    //   this.approvalsCount = count;
    //   if (this.approvalsCount > 10) {
    //     this.socketService.emitEvent('approvalsIndex', { userId: this.authService.userObj.id, pageIndex: this.dropdownPageIndex, pageSize: 10 });
    //   }
    // });

    // this.socketService.listen('approvalsIndex').subscribe(approvals => {
    //   this.approvalsList = this.approvalsList.concat(approvals);

    //   if (this.dropdownPageIndex < (this.approvalsCount / 10)) {
    //     this.dropdownPageIndex++;
    //     this.socketService.emitEvent('approvalsIndex', { userId: this.authService.userObj.id, pageIndex: this.dropdownPageIndex, pageSize: 10 });
    //   }
    // });

    // this.socketService.listen('smallOrdersBySponsorCount').subscribe(count => {
    //   this.smallOrdersSponsorCount = count;

    //   if (count > 20) {
    //     this.socketService.emitEvent('smallOrdersBySponsorIndex', { pageIndex: this.smallOrdersSponsorPageIndex, pageSize: 20, userId: this.authService.userObj.id });
    //   }
    // });

    // this.socketService.listen('smallOrdersBySponsorIndex').subscribe((response: any) => {
    //   this.approvalsSmallOrderList = this.approvalsSmallOrderList.concat(response);

    //   if (this.smallOrdersSponsorPageIndex < (this.smallOrdersSponsorCount / 20)) {
    //     this.smallOrdersSponsorPageIndex++;
    //     this.socketService.emitEvent('smallOrdersBySponsorIndex', { pageIndex: this.smallOrdersSponsorPageIndex, pageSize: 20, userId: this.authService.userObj.id });
    //   }
    // });

    // this.socketService.listen('approvalsSearchIndex').subscribe(approvals => {
    //   this.approvalsList = approvals;
    // });

    // this.socketService.listen('approvalsSearch').subscribe(approvalsResult => {
    //   this.approvalsCount = approvalsResult.count;
    //   this.approvalsList = approvalsResult.approvals;
    // });

    this.approvalsSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
      if (value != undefined) {
        this.socketService.emitEvent('approvalsSearch', { userId: this.authService.userObj.id, search: value });
      }
      return [];
    })).subscribe((response: any) => { }, error => {
    });
  }

  ngAfterContentChecked() {
  }

  // fetchProjects() {
  //   this.spinner.show();
  //   this.dropdownPageIndex = 2;
  //   this.smallOrdersSponsorPageIndex = 2;
  //   this.apiService.get('/getOutlinesByUser/' + this.authService.userObj.id + '?limit=10').subscribe(response => {
  //     this.approvalsList = response;
  //     this.spinner.hide();
  //     this.socketService.emitEvent('approvalsCount', { userId: this.authService.userObj.id });
  //   });
  //   this.fetchSmallOrders();
  // }

  fetchSmallOrders() {
    this.apiService.get('/smallOrder/sponsor/' + this.authService.userObj.id + '?limit=20').subscribe((response: any) => {
      this.approvalsSmallOrderList = response;
      this.socketService.emitEvent('smallOrdersBySponsorCount', { userId: this.authService.userObj.id });
    });
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      "success",
    );
  }

  approvalsIndex(event) {
    if (this.approvalsSearch == '') {
      this.socketService.emitEvent('approvalsIndex', { userId: this.authService.userObj.id, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    } else {
      this.socketService.emitEvent('approvalsSearchIndex', { search: this.approvalsSearch, userId: this.authService.userObj.id, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    }
  }

  redirectToLink(link) {
    if (link != '') {
      window.open(link, "_blank");
    }
  }

  onSearchChange(event) {
    this.approvalsSubject.next(event.target.value)
  }

  actionEvent(event, item) {
    this.blockDropdown = true;

    if (item.key.project.docType == "Outline") {
      if (event.target.value == "Approve") {
        this.spinner.show();
        this.apiService.put('/outlineApproval/' + item.key.id, {
          status: 'Approved',
          isFreezed: true,
          // overallStatus: "Approved"
        }).subscribe((approvedProj: any) => {
          this.approvalsList.forEach((projectObj, index) => {
            if (projectObj.id == item.key.id) {
              this.approvalsList[index].status = "Approved";
              this.approvalsList[index].isFreezed = true;
              this.approvalsList[index].project = approvedProj.project;

              if (approvedProj.sentTo == "PMO") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "Sponsor") {
                this.approvalsList[index].overallStatus = "Approved";
              }
            }
          });

          this.dataGrid.instance.refresh();
          this.notificationService.createNotification(item.key.project.id, 'Project Outline', 'approved', item.key.assignedTo.id);

          if (approvedProj.sentTo == "PMO") {
            this.apiService.put('/projects/' + item.key.project.id, {
              workflowStatus: 'Outline has been sent to Project Sponsor for approval.',
              pmoApprovedOutlineDate: new Date().toISOString(),
              isPMOApprovedOutline: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              let projectResp = Object.assign({}, response);
              delete (projectResp.projectOutline);
              delete (projectResp.projectOrder);
              delete (projectResp.history);
              delete (projectResp.changeRequests);
              delete (projectResp.closingReport);
              delete (projectResp.approvals);
              delete (projectResp.user);
              delete (projectResp.subPortfolio);

              let approvedOutline = approvedProj.projectOutline;
              approvedOutline.projectId = projectResp;
              approvedOutline.projectId.isPMOApprovedOutline = true;
              approvedOutline.status = 'Submitted';

              this.apiService.post('/outlineApproval', {
                projectOutline: approvedOutline,
                status: "Open",
                overallStatus: "Submitted",
                projectStatus: 'Submitted',
                assignedTo: approvedProj.projectOutline.projectSponsor.id,
                project: approvedProj.project.id,
                docType: 'Outline',
                sentTo: "Sponsor",
                isFreezed: false,
                uid: approvedProj.uid,
                version: approvedProj.projectOutline.version,
                isPMOApprovedOutline: true,
                pmoApprovedOutlineDate: new Date().toISOString(),
              }).subscribe((newApproval: any) => {
                this.apiService.put('/outlineApproval/' + item.key.id, {
                  projectOutline: approvedOutline,
                  isPMOApprovedOutline: true,
                  projectStatus: 'Submitted',
                  overallStatus: "Submitted",
                  pmoApprovedOutlineDate: new Date().toISOString(),
                }).subscribe((resp: any) => {
                  this.approvalsList.find(val => val.id == item.key.id).project = response;
                  this.dataGrid.instance.refresh();
                  this.notification('Success', "Outline has been sent to Project Sponsor for approval.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.blockDropdown = false;
                  this.spinner.hide();
                  this.apiService.post('/sendEmail', {
                    email: newApproval.projectOutline.projectSponsor.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                   <a href="${this.apiService.FrontEndPATH}/outlinePreview/${newApproval.id}" target="_blank">${'Projektskizze / Project Outline : ' + response.projectName}</a>`
                  }).subscribe(msg => { });
                });
              });
            });
          } else if (approvedProj.sentTo == "Sponsor") {
            this.apiService.put('/projects/' + item.key.project.id, {
              outlineApproved: true,
              status: 'Approved',
              workflowStatus: 'Outline has been approved.',
              sponsorApprovedDate: new Date().toISOString(),
              isSponsorApproved: true
            }).subscribe((project: any) => {
              let projectResponse = Object.assign({}, project);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedOutineNew = approvedProj.projectOutline;
              approvedOutineNew.projectId = projectResponse;
              approvedOutineNew.status = 'Approved';
              this.approvalsList.forEach((projectObj, index) => {
                if (projectObj.id == item.key.id) {
                  this.approvalsList[index].projectOutline.projectId = projectResponse;
                }
              });
              this.apiService.put('/outlineApproval/' + item.key.id, {
                projectOutline: approvedOutineNew,
                overallStatus: "Approved",
                isPMOApprovedOutline: true,
                isSponsorApproved: true,
                sponsorApprovedDate: new Date().toISOString(),
                projectStatus: 'Submitted',
              }).subscribe((resp: any) => {
                this.apiService.post('/updatepreviousapproval/', {
                  query: {
                    project: resp.project.id,
                    docType: 'Outline',
                    sentTo: 'PMO',
                    version: resp.version
                  },
                  projectItem: {
                    projectOutline: approvedOutineNew,
                    isSponsorApproved: true,
                    projectStatus: 'Approved',
                    sponsorApprovedDate: new Date().toISOString(),
                    overallStatus: "Approved"
                  }
                }).subscribe((resp: any) => {
                  let budget = [
                    {
                      costType: "External Costs",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 0,
                      group: "CAPEX",
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 1,
                      group: "CAPEX",
                    }, {
                      costType: "External Costs",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 2,
                      group: "OPEX"
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 3,
                      group: "OPEX"
                    }, {
                      costType: "Revenues",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 4,
                      group: "Sonstiges",
                    }, {
                      costType: "Reserves",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      group: "Sonstiges",
                      id: 5,
                    }, {
                      costType: "Total",
                      budget: '',
                      thereofIT: '',
                      currentBudget: "",
                      yearlyForecast: '',
                      thereofITForecast: '',
                      remainingProjectBudget: "",
                      id: 6,
                      group: "Sonstiges",
                    },
                  ];

                  this.apiService.post('/projectBudgetCost/createForSubportfolio', {
                    subPortfolio: project.subPortfolio.id,
                    project: project.id,
                    budget: budget
                  }).subscribe((projectBudgetCostResponce: any) => {

                    this.approvalsList.find(val => val.id == item.key.id).project = project;
                    this.dataGrid.instance.refresh();
                    let startDate = project.projectOutline[0].startOfProjectOrder;
                    let endDate = project.projectOutline[0].initiationApprovalDate;
                    project.projectOutline[0].plannedEndDate = project.projectOutline[0].initiationApprovalDate;
                    project.projectOutline[0].forecastEndDate = project.projectOutline[0].initiationApprovalDate;

                    let costTypeTable = [
                      {
                        costTypes: "External Costs",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 0,
                        group: 'CAPEX',
                      },
                      {
                        costTypes: "Internal Costs",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 1,
                        group: 'CAPEX',
                      },
                      {
                        costTypes: "External Costs",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 2,
                        group: 'OPEX',
                      },
                      {
                        costTypes: "Internal Costs",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 3,
                        group: 'OPEX',
                      },
                      {
                        costTypes: "Revenues",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 4,
                        group: 'Sonstiges',
                      },
                      {
                        costTypes: "Reserves",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 5,
                        group: 'Sonstiges',
                      },
                      {
                        costTypes: "Total",
                        actualCost: '',
                        forecast: '',
                        currentBudget: "",
                        originalBudget: '',
                        id: 6,
                        group: 'Sonstiges',
                      },];

                    project.projectOutline[0].fundsApprovedForInitiationTable.forEach((value, index) => {
                      costTypeTable[index].forecast = value.budget;
                      costTypeTable[index].currentBudget = value.budget;
                      costTypeTable[index].originalBudget = value.budget;
                    });

                    let estimatedProjectCostTableOutline = project.projectOutline[0].estimatedProjectTable;
                    let projectBudgetCurrent = project.projectOutline[0].fundsApprovedForInitiationTable[4].total;
                    delete (project.projectOutline[0].fundsApprovedForInitiationTable);

                    let risks = [
                      {
                        id: 1,
                        name: '',
                        description: '',
                        probability: '',
                        extentOfLosses: '',
                        riskExposure: '',
                        riskStrategy: '',
                        reputationRisks: '',
                        measures: '',
                        report: true,
                      }, {
                        id: 2,
                        name: '',
                        description: '',
                        probability: '',
                        extentOfLosses: '',
                        riskExposure: '',
                        riskStrategy: '',
                        reputationRisks: '',
                        measures: '',
                        report: true,
                      }, {
                        id: 3,
                        name: '',
                        description: '',
                        probability: '',
                        extentOfLosses: '',
                        riskExposure: '',
                        riskStrategy: '',
                        reputationRisks: '',
                        measures: '',
                        report: true,
                      }, {
                        id: 4,
                        name: '',
                        description: '',
                        probability: '',
                        extentOfLosses: '',
                        riskExposure: '',
                        riskStrategy: '',
                        reputationRisks: '',
                        measures: '',
                        report: true,
                      }, {
                        id: 5,
                        name: '',
                        description: '',
                        probability: '',
                        extentOfLosses: '',
                        riskExposure: '',
                        riskStrategy: '',
                        reputationRisks: '',
                        measures: '',
                        report: true,
                      },];

                    let mileStonesValues = [
                      {
                        id: 1,
                        name: '',
                        description: "",
                        dueDate: '',
                        report: true
                      },
                      {
                        id: 2,
                        name: '',
                        description: "",
                        dueDate: '',
                        report: true
                      },
                      {
                        id: 3,
                        name: '',
                        description: "",
                        dueDate: '',
                        report: true
                      },
                      {
                        id: 4,
                        name: '',
                        description: "",
                        dueDate: '',
                        report: true
                      },
                      {
                        id: 5,
                        name: '',
                        description: "",
                        dueDate: '',
                        report: true
                      }
                    ];
                    let actualCostTable = [{
                      costType: "External Costs",
                      actualCost: '',
                      id: 0,
                      group: "CAPEX",
                      actualBudget: '',
                    }, {
                      costType: "Internal Costs",
                      actualCost: '',
                      id: 1,
                      group: "CAPEX",
                      actualBudget: '',
                    }, {
                      costType: "External Costs",
                      actualCost: '',
                      id: 2,
                      group: "OPEX",
                      actualBudget: '',
                    }, {
                      costType: "Internal Costs",
                      actualCost: '',
                      id: 3,
                      group: "OPEX",
                      actualBudget: '',
                    }, {
                      costType: "Revenues",
                      actualCost: '',
                      id: 4,
                      group: "Sonstiges",
                      actualBudget: '',
                    }, {
                      costType: "Reserves",
                      actualCost: '',
                      group: "Sonstiges",
                      actualBudget: '',
                      id: 5,
                    }, {
                      costType: "Total",
                      actualCost: '',
                      id: 6,
                      group: "Sonstiges",
                      actualBudget: '',
                    },];
                    let budgetPlanningTable1 = [{
                      costType: "External Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 0,
                      group: "CAPEX",
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 1,
                      group: "CAPEX",
                    }, {
                      costType: "External Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 2,
                      group: "OPEX"
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 3,
                      group: "OPEX"
                    }, {
                      costType: "Revenues",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 4,
                      group: "Sonstiges",
                    }, {
                      costType: "Reserves",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      group: "Sonstiges",
                      id: 5,
                    }, {
                      costType: "Total",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 6,
                      group: "Sonstiges",
                    },];
                    let budgetPlanningTable2 = [{
                      costType: "External Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 0,
                      group: "CAPEX",
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 1,
                      group: "CAPEX",
                    }, {
                      costType: "External Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 2,
                      group: "OPEX"
                    }, {
                      costType: "Internal Costs",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 3,
                      group: "OPEX"
                    }, {
                      costType: "Revenues",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 4,
                      group: "Sonstiges",
                    }, {
                      costType: "Reserves",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      group: "Sonstiges",
                      id: 5,
                    }, {
                      costType: "Total",
                      budget: '',
                      thereofICT: '',
                      actualCost: "",
                      forecast: "",
                      id: 6,
                      group: "Sonstiges",
                    },];

                    project.projectOutline[0].risksTable.forEach((element, index) => {
                      risks[index].description = element.description
                    });

                    delete (project.projectOutline[0].attachment1);
                    delete (project.projectOutline[0].attachment2);
                    delete (project.projectOutline[0].attachments);
                    delete (project.projectOutline[0].isAttachement1Added);
                    delete (project.projectOutline[0].isAttachement2Added);
                    let outlineStartDate = project.projectOutline[0].startDate;
                    let outlineEndDate = project.projectOutline[0].endDate;
                    delete (project.projectOutline[0].startDate);
                    delete (project.projectOutline[0].endDate);
                    delete (project.projectOutline[0].startOfProjectOrder);
                    delete (project.projectOutline[0].initiationApprovalDate);

                    let projectPhaseOptions = this.utilityService.projectPhaseOptions;
                    let projectPhase;

                    if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
                      projectPhase = projectPhaseOptions.values.find(val => val.name == 'Initiating').id;
                    }

                    this.apiService.post('/reports', {
                      uid: project.uid,
                      ...project.projectOutline[0],
                      projectName: project.projectName,
                      user: project.user.id,
                      startDate: startDate,
                      endDate: endDate,
                      plannedEndDate: endDate,
                      forecastEndDate: endDate,
                      outlineStartDate,
                      outlineEndDate,
                      project: item.key.project.id,
                      status: 'Active',
                      projectPhase: projectPhase ? projectPhase : undefined,
                      program: project.projectOutline[0].program,
                      projectBudgetCurrent: projectBudgetCurrent,
                      costTypeTable: costTypeTable,
                      estimatedProjectCostTableOutline: estimatedProjectCostTableOutline,
                      currentReserveHistory: [],
                      risks: risks,
                      mileStonesValues: mileStonesValues,
                      reportRelevantRisksCount: 0,
                      actualCostTable,
                      budgetPlanningTable1,
                      budgetPlanningTable2,
                    }).subscribe((report: any) => {
                      this.apiService.put('/projects/' + item.key.project.id, {
                        projectReport: report.id
                      }).subscribe((response: any) => {
                        let status = '';
                        if (project.user.role != 'admin' || project.user.role != 'pmo') {
                          status = 'pm';
                        } else {
                          status = project.user.role;
                        }

                        this.apiService.put('/user/' + project.user.id, {
                          status: status
                        }).subscribe((userResponse: any) => {
                          this.apiService.post('/history', {
                            date: Date.now(),
                            project: project.id,
                            document: 'Project Outline',
                            status: 'Approved'
                          }).subscribe(historyResponse => {

                            this.apiService.post('/team', {
                              users: [report.projectSponsor.id],
                              project: project.id
                            }).subscribe((responseTeam: any) => {
                              let teamId = responseTeam.id;
                              this.apiService.post('/rights', {
                                user: report.projectSponsor.id,
                                project: project.id,
                                isView: true,
                                isEdit: true
                              }).subscribe((responseRights: any) => {
                                this.notification('Success', "Outline has been approved.");
                                this.utilityService.getUsersOpenOutlinesCount();
                                this.blockDropdown = false;
                                this.apiService.put('/projectOutline/' + project.projectOutline[0].id, {
                                  status: 'Approved',
                                }).subscribe(outlineResponse => {
                                  // send email reminder for project order creation if end of project order date lies under 14 days according to current date
                                  let initiationApprovalDate = moment(endDate);
                                  let currentDate = moment(new Date());
                                  let dateDiffDays = initiationApprovalDate.diff(currentDate, 'days');
                                  ++dateDiffDays;
                                  if (dateDiffDays <= 14) {
                                    this.apiService.post('/sendEmail', {
                                      email: project.user.email,
                                      subject: `Reminder oneView Projekt ID ${project.uid}:  Projektauftrag fällig`,
                                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Email Reminder Project Order')[0].text
                                    }).subscribe(msg => { });
                                  }
                                  this.spinner.hide();
                                  this.apiService.post('/sendEmail', {
                                    email: userResponse.email,
                                    subject: "oneView: Projekt Genehmigungsworkflow",                                            // Approval
                                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Approval Confirmation')[0].text + `<br>` +
                                      'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
                                  }).subscribe(msg => { });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          }
        });
      } else if (event.target.value == "On Hold") {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'On Hold',
          workflowStatus: 'Outline has been put on hold.'
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'On Hold',
            projectStatus: 'On Hold',
            isFreezed: false,
            overallStatus: "On Hold"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOutineNew = appRes.projectOutline;
            approvedOutineNew.projectId = projectResponse;
            approvedOutineNew.status = 'On Hold';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOutline: approvedOutineNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Outline',
                  version: resp.version
                },
                projectItem: { projectOutline: approvedOutineNew, projectStatus: 'On Hold', overallStatus: "On Hold" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "On Hold";
                    this.approvalsList[index].overallStatus = "On Hold";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Project Outline', 'held', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;
                this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
                  status: 'On Hold',
                }).subscribe(outlineResponse => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.projectOutline.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline On Hold')[0].text + `<br>` +
                      'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
                  }).subscribe(msg => { });
                  this.notification('Success', "Outline is on Hold.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Return to Sender') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Returned',
          isFreezed: false,
          outlineReturned: true,
          outlineSubmitted: true,
          workflowStatus: 'Outline has been returned from approver.',
          outlineReturnDate: new Date().toISOString(),
        }).subscribe((response: any) => {
          this.approvalsList.find(val => val.id == item.key.id).project = response;
          this.dataGrid.instance.refresh();
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Returned',
            isFreezed: true,
            outlineReturned: true,
            projectStatus: 'Returned',
            overallStatus: "Returned",
            outlineReturnDate: new Date().toISOString(),
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOutineNew = appRes.projectOutline;
            approvedOutineNew.projectId = projectResponse;
            approvedOutineNew.projectId.status = 'Returned';
            approvedOutineNew.status = 'Returned';
            approvedOutineNew.projectId.workflowStatus = 'Outline has been returned from approver.'
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOutline: approvedOutineNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Outline',
                  version: resp.version
                },
                projectItem: { projectOutline: approvedOutineNew, projectStatus: 'Returned', outlineReturnDate: new Date().toISOString(), overallStatus: "Returned" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Returned";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Returned";
                  }
                });
              });
              this.notificationService.createNotification(item.key.project.id, 'Project Outline', 'returned', item.key.assignedTo.id);
              this.dataGrid.instance.refresh();
              this.blockDropdown = false;

              this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
                status: 'Returned',
              }).subscribe(outlineResponse => {
                this.apiService.post('/sendEmail', {
                  email: appRes.projectOutline.projectManager.email,
                  subject: "oneView: Projekt Genehmigungsworkflow",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Returned')[0].text + `<br>` +
                    'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
                }).subscribe(msg => { });
                this.notification('Success', "Outline has been returned to sender.");
                this.utilityService.getUsersOpenOutlinesCount();
                this.spinner.hide();
              });
            });
          });
        });
      } else if (event.target.value == 'Reject') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Rejected',
          isFreezed: true,
          //outlineSubmitted: false,
          workflowStatus: 'Outline has been Rejected.',
          outlineRejectDate: new Date().toISOString(),
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Rejected',
            projectStatus: 'Rejected',
            isFreezed: true,
            outlineRejectDate: new Date().toISOString(),
            overallStatus: "Rejected"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOutineNew = appRes.projectOutline;
            approvedOutineNew.projectId = projectResponse;
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOutline: approvedOutineNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Outline',
                  version: resp.version
                },
                projectItem: { projectOutline: approvedOutineNew, projectStatus: 'Rejected', outlineRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Rejected";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Rejected";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Project Outline', 'rejected', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
                  status: 'Rejected',
                }).subscribe(outlineResponse => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.projectOutline.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Outline Rejected')[0].text + `<br>` +
                      'Projektskizze / Project Outline : ' + response.projectName + `<br><br><br>`
                  }).subscribe(msg => { });
                  this.notification('Success', "Outline has been Rejected.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      }
    } else if (item.key.project.docType == "Order") {
      if (event.target.value == "Approve") {
        this.spinner.show();
        this.apiService.put('/outlineApproval/' + item.key.id, {
          status: 'Approved',
          isFreezed: true,
          // overallStatus: "Approved"
        }).subscribe((approvedProj: any) => {
          this.approvalsList.forEach((projectObj, index) => {
            if (projectObj.id == item.key.id) {
              this.approvalsList[index].status = "Approved";
              this.approvalsList[index].isFreezed = true;

              if (approvedProj.sentTo == "PMO") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "Sponsor") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "FICO") {
                this.approvalsList[index].overallStatus = "Approved";
              }
            }
          });
          this.notificationService.createNotification(item.key.project.id, 'Project Order', 'approved', item.key.assignedTo.id);
          this.dataGrid.instance.refresh();

          if (approvedProj.sentTo == "PMO") {
            this.apiService.put('/projects/' + item.key.project.id, {
              workflowStatus: 'Order has been sent to Project Sponsor for approval.',
              pmoApprovedOrderDate: new Date().toISOString(),
              isPMOApprovedOrder: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              let projectResponse = Object.assign({}, response);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedOrderNew = approvedProj.projectOrder;
              approvedOrderNew.projectId = projectResponse;
              approvedOrderNew.projectId.isPMOApprovedOrder = true;
              approvedOrderNew.status = 'Submitted';
              this.apiService.post('/outlineApproval', {
                projectOrder: approvedOrderNew,
                status: "Open",
                projectStatus: 'Submitted',
                overallStatus: "Submitted",
                assignedTo: approvedProj.projectOrder.projectSponsor.id,
                project: approvedProj.project.id,
                docType: 'Order',
                sentTo: "Sponsor",
                isFreezed: false,
                uid: approvedProj.uid,
                version: approvedProj.projectOrder.version,
                isPMOApprovedOrder: true,
                pmoApprovedOrderDate: new Date().toISOString(),
              }).subscribe((newApproval: any) => {
                this.apiService.put('/outlineApproval/' + item.key.id, {
                  projectOrder: approvedOrderNew,
                  isPMOApprovedOrder: true,
                  projectStatus: 'Submitted',
                  overallStatus: "Submitted",
                  pmoApprovedOrderDate: new Date().toISOString()
                }).subscribe((resp: any) => {
                  this.approvalsList.find(val => val.id == item.key.id).project = response;
                  this.dataGrid.instance.refresh();
                  this.notification('Success', "Order has been sent to Project Sponsor for approval.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.blockDropdown = false;
                  this.spinner.hide();
                  this.apiService.post('/sendEmail', {
                    email: newApproval.projectOrder.projectSponsor.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                  <a href="${this.apiService.FrontEndPATH}/orderPreview/${newApproval.id}" target="_blank">${'Projektauftrag / Project Order : ' + response.projectName}</a>`
                  }).subscribe(msg => { });
                });
              });
            });
          } else if (approvedProj.sentTo == "Sponsor") {
            this.apiService.put('/projects/' + item.key.project.id, {
              workflowStatus: 'Order has been sent to Financial Manager for approval.',
              sponsorApprovedOrderDate: new Date().toISOString(),
              isSponsorApprovedOrder: true,
              status: 'Submitted'
            }).subscribe((response: any) => {
              let projectResponse = Object.assign({}, response);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedOrderNew = approvedProj.projectOrder;
              approvedOrderNew.projectId = projectResponse;
              approvedProj.projectOrder.projectId.isSponsorApprovedOrder = true;
              approvedOrderNew.status = 'Submitted';

              this.apiService.post('/outlineApproval', {
                projectOrder: approvedOrderNew,
                status: "Open",
                projectStatus: 'Submitted',
                overallStatus: "Submitted",
                assignedTo: approvedProj.projectOrder.projectFico.id,
                project: approvedProj.project.id,
                docType: 'Order',
                sentTo: "FICO",
                isFreezed: false,
                uid: approvedProj.uid,
                version: approvedProj.projectOrder.version,
                isPMOApprovedOrder: true,
                isSponsorApprovedOrder: true,
                sponsorApprovedOrderDate: new Date().toISOString(),
                pmoApprovedOrderDate: item.key.pmoApprovedOrderDate
              }).subscribe((newApproval: any) => {
                this.apiService.put('/outlineApproval/' + item.key.id, {
                  projectOrder: approvedOrderNew,
                  isPMOApprovedOrder: true,
                  isSponsorApprovedOrder: true,
                  projectStatus: 'Submitted',
                  overallStatus: "Submitted",
                  sponsorApprovedOrderDate: new Date().toISOString(),
                }).subscribe((resp: any) => {
                  this.apiService.post('/updatepreviousapproval/', {
                    query: {
                      project: resp.project.id,
                      docType: 'Order',
                      sentTo: 'PMO',
                      version: resp.version
                    },
                    projectItem: {
                      projectOrder: approvedOrderNew,
                      isSponsorApprovedOrder: true,
                      projectStatus: 'Submitted',
                      sponsorApprovedOrderDate: new Date().toISOString(),
                      overallStatus: "Submitted"
                    }
                  }).subscribe((resp: any) => {
                    this.approvalsList.find(val => val.id == item.key.id).project = response;
                    this.dataGrid.instance.refresh();
                    this.notification('Success', "Order has been sent to Financial Manager for approval.");
                    this.utilityService.getUsersOpenOutlinesCount();
                    this.blockDropdown = false;
                    this.spinner.hide();
                    this.apiService.post('/sendEmail', {
                      email: newApproval.projectOrder.projectFico.email,
                      subject: "oneView: Projekt Genehmigungsworkflow",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                  <a href="${this.apiService.FrontEndPATH}/orderPreview/${newApproval.id}" target="_blank">${'Projektauftrag / Project Order : ' + response.projectName}</a>`
                    }).subscribe(msg => { });
                  });
                });
              });
            });
          } else if (approvedProj.sentTo == "FICO") {
            this.apiService.put('/projects/' + item.key.project.id, {
              orderApproved: true,
              status: 'Approved',
              workflowStatus: 'Order has been approved.',
              ficoApprovedOrderDate: new Date().toISOString(),
              isFicoApprovedOrder: true,
              isOrderFreezed: true
            }).subscribe((project: any) => {
              let projectResponse = Object.assign({}, project);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedOrderNew = approvedProj.projectOrder;
              approvedOrderNew.projectId = projectResponse;
              this.apiService.put('/outlineApproval/' + item.key.id, {
                projectOrder: approvedOrderNew,
                isPMOApprovedOrder: true,
                isSponsorApprovedOrder: true,
                isFicoApprovedOrder: true,
                ficoApprovedOrderDate: new Date().toISOString(),
                projectStatus: 'Approved',
                overallStatus: "Approved"
              }).subscribe((resp: any) => {
                this.apiService.post('/updatepreviousapproval/', {
                  query: {
                    project: resp.project.id,
                    docType: 'Order',
                    version: resp.version
                  },
                  projectItem: {
                    projectOrder: approvedOrderNew,
                    isFicoApprovedOrder: true,
                    projectStatus: 'Approved',
                    ficoApprovedOrderDate: new Date().toISOString(),
                    overallStatus: "Approved"
                  }
                }).subscribe((resp: any) => {
                  this.approvalsList.find(val => val.id == item.key.id).project = project;
                  this.dataGrid.instance.refresh();
                  this.reportOverwriter(approvedProj, project);
                });
              });
            });
          }
        });
      } else if (event.target.value == "On Hold") {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'On Hold',
          workflowStatus: 'Order has been put on hold.'
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'On Hold',
            projectStatus: 'On Hold',
            overallStatus: "On Hold"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOrderNew = appRes.projectOrder;
            approvedOrderNew.projectId = projectResponse;
            approvedOrderNew.status = 'On Hold';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOrder: approvedOrderNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Order',
                  version: resp.version
                },
                projectItem: { projectOrder: approvedOrderNew, projectStatus: 'On Hold', overallStatus: "On Hold" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "On Hold";
                    this.approvalsList[index].overallStatus = "On Hold";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Project Order', 'held', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
                  status: 'On Hold',
                }).subscribe(orderRes => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.projectOrder.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Order On Hold')[0].text + `<br>` +
                      'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
                  }).subscribe(msg => { });
                  this.notification('Success', "Order has been put on hold.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Return to Sender') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Returned',
          isOrderFreezed: false,
          orderSubmitted: true,
          // isPMOApprovedOrder: false,
          // isSponsorApprovedOrder: false,
          orderReturned: true,
          workflowStatus: 'Order has been returned back from approver.',
          orderReturnDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.approvalsList.find(val => val.id == item.key.id).project = response;
          this.dataGrid.instance.refresh();
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Returned',
            projectStatus: 'Returned',
            orderReturned: true,
            isFreezed: true,
            orderReturnDate: new Date().toISOString(),
            overallStatus: "Returned"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOrderNew = appRes.projectOrder;
            approvedOrderNew.projectId.status = 'Returned';
            approvedOrderNew.projectId.orderReturnDate = new Date().toISOString();
            approvedOrderNew.status = 'Returned';
            approvedOrderNew.projectId.workflowStatus = 'Order has been returned back from approver.'
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOrder: approvedOrderNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Order',
                  version: resp.version
                },
                projectItem: { projectOrder: approvedOrderNew, projectStatus: 'Returned', orderReturnDate: new Date().toISOString(), overallStatus: "Returned" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Returned";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Returned";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Project Order', 'returned', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
                  status: 'Returned',
                }).subscribe(orderRes => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.projectOrder.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Returned')[0].text + `<br>` +
                      'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
                  }).subscribe(msg => { });
                  this.notification('Success', "Order has been returned to sender.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Reject') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Rejected',
          workflowStatus: 'Order has been rejected.',
          //orderSubmitted: false,
          isOrderFreezed: true,
          orderRejectDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Rejected',
            projectStatus: 'Rejected',
            isFreezed: true,
            orderRejectDate: new Date().toISOString(),
            overallStatus: "Rejected"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedOrderNew = appRes.projectOrder;
            approvedOrderNew.projectId = projectResponse;
            approvedOrderNew.projectId.orderRejectDate = new Date().toISOString();
            approvedOrderNew.status = 'Rejected';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              projectOrder: approvedOrderNew
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Order',
                  version: resp.version
                },
                projectItem: { projectOrder: approvedOrderNew, projectStatus: 'Rejected', orderRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Rejected";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Rejected";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Project Order', 'rejected', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
                  status: 'Rejected',
                }).subscribe(orderRes => {
                  this.apiService.put('/reports/' + response.projectReport.id, {
                    status: "Closed"
                  }).subscribe(reportResponse => {
                    this.apiService.post('/sendEmail', {
                      email: appRes.projectOrder.projectManager.email,
                      subject: "oneView: Projekt Genehmigungsworkflow",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Rejected')[0].text + `<br>` +
                        'Projektauftrag / Project Order : ' + response.projectName + `<br><br><br>`
                    }).subscribe(msg => { });
                    this.notification('Success', "Order has been rejected.");
                    this.utilityService.getUsersOpenOutlinesCount();
                    this.spinner.hide();
                  });
                });
              });
            });
          });
        });
      }
    } else if (item.key.project.docType == "Change Request") {
      if (event.target.value == "Approve") {
        this.spinner.show();
        this.apiService.put('/outlineApproval/' + item.key.id, {
          status: 'Approved',
          isFreezed: true,
          // overallStatus: "Approved"
        }).subscribe((approvedProj: any) => {
          this.approvalsList.forEach((projectObj, index) => {
            if (projectObj.id == item.key.id) {
              this.approvalsList[index].status = "Approved";
              this.approvalsList[index].isFreezed = true;

              if (approvedProj.sentTo == "PMO") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "Sponsor") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "FICO") {
                this.approvalsList[index].overallStatus = "Approved";
              }
            }
          });
          this.notificationService.createNotification(item.key.project.id, 'Change Request', 'approved', item.key.assignedTo.id);
          this.dataGrid.instance.refresh();

          if (approvedProj.sentTo == "PMO") {
            this.apiService.get('/changeRequest/' + approvedProj.changeRequest.id).subscribe((crResponse: any) => {
              this.apiService.put('/projects/' + item.key.project.id, {
                workflowStatus: 'Change Request has been sent to Project Sponsor for approval.',
                pmoApprovedChangeRequestDate: new Date().toISOString(),
                isPMOApprovedChangeRequest: true,
                status: 'Submitted'
              }).subscribe((response: any) => {
                this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
                  pmoApprovedChangeRequestDate: new Date().toISOString(),
                  isPMOApprovedChangeRequest: true,
                  status: 'Submitted',
                }).subscribe(changeRequestResponse => {
                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    changeRequest: changeRequestResponse,
                    isPMOApprovedChangeRequest: true,
                    projectStatus: 'Submitted',
                    pmoApprovedChangeRequestDate: new Date().toISOString(),
                    overallStatus: "Submitted"
                  }).subscribe((approvalResponse: any) => {
                    let projectResponse = Object.assign({}, response);
                    delete (projectResponse.projectOutline);
                    delete (projectResponse.projectOrder);
                    delete (projectResponse.history);
                    delete (projectResponse.changeRequests);
                    delete (projectResponse.closingReport);
                    delete (projectResponse.approvals);
                    delete (projectResponse.user);
                    delete (projectResponse.subPortfolio);

                    let approvedchangeRequest = approvalResponse.changeRequest;
                    approvedchangeRequest.projectId = projectResponse;
                    approvedchangeRequest.projectId.isPMOApprovedChangeRequest = true;
                    approvedchangeRequest.status = 'Submitted';

                    this.apiService.post('/outlineApproval', {
                      changeRequest: approvedchangeRequest,
                      status: "Open",
                      projectStatus: 'Submitted',
                      overallStatus: "Submitted",
                      assignedTo: crResponse.projectSponsor.id,
                      project: approvedProj.project.id,
                      docType: 'Change Request',
                      sentTo: "Sponsor",
                      isFreezed: false,
                      uid: approvedProj.uid,
                      version: approvedProj.version,
                      crNo: approvedProj.crNo,
                      isPMOApprovedChangeRequest: true,
                      pmoApprovedChangeRequestDate: new Date().toISOString(),
                    }).subscribe((newApproval: any) => {
                      this.apiService.put('/outlineApproval/' + item.key.id, {
                        changeRequest: approvedchangeRequest
                      }).subscribe((resp: any) => {
                        this.approvalsList.find(val => val.id == item.key.id).changeRequest = approvalResponse.changeRequest;
                        this.dataGrid.instance.refresh();
                        this.notification('Success', "Change Request has been sent to Project Sponsor for approval.");
                        this.utilityService.getUsersOpenOutlinesCount();
                        this.blockDropdown = false;
                        this.spinner.hide();
                        this.apiService.post('/sendEmail', {
                          email: newApproval.changeRequest.projectSponsor.email,
                          subject: "oneView: Projekt Genehmigungsworkflow",
                          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                          <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${newApproval.id}" target="_blank">${'Ã„nderungsantrag / Change Request : ' + response.projectName}</a>`
                        }).subscribe(msg => { });
                      });
                    });
                  });
                });
              });
            });
          } else if (approvedProj.sentTo == "Sponsor") {
            this.apiService.get('/changeRequest/' + approvedProj.changeRequest.id).subscribe((crResponse: any) => {
              this.apiService.put('/projects/' + item.key.project.id, {
                workflowStatus: 'Change Request has been sent to Financial Manager for approval.',
                sponsorApprovedChangeRequestDate: new Date().toISOString(),
                isSponsorApprovedChangeRequest: true,
                status: 'Submitted'
              }).subscribe((response: any) => {
                this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
                  sponsorApprovedChangeRequestDate: new Date().toISOString(),
                  isSponsorApprovedChangeRequest: true,
                  status: 'Submitted'
                }).subscribe(changeRequestResponse => {
                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    changeRequest: changeRequestResponse,
                    isPMOApprovedChangeRequest: true,
                    isSponsorApprovedChangeRequest: true,
                    projectStatus: 'Submitted',
                    sponsorApprovedChangeRequestDate: new Date().toISOString(),
                    overallStatus: "Submitted"
                  }).subscribe((approvalResponse: any) => {
                    let projectResponse = Object.assign({}, response);
                    delete (projectResponse.projectOutline);
                    delete (projectResponse.projectOrder);
                    delete (projectResponse.history);
                    delete (projectResponse.changeRequests);
                    delete (projectResponse.closingReport);
                    delete (projectResponse.approvals);
                    delete (projectResponse.user);
                    delete (projectResponse.subPortfolio);
                    let approvedchangeRequest = approvalResponse.changeRequest;
                    approvedchangeRequest.projectId = projectResponse;
                    approvedchangeRequest.projectId.isSponsorApprovedChangeRequest = true;
                    approvedchangeRequest.status = 'Submitted';

                    this.apiService.post('/outlineApproval', {
                      changeRequest: approvedchangeRequest,
                      status: "Open",
                      projectStatus: 'Submitted',
                      overallStatus: "Submitted",
                      assignedTo: crResponse.projectFico.id,
                      project: approvedProj.project.id,
                      docType: 'Change Request',
                      sentTo: "FICO",
                      isFreezed: false,
                      uid: approvedProj.uid,
                      version: approvedProj.version,
                      crNo: approvedProj.crNo,
                      isPMOApprovedChangeRequest: true,
                      isSponsorApprovedChangeRequest: true,
                      pmoApprovedChangeRequestDate: item.key.pmoApprovedChangeRequestDate,
                      sponsorApprovedChangeRequestDate: new Date().toISOString(),
                    }).subscribe((newApproval: any) => {
                      this.apiService.put('/outlineApproval/' + item.key.id, {
                        changeRequest: approvedchangeRequest
                      }).subscribe((resp: any) => {
                        this.apiService.post('/updatepreviousapproval/', {
                          query: {
                            project: resp.project.id,
                            docType: 'Change Request',
                            sentTo: 'PMO',
                            version: resp.version,
                            crNo: resp.crNo,
                          },
                          projectItem: {
                            changeRequest: approvedchangeRequest,
                            isSponsorApprovedChangeRequest: true,
                            projectStatus: 'Submitted',
                            sponsorApprovedChangeRequestDate: new Date().toISOString(),
                            overallStatus: "Submitted"
                          }
                        }).subscribe((resp: any) => {
                          this.approvalsList.find(val => val.id == item.key.id).changeRequest = approvalResponse.changeRequest;
                          this.dataGrid.instance.refresh();
                          this.notification('Success', "Change Request has been sent to Financial Manager for approval.");
                          this.utilityService.getUsersOpenOutlinesCount();
                          this.blockDropdown = false;
                          this.spinner.hide();

                          this.apiService.post('/sendEmail', {
                            email: newApproval.changeRequest.projectSponsor.email,
                            subject: "oneView: Projekt Genehmigungsworkflow",
                            message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                          <a href="${this.apiService.FrontEndPATH}/changeRequestPreview/${newApproval.id}" target="_blank">${'Ã„nderungsantrag / Change Request : ' + response.projectName}</a>`
                          }).subscribe(msg => { });
                        });
                      });
                    });
                  });
                });
              });
            });
          } else if (approvedProj.sentTo == "FICO") {
            this.apiService.put('/projects/' + item.key.project.id, {
              status: 'Approved',
              workflowStatus: 'Change Request has been approved.',
              changeRequestMade: false,
              changeRequestApproved: true,
              ficoApprovedChangeRequestDate: new Date().toISOString(),
              isFicoApprovedChangeRequest: true
            }).subscribe((project: any) => {
              this.apiService.put('/changeRequest/' + approvedProj.changeRequest.id, {
                isApproved: true,
                ficoApprovedChangeRequestDate: new Date().toISOString(),
                isFicoApprovedChangeRequest: true
              }).subscribe(CrResponse => {
                this.apiService.put('/outlineApproval/' + item.key.id, {
                  changeRequest: CrResponse,
                  isPMOApprovedChangeRequest: true,
                  isSponsorApprovedChangeRequest: true,
                  isFicoApprovedChangeRequest: true,
                  projectStatus: 'Approved',
                  ficoApprovedChangeRequestDate: new Date().toISOString(),
                  overallStatus: "Approved"
                }).subscribe((approvalResponse: any) => {
                  let projectResponse = Object.assign({}, project);
                  delete (projectResponse.projectOutline);
                  delete (projectResponse.projectOrder);
                  delete (projectResponse.history);
                  delete (projectResponse.changeRequests);
                  delete (projectResponse.closingReport);
                  delete (projectResponse.approvals);
                  delete (projectResponse.user);
                  delete (projectResponse.subPortfolio);

                  let approvedchangeRequest = approvalResponse.changeRequest;
                  approvedchangeRequest.projectId = projectResponse;

                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    changeRequest: approvedchangeRequest
                  }).subscribe((resp: any) => {
                    this.apiService.post('/updatepreviousapproval/', {
                      query: {
                        project: resp.project.id,
                        docType: 'Change Request',
                        version: resp.version,
                        crNo: resp.crNo,
                      },
                      projectItem: {
                        changeRequest: approvedchangeRequest,
                        isFicoApprovedChangeRequest: true,
                        projectStatus: 'Approved',
                        ficoApprovedChangeRequestDate: new Date().toISOString(),
                        overallStatus: "Approved"
                      }
                    }).subscribe((resp: any) => {
                      this.approvalsList.find(val => val.id == item.key.id).changeRequest = approvalResponse.changeRequest;
                      this.dataGrid.instance.refresh();
                      this.changeRequestReportOverwriter(approvedProj, project);
                    });
                  });
                });
              });
            });
          }
        });
      } else if (event.target.value == "On Hold") {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'On Hold',
          workflowStatus: 'Change Request has been put on hold.',
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'On Hold',
            projectStatus: 'On Hold',
            overallStatus: "On Hold"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedchangeRequest = appRes.changeRequest;
            approvedchangeRequest.projectId = projectResponse;
            approvedchangeRequest.status = 'On Hold';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              changeRequest: approvedchangeRequest
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Change Request',
                  version: resp.version,
                  crNo: resp.crNo,
                },
                projectItem: { changeRequest: approvedchangeRequest, projectStatus: 'On Hold', overallStatus: "On Hold" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "On Hold";
                    this.approvalsList[index].overallStatus = "On Hold";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Change Request', 'held', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/changeRequest/' + response.changeRequests[response.changeRequests.length - 1].id, {
                  status: 'On Hold',
                }).subscribe(changeRes => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.changeRequest.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request On Hold')[0].text + `<br>` +
                      'derungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
                  }).subscribe(msg => { });
                  this.notification('Success', "Change Request has been put on hold.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Return to Sender') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id + '?populate=false', {
          status: 'Returned',
          changeRequestMade: false,
          changeRequestReturned: true,
          workflowStatus: 'Change Request has been returned back from approver.',
          changeRequestReturnDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Returned',
            projectStatus: 'Returned',
            changeRequestReturned: true,
            isFreezed: true,
            changeRequestReturnDate: new Date().toISOString(),
            overallStatus: "Returned"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedchangeRequest = appRes.project;

            let embeddedChangeRequest = appRes.changeRequest;
            //This logic needs to be revised

            approvedchangeRequest.status = 'Returned';
            approvedchangeRequest.workflowStatus = 'Change Request has been returned back from approver.'
            approvedchangeRequest.changeRequestReturnDate = new Date().toISOString();

            embeddedChangeRequest.project.status = 'Returned';
            embeddedChangeRequest.project.workflowStatus = 'Change Request has been returned back from approver.'
            embeddedChangeRequest.project.changeRequestReturnDate = new Date().toISOString();

            this.apiService.put('/outlineApproval/' + item.key.id + '?populate=false', {
              project: approvedchangeRequest
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval?populate=false', {
                query: {
                  project: resp.project,
                  docType: 'Change Request',
                  version: resp.version,
                  crNo: resp.crNo,
                },
                projectItem: {
                  changeRequest: embeddedChangeRequest,
                  project: approvedchangeRequest,
                  projectStatus: 'Returned',
                  changeRequestReturnDate: new Date().toISOString(),
                  overallStatus: "Returned"
                }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Returned";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Returned";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Change Request', 'returned', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();

                this.apiService.put('/changeRequest/' + item.key.changeRequest.id + '?populate=false', {
                  isApproved: false,
                  isSubmitted: true,
                  isEditable: true,
                  status: 'Returned',
                  // isPMOApprovedChangeRequest: false,
                  // isSponsorApprovedChangeRequest: false,
                }).subscribe(crResponse => {
                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    changeRequest: crResponse,
                  }).subscribe((approvalResponse: any) => {
                    this.approvalsList.find(val => val.id == item.key.id).changeRequest = approvalResponse.changeRequest;
                    this.dataGrid.instance.refresh();
                    this.blockDropdown = false;
                    this.apiService.post('/sendEmail', {
                      email: appRes.changeRequest.projectManager.email,
                      subject: "oneView: Projekt Genehmigungsworkflow",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Returned')[0].text + `<br>` +
                        'nderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
                    }).subscribe(msg => { });
                    this.notification('Success', "Change Request has been returned to sender.");
                    this.utilityService.getUsersOpenOutlinesCount();
                    this.spinner.hide();
                  });
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Reject') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Rejected',
          workflowStatus: 'Change Request has been rejected.',
          changeRequestMade: false,
          changeRequestRejectDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Rejected',
            projectStatus: 'Rejected',
            isFreezed: true,
            changeRequestRejectDate: new Date().toISOString(),
            overallStatus: "Rejected"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedchangeRequest = appRes.changeRequest;
            approvedchangeRequest.projectId = projectResponse;

            approvedchangeRequest.project = projectResponse;
            approvedchangeRequest.project.changeRequestRejectDate = new Date().toISOString();
            approvedchangeRequest.status = 'Rejected';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              changeRequest: approvedchangeRequest
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Change Request',
                  version: resp.version,
                  crNo: resp.crNo,
                },
                projectItem: { changeRequest: approvedchangeRequest, projectStatus: 'Rejected', changeRequestRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Rejected";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Rejected";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Change Request', 'rejected', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;
                this.apiService.put('/changeRequest/' + item.key.changeRequest.id, {
                  isApproved: true,
                  isSubmitted: true,
                  isEditable: false,
                  status: 'Rejected',
                }).subscribe(crResponse => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.changeRequest.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Rejected')[0].text + `<br>` +
                      'Ã„nderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
                  }).subscribe(msg => { });
                  this.notification('Success', "Change Request has been rejected.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      }
    } else {
      if (event.target.value == "Approve") {
        this.spinner.show();
        this.apiService.put('/outlineApproval/' + item.key.id, {
          status: 'Approved',
          isFreezed: true,
          // overallStatus: "Approved"
        }).subscribe((approvedProj: any) => {
          this.approvalsList.forEach((projectObj, index) => {
            if (projectObj.id == item.key.id) {
              this.approvalsList[index].status = "Approved";
              this.approvalsList[index].isFreezed = true;

              if (approvedProj.sentTo == "PMO") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "Sponsor") {
                this.approvalsList[index].overallStatus = "Submitted";
              } else if (approvedProj.sentTo == "FICO") {
                this.approvalsList[index].overallStatus = "Approved";
              }
            }
          });
          this.notificationService.createNotification(item.key.project.id, 'Closing Report', 'approved', item.key.assignedTo.id);
          this.dataGrid.instance.refresh();

          if (approvedProj.sentTo == "PMO") {
            this.apiService.get('/closingReport/' + approvedProj.closingReport.id).subscribe((crResponse: any) => {
              this.apiService.put('/projects/' + item.key.project.id, {
                workflowStatus: 'Closing Report has been sent to Project Sponsor for approval.',
                pmoApprovedClosingReportDate: new Date().toISOString(),
                isPMOApprovedClosingReport: true,
                status: 'Submitted'
              }).subscribe((response: any) => {
                let projectResponse = Object.assign({}, response);
                delete (projectResponse.projectOutline);
                delete (projectResponse.projectOrder);
                delete (projectResponse.history);
                delete (projectResponse.changeRequests);
                delete (projectResponse.closingReport);
                delete (projectResponse.approvals);
                delete (projectResponse.user);
                delete (projectResponse.subPortfolio);

                let projectObj = approvedProj.project;

                approvedProj.closingReport.project.isPMOApprovedClosingReport = true;
                approvedProj.closingReport.project.status = 'Submitted';

                projectObj = projectResponse;
                projectObj.isPMOApprovedClosingReport = true;
                projectObj.status = 'Submitted';

                this.apiService.post('/outlineApproval', {
                  closingReport: approvedProj.closingReport,
                  status: "Open",
                  projectStatus: 'Submitted',
                  overallStatus: "Submitted",
                  assignedTo: crResponse.projectSponsor.id,
                  project: approvedProj.project.id,
                  docType: 'Closing Report',
                  sentTo: "Sponsor",
                  isFreezed: false,
                  uid: approvedProj.uid,
                  version: approvedProj.closingReport.version,
                  isPMOApprovedClosingReport: true,
                  pmoApprovedClosingReportDate: new Date().toISOString(),
                }).subscribe((newApproval: any) => {
                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    project: projectObj,
                    isPMOApprovedClosingReport: true,
                    projectStatus: 'Submitted',
                    overallStatus: "Submitted",
                    pmoApprovedClosingReportDate: new Date().toISOString(),
                  }).subscribe((resp: any) => {
                    this.approvalsList.find(val => val.id == item.key.id).project = response;
                    this.dataGrid.instance.refresh();
                    this.notification('Success', "Closing Report has been sent to Project Sponsor for approval.");
                    this.utilityService.getUsersOpenOutlinesCount();
                    this.blockDropdown = false;
                    this.spinner.hide();

                    this.apiService.post('/sendEmail', {
                      email: newApproval.closingReport.projectSponsor.email,
                      subject: "oneView: Projekt Genehmigungsworkflow",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                    <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${newApproval.id}" target="_blank">${'Abschlussbericht / Closing Report : ' + response.projectName}</a>`
                    }).subscribe(msg => { });
                  });
                });
              });
            });
          } else if (approvedProj.sentTo == "Sponsor") {
            this.apiService.get('/closingReport/' + approvedProj.closingReport.id).subscribe((crResponse: any) => {
              this.apiService.put('/projects/' + item.key.project.id, {
                workflowStatus: 'Closing Report has been sent to Financial Manager for approval.',
                sponsorApprovedClosingReportDate: new Date().toISOString(),
                isSponsorApprovedClosingReport: true,
                status: 'Submitted'
              }).subscribe((response: any) => {
                let projectResponse = Object.assign({}, response);
                delete (projectResponse.projectOutline);
                delete (projectResponse.projectOrder);
                delete (projectResponse.history);
                delete (projectResponse.changeRequests);
                delete (projectResponse.closingReport);
                delete (projectResponse.approvals);
                delete (projectResponse.user);
                delete (projectResponse.subPortfolio);

                let approvedclosingReport = approvedProj.closingReport;
                approvedclosingReport.project = projectResponse;
                approvedclosingReport.project.isSponsorApprovedClosingReport = true;
                approvedclosingReport.status = 'Submitted';

                this.apiService.post('/outlineApproval', {
                  closingReport: approvedclosingReport,
                  status: "Open",
                  projectStatus: 'Submitted',
                  overallStatus: "Submitted",
                  assignedTo: crResponse.projectFico.id,
                  project: approvedProj.project.id,
                  docType: 'Closing Report',
                  sentTo: "FICO",
                  isFreezed: false,
                  uid: approvedProj.uid,
                  version: approvedProj.closingReport.version,
                  isPMOApprovedClosingReport: true,
                  isSponsorApprovedClosingReport: true,
                  pmoApprovedClosingReportDate: item.key.pmoApprovedClosingReportDate,
                  sponsorApprovedClosingReportDate: new Date().toISOString(),
                }).subscribe((newApproval: any) => {
                  this.apiService.put('/outlineApproval/' + item.key.id, {
                    closingReport: approvedclosingReport,
                    isPMOApprovedClosingReport: true,
                    isSponsorApprovedClosingReport: true,
                    projectStatus: 'Submitted',
                    overallStatus: "Submitted",
                    sponsorApprovedClosingReportDate: new Date().toISOString(),
                  }).subscribe((resp: any) => {
                    this.apiService.post('/updatepreviousapproval/', {
                      query: {
                        project: resp.project.id,
                        docType: 'Closing Report',
                        version: resp.version
                      },
                      projectItem: {
                        closingReport: approvedclosingReport,
                        isSponsorApprovedClosingReport: true,
                        projectStatus: 'Submitted',
                        sponsorApprovedClosingReportDate: new Date().toISOString(),
                        overallStatus: "Submitted"
                      }
                    }).subscribe((resp: any) => {
                      this.approvalsList.find(val => val.id == item.key.id).project = response;
                      this.dataGrid.instance.refresh();
                      this.notification('Success', "Closing Report has been sent to Financial Manager for approval.");
                      this.utilityService.getUsersOpenOutlinesCount();
                      this.blockDropdown = false;
                      this.spinner.hide();
                      this.apiService.post('/sendEmail', {
                        email: newApproval.closingReport.projectFico.email,
                        subject: "oneView: Projekt Genehmigungsworkflow",
                        message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
                    <a href="${this.apiService.FrontEndPATH}/closingReportPreview/${newApproval.id}" target="_blank">${'Abschlussbericht / Closing Report : ' + response.projectName}</a>`
                      }).subscribe(msg => { });
                    });
                  });
                });
              });
            });
          } else if (approvedProj.sentTo == "FICO") {
            this.socketService.emitEvent('updateFicoApprovalStatus', {
              ficoApprovedClosingReportDate: new Date().toISOString(),
              isFicoApprovedClosingReport: true,
              project: item.key.project.id
            });

            this.apiService.put('/projects/' + item.key.project.id, {
              status: 'Approved',
              workflowStatus: 'Closing Report has been approved.',
              closingReportApproved: false,
              isClosed: true,
              ficoApprovedClosingReportDate: new Date().toISOString(),
              isFicoApprovedClosingReport: true
            }).subscribe((project: any) => {
              let projectResponse = Object.assign({}, project);
              delete (projectResponse.projectOutline);
              delete (projectResponse.projectOrder);
              delete (projectResponse.history);
              delete (projectResponse.changeRequests);
              delete (projectResponse.closingReport);
              delete (projectResponse.approvals);
              delete (projectResponse.user);
              delete (projectResponse.subPortfolio);
              let approvedclosingReport = approvedProj.closingReport;
              approvedclosingReport.project = projectResponse;
              this.apiService.put('/outlineApproval/' + item.key.id, {
                closingReport: approvedclosingReport,
                isPMOApprovedClosingReport: true,
                isSponsorApprovedClosingReport: true,
                isFicoApprovedClosingReport: true,
                projectStatus: 'Approved',
                overallStatus: "Approved",
                ficoApprovedClosingReportDate: new Date().toISOString(),
              }).subscribe((resp: any) => {
                this.apiService.post('/updatepreviousapproval/', {
                  query: {
                    project: resp.project.id,
                    docType: 'Closing Report',
                    version: resp.version
                  },
                  projectItem: {
                    closingReport: approvedclosingReport,
                    isFicoApprovedClosingReport: true,
                    projectStatus: 'Approved',
                    ficoApprovedClosingReportDate: new Date().toISOString(),
                    overallStatus: "Approved"
                  }
                }).subscribe((resp: any) => {
                  this.approvalsList.find(val => val.id == item.key.id).project = project;
                  this.dataGrid.instance.refresh();
                  this.apiService.put('/closingReport/' + item.key.closingReport.id, {
                    isApproved: true,
                    status: 'Approved'
                  }).subscribe(crResponse => {
                    this.closingReportOverwrite(approvedProj, project);
                  });
                });
              });
            });
          }
        });
      } else if (event.target.value == "On Hold") {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'On Hold',
          workflowStatus: 'Closing Report has been put on hold.',
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'On Hold',
            projectStatus: 'On Hold',
            overallStatus: "On Hold"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedclosingReport = appRes.closingReport;
            approvedclosingReport.project = projectResponse;
            approvedclosingReport.status = 'On Hold';
            this.apiService.put('/outlineApproval/' + item.key.id, {
              closingReport: approvedclosingReport
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Closing Report',
                  version: resp.version
                },
                projectItem: { closingReport: approvedclosingReport, projectStatus: 'On Hold', overallStatus: "On Hold" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "On Hold";
                    this.approvalsList[index].overallStatus = "On Hold";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Closing Report', 'held', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;

                this.apiService.put('/closingReport/' + response.closingReport[0].id, {
                  status: 'On Hold'
                }).subscribe(closingRes => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.closingReport.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report On Hold')[0].text + `<br>` +
                      'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
                  }).subscribe(msg => { });
                  this.notification('Success', "Closing Report has been put on hold.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Return to Sender') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Returned',
          changeRequestMade: false,
          workflowStatus: 'Closing Report has been returned back from approver.',
          closingReportSubmitted: true,
          // isPMOApprovedClosingReport: false,
          // isSponsorApprovedClosingReport: false,
          closingReportReturned: true,
          closingReportReturnDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.approvalsList.find(val => val.id == item.key.id).project = response;
          this.dataGrid.instance.refresh();
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Returned',
            projectStatus: 'Returned',
            isFreezed: true,
            closingReportReturnDate: new Date().toISOString(),
            overallStatus: "Returned"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedclosingReport = appRes.closingReport;

            approvedclosingReport.project.status = 'Returned';
            approvedclosingReport.project.closingReportReturnDate = new Date().toISOString();
            approvedclosingReport.project.workflowStatus = 'Closing Report has been returned back from approver.'
            this.apiService.put('/outlineApproval/' + item.key.id, {
              closingReport: approvedclosingReport
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Closing Report',
                  version: resp.version
                },
                projectItem: { closingReport: approvedclosingReport, projectStatus: 'Returned', closingReportReturnDate: new Date().toISOString(), overallStatus: "Returned" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Returned";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Returned";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Closing Report', 'returned', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();

                this.apiService.put('/closingReport/' + item.key.closingReport.id, {
                  isApproved: false,
                  isSubmitted: false,
                  status: 'Returned'
                }).subscribe(crResponse => {
                  this.blockDropdown = false;
                  this.apiService.post('/sendEmail', {
                    email: appRes.closingReport.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Returned')[0].text + `<br>` +
                      'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
                  }).subscribe(msg => { });
                  this.notification('Success', "Closing Report has been returned to sender.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      } else if (event.target.value == 'Reject') {
        this.spinner.show();
        this.apiService.put('/projects/' + item.key.project.id, {
          status: 'Rejected',
          workflowStatus: 'Closing Report has been rejected.',
          changeRequestMade: false,
          closingReportRejectDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.put('/outlineApproval/' + item.key.id, {
            status: 'Rejected',
            projectStatus: 'Rejected',
            isFreezed: true,
            closingReportRejectDate: new Date().toISOString(),
            overallStatus: "Rejected"
          }).subscribe((appRes: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvedclosingReport = appRes.closingReport;
            approvedclosingReport.project = projectResponse;
            approvedclosingReport.status = 'Rejected'
            this.apiService.put('/outlineApproval/' + item.key.id, {
              closingReport: approvedclosingReport
            }).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Closing Report',
                  version: resp.version
                },
                projectItem: { closingReport: approvedclosingReport, projectStatus: 'Rejected', closingReportRejectDate: new Date().toISOString(), overallStatus: "Rejected" }
              }).subscribe((resp: any) => {
                this.approvalsList.forEach((projectObj, index) => {
                  if (projectObj.id == item.key.id) {
                    this.approvalsList[index].status = "Rejected";
                    this.approvalsList[index].isFreezed = true;
                    this.approvalsList[index].overallStatus = "Rejected";
                  }
                });
                this.notificationService.createNotification(item.key.project.id, 'Closing Report', 'rejected', item.key.assignedTo.id);
                this.dataGrid.instance.refresh();
                this.blockDropdown = false;
                this.apiService.put('/closingReport/' + item.key.closingReport.id, {
                  isApproved: false,
                  isSubmitted: true,
                  status: 'Rejected'
                }).subscribe(crResponse => {
                  this.apiService.post('/sendEmail', {
                    email: appRes.closingReport.projectManager.email,
                    subject: "oneView: Projekt Genehmigungsworkflow",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Rejected')[0].text + `<br>` +
                      'Abschlussbericht / Closing Report : ' + response.projectName + '<br><br><br>'
                  }).subscribe(msg => { });
                  this.notification('Success', "Closing Report has been rejected.");
                  this.utilityService.getUsersOpenOutlinesCount();
                  this.spinner.hide();
                });
              });
            });
          });
        });
      }
    }

    let interval = setInterval(() => {
      if (this.blockDropdown == false) {
        clearInterval(interval);
        this.filter();
      }
    }, 500);
  }

  reportOverwriter(approvedProj, project) {
    let budgetTable = project.projectOrder[0].costTypeTable;
    delete (project.projectOrder[0].costTypeTable);
    delete (project.projectOrder[0].attachment1);
    delete (project.projectOrder[0].attachment2);
    delete (project.projectOrder[0].attachments);
    delete (project.projectOrder[0].isAttachement1Added);
    delete (project.projectOrder[0].isAttachement2Added);

    let obj: any = {
      projectName: project.projectName,
      ...project.projectOrder[0],
      status: 'Active',
    };

    delete (obj.question);
    obj.orderQuestion = project.projectOrder[0].question;
    if (project.projectOrder[0].portfolioQuestions && project.projectOrder[0].portfolioQuestions.length > 0) {
      obj.orderPortfolioQuestions = project.projectOrder[0].portfolioQuestions;
    }

    obj.risks.forEach((risk, idx) => {
      obj.risks[idx].report = false;
    });

    obj.mileStonesValues.forEach((val, index) => {
      obj.mileStonesValues[index].status = 'Due';
    });

    obj.milestoneTable = [];

    if (project.subPortfolio.id != project.projectReport.subPortfolio) {
      let budget = [
        {
          costType: "External Costs",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          group: "Sonstiges",
          id: 5,
        }, {
          costType: "Total",
          budget: '',
          thereofIT: '',
          currentBudget: '',
          yearlyForecast: '',
          remainingProjectBudget: '',
          id: 6,
          group: "Sonstiges",
        },
      ];

      this.apiService.post('/projectBudgetCost/yearlyBudget/switch', {
        project: project.id,
        budget,
        subPortfolio: project.subPortfolio.id
      }).subscribe(response => {
        console.log(response);
      }, error => {
        console.log(error.message);
      });
    }

    this.apiService.get('/reports/' + project.projectReport.id).subscribe((response: any) => {
      let costTypeTable = response.costTypeTable;

      budgetTable.forEach((value, index) => {
        costTypeTable[index].forecast = value.budget;
        costTypeTable[index].currentBudget = value.budget;
        costTypeTable[index].originalBudget = value.budget;
      });
      obj.costTypeTable = costTypeTable;
      obj.plannedEndDate = obj.endDate;
      obj.forecastEndDate = obj.endDate;

      obj.currentReserveHistory = [];

      let projectPhaseOptions = this.utilityService.projectPhaseOptions;
      let projectPhase;

      if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
        projectPhase = projectPhaseOptions.values.find(val => val.name == 'Executing').id;
      }
      obj.projectPhase = projectPhase;

      this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
        this.apiService.post('/history', {
          date: Date.now(),
          project: project.id,
          document: 'Project Order',
          status: 'Approved'
        }).subscribe(historyResponse => {
          this.blockDropdown = false;

          this.addUserToTeam(project.id, response.projectFico);

          this.apiService.put('/projectOrder/' + project.projectOrder[0].id, {
            status: 'Approved',
          }).subscribe(orderResponse => {
            this.apiService.post('/sendEmail', {
              email: approvedProj.projectOrder.projectManager.email,
              subject: "oneView: Projekt Genehmigungsworkflow",     //  "Order has been approved by approver. <br>" +
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Order Approval Confirmation')[0].text + `<br>` + 'Projektauftrag / Project Order : ' + response.projectName + '<br><br><br>'
            }).subscribe(msg => { });
            this.notification('Success', "Order has been approved.");
            this.utilityService.getUsersOpenOutlinesCount();
            this.spinner.hide();
          });
        });
      });
    });
  }

  getteamUsersIds(teamUsers): any {
    let teamUsersIds = [];
    teamUsers.forEach(team => {
      teamUsersIds.push(team.user.id);
    });
    return teamUsersIds;
  }

  async addUserToTeam(projectId, user) {
    let teamId, teamUsers = [];
    await this.apiService.get('/project/team/' + projectId).subscribe((projectTeam: any) => {
      if (projectTeam.teamId != null && projectTeam.teamId != undefined) {
        teamId = projectTeam.teamId;
        teamUsers = projectTeam.teamUsers;
      }

      if (user.id != '' && user.id != undefined && user.id != null &&
        user.email != '' && user.email != undefined && user.name != null) {
        if (teamUsers.find(val => val.id == user.id) != undefined) {
          // this.spinner.hide();
        } else {
          if (teamId != undefined && teamId != null) {
            let teamUsersIds = this.getteamUsersIds(teamUsers);
            teamUsersIds.push(user.id);
            this.apiService.put('/team/' + teamId, {
              users: teamUsersIds,
            }).subscribe((responseTeam: any) => {
              this.apiService.post('/rights', {
                user: user.id,
                project: projectId,
                isView: true,
                isEdit: true
              }).subscribe((responseRights: any) => {
                // this.spinner.hide();
              });
            });
          } else {
            this.apiService.post('/team', {
              users: [user.id],
              project: projectId
            }).subscribe((responseTeam: any) => {
              teamId = responseTeam.id;
              this.apiService.post('/rights', {
                user: user.id,
                project: projectId,
                isView: true,
                isEdit: true
              }).subscribe((responseRights: any) => {
                // this.spinner.hide();
              });
            });
          }
        }
      } else {
        // this.spinner.hide();
      }
    });

  }

  changeRequestReportOverwriter(approvedProj, project) {
    let budgetTable = project.changeRequests[project.changeRequests.length - 1].effectsOnProjectBudget;
    let newEndDate = project.changeRequests[project.changeRequests.length - 1].newEndDate;
    delete (project.changeRequests[project.changeRequests.length - 1].actualProjectBudget);
    delete (project.changeRequests[project.changeRequests.length - 1].effectsOnProjectBudget);
    delete (project.changeRequests[project.changeRequests.length - 1].newEndDate);
    delete (project.changeRequests[project.changeRequests.length - 1].attachment1);
    delete (project.changeRequests[project.changeRequests.length - 1].attachment2);
    delete (project.changeRequests[project.changeRequests.length - 1].attachments);
    delete (project.changeRequests[project.changeRequests.length - 1].isAttachement1Added);
    delete (project.changeRequests[project.changeRequests.length - 1].isAttachement2Added);

    let obj: any = {
      projectName: project.projectName,
      ...project.changeRequests[project.changeRequests.length - 1],
      status: 'Active',
    };

    delete (obj.question);
    // obj.changeRequestQuestion = project.changeRequests[project.changeRequests.length - 1].changeRequestQuestion;
    obj.plannedEndDate = newEndDate;
    obj.forecastEndDate = newEndDate;

    if (project.subportfolio) {
      if (project.subPortfolio.id != project.projectReport.subPortfolio) {
        let budget = [
          {
            costType: "External Costs",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 0,
            group: "CAPEX",
          }, {
            costType: "Internal Costs",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 1,
            group: "CAPEX",
          }, {
            costType: "External Costs",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 2,
            group: "OPEX"
          }, {
            costType: "Internal Costs",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 3,
            group: "OPEX"
          }, {
            costType: "Revenues",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 4,
            group: "Sonstiges",
          }, {
            costType: "Reserves",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            group: "Sonstiges",
            id: 5,
          }, {
            costType: "Total",
            budget: '',
            thereofICT: '',
            actualCost: "",
            forecast: "",
            id: 6,
            group: "Sonstiges",
          },
        ];

        this.apiService.post('/projectBudgetCost/yearlyBudget/switch', {
          project: project.id,
          budget,
          subPortfolio: project.subPortfolio.id
        }).subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error.message);
        });
      }
    }

    this.apiService.get('/reports/' + project.projectReport.id).subscribe((response: any) => {
      let costTypeTable = response.costTypeTable;

      budgetTable.forEach((value, index) => {
        costTypeTable[index].forecast = value.budget;
        costTypeTable[index].currentBudget = value.budget;
      });
      obj.costTypeTable = costTypeTable;

      this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
        this.apiService.post('/history', {
          date: Date.now(),
          project: project.id,
          document: 'Change Request',
          status: 'Approved'
        }).subscribe(historyResponse => {
          this.blockDropdown = false;

          this.apiService.put('/changeRequest/' + project.changeRequests[project.changeRequests.length - 1].id, {
            status: 'Approved',
          }).subscribe(changeRes => {
            this.apiService.post('/sendEmail', {
              email: approvedProj.changeRequest.projectManager.email,
              subject: "oneView: Projekt Genehmigungsworkflow",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Change Request Approval Confirmation')[0].text + `<br>` + 'Änderungsantrag / Change Request : ' + response.projectName + '<br><br><br>'
            }).subscribe(msg => { });
            this.notification('Success', "Change Request has been approved.");
            this.utilityService.getUsersOpenOutlinesCount();
            this.spinner.hide();
          })
        });
      });
    });
  }

  closingReportOverwrite(approvedProj, project) {
    let obj: any = {
      lessonsLearned: project.closingReport[0].lessonsLearned,
      closingQuestion: project.closingReport[0].question,
      status: 'Closed',
    };

    if (project.closingReport[0].portfolioClosingReportQuestion && project.closingReport[0].portfolioClosingReportQuestion.length > 0) {
      obj.portfolioClosingReportQuestion = project.closingReport[0].portfolioClosingReportQuestion;
    }

    let projectPhaseOptions = this.utilityService.projectPhaseOptions;
    let projectPhase;

    if (projectPhaseOptions != undefined && projectPhaseOptions != null) {
      projectPhase = projectPhaseOptions.values.find(val => val.name == 'Closing').id;
    }
    obj.projectPhase = projectPhase;

    this.apiService.put('/reports/' + project.projectReport.id, obj).subscribe((response: any) => {
      this.apiService.post('/history', {
        date: Date.now(),
        project: project.id,
        document: 'Closing Report',
        status: 'Approved'
      }).subscribe(historyResponse => {
        this.notification('Success', "Closing Report has been approved.");
        this.utilityService.getUsersOpenOutlinesCount();
        this.blockDropdown = false;
        this.spinner.hide();
        this.apiService.post('/sendEmail', {
          email: approvedProj.closingReport.projectManager.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Closing Report Approval Confirmation')[0].text + `<br>` +
            'Abschlussbericht /Closing Report : ' + response.projectName + '<br><br><br>'
        }).subscribe(msg => { });
      });
    });
  }

  openOutlinePreview(outline, name) {
    this.showPreview = false;
    outline = this.utilityService.setOutlinePdfValues(outline);
    this.outlinePreview = outline;
    this.outlinePreview.projectName = name;
    this.showPreview = true;
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  openSmallOrderPreview(smallOrder) {
    this.showSmallOrderPreview = false;
    this.smallOrderPreview = smallOrder;

    setTimeout(() => {
      this.showSmallOrderPreview = true;
    }, 100);
    this.smallOrderModal.show();
    this.smallOrderModal.onHidden.subscribe((reason: string) => {
      this.showSmallOrderPreview = false;
    });
  }

  // openOutlinePreview(outline, name, template) {
  //   this.showPreview = false;
  //   outline = this.utilityService.setOutlinePdfValues(outline);
  //   this.outlinePreview = outline;
  //   this.outlinePreview.projectName = name;
  //   this.showPreview = true;
  //   this.modalRef = this.modalService.show(template, this.config);
  //   this.modalService.onHidden.subscribe((reason: string) => {
  //     this.showPreview = false;
  //   });
  // }

  // refreshGrid() {
  //   this.spinner.show();
  //   this.apiService.get('/getOutlinesByUser/' + this.authService.userObj.id).subscribe(response => {
  //     this.approvalsList = response;
  //     this.dataGrid.instance.option('dataSource', this.approvalsList);
  //     this.spinner.hide();
  //   });
  // }

  // openOrderPreview(outline, template) {
  //   this.showOrderPreview = false;
  //   outline.projectOrder = this.utilityService.setOrderPdfValues(outline.projectOrder);
  //   this.orderPreview = outline.projectOrder;

  //   setTimeout(() => {
  //     this.showOrderPreview = true;
  //   }, 100);
  //   this.modalRef = this.modalService.show(template, this.config);
  //   this.modalService.onHidden.subscribe((reason: string) => {
  //     this.showOrderPreview = false;
  //   });
  // }

  openOrderPreview(outline) {
    this.spinner.show();
    this.showOrderPreview = false;

    outline.projectOrder = this.utilityService.setOrderPdfValues(outline.projectOrder);

    if (outline.projectOrder.projectId && outline.projectOrder.projectId.id) {
      this.orderPreview = outline.projectOrder;

      setTimeout(() => {
        this.showOrderPreview = true;
      }, 100);
      this.spinner.hide();
      this.orderModal.show();
      this.orderModal.onHidden.subscribe((reason: string) => {
        this.showOrderPreview = false;
      });
    } else {
      this.apiService.get('/projects/' + outline.projectOrder.projectId.id).subscribe((project: any) => {
        outline.projectOrder.projectId = project;

        this.orderPreview = outline.projectOrder;

        setTimeout(() => {
          this.showOrderPreview = true;
        }, 100);
        this.spinner.hide();
        this.orderModal.show();
        this.orderModal.onHidden.subscribe((reason: string) => {
          this.showOrderPreview = false;
        });
      });
    }
  }

  // openChangeRequestPreview(changeRequestObj, template) {
  //   this.showChangePreview = false;

  //   if (changeRequestObj.changeRequest.project.projectReport == undefined) {
  //     this.apiService.get('/projects/' + changeRequestObj.changeRequest.project).subscribe((projectResponse: any) => {
  //       this.apiService.get('/reports/' + projectResponse.projectReport.id).subscribe(response => {
  //         changeRequestObj.changeRequest.project = projectResponse;
  //         changeRequestObj.changeRequest.project.projectReport = response;
  //         changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
  //         this.changeRequestPreview = changeRequestObj.changeRequest;

  //         setTimeout(() => {
  //           this.showChangePreview = true;
  //         }, 100);
  //         this.modalRef = this.modalService.show(template, this.config);
  //         this.modalService.onHidden.subscribe((reason: string) => {
  //           this.showChangePreview = false;
  //         });
  //       });
  //     });
  //   } else {
  //     if (typeof (changeRequestObj.changeRequest.project.projectReport) == 'string') {
  //       this.apiService.get('/reports/' + changeRequestObj.changeRequest.project.projectReport).subscribe(response => {
  //         changeRequestObj.changeRequest.project.projectReport = response;

  //         changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
  //         this.changeRequestPreview = changeRequestObj.changeRequest;

  //         setTimeout(() => {
  //           this.showChangePreview = true;
  //         }, 100);
  //         this.modalRef = this.modalService.show(template, this.config);
  //         this.modalService.onHidden.subscribe((reason: string) => {
  //           this.showChangePreview = false;
  //         });
  //       });
  //     }
  //     else {
  //       changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
  //       this.changeRequestPreview = changeRequestObj.changeRequest;

  //       setTimeout(() => {
  //         this.showChangePreview = true;
  //       }, 100);
  //       this.modalRef = this.modalService.show(template, this.config);
  //       this.modalService.onHidden.subscribe((reason: string) => {
  //         this.showChangePreview = false;
  //       });
  //     }
  //   }
  // }

  openChangeRequestPreview(changeRequestObj) {
    this.spinner.show();
    this.showChangePreview = false;
    let projectId = changeRequestObj.changeRequest.project.id ? changeRequestObj.changeRequest.project.id : changeRequestObj.changeRequest.project;

    if (changeRequestObj.changeRequest.project && changeRequestObj.changeRequest.project.id) {
      if (changeRequestObj.changeRequest.project.projectReport && changeRequestObj.changeRequest.project.projectReport.id) {
        changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
        this.changeRequestPreview = changeRequestObj.changeRequest;

        setTimeout(() => {
          this.showChangePreview = true;
        }, 100);
        this.spinner.hide();
        this.lgChangeModal.show();
        this.lgChangeModal.onHidden.subscribe((reason: string) => {
          this.showChangePreview = false;
        });

      } else {
        this.apiService.get('/reports/' + changeRequestObj.changeRequest.project.projectReport).subscribe(response => {
          changeRequestObj.changeRequest.project.projectReport = response;
          changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
          this.changeRequestPreview = changeRequestObj.changeRequest;

          setTimeout(() => {
            this.showChangePreview = true;
          }, 100);
          this.spinner.hide();
          this.lgChangeModal.show();
          this.lgChangeModal.onHidden.subscribe((reason: string) => {
            this.showChangePreview = false;
          });
        });
      }
    } else {
      this.apiService.get('/projects/' + projectId).subscribe((project: any) => {
        changeRequestObj.changeRequest.project = project;

        if (changeRequestObj.changeRequest.project.projectReport == undefined) {
          this.apiService.get('/projects/' + changeRequestObj.changeRequest.project).subscribe((projectResponse: any) => {
            this.apiService.get('/reports/' + projectResponse.projectReport.id).subscribe(response => {
              changeRequestObj.changeRequest.project = projectResponse;
              changeRequestObj.changeRequest.project.projectReport = response;
              changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
              this.changeRequestPreview = changeRequestObj.changeRequest;

              setTimeout(() => {
                this.showChangePreview = true;
              }, 100);
              this.spinner.hide();
              this.lgChangeModal.show();
              this.lgChangeModal.onHidden.subscribe((reason: string) => {
                this.showChangePreview = false;
              });
            });
          });
        } else {
          if (typeof (changeRequestObj.changeRequest.project.projectReport) == 'string') {
            this.apiService.get('/reports/' + changeRequestObj.changeRequest.project.projectReport).subscribe(response => {
              changeRequestObj.changeRequest.project.projectReport = response;

              changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
              this.changeRequestPreview = changeRequestObj.changeRequest;

              setTimeout(() => {
                this.showChangePreview = true;
              }, 100);
              this.spinner.hide();
              this.lgChangeModal.show();
              this.lgChangeModal.onHidden.subscribe((reason: string) => {
                this.showChangePreview = false;
              });
            });
          } else {
            changeRequestObj.changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequestObj.changeRequest);
            this.changeRequestPreview = changeRequestObj.changeRequest;

            setTimeout(() => {
              this.showChangePreview = true;
            }, 100);
            this.spinner.hide();
            this.lgChangeModal.show();
            this.lgChangeModal.onHidden.subscribe((reason: string) => {
              this.showChangePreview = false;
            });
          }
        }

      });
    }
  }

  // openClosingReportPreview(outline, template) {
  //   this.showClosingPreview = false;

  //   if (outline.closingReport.project.projectReport.id == undefined) {
  //     this.apiService.get('/reports/' + outline.closingReport.project.projectReport).subscribe(response => {
  //       outline.closingReport.project.projectReport = response;
  //       this.closingReportPreview = outline.closingReport;

  //       setTimeout(() => {
  //         this.showClosingPreview = true;
  //       }, 100);
  //       this.modalRef = this.modalService.show(template, this.config);
  //       this.modalService.onHidden.subscribe((reason: string) => {
  //         this.showClosingPreview = false;
  //       });
  //     });
  //   }
  //   else {
  //     this.closingReportPreview = outline.closingReport;
  //     setTimeout(() => {
  //       this.showClosingPreview = true;
  //     }, 100);
  //     this.modalRef = this.modalService.show(template, this.config);
  //     this.modalService.onHidden.subscribe((reason: string) => {
  //       this.showClosingPreview = false;
  //     });
  //   }
  // }

  openClosingReportPreview(outline) {
    this.spinner.show();
    this.showClosingPreview = false;

    let projectId = outline.closingReport.project.id ? outline.closingReport.project.id : outline.closingReport.project;

    if (outline.closingReport.project && outline.closingReport.project.id) {
      if (outline.closingReport.project.projectReport && outline.closingReport.project.projectReport.id) {
        this.closingReportPreview = outline.closingReport;

        setTimeout(() => {
          this.showClosingPreview = true;
        }, 100);
        this.spinner.hide();
        this.lgClosingModal.show();
        this.lgClosingModal.onHidden.subscribe((reason: string) => {
          this.showClosingPreview = false;
        });

      } else {
        this.apiService.get('/reports/' + outline.closingReport.project.projectReport).subscribe(response => {
          outline.closingReport.project.projectReport = response;
          this.closingReportPreview = outline.closingReport;

          setTimeout(() => {
            this.showClosingPreview = true;
          }, 100);
          this.spinner.hide();
          this.lgClosingModal.show();
          this.lgClosingModal.onHidden.subscribe((reason: string) => {
            this.showClosingPreview = false;
          });
        });
      }
    } else {
      this.apiService.get('/projects/' + projectId).subscribe((project: any) => {
        outline.closingReport.project = project;

        if (outline.closingReport.project.projectReport.id == undefined) {
          this.apiService.get('/reports/' + outline.closingReport.project.projectReport).subscribe(response => {
            outline.closingReport.project.projectReport = response;
            this.closingReportPreview = outline.closingReport;

            setTimeout(() => {
              this.showClosingPreview = true;
            }, 100);
            this.spinner.hide();
            this.lgClosingModal.show();
            this.lgClosingModal.onHidden.subscribe((reason: string) => {
              this.showClosingPreview = false;
            });
          });
        }
        else {
          this.closingReportPreview = outline.closingReport;
          setTimeout(() => {
            this.showClosingPreview = true;
          }, 100);
          this.spinner.hide();
          this.lgClosingModal.show();
          this.lgClosingModal.onHidden.subscribe((reason: string) => {
            this.showClosingPreview = false;
          });
        }
      });
    }
  }

  openConfirmation(e, data) {
    let msg = '';
    let flag = true;

    if (e.target.value == "Approve") {
      msg = this.localize.transform('Once approved, no changes are permitted. Do you wish too continue?');
    } else if (e.target.value == "On Hold") {
      flag = false;
    } else if (e.target.value == "Return to Sender") {
      msg = this.localize.transform('When the request is returned, the responsible project manager must resubmit the request. Do you wish to continue?');
    } else if (e.target.value == "Reject") {
      msg = this.localize.transform('Once rejected, no changes are allowed. Are you sure you want to reject the request?');
    }

    if (flag) {
      swal({
        title: `${this.localize.transform('Confirmation')}`,
        text: `${this.localize.transform(msg)}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: `${this.localize.transform('Yes')}`,
        cancelButtonText: `${this.localize.transform('No')}`,
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.actionEvent(e, data);
        } else {
          let elem: any = document.getElementById(data.key.id);
          elem.options.selectedIndex = 0;
        }
      });
    } else {
      this.actionEvent(e, data);
    }
  }

  calculateCellValueSubmissionDate(e) {
    if (e.docType == "Outline") {
      if (e.projectOutline) {
        return e.projectOutline.createdAt;
      }
    }
    else if (e.docType == "Order")
      return e.projectOrder.createdAt;
    else if (e.docType == "Change Request") {
      return e.changeRequest.createdAt;
    } else {
      return e.closingReport.createdAt;
    }
  }

  openComments(projectObj) {
    this.showComments = false;
    this.commentsArr = projectObj.comments;
    this.commentsProjectId = projectObj.id;

    setTimeout(() => {
      this.showComments = true;
    }, 100);
    this.commentsModal.show();
    this.commentsModal.onHidden.subscribe((reason: string) => {
      this.showComments = false;
      this.commentsArr = [];
    });
  }

  // openComments(projectObj, template) {
  //   this.showComments = false;
  //   this.commentsArr = projectObj.comments;
  //   this.commentsProjectId = projectObj.id;

  //   setTimeout(() => {
  //     this.showComments = true;
  //   }, 100);
  //   this.modalRef = this.modalService.show(template, this.config);
  //   this.modalService.onHidden.subscribe((reason: string) => {
  //     this.showComments = false;
  //     this.commentsArr = [];
  //   });
  // }

  saveComments() {
    if (this.commentsArr == undefined) {
      this.commentsArr = [];
    }
    if (this.commentMsg != '') {
      this.commentsArr.push({
        message: this.commentMsg,
        time: Date.now(),
        user: this.authService.userObj.email
      });
      this.commentMsg = '';

      this.apiService.put('/projects/' + this.commentsProjectId, {
        comments: this.commentsArr
      }).subscribe((response: any) => {
        // Update the existing grid
        this.approvalsList.forEach((project, index) => {
          if (this.commentsProjectId == project.id) {
            this.approvalsList[index].comments = response.comments;
          }
        });
        this.notificationService.createNotification(this.commentsProjectId, '', 'comment', this.authService.userObj.id);
        // this.refreshGrid();
      });
    }
  }

  ordersOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.orderNameFilter != undefined) {
      this.approvalsSmallOrderList = [];
      this.orderNameFilter = undefined;
      this.orderName = '';
      this.filterOrder();
    }
    this.apiService.get('/smallOrder/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.ordersSearchList = response;
    });
  }

  ordersValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.orderNameFilter = e.itemData;
      this.filterOrder();
    }
  }

  orderManagerValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.orderManagerFilter = response;
            this.filterOrder();
          }
        });
      }
    }
  }

  orderManagerOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.orderManagerFilter != undefined) {
      this.approvalsSmallOrderList = [];
      this.orderManagerFilter = undefined;
      this.orderManagerEmail = '';
      this.filterOrder();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.orderManagerFilter = response;
      //       } else {
      //         this.orderManagerFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.orderManagerFilter = { id: '' };
      // }
    });
  }

  resetFilterOrder() {
    this.filtersArrayOrders = [{ status: ['Start'] }];
    this.approvalsSmallOrderList = [];
    this.statusFilterOrder = ['Start'];
    this.orderName = '';
    this.orderNameFilter = '';
    this.orderManagerEmail = '';
    this.orderManagerFilter = '';

    this.isFilterApplied = false;
    localStorage.setItem("approvalsSmallOrdersFilter", JSON.stringify(this.filtersArrayOrders));
    let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
    let finalFilter = this.filtersArrayOrders.concat(defaultFilter);
    this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });

  }

  filterOrder() {
    let filtersArray = this.setFiltersOrders();

    if (filtersArray.length > 0) {
      localStorage.setItem('approvalsSmallOrdersFilter', JSON.stringify(filtersArray));
      this.filtersArrayOrders = filtersArray;

      let defaultFilter = [{ orderSponsor: this.authService.userObj.id }]
      this.approvalsSmallOrderList = [];
      let finalFilter = filtersArray.concat(defaultFilter);

      this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray: finalFilter });
      this.spinner.show();
    } else { //If no filter is selected, bring all data
      filtersArray.push({ orderSponsor: this.authService.userObj.id });
      this.socketService.emitEvent('smallOrdersFilter', { userId: this.authService.userObj.id, filtersArray });
      this.spinner.show();
    }
  }

  setFiltersOrders() {
    let filtersArray = new Array;

    if (this.statusFilterOrder != undefined && this.statusFilterOrder.length > 0) {
      filtersArray.push({ status: this.statusFilterOrder })
    }

    if (this.orderManagerFilter) {
      filtersArray.push({ orderManager: this.orderManagerFilter.id })
    }
    if (this.orderNameFilter) {
      filtersArray.push({ id: this.orderNameFilter.id })
    }

    this.isFilterApplied = true;
    return filtersArray;
  }

  setFilterValuesOrder(filterArray) {
    filterArray.forEach(filter => {
      switch (Object.keys(filter)[0]) {

        case 'status':
          this.statusFilterOrder = filter.status;
          break;

        case 'orderManager':
          this.orderManagerFilter = this.usersList.find(val => val.id == filter['orderManager'])
          if (this.orderManagerFilter) {
            this.orderManagerEmail = this.orderManagerFilter.email;
          }
          break;

        case 'id':
          this.orderNameFilter = this.ordersSearchList.find(val => val.id == filter['id'])
          if (this.orderNameFilter) {
            this.orderName = this.orderNameFilter.name;
          }
          break;

        default:
        // code block
      }
    });
  }

  resetFilter() {
    this.isResetPressed = true;
    this.filtersArray = [{ status: ['Open'] }];
    this.approvalsList = [];
    this.projectName = '';
    this.projManagerEmail = '';
    this.projSponsorEmail = '';
    this.statusFilter = '';
    this.decisionFilter = ['Open'];
    this.documentTypeFilter = '';
    this.projectManagerFilter = '';
    this.projectSponsorFilter = '';
    this.projectNameFilter = '';
    this.isFilterApplied = false;
    this.dropdownPageIndex = 1;
    localStorage.setItem('ApprovalsFilter', JSON.stringify(this.filtersArray));

    this.spinner.show();
    this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray: [{ assignedTo: this.authService.userObj.id }, { status: 'Open' }] });

    let interval = setInterval(() => {
      if (this.isResetPressed == true) {
        clearInterval(interval);
        this.isResetPressed = false;
      }
    }, 500);
  }

  filter() {
    let filtersArray = this.setFilters();
    this.approvalsList = [];

    if (filtersArray.length > 0) {
      localStorage.setItem('ApprovalsFilter', JSON.stringify(filtersArray));
      this.filtersArray = filtersArray;
      this.dropdownPageIndex = 1;

      filtersArray.push({ assignedTo: this.authService.userObj.id });
      this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray });
      this.spinner.show();
    } else { //If no filter is selected, bring all data
      filtersArray.push({ assignedTo: this.authService.userObj.id });
      this.filtersArray = [{ portfolio: undefined }]
      this.socketService.emitEvent('ApprovalsFilterCount', { userId: this.authService.userObj.id, filtersArray });
      this.spinner.show();
    }
  }

  setFilters() {
    let filtersArray = new Array;


    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      filtersArray.push({ overallStatus: this.statusFilter })
    }

    if (this.documentTypeFilter != '' && this.documentTypeFilter != undefined) {
      filtersArray.push({ docType: this.documentTypeFilter })
    }

    if (this.decisionFilter != undefined && this.decisionFilter.length > 0) {
      filtersArray.push({ status: this.decisionFilter })
    }

    if (this.projectSponsorFilter) {
      let sponsorFilter = {
        or: [
          { 'projectOutline.projectSponsor.id': this.projectSponsorFilter.id },
          { 'projectOrder.projectSponsor.id': this.projectSponsorFilter.id },
          { 'changeRequest.projectSponsor.id': this.projectSponsorFilter.id },
          { 'closingReport.projectSponsor.id': this.projectSponsorFilter.id }
        ]
      }

      filtersArray.push(sponsorFilter)
    }
    if (this.projectManagerFilter) {
      let managerFilter = {
        or: [
          { 'projectOutline.projectManager.id': this.projectManagerFilter.id },
          { 'projectOrder.projectManager.id': this.projectManagerFilter.id },
          { 'changeRequest.projectManager.id': this.projectManagerFilter.id },
          { 'closingReport.projectManager.id': this.projectManagerFilter.id }
        ]
      }
      filtersArray.push(managerFilter)
    }
    if (this.projectNameFilter) {
      filtersArray.push({ 'project': this.projectNameFilter.id })
    }

    this.isFilterApplied = true;
    return filtersArray;
  }

  setFilterValues(filterArray) {
    filterArray.forEach(filter => {
      switch (Object.keys(filter)[0]) {

        case 'status':
          this.decisionFilter = filter.status;
          break;

        case 'overallStatus':
          this.statusFilter = filter.overallStatus;
          break;

        case 'docType':
          this.documentTypeFilter = filter.docType;
          break;

        case 'or':
          if (Object.keys(filter['or'][0])[0] == 'projectOutline.projectManager.id') {
            this.projectManagerFilter = this.usersList.find(val => val.id == filter['or'][0]['projectOutline.projectManager.id'])
            if (this.projectManagerFilter) {
              this.projManagerEmail = this.projectManagerFilter.email;
            }
          } else {
            this.projectSponsorFilter = this.usersList.find(val => val.id == filter['or'][0]['projectOutline.projectSponsor.id'])
            if (this.projectSponsorFilter) {
              this.projSponsorEmail = this.projectSponsorFilter.email;
            }
          }

          break;

        case 'project':
          this.projectNameFilter = this.projectsSearchList.find(val => val.id == filter['project'])
          if (this.projectNameFilter) {
            this.projectName = this.projectNameFilter.projectName;
          }
          break;


        default:
        // code block
      }
    });
  }

  projectsOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectNameFilter != undefined) {
      this.approvalsList = [];
      this.projectNameFilter = undefined;
      this.projectName = '';
      this.filter();
    }
    this.apiService.get('/projects/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsSearchList = response;
    });
  }

  projectsValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.projectNameFilter = e.itemData;
      this.filter();
    }
  }

  projectManagerValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.projectManagerFilter = response;
            this.filter();
          }
        });
      }
    }
  }

  projectManagerOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectManagerFilter != undefined) {
      this.approvalsList = [];
      this.projectManagerFilter = undefined;
      this.projManagerEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectManagerFilter = response;
      //       } else {
      //         this.projectManagerFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectManagerFilter = { id: '' };
      // }
    });
  }

  projectSponsorValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.projectSponsorFilter = response;
            this.filter();
          }
        });
      }
    }
  }

  projectSponsorOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.projectSponsorFilter != undefined) {
      this.approvalsList = [];
      this.projectSponsorFilter = undefined;
      this.projSponsorEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.projectSponsorFilter = response;
      //       } else {
      //         this.projectSponsorFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.projectSponsorFilter = { id: '' };
      // }
    });
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  onStatusSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  onDocumentSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  onDecisionSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  onStatusOrderSelectionChanged(e) {
    this.filterOrder();
  }

  ngOnDestroy() {
    this.socketService.removeListener('ApprovalsFilter');
    this.socketService.removeListener('ApprovalsFilterCount');
    this.socketService.removeListener('getSelectiveOrders');
    this.socketService.removeListener('fetchMultipleUsers');
    this.socketService.removeListener('smallOrdersFilter');
    this.socketService.removeListener('getSelectiveProjects');
  }
}
