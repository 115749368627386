import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateSmallOrderRoutingModule } from './create-small-order-routing.module';
import { CreateSmallOrderComponent } from './create-small-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DxDataGridModule, DxAutocompleteModule, DxSelectBoxModule, DxDateBoxModule,
  DxCheckBoxModule, DxProgressBarModule, DxDropDownBoxModule,
  DxTextBoxModule, DxPopoverModule, DxRadioGroupModule, DxTreeViewModule} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { PDFModule } from '../../pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  imports: [
    CommonModule,
    CreateSmallOrderRoutingModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxProgressBarModule,
    DxDropDownBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    CurrencyFormatterPipeModule,
    PDFModule,
    ModalModule,
    FileUploadModule
  ],
  declarations: [
    CreateSmallOrderComponent
  ]
})
export class CreateSmallOrderModule { }
