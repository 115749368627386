import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { ClipboardService } from 'ngx-clipboard';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-program-overview',
  templateUrl: './program-overview.component.html',
  styleUrls: ['./program-overview.component.scss']
})
export class ProgramOverviewComponent implements OnInit, OnDestroy {
  progSponsorEmail;
  tabs: FormGroup
  currentTab = 'Overview';
  programObj: any = {
    programCostTable1: [],
    programSponsor: {
      id: 0
    },
    programManager: {
      id: 0
    },
  };
  usersList = [];
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  programCostTable1;
  aggregatedProgramTable1;
  programId;
  statusReportTable = [];
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  totalTime;
  timeCompleted;
  endDateVSPlannedEndDate;
  plannedDateVSForecastDate;
  tabIndex = 0;
  prevStatusReport;
  goals: any = [];
  programBudgetCurrentYear = [];
  programBudgetNextYear = [];
  programBudgetCurrentYearCheck: boolean = false;
  programBudgetNextYearCheck: boolean = false;
  statusOptions = [];
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  attachment1;
  attachment2;
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  showNotification = false;
  FrontEnd_PATH;
  isProgramCost: boolean = false;
  isAggregatedProgramCost: boolean = true;
  tabsItemId;
  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  smallOrders = [];

  constructor(public utilityService: UtilityService, private apiService: ApiService, public authService: AuthService,
    private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
    private localize: I18nPipe, private clipboardService: ClipboardService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Program Details";
    this.currentTab = "Overview";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    globalThis = this;
    this.initProgramBudgetTables();

    this.route.params.subscribe(params => {
      this.programId = params.id;
      this.socketService.emitEvent('fetchProgram', { programId: params.id });
    });

    this.socketService.listen('programStatusReports').subscribe(response => {
      this.statusReportTable = response;
    });

    this.socketService.listen('fetchProgram').subscribe(response => {
      this.programObj = response;
      this.utilityService.breadcrumbList = ['My Programs', this.programObj.programName, 'Details', 'Overview'];

      if (this.programObj.programCostTable1 != undefined && this.programObj.programCostTable1 != undefined) {
        this.programCostTable1 = this.programObj.programCostTable1;
        this.programCostTable1 = this.programObj.programCostTable1;
      }

      if (this.programObj.programBudgetCurrentYear != undefined) {
        this.programBudgetCurrentYear = this.programObj.programBudgetCurrentYear;
      }
      if (this.programObj.programBudgetNextYear != undefined) {
        this.programBudgetNextYear = this.programObj.programBudgetNextYear;
      }
      if (this.programObj.programBudgetCurrentYearCheck != undefined) {
        this.programBudgetCurrentYearCheck = this.programObj.programBudgetCurrentYearCheck;
      }
      if (this.programObj.programBudgetNextYearCheck != undefined) {
        this.programBudgetNextYearCheck = this.programObj.programBudgetNextYearCheck;
      }

      if (this.programObj.programManager == undefined) {
        this.programObj.programManager = {
          email: ''
        }
      }

      if (this.programObj.programSponsor == undefined) {
        this.programObj.programSponsor = {
          email: ''
        }
      }

      if (this.programObj.smallOrders != undefined) {
        this.smallOrders = this.programObj.smallOrders;
      }

      this.EmptyProgramBudget();
      this.calculateProgramBudget();

      if (this.programObj.goals != undefined) {
        this.goals = this.programObj.goals;
      }

      this.setAggregatedDataTableValues();
      // this.fetchStatusReports(this.programId);

      let attach1: any = document.getElementById("attact1-span");
      let attach2: any = document.getElementById("attact2-span");
      if (this.programObj.attachments != undefined) {
        if (this.programObj.attachments[0].originalName != undefined) {
          attach1.value = this.programObj.attachments[0].originalName;
          this.attachment1 = this.programObj.attachments[0];
        }
        if (this.programObj.attachments[1].originalName != undefined) {
          attach2.value = this.programObj.attachments[1].originalName;
          this.attachment2 = this.programObj.attachments[1];
        }
      }

      if (this.programObj.isProgramCost != undefined) {
        this.isProgramCost = this.programObj.isProgramCost;
      }

      if (this.programObj.isAggregatedProgramCost != undefined) {
        this.isAggregatedProgramCost = this.programObj.isAggregatedProgramCost;
      }

      if (this.programObj.programSponsor && this.programObj.programSponsor.email != '') {
        this.progSponsorEmail = this.programObj.programSponsor.email;
      }

      this.spinner.hide();
      this.utilityService.programDetail.next({ programId: this.programId, programName: this.programObj.programName });
    });

    this.selectFile();
    this.selectFile1();
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.statusOptions = ['Active', 'On Hold', 'Closed', 'Cancelled'];

    this.programObj.purpose = "";

    this.programCostTable1 = [{
      costType: "External Costs",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      group: "OTHERS",
      id: 5,
    }, {
      costType: "Total",
      actualCost: '',
      forecast: '',
      currentBudget: "",
      originalBudget: '',
      id: 6,
      group: "OTHERS",
    },];

    this.initAggregatedValues();

    this.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
      {
        id: 6,
        description: ''
      },
      {
        id: 7,
        description: ''
      },
      {
        id: 8,
        description: ''
      },
      {
        id: 9,
        description: ''
      },
      {
        id: 10,
        description: ''
      }
    ];
  }

  initProgramBudgetTables() {
    this.programBudgetCurrentYear = [{
      costType: "External Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      group: "OTHERS",
      id: 5,
    }, {
      costType: "Total",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 6,
      group: "OTHERS",
    },];

    this.programBudgetNextYear = [{
      costType: "External Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      group: "OTHERS",
      id: 5,
    }, {
      costType: "Total",
      budget: "",
      assigned: "",
      remaining: "",
      remainingPercent: "",
      actualCost: "",
      forecast: "",
      id: 6,
      group: "OTHERS",
    },];
  }

  initAggregatedValues() {
    this.aggregatedProgramTable1 = [{
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 5,
      group: "OTHERS",
    }, {
      costType: "Total",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 6,
      group: "OTHERS",
    },];
  }

  setAggregatedDataTableValues() {
    if (this.programObj != undefined) {
      for (let i = 0; i < 7; i++) {
        this.programObj.reports.forEach((report, idx) => {
          this.aggregatedProgramTable1[i].actualCost += Math.round(report.costTypeTable[i].actualCost * this.currencyParser(report.currency));
          this.aggregatedProgramTable1[i].forecast += Math.round(report.costTypeTable[i].forecast * this.currencyParser(report.currency));
          this.aggregatedProgramTable1[i].currentBudget += Math.round(report.costTypeTable[i].currentBudget * this.currencyParser(report.currency));
          this.aggregatedProgramTable1[i].originalBudget += Math.round(report.costTypeTable[i].originalBudget * this.currencyParser(report.currency));
        });

        this.smallOrders.forEach((order, idx) => {
          this.aggregatedProgramTable1[i].actualCost += order.costTypeTable[i].actualCost * this.currencyParser(order.currency);
          this.aggregatedProgramTable1[i].forecast += order.costTypeTable[i].forecast * this.currencyParser(order.currency);
          this.aggregatedProgramTable1[i].currentBudget += order.costTypeTable[i].currentBudget * this.currencyParser(order.currency);
          this.aggregatedProgramTable1[i].originalBudget += order.costTypeTable[i].originalBudget * this.currencyParser(order.currency);
        });

        this.aggregatedProgramTable1[i].actualCost += this.programCostTable1[i].actualCost * 1;
        this.aggregatedProgramTable1[i].forecast += this.programCostTable1[i].forecast * 1;
        this.aggregatedProgramTable1[i].currentBudget += this.programCostTable1[i].currentBudget * 1;
        this.aggregatedProgramTable1[i].originalBudget += this.programCostTable1[i].originalBudget * 1;
      }
    }
  }

  currencyParser(currency): number {
    if (currency == 'CHF') {
      return 1;
    } else if (currency == 'EUR') {
      return this.authService.userObj.configuration.rates.EUR;
    } else if (currency == 'GBP') {
      return this.authService.userObj.configuration.rates.GBP;
    } else if (currency == 'USD') {
      return this.authService.userObj.configuration.rates.USD;
    }
  }

  tabsItemClick(e) {
    this.currentTab = e;
    if (this.currentTab == 'Overview') {
      setTimeout(() => {
        let attach1: any = document.getElementById("attact1-span");
        let attach2: any = document.getElementById("attact2-span");
        if (this.programObj.attachments != undefined) {
          if (this.programObj.attachments[0].originalName != undefined) {
            attach1.value = this.programObj.attachments[0].originalName;
            this.attachment1 = this.programObj.attachments[0];
          }
          if (this.programObj.attachments[1].originalName != undefined) {
            attach2.value = this.programObj.attachments[1].originalName;
            this.attachment2 = this.programObj.attachments[1];
          }
        }
      }, 500);
    }
  }

  onEditorPreparingCostTypeTable(e) {
    if (e.parentType == "dataRow") {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateActualCostValue(e) {
    if (e.costType == 'Revenues') {
      return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programCostTable1[6].actualCost = globalThis.projectCostsValueCalculator('actualCost');
      return globalThis.programCostTable1[6].actualCost;
    } else {
      return e.actualCost ? e.actualCost : '';
    }
  }

  calculateForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programCostTable1[6].forecast = globalThis.projectCostsValueCalculator('forecast');
      return globalThis.programCostTable1[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.programCostTable1[0][field] * 1;
    let two: any = globalThis.programCostTable1[1][field] * 1;
    let three: any = globalThis.programCostTable1[2][field] * 1;
    let four: any = globalThis.programCostTable1[3][field] * 1;
    let five: any = globalThis.programCostTable1[4][field] * 1;
    let six: any = globalThis.programCostTable1[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  calculateCurrentBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programCostTable1[6].currentBudget = globalThis.projectCostsValueCalculator1('currentBudget');
      return globalThis.programCostTable1[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  projectCostsValueCalculator1(field) {
    let one: any = globalThis.programCostTable1[0][field] * 1;
    let two: any = globalThis.programCostTable1[1][field] * 1;
    let three: any = globalThis.programCostTable1[2][field] * 1;
    let four: any = globalThis.programCostTable1[3][field] * 1;
    let five: any = globalThis.programCostTable1[4][field] * 1;
    let six: any = globalThis.programCostTable1[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  calculateOriginalBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programCostTable1[6].originalBudget = globalThis.projectCostsValueCalculator1('originalBudget');
      return globalThis.programCostTable1[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  projectAggregateValueCalculator(e) {
    if (e.id == 4) {
      globalThis.programCostTable1[4].originalBudget = e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.id == "6") {
      globalThis.aggregatedProgramTable1[6].originalBudget = globalThis.projectCostsValueCalculator1('originalBudget');
      return globalThis.aggregatedProgramTable1[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  calculateAggregateValue(e) {
    if (e.costType == 'Revenues') {
      return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedProgramTable1[6].actualCost = globalThis.projectAggregateCostsValueCalculator('actualCost');
      return globalThis.aggregatedProgramTable1[6].actualCost;
    } else {
      return e.actualCost ? e.actualCost : '';
    }
  }

  calculateAggregateForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedProgramTable1[6].forecast = globalThis.projectAggregateCostsValueCalculator('forecast');
      return globalThis.aggregatedProgramTable1[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  calculateAggregateCurrentBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedProgramTable1[6].currentBudget = globalThis.projectAggregateCostsValueCalculator1('currentBudget');
      return globalThis.aggregatedProgramTable1[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  projectAggregateCostsValueCalculator(field) {
    let one: any = globalThis.aggregatedProgramTable1[0][field] * 1;
    let two: any = globalThis.aggregatedProgramTable1[1][field] * 1;
    let three: any = globalThis.aggregatedProgramTable1[2][field] * 1;
    let four: any = globalThis.aggregatedProgramTable1[3][field] * 1;
    let five: any = globalThis.aggregatedProgramTable1[4][field] * 1;
    let six: any = globalThis.aggregatedProgramTable1[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  projectAggregateCostsValueCalculator1(field) {
    let one: any = globalThis.aggregatedProgramTable1[0][field] * 1;
    let two: any = globalThis.aggregatedProgramTable1[1][field] * 1;
    let three: any = globalThis.aggregatedProgramTable1[2][field] * 1;
    let four: any = globalThis.aggregatedProgramTable1[3][field] * 1;
    let five: any = globalThis.aggregatedProgramTable1[4][field] * 1;
    let six: any = globalThis.aggregatedProgramTable1[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  calculateAggregateOriginalBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedProgramTable1[6].originalBudget = globalThis.projectAggregateCostsValueCalculator1('originalBudget');
      return globalThis.aggregatedProgramTable1[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  save() {
    // this.programObj.programCostTable1 = this.programCostTable1;
    if (this.programObj.documentLink && this.programObj.documentLink != '') {
      if (!this.isWebURL(this.programObj.documentLink)) {
        this.showNotificationBox('Warning', 'Please enter a valid link for Documents', 'warning');
        return;
      }
      this.programObj.documentLink = this.isWebViewUrl(this.programObj.documentLink) ? this.programObj.documentLink : `http://${this.programObj.documentLink}`;
    }
    if (!this.isStartDateValid) {
      this.showNotificationBox('Warning', 'Please enter start date in correct format (dd.MM.yyyy) first.', 'warning');
      return;
    }
    if (!this.isEndDateValid) {
      this.showNotificationBox('Warning', 'Please enter end date in correct format (dd.MM.yyyy) first.', 'warning');
      return;
    }
    this.programObj.goals = this.goals;
    this.programObj.programCostTable1 = this.programCostTable1;
    this.programObj.aggregatedProgramTable1 = this.aggregatedProgramTable1;
    let reports = this.programObj.reports;
    delete (this.programObj.reports);

    this.programObj.attachments = [
      this.attachment1 ? this.attachment1 : "",
      this.attachment2 ? this.attachment2 : ""
    ];

    this.programObj.isProgramCost = this.isProgramCost;
    this.programObj.isAggregatedProgramCost = this.isAggregatedProgramCost;

    this.apiService.put('/program/' + this.programObj.id, this.programObj).subscribe(response => {
      this.initAggregatedValues();
      this.programObj.reports = reports;
      this.setAggregatedDataTableValues();

      this.utilityService.showNotification(`${this.localize.transform('Save Success')}`,
        `${this.localize.transform('Program has been saved.')}`, 'success');
    });
  }

  onCellPreparedFinancials(e) {
    // if (e.columnIndex > 3) {
    //   if (this.authService.userObj.role != 'pmo' && this.authService.userObj.role != 'admin') {
    //     e.cellElement.style.backgroundColor = "#eee";
    //   }
    // }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onRowPreparedFinancials(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onRowPreparedAggregated(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onCellPreparedAggregated(e) {
    e.cellElement.style.backgroundColor = "#ddd";
    if (e.columnIndex > 0) {
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    } else if (e.columnIndex == 0) {
      e.cellElement.style.borderBottom = "1px solid #eee";
    }
  }

  fetchStatusReports(id) {
    this.socketService.emitEvent('programStatusReports', { id });
    // this.apiService.get('/getStatusReportsByProgram/' + id).subscribe((statusReportList: any) => {
    //   this.statusReportTable = statusReportList;
    // });
  }

  actionEvent(event, item) {
    let elem: any = document.getElementById('status-' + item.key.id);

    if (event.target.value == "Edit") {
      this.router.navigate(['program/detail/createProgramReport', { reportId: item.key.id, programId: this.programId, lastStatus: this.statusReportTable.length > 1 ? this.statusReportTable[this.statusReportTable.length - 2].id : null }]);
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "View") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        this.openStatusPreview(item.key, item.component.getVisibleRows()[item.rowIndex - 1].key);
      } else {
        this.openStatusPreview(item.key, {});
      }
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "Delete") {
      this.deleteReportConfirmation(item.key.id);
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "copy") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        let text = `${this.FrontEnd_PATH}/view/program/statusReport/${item.component.getVisibleRows()[item.rowIndex - 1].key.id}/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      } else {
        let text = `${this.FrontEnd_PATH}/view/program/statusReport/0/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      }
      elem.options.selectedIndex = 0;
    }
  }

  deleteReportConfirmation(id) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/programStatusReports/' + id).subscribe(response => {    // statusReports
          let data = this.statusReportTable.filter(report => report.id != id);
          this.statusReportTable = data;

          this.apiService.put('/program/' + this.programId, {
            hasDraftReport: false,
          }).subscribe(response => {
            this.programObj.hasDraftReport = false;
            this.createReportDisableChecker();
            swal(
              {
                title: `${this.localize.transform('Success')}`,
                text: `${this.localize.transform('Project Report has been Deleted.')}`,
                type: 'success',
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              }
            );
          });
        });
      }
    });
  }

  openStatusPreview(outline, prev) {
    this.statusReportPreview = outline;
    this.prevStatusReport = prev;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

  programSponsorSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(user => {
          if (user.id == e.value) {
            this.programObj.programSponsor = user;
          }
        });
      }
    }
  }

  programManagerSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(user => {
          if (user.id == e.value) {
            this.programObj.programManager = user;
          }
        });
      }
    }
  }

  forecastValueChange(e) {
    this.calculateDatePercentages();
  }

  calculateDatePercentages() {
    let endDate: any = moment(this.programObj.endDate);
    let startDate: any = moment(this.programObj.startDate);
    let plannedDate: any = moment(this.programObj.plannedEndDate);
    let forecastDate: any = moment(this.programObj.forecastEndDate);
    let now = moment(Date.now());

    this.totalTime = endDate.diff(startDate, 'days') + ' days';
    this.timeCompleted = (100 - (Math.round((endDate.diff(startDate, 'days') - now.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100))) + '%';
    this.endDateVSPlannedEndDate = Math.round((plannedDate.diff(startDate, 'days') - endDate.diff(startDate, 'days')) / endDate.diff(startDate, 'days') * 100) + '%';
    if (this.programObj.forecastEndDate != undefined) {
      this.plannedDateVSForecastDate = Math.round((plannedDate.diff(forecastDate, 'days') / plannedDate.diff(startDate, 'days')) * -100) + '%';
    }
  }

  programManagerValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.programObj.programManager = response;
        }
      });
    }
  }

  additionalProgramManagerValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.programObj.additionalProgramManager = response;
        }
      });
    }
  }

  programSponsorValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.programObj.programSponsor = response;
        }
      });
    }
  }

  projectManagerOnInputEvent(e) {
    this.apiService.get('/user/search/' + this.programObj.programManager.email).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  additionalProgramManagerOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.programObj.programSponsor = response;
            } else {
              this.programObj.programSponsor = { id: '' };
            }
          });
        }
      } else {
        this.programObj.programSponsor = { id: '' };
      }
    });
  }

  createReportDisableChecker(): boolean {
    if (this.programObj.hasDraftReport == true || this.authService.userObj.role == 'guest') {
      return true;
    } else {
      return false;
    }
  }

  // Program Current Year

  onRowPreparedProgramBudgetCurrentYear(e) {
    this.setRowIndex9(e);
  }

  onCellPreparedProgramBudgetCurrentYear(e) {
    if (this.programBudgetCurrentYearCheck == false) {
      if (e.columnIndex > 1 && e.columnIndex < 3) {
        e.cellElement.style.backgroundColor = "#ddd";
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
    else {
      if (e.rowIndex != 9 && e.parentType == 'dataRow') {
        if (e.columnIndex > 1 && e.columnIndex < 3) {
          e.cellElement.style.backgroundColor = "#FFF";
        }
      }
    }

    if (e.columnIndex > 2) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingProgramBudgetCurrentYear(e) {
    this.editRowIndex9(e);
  }

  calculateProgramCurrentYearBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetCurrentYear[6].budget = globalThis.programCostsValueCalculator('budget', 'Current');
      return globalThis.programBudgetCurrentYear[6].budget;
    } else {
      globalThis.calculateRemainingProgramBudgetCurrentYear();
      return e.budget ? e.budget : '';
    }
  }

  calculateProgramCurrentYearAssignedValue(e) {
    if (e.costType == 'Revenues') {
      return e.assigned ? (Math.abs(e.assigned) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetCurrentYear[6].assigned = globalThis.programCostsValueCalculator('assigned', 'Current');
      return globalThis.programBudgetCurrentYear[6].assigned;
    }
  }

  calculateProgramCurrentYearRemainingValue(e) {
  }

  calculateProgramCurrentYearRemainingPercentValue(e) {
  }

  calculateProgramCurrentYearActualCostValue(e) {
    if (e.costType == 'Revenues') {
      return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetCurrentYear[6].actualCost = globalThis.programCostsValueCalculator('actualCost', 'Current');
      return globalThis.programBudgetCurrentYear[6].actualCost;
    }
  }

  calculateProgramCurrentYearForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetCurrentYear[6].forecast = globalThis.programCostsValueCalculator('forecast', 'Current');
      return globalThis.programBudgetCurrentYear[6].forecast;
    }
  }

  calculateRemainingProgramBudgetCurrentYear() {
    if (+this.programBudgetCurrentYear[0].budget > 0) {
      this.programBudgetCurrentYear[0].remaining = +this.programBudgetCurrentYear[0].budget
        - +this.programBudgetCurrentYear[0].assigned;

      this.programBudgetCurrentYear[0].remainingPercent =
        (+(this.programBudgetCurrentYear[0].remaining * 100) / +this.programBudgetCurrentYear[0].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[1].budget > 0) {
      this.programBudgetCurrentYear[1].remaining = +this.programBudgetCurrentYear[1].budget
        - +this.programBudgetCurrentYear[1].assigned;

      this.programBudgetCurrentYear[1].remainingPercent =
        (+(this.programBudgetCurrentYear[1].remaining * 100) / +this.programBudgetCurrentYear[1].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[2].budget > 0) {
      this.programBudgetCurrentYear[2].remaining = +this.programBudgetCurrentYear[2].budget
        - +this.programBudgetCurrentYear[2].assigned;

      this.programBudgetCurrentYear[2].remainingPercent =
        (+(this.programBudgetCurrentYear[2].remaining * 100) / +this.programBudgetCurrentYear[2].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[3].budget > 0) {
      this.programBudgetCurrentYear[3].remaining = +this.programBudgetCurrentYear[3].budget
        - +this.programBudgetCurrentYear[3].assigned;

      this.programBudgetCurrentYear[3].remainingPercent =
        (+(this.programBudgetCurrentYear[3].remaining * 100) / +this.programBudgetCurrentYear[3].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[4].budget > 0) {
      this.programBudgetCurrentYear[4].remaining = +this.programBudgetCurrentYear[4].budget
        - +this.programBudgetCurrentYear[4].assigned;

      this.programBudgetCurrentYear[4].remainingPercent =
        (+(this.programBudgetCurrentYear[4].remaining * 100) / +this.programBudgetCurrentYear[4].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[5].budget > 0) {
      this.programBudgetCurrentYear[5].remaining = +this.programBudgetCurrentYear[5].budget
        - +this.programBudgetCurrentYear[5].assigned;

      this.programBudgetCurrentYear[5].remainingPercent =
        (+(this.programBudgetCurrentYear[5].remaining * 100) / +this.programBudgetCurrentYear[5].budget).toFixed(2);
    }

    if (+this.programBudgetCurrentYear[6].budget > 0) {
      this.programBudgetCurrentYear[6].remaining = +this.programBudgetCurrentYear[6].budget
        - +this.programBudgetCurrentYear[6].assigned;

      this.programBudgetCurrentYear[6].remainingPercent =
        (+(this.programBudgetCurrentYear[6].remaining * 100) / +this.programBudgetCurrentYear[6].budget).toFixed(2);
    }
  }

  onRowPreparedProgramBudgetNextYear(e) {
    this.setRowIndex9(e);
  }

  onCellPreparedProgramBudgetNextYear(e) {
    if (this.programBudgetNextYearCheck == false) {
      if (e.columnIndex > 1 && e.columnIndex < 3) {
        e.cellElement.style.backgroundColor = "#ddd";
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
    else {
      if (e.rowIndex != 9 && e.parentType == 'dataRow') {
        if (e.columnIndex > 1 && e.columnIndex < 3) {
          e.cellElement.style.backgroundColor = "#FFF";
        }
      }
    }

    if (e.columnIndex > 2) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingProgramBudgetNextYear(e) {
    this.editRowIndex9(e);
  }

  calculateProgramNextYearBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetNextYear[6].budget = globalThis.programCostsValueCalculator('budget', 'Next');
      return globalThis.programBudgetNextYear[6].budget;
    } else {
      globalThis.calculateRemainingProgramBudgetNextYear();
      return e.budget ? e.budget : '';
    }
  }

  calculateProgramNextYearAssignedValue(e) {
    if (e.costType == 'Revenues') {
      return e.assigned ? (Math.abs(e.assigned) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.programBudgetNextYear[6].assigned = globalThis.programCostsValueCalculator('assigned', 'Next');
      return globalThis.programBudgetNextYear[6].assigned;
    }
  }

  calculateProgramNextYearRemainingValue(e) {
  }

  calculateProgramNextYearRemainingPercentValue(e) {
  }

  // calculateProgramNextYearActualCostValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.programBudgetNextYear[6].actualCost = globalThis.programCostsValueCalculator('actualCost', 'Next');
  //     return globalThis.programBudgetNextYear[6].actualCost;
  //   }
  // }

  // calculateProgramNextYearForecastValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.forecast ? (Math.abs(e.forecast) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.programBudgetNextYear[6].forecast = globalThis.programCostsValueCalculator('forecast', 'Next');
  //     return globalThis.programBudgetNextYear[6].forecast;
  //   }
  // }

  calculateRemainingProgramBudgetNextYear() {
    if (+this.programBudgetNextYear[0].budget > 0) {
      this.programBudgetNextYear[0].remaining = +this.programBudgetNextYear[0].budget
        - +this.programBudgetNextYear[0].assigned;

      this.programBudgetNextYear[0].remainingPercent =
        (+(this.programBudgetNextYear[0].remaining * 100) / +this.programBudgetNextYear[0].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[1].budget > 0) {
      this.programBudgetNextYear[1].remaining = +this.programBudgetNextYear[1].budget
        - +this.programBudgetNextYear[1].assigned;

      this.programBudgetNextYear[1].remainingPercent =
        (+(this.programBudgetNextYear[1].remaining * 100) / +this.programBudgetNextYear[1].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[2].budget > 0) {
      this.programBudgetNextYear[2].remaining = +this.programBudgetNextYear[2].budget
        - +this.programBudgetNextYear[2].assigned;

      this.programBudgetNextYear[2].remainingPercent =
        (+(this.programBudgetNextYear[2].remaining * 100) / +this.programBudgetNextYear[2].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[3].budget > 0) {
      this.programBudgetNextYear[3].remaining = +this.programBudgetNextYear[3].budget
        - +this.programBudgetNextYear[3].assigned;

      this.programBudgetNextYear[3].remainingPercent =
        (+(this.programBudgetNextYear[3].remaining * 100) / +this.programBudgetNextYear[3].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[4].budget > 0) {
      this.programBudgetNextYear[4].remaining = +this.programBudgetNextYear[4].budget
        - +this.programBudgetNextYear[4].assigned;

      this.programBudgetNextYear[4].remainingPercent =
        (+(this.programBudgetNextYear[4].remaining * 100) / +this.programBudgetNextYear[4].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[5].budget > 0) {
      this.programBudgetNextYear[5].remaining = +this.programBudgetNextYear[5].budget
        - +this.programBudgetNextYear[5].assigned;

      this.programBudgetNextYear[5].remainingPercent =
        (+(this.programBudgetNextYear[5].remaining * 100) / +this.programBudgetNextYear[5].budget).toFixed(2);
    }

    if (+this.programBudgetNextYear[6].budget > 0) {
      this.programBudgetNextYear[6].remaining = +this.programBudgetNextYear[6].budget
        - +this.programBudgetNextYear[6].assigned;

      this.programBudgetNextYear[6].remainingPercent =
        (+(this.programBudgetNextYear[6].remaining * 100) / +this.programBudgetNextYear[6].budget).toFixed(2);
    }
  }

  programCostsValueCalculator(field, tableType) {
    if (tableType == 'Current') {
      let one: any = globalThis.programBudgetCurrentYear[0][field] * 1;
      let two: any = globalThis.programBudgetCurrentYear[1][field] * 1;
      let three: any = globalThis.programBudgetCurrentYear[2][field] * 1;
      let four: any = globalThis.programBudgetCurrentYear[3][field] * 1;
      let five: any = globalThis.programBudgetCurrentYear[4][field] * 1;
      let six: any = globalThis.programBudgetCurrentYear[5][field] * 1;
      let total = one + two + three + four - five + six;
      return total;
    }
    else if (tableType == 'Next') {
      let one: any = globalThis.programBudgetNextYear[0][field] * 1;
      let two: any = globalThis.programBudgetNextYear[1][field] * 1;
      let three: any = globalThis.programBudgetNextYear[2][field] * 1;
      let four: any = globalThis.programBudgetNextYear[3][field] * 1;
      let five: any = globalThis.programBudgetNextYear[4][field] * 1;
      let six: any = globalThis.programBudgetNextYear[5][field] * 1;
      let total = one + two + three + four - five + six;
      return total;
    }
  }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  EmptyProgramBudget() {
    // Current Year

    // Empty Assigned Value
    this.programBudgetCurrentYear[0].assigned = 0;
    this.programBudgetCurrentYear[1].assigned = 0;
    this.programBudgetCurrentYear[2].assigned = 0;
    this.programBudgetCurrentYear[3].assigned = 0;
    this.programBudgetCurrentYear[4].assigned = 0;
    this.programBudgetCurrentYear[5].assigned = 0;
    this.programBudgetCurrentYear[6].assigned = 0;

    // Empty Actual Value
    this.programBudgetCurrentYear[0].actualCost = 0;
    this.programBudgetCurrentYear[1].actualCost = 0;
    this.programBudgetCurrentYear[2].actualCost = 0;
    this.programBudgetCurrentYear[3].actualCost = 0;
    this.programBudgetCurrentYear[4].actualCost = 0;
    this.programBudgetCurrentYear[5].actualCost = 0;
    this.programBudgetCurrentYear[6].actualCost = 0;

    // Empty Forecast Value
    this.programBudgetCurrentYear[0].forecast = 0;
    this.programBudgetCurrentYear[1].forecast = 0;
    this.programBudgetCurrentYear[2].forecast = 0;
    this.programBudgetCurrentYear[3].forecast = 0;
    this.programBudgetCurrentYear[4].forecast = 0;
    this.programBudgetCurrentYear[5].forecast = 0;
    this.programBudgetCurrentYear[6].forecast = 0;

    // Next Year

    // Empty Assigned Value
    this.programBudgetNextYear[0].assigned = 0;
    this.programBudgetNextYear[1].assigned = 0;
    this.programBudgetNextYear[2].assigned = 0;
    this.programBudgetNextYear[3].assigned = 0;
    this.programBudgetNextYear[4].assigned = 0;
    this.programBudgetNextYear[5].assigned = 0;
    this.programBudgetNextYear[6].assigned = 0;
  }

  calculateProgramBudget() {
    if (this.programObj.reports != undefined) {
      if (this.programObj.reports.length > 0) {
        for (let i = 0; i < this.programObj.reports.length; i++) {

          // Current Year
          if (this.programObj.reports[i].budgetPlanningTable1 != undefined) {
            // Calculate Assigned Value
            this.programBudgetCurrentYear[0].assigned = +this.programBudgetCurrentYear[0].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[0].budget;

            this.programBudgetCurrentYear[1].assigned = +this.programBudgetCurrentYear[1].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[1].budget;

            this.programBudgetCurrentYear[2].assigned = +this.programBudgetCurrentYear[2].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[2].budget;

            this.programBudgetCurrentYear[3].assigned = +this.programBudgetCurrentYear[3].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[3].budget;

            this.programBudgetCurrentYear[4].assigned = +this.programBudgetCurrentYear[4].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[4].budget;

            this.programBudgetCurrentYear[5].assigned = +this.programBudgetCurrentYear[5].assigned +
              +this.programObj.reports[i].budgetPlanningTable1[5].budget;

            // Calculate Actual Cost Value
            this.programBudgetCurrentYear[0].actualCost = +this.programBudgetCurrentYear[0].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[0].actualCost;

            this.programBudgetCurrentYear[1].actualCost = +this.programBudgetCurrentYear[1].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[1].actualCost;

            this.programBudgetCurrentYear[2].actualCost = +this.programBudgetCurrentYear[2].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[2].actualCost;

            this.programBudgetCurrentYear[3].actualCost = +this.programBudgetCurrentYear[3].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[3].actualCost;

            this.programBudgetCurrentYear[4].actualCost = +this.programBudgetCurrentYear[4].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[4].actualCost;

            this.programBudgetCurrentYear[5].actualCost = +this.programBudgetCurrentYear[5].actualCost +
              +this.programObj.reports[i].budgetPlanningTable1[5].actualCost;

            // Calculate Forecast Value
            this.programBudgetCurrentYear[0].forecast = +this.programBudgetCurrentYear[0].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[0].forecast;

            this.programBudgetCurrentYear[1].forecast = +this.programBudgetCurrentYear[1].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[1].forecast;

            this.programBudgetCurrentYear[2].forecast = +this.programBudgetCurrentYear[2].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[2].forecast;

            this.programBudgetCurrentYear[3].forecast = +this.programBudgetCurrentYear[3].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[3].forecast;

            this.programBudgetCurrentYear[4].forecast = +this.programBudgetCurrentYear[4].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[4].forecast;

            this.programBudgetCurrentYear[5].forecast = +this.programBudgetCurrentYear[5].forecast +
              +this.programObj.reports[i].budgetPlanningTable1[5].forecast;
          }


          // Next Year
          if (this.programObj.reports[i].budgetPlanningTable2 != undefined) {
            // Calculate Assigned Value
            this.programBudgetNextYear[0].assigned = +this.programBudgetNextYear[0].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[0].budget;

            this.programBudgetNextYear[1].assigned = +this.programBudgetNextYear[1].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[1].budget;

            this.programBudgetNextYear[2].assigned = +this.programBudgetNextYear[2].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[2].budget;

            this.programBudgetNextYear[3].assigned = +this.programBudgetNextYear[3].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[3].budget;

            this.programBudgetNextYear[4].assigned = +this.programBudgetNextYear[4].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[4].budget;

            this.programBudgetNextYear[5].assigned = +this.programBudgetNextYear[5].assigned +
              +this.programObj.reports[i].budgetPlanningTable2[5].budget;

            // Calculate Actual Cost Value
            // this.programBudgetNextYear[0].actualCost = +this.programBudgetNextYear[0].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[0].actualCost;

            // this.programBudgetNextYear[1].actualCost = +this.programBudgetNextYear[1].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[1].actualCost;

            // this.programBudgetNextYear[2].actualCost = +this.programBudgetNextYear[2].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[2].actualCost;

            // this.programBudgetNextYear[3].actualCost = +this.programBudgetNextYear[3].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[3].actualCost;

            // this.programBudgetNextYear[4].actualCost = +this.programBudgetNextYear[4].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[4].actualCost;

            // this.programBudgetNextYear[5].actualCost = +this.programBudgetNextYear[5].actualCost +
            //   +this.programObj.reports[i].budgetPlanningTable2[5].actualCost;

            // Calculate Forecast Value
            // this.programBudgetNextYear[0].forecast = +this.programBudgetNextYear[0].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[0].forecast;

            // this.programBudgetNextYear[1].forecast = +this.programBudgetNextYear[1].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[1].forecast;

            // this.programBudgetNextYear[2].forecast = +this.programBudgetNextYear[2].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[2].forecast;

            // this.programBudgetNextYear[3].forecast = +this.programBudgetNextYear[3].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[3].forecast;

            // this.programBudgetNextYear[4].forecast = +this.programBudgetNextYear[4].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[4].forecast;

            // this.programBudgetNextYear[5].forecast = +this.programBudgetNextYear[5].forecast +
            //   +this.programObj.reports[i].budgetPlanningTable2[5].forecast;
          }
        }
      }
    }
  }

  saveProgramBudget() {
    this.programObj.programBudgetCurrentYear = this.programBudgetCurrentYear;
    this.programObj.programBudgetNextYear = this.programBudgetNextYear;
    this.programObj.programBudgetCurrentYearCheck = this.programBudgetCurrentYearCheck;
    this.programObj.programBudgetNextYearCheck = this.programBudgetNextYearCheck;

    this.apiService.put('/program/' + this.programId, this.programObj).subscribe(response => {
      this.utilityService.showNotification(`${this.localize.transform('Save Success')}`,
        `${this.localize.transform('Program has been saved.')}`, 'success');
    });
  }

  public showAttachmentDeleteButton(type: string): boolean {
    if (this.programObj.attachments != undefined) {
      if (this.programObj.attachments.length > 0) {
        if (type == '1') {
          return (this.programObj.id != undefined && this.programObj.attachments[0] != "");
        } else {
          return (this.programObj.id != undefined && this.programObj.attachments[1] != "");
        }
      } else {
        return false;
      }
    }
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  deleteAttachment(type: string) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.apiService.delete("/attachment/" + this.programObj.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.programObj.attachments[0] = "";

              this.apiService.put('/program/' + this.programId, {
                attachments: this.programObj.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";
                let fileInput: any = document.getElementById('pmt-uploader');
                fileInput.value = '';

                this.notificationBox("Success", "Attachment deleted.", "success");
              });
            });
          });
        } else {
          this.apiService.delete("/attachment/" + this.programObj.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.programObj.attachments[1] = "";

              this.apiService.put('/program/' + this.programId, {
                attachments: this.programObj.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";
                let fileInput: any = document.getElementById('pmt-uploader2');
                fileInput.value = '';

                this.notificationBox("Success", "Attachment deleted.", "success");
              });
            });
          });
        }
      }
    });
  }

  notificationBox(title, text, type) {
    swal({
      title: this.localize.transform(title),
      text: this.localize.transform(text),
      type: type,
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    });
  }

  saveHelper() {
    if (this.programObj.isAttachement1Added == true || this.programObj.isAttachement2Added == true) {
      this.uploadAttachments();
    } else {
      this.save();
    }
  }

  uploadAttachments() {
    this.showNotification = true;
    if (this.programObj.isAttachement1Added) {
      this.attachment1Uploaded = false;
      this.uploader.uploadAll();
    }
    if (this.programObj.isAttachement2Added) {
      this.attachment2Uploaded = false;
      this.uploader1.uploadAll();
    }
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.programObj.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);
        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);

      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.programObj.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.programObj.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        this.attachment1Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          this.save();
        }
      });
    };
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.programObj.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };

    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.programObj.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.programObj.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        this.attachment2Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          this.save();
        }
      });
    };
  }

  isWebURL(value) {
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(value)
  }

  isWebViewUrl(value) {
    let regexp = /^(http|https):///
    return regexp.test(value)
  }

  redirectToLink() {
    if (this.programObj.documentLink) {
      window.open(this.programObj.documentLink, "_blank");
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('programStatusReports');
    this.socketService.removeListener('fetchProgram');
  }

  deleteProgram() {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('Are you sure you want to delete?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/program/' + this.programId).subscribe(response => {
          this.notificationBox('Success', 'Program deleted successfully.', 'success');
          this.router.navigate(['program']);
        });
      }
    });
  }

  programCostCheckChange(e) {
    if (this.isProgramCost) {
      this.isAggregatedProgramCost = false;
    }
  }

  aggregatedProgramCostCheckChange(e) {
    if (this.isAggregatedProgramCost) {
      this.isProgramCost = false;
    }
  }
}
