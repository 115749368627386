import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmallOrderDetailsComponent } from './small-order-details.component';


const routes: Routes = [
  {
    path: '',
    component: SmallOrderDetailsComponent,
    children: [
      {
        path: '',
        redirectTo: 'statusReport'
      },
      {
        path: 'statusReport',
        loadChildren: () => import('../small-order-status-report/small-order-status-report.module').then(mod => mod.SmallOrderStatusReportModule),
      },
      {
        path: 'team',
        loadChildren: () => import('./small-order-team/small-order-team.module').then(mod => mod.SmallOrderTeamModule),
      },
      {
        path: 'orgChart',
        loadChildren: () => import('./small-order-org-chart/small-order-org-chart.module').then(mod => mod.SmallOrderOrgChartModule),
      },
      {
        path: 'gantt',
        loadChildren: () => import('./small-order-gantt/small-order-gantt.module').then(mod => mod.SmallOrderGanttModule),
      },
      {
        path: 'budget',
        loadChildren: () => import('../small-order-budget/small-order-budget.module').then(mod => mod.SmallOrderBudgetModule)
      },
      {
        path: 'lessonsLearned',
        loadChildren: () => import('../small-order-details/small-orders-lessons-learned/small-orders-lessons-learned.module').then(m => m.SmallOrdersLessonsLearnedModule)
      }
    ]
  },
  {
    path: 'statusReport/:previous',
    loadChildren: () => import('../create-order-report/create-order-report.module').then(mod => mod.CreateOrderReportModule)
  },
  {
    path: 'statusReport/:previous/:current',
    loadChildren: () => import('../create-order-report/create-order-report.module').then(mod => mod.CreateOrderReportModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmallOrderDetailsRoutingModule { }
