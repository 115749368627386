import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pdf-closing-report-view',
  templateUrl: './pdf-closing-report-view.component.html',
  styleUrls: ['./pdf-closing-report-view.component.scss']
})
export class PdfClosingReportViewComponent implements OnInit {
  closingReportPreview;
  showClosingPreview = false;
  @ViewChild('lgClosingModal', {static: false}) public lgClosingModal;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  modalRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private utilityService: UtilityService,
    private modalService: BsModalService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(params => {
      this.openClosingReportPreview(params['id']);
    });
  }

  openClosingReportPreview(id) {
    this.showClosingPreview = false;
    this.apiService.get('/outlineApproval/' + id).subscribe((response: any) => {
      if (response.closingReport.project.projectReport == undefined) {
        this.apiService.get('/projects/' + response.closingReport.project).subscribe(projectResponse => {
          response.closingReport.project = projectResponse;
          this.apiService.get('/reports/' + response.closingReport.project.projectReport.id).subscribe(reportResponse => {
            response.closingReport.project.projectReport = reportResponse;
            this.closingReportPreview = response.closingReport;
            this.closingReportPreview.closingReportId = response.closingReport.id;

            setTimeout(() => {
              this.showClosingPreview = true;
            }, 100);

            setTimeout(() => {
              document.getElementById("download").style.display = "none";
            }, 100);

            this.spinner.hide();

            this.modalRef = this.modalService.show(this.lgClosingModal, this.config);
            this.modalService.onHidden.subscribe((reason: string) => {
              this.showClosingPreview = false;
            });
          });
        });
      }
      else {
        this.closingReportPreview = response.closingReport;
        this.closingReportPreview.closingReportId = response.closingReport.id;

        setTimeout(() => {
          this.showClosingPreview = true;
        }, 100);

        this.spinner.hide();

        this.modalRef = this.modalService.show(this.lgClosingModal, this.config);
        this.modalService.onHidden.subscribe((reason: string) => {
          this.showClosingPreview = false;
        });
      }
    }, (error) => {
      this.apiService.get('/closingReport/' + id).subscribe((responseClosingReport: any) => {
        this.apiService.get('/reports/' + responseClosingReport.project.projectReport).subscribe(responseProjectReport => {
          responseClosingReport.project.projectReport = responseProjectReport;

          this.closingReportPreview = responseClosingReport;
          this.closingReportPreview.closingReportId = responseClosingReport.id;

          setTimeout(() => {
            this.showClosingPreview = true;
          }, 100);

          this.spinner.hide();
  
          this.modalRef = this.modalService.show(this.lgClosingModal, this.config);
          this.modalService.onHidden.subscribe((reason: string) => {
            this.showClosingPreview = false;
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }
}
