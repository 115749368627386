import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectPhasesFinancialsRoutingModule } from './project-phases-financials-routing.module';
import { ProjectPhasesFinancialsComponent } from './project-phases-financials.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxDataGridModule } from 'devextreme-angular';


@NgModule({
  declarations: [ProjectPhasesFinancialsComponent],
  imports: [
    CommonModule,
    ProjectPhasesFinancialsRoutingModule,
    DxDataGridModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    NgxSpinnerModule
  ]
})
export class ProjectPhasesFinancialsModule { }
