import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApprovalsComponent } from './approvals.component';

const routes: Routes = [
  {
    path: '',
    children: [{
      path: '',
      component: ApprovalsComponent,
    }
    ],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApprovalsRoutingModule { }
