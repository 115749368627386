import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrgChartComponent } from 'app/components/org-chart/org-chart.component';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-small-order-org-chart',
  templateUrl: './small-order-org-chart.component.html',
  styleUrls: ['./small-order-org-chart.component.scss']
})
export class SmallOrderOrgChartComponent implements OnInit {
  projectOrganizationChartData = [];
  isChartLoaded: boolean = false;
  isOrderApproved: boolean = false;
  smallOrderId;
  @ViewChild("diagram", { static: false }) diagram: OrgChartComponent;

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, private apiService: ApiService, private utilityService: UtilityService, private localize: I18nPipe) { }

  ngOnInit() {
    let sub = this.route.params.subscribe(params => {
      this.smallOrderId = params.id;
      this.spinner.show();
      this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe((smallOrderRes: any) => {
        this.utilityService.breadcrumbList = ['My Orders', smallOrderRes.name, 'Org Chart'];

        this.projectOrganizationChartData = smallOrderRes.projectOrganizationChart ? smallOrderRes.projectOrganizationChart : [];
        this.isChartLoaded = true;
        this.spinner.hide();
      })
    });

  }

  saveChart() {
    if (this.smallOrderId != undefined) {
      this.spinner.show();
      this.apiService.put('/smallOrder/' + this.smallOrderId, {
        projectOrganizationChart: this.diagram.export()
      }).subscribe(response => {
        this.notificationBox('Saved', 'Chart has been saved!', 'success')
        this.spinner.hide();
      });
    }
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

}
