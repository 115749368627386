import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { DxPieChartModule, DxSliderModule, DxChartModule } from 'devextreme-angular';
import { ChartsModule } from 'ng2-charts';
import { PdfProjectStatusReportComponent } from 'app/projects/project-detail/pdf-project-status-report/pdf-project-status-report.component';
import { ShortNumberPipeModule } from 'app/shared/short-number-pipe/short-numberPipe.module';

@NgModule({
  imports: [
    CommonModule,
    CurrencyFormatterPipeModule,
    DxPieChartModule,
    DxSliderModule,
    DxChartModule,
    ChartsModule,
    I18nModule,
    ShortNumberPipeModule
  ],
  declarations: [
    PdfProjectStatusReportComponent
  ],
  exports: [
    PdfProjectStatusReportComponent
  ]
})

export class PDFProjectStatusReportModule { }