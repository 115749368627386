import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallOrderGanttRoutingModule } from './small-order-gantt-routing.module';
import { SmallOrderGanttComponent } from './small-order-gantt.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxAutocompleteModule, DxChartModule, DxCheckBoxModule, DxDataGridModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [SmallOrderGanttComponent],
  imports: [
    CommonModule,
    SmallOrderGanttRoutingModule,
    I18nModule,
    DxDataGridModule,
    DxCheckBoxModule,
    CurrencyFormatterPipeModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxChartModule,
    MaterialModule,
    DxAutocompleteModule,
    DragDropModule,
    DxScrollViewModule,

  ]
})
export class SmallOrderGanttModule { }
