import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { UtilityService } from 'app/services/utility.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { DxTreeViewComponent, DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import swal from 'sweetalert2';
declare var $: any;
var globalThis;
@Component({
  selector: 'app-portfolio-budget',
  templateUrl: './portfolio-budget.component.html',
  styleUrls: ['./portfolio-budget.component.scss']
})
export class PortfolioBudgetComponent implements OnInit, OnDestroy {
  budgetTitle: string;
  isPm: boolean = true;
  portfolioObj;
  subPortfolio: any = {};
  idx: number;
  yearsList = [];
  currentSelectedYear;
  budgetByYear: any = {
    totalBudget: 0,
    thereofITBudget: 0,
    thereofGEICTBudget: 0,
    externalIT: 0,
    opex: 0,
    capex: 0,
    opexCapexExternal: 0
  };
  isEditColumn: boolean = false;
  editedColumn: any;
  yearlyGridColumns = [];
  yearlyFixedColumns = [];
  additionalColumnsOption = [];
  additionalColumnsOptionBackup = [];
  columnInputField: string = '';
  budgetProjectsSearch: string = '';
  projectsCount: number = 0;
  subPortfolioBudgetingList = [];
  portfolioBudgetCurrentYear = [];
  portfolioBudgetNextYear = [];
  subPortfolioBudgetCurrentYear = [];
  subPortfolioBudgetNextYear = [];
  portfolioBudgetCurrentYearCheck: boolean = false;
  portfolioBudgetNextYearCheck: boolean = false;
  subPortfolioBudgetCurrentYearCheck: boolean = false;
  subPortfolioBudgetNextYearCheck: boolean = false;
  portfolioBudgetCurrentYearCheckEnabled: boolean = false;
  portfolioBudgetNextYearCheckEnabled: boolean = false;
  subPortfolioBudgetCurrentYearCheckEnabled: boolean = false;
  subPortfolioBudgetNextYearCheckEnabled: boolean = false;
  pspCurrentYear = '';
  pspNextYear = '';
  formObj: any = {};
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  portfolioList: Array<any> = new Array();
  subPortfolioList: Array<any> = new Array();
  itPlatformOptions: Array<any> = new Array();
  selectedYear: any = {};
  isSave: boolean = false;
  projectsSubject = new Subject<any>();
  stateStore;
  subportfolioId;
  statusOptions = [];
  startDateChangeFlag: boolean;
  minimumDate = '';
  documentLink;
  isEditsubPortfolioBucket: boolean = false;
  backupForm;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  @ViewChild('dataGridVar', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('dataGridVar1', { static: false }) dataGrid1: DxDataGridComponent;
  @ViewChild('dataGridVar2', { static: false }) dataGrid2: DxDataGridComponent;
  @ViewChild('budgetYearWidget', { static: false }) budgetYearWidget: DxSelectBoxComponent;
  treeViewObj;
  projectId;
  splitResult = { buckets: [], projects: [] };
  smallOrders = [];
  @ViewChild('lgMultiBudgetView', { static: false }) public lgMultiBudgetViewModal;
  showMultiBudgetView = false;
  multiYearlyBudgetList = [];
  multiYearlyBudgetYears = [];
  showVariableTickers: boolean = true;
  showFixedTickers: boolean = true;
  selectedBucket;
  selectedProject;
  selectedProjectType;
  currentProjectBucketAssignedItem;
  budgetHolder = [
    {
      costType: "External Costs",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 4,
      group: "Sonstiges",
    }, {
      costType: "Reserves",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      group: "Sonstiges",
      id: 5,
    }, {
      costType: "Total",
      budget: 0,
      thereofIT: 0,
      budgetBucket: 0,
      thereofITBucket: 0,
      budgetPicked: 0,
      thereofITPicked: 0,
      id: 6,
      group: "Sonstiges",
    },
  ];
  isBudgetApplied: boolean = false;
  budgetPopupType = '';
  projectsList = [];
  ordersList = [];
  selectedProjectIDForBudgetCreation;
  selectedOrderIDForBudgetCreation;

  constructor(public route: ActivatedRoute, private apiService: ApiService, private authService: AuthService,
    private router: Router, private localize: I18nPipe, private utilityService: UtilityService, private modalService: BsModalService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    globalThis = this;

    this.formObj.itPlatform = [];

    let sub = this.route.params.subscribe(params => {
      if (params.budgetType == 'Portfolio') {
        this.budgetTitle = "Portfolio Budget";
        this.utilityService.breadcrumb = "Portfolio Budget";
        // this.initPortfolioBudgetTables();
        this.fetchPortfolios(params.portfolioId);
      } else if (params.budgetType == 'Sub-Portfolio') {
        this.budgetTitle = "Sub-Portfolio Budget";
        this.utilityService.breadcrumb = "Sub-Portfolio Budget";
        this.subportfolioId = params.subPortfolio;

        this.fetchSubPortfolio(params.subPortfolio);
      }
    });

    this.stateStore = {
      enabled: true,
      storageKey: 'subPortfolioBudgetList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.calculateBudgetValue = this.calculateBudgetValue.bind(this);
    this.calculateThereofITValue = this.calculateThereofITValue.bind(this);
  }

  ngOnInit() {
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Budget'];
    this.statusOptions = ['Active', 'Inactive'];
    this.itPlatformOptions = this.utilityService.itPlatformOptions;

    if (this.authService.userObj.role == 'guest') {
      this.isPm = true;
    } else {
      this.isPm = false;
    }

    this.apiService.get('/getGlobalConfigurations').subscribe((response: any) => {
      if (response.yearlyBudgetColumns) {
        this.additionalColumnsOption = response.yearlyBudgetColumns;
        this.additionalColumnsOptionBackup = response.yearlyBudgetColumns.slice();
      }
    });
  }

  ngAfterViewInit() {
    //Fixed div collapse
    $("#collapseFixed").on('show.bs.collapse', function () {
      globalThis.showFixedTickers = true;
    });
    $("#collapseFixed").on('hide.bs.collapse', function () {
      globalThis.showFixedTickers = false;
    });

    //Variable div collapse
    $("#collapseVariable").on('show.bs.collapse', function () {
      globalThis.showVariableTickers = true;
    });
    $("#collapseVariable").on('hide.bs.collapse', function () {
      globalThis.showVariableTickers = false;
    });
  }

  assignedBucketChanged(e) {
    this.spinner.show();
    this.selectedBucket = e.value;

    let findPayload = { bucketId: this.selectedBucket.projectitem._id };
    if (this.selectedProjectType == 'order') {
      findPayload['orderId'] = this.selectedProject.order._id;
    } else {
      findPayload['projectId'] = this.selectedProject.projectitem._id;
    }

    this.apiService.post('/findAssignedProjectBucketBudget', findPayload).subscribe((response: any) => {
      if (response.length > 0) {
        this.currentProjectBucketAssignedItem = response[0];
        this.isBudgetApplied = true;
      } else {
        this.currentProjectBucketAssignedItem = undefined;
        this.isBudgetApplied = false;
      }

      for (let i = 0; i < this.budgetHolder.length; i++) {
        this.budgetHolder[i].budgetBucket = e.value.budget[i].budget;
        this.budgetHolder[i].thereofITBucket = e.value.budget[i].thereofIT;

        if (this.currentProjectBucketAssignedItem != undefined) {
          this.budgetHolder[i].budgetPicked = this.currentProjectBucketAssignedItem.budget[i].budget;
          this.budgetHolder[i].thereofITPicked = this.currentProjectBucketAssignedItem.budget[i].thereofIT;
        } else {
          this.budgetHolder[i].budgetPicked = 0;
          this.budgetHolder[i].thereofITPicked = 0;
        }
      }
      this.spinner.hide();
    });

  }

  calculateBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.budgetPicked ? (Math.abs(e.budgetPicked) * -1) : 0;
    } else if (e.costType == "Total") {
      this.budgetHolder[6].budgetPicked = this.projectCostsValueCalculator('budgetPicked', this.budgetHolder);
      return this.budgetHolder[6].budgetPicked;
    } else {
      return e.budgetPicked ? e.budgetPicked : 0;
    }
  }

  calculateThereofITValue(e) {
    if (e.costType == 'Revenues') {
      return e.thereofITPicked ? (Math.abs(e.thereofITPicked) * -1) : 0;
    } else if (e.costType == "Total") {
      this.budgetHolder[6].thereofITPicked = this.projectCostsValueCalculator('thereofITPicked', this.budgetHolder);
      return this.budgetHolder[6].thereofITPicked;
    } else {
      return e.thereofITPicked ? e.thereofITPicked : 0;
    }
  }

  //When Apply Button is Pressed
  subtractBucketBudget() {
    this.spinner.show();

    //IF existing budget has been emptied, delete the assignment
    if (this.currentProjectBucketAssignedItem != undefined && this.budgetHolder[6].budgetPicked == 0 && this.budgetHolder[6].thereofITPicked == 0) {
      this.apiService.delete('/projectBucketBudget/' + this.currentProjectBucketAssignedItem.id).subscribe(response => {
        let originalBudget;
        if (this.selectedProjectType == 'order') {
          originalBudget = this.smallOrders.find(order => order._id == this.selectedProject._id).budget;
        } else {
          originalBudget = this.splitResult.projects.find(proj => proj._id == this.selectedProject._id).budget;
        }

        let originalBucketBudget = this.splitResult.buckets.find(buck => buck._id == this.selectedBucket._id).budget;
        for (let i = 0; i < originalBudget.length; i++) {
          originalBudget[i].budget = this.budgetHolder[i].budget;
          originalBudget[i].thereofIT = this.budgetHolder[i].thereofIT;

          originalBucketBudget[i].budget = this.budgetHolder[i].budgetBucket;
          originalBucketBudget[i].thereofIT = this.budgetHolder[i].thereofITBucket;
        }
        this.saveSubPortfolio(true);
        this.closeBucketBudgetAssignmentModal();
      });
    } else {
      let budgetChunk = [
        {
          costType: "External Costs",
          budget: 0,
          thereofIT: 0,
          id: 0,
          group: "CAPEX",
        }, {
          costType: "Internal Costs",
          budget: 0,
          thereofIT: 0,
          id: 1,
          group: "CAPEX",
        }, {
          costType: "External Costs",
          budget: 0,
          thereofIT: 0,
          id: 2,
          group: "OPEX"
        }, {
          costType: "Internal Costs",
          budget: 0,
          thereofIT: 0,
          id: 3,
          group: "OPEX"
        }, {
          costType: "Revenues",
          budget: 0,
          thereofIT: 0,
          id: 4,
          group: "Sonstiges",
        }, {
          costType: "Reserves",
          budget: 0,
          thereofIT: 0,
          group: "Sonstiges",
          id: 5,
        }, {
          costType: "Total",
          budget: 0,
          thereofIT: 0,
          id: 6,
          group: "Sonstiges",
        },
      ];

      for (let i = 0; i < this.budgetHolder.length; i++) {
        //Subtract from Bucket
        this.budgetHolder[i].budgetBucket = this.budgetHolder[i].budgetBucket - this.budgetHolder[i].budgetPicked;
        this.budgetHolder[i].thereofITBucket = this.budgetHolder[i].thereofITBucket - this.budgetHolder[i].thereofITPicked;

        //Add into Projects Budget
        this.budgetHolder[i].budget = this.budgetHolder[i].budget + this.budgetHolder[i].budgetPicked;
        this.budgetHolder[i].thereofIT = this.budgetHolder[i].thereofIT + this.budgetHolder[i].thereofITPicked;

        //Putting Picked Budget into seperate container
        budgetChunk[i].budget = this.budgetHolder[i].budgetPicked;
        budgetChunk[i].thereofIT = this.budgetHolder[i].thereofITPicked;

        this.isBudgetApplied = true;
      }

      let originalBudget;

      let savePayload = { budget: budgetChunk, bucketId: this.selectedBucket.projectitem._id, portfolioBudgetYear: this.selectedYear.id };
      if (this.selectedProjectType == 'order') {
        savePayload['orderId'] = this.selectedProject.order._id;
        originalBudget = this.smallOrders.find(order => order._id == this.selectedProject._id).budget;
      } else {
        savePayload['projectId'] = this.selectedProject.projectitem._id;
        originalBudget = this.splitResult.projects.find(proj => proj._id == this.selectedProject._id).budget;
      }

      let originalBucketBudget = this.splitResult.buckets.find(buck => buck._id == this.selectedBucket._id).budget;

      for (let i = 0; i < originalBudget.length; i++) {
        originalBudget[i].budget = this.budgetHolder[i].budget;
        originalBudget[i].thereofIT = this.budgetHolder[i].thereofIT;

        originalBucketBudget[i].budget = this.budgetHolder[i].budgetBucket;
        originalBucketBudget[i].thereofIT = this.budgetHolder[i].thereofITBucket;
      }

      //IF Budget Assignment already exists, update it //else create it
      if (this.currentProjectBucketAssignedItem != undefined) {
        this.apiService.put('/projectBucketBudget/' + this.currentProjectBucketAssignedItem.id, savePayload).subscribe(response => {
          this.saveSubPortfolio(true);
          this.closeBucketBudgetAssignmentModal();
        });
      } else {
        this.apiService.post('/projectBucketBudget', savePayload).subscribe(response => {
          this.saveSubPortfolio(true);
          this.closeBucketBudgetAssignmentModal();
        });
      }
    }
  }

  //When Edit button is Pressed
  reverseSubtractBucketBudget() {
    for (let i = 0; i < this.budgetHolder.length; i++) {
      //Add back into Bucket 
      this.budgetHolder[i].budgetBucket = this.budgetHolder[i].budgetBucket + this.budgetHolder[i].budgetPicked;
      this.budgetHolder[i].thereofITBucket = this.budgetHolder[i].thereofITBucket + this.budgetHolder[i].thereofITPicked;

      //Subtract from Projects Budget
      this.budgetHolder[i].budget = this.budgetHolder[i].budget - this.budgetHolder[i].budgetPicked;
      this.budgetHolder[i].thereofIT = this.budgetHolder[i].thereofIT - this.budgetHolder[i].thereofITPicked;

      this.isBudgetApplied = false;
    }
  }

  loadAssignedProjectsNames(data) {
    let bucket = this.splitResult.buckets.find(buck => buck._id == data._id)
    this.apiService.get('/projectBudget/' + data.projectitem._id + '/names').subscribe(response => {
      bucket.assignments = response;
    })
  }

  onRowPrepared(e) {
    this.setRowIndex9(e);
  }

  onEditorPreparing(e) {
    this.editRowIndex9(e);
  }

  onCellPrepared(e) {
    let x = this.isBudgetApplied ? 2 : 0;
    if (e.columnIndex > 1 && e.columnIndex < 6 + x) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex != 9 && e.parentType == 'dataRow') {
      if (e.columnIndex > 3) {
        e.cellElement.style.backgroundColor = "#FFF";
      }
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  projectCostsValueCalculator(field, dataSource) {
    let calculationArray = [];
    for (let i = 0; i < 6; i++) {
      calculationArray[i] = dataSource[i][field] * 1;
    }

    let total = calculationArray[0] + calculationArray[1] + calculationArray[2] + calculationArray[3] - calculationArray[4] + calculationArray[5];
    return total;
  }

  deleteItem(item, formObj) {
    this.spinner.show();
    let index = this.yearlyGridColumns.findIndex(itemm => itemm.caption == item.caption);
    this.yearlyGridColumns.splice(index, 1);
    this.apiService.put('/portfolioBudgetYear/' + this.selectedYear.id, {
      additionalColumns: this.yearlyGridColumns
    }).subscribe((response) => {
      let projectsToUpdate = [];

      this.subPortfolio.projects.forEach(project => {
        for (let i = 0; i < 6; i++) {
          var key = item.dataField;
          delete project.budget[i][key];
        }

        projectsToUpdate.push({ id: project._id, budget: project.budget });
      })

      let ordersToUpdate = [];
      this.smallOrders.forEach(order => {
        for (let i = 0; i < 6; i++) {
          var key = item.dataField;
          delete order.budget[i][key];
        }
        ordersToUpdate.push({ id: order._id, budget: order.budget });
      })

      this.apiService.post('/updateMultipleProjectsBudget/', {
        projectsBudget: projectsToUpdate,
        ordersBudget: ordersToUpdate
      }).subscribe(updatedObj => {
        this.additionalColumnsOption.push(item.caption);
        this.refreshSubportfolioBudget();
        this.utilityService.showNotification(
          `${this.localize.transform("Delete Success")}`,
          `${this.localize.transform("Column has been Deleted.")}`,
          "success",
        );
      });
    });
  }

  editItem(item, formObj) {
    this.isEditColumn = true;
    this.columnInputField = item.caption;
    this.editedColumn = item;
  }

  resetInputFields() {
    this.isEditColumn = false;
    this.columnInputField = '';
  }

  onModalClose() {
    this.resetInputFields();
    this.modalRef.hide()
  }

  editSaveColumn() {
    var oldKey = this.editedColumn.dataField;

    let index = this.yearlyGridColumns.findIndex(itemm => itemm.caption == this.editedColumn.caption);
    this.yearlyGridColumns[index].caption = this.columnInputField;
    this.yearlyGridColumns[index].dataField = this.columnInputField;

    if (/\s/.test(this.columnInputField)) {
      this.yearlyGridColumns[index].dataField = this.columnInputField.replace(/\s/g, "_");
    }

    this.apiService.put('/portfolioBudgetYear/' + this.selectedYear.id, {
      additionalColumns: this.yearlyGridColumns
    }).subscribe((response) => {
      let projectsToUpdate = [];

      this.subPortfolio.projects.forEach(project => {
        for (let i = 0; i < 6; i++) {
          let temp = Object.assign({}, project.budget[i]);

          project.budget[i] = Object.assign({}, { [this.yearlyGridColumns[index].dataField]: project.budget[i][oldKey] }, temp);
          delete project.budget[i][oldKey];
        }
        projectsToUpdate.push({ id: project._id, budget: project.budget });
      });

      this.apiService.post('/updateMultipleProjectsBudget/', {
        projectsBudget: projectsToUpdate
      }).subscribe(updatedObj => {
        this.isEditColumn = false;
        this.columnInputField = '';
        this.modalRef.hide();
        this.utilityService.showNotification(
          `${this.localize.transform("Update Success")}`,
          `${this.localize.transform("Column has been Updated.")}`,
          "success",
        );
      });
    });
  }

  startDateValueChanged(e) {
    if (this.startDateChangeFlag == true) {
      this.minimumDate = moment(this.formObj.startDate).add('days', 1).format('MMM DD, YYYY');
      this.formObj.endDate = this.minimumDate;
    } else {
      this.startDateChangeFlag = true;
      if (this.formObj.id == undefined) {
        this.minimumDate = moment(this.formObj.startDate).add('days', 1).format('MMM DD, YYYY');
        this.formObj.endDate = this.minimumDate;
      }
    }
  }

  saveColumn(columnItem) {

    let isColumnExist = false;
    this.yearlyGridColumns.forEach(element => {
      if (element.caption == columnItem) {
        isColumnExist = true;
      }
    })

    if (isColumnExist) {
      this.utilityService.showNotification(
        this.localize.transform("Error"),
        this.localize.transform("Column with this name already Exists."),
        "danger",
      );
    } else {
      this.spinner.show();

      let columnKey = columnItem;
      if (/\s/.test(columnItem)) {
        columnKey = columnItem.replace(/\s/g, "_");
      }
      let projectsToUpdate = [];
      this.subPortfolio.projects.forEach(project => {
        for (let i = 0; i < 6; i++) {
          project.budget[i] = Object.assign({}, project.budget[i], { [columnKey]: '' });
        }
        projectsToUpdate.push({ id: project._id, budget: project.budget });
      })
      this.yearlyGridColumns.push({ dataField: columnKey, caption: columnItem });
      //Remove item from array
      let index = this.additionalColumnsOption.indexOf(columnItem);
      if (index > -1) {
        this.additionalColumnsOption.splice(index, 1);
      }

      let ordersToUpdate = [];
      this.smallOrders.forEach(order => {
        for (let i = 0; i < 6; i++) {
          order.budget[i] = Object.assign({}, order.budget[i], { [columnKey]: '' });
        }
        ordersToUpdate.push({ id: order._id, budget: order.budget });
      })

      // this.splitResult = this.splitSubportfolioProjects(this.subPortfolio.projects);
      this.apiService.put('/portfolioBudgetYear/' + this.selectedYear.id, {
        additionalColumns: this.yearlyGridColumns
      }).subscribe((response) => {
        this.apiService.post('/updateMultipleProjectsBudget/', {
          projectsBudget: projectsToUpdate,
          ordersBudget: ordersToUpdate
        }).subscribe(updatedObj => {
          this.dataGrid.instance.repaint();
          this.dataGrid1.instance.repaint();
          this.dataGrid2.instance.repaint();
          this.refreshSubportfolioBudget();
          this.modalRef.hide();
          this.utilityService.showNotification(
            `${this.localize.transform("Save Success")}`,
            `${this.localize.transform("New column has been added.")}`,
            "success",
          );
        })
      });
    }
    // }
  }


  //Calculate Total budget of projects (i.e OPEX, CAPEX, Budget) for a given year to display on top
  calculateTotalYearlyBudget() {
    this.budgetByYear = {
      totalBudget: 0,
      thereofITBudget: 0,
      thereofGEICTBudget: 0,
      externalIT: 0,
      opex: 0,
      capex: 0,
      opexCapexExternal: 0
    };

    this.subPortfolio.projects.forEach(project => {
      this.budgetByYear.totalBudget += parseInt(project.budget[6].budget || 0);
      this.budgetByYear.thereofITBudget += parseInt(project.budget[6].thereofIT || 0);
      this.budgetByYear.thereofGEICTBudget += parseInt(project.budget[6].davon_GE_ICT || 0);
      this.budgetByYear.externalIT += parseInt(project.budget[0].thereofIT || 0) + parseInt(project.budget[2].thereofIT || 0);
      this.budgetByYear.capex += parseInt(project.budget[0].budget || 0) + parseInt(project.budget[1].budget || 0);
      this.budgetByYear.opex += parseInt(project.budget[2].budget || 0) + parseInt(project.budget[3].budget || 0);
      this.budgetByYear.opexCapexExternal += parseInt(project.budget[0].budget || 0) + parseInt(project.budget[2].budget || 0);
    });

    this.smallOrders.forEach(order => {
      this.budgetByYear.totalBudget += parseInt(order.budget[6].budget || 0);
      this.budgetByYear.thereofITBudget += parseInt(order.budget[6].thereofIT || 0);
      this.budgetByYear.thereofGEICTBudget += parseInt(order.budget[6].davon_GE_ICT || 0);
      this.budgetByYear.externalIT += parseInt(order.budget[0].thereofIT || 0) + parseInt(order.budget[2].thereofIT || 0);
      this.budgetByYear.capex += parseInt(order.budget[0].budget || 0) + parseInt(order.budget[1].budget || 0);
      this.budgetByYear.opex += parseInt(order.budget[2].budget || 0) + parseInt(order.budget[3].budget || 0);
      this.budgetByYear.opexCapexExternal += parseInt(order.budget[0].budget || 0) + parseInt(order.budget[2].budget || 0);
    });
  }

  onYearChanged(event) {
    this.spinner.show();

    this.apiService.get(`/projectBudgetCost/year/${event.value.id}`).subscribe(budgetResponse => {
      this.apiService.get(`/orderBudgetCost/year/${event.value.id}`).subscribe((orderBudgetResponse: any) => {
        this.smallOrders = orderBudgetResponse;

        this.subPortfolio.projects = budgetResponse;
        this.selectedYear = event.value;
        this.yearlyGridColumns = this.selectedYear.additionalColumns ? this.selectedYear.additionalColumns : [];
        this.yearlyFixedColumns = this.selectedYear.fixedColumns ? this.selectedYear.fixedColumns : [];
        this.additionalColumnsOption = this.additionalColumnsOptionBackup.slice();

        this.yearlyGridColumns.forEach(column => {
          let indx = this.additionalColumnsOption.indexOf(column.caption)
          if (indx > -1) {
            this.additionalColumnsOption.splice(indx, 1);
          }
        })

        this.smallOrders.forEach(order => {
          for (let i = 0; i < this.yearlyGridColumns.length; i++) {
            if (!(order.budget[0].hasOwnProperty(this.yearlyGridColumns[i].dataField))) {
              order.budget[0][this.yearlyGridColumns[i].dataField] = '';
              order.budget[1][this.yearlyGridColumns[i].dataField] = '';
              order.budget[2][this.yearlyGridColumns[i].dataField] = '';
              order.budget[3][this.yearlyGridColumns[i].dataField] = '';
              order.budget[4][this.yearlyGridColumns[i].dataField] = '';
              order.budget[5][this.yearlyGridColumns[i].dataField] = '';
              order.budget[6][this.yearlyGridColumns[i].dataField] = '';
            }
          }
        });

        this.subPortfolio.projects.forEach(project => {
          for (let i = 0; i < this.yearlyGridColumns.length; i++) {
            if (!(project.budget[0].hasOwnProperty(this.yearlyGridColumns[i].dataField))) {
              project.budget[0][this.yearlyGridColumns[i].dataField] = '';
              project.budget[1][this.yearlyGridColumns[i].dataField] = '';
              project.budget[2][this.yearlyGridColumns[i].dataField] = '';
              project.budget[3][this.yearlyGridColumns[i].dataField] = '';
              project.budget[4][this.yearlyGridColumns[i].dataField] = '';
              project.budget[5][this.yearlyGridColumns[i].dataField] = '';
              project.budget[6][this.yearlyGridColumns[i].dataField] = '';
            }
          }
        });

        this.subPortfolio.projects.forEach(proj => {
          if (proj.report) {
            if (proj.report.itPlatform) {
              if (proj.report.itPlatform.length > 0) {
                let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }];

                proj.report.itPlatform.forEach((itPlatform, idx) => {
                  let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

                  if (itPlatformObj) {
                    itPlatforms[idx].id = itPlatformObj.id;
                    itPlatforms[idx].name = itPlatformObj.name;
                  }
                });
                proj.report.itPlatform = itPlatforms;
              }
            }
          } else {
            if (proj.projectitem.itPlatform) {
              if (proj.projectitem.itPlatform.length > 0) {
                proj.projectitem.itPlatformBackup = proj.projectitem.itPlatform;
                let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }];

                proj.projectitem.itPlatform.forEach((itPlatform, idx) => {
                  let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

                  if (itPlatformObj) {
                    itPlatforms[idx].id = itPlatformObj.id;
                    itPlatforms[idx].name = itPlatformObj.name;
                  }
                });
                proj.projectitem.itPlatform = itPlatforms;
              }
            }
          }
        });

        this.smallOrders.forEach(smallOrder => {
          if (smallOrder.order.itPlatform) {
            if (smallOrder.order.itPlatform.length > 0) {
              let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }];

              smallOrder.order.itPlatform.forEach((itPlatform, idx) => {
                let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);

                if (itPlatformObj) {
                  itPlatforms[idx].id = itPlatformObj.id;
                  itPlatforms[idx].name = itPlatformObj.name;
                }
              });
              smallOrder.order.itPlatform = itPlatforms;
            }
          }
        });

        this.calculateTotalYearlyBudget();
        this.splitResult = this.splitSubportfolioProjects(this.subPortfolio.projects);
        this.spinner.hide();
      });

    });
  }

  splitSubportfolioProjects(projectsArray) {
    let result = { buckets: [], projects: [] };

    result.buckets = projectsArray.filter(project => {
      return project.projectitem.mode == 'bucket';
    })

    result.projects = projectsArray.filter(project => {
      return !('mode' in project.projectitem) || project.projectitem.mode == 'project';
    })

    return result;
  }

  addNewBudgetYear() {
    let msg = 'Are you sure you want to add new year? Once year added cannot be deleted.';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let newYear = parseInt(this.yearsList[this.yearsList.length - 1].year) + 1;

        this.apiService.post('/portfolioBudgetYear', {
          year: newYear,
          subPortfolio: this.subPortfolio
        }).subscribe((portfolioBudgetYearResponse: any) => {
          this.yearsList.push(portfolioBudgetYearResponse);

          this.apiService.post('/createProjectBudgetCostbyYear', {
            subPortfolio: this.subPortfolio.id,
            portfolioBudgetYear: portfolioBudgetYearResponse.id,
            year: portfolioBudgetYearResponse.year
          }).subscribe(result => {
            this.utilityService.showNotification(
              `${this.localize.transform("Save Success")}`,
              `${this.localize.transform("New Budget Year has been added.")}`,
              "success",
            );
            this.spinner.hide();
          });
        });
      }
    });
  }

  fetchSubPortfolioBudgetYears(id) {
    this.apiService.get('/portfolioBudgetYear/' + id).subscribe((years: any) => {
      this.yearsList = years;
      let currentDateYear = moment().format('YYYY');
      this.currentSelectedYear = this.yearsList.find(val => val.year == currentDateYear);

      //selecting last available year in list in case current year has not been added yet
      if (this.currentSelectedYear == undefined) {
        this.currentSelectedYear = this.yearsList[this.yearsList.length - 1]
      }
    })
  }

  fetchSubPortfolio(id) {
    this.spinner.show();
    this.apiService.get(`/subPortfolio/${id}`).subscribe((response: any) => {
      this.selectedYear = response.costYears.find(val => val.id == response.currentYear);
      this.yearlyGridColumns = (this.selectedYear && this.selectedYear.additionalColumns) ? this.selectedYear.additionalColumns : [];

      this.yearlyGridColumns.forEach(column => {
        let indx = this.additionalColumnsOption.indexOf(column.caption)
        if (indx > -1) {
          this.additionalColumnsOption.splice(indx, 1);
        }
      })

      if (this.authService.userObj.role != 'admin' && this.authService.userObj.role != 'pmo' &&
        (response.subPortfolioManager && response.subPortfolioManager.email != this.authService.userObj.email)) {
        this.isPm = true;
      } else {
        this.isPm = false;
      }

      this.subPortfolio = response;
      this.subPortfolio.projects = []
      if (this.subPortfolio.documentLink) {
        this.documentLink = this.subPortfolio.documentLink;
      }

      this.fetchSubPortfolioBudgetYears(id);
    });
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  saveBucketProject() {
    if (this.formObj.projectName == '' || this.formObj.projectName == undefined) {
      this.showNotificationBox('Please Enter Bucket Name First')
      return;
    }
    if (this.formObj.purpose == '' || this.formObj.purpose == undefined) {
      this.showNotificationBox('Please Enter Purpose First')
      return;
    }
    if (this.formObj.itPlatform.length <= 0 /*this.formObj.itPlatform == undefined || this.formObj.itPlatform.id == ''*/) {
      this.showNotificationBox('Please Select IT Platform First')
      return;
    }
    if (this.formObj.startDate == '' || this.formObj.startDate == undefined) {
      this.showNotificationBox('Please Select Start Date First')
      return;
    }
    if (this.formObj.endDate == '' || this.formObj.endDate == undefined) {
      this.showNotificationBox('Please Select End Date First')
      return;
    }

    this.formObj.portfolio = this.subPortfolio.portfolio.id;
    this.formObj.subPortfolio = this.subPortfolio.id;
    this.formObj.mode = 'bucket';

    let budget = [
      {
        costType: "External Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 0,
        group: "CAPEX",
      }, {
        costType: "Internal Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 1,
        group: "CAPEX",
      }, {
        costType: "External Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 2,
        group: "OPEX"
      }, {
        costType: "Internal Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 3,
        group: "OPEX"
      }, {
        costType: "Revenues",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 4,
        group: "Sonstiges",
      }, {
        costType: "Reserves",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        group: "Sonstiges",
        id: 5,
      }, {
        costType: "Total",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 6,
        group: "Sonstiges",
      },
    ];

    if (this.yearlyGridColumns.length > 0) {
      for (let i = 0; i < this.yearlyGridColumns.length; i++) {
        for (let j = 0; j < 7; j++) {
          budget[j][this.yearlyGridColumns[i].dataField] = '';
        }
      }
    }

    if (this.yearlyFixedColumns.length > 0) {
      for (let i = 0; i < this.yearlyFixedColumns.length; i++) {
        for (let j = 0; j < 7; j++) {
          budget[j][this.yearlyFixedColumns[i].dataField] = '';
        }
      }
    }

    this.spinner.show();

    this.apiService.post(`/projects`, {
      ...this.formObj,
    }).subscribe((response: any) => {
      this.apiService.post(`/projectBudgetCost`, {
        portfolioBudgetYear: this.selectedYear.id,
        project: response.id,
        budget: budget
      }).subscribe((budgetResponse: any) => {
        this.apiService.get(`/projectBudgetCost/` + budgetResponse.id).subscribe((budget: any) => {
          let budgetObj = Object.assign({}, budget);
          let budgetProject = Object.assign({}, budget.project);
          budget.project = Object.assign({}, budgetProject, { _id: budgetProject.id })
          budget = Object.assign({}, { projectitem: budget.project }, { _id: budget.id }, budgetObj);

          delete budget.project;

          if (budget.projectitem.itPlatform) {
            if (budget.projectitem.itPlatform.length > 0) {
              budget.projectitem.itPlatformBackup = budget.projectitem.itPlatform;
              let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }]
              budget.projectitem.itPlatform.forEach((itPlatform, idx) => {
                let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);
                itPlatforms[idx].id = itPlatformObj.id;
                itPlatforms[idx].name = itPlatformObj.name;
              });
              budget.projectitem.itPlatform = itPlatforms;
            }
          }

          this.subPortfolio.projects.push(budget);
          this.splitResult = this.splitSubportfolioProjects(this.subPortfolio.projects);

          this.modalRef.hide();
          this.formObj = {};
          this.formObj.itPlatform = [];
          this.projectId = {};
          this.formObj.startDate = null;
          this.formObj.endDate = null;
          this.utilityService.showNotification(this.localize.transform('Success'), this.localize.transform('Bucket created successfully.'), 'success');
          this.spinner.hide();
        });
      });
    });
  }

  editBucket() {
    if (this.formObj.projectName == '' || this.formObj.projectName == undefined) {
      this.showNotificationBox('Please Enter Bucket Name First')
      return;
    }
    if (this.formObj.purpose == '' || this.formObj.purpose == undefined) {
      this.showNotificationBox('Please Enter Purpose First')
      return;
    }
    if (this.formObj.itPlatform.length <= 0 /*== undefined || this.formObj.itPlatform.id == ''*/) {
      this.showNotificationBox('Please Select IT Platform First')
      return;
    }
    if (this.formObj.startDate == '' || this.formObj.startDate == undefined) {
      this.showNotificationBox('Please Select Start Date First')
      return;
    }
    if (this.formObj.endDate == '' || this.formObj.endDate == undefined) {
      this.showNotificationBox('Please Select End Date First')
      return;
    }

    this.spinner.show();

    this.apiService.put('/projects/' + this.formObj._id, {
      projectName: this.formObj.projectName,
      psp: this.formObj.psp,
      purpose: this.formObj.purpose,
      itPlatform: this.formObj.itPlatform,
      startDate: this.formObj.startDate,
      endDate: this.formObj.endDate,
      status: this.formObj.status,
      isIdea: this.formObj.isIdea,
    }).subscribe((response: any) => {
      let project = this.subPortfolio.projects.find(val => val._id == this.projectId);

      if (project.projectitem.itPlatform) {
        if (project.projectitem.itPlatform.length > 0) {
          project.projectitem.itPlatformBackup = project.projectitem.itPlatform;
          let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }]
          project.projectitem.itPlatform.forEach((itPlatform, idx) => {
            let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);
            itPlatforms[idx].id = itPlatformObj.id;
            itPlatforms[idx].name = itPlatformObj.name;
          });
          project.projectitem.itPlatform = itPlatforms;
        }
      }

      this.modalRef.hide();
      this.formObj = {};
      this.formObj.itPlatform = [];
      this.projectId = {};
      this.formObj.startDate = null;
      this.formObj.endDate = null;
      this.isEditsubPortfolioBucket = false;
      this.utilityService.showNotification(this.localize.transform('Success'), this.localize.transform('Bucket updated successfully.'), 'success');
      this.spinner.hide();
    });
  }

  saveSubPortfolio(isRefresh) {
    if (this.subPortfolio.projects.length > 0) {
      if (this.documentLink && this.documentLink != '') {
        if (!this.isWebURL(this.documentLink)) {
          this.utilityService.showNotification(
            `${this.localize.transform("Warning")}`,
            `${this.localize.transform("Please enter a valid link for Documents.")}`,
            "warning",
          );
          return;
        }
        this.documentLink = this.isWebViewUrl(this.documentLink) ? this.documentLink : `http://${this.documentLink}`;
      }
      this.isSave = true;
      let projectsToUpdate = [];
      let ordersToUpdate = []

      this.subPortfolio.projects.forEach((project, Idx) => {
        projectsToUpdate.push({ id: project._id, budget: project.budget })
      });

      this.smallOrders.forEach((order, Idx) => {
        ordersToUpdate.push({ id: order._id, budget: order.budget })
      });

      this.spinner.show();

      this.apiService.post('/updateMultipleProjectsBudget/', {
        projectsBudget: projectsToUpdate,
        documentLink: this.documentLink,
        subPortfolioId: this.subPortfolio.id,
        ordersBudget: ordersToUpdate
      }).subscribe(updatedObj => {
        this.isSave = false;
        this.calculateTotalYearlyBudget();
        if (isRefresh) {
          this.refreshSubportfolioBudget();
        } else {
          this.spinner.hide();
        }
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Sub Portfolio Budget has been saved.")}`,
          "success",
        );
      });
    } else {
      this.utilityService.showNotification(
        `${this.localize.transform("Failed")}`,
        `${this.localize.transform("No Project to Save.")}`,
        "danger",
      );
    }
  }

  itPlatformValueChanged(e) {
    this.formObj.itPlatform = e.selectedItem;
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.formObj.itPlatform) {
      this.formObj.itPlatform.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.showNotificationBox('It Platform can select maximum no of 3.');
    } else {
      this.formObj.itPlatform = this.getSelectedItemsKeys(nodes);
    }
  }


  fetchPortfolios(portfolioId) {
    this.apiService.get('/portfolio/' + portfolioId).subscribe(portfolioData => {
      this.portfolioObj = portfolioData;
      this.portfolioList.push(this.portfolioObj);
      this.spinner.hide();

      if (this.budgetTitle == "Portfolio Budget") {
        if (this.portfolioObj.portfolioBudgetingList != undefined) {
          this.portfolioBudgetCurrentYear = this.portfolioObj.portfolioBudgetingList.portfolioBudgetCurrentYear;
          this.portfolioBudgetNextYear = this.portfolioObj.portfolioBudgetingList.portfolioBudgetNextYear;
          this.portfolioBudgetCurrentYearCheck = this.portfolioObj.portfolioBudgetingList.CurrentYearCheck;
          this.portfolioBudgetNextYearCheck = this.portfolioObj.portfolioBudgetingList.NextYearCheck;
        }

        if (this.portfolioObj.subPortfolioBudgetingList != undefined) {
          //this.calculatePortfolioBudget();
        }

      } else if (this.budgetTitle == "Sub-Portfolio Budget") {
        if (this.portfolioObj.subPortfolioBudgetingList != undefined) {
          let obj = this.portfolioObj.subPortfolioBudgetingList.find(val => val.subPortfolio == this.subPortfolio);
          this.idx = this.portfolioObj.subPortfolioBudgetingList.indexOf(obj);

          if (this.idx >= 0) {
            this.subPortfolioBudgetCurrentYear = this.portfolioObj.subPortfolioBudgetingList[this.idx].subPortfolioBudgetCurrentYear;
            if (this.portfolioObj.subPortfolioBudgetingList[this.idx].subPortfolioBudgetNextYear) {
              this.subPortfolioBudgetNextYear = this.portfolioObj.subPortfolioBudgetingList[this.idx].subPortfolioBudgetNextYear;
            }
            this.subPortfolioBudgetCurrentYearCheck = this.portfolioObj.subPortfolioBudgetingList[this.idx].CurrentYearCheck;
            this.subPortfolioBudgetNextYearCheck = this.portfolioObj.subPortfolioBudgetingList[this.idx].NextYearCheck;
            this.pspCurrentYear = this.portfolioObj.subPortfolioBudgetingList[this.idx].pspCurrentYear;
            this.pspNextYear = this.portfolioObj.subPortfolioBudgetingList[this.idx].pspNextYear;
          }
        }
      }
      this.spinner.hide();
    });
  }

  openMultiBudgetView() {
    this.spinner.show();
    this.showMultiBudgetView = false;

    setTimeout(() => {
      this.showMultiBudgetView = true;
    }, 100);

    this.apiService.get(`/portfolioBudgetYear/subportfolio/${this.subportfolioId}/yearlyBudget`).subscribe((response: any) => {
      this.multiYearlyBudgetList = response;
      if (response.length > 0) {
        this.multiYearlyBudgetYears = response[0].years;
      }

      this.lgMultiBudgetViewModal.show();
      this.lgMultiBudgetViewModal.onHidden.subscribe((reason: string) => {
        this.showMultiBudgetView = false;
      });
      this.spinner.hide();
    });
  }

  onKeyUpPurpose(e) {
    this.formObj.purpose = e.event.target.value;
  }

  // this.socketService.listen('projectBudgetByYearCount').subscribe(count => {
  //   this.projectsCount = count;
  // });

  // this.socketService.listen('projectBudgetByYearIndex').subscribe(budgetResponse => {
  //   this.subPortfolio.projects = budgetResponse;
  // });

  // this.socketService.listen('projectBudgetByYearSearch').subscribe(response => {
  //   this.projectsCount = response.count;
  //   this.subPortfolio.projects = response.projects;
  // });

  // this.socketService.listen('projectBudgetByYearSearchIndex').subscribe(projects => {
  //   this.subPortfolio.projects = projects;
  // });

  // this.projectsSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
  //   if (value != undefined) {
  //     this.socketService.emitEvent('projectBudgetByYearSearch', { id: this.selectedYear.id, search: value });
  //   }
  //   return [];
  // })).subscribe((response: any) => { }, error => {
  // });



  // initPortfolioBudgetTables() {
  //   this.portfolioBudgetCurrentYear = [{
  //     costType: "External Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 0,
  //     group: "CAPEX",
  //   }, {
  //     costType: "Internal Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 1,
  //     group: "CAPEX",
  //   }, {
  //     costType: "External Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 2,
  //     group: "OPEX"
  //   }, {
  //     costType: "Internal Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 3,
  //     group: "OPEX"
  //   }, {
  //     costType: "Revenues",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 4,
  //     group: "OTHERS",
  //   }, {
  //     costType: "Reserves",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     group: "OTHERS",
  //     id: 5,
  //   }, {
  //     costType: "Total",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 6,
  //     group: "OTHERS",
  //   },];

  //   this.portfolioBudgetNextYear = [{
  //     costType: "External Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 0,
  //     group: "CAPEX",
  //   }, {
  //     costType: "Internal Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 1,
  //     group: "CAPEX",
  //   }, {
  //     costType: "External Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 2,
  //     group: "OPEX"
  //   }, {
  //     costType: "Internal Costs",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 3,
  //     group: "OPEX"
  //   }, {
  //     costType: "Revenues",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 4,
  //     group: "OTHERS",
  //   }, {
  //     costType: "Reserves",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     group: "OTHERS",
  //     id: 5,
  //   }, {
  //     costType: "Total",
  //     budget: "",
  //     assigned: "",
  //     remaining: "",
  //     remainingPercent: "",
  //     actualCost: "",
  //     forecast: "",
  //     id: 6,
  //     group: "OTHERS",
  //   },];
  // }


  // calculatePortfolioBudget() {
  //   if (this.portfolioObj.subPortfolioBudgetingList.length > 0) {
  //     for (let i = 0; i < this.portfolioObj.subPortfolioBudgetingList.length; i++) {
  //       // Current Year
  //       // Calculate Assigned Value
  //       this.portfolioBudgetCurrentYear[0].assigned = +this.portfolioBudgetCurrentYear[0].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[0].assigned;

  //       this.portfolioBudgetCurrentYear[1].assigned = +this.portfolioBudgetCurrentYear[1].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[1].assigned;

  //       this.portfolioBudgetCurrentYear[2].assigned = +this.portfolioBudgetCurrentYear[2].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[2].assigned;

  //       this.portfolioBudgetCurrentYear[3].assigned = +this.portfolioBudgetCurrentYear[3].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[3].assigned;

  //       this.portfolioBudgetCurrentYear[4].assigned = +this.portfolioBudgetCurrentYear[4].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[4].assigned;

  //       this.portfolioBudgetCurrentYear[5].assigned = +this.portfolioBudgetCurrentYear[5].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[5].assigned;

  //       // Calculate Actual Cost Value
  //       this.portfolioBudgetCurrentYear[0].actualCost = +this.portfolioBudgetCurrentYear[0].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[0].actualCost;

  //       this.portfolioBudgetCurrentYear[1].actualCost = +this.portfolioBudgetCurrentYear[1].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[1].actualCost;

  //       this.portfolioBudgetCurrentYear[2].actualCost = +this.portfolioBudgetCurrentYear[2].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[2].actualCost;

  //       this.portfolioBudgetCurrentYear[3].actualCost = +this.portfolioBudgetCurrentYear[3].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[3].actualCost;

  //       this.portfolioBudgetCurrentYear[4].actualCost = +this.portfolioBudgetCurrentYear[4].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[4].actualCost;

  //       this.portfolioBudgetCurrentYear[5].actualCost = +this.portfolioBudgetCurrentYear[5].actualCost +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[5].actualCost;

  //       // Calculate Forecast Value
  //       this.portfolioBudgetCurrentYear[0].forecast = +this.portfolioBudgetCurrentYear[0].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[0].forecast;

  //       this.portfolioBudgetCurrentYear[1].forecast = +this.portfolioBudgetCurrentYear[1].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[1].forecast;

  //       this.portfolioBudgetCurrentYear[2].forecast = +this.portfolioBudgetCurrentYear[2].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[2].forecast;

  //       this.portfolioBudgetCurrentYear[3].forecast = +this.portfolioBudgetCurrentYear[3].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[3].forecast;

  //       this.portfolioBudgetCurrentYear[4].forecast = +this.portfolioBudgetCurrentYear[4].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[4].forecast;

  //       this.portfolioBudgetCurrentYear[5].forecast = +this.portfolioBudgetCurrentYear[5].forecast +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetCurrentYear[5].forecast;


  //       // Next Year
  //       // Calculate Assigned Value
  //       this.portfolioBudgetNextYear[0].assigned = +this.portfolioBudgetNextYear[0].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[0].assigned;

  //       this.portfolioBudgetNextYear[1].assigned = +this.portfolioBudgetNextYear[1].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[1].assigned;

  //       this.portfolioBudgetNextYear[2].assigned = +this.portfolioBudgetNextYear[2].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[2].assigned;

  //       this.portfolioBudgetNextYear[3].assigned = +this.portfolioBudgetNextYear[3].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[3].assigned;

  //       this.portfolioBudgetNextYear[4].assigned = +this.portfolioBudgetNextYear[4].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[4].assigned;

  //       this.portfolioBudgetNextYear[5].assigned = +this.portfolioBudgetNextYear[5].assigned +
  //         +this.portfolioObj.subPortfolioBudgetingList[i].subPortfolioBudgetNextYear[5].assigned;

  //     }
  //   }
  // }

  // portfolioBudgetCurrentYearCheckChange(e) {
  //   if (this.portfolioBudgetCurrentYearCheck == true) {
  //     this.portfolioBudgetCurrentYearCheckEnabled = true;
  //   }
  //   else if (this.portfolioBudgetCurrentYearCheck == false) {
  //     this.portfolioBudgetCurrentYearCheckEnabled = false;
  //   }
  // }
  // portfolioBudgetNextYearCheckChange(e) {
  //   if (this.portfolioBudgetNextYearCheck == true) {
  //     this.portfolioBudgetNextYearCheckEnabled = true;
  //   }
  //   else if (this.portfolioBudgetNextYearCheck == false) {
  //     this.portfolioBudgetNextYearCheckEnabled = false;
  //   }
  // }

  // Porfolio Current Year
  // onRowPreparedPortfolioBudgetCurrentYear(e) {
  //   this.setRowIndex9(e);
  // }

  // onCellPreparedPortfolioBudgetCurrentYear(e) {
  //   if (this.portfolioBudgetCurrentYearCheckEnabled == false) {
  //     if (e.columnIndex > 1 && e.columnIndex < 3) {
  //       e.cellElement.style.backgroundColor = "#ddd";
  //       e.cellElement.style.borderBottom = "1px solid #eee";
  //       e.cellElement.style.borderTop = "1px solid #eee";
  //       e.cellElement.style.borderRight = "1px solid #eee";
  //     }
  //   } else {
  //     if (e.rowIndex != 9 && e.parentType == 'dataRow') {
  //       if (e.columnIndex > 1 && e.columnIndex < 3) {
  //         e.cellElement.style.backgroundColor = "#FFF";
  //       }
  //     }

  //     if (e.rowIndex == 9) {
  //       e.cellElement.style.borderBottom = "1px solid #eee";
  //       e.cellElement.style.borderTop = "1px solid #eee";
  //       e.cellElement.style.borderRight = "1px solid #eee";
  //     }
  //   }

  //   if (e.columnIndex > 2) {
  //     e.cellElement.style.backgroundColor = "#ddd";
  //     e.cellElement.style.borderBottom = "1px solid #eee";
  //     e.cellElement.style.borderTop = "1px solid #eee";
  //     e.cellElement.style.borderRight = "1px solid #eee";
  //   }
  // }

  // onEditorPreparingPortfolioBudgetCurrentYear(e) {
  //   this.editRowIndex9(e);
  // }

  // calculatePortfolioCurrentYearBudgetValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.budget ? (Math.abs(e.budget) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetCurrentYear[6].budget = globalThis.portfolioCostsValueCalculator('budget', 'Current');
  //     return globalThis.portfolioBudgetCurrentYear[6].budget;
  //   } else {
  //     globalThis.calculateRemainingPortfolioBudgetCurrentYear();
  //     return e.budget ? e.budget : '';
  //   }
  // }

  // calculatePortfolioCurrentYearAssignedValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.assigned ? (Math.abs(e.assigned) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetCurrentYear[6].assigned = globalThis.portfolioCostsValueCalculator('assigned', 'Current');
  //     return globalThis.portfolioBudgetCurrentYear[6].assigned;
  //   }
  // }

  //calculatePortfolioCurrentYearRemainingValue(e) {
  // if (e.costType == 'Revenues') {
  //   return e.remaining ? (Math.abs(e.remaining) * -1) : '';
  // } else if (e.costType == "Total") {
  //   globalThis.portfolioBudgetCurrentYear[6].remaining = globalThis.portfolioCostsValueCalculator('remaining', 'Current');
  //   return globalThis.portfolioBudgetCurrentYear[6].remaining;
  // }
  //}

  //calculatePortfolioCurrentYearRemainingPercentValue(e) {
  // if (e.costType == 'Revenues') {
  //   return e.remainingPercent ? (Math.abs(e.remainingPercent) * -1) : '';
  // } else if (e.costType == "Total") {
  //   globalThis.portfolioBudgetCurrentYear[6].remainingPercent = globalThis.portfolioCostsValueCalculator('remainingPercent', 'Current');
  //   return globalThis.portfolioBudgetCurrentYear[6].remainingPercent;
  // }
  //}

  // calculatePortfolioCurrentYearActualCostValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetCurrentYear[6].actualCost = globalThis.portfolioCostsValueCalculator('actualCost', 'Current');
  //     return globalThis.portfolioBudgetCurrentYear[6].actualCost;
  //   }
  // }

  // calculatePortfolioCurrentYearForecastValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.forecast ? (Math.abs(e.forecast) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetCurrentYear[6].forecast = globalThis.portfolioCostsValueCalculator('forecast', 'Current');
  //     return globalThis.portfolioBudgetCurrentYear[6].forecast;
  //   }
  // }

  // calculateRemainingPortfolioBudgetCurrentYear() {
  //   if (+this.portfolioBudgetCurrentYear[0].budget > 0) {
  //     this.portfolioBudgetCurrentYear[0].remaining = +this.portfolioBudgetCurrentYear[0].budget
  //       - +this.portfolioBudgetCurrentYear[0].assigned;

  //     this.portfolioBudgetCurrentYear[0].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[0].remaining * 100) / +this.portfolioBudgetCurrentYear[0].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[1].budget > 0) {
  //     this.portfolioBudgetCurrentYear[1].remaining = +this.portfolioBudgetCurrentYear[1].budget
  //       - +this.portfolioBudgetCurrentYear[1].assigned;

  //     this.portfolioBudgetCurrentYear[1].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[1].remaining * 100) / +this.portfolioBudgetCurrentYear[1].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[2].budget > 0) {
  //     this.portfolioBudgetCurrentYear[2].remaining = +this.portfolioBudgetCurrentYear[2].budget
  //       - +this.portfolioBudgetCurrentYear[2].assigned;

  //     this.portfolioBudgetCurrentYear[2].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[2].remaining * 100) / +this.portfolioBudgetCurrentYear[2].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[3].budget > 0) {
  //     this.portfolioBudgetCurrentYear[3].remaining = +this.portfolioBudgetCurrentYear[3].budget
  //       - +this.portfolioBudgetCurrentYear[3].assigned;

  //     this.portfolioBudgetCurrentYear[3].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[3].remaining * 100) / +this.portfolioBudgetCurrentYear[3].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[4].budget > 0) {
  //     this.portfolioBudgetCurrentYear[4].remaining = +this.portfolioBudgetCurrentYear[4].budget
  //       - +this.portfolioBudgetCurrentYear[4].assigned;

  //     this.portfolioBudgetCurrentYear[4].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[4].remaining * 100) / +this.portfolioBudgetCurrentYear[4].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[5].budget > 0) {
  //     this.portfolioBudgetCurrentYear[5].remaining = +this.portfolioBudgetCurrentYear[5].budget
  //       - +this.portfolioBudgetCurrentYear[5].assigned;

  //     this.portfolioBudgetCurrentYear[5].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[5].remaining * 100) / +this.portfolioBudgetCurrentYear[5].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetCurrentYear[6].budget > 0) {
  //     this.portfolioBudgetCurrentYear[6].remaining = +this.portfolioBudgetCurrentYear[6].budget
  //       - +this.portfolioBudgetCurrentYear[6].assigned;

  //     this.portfolioBudgetCurrentYear[6].remainingPercent =
  //       (+(this.portfolioBudgetCurrentYear[6].remaining * 100) / +this.portfolioBudgetCurrentYear[6].budget).toFixed(2);
  //   }
  // }

  // Porfolio Next Year
  // onRowPreparedPortfolioBudgetNextYear(e) {
  //   this.setRowIndex9(e);
  // }

  // onCellPreparedPortfolioBudgetNextYear(e) {
  //   if (this.portfolioBudgetNextYearCheckEnabled == false) {
  //     if (e.columnIndex > 1 && e.columnIndex < 3) {
  //       e.cellElement.style.backgroundColor = "#ddd";
  //       e.cellElement.style.borderBottom = "1px solid #eee";
  //       e.cellElement.style.borderTop = "1px solid #eee";
  //       e.cellElement.style.borderRight = "1px solid #eee";
  //     }
  //   }
  //   else {
  //     if (e.rowIndex != 9 && e.parentType == 'dataRow') {
  //       if (e.columnIndex > 1 && e.columnIndex < 3) {
  //         e.cellElement.style.backgroundColor = "#FFF";
  //       }
  //     }

  //     if (e.rowIndex == 9) {
  //       e.cellElement.style.borderBottom = "1px solid #eee";
  //       e.cellElement.style.borderTop = "1px solid #eee";
  //       e.cellElement.style.borderRight = "1px solid #eee";
  //     }
  //   }

  //   if (e.columnIndex > 2) {
  //     e.cellElement.style.backgroundColor = "#ddd";
  //     e.cellElement.style.borderBottom = "1px solid #eee";
  //     e.cellElement.style.borderTop = "1px solid #eee";
  //     e.cellElement.style.borderRight = "1px solid #eee";
  //   }
  // }

  // onEditorPreparingPortfolioBudgetNextYear(e) {
  //   this.editRowIndex9(e);
  // }

  // calculatePortfolioNextYearBudgetValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.budget ? (Math.abs(e.budget) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetNextYear[6].budget = globalThis.portfolioCostsValueCalculator('budget', 'Next');
  //     return globalThis.portfolioBudgetNextYear[6].budget;
  //   } else {
  //     globalThis.calculateRemainingPortfolioBudgetNextYear();
  //     return e.budget ? e.budget : '';
  //   }
  // }

  // calculatePortfolioNextYearAssignedValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.assigned ? (Math.abs(e.assigned) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetNextYear[6].assigned = globalThis.portfolioCostsValueCalculator('assigned', 'Next');
  //     return globalThis.portfolioBudgetNextYear[6].assigned;
  //   }
  // }

  // calculatePortfolioNextYearRemainingValue(e) {
  // if (e.costType == 'Revenues') {
  //   return e.remaining ? (Math.abs(e.remaining) * -1) : '';
  // } else if (e.costType == "Total") {
  //   globalThis.portfolioBudgetNextYear[6].remaining = globalThis.portfolioCostsValueCalculator('remaining', 'Next');
  //   return globalThis.portfolioBudgetNextYear[6].remaining;
  // }
  //}

  // calculatePortfolioNextYearRemainingPercentValue(e) {
  // if (e.costType == 'Revenues') {
  //   return e.remainingPercent ? (Math.abs(e.remainingPercent) * -1) : '';
  // } else if (e.costType == "Total") {
  //   globalThis.portfolioBudgetNextYear[6].remainingPercent = globalThis.portfolioCostsValueCalculator('remainingPercent', 'Next');
  //   return globalThis.portfolioBudgetNextYear[6].remainingPercent;
  // }
  //}

  // calculatePortfolioNextYearActualCostValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetNextYear[6].actualCost = globalThis.portfolioCostsValueCalculator('actualCost', 'Next');
  //     return globalThis.portfolioBudgetNextYear[6].actualCost;
  //   }
  // }

  // calculatePortfolioNextYearForecastValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.forecast ? (Math.abs(e.forecast) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     globalThis.portfolioBudgetNextYear[6].forecast = globalThis.portfolioCostsValueCalculator('forecast', 'Next');
  //     return globalThis.portfolioBudgetNextYear[6].forecast;
  //   }
  // }

  // calculateRemainingPortfolioBudgetNextYear() {
  //   if (+this.portfolioBudgetNextYear[0].budget > 0) {
  //     this.portfolioBudgetNextYear[0].remaining = +this.portfolioBudgetNextYear[0].budget
  //       - +this.portfolioBudgetNextYear[0].assigned;

  //     this.portfolioBudgetNextYear[0].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[0].remaining * 100) / +this.portfolioBudgetNextYear[0].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetNextYear[1].budget > 0) {
  //     this.portfolioBudgetNextYear[1].remaining = +this.portfolioBudgetNextYear[1].budget
  //       - +this.portfolioBudgetNextYear[1].assigned;

  //     this.portfolioBudgetNextYear[1].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[1].remaining * 100) / +this.portfolioBudgetNextYear[1].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetNextYear[2].budget > 0) {
  //     this.portfolioBudgetNextYear[2].remaining = +this.portfolioBudgetNextYear[2].budget
  //       - +this.portfolioBudgetNextYear[2].assigned;

  //     this.portfolioBudgetNextYear[2].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[2].remaining * 100) / +this.portfolioBudgetNextYear[2].budget).toFixed(2);
  // }

  //   if (+this.portfolioBudgetNextYear[3].budget > 0) {
  //     this.portfolioBudgetNextYear[3].remaining = +this.portfolioBudgetNextYear[3].budget
  //       - +this.portfolioBudgetNextYear[3].assigned;

  //     this.portfolioBudgetNextYear[3].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[3].remaining * 100) / +this.portfolioBudgetNextYear[3].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetNextYear[4].budget > 0) {
  //     this.portfolioBudgetNextYear[4].remaining = +this.portfolioBudgetNextYear[4].budget
  //       - +this.portfolioBudgetNextYear[4].assigned;

  //     this.portfolioBudgetNextYear[4].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[4].remaining * 100) / +this.portfolioBudgetNextYear[4].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetNextYear[5].budget > 0) {
  //     this.portfolioBudgetNextYear[5].remaining = +this.portfolioBudgetNextYear[5].budget
  //       - +this.portfolioBudgetNextYear[5].assigned;

  //     this.portfolioBudgetNextYear[5].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[5].remaining * 100) / +this.portfolioBudgetNextYear[5].budget).toFixed(2);
  //   }

  //   if (+this.portfolioBudgetNextYear[6].budget > 0) {
  //     this.portfolioBudgetNextYear[6].remaining = +this.portfolioBudgetNextYear[6].budget
  //       - +this.portfolioBudgetNextYear[6].assigned;

  //     this.portfolioBudgetNextYear[6].remainingPercent =
  //       (+(this.portfolioBudgetNextYear[6].remaining * 100) / +this.portfolioBudgetNextYear[6].budget).toFixed(2);
  // }
  // }

  // portfolioCostsValueCalculator(field, tableType) {
  //   if (tableType == 'Current') {
  //     let one: any = globalThis.portfolioBudgetCurrentYear[0][field] * 1;
  //     let two: any = globalThis.portfolioBudgetCurrentYear[1][field] * 1;
  //     let three: any = globalThis.portfolioBudgetCurrentYear[2][field] * 1;
  //     let four: any = globalThis.portfolioBudgetCurrentYear[3][field] * 1;
  //     let five: any = globalThis.portfolioBudgetCurrentYear[4][field] * 1;
  //     let six: any = globalThis.portfolioBudgetCurrentYear[5][field] * 1;
  //     let total = one + two + three + four - five + six;
  //     return total;
  //   }
  //   else if (tableType == 'Next') {
  //     let one: any = globalThis.portfolioBudgetNextYear[0][field] * 1;
  //     let two: any = globalThis.portfolioBudgetNextYear[1][field] * 1;
  //     let three: any = globalThis.portfolioBudgetNextYear[2][field] * 1;
  //     let four: any = globalThis.portfolioBudgetNextYear[3][field] * 1;
  //     let five: any = globalThis.portfolioBudgetNextYear[4][field] * 1;
  //     let six: any = globalThis.portfolioBudgetNextYear[5][field] * 1;
  //     let total = one + two + three + four - five + six;
  //     return total;
  //   }
  // }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  savePortfolio() {
    if (this.portfolioObj.portfolioBudgetingList == undefined) {
      let obj = {
        portfolioBudgetCurrentYear: this.portfolioBudgetCurrentYear,
        portfolioBudgetNextYear: this.portfolioBudgetNextYear,
        CurrentYearCheck: this.portfolioBudgetCurrentYearCheck,
        NextYearCheck: this.portfolioBudgetNextYearCheck,
      }
      this.portfolioObj.portfolioBudgetingList = obj;
      this.spinner.show();

      this.apiService.put('/portfolio/' + this.portfolioObj.id, this.portfolioObj).subscribe(updatedObj => {
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Portfolio Budget has been saved.")}`,
          "success",
        );
        this.spinner.hide();
      });
    } else {
      this.portfolioObj.portfolioBudgetingList.portfolioBudgetCurrentYear = this.portfolioBudgetCurrentYear;
      this.portfolioObj.portfolioBudgetingList.portfolioBudgetNextYear = this.portfolioBudgetNextYear;
      this.portfolioObj.portfolioBudgetingList.CurrentYearCheck = this.portfolioBudgetCurrentYearCheck;
      this.portfolioObj.portfolioBudgetingList.NextYearCheck = this.portfolioBudgetNextYearCheck;
      this.spinner.show();

      this.apiService.put('/portfolio/' + this.portfolioObj.id, this.portfolioObj).subscribe(updatedObj => {
        this.utilityService.showNotification(
          `${this.localize.transform("Save Success")}`,
          `${this.localize.transform("Portfolio Budget has been saved.")}`,
          "success",
        );
        this.spinner.hide();
      });
    }
  }

  isWebURL(value) {
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(value)
  }

  isWebViewUrl(value) {
    let regexp = /^(http|https):///
    return regexp.test(value)
  }

  redirectToLink() {
    if (this.documentLink) {
      window.open(this.documentLink, "_blank");
    }
  }

  backToPortfolio() {
    this.router.navigate(['/dashboard/portfolio']);
  }

  resetForm() {
    this.formObj = {};
    this.formObj.itPlatform = [];
  }

  openBudgetAssignmentForm(template: TemplateRef<any>, data, type) {
    this.selectedProject = data;
    this.selectedProjectType = type;
    for (let i = 0; i < this.budgetHolder.length; i++) {
      this.budgetHolder[i].budget = parseInt(data.budget[i].budget || 0);
      this.budgetHolder[i].thereofIT = parseInt(data.budget[i].thereofIT || 0);
    }
    this.openModal(template, 'New');
  }

  openBudgetCreationForm(template: TemplateRef<any>, type) {
    this.budgetPopupType = type;
    this.openModal(template, '');
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == 'New') {
      this.isEditsubPortfolioBucket = false;
      this.resetForm();
    }

    if (state == 'Edit') {
      if (!this.isEditsubPortfolioBucket) {
        this.resetForm();
      }
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  projectsValueChanged(e) {
    if (e.itemData.project != undefined && e.itemData.project != null) {
      this.selectedProjectIDForBudgetCreation = e.itemData.project;
    } else {
      this.selectedProjectIDForBudgetCreation = undefined;
    }
  }

  projectsOnInputEvent(e) {
    this.apiService.get(`/reports/searchBySubportfolio/${this.subportfolioId}/` + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.projectsList = response;
    });
  }

  smallOrderValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.selectedOrderIDForBudgetCreation = e.itemData.id;
    } else {
      this.selectedOrderIDForBudgetCreation = undefined;
    }
  }

  smallOrderOnInputEvent(e) {
    this.apiService.get(`/startedSmallOrder/${this.subportfolioId}/` + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.ordersList = response;
    });
  }

  createBudget() {
    if (this.budgetPopupType == 'project') {
      if (this.selectedProjectIDForBudgetCreation == undefined) {
        this.showNotificationBox('Please Select a Project to create Budget')
        return;
      }
    }

    if (this.budgetPopupType == 'order') {
      if (this.selectedOrderIDForBudgetCreation == undefined) {
        this.showNotificationBox('Please Select an Order to create Budget')
        return;
      }
    }

    let budget = [
      {
        costType: "External Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 0,
        group: "CAPEX",
      }, {
        costType: "Internal Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 1,
        group: "CAPEX",
      }, {
        costType: "External Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 2,
        group: "OPEX"
      }, {
        costType: "Internal Costs",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 3,
        group: "OPEX"
      }, {
        costType: "Revenues",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 4,
        group: "Sonstiges",
      }, {
        costType: "Reserves",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        group: "Sonstiges",
        id: 5,
      }, {
        costType: "Total",
        budget: '',
        thereofIT: '',
        currentBudget: '',
        yearlyForecast: '',
        thereofITForecast: '',
        remainingProjectBudget: '',
        id: 6,
        group: "Sonstiges",
      },
    ];

    if (this.yearlyGridColumns.length > 0) {
      for (let i = 0; i < this.yearlyGridColumns.length; i++) {
        for (let j = 0; j < 7; j++) {
          budget[j][this.yearlyGridColumns[i].dataField] = '';
        }
      }
    }

    if (this.yearlyFixedColumns.length > 0) {
      for (let i = 0; i < this.yearlyFixedColumns.length; i++) {
        for (let j = 0; j < 7; j++) {
          budget[j][this.yearlyFixedColumns[i].dataField] = '';
        }
      }
    }

    if (this.budgetPopupType == 'project') {
      this.apiService.post('/projectbudgetcost', {
        portfolioBudgetYear: this.selectedYear.id,
        project: this.selectedProjectIDForBudgetCreation,
        budget,
      }).subscribe((response: any) => {
        this.utilityService.showNotification(this.localize.transform('Success'), this.localize.transform('Budget Created successfully.'), 'success');
        this.refreshSubportfolioBudget();
        this.modalRef.hide();
      })
    } else if (this.budgetPopupType == 'order') {
      this.apiService.post('/orderbudgetcost', {
        portfolioBudgetYear: this.selectedYear.id,
        order: this.selectedOrderIDForBudgetCreation,
        budget,
      }).subscribe((response: any) => {
        this.utilityService.showNotification(this.localize.transform('Success'), this.localize.transform('Budget Created successfully.'), 'success');
        this.refreshSubportfolioBudget();
        this.modalRef.hide();
      })
    }
  }

  closeModel() {
    if (this.isEditsubPortfolioBucket && this.subPortfolio.projects) {
      this.subPortfolio.projects.forEach(project => {
        if (project.projectitem._id == this.backupForm._id) {
          project.projectitem = this.backupForm

          if (project.projectitem.itPlatform) {
            if (project.projectitem.itPlatform.length > 0) {
              project.projectitem.itPlatformBackup = project.projectitem.itPlatform;
              let itPlatforms = [{ id: '', name: '' }, { id: '', name: '' }, { id: '', name: '' }]
              project.projectitem.itPlatform.forEach((itPlatform, idx) => {
                let itPlatformObj = this.utilityService.itPlatformOptions.values.find(val => val.id == itPlatform);
                itPlatforms[idx].id = itPlatformObj.id;
                itPlatforms[idx].name = itPlatformObj.name;
              });
              project.projectitem.itPlatform = itPlatforms;
            }
          }
        }
      })
    }
    this.modalRef.hide();
  }

  closeBucketBudgetAssignmentModal() {
    this.selectedBucket = undefined;
    for (let i = 0; i < this.budgetHolder.length; i++) {
      this.budgetHolder[i].budgetBucket = 0;
      this.budgetHolder[i].thereofITBucket = 0;

      this.budgetHolder[i].budgetPicked = 0;
      this.budgetHolder[i].thereofITPicked = 0;
    }
    this.modalRef.hide();
  }

  openEditDialog(template, obj) {
    this.projectId = obj._id;
    this.isEditsubPortfolioBucket = true;
    this.formObj = obj.projectitem;

    if (this.formObj.itPlatformBackup) {
      this.formObj.itPlatform = this.formObj.itPlatformBackup;
    } else {
      this.formObj.itPlatform = [];
    }

    this.backupForm = JSON.parse(JSON.stringify(this.formObj));
    this.openModal(template, 'Edit');
  }

  portfolioSelectValueChanged(e) {
  }

  subPortfolioSelectValueChanged(e) {
  }

  refreshSubportfolioBudget() {
    this.budgetYearWidget.instance.option('value', this.selectedYear);
  }

  ngOnDestroy() {
    // this.socketService.removeListener('projectBudgetByYearCount');
    // this.socketService.removeListener('projectBudgetByYearIndex');
    // this.socketService.removeListener('projectBudgetByYearSearch');
    // this.socketService.removeListener('projectBudgetByYearSearchIndex');
  }

}
