import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment';
import { DxChartComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import * as jsPDF from 'jspdf';
import "dhtmlx-gantt";
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ShortNumberPipe } from 'app/shared/short-number-pipe/short-number.pipe';

declare var html2canvas: any;
declare var gantt: any;
declare var $: any;
let globalThis;

@Component({
  selector: 'app-pdf-sub-portfolio-status-report',
  templateUrl: './pdf-sub-portfolio-status-report.component.html',
  styleUrls: ['./pdf-sub-portfolio-status-report.component.scss']
})
export class PdfSubPortfolioStatusReportComponent implements OnInit {
  @Input() report;
  @Input() prevReport;
  programReport;
  evaList = [];
  projectsPerProjectTypeChart;
  progressChart;
  financialTrend;
  @ViewChild('progressChart', { static: false }) progressChartElem: DxChartComponent;
  @ViewChild('financialTrend', { static: false }) financialTrendElem: DxChartComponent;
  @ViewChild('milestoneTrendAnalysis', { static: false }) milestoneTrendAnalysisElem: DxChartComponent;
  mtaChart: any = [{
    reportingDate: moment(Date.now()),
    milestones: [Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now()]
  }];
  mtaReport;
  risks = [];
  risksTopFiveRiskCategorySelectOptions;
  risksTopFiveReputationRiskSelectOptions = [{
    reputationRisks: 0,
  }, {
    reputationRisks: 1,
  }, {
    reputationRisks: 2,
  }, {
    reputationRisks: 3,
  }, {
    reputationRisks: 4,
  }];

  otherRisksRE = 0;
  totalRisksRE = 0;
  totalPercent = 0;

  riskPrevious = '';
  riskCurrent = '';

  showLoading: boolean = false;

  tasks: Task[] = [];
  links = [];
  ganttChart = false;
  reportsList;
  @ViewChild("gantt", { static: false }) ganttContainer: ElementRef;

  constructor(private apiService: ApiService, private authService: AuthService, private localize: I18nPipe, private shortNumber: ShortNumberPipe) {
    globalThis = this;

    this.financialTrend = [{
      type: this.localize.transform("Actual Cost"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Forecast"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Current Budget"),
      value: 0,
    }, {
      type: this.localize.transform("Original Budget"),
      value: 0,
    }];
  }

  ngOnInit() {
    this.calculateAggregatedFinancialTrends();
    if (this.report.risks != undefined) {
      for (let i = 0; i < this.report.risks.length; i++) {
        if (this.report.risks[i].report == false) {
          this.otherRisksRE += this.report.risks[i].riskExposure;
        }
        this.totalRisksRE += this.report.risks[i].riskExposure;
      }
    }

    this.otherRisksRE = +this.otherRisksRE.toFixed(2);
    this.totalRisksRE = +this.totalRisksRE.toFixed(2);

    // Calculate Risk previous and current status
    if (this.report.riskStatus == "Low") {
      this.riskCurrent = "L";
    } else if (this.report.riskStatus == 'Medium') {
      this.riskCurrent = 'M';
    } else {
      this.riskCurrent = 'H';
    }

    if (this.prevReport) {
      if (this.prevReport.riskStatus == "Low") {
        this.riskPrevious = "L";
      } else if (this.prevReport.riskStatus == 'Medium') {
        this.riskPrevious = 'M';
      } else if (this.prevReport.riskStatus == 'High') {
        this.riskPrevious = 'H';
      } else {
        this.riskPrevious = '';
      }
    } else {
      this.riskPrevious = '';
    }

    if (this.report.program.id != undefined) {
      this.report.program = this.report.program.id
    }

    // Setting Financial Trend Data
    if (this.report.financialTrend != undefined) {
      this.financialTrend = this.report.financialTrend;
    }

    setTimeout(() => {
      this.financialTrendElem.instance.option('dataSource', this.financialTrend);
    }, 0);

    if (gantt.getDatastore() != null) {
      gantt.clearAll();
    }
  }

  calculateAggregatedFinancialTrends() {
    if (this.report.aggregatedSubportfolioFinancials) {
      this.financialTrend = [{
        type: this.localize.transform("Actual Cost"),
        value: this.report.aggregatedSubportfolioFinancials[6].actualCost,
      }, {
        type: this.localize.transform("Yearly Forecast"),
        value: this.report.aggregatedSubportfolioFinancials[6].forecast,
      }, {
        type: this.localize.transform("Yearly Current Budget"),
        value: this.report.aggregatedSubportfolioFinancials[6].currentBudget,
      }, {
        type: this.localize.transform("Original Budget"),
        value: this.report.aggregatedSubportfolioFinancials[6].originalBudget,
      }];
    }
  }

  currencyParser(currency): number {
    if (currency == 'CHF') {
      return 1;
    } else if (currency == 'EUR') {
      return this.authService.userObj.configuration.rates.EUR;
    } else if (currency == 'GBP') {
      return this.authService.userObj.configuration.rates.GBP;
    } else if (currency == 'USD') {
      return this.authService.userObj.configuration.rates.USD;
    }
  }

  customizePoint(point) {
    if (point.argument == "O") {
      return {
        color: "#1f497d"
      }
    } else if (point.argument == "C") {
      return {
        color: "#8eb4e3"
      }
    } else if (point.argument == "A") {
      return {
        color: "#9bbb59"
      }
    } else if (point.argument == "F") {
      return {
        color: "#f79646"
      }
    }
  }

  onCellPrepared(e) {
    e.cellElement.style.backgroundColor = "#f0f0f0";
  }

  customizeSeries(e) {
    return e ? { showInLegend: true } : { showInLegend: false };
  }

  onDone(e) {
    setTimeout(() => {
      let series = e.component.getSeriesByName("M1");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[0].showInLegend", false);
      }
      series = e.component.getSeriesByName("M2");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[1].showInLegend", false);
      }
      series = e.component.getSeriesByName("M3");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[2].showInLegend", false);
      }
      series = e.component.getSeriesByName("M4");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[3].showInLegend", false);
      }
      series = e.component.getSeriesByName("M5");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[4].showInLegend", false);
      }
      series = e.component.getSeriesByName("M6");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[5].showInLegend", false);
      }
      series = e.component.getSeriesByName("M7");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[6].showInLegend", false);
      }
      series = e.component.getSeriesByName("M8");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[7].showInLegend", false);
      }
      series = e.component.getSeriesByName("M9");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[8].showInLegend", false);
      }
      series = e.component.getSeriesByName("M10");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[9].showInLegend", false);
      }
    });
  }

  download() {
    let imgWidth = 297;
    let pageHeight = 210; //295
    let position = 0;

    const elementToPrint: any = $('#printable-area');
    let downloadElem = elementToPrint.find('#download')[0];
    downloadElem.remove();
    const pdf = new jsPDF('l', 'mm', [840,575]); //[200, 800]

    this.showLoading = true;

    html2canvas(elementToPrint[0], { scale: 2.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;

      let heightLeft = imgHeight;
      let image = canvas.toDataURL("image/jpeg");
      pdf.addImage(image, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 8, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      pdf.save('Subportfolio Report.pdf');
      globalThis.showLoading = false;
      elementToPrint.children().children().append(downloadElem);
    });
  }

  customizeFinancialTrendLabel(e) {
    return globalThis.shortNumber.transform(e.value);
  }

  reset() {
    gantt.clearAll();
    this.reportsList.forEach(element => {
      let overall = "Empty";
      if (element.statusReports != undefined) {
        if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].overallStatus != undefined) {
          overall = element.statusReports[element.statusReports.length - 1].overallStatus;
        } else {
          overall = "Empty";
        }
      } else {
        overall = "Empty";
      }
      this.tasks.push({
        id: element.id,
        text: element.projectName,
        overall: overall, //element.statusReports != undefined ? element.statusReports[element.statusReports.length - 1].overallStatus : "",
        start_date: moment(element.startDate).format('DD/MM/YYYY'),
        end_date: moment(element.endDate).format('DD/MM/YYYY'),
        // progress: element.statusReports.length > 0 ? element.statusReports[element.statusReports.length - 1].percentageComplete / 100 : 0,
      });
    });
    let [data, links] = [this.tasks, this.links];
    gantt.parse({ data });
  }
}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  text?: string;
  // progress: number;
  duration?: number;
  overall: any;
}