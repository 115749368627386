import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderPreviewComponent } from './order-preview.component';
import { ProjectsResolverService } from 'app/resolvers/projects-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: OrderPreviewComponent,
    // resolve: {
    //   projects: ProjectsResolverService
    // }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderPreviewRoutingModule { }
