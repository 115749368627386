import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmallOrderStatusReportComponent } from './small-order-status-report.component';

const routes: Routes = [
  {
    path: '',
    component: SmallOrderStatusReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmallOrderStatusReportRoutingModule { }
