import { Component, OnInit, TemplateRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { UtilityService } from 'app/services/utility.service';
import { SocketService } from 'app/services/socket.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
declare var gantt: any;
let globalThis;

@Component({
  selector: 'app-sub-portfolio-gantt-chart',
  templateUrl: './sub-portfolio-gantt-chart.component.html',
  styleUrls: ['./sub-portfolio-gantt-chart.component.scss']
})
export class SubPortfolioGanttChartComponent implements OnInit, OnDestroy {

  @ViewChild("gantt", { static: true }) ganttContainer: ElementRef;
  projects;
  tasks: Task[] = [];
  links = [];
  ganttChart;
  viewOpt = 'Show Resource view';
  peopleList: any = [];
  public selectedFilter: string = 'year';

  constructor(public route: ActivatedRoute, private apiService: ApiService, private authService: AuthService,
    private router: Router, private localize: I18nPipe, private utilityService: UtilityService, private socketService: SocketService, private spinner: NgxSpinnerService) {
    globalThis = this;
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Gantt View'];

    let sub = this.route.params.subscribe(params => {
      if (params.subPortfolio != undefined) {
        this.utilityService.breadcrumb = "Sub-Portfolio Gantt View";

        this.apiService.get(`/subPortfolio/${params.subPortfolio}`).subscribe((response: any) => {
          this.projects = response.reports;
          let userIds = [];

          if (this.projects) {
            if (this.projects.length > 0) {
              this.projects.forEach(project => {
                userIds.push(project.user);
                
                if (project.ganttChart != null && project.ganttChart != undefined) {
                  if (project.ganttChart.data.length > 0) {
                    project.ganttChart.data.forEach(task => {
                      if (task.owner_id) {
                        userIds.push(task.owner_id);
                      }
                    });
                  }
                }
              });
            }
          }

          this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
        });
      }
    });

    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      responseUsers.forEach(user => {
        this.peopleList.push({ id: user.id, text: user.name, parent: null });
      });

      gantt.config.xml_date = "%d.%m.%Y";
      gantt.config.date_grid = "%d.%m.%Y";

      // initialize gantt chart
      gantt.init(this.ganttContainer.nativeElement);
      // to clear gantt data
      gantt.clearAll();

      let resourceMode = "hours";

      // identify folder_row, group_row and highlighted_resource in gantt chart and add css accordingly  
      gantt.templates.grid_row_class = function (start, end, task) {
        let css = [];
        if (gantt.hasChild(task.id)) {
          css.push("folder_row");
        }

        if (task.$virtual) {
          css.push("group_row")
        }

        if (globalThis.shouldHighlightTask(task)) {
          css.push("highlighted_resource");
        }

        return css.join(" ");
      };

      // if task is highlighted return highlighted_resource string else nothing
      gantt.templates.task_row_class = function (start, end, task) {
        if (globalThis.shouldHighlightTask(task)) {
          return "highlighted_resource";
        }
        return "";
      };

      gantt.templates.timeline_cell_class = function (task, date) {
        if (!gantt.isWorkTime({ date: date, task: task }))
          return "week_end";
        return "";
      };

      gantt.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
        let css = [];
        css.push("resource_marker");
        if (tasks.length <= 1) {
          css.push("workday_ok");
        } else {
          css.push("workday_over");
        }
        return css.join(" ");
      };

      // shows the working hours in a day on task
      gantt.templates.resource_cell_value = function (start_date, end_date, resource, tasks) {
        let html = "<div>"
        if (resourceMode == "hours") {
          html += tasks.length * 8;
        } else {
          html += tasks.length;
        }
        html += "</div>";
        return html;
      };

      // if task is highlighted return highlighted_resource string else nothing
      let resourceTemplates = {
        grid_row_class: function (start, end, resource) {
          let css = [];
          if (gantt.$resourcesStore.hasChild(resource.id)) {
            css.push("folder_row");
            css.push("group_row");
          }
          if (globalThis.shouldHighlightResource(resource)) {
            css.push("highlighted_resource");
          }
          return css.join(" ");
        },
        task_row_class: function (start, end, resource) {
          let css = [];
          if (globalThis.shouldHighlightResource(resource)) {
            css.push("highlighted_resource");
          }
          if (gantt.$resourcesStore.hasChild(resource.id)) {
            css.push("group_row");
          }

          return css.join(" ");
        }
      };

      // use to show resource chart and their labels and functionalities 
      let resourceConfig = {
        scale_height: 30,
        scales: [
          { unit: "day", step: 1, date: "%d %M" }
        ],
        columns: [
          {
            name: "name", label: this.localize.transform("Name"), tree: true, width: 200, template: function (resource) {
              return resource.text;
            }, resize: true
          },
          {
            name: "progress", label: this.localize.transform("Complete"), align: "center", template: function (resource) {
              let tasks = globalThis.getResourceTasks(resource.id);

              let totalToDo = 0,
                totalDone = 0;
              tasks.forEach(function (task) {
                totalToDo += task.duration;
                totalDone += task.duration * (task.progress || 0);
              });

              let completion = 0;
              if (totalToDo) {
                completion = Math.floor((totalDone / totalToDo) * 100);
              }

              return Math.floor(completion) + "%";
            }, resize: true
          },
          {
            name: "workload", label: this.localize.transform("Workload"), align: "center", template: function (resource) {
              let tasks = globalThis.getResourceTasks(resource.id);
              let totalDuration = 0;
              tasks.forEach(function (task) {
                totalDuration += task.duration;
              });

              return (totalDuration || 0) * 8 + "h";
            }, resize: true
          },
          {
            name: "capacity", label: this.localize.transform("Capacity"), align: "center", template: function (resource) {
              let store = gantt.getDatastore(gantt.config.resource_store);
              let n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1

              let state = gantt.getState();

              return gantt.calculateDuration(state.min_date, state.max_date) * n * 8 + "h";
            }
          }
        ]
      };

      gantt.config.scales = [
        { unit: "month", step: 1, format: "%F, %Y" },
        { unit: "day", step: 1, format: "%d %M" }
      ];

      gantt.config.auto_scheduling = true;
      gantt.config.auto_scheduling_strict = true;
      gantt.config.work_time = true;

      // gantt chart labels shows on the left side
      gantt.config.columns = [
        { name: "text", label: this.localize.transform("Project Name"), tree: true, width: 350, resize: true },
        { name: "start_date", label: this.localize.transform("Start Date"), align: "center", width: 120, resize: true },
        { name: "end_date", label: this.localize.transform("End Date"), align: "center", width: 120, resize: true },
        {
          name: "owner", align: "center", width: 120, label: this.localize.transform("Owner"), template: function (task) {
            if (task.type == gantt.config.types.project) {
              return "";
            }

            let store = gantt.getDatastore(gantt.config.resource_store);
            let owner = store.getItem(task[gantt.config.resource_property]);
            if (owner) {
              return owner.text;
            } else {
              return "Unassigned";
            }
          }, resize: true
        },
        { name: "duration", label: this.localize.transform("Duration"), width: 90, align: "center", resize: true },
        // { name: "add", width: 44 }
      ];

      gantt.config.resource_store = "resource";
      gantt.config.resource_property = "owner_id";
      gantt.config.order_branch = true;
      gantt.config.open_tree_initially = true;
      gantt.config.scale_height = 50;

      // add scrolling in work chart and add radio buttons to select hours per day and tasks per day 
      gantt.config.layout = {
        css: "gantt_container",
        rows: [
          {
            gravity: 2,
            cols: [
              { view: "grid", group: "grids", scrollY: "scrollVer" },
              { resizer: true, width: 1 },
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              { view: "scrollbar", id: "scrollVer", group: "vertical" }
            ]
          },
          { resizer: true, width: 1, next: "resources" },
          {
            height: 35,
            cols: [
              { html: "", group: "grids" },
              { resizer: true, width: 1 },
              {
                html: `<label class='active gantt-radio-btn'>Hours per day <input checked type='radio' name='resource-mode' value='hours'></label>" +
                  "<label class='gantt-radio-btn'>Tasks per day <input type='radio' name='resource-mode' value='tasks'></label>`, css: "resource-controls"
              }
            ]
          },
          {
            gravity: 1,
            id: "resources",
            config: resourceConfig,
            templates: resourceTemplates,
            cols: [
              { view: "resourceGrid", group: "grids", scrollY: "resourceVScroll" },
              { resizer: true, width: 1 },
              { view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll" },
              { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
            ]
          },
          { view: "scrollbar", id: "scrollHor" }
        ]
      };

      // on gantt initialization shows the buttons on gantt task creation popup and shows tha radio buttons on work chart 
      // and add some css on it 
      gantt.attachEvent("onGanttReady", function () {
        let radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
        radios.forEach(function (r) {
          gantt.event(r, "change", function (e) {
            let radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
            radios.forEach(function (r) {
              r.parentNode.className = r.parentNode.className.replace("active", "");
            });

            if (this.checked) {
              resourceMode = this.value;
              this.parentNode.className += " active";
              gantt.getDatastore(gantt.config.resource_store).refresh();
            }
          });
        });
      });

      // create the treeDatastore for gantt and store in resourcesStore
      gantt.$resourcesStore = gantt.createDatastore({
        name: gantt.config.resource_store,
        type: "treeDatastore",
        initItem: function (item) {
          item.parent = item.parent || gantt.config.root_id;
          item[gantt.config.resource_property] = item.parent;
          item.open = true;
          return item;
        }
      });

      // refresh daat on after gantt selection
      gantt.$resourcesStore.attachEvent("onAfterSelect", function (id) {
        gantt.refreshData();
      });

      // parse people data in gantt work chart to peoplecollection 
      gantt.$resourcesStore.attachEvent("onParse", function () {
        let people = [];
        gantt.$resourcesStore.eachItem(function (res) {
          if (!gantt.$resourcesStore.hasChild(res.id)) {
            let copy = gantt.copy(res);
            copy.key = res.id;
            copy.label = res.text;
            people.push(copy);
          }
        });
        gantt.updateCollection("people", people);
      });

      // parse the people data in gantt resourcesStore
      gantt.$resourcesStore.parse(this.peopleList);

      gantt.locale.labels.section_owner = "Owner";
      gantt.config.lightbox.sections = [
        { name: "description", height: 50, map_to: "text", type: "textarea", focus: true },
        { name: "owner", height: 50, map_to: "owner_id", type: "select", options: gantt.serverList("people") },
        { name: "time", height: 50, type: "duration", map_to: "auto" }
      ];

      gantt.config.drag_resize = false;
      gantt.config.readonly = true;
      gantt.config.autofit = true;
      gantt.config.scale_unit = "year";
      gantt.config.date_scale = "%M, %Y";

      gantt.init(this.ganttContainer.nativeElement);

      this.fetchSubPortfolio();
    });
  }

  switchDateScale(value: string) {
    this.selectedFilter = value;
    gantt.config.scale_unit = value;
    gantt.render();
  }

  fetchSubPortfolio() {
    if (this.projects.length > 0) {
      this.projects.forEach(project => {
        if (project.ganttChart != null && project.ganttChart != undefined) {
          if (project.ganttChart.data.length > 0) {
            project.ganttChart.data.forEach(task => {
              this.tasks.push(task);
            });
          }
          if (project.ganttChart.links.length > 0) {
            project.ganttChart.links.forEach(link => {
              this.links.push(link);
            });
          }
        } else {
          let startDate: any = moment(project.startDate);
          let endDate: any = moment(project.endDate);
          let dateDiffDays = endDate.diff(startDate, 'days');
          this.tasks.push({
            id: project.id,
            text: project.projectName,
            start_date: moment(project.startDate).format('DD/MM/YYYY'),
            end_date: moment(project.endDate).format('DD/MM/YYYY'),
            duration: dateDiffDays,
            progress: 0,
            owner_id: project.user
          });
        }
      });

      let [data, links] = [this.tasks, this.links];
      gantt.parse({ data, links });
    }
    this.spinner.hide();
  }

  checkProgress(element): any {
    if (element.statusReports != undefined) {
      if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].percentageComplete != undefined) {
        return element.statusReports[element.statusReports.length - 1].percentageComplete / 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // show gantt chart group mode or tasks mode
  toggleGroups() {
    gantt.$groupMode = !gantt.$groupMode;
    if (gantt.$groupMode) {
      this.viewOpt = "show gantt view";

      let groups = gantt.$resourcesStore.getItems().map(function (item) {
        let group = gantt.copy(item);
        group.group_id = group.id;
        group.id = gantt.uid();
        return group;
      });

      gantt.groupBy({
        groups: groups,
        relation_property: gantt.config.resource_property,
        group_id: "group_id",
        group_text: "text"
      });
    } else {
      this.viewOpt = "show resource view";
      gantt.groupBy(false);
    }
  }

  // highlighted task on gantt chart
  shouldHighlightTask(task) {
    let store = gantt.$resourcesStore;
    let taskResource = task[gantt.config.resource_property],
      selectedResource = store.getSelectedId();
    if (taskResource == selectedResource || store.isChildOf(taskResource, selectedResource)) {
      return true;
    }
  }

  // highlighted resource on gantt chart
  shouldHighlightResource(resource) {
    let selectedTaskId = gantt.getState().selected_task;
    if (gantt.isTaskExists(selectedTaskId)) {
      let selectedTask = gantt.getTask(selectedTaskId),
        selectedResource = selectedTask[gantt.config.resource_property];

      if (resource.id == selectedResource) {
        return true;
      } else if (gantt.$resourcesStore.isChildOf(selectedResource, resource.id)) {
        return true;
      }
    }
    return false;
  }

  // get particular resource task from gantt chart
  getResourceTasks(resourceId) {
    let store = gantt.getDatastore(gantt.config.resource_store),
      field = gantt.config.resource_property,
      tasks;

    if (store.hasChild(resourceId)) {
      tasks = gantt.getTaskBy(field, store.getChildren(resourceId));
    } else {
      tasks = gantt.getTaskBy(field, resourceId);
    }
    return tasks;
  }

  backToPortfolio() {
    this.router.navigate(['/dashboard/portfolio']);
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  ngOnDestroy() {
    this.socketService.removeListener('fetchMultipleUsers');
  }
}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  text: string;
  duration: number;
  owner_id: any;
  progress: number;
  //parent: number;
}