import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { ClipboardService } from 'ngx-clipboard';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-program-budget',
  templateUrl: './program-budget.component.html',
  styleUrls: ['./program-budget.component.scss']
})
export class ProgramBudgetComponent implements OnInit, OnDestroy {
  progSponsorEmail;
  tabs: FormGroup
  currentTab = 'Overview';
  programObj: any = {
    programCostTable1: [],
    programSponsor: {
      id: 0
    },
    programManager: {
      id: 0
    },
  };
  usersList = [];
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  aggregatedProgramTable1;
  programId;
  statusReportTable = [];
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  totalTime;
  timeCompleted;
  endDateVSPlannedEndDate;
  plannedDateVSForecastDate;
  tabIndex = 0;
  prevStatusReport;
  goals: any = [];
  programBudgetCurrentYear = [];
  programBudgetNextYear = [];
  programBudgetCurrentYearCheck: boolean = false;
  programBudgetNextYearCheck: boolean = false;
  statusOptions = [];
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  attachment1;
  attachment2;
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  showNotification = false;
  FrontEnd_PATH;
  isProgramCost: boolean = false;
  isAggregatedProgramCost: boolean = true;
  tabsItemId;
  isVisible: boolean = false;
  portfolioBudgetYear = {
    year: []
  }
  programBudgetList = [];
  programBudget;

  constructor(public utilityService: UtilityService, private apiService: ApiService, public authService: AuthService,
    private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
    private localize: I18nPipe, private clipboardService: ClipboardService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Program Details";
    this.currentTab = "Overview";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    globalThis = this;

    this.calculateBudgetValue = this.calculateBudgetValue.bind(this);
    this.calculatethereofITValue = this.calculatethereofITValue.bind(this);
    this.calculatedavonGEICTValue = this.calculatedavonGEICTValue.bind(this);

    this.route.params.subscribe(params => {
      this.programId = params.id;
      this.socketService.emitEvent('fetchProgram', { programId: params.id })
    });

    this.socketService.listen('programStatusReports').subscribe(response => {
      this.statusReportTable = response;
    });

    this.socketService.listen('fetchProgram').subscribe(response => {
      this.programObj = response;
      this.utilityService.breadcrumbList = ['My Programs', this.programObj.programName ,'Details', 'Budget'];

      if (this.programObj.programManager == undefined) {
        this.programObj.programManager = {
          email: ''
        }
      }

      if (this.programObj.programSponsor == undefined) {
        this.programObj.programSponsor = {
          email: ''
        }
      }

      if (this.programObj.goals != undefined) {
        this.goals = this.programObj.goals;
      }

      if (this.programObj.programSponsor && this.programObj.programSponsor.email != '') {
        this.progSponsorEmail = this.programObj.programSponsor.email;
      }

      this.isVisible = true;
    });
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.apiService.get('/getBudgetCostByProgram/' + this.programId).subscribe((response: any) => {
      this.programBudgetList = response;
      this.programBudget = response[0];
      this.spinner.hide();
    });

    this.statusOptions = ['Active', 'On Hold', 'Closed', 'Cancelled'];  
    this.programObj.purpose = "";
  }

  currencyParser(currency): number {
    if (currency == 'CHF') {
      return 1;
    } else if (currency == 'EUR') {
      return this.authService.userObj.configuration.rates.EUR;
    } else if (currency == 'GBP') {
      return this.authService.userObj.configuration.rates.GBP;
    } else if (currency == 'USD') {
      return this.authService.userObj.configuration.rates.USD;
    }
  }

  onEditorPreparingCostTypeTable(e) {
    if (e.parentType == "dataRow") {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  onYearChanged(event) {
    this.programBudget = event.value;
  }

  addNewBudgetYear() {
    let newYear;
    if (this.programBudgetList.length > 0) {
      newYear = parseInt(this.programBudgetList[this.programBudgetList.length - 1].programBudgetYear.year) + 1;
    } else {
      newYear = new Date().getFullYear();
    }

    this.apiService.post('/createNewProgramBudgetYear', {
      nextYear: newYear,
      programId: this.programId
    }).subscribe((response: any) => {
      this.programBudgetList.push(response.programBudgetCost);

      this.utilityService.showNotification(
        `${this.localize.transform("Save Success")}`,
        `${this.localize.transform("New Program Budget Year has been added.")}`,
        "success",
      );
    });
  }

  onCellPrepared(e) {
    if (e.rowIndex != 9 && e.parentType == 'dataRow') {
      if (e.columnIndex > 3) {
        e.cellElement.style.backgroundColor = "#FFF";
      }
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onRowPrepared(e) {
    this.setRowIndex9(e);
  }

  onEditorPreparing(e) {
    this.editRowIndex9(e);
  }

  calculateBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      if (this.programBudget != undefined) {
        this.programBudget.budget[6].yearlyBudget = this.programCostsValueCalculator('yearlyBudget');
        return this.programBudget.budget[6].yearlyBudget;
      }
    } else {
      return e.yearlyBudget ? e.yearlyBudget : '';
    }
  }

  calculatethereofITValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      if (this.programBudget) {
        this.programBudget.budget[6].thereofIT = this.programCostsValueCalculator('thereofIT');
        return this.programBudget.budget[6].thereofIT;
      }
    } else {
      return e.thereofIT ? e.thereofIT : '';
    }
  }

  calculatedavonGEICTValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      if (this.programBudget) {
        this.programBudget.budget[6].davonGEICT = this.programCostsValueCalculator('davonGEICT');
        return this.programBudget.budget[6].davonGEICT;
      }
    } else {
      return e.davonGEICT ? e.davonGEICT : '';
    }
  }

  programCostsValueCalculator(field) {
    let calculationArray = [];
    for (let i = 0; i < 6; i++) {
      calculationArray[i] = this.programBudget.budget[i][field] * 1;
    }

    let total = calculationArray[0] + calculationArray[1] + calculationArray[2] + calculationArray[3] - calculationArray[4] + calculationArray[5];
    return total;
  }

  saveProgramBudget() {
    this.apiService.put('/programBudgetCost/' + this.programBudget.id,
      { budget: this.programBudget.budget }
    ).subscribe(response => {
      this.utilityService.showNotification(`${this.localize.transform('Save Success')}`,
        `${this.localize.transform('Program Budget has been saved.')}`, 'success');
    });
  }

  notificationBox(title, text, type) {
    swal({
      title: this.localize.transform(title),
      text: this.localize.transform(text),
      type: type,
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    });
  }

  ngOnDestroy() {
    this.socketService.removeListener('programStatusReports');
    this.socketService.removeListener('fetchProgram');
  }
}