import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DxTreeViewComponent } from 'devextreme-angular';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FileUploader } from "ng2-file-upload";
import swal from 'sweetalert2';
declare var $: any;

var globalThis;


@Component({
  selector: 'app-create-small-order',
  templateUrl: './create-small-order.component.html',
  styleUrls: ['./create-small-order.component.scss']
})
export class CreateSmallOrderComponent implements OnInit, OnDestroy {
  usersList = [];
  links = [];
  formObject: any = {};
  mandatoryProjectOptions;
  businessUnitOptions;
  businessAreaOptions;
  businessSegmentOptions;
  reportingLevelOptions;
  classificationOptions;
  programOptions;
  strategicContributionOptions;
  feasibilityOptions;
  profitabilityOptions;
  minimumDate = '';
  currencyOptions;
  currentSituationLimit: boolean = false;
  portfolioList = [];
  subPortfolioList = [];
  itPlatformOptions;
  itRelevantOptions;
  confidentialOptions;
  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  @ViewChild('lgModal', { static: false }) public lgModal;
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader2: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader3: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader4: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader5: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader6: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader7: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  treeViewObj;
  smallOrderId;
  attachment1;
  attachment2;
  attachment3;
  attachment4;
  attachment5;
  attachment6;
  attachment7;
  attachment8;
  eventType = "";
  isEdit: boolean = false;
  orderSubject: Subject<any> = new Subject();
  saveFlag: boolean = false;
  isUser: boolean = true;
  showPreview: boolean = false;
  showNotification = false;
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  attachment3Uploaded = true;
  attachment4Uploaded = true;
  attachment5Uploaded = true;
  attachment6Uploaded = true;
  attachment7Uploaded = true;
  attachment8Uploaded = true;
  smallOrderPreview;
  seeMoreFlagEnabled = false;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private utilityService: UtilityService,
    private localize: I18nPipe, private spinner: NgxSpinnerService, private authService: AuthService, private socketService: SocketService) {
    globalThis = this;

    let sub = this.route.params.subscribe(params => {
      this.smallOrderId = params.id;
    });

    this.initOrderForm();
    this.initOrderListeners();

    this.socketService.listen('activePortfolios').subscribe(response => {
      this.portfolioList = response;

      if (this.smallOrderId != undefined) {
        this.utilityService.breadcrumb = "Edit Order";
        this.utilityService.breadcrumbList = ['My Projects', 'Edit Order'];
        this.isEdit = true;

        this.fetchSmallOrder();
      } else {
        this.utilityService.breadcrumb = "Create Order";
        this.utilityService.breadcrumbList = ['My Projects', 'Create Order'];
        this.spinner.hide();
      }
    });

    this.socketService.listen('activePrograms').subscribe(response => {
      this.programOptions = response;
    });

    this.fetchPortfolios();
    this.fetchLinksData();
    this.fetchPrograms();
    this.selectFile();
    this.selectFile1();
    this.selectFile2();
    this.selectFile3();
    this.selectFile4();
    this.selectFile5();
    this.selectFile6();
    this.selectFile7();
  }

  fetchSmallOrder() {
    this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe(order => {
      this.formObject = Object.assign({}, order);
      this.setData(order);
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.currencyOptions = ['CHF', 'EUR', 'USD'];
    this.confidentialOptions = ['Yes', 'No'];
    this.mandatoryProjectOptions = this.utilityService.mandatoryProjectOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessSegmentOptions = this.utilityService.businessSegmentOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.strategicContributionOptions = this.utilityService.strategicContributionOptions;
    this.feasibilityOptions = this.utilityService.feasibilityOptions;
    this.profitabilityOptions = this.utilityService.profitabilityOptions;
    this.itPlatformOptions = this.utilityService.itPlatformOptions;
    this.itRelevantOptions = this.utilityService.itRelevantOptions;

    this.formObject.classification = this.classificationOptions.values[3];
  }

  initOrderForm() {
    this.formObject.name = "";
    this.formObject.purpose = "";
    this.formObject.currentSituation = "";
    this.formObject.status = "Draft";

    this.formObject.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      }
    ];

    this.formObject.deliverables = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      }
    ];

    this.formObject.qualitativeBenefits = [
      {
        id: 1,
        description: ""
      },
      {
        id: 2,
        description: ""
      },
      {
        id: 3,
        description: ""
      }
    ];

    this.formObject.quantitativeBenefits = [
      {
        description: "Cost Savings",
        value: "",
        type: "Per Year"
      },
      {
        description: "NPV",
        value: "",
        type: ""
      },
      {
        description: "Additional Revenues",
        value: "",
        type: "Per Year"
      },
      {
        description: "Payback duration (Discounted)",
        value: "",
        type: "Years"
      },
      {
        description: "IRR",
        value: "",
        type: "%"
      }
    ];

    this.formObject.budget = [
      {
        costTypes: "External Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.costTypeTable = [
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 0,
        group: 'CAPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 1,
        group: 'CAPEX'
      },
      {
        costTypes: "External Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 2,
        group: 'OPEX'
      },
      {
        costTypes: "Internal Costs",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 3,
        group: 'OPEX'
      },
      {
        costTypes: "Revenues",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 4,
        group: 'Sonstiges'
      },
      {
        costTypes: "Reserves",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 5,
        group: 'Sonstiges'
      },
      {
        costTypes: "Total",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 6,
        group: 'Sonstiges'
      },
    ];

    this.formObject.mandatoryProject = {
      id: ""
    };
    this.formObject.portfolio = {
      id: ""
    }
    this.formObject.subPortfolio = {
      id: ""
    }
    this.formObject.businessArea = {
      id: ""
    };
    this.formObject.businessUnit = {
      id: ""
    };
    this.formObject.businessSegment = {
      id: ""
    };
    this.formObject.reportingLevel = {
      id: ""
    };
    this.formObject.classification = {
      id: ""
    };
    this.formObject.strategicContribution = {
      id: ""
    };
    this.formObject.profitability = {
      id: ""
    };
    this.formObject.feasibility = {
      id: ""
    };
    this.formObject.program = {
      id: ""
    };
    this.formObject.itPlatform = [];
    this.formObject.itRelevant = {
      id: ""
    };
    this.formObject.orderManager = this.authService.userObj;
    this.formObject.orderSponsor = {
      email: ""
    };
  }

  fetchLinksData() {
    this.apiService.get('/externalLinks').subscribe((links: any) => {
      this.links = links;
    })
  }

  fetchPortfolios() {
    this.socketService.emitEvent('activePortfolios', {});
  }

  fetchPrograms() {
    this.socketService.emitEvent('activePrograms', {});
  }

  setData(order) {
    if (!this.formObject.mandatoryProject) {
      this.formObject.mandatoryProject = {
        id: ''
      }
    }
    if (!this.formObject.itPlatform) {
      this.formObject.itPlatform = [];
    }
    if (!this.formObject.itRelevant) {
      this.formObject.itRelevant = {
        id: ''
      }
    }
    if (!this.formObject.portfolio) {
      this.formObject.portfolio = {
        id: ''
      }

    }
    if (!this.formObject.subPortfolio) {
      this.formObject.subPortfolio = {
        id: ''
      }
    }
    if (!this.formObject.businessUnit) {
      this.formObject.businessUnit = {
        id: ''
      }
    }
    if (!this.formObject.businessArea) {
      this.formObject.businessArea = {
        id: ''
      }
    }
    if (!this.formObject.businessSegment) {
      this.formObject.businessSegment = {
        id: ''
      }
    }
    if (!this.formObject.reportingLevel) {
      this.formObject.reportingLevel = {
        id: ''
      }
    }
    if (!this.formObject.classification) {
      this.formObject.classification = {
        id: ''
      }
    }
    if (!this.formObject.program) {
      this.formObject.program = {
        id: ""
      };
    }
    if (!this.formObject.strategicContribution) {
      this.formObject.strategicContribution = {
        id: ''
      }
    }
    if (!this.formObject.feasibility) {
      this.formObject.feasibility = {
        id: ''
      }
    }
    if (!this.formObject.profitability) {
      this.formObject.profitability = {
        id: ''
      }
    }
    if (!this.formObject.orderManager) {
      this.formObject.orderManager = this.authService.userObj;
    }
    if (!this.formObject.orderSponsor) {
      this.formObject.orderSponsor = {
        email: ""
      };
    }

    if (!this.formObject.costTypeTable) {
      this.formObject.costTypeTable = [
        {
          costTypes: "External Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 0,
          group: 'CAPEX'
        },
        {
          costTypes: "Internal Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 1,
          group: 'CAPEX'
        },
        {
          costTypes: "External Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 2,
          group: 'OPEX'
        },
        {
          costTypes: "Internal Costs",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 3,
          group: 'OPEX'
        },
        {
          costTypes: "Revenues",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 4,
          group: 'Sonstiges'
        },
        {
          costTypes: "Reserves",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 5,
          group: 'Sonstiges'
        },
        {
          costTypes: "Total",
          actualCost: '',
          forecast: '',
          currentBudget: "",
          originalBudget: '',
          id: 6,
          group: 'Sonstiges'
        },
      ];
    }

    if (this.formObject.startDate != undefined && this.formObject.startDate != null && this.formObject.startDate != "") {
      this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
      this.formObject.endDate = this.minimumDate;
    }

    this.formObject.attachments = order.attachments;
    let attach1: any = document.getElementById("attact1-span");
    let attach2: any = document.getElementById("attact2-span");
    let attach3: any = document.getElementById("attact3-span");
    let attach4: any = document.getElementById("attact4-span");
    let attach5: any = document.getElementById("attact5-span");
    let attach6: any = document.getElementById("attact6-span");
    let attach7: any = document.getElementById("attact7-span");
    let attach8: any = document.getElementById("attact8-span");

    if (order.attachments[0].originalName != undefined) {
      attach1.value = order.attachments[0].originalName;
      this.attachment1 = order.attachments[0];
    }
    if (order.attachments[1].originalName != undefined) {
      attach2.value = order.attachments[1].originalName;
      this.attachment2 = order.attachments[1];
    }

    if (order.attachments[2] && order.attachments[2].originalName != undefined) {
      attach3.value = order.attachments[2].originalName;
      this.attachment3 = order.attachments[2];
    }

    if (order.attachments[3] && order.attachments[3].originalName != undefined) {
      attach4.value = order.attachments[3].originalName;
      this.attachment4 = order.attachments[3];
    }

    if (order.attachments[4] && order.attachments[4].originalName != undefined) {
      attach5.value = order.attachments[4].originalName;
      this.attachment5 = order.attachments[4];
    }

    if (order.attachments[5] && order.attachments[5].originalName != undefined) {
      attach6.value = order.attachments[5].originalName;
      this.attachment6 = order.attachments[5];
    }

    if (order.attachments[6] && order.attachments[6].originalName != undefined) {
      attach7.value = order.attachments[6].originalName;
      this.attachment7 = order.attachments[6];
    }

    if (order.attachments[7] && order.attachments[7].originalName != undefined) {
      attach8.value = order.attachments[7].originalName;
      this.attachment8 = order.attachments[7];
    }

    setTimeout(() => {
      this.formObject.endDate = order.endDate;
    }, 100);

    if (this.authService.userObj.role != "pmo" && this.authService.userObj.role != "admin" && this.formObject.orderManager && this.formObject.orderManager.id != this.authService.userObj.id) {
      this.isUser = false;
    }
  }

  onKeyPurposeInfo(e) {
    if (e.key == 'Enter') {
      e.preventDefault();
    }
  }

  startDateValueChanged(e) {
    this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
    this.formObject.endDate = this.minimumDate;
  }

  onRightClick(e) {
    e.preventDefault();
  }

  onKeyCurrentSituationInfo(e) {
    let lines = e.target.value.split(/\r*\n/);

    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 166) {
        e.preventDefault();
      } else {
        this.currentSituationLimit = false;
      }
    } else {
      this.currentSituationLimit = false;
    }
  }

  onEditorPreparingQuantitativeBenefits(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Period") {
        e.editorOptions.placeholder = this.localize.transform("Payback Period");
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onCellPrepared(e) {
    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingBudgetTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Period") {
        e.editorOptions.placeholder = this.localize.transform("Payback Period");
      }
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.formObject.budget[0][field] * 1;
    let two: any = globalThis.formObject.budget[1][field] * 1;
    let three: any = globalThis.formObject.budget[2][field] * 1;
    let four: any = globalThis.formObject.budget[3][field] * 1;
    let five: any = globalThis.formObject.budget[4][field] * 1;
    let six: any = globalThis.formObject.budget[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.budget[6][field] = total;
    return total;
  }

  calculateResourcesTotalValueForCapex(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? Math.abs(e.budget) : "";
    } else {
      return e.budget;
    }
  }

  mandatoryProjectValueChanged(e) {
    if (this.mandatoryProjectOptions != undefined) {
      this.mandatoryProjectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.mandatoryProject = response;
        }
      });
    }
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(res => {
          if (res.id == e.value) {
            this.formObject.portfolio = res;
            this.subPortfolioList = res.subPortfolio
            if (this.formObject.subPortfolio && this.formObject.subPortfolio.id) {
              let subPortfolio = this.subPortfolioList.find(val => val.id == this.formObject.subPortfolio.id);
              if (!subPortfolio) {
                this.formObject.subPortfolio = { id: '' };
              }
            }
          }
        })
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.formObject.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessUnit = response;
        }
      });
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessArea = response;
        }
      });
    }
  }

  businessSegmentValueChanged(e) {
    if (this.businessSegmentOptions != undefined) {
      this.businessSegmentOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessSegment = response;
        }
      });
    }
  }

  reportingLevelValueChanged(e) {
    if (this.reportingLevelOptions != undefined) {
      this.reportingLevelOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.reportingLevel = response;
        }
      });
    }
  }

  classificationValueChanged(e) {
    if (this.classificationOptions != undefined) {
      this.classificationOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.classification = response;
        }
      });
    }
  }

  strategicContributionValueChanged(e) {
    if (this.strategicContributionOptions != undefined) {
      this.strategicContributionOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.strategicContribution = response;
        }
      });
    }
  }

  feasibilityValueChanged(e) {
    if (this.feasibilityOptions != undefined) {
      this.feasibilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.feasibility = response;
        }
      });
    }
  }

  profitabilityValueChanged(e) {
    if (this.profitabilityOptions != undefined) {
      this.profitabilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.profitability = response;
        }
      });
    }
  }

  confidentialValueChanged(e) {
    if (this.confidentialOptions != undefined) {
      this.confidentialOptions.forEach(response => {
        if (response == e.value) {
          this.formObject.confidential = response;
        }
      });
    }
  }

  itRelevantValueChanged(e) {
    if (this.itRelevantOptions != undefined) {
      this.itRelevantOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itRelevant = response;
        }
      });
    }
  }

  partOfAProgramValueChanged(e) {
    if (this.programOptions != undefined) {
      this.programOptions.forEach(response => {
        if (response.id == e.value) {
          this.formObject.program = response;
          delete this.formObject.program.reports;
        }
      });
    }
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.formObject.itPlatform) {
      this.formObject.itPlatform.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.notificationBox("Warning", 'step 3: It Platform can select maximum no of 3.', "warning");
    } else {
      this.formObject.itPlatform = this.getSelectedItemsKeys(nodes);
    }
  }

  initOrderListeners() {
    this.orderSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
      if (value != undefined) {
        this.socketService.emitEvent('searchUsers', { search: value });
      }

      return [];
    })).subscribe((response: any) => { }, error => { });

    this.socketService.listen('searchUsers').subscribe(response => {
      this.usersList = response;
    });
  }

  orderManagerOnInputEvent(e) {
    this.orderSubject.next(e.event.target.value);
  }

  orderManagerValueChanged(e) {
    if (e.selectedItem != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.selectedItem.email) {
            this.formObject.orderManager = response;
          }
        });
      }
    }
  }

  orderSponsorOnInputEvent(e) {
    this.orderSubject.next(e.event.target.value);
  }

  orderSponsorValueChanged(e) {
    if (e.selectedItem != undefined) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.selectedItem.email) {
            this.formObject.orderSponsor = response;
          }
        });
      }
    }
  }

  redirectToLink(link) {
    if (link) {
      window.open(link, "_blank");
    }
  }

  preview() {
    this.showPreview = false;
    this.smallOrderPreview = this.formObject;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  getOrderObj(): any {
    this.formObject.budget.forEach((value, index) => {
      this.formObject.costTypeTable[index].forecast = value.budget;
      this.formObject.costTypeTable[index].currentBudget = value.budget;
      this.formObject.costTypeTable[index].originalBudget = value.budget;
    });

    return {
      ...this.formObject,
      forecastEndDate: this.formObject.endDate,
      plannedEndDate: this.formObject.endDate,
      mandatoryProject: this.formObject.mandatoryProject.id != '' ? this.formObject.mandatoryProject.id : undefined,
      portfolio: this.formObject.portfolio.id != '' ? this.formObject.portfolio.id : undefined,
      subPortfolio: this.formObject.subPortfolio.id != '' ? this.formObject.subPortfolio.id : undefined,
      businessUnit: this.formObject.businessUnit.id != '' ? this.formObject.businessUnit.id : undefined,
      businessArea: this.formObject.businessArea.id != '' ? this.formObject.businessArea.id : undefined,
      businessSegment: this.formObject.businessSegment.id != '' ? this.formObject.businessSegment.id : undefined,
      reportingLevel: this.formObject.reportingLevel.id != '' ? this.formObject.reportingLevel.id : undefined,
      classification: this.formObject.classification.id != '' ? this.formObject.classification.id : undefined,
      program: this.formObject.program.id != '' ? this.formObject.program.id : undefined,
      strategicContribution: this.formObject.strategicContribution.id != '' ? this.formObject.strategicContribution.id : undefined,
      feasibility: this.formObject.feasibility.id != '' ? this.formObject.feasibility.id : undefined,
      profitability: this.formObject.profitability.id != '' ? this.formObject.profitability.id : undefined,
      itRelevant: this.formObject.itRelevant.id != '' ? this.formObject.itRelevant.id : undefined,
      orderManager: this.formObject.orderManager.email != '' ? this.formObject.orderManager.id : undefined,
      orderSponsor: this.formObject.orderSponsor.email != '' ? this.formObject.orderSponsor.id : undefined
    }
  }

  saveOrder() {
    this.saveFlag = true;
    this.eventType = "save";

    if (this.formObject.name == undefined || this.formObject.name == '') {
      this.notificationBox("Warning", "Please Enter Order Name First.", "warning");
      this.saveFlag = false;
      return;
    }

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(this.formObject.orderManager.email) == false) {
      this.notificationBox("Warning", 'Please enter a valid email address for order manager.', "warning");
      this.saveFlag = false;
      return;
    }

    if (this.formObject.orderManager.id == "") {
      this.notificationBox("Warning", 'Please Select a Order Manager First.', "warning");
      this.saveFlag = false;
      return;
    }

    if (this.formObject.isAttachement1Added == true || this.formObject.isAttachement2Added == true || this.formObject.isAttachement3Added == true || this.formObject.isAttachement4Added == true || this.formObject.isAttachement5Added == true || this.formObject.isAttachement6Added == true || this.formObject.isAttachement7Added == true || this.formObject.isAttachement8Added == true) {
      this.uploadAttachments("save");
    } else {
      this.saveHelper();
    }
  }

  uploadAttachments(type: string) {
    this.showNotification = true;
    if (this.formObject.isAttachement1Added) {
      this.attachment1Uploaded = false;
      this.uploader.uploadAll();
    }
    if (this.formObject.isAttachement2Added) {
      this.attachment2Uploaded = false;
      this.uploader1.uploadAll();
    }
    if (this.formObject.isAttachement3Added) {
      this.attachment3Uploaded = false;
      this.uploader2.uploadAll();
    }
    if (this.formObject.isAttachement4Added) {
      this.attachment4Uploaded = false;
      this.uploader3.uploadAll();
    }
    if (this.formObject.isAttachement5Added) {
      this.attachment5Uploaded = false;
      this.uploader4.uploadAll();
    }
    if (this.formObject.isAttachement6Added) {
      this.attachment6Uploaded = false;
      this.uploader5.uploadAll();
    }
    if (this.formObject.isAttachement7Added) {
      this.attachment7Uploaded = false;
      this.uploader6.uploadAll();
    }
    if (this.formObject.isAttachement8Added) {
      this.attachment8Uploaded = false;
      this.uploader7.uploadAll();
    }
  }

  saveHelper() {
    this.spinner.show();
    let orderObj = this.getOrderObj();

    if (this.smallOrderId) {
      delete orderObj.user;
      this.apiService.put("/smallOrder/" + this.smallOrderId, {
        ...orderObj,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : "",
          this.attachment3 ? this.attachment3 : "",
          this.attachment4 ? this.attachment4 : "",
          this.attachment5 ? this.attachment5 : "",
          this.attachment6 ? this.attachment6 : "",
          this.attachment7 ? this.attachment7 : "",
          this.attachment8 ? this.attachment8 : "",
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added,
        isAttachement3Added: this.formObject.isAttachement3Added,
        isAttachement4Added: this.formObject.isAttachement4Added,
        isAttachement5Added: this.formObject.isAttachement5Added,
        isAttachement6Added: this.formObject.isAttachement6Added,
        isAttachement7Added: this.formObject.isAttachement7Added,
        isAttachement8Added: this.formObject.isAttachement8Added,
      }).subscribe((smallOrderResponse: any) => {
        this.notificationBox("Success", "Order has been saved.", "success");
        this.saveFlag = false;
        this.spinner.hide();
      });
    } else {
      this.apiService.post("/smallOrder", {
        ...orderObj,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : "",
          this.attachment3 ? this.attachment3 : "",
          this.attachment4 ? this.attachment4 : "",
          this.attachment5 ? this.attachment5 : "",
          this.attachment6 ? this.attachment6 : "",
          this.attachment7 ? this.attachment7 : "",
          this.attachment8 ? this.attachment8 : "",
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added,
        isAttachement3Added: this.formObject.isAttachement3Added,
        isAttachement4Added: this.formObject.isAttachement4Added,
        isAttachement5Added: this.formObject.isAttachement5Added,
        isAttachement6Added: this.formObject.isAttachement6Added,
        isAttachement7Added: this.formObject.isAttachement7Added,
        isAttachement8Added: this.formObject.isAttachement8Added,
        user: this.authService.userObj.id
      }).subscribe((smallOrderResponse: any) => {
        this.smallOrderId = smallOrderResponse.id;
        this.notificationBox("Success", "Order has been saved.", "success");
        this.saveFlag = false;
        this.spinner.hide();
      });
    }
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);

      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        this.attachment1Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        this.attachment2Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile2() {
    this.uploader2.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement3Added = true;
      } else {
        this.uploader2.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact3-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader2.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment3 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment3.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement3Added = false;
        this.attachment3 = attach;
        this.showNotification = false;
        this.attachment3Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile3() {
    this.uploader3.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement4Added = true;
      } else {
        this.uploader3.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact4-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader3.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment4 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment4.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement4Added = false;
        this.attachment4 = attach;
        this.showNotification = false;
        this.attachment4Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile4() {
    this.uploader4.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement5Added = true;
      } else {
        this.uploader4.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact5-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader4.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment5 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment5.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement5Added = false;
        this.attachment5 = attach;
        this.showNotification = false;
        this.attachment5Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile5() {
    this.uploader5.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement6Added = true;
      } else {
        this.uploader5.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact6-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader5.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment6 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment6.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement6Added = false;
        this.attachment6 = attach;
        this.showNotification = false;
        this.attachment6Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile6() {
    this.uploader6.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement7Added = true;
      } else {
        this.uploader6.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact7-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader6.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment7 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment7.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement7Added = false;
        this.attachment7 = attach;
        this.showNotification = false;
        this.attachment7Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  selectFile7() {
    this.uploader7.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement8Added = true;
      } else {
        this.uploader7.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact8-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader7.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment8 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment8.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement8Added = false;
        this.attachment8 = attach;
        this.showNotification = false;
        this.attachment8Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true && this.attachment3Uploaded == true && this.attachment4Uploaded == true && this.attachment5Uploaded == true && this.attachment6Uploaded == true && this.attachment7Uploaded == true && this.attachment8Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("start") {
            this.startHelper();
          }
        }
      });
    };
  }

  checkValidation() {
    if (this.formObject.name == undefined || this.formObject.name == '') {
      this.notificationBox("Warning", "Please Enter Order Name First.", "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.purpose == '' || this.formObject.purpose == undefined) {
      this.notificationBox("Warning", 'Please Enter Purpose First.', "warning")
      this.saveFlag = false;
      return false;
    }

    if (!this.isStartDateValid) {
      this.notificationBox("Warning", 'Please enter start date in correct format (dd.MM.yyyy) first.', "warning")
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.startDate == undefined) {
      this.notificationBox("Warning", 'Please select a start date first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (!this.isEndDateValid) {
      this.notificationBox("Warning", 'Please enter end date in correct format (dd.MM.yyyy) first.', "warning")
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.endDate == undefined) {
      this.notificationBox("Warning", 'Please select a end date first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.currentSituation == "" || this.formObject.currentSituation == undefined) {
      this.notificationBox("Warning", 'Please fill current situation first.', "warning");
      this.saveFlag = false;
      return false;
    }

    let goalsCheck = this.formObject.goals.filter(val => val.description != null && val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.notificationBox("Warning", 'Please enter goals first.', "warning");
      this.saveFlag = false;
      return false;
    }

    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description != null && val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.notificationBox("Warning", 'Please enter deliverables first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.notificationBox("Warning", 'Please select a currency first.', "warning");
      this.saveFlag = false;
      return false;
    }

    let qualitativeBenefitsCheck = this.formObject.qualitativeBenefits.filter(val => val.description != null && val.description.length > 0);
    if (qualitativeBenefitsCheck.length == 0) {
      this.notificationBox("Warning", 'Please enter qualitative benefits first.', "warning");
      this.saveFlag = false;
      return false;
    }

    let quantitativeBenefitsCheck = this.formObject.quantitativeBenefits.filter(val => val != null && val.value != null && val.value.length > 0);
    if (quantitativeBenefitsCheck.length == 0) {
      this.notificationBox("Warning", 'Please enter quantitative benefits first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.budget[6].budget == 0) {
      this.notificationBox("Warning", 'Please enter budget first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.mandatoryProject.id == "") {
      this.notificationBox("Warning", 'Please select mandatory project first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.portfolio.id == "") {
      this.notificationBox("Warning", 'Please select portfolio first', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.subPortfolio.id == "") {
      this.notificationBox("Warning", 'Please select sub-portfolio first', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.businessUnit.id == "") {
      this.notificationBox("Warning", 'Please select business unit first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.businessArea.id == "") {
      this.notificationBox("Warning", 'Please select business area first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.businessSegment.id == "") {
      this.notificationBox("Warning", 'Please select business segment first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.reportingLevel.id == "") {
      this.notificationBox("Warning", 'Please select reporting level first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.classification.id == "") {
      this.notificationBox("Warning", 'Please select classification first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.program.programName != "None") {
      if (this.formObject.program.id == "") {
        this.notificationBox("Warning", 'Please select program first.', "warning");
        this.saveFlag = false;
        return false;
      }
    }

    if (this.formObject.strategicContribution.id == "") {
      this.notificationBox("Warning", 'Please select strategic contribution first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.feasibility.id == "") {
      this.notificationBox("Warning", 'Please select feasibility first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.profitability.id == "") {
      this.notificationBox("Warning", 'Please select profitability first.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.itRelevant.id == "") {
      this.notificationBox("Warning", 'Please select IT Relevant.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.itPlatform.length <= 0) {
      this.notificationBox("Warning", 'Please select IT Platform.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.notificationBox("Warning", 'Please select confidential option.', "warning");
      this.saveFlag = false;
      return false;
    }

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(this.formObject.orderManager.email) == false) {
      this.notificationBox("Warning", 'Please enter a valid email address for order manager.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.orderManager.id == "") {
      this.notificationBox("Warning", 'Please Select a Order Manager First.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (emailRegex.test(this.formObject.orderSponsor.email) == false) {
      this.notificationBox("Warning", 'Please enter a valid email address for order sponsor.', "warning");
      this.saveFlag = false;
      return false;
    }

    if (this.formObject.orderSponsor.id == "") {
      this.notificationBox("Warning", 'Please Select a Order Sponsor First.', "warning");
      this.saveFlag = false;
      return false;
    }

    else {
      return true;
    }
  }

  public showAttachmentDeleteButton(type: string): boolean {
    if (this.formObject.attachments != undefined) {
      if (this.formObject.attachments.length > 0) {
        if (type == '1') {
          return (this.formObject.id != undefined && this.formObject.attachments[0] != undefined && this.formObject.attachments[0] != "");
        } else if (type == '2') {
          return (this.formObject.id != undefined && this.formObject.attachments[1] != undefined && this.formObject.attachments[1] != "");
        } else if (type == '3') {
          return (this.formObject.id != undefined && this.formObject.attachments[2] != undefined && this.formObject.attachments[2] != "");
        } else if (type == '4') {
          return (this.formObject.id != undefined && this.formObject.attachments[3] != undefined && this.formObject.attachments[3] != "");
        } else if (type == '5') {
          return (this.formObject.id != undefined && this.formObject.attachments[4] != undefined && this.formObject.attachments[4] != "");
        } else if (type == '6') {
          return (this.formObject.id != undefined && this.formObject.attachments[5] != undefined && this.formObject.attachments[5] != "");
        } else if (type == '7') {
          return (this.formObject.id != undefined && this.formObject.attachments[6] != undefined && this.formObject.attachments[6] != "");
        } else if (type == '8') {
          return (this.formObject.id != undefined && this.formObject.attachments[7] != undefined && this.formObject.attachments[7] != "");
        }
      } else {
        return false;
      }
    }
  }

  deleteAttachment(type: string) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.formObject.attachments[0] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '2') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.formObject.attachments[1] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '3') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[2].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment3 = "";
              this.formObject.attachments[2] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact3-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '4') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[3].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment4 = "";
              this.formObject.attachments[3] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact4-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '5') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[4].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment5 = "";
              this.formObject.attachments[4] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact5-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '6') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[5].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment6 = "";
              this.formObject.attachments[5] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact6-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '7') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[6].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment7 = "";
              this.formObject.attachments[6] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact7-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else if (type == '8') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[7].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment8 = "";
              this.formObject.attachments[7] = "";

              this.apiService.put('/smallOrder/' + this.smallOrderId, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact8-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        }
      }
    });
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  setAttachmentName3() {
    let obj: any = document.getElementById("attact3-span");
    let obj3: any = document.getElementById("pmt-uploader3");
    obj.value = obj3.value;
  }

  setAttachmentName4() {
    let obj: any = document.getElementById("attact4-span");
    let obj4: any = document.getElementById("pmt-uploader4");
    obj.value = obj4.value;
  }

  setAttachmentName5() {
    let obj: any = document.getElementById("attact5-span");
    let obj5: any = document.getElementById("pmt-uploader5");
    obj.value = obj5.value;
  }

  setAttachmentName6() {
    let obj: any = document.getElementById("attact6-span");
    let obj6: any = document.getElementById("pmt-uploader6");
    obj.value = obj6.value;
  }

  setAttachmentName7() {
    let obj: any = document.getElementById("attact7-span");
    let obj7: any = document.getElementById("pmt-uploader7");
    obj.value = obj7.value;
  }

  setAttachmentName8() {
    let obj: any = document.getElementById("attact8-span");
    let obj8: any = document.getElementById("pmt-uploader8");
    obj.value = obj8.value;
  }

  startOrder() {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to Start the Order?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (this.checkValidation()) {
          this.saveFlag = true;
          this.eventType = "start";

          if (this.formObject.isAttachement1Added == true || this.formObject.isAttachement2Added == true || this.formObject.isAttachement3Added == true || this.formObject.isAttachement4Added == true || this.formObject.isAttachement5Added == true || this.formObject.isAttachement6Added == true || this.formObject.isAttachement7Added == true || this.formObject.isAttachement8Added == true) {
            this.uploadAttachments("submit");
          } else {
            this.startHelper();
          }
        }
      }
    });
  }

  restartOrder() {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to Restart the Order?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.put("/smallOrder/" + this.smallOrderId, {
          status: 'Start'
        }).subscribe(order => {
          this.formObject = Object.assign({}, order);
          this.setData(order);
          this.spinner.hide();
          this.notificationBox("Success", "Order has been Restarted.", "success");
        })
      }
    });
  }

  startHelper() {
    this.spinner.show();
    let orderObj = this.getOrderObj();

    if (this.smallOrderId) {
      delete orderObj.user;
      this.apiService.put("/smallOrder/" + this.smallOrderId, {
        ...orderObj,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : "",
          this.attachment3 ? this.attachment3 : "",
          this.attachment4 ? this.attachment4 : "",
          this.attachment5 ? this.attachment5 : "",
          this.attachment6 ? this.attachment6 : "",
          this.attachment7 ? this.attachment7 : "",
          this.attachment8 ? this.attachment8 : "",
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added,
        isAttachement3Added: this.formObject.isAttachement3Added,
        isAttachement4Added: this.formObject.isAttachement4Added,
        isAttachement5Added: this.formObject.isAttachement5Added,
        isAttachement6Added: this.formObject.isAttachement6Added,
        isAttachement7Added: this.formObject.isAttachement7Added,
        isAttachement8Added: this.formObject.isAttachement8Added,
        status: 'Start'
      }).subscribe((smallOrderResponse: any) => {
        this.apiService.post("/createOrderBudget", {
          year: new Date().getFullYear(),
          subPortfolioId: this.formObject.subPortfolio.id,
          orderId: this.smallOrderId
        }).subscribe(response => {
          this.socketService.emitEvent('sendEmail', {
            email: this.formObject.orderSponsor.email,
            subject: `${this.formObject.name} Start`,
            message: `<a href="${this.apiService.FrontEndPATH}/view/smallorder/${this.smallOrderId}" target="_blank">${'Auftrag / Order : ' + this.formObject.name}</a><br>` +
              this.utilityService.emailTemplates.filter(val => val.event == 'Small Order Started')[0].text
          });

          //Send Notifications to Assigned emails in subportfolio
          if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
            let notificationEmails = [];
            if (this.formObject.itRelevant.name == 'No') {
              notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
            } else {
              notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
            }

            if (notificationEmails.length > 0) {
              this.socketService.emitEvent('sendEmail', {
                email: notificationEmails,
                subject: "oneView Projekt-Notification: Neue Projektaktivität",
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/smallorder/${this.smallOrderId}" target="_blank">${'Auftrag / Order : ' + this.formObject.name}</a>`
              });
            }
          }

          this.spinner.hide();
          this.router.navigate(["smallOrder"]);
          this.notificationBox("Success", "Order has been started.", "success");
          this.saveFlag = false;
        })
      });
    } else {
      this.apiService.post("/smallOrder", {
        ...orderObj,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : "",
          this.attachment3 ? this.attachment3 : "",
          this.attachment4 ? this.attachment4 : "",
          this.attachment5 ? this.attachment5 : "",
          this.attachment6 ? this.attachment6 : "",
          this.attachment7 ? this.attachment7 : "",
          this.attachment8 ? this.attachment8 : "",
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added,
        isAttachement3Added: this.formObject.isAttachement3Added,
        isAttachement4Added: this.formObject.isAttachement4Added,
        isAttachement5Added: this.formObject.isAttachement5Added,
        isAttachement6Added: this.formObject.isAttachement6Added,
        isAttachement7Added: this.formObject.isAttachement7Added,
        isAttachement8Added: this.formObject.isAttachement8Added,
        status: 'Start',
        user: this.authService.userObj.id
      }).subscribe((smallOrderResponse: any) => {
        this.smallOrderId = smallOrderResponse.id;

        this.apiService.post("/createOrderBudget", {
          year: new Date().getFullYear(),
          subPortfolioId: this.formObject.subPortfolio.id,
          orderId: this.smallOrderId
        }).subscribe(response => {
          this.socketService.emitEvent('sendEmail', {
            email: this.formObject.orderSponsor.email,
            subject: `${this.formObject.name} Start`,
            message: `<a href="${this.apiService.FrontEndPATH}/view/smallorder/${this.smallOrderId}" target="_blank">${'Auftrag / Order : ' + this.formObject.name}</a><br>` +
              this.utilityService.emailTemplates.filter(val => val.event == 'Small Order Started')[0].text
          });

          this.spinner.hide();
          this.router.navigate(["smallOrder"]);
          this.notificationBox("Success", "Order has been started.", "success");
          this.saveFlag = false;
        });

      });
    }
  }

  closeOrder() {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to close the order?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.saveFlag = true;

        if (this.checkValidation()) {
          this.spinner.show();
          let orderObj = this.getOrderObj();

          if (this.smallOrderId) {
            delete orderObj.user;
            this.apiService.put("/smallOrder/" + this.smallOrderId, {
              ...orderObj,
              status: 'Closed'
            }).subscribe((smallOrderResponse: any) => {
              this.notificationBox("Success", "Order has been Close.", "success");
              this.saveFlag = false;

              this.socketService.emitEvent('sendEmail', {
                email: this.formObject.orderSponsor.email,
                subject: `${this.formObject.name} Close`,
                message: this.utilityService.emailTemplates.filter(val => val.event == 'Small Order Closed')[0].text
              });

              this.spinner.hide();
              this.router.navigate(["smallOrder"]);

            });
          }
        }
      }
    });
  }

  checkValidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Qualitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.qualitativeBenefits.filter(val => val.description.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Quantitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefits.filter(val => val != null && val.value != null && val.value.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkInvalidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Qualitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.qualitativeBenefits.filter(val => val.description.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Quantitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefits.filter(val => val != null && val.value != null && val.value.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else {
      return true;
    }
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  ngOnDestroy() {
    this.socketService.removeListener('activePortfolios');
    this.socketService.removeListener('activePrograms');
    this.socketService.removeListener('searchUsers');
    this.socketService.removeListener('sendEmail');
  }
}
