import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocketService } from 'app/services/socket.service';
import { DxDataGridComponent } from 'devextreme-angular';
import swal from 'sweetalert2';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  projectForms: any = [];
  formObj: any = {};
  public modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public inputField = '';
  public inputFieldFr = '';
  public inputFieldDe = '';
  public email = '';
  public group = '';
  projectFormTitle: string;
  disableEmail: Boolean = true;
  portfolioList;
  portfoliosCount: number = 0;
  portfolioPageIndex = 2;
  portfolioQuestionsGroups = [{ name: "Fachfunktion Arbeitssicherheit & Gesundheitsschutz (AS&GS)" }, { name: "Fachfunktion Qualitäts-, Umwelt- und Risikomanagement" }, { name: "Fachfunktion Research & Innovation: Schutz des geistigen Eigentums" }];
  isQuestionEditOpen: boolean = false;
  @ViewChild("popUpQuestionsPortfolio", { static: false }) popUpQuestionsPortfolio: DxDataGridComponent;

  constructor(private modalService: BsModalService, private apiService: ApiService, private utilityService: UtilityService,
    private socketService: SocketService, private localize: I18nPipe, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Questions';
    this.utilityService.breadcrumbList = ['Settings', 'Questions'];
  }

  ngOnInit() {
    this.spinner.show();
    this.getQuestions();

    this.socketService.listen('portfoliosCount').subscribe(count => {
      this.portfoliosCount = count;

      if (count > 20) {
        this.socketService.emitEvent('portfoliosIndex', { pageIndex: this.portfolioPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('portfoliosIndex').subscribe((response: any) => {
      this.portfolioList = this.portfolioList.concat(response);

      if (this.portfolioPageIndex < (this.portfoliosCount / 20)) {
        this.portfolioPageIndex++;
        this.socketService.emitEvent('portfoliosIndex', { pageIndex: this.portfolioPageIndex, pageSize: 20 });
      }
    });

    this.fetchPortfolios();
  }

  getQuestions(): void {
    this.apiService.get('/questions/').subscribe(response => {
      this.projectForms = response;
      this.spinner.hide();
    });
  }

  fetchPortfolios() {
    this.spinner.show();
    this.apiService.get('/portfolio?limit=20').subscribe(response => {
      this.portfolioList = response;
      this.socketService.emitEvent('portfoliosCount', {});
      this.spinner.hide();
    })
  }

  openEditDialog(template, obj, projectFormTitle) {
    this.spinner.show();
    this.projectFormTitle = projectFormTitle
    this.apiService.get('/questionsMapper/' + obj.id).subscribe((response: any) => {
      this.formObj = response.data;
      this.inputField = this.formObj.question;
      this.email = this.formObj.email;
      this.inputFieldDe = response.de;
      this.inputFieldFr = response.fr;

      if (this.formObj.count == 1) {
        this.disableEmail = false;
      } else {
        this.disableEmail = true;
      }

      this.spinner.hide();
      this.openModal(template);
    });
  }

  openCreateDialog(template, objQuestion, type) {
    if (type == 'new') {
      this.formObj = objQuestion;
      this.resetFields();
      this.isQuestionEditOpen = false;
      this.openModal(template);
    } else {
      this.spinner.show();
      this.apiService.get('/questionsMapper/' + objQuestion.id).subscribe((response: any) => {
        this.formObj = response.data;

        this.inputField = response.data.question;
        this.email = response.data.email;
        this.inputFieldDe = response.de;
        this.inputFieldFr = response.fr;
        this.group = response.data.group;
        this.isQuestionEditOpen = true;

        if (this.formObj.count == 1) {
          this.disableEmail = false;
        } else {
          this.disableEmail = true;
        }

        this.spinner.hide();
        this.openModal(template);
      });
    }
  }

  savePortfolioQuestions(obj) {
    if (this.inputField == '' || this.inputField == undefined || this.inputFieldDe == ''
      || this.inputFieldDe == undefined || this.inputFieldFr == '' || this.inputFieldFr == undefined) {
      this.showNotification('Warning', 'Please fill all language fields.');
    } else {

      this.spinner.show();
      if (this.isQuestionEditOpen) {
        this.apiService.put('/questionsMapper/' + obj.id, {
          question: {
            question: this.inputField,
            email: this.email,
          },
          de: this.inputFieldDe,
          fr: this.inputFieldFr
        }).subscribe((response: any) => {
          let index = this.portfolioList.findIndex(val => val.id == response.data[0].portfolioName);
          let objData = this.portfolioList[index].questions.find(val => val.id == response.data[0].id);
          let i = this.portfolioList[index].questions.indexOf(objData);
          this.portfolioList[index].questions[i] = response.data[0];
          this.notification('Success', 'Saved Successfully.');
          this.closeModel();
          this.spinner.hide();
        });
      } else {
        this.apiService.post('/questionsMapper', {
          question: {
            question: this.inputField,
            email: this.email,
            group: this.group,
            department: this.group,
            portfolioName: obj.id
          },
          de: this.inputFieldDe,
          fr: this.inputFieldFr
        }).subscribe((response: any) => {
          let index = this.portfolioList.findIndex(val => val.id == response.data.portfolioName);
          this.portfolioList[index].questions.push(response.data);
          this.notification('Success', 'Saved Successfully.');
          this.closeModel();
          this.spinner.hide();
        });
      }
    }
  }

  onDelete(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to delete this Question?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.delete('/questionsMapper/' + obj.id).subscribe((response: any) => {
          let index = this.portfolioList.findIndex(val => val.id == obj.portfolioName);
          let questionIndex = this.portfolioList[index].questions.findIndex(val => val.id == obj.id);
          this.portfolioList[index].questions.splice(questionIndex, 1);
          this.notification('Success', 'Deleted Successfully.');
          this.spinner.hide();
        });
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  closeModel() {
    this.modalRef.hide();
    this.resetFields();
  }

  resetFields(): void {
    this.inputField = "";
    this.inputFieldDe = "";
    this.inputFieldFr = "";
    this.email = "";
    this.group = "";
  }

  save(obj) {
    if (this.inputField == '' || this.inputField == undefined || this.inputFieldDe == ''
      || this.inputFieldDe == undefined || this.inputFieldFr == '' || this.inputFieldFr == undefined) {
      this.showNotification('Warning', 'Please fill all language fields.');
    } else {
      this.spinner.show();
      obj.question = this.inputField;
      this.formObj = obj;
      this.apiService.put('/questionsMapper/' + this.formObj.id, {
        question: {
          question: this.formObj.question,
          email: this.email
        },
        de: this.inputFieldDe,
        fr: this.inputFieldFr
      }).subscribe((response: any) => {
        let objArr = this.projectForms.find(val => val.id == response.data[0].formName);
        let index = this.projectForms.indexOf(objArr);
        let objData = this.projectForms[index].questions.find(val => val.id == response.data[0].id);
        let i = this.projectForms[index].questions.indexOf(objData);
        this.projectForms[index].questions[i] = response.data[0];
        this.notification('Success', 'Save Successfully.');
        this.closeModel();
        this.spinner.hide();
      });
    }
  }

  notification(title, message) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'success',
    );
  }

  showNotification(title, message,) {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'warning',
    );
  }
}
