import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatter'
})
export class CurrencyFormatterPipe implements PipeTransform {

  transform(value: number, includeDecimal: boolean = true,
    currencySign: string = '',
    decimalLength: number = 0,
    chunkDelimiter: string = "'",
    decimalDelimiter: string = '.',
    chunkLength: number = 3): string {
    // value /= 100;

    let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    let num: any;
    let answer;
    if (value != null && value != undefined) {
      if (value.toString().split('.')[1] != undefined) {
        num = value.toString();
        let decimalPrecisionTokens = num.split('.');
        answer = currencySign + (decimalDelimiter ? decimalPrecisionTokens[0].replace('.', decimalDelimiter) : decimalPrecisionTokens[0])
          .replace(new RegExp(result, 'g'), '$&' + chunkDelimiter) + '.' + decimalPrecisionTokens[1];
      } else {
        num = (value * 1).toFixed(Math.max(0, ~~decimalLength));
        answer = currencySign + (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
      }
    } else {
      return '';
    }

    return (num == 0 || num == 'NaN') ? '' : answer;
  }

}
