import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { DxDataGridComponent, DxTreeViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FileUploader } from "ng2-file-upload";
import * as moment from 'moment';
import 'rxjs/operator/retry';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import { SocketService } from 'app/services/socket.service';
import { OrgChartComponent } from 'app/components/org-chart/org-chart.component';
declare var $: any;
// declare var dhx: any;

var globalThis;
// var globalOrgChart;

@Component({
  selector: 'app-project-order',
  templateUrl: './project-order.component.html',
  styleUrls: ['./project-order.component.scss']
})
export class ProjectOrderComponent implements OnInit, OnDestroy {
  public formObject: any;
  projectObj: any;
  projSponsorEmail;
  projFicoEmail;
  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2029, 11, 31);
  currentDate = new Date();
  impactedByDependenciesTable;
  communicationTable;
  risks;
  risksTopFiveRiskStrategySelectOptions;
  risksTopFiveReputationRiskSelectOptions;
  involvedPartnersTable;
  trippleConstraint = {
    cost: 1,
    time: 1,
    scope: 1
  };
  renderChart = true;
  radarChartOptions;
  PMOsList = [];
  usersList = [];
  mandatoryProjectOptions;
  businessUnitOptions;
  businessAreaOptions;
  subjectFieldOptions;
  levelRelevanceOptions;
  classificationOptions;
  projectTypeOptions;
  programOptions;
  strategicContributionOptions;
  feasibilityOptions;
  profitabilityOptions;
  projectManagerInOptions;
  pmoFormalControlOptions;
  projectClientInOptions;
  businessSegmentOptions;
  reportingLevelOptions;
  lineChartColors = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)"
    }
  ];
  showLoader;
  attachment1;
  attachment2;
  @ViewChild("lgModal", { static: false }) lgModal;
  @ViewChild("riskPopUp", { static: false }) riskPopUpTemplate;
  @ViewChild("milestonePopUp", { static: false }) milestonePopUpTemplate;
  @ViewChild("dependenciesPopUp", { static: false }) dependenciesPopUpTemplate;
  @ViewChild("communicationPopUp", { static: false }) communicationPopUpTemplate;
  @ViewChild("descriptionDataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("projectSearch", { static: false }) projectSearchBox: DxSelectBoxComponent;
  data;

  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public radarChartLabels: string[] = [];
  public radarChartType: string = "radar";
  public saveFlag = false;
  public submitFlag = false;
  showNotification = false;
  eventType = "";
  projectOrganizationChartData = [];
  public benefitForm: any = {
    description: '',
    report: false
  };
  benefitTypeSelectOptions = [];
  backupBenefitForm;
  isEditBenefit = false;
  @ViewChild("popUp", { static: false }) popUp;
  popUpConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  minimumDate = '';
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  riskForm: any = {
    name: '',
    report: true,
    riskStrategy: {
      id: ''
    },
    description: '',
    measures: ''
  };
  backupForm;
  milestoneForm: any = {
    name: '',
    description: '',
    dueDate: '',
    report: true
  };
  mileStonesValues: any = [];
  public currencyOptions;
  public questionOptions;
  public dependenciesImpactColumnOptions: any = [];
  public dependenciesProjectColumnOptions = [];
  public dependenciesProjectFormColumnOptions = [];
  public dependenciesForm: any = {};
  public communicationForm = {};
  showOrderPreview: boolean = false;
  public startDateChangeFlag = false;
  orderObj: any;
  orderQuestions: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  orderHelpGuide: any = [];
  @ViewChild('form', { static: false }) form: NgForm;
  isFormSaveSubmit: boolean = false;
  invalidChars = [
    "-",
    "+",
    "e",
  ];
  currentSituationLimit: boolean = false;
  additionalInfoLimit: boolean = false;
  // diagram: any;
  // editor: any;
  // editorCont: any;
  // diagramCont: any;
  portfolioList = [];
  subPortfolioList = [];
  itPlatformOptions;
  itRelevantOptions;
  projectMethodologyOptions;
  confidentialOptions;
  isTeamProject = false;
  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  isDueDateValid: boolean = true;
  projectsList = [];
  projectSubject: Subject<any> = new Subject();
  projectInputVal;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeViewObj;
  backupProjectsList = [];
  dropdownConfig: any;
  @ViewChild("diagram", { static: false }) diagram: OrgChartComponent;
  isChartLoaded: boolean = false;
  isStepValidated = [true, true, true, true, true, true, true];
  isSavedPressed: boolean = false;
  FrontEnd_PATH = '';
  BASE_PATH;
  attachmentQueryParams = '';
  groups = [];
  groupsHolder = [];
  projectSuffix = "";

  constructor(private apiService: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private modalService: BsModalService,
    private localize: I18nPipe,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private socketService: SocketService) {
    this.utilityService.breadcrumb = "Project Order";

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    globalThis = this;

    this.formObject = new OrderForm();
    this.formObject.projectManager = {
      email: ""
    };
    this.formObject.program = {
      id: ""
    };
    this.formObject.pmoOfficer = {
      id: ""
    };
    this.formObject.projectSponsor = {
      id: ""
    };
    this.formObject.projectFico = {
      id: ""
    };
    this.formObject.mandatoryProject = {
      id: ""
    };
    this.formObject.portfolio = {
      id: ""
    }
    this.formObject.subPortfolio = {
      id: ""
    }
    this.formObject.businessArea = {
      id: ""
    };
    this.formObject.businessUnit = {
      id: ""
    };
    this.formObject.businessSegment = {
      id: ""
    };
    this.formObject.reportingLevel = {
      id: ""
    };
    this.formObject.classification = {
      id: ""
    };
    this.formObject.projectType = {
      id: ""
    };
    this.formObject.strategicContribution = {
      id: ""
    };
    this.formObject.profitability = {
      id: ""
    };
    this.formObject.feasibility = {
      id: ""
    };
    this.formObject.itPlatform = [];
    this.formObject.itRelevant = {
      id: ""
    };
    this.formObject.projectMethodology = {
      id: ""
    };
    this.formObject.projectMethodology = {
      id: ""
    };

    this.formObject.radarChartData = [
      {
        data: [
          this.trippleConstraint.time,
          this.trippleConstraint.cost,
          this.trippleConstraint.scope
        ],
        label: this.localize.transform("Triple Constraint")
      }
    ];
    this.formObject.purpose = "";
    this.formObject.isAttachement1Added = false;
    this.formObject.isAttachement2Added = false;
    this.radarChartLabels = [this.localize.transform("Time"), this.localize.transform("Cost"), this.localize.transform("Scope")];

    this.socketService.listen('usersFromRoles').subscribe(usersList => {
      this.utilityService.PMOsList = usersList;
      this.PMOsList = this.utilityService.PMOsList;
    });

    this.socketService.listen('activePrograms').subscribe(response => {
      this.programOptions = response;
    });

    this.socketService.listen('fetchProjectOrder').subscribe(response => {
      this.projectObj = response.project;

      if (this.projectObj.projectOrder.length == 0) {
        this.utilityService.breadcrumbList = ['My Projects', 'Create Order'];
        this.projectObj.projectReport = response.detail;
        this.isChartLoaded = true;

        this.formObject.projectName = this.projectObj.projectName;
        this.formObject.purpose = this.projectObj.purpose;
        this.formObject.projectManager = this.projectObj.projectReport.projectManager;
        this.formObject.projectSponsor = this.projectObj.projectReport.projectSponsor;
        this.formObject.startDate = this.projectObj.projectReport.outlineStartDate;

        if (this.projectObj.isPhase) {
          let sepIndex = this.projectObj.projectName.lastIndexOf("-");
          this.projectSuffix = this.projectObj.projectName.substring(sepIndex);
          this.formObject.projectName = this.projectObj.projectName.substring(0, sepIndex);
        }

        if (this.formObject.startDate != undefined && this.formObject.startDate != "") {
          this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
          this.formObject.endDate = this.minimumDate;
        }

        this.formObject.endDate = this.projectObj.projectReport.outlineEndDate;
        this.formObject.purpose = this.projectObj.projectReport.purpose;
        this.formObject.currentSituation = this.projectObj.projectReport.currentSituation;
        this.formObject.goals = this.projectObj.projectReport.goals;
        this.formObject.deliverables = this.projectObj.projectReport.deliverables;
        this.formObject.outOfScope = this.projectObj.projectReport.outOfScope;
        this.formObject.risks = this.projectObj.projectReport.risks;
        this.mileStonesValues = this.projectObj.projectReport.mileStonesValues;
        this.formObject.quantitativeBenefit = this.projectObj.projectReport.quantitativeBenefit;
        this.formObject.kpisTable = this.projectObj.projectReport.kpisTable;
        this.formObject.involvedPartnersTable = this.projectObj.projectReport.involvedPartnersTable;
        this.formObject.mandatoryProject = this.projectObj.projectReport.mandatoryProject;
        this.formObject.itPlatform = this.projectObj.projectReport.itPlatform;
        this.formObject.itRelevant = this.projectObj.projectReport.itRelevant;
        this.formObject.portfolio = this.projectObj.projectReport.portfolio;
        this.formObject.subPortfolio = this.projectObj.projectReport.subPortfolio;
        this.formObject.businessUnit = this.projectObj.projectReport.businessUnit;
        this.formObject.businessArea = this.projectObj.projectReport.businessArea;
        this.formObject.businessSegment = this.projectObj.projectReport.businessSegment;
        this.formObject.reportingLevel = this.projectObj.projectReport.reportingLevel;
        this.formObject.classification = this.projectObj.projectReport.classification;
        this.formObject.projectType = this.projectObj.projectReport.projectType;
        this.formObject.program = this.projectObj.projectReport.program;
        this.formObject.strategicContribution = this.projectObj.projectReport.strategicContribution;
        this.formObject.feasibility = this.projectObj.projectReport.feasibility;
        this.formObject.profitability = this.projectObj.projectReport.profitability;
        this.formObject.projectMethodology = this.projectObj.projectReport.projectMethodology;
        this.formObject.confidential = this.projectObj.projectReport.confidential;
        this.formObject.additionalInformation = this.projectObj.projectReport.additionalInformation;
        this.formObject.pmoOfficer = this.projectObj.projectReport.pmoOfficer;
        this.formObject.currency = this.projectObj.projectReport.currency;

        this.formObject.costTypeTable.map((val, index) => {
          this.formObject.costTypeTable[index].current = this.projectObj.projectReport.estimatedProjectCostTableOutline[index].budget;
        });
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }
        this.updateChart();
        this.spinner.hide();

      } else {
        this.utilityService.breadcrumbList = ['My Projects', 'Edit Order'];
        this.projectObj.projectOrder[0] = response.order;

        if (this.projectObj.projectOrder[0].businessArea == undefined) {
          this.projectObj.projectOrder[0].businessArea = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].businessUnit == undefined) {
          this.projectObj.projectOrder[0].businessUnit = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].businessSegment == undefined) {
          this.projectObj.projectOrder[0].businessSegment = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].reportingLevel == undefined) {
          this.projectObj.projectOrder[0].reportingLevel = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].classification == undefined) {
          this.projectObj.projectOrder[0].classification = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].projectType == undefined) {
          this.projectObj.projectOrder[0].projectType = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].strategicContribution == undefined) {
          this.projectObj.projectOrder[0].strategicContribution = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].profitability == undefined) {
          this.projectObj.projectOrder[0].profitability = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].feasibility == undefined) {
          this.projectObj.projectOrder[0].feasibility = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].mandatoryProject == undefined) {
          this.projectObj.projectOrder[0].mandatoryProject = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].itPlatform == undefined) {
          this.projectObj.projectOrder[0].itPlatform = [];
        }
        if (this.projectObj.projectOrder[0].itRelevant == undefined) {
          this.projectObj.projectOrder[0].itRelevant = {
            id: ''
          }
        }
        if (this.projectObj.projectOrder[0].portfolio == undefined) {
          this.projectObj.projectOrder[0].portfolio = {
            id: ''
          }
        }
        if (this.projectObj.projectOrder[0].subPortfolio == undefined) {
          this.projectObj.projectOrder[0].subPortfolio = {
            id: ''
          };
        }
        if (this.projectObj.projectOrder[0].projectMethodology == undefined) {
          this.projectObj.projectOrder[0].projectMethodology = "";
        }

        this.formObject.id = this.projectObj.projectOrder[0].id;
        this.formObject.projectName = this.projectObj.projectName;
        this.formObject.purpose = this.projectObj.projectOrder[0].purpose;
        // this.formObject.projectManager = this.projectObj.projectOrder[0].projectManager;
        this.formObject.projectManager = response.project.user;
        this.formObject.projectSponsor = this.projectObj.projectOrder[0].projectSponsor;
        this.formObject.purpose = this.projectObj.projectOrder[0].purpose;
        this.formObject.startDate = this.projectObj.projectOrder[0].startDate;

        if (this.projectObj.isPhase) {
          let sepIndex = this.projectObj.projectName.lastIndexOf("-");
          this.projectSuffix = this.projectObj.projectName.substring(sepIndex);
          this.formObject.projectName = this.projectObj.projectName.substring(0, sepIndex);
        }

        if (this.formObject.startDate != undefined && this.formObject.startDate != "" && this.formObject.startDate != "") {
          this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
          this.formObject.endDate = this.minimumDate;
        }

        this.formObject.endDate = this.projectObj.projectOrder[0].endDate;
        this.formObject.currentSituation = this.projectObj.projectOrder[0].currentSituation;
        this.formObject.goals = this.projectObj.projectOrder[0].goals;
        this.formObject.deliverables = this.projectObj.projectOrder[0].deliverables;
        this.formObject.outOfScope = this.projectObj.projectOrder[0].outOfScope;
        this.formObject.communicationTable = this.projectObj.projectOrder[0].communicationTable;
        this.formObject.risks = this.projectObj.projectOrder[0].risks;
        this.formObject.quantitativeBenefit = this.projectObj.projectOrder[0].quantitativeBenefit;
        this.formObject.kpisTable = this.projectObj.projectOrder[0].kpisTable;
        this.formObject.costTypeTable = this.projectObj.projectOrder[0].costTypeTable;
        this.formObject.involvedPartnersTable = this.projectObj.projectOrder[0].involvedPartnersTable;
        this.formObject.mandatoryProject = this.projectObj.projectOrder[0].mandatoryProject;
        this.formObject.itPlatform = this.projectObj.projectOrder[0].itPlatform;
        this.formObject.itRelevant = this.projectObj.projectOrder[0].itRelevant;
        this.formObject.portfolio = this.projectObj.projectOrder[0].portfolio;
        this.formObject.subPortfolio = this.projectObj.projectOrder[0].subPortfolio;
        this.formObject.businessUnit = this.projectObj.projectOrder[0].businessUnit;
        this.formObject.businessArea = this.projectObj.projectOrder[0].businessArea;
        this.formObject.businessSegment = this.projectObj.projectOrder[0].businessSegment;
        this.formObject.reportingLevel = this.projectObj.projectOrder[0].reportingLevel;
        this.formObject.classification = this.projectObj.projectOrder[0].classification;
        this.formObject.projectType = this.projectObj.projectOrder[0].projectType;
        this.formObject.program = this.projectObj.projectOrder[0].program;
        this.formObject.strategicContribution = this.projectObj.projectOrder[0].strategicContribution;
        this.formObject.feasibility = this.projectObj.projectOrder[0].feasibility;
        this.formObject.profitability = this.projectObj.projectOrder[0].profitability;
        this.formObject.projectMethodology = this.projectObj.projectOrder[0].projectMethodology;
        this.formObject.confidential = this.projectObj.projectOrder[0].confidential;
        this.formObject.additionalInformation = this.projectObj.projectOrder[0].additionalInformation;
        this.formObject.pmoOfficer = this.projectObj.projectOrder[0].pmoOfficer;
        this.formObject.projectFico = this.projectObj.projectOrder[0].projectFico;
        this.formObject.pmoFormalControl = this.projectObj.projectOrder[0].pmoFormalControl;
        this.formObject.projectSponsorApproval = this.projectObj.projectOrder[0].projectSponsorApproval;
        this.formObject.projectFicoApproval = this.projectObj.projectOrder[0].projectFicoApproval;
        this.formObject.stakeholders = this.projectObj.projectOrder[0].stakeholders;
        this.formObject.marketPotential = this.projectObj.projectOrder[0].marketPotential;
        this.formObject.budgeted = this.projectObj.projectOrder[0].budgeted;
        this.formObject.comments = this.projectObj.projectOrder[0].comments;
        this.mileStonesValues = this.projectObj.projectOrder[0].mileStonesValues;
        this.formObject.currency = this.projectObj.projectOrder[0].currency;
        this.formObject.question = this.projectObj.projectOrder[0].question;
        this.formObject.attachments = this.projectObj.projectOrder[0].attachments;
        this.formObject.impactedByDependenciesTable = this.projectObj.projectOrder[0].impactedByDependenciesTable;
        this.formObject.communicationTable = this.projectObj.projectOrder[0].communicationTable;
        setTimeout(()=> {
          this.formObject.portfolioQuestions = this.projectObj.projectOrder[0].portfolioQuestions;
        }, 1000)

        let dependenciesProjectsIds = [];
        for (let i = 0; i < 5; i++) {
          if (this.formObject.impactedByDependenciesTable[i].project != "") {
            dependenciesProjectsIds.push(this.formObject.impactedByDependenciesTable[i].project);
          }
        }
        this.socketService.emitEvent('getSelectiveProjects', { ids: dependenciesProjectsIds });

        if (this.projectObj.projectOrder[0].trippleConstraint != undefined) {
          this.trippleConstraint = this.projectObj.projectOrder[0].trippleConstraint;
        }

        if (this.projectObj.projectOrder[0].projectOrganizationChart != undefined) {
          this.projectOrganizationChartData = this.projectObj.projectOrder[0].projectOrganizationChart;
        }
        this.isChartLoaded = true;

        let attach1: any = document.getElementById("attact1-span");
        let attach2: any = document.getElementById("attact2-span");
        if (
          this.projectObj.projectOrder[0].attachments[0].originalName != undefined) {
          attach1.value = this.projectObj.projectOrder[0].attachments[0].originalName;
          this.attachment1 = this.projectObj.projectOrder[0].attachments[0];
        }
        if (this.projectObj.projectOrder[0].attachments[1].originalName != undefined) {
          attach2.value = this.projectObj.projectOrder[0].attachments[1].originalName;
          this.attachment2 = this.projectObj.projectOrder[0].attachments[1];
        }
        if (this.formObject.projectSponsor && this.formObject.projectSponsor.email != '') {
          this.projSponsorEmail = this.formObject.projectSponsor.email;
        }
        if (this.formObject.projectFico && this.formObject.projectFico.email != '') {
          this.projFicoEmail = this.formObject.projectFico.email;
        }
        this.updateChart();
        this.spinner.hide();
      }
    });

    this.socketService.listen('activePortfolios').subscribe(response => {
      this.portfolioList = response;
    });

    this.initProjectListeners();
    this.fetchPMOs();
    this.fetchUsers();
    this.getProjectInfo();
    this.fetchPrograms();

    this.formObject.impactedByDependenciesTable = [
      {
        id: 1,
        description: "",
        impact: "",
        project: ''
      },
      {
        id: 2,
        description: "",
        impact: "",
        project: ''
      },
      {
        id: 3,
        description: "",
        impact: "",
        project: ''
      },
      {
        id: 4,
        description: "",
        impact: "",
        project: ''
      },
      {
        id: 5,
        description: "",
        impact: "",
        project: ''
      }
    ];

    this.mileStonesValues = [
      {
        id: 1,
        name: "",
        description: "",
        dueDate: '',
        report: false
      },
      {
        id: 2,
        name: "",
        description: "",
        dueDate: '',
        report: false
      },
      {
        id: 3,
        name: "",
        description: "",
        dueDate: '',
        report: false
      },
      {
        id: 4,
        name: "",
        description: "",
        dueDate: '',
        report: false
      },
      {
        id: 5,
        name: "",
        description: "",
        dueDate: '',
        report: false
      }
    ];

    this.formObject.communicationTable = [
      {
        information: "",
        stakeholder: ""
      },
      {
        information: "",
        stakeholder: ""
      },
      {
        information: "",
        stakeholder: ""
      },
      {
        information: "",
        stakeholder: ""
      },
      {
        information: "",
        stakeholder: ""
      }
    ];

    this.formObject.risks = [
      {
        id: 1,
        name: '',
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 2,
        name: '',
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 3,
        name: '',
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 4,
        name: '',
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 5,
        name: '',
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }];

    this.risksTopFiveReputationRiskSelectOptions = [
      {
        reputationRisks: 0
      },
      {
        reputationRisks: 1
      },
      {
        reputationRisks: 2
      },
      {
        reputationRisks: 3
      },
      {
        reputationRisks: 4
      }
    ];

    this.formObject.kpisTable = [
      {
        description: "Cost Savings",
        value: "",
        type: "Per Year"
      },
      {
        description: "NPV",
        value: "",
        type: ""
      },
      {
        description: "Add.Revenue",
        value: "",
        type: "Per Year"
      },
      {
        description: "Payback duration (Discounted)",
        value: "",
        type: "Years"
      },
      {
        description: "IRR",
        value: "",
        type: "%"
      }
    ];

    this.formObject.costTypeTable = [
      {
        costTypes: "External Costs",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "Internal Costs",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "CAPEX",
      },
      {
        costTypes: "External Costs",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Internal Costs",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "OPEX",
      },
      {
        costTypes: "Revenues",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Reserve",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "Sonstiges",
      },
      {
        costTypes: "Total",
        current: "",
        next: "",
        reset: "",
        budget: "",
        group: "Sonstiges",
      }
    ];

    this.formObject.involvedPartnersTable = [
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      },
      {
        externalPartners: "",
        totalManDays: "",
        releasedManDays: ""
      }
    ];

    this.formObject.goals = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.deliverables = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.outOfScope = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.quantitativeBenefit = [
      {
        id: 1,
        description: ''
      },
      {
        id: 2,
        description: ''
      },
      {
        id: 3,
        description: ''
      },
      {
        id: 4,
        description: ''
      },
      {
        id: 5,
        description: ''
      },
    ];

    this.formObject.question = ['', '', '', '', '', '', '', '', ''];

    this.dependenciesForm = {
      description: '',
      impact: '',
      project: ''
    };

    this.dropdownConfig = {
      displayKey: "projectName",
      search: true,
      height: 'auto',
      placeholder: 'Search',
      noResultsFound: 'No results found!',
      searchPlaceholder: 'Search',
      searchOnKey: 'projectName',
      clearOnSelection: false,
      inputDirection: 'ltr',
    };
  }

  ngOnInit() {
    this.spinner.show();
    this.projectManagerInOptions = ["PMO", "Sponsor", "FIKO"];
    this.pmoFormalControlOptions = ["PMO", "Sponsor", "FIKO"];
    this.projectClientInOptions = ["PMO", "Sponsor", "FIKO"];
    this.radarChartOptions = {
      color: "blue"
    };
    this.currencyOptions = ['CHF', 'EUR', 'USD'];
    this.questionOptions = ["Yes", "No"];
    this.projectManagerInOptions = ["PMO", "Customer", "FIKO"];
    this.confidentialOptions = ['Yes', 'No'];
    this.mandatoryProjectOptions = this.utilityService.mandatoryProjectOptions;
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.businessSegmentOptions = this.utilityService.businessSegmentOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.projectTypeOptions = this.utilityService.projectTypeOptions;
    this.strategicContributionOptions = this.utilityService.strategicContributionOptions;
    this.feasibilityOptions = this.utilityService.feasibilityOptions;
    this.profitabilityOptions = this.utilityService.profitabilityOptions;
    this.risksTopFiveRiskStrategySelectOptions = this.utilityService.risksTopFiveRiskStrategySelectOptions;
    this.benefitTypeSelectOptions = ['Quantitative', 'Qualitative'];
    this.dependenciesImpactColumnOptions = this.utilityService.impactOptions;
    this.itPlatformOptions = this.utilityService.itPlatformOptions;
    this.itRelevantOptions = this.utilityService.itRelevantOptions;
    this.projectMethodologyOptions = this.utilityService.projectMethodologyOptions;

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.socketService.listen('questionsByFormName').subscribe(response => {
      for (let i = 0; i < response.questions.length; i++) {
        if (response.questions[i].department == 'Compliance') {
          this.orderQuestions[0] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling' && response.questions[i].count == 1) {
          this.orderQuestions[1] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling' && response.questions[i].count == 2) {
          this.orderQuestions[2] = response.questions[i];
        }
        if (response.questions[i].department == 'Controlling' && response.questions[i].count == 3) {
          this.orderQuestions[3] = response.questions[i];
        }
        if (response.questions[i].department == 'Human Resources') {
          this.orderQuestions[4] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 1) {
          this.orderQuestions[5] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 2) {
          this.orderQuestions[6] = response.questions[i];
        }
        if (response.questions[i].department == 'ICT' && response.questions[i].count == 3) {
          this.orderQuestions[7] = response.questions[i];
        }
        if (response.questions[i].department == 'Procurement') {
          this.orderQuestions[8] = response.questions[i];
        }
      }
    });

    this.socketService.listen('helpGuideByFormName').subscribe(response => {
      this.orderHelpGuide = response.fields;
    });

    this.getOrderQuestions();
    this.getOrderHelpGuide();
    //this.fetchProjectsList();
  }

  ngAfterViewInit() {
    // this.diagram = new dhx.Diagram("cont", { type: "org", defaultShapeType: "svg-card" });
    // this.editor = new dhx.DiagramEditor("editor_wrapper", { type: "org", shapeType: "svg-card" });
    // this.diagram.data.parse(this.projectOrganizationChartData);

    // this.editorCont = document.querySelector("#editor_wrapper");
    // this.diagramCont = document.querySelector("#diagram_wrapper");
    // this.editorCont.style.display = "none";

    // setTimeout(() => {
    //   $(this.diagramCont).find('.dhx_content_title').css('font-size', '12px');
    // }, 100);
  }

  // runEditor() {
  //   this.diagramCont.style.display = "none";
  //   this.editorCont.style.display = "block";
  //   // this.editor.import(this.diagram);

  //   this.editor.events.on("ApplyButton", function () {
  //     globalThis.diagramCont.style.display = "block";
  //     globalThis.editorCont.style.display = "none";
  //     globalThis.diagram.data.parse(globalThis.editor.serialize());
  //   });
  //   this.editor.events.on("ResetButton", function () {
  //     globalThis.diagramCont.style.display = "block";
  //     globalThis.editorCont.style.display = "none";
  //   });
  // }

  getOrderQuestions(): void {
    this.socketService.emitEvent('questionsByFormName', { formName: 'order' });
  }

  getOrderHelpGuide(): void {
    this.socketService.emitEvent('helpGuideByFormName', { formName: 'order' });
  }

  fetchProjectsList() {
    this.apiService.get('/projects/getRecentActiveProjects').subscribe((response: any) => {
      this.dependenciesProjectColumnOptions = response;
      this.dependenciesProjectColumnOptions.unshift({ id: 0, projectName: "None" });
    });
  }

  // deleteNodeRecusive(id) {
  // for (let i = 0; i < globalThis.projectOrganizationChartData.length; i++) {
  //   if (globalThis.projectOrganizationChartData[i].parent == id) {
  //     let data = globalOrgChart.getData();
  //     globalThis.deleteNodeRecusive(
  //       globalThis.projectOrganizationChartData[i].id
  //     );
  //     // globalOrgChart.projectOrganizationChartData.splice(globalThis.projectOrganizationChartData[i].id, 1);
  //   }
  // }
  // }

  fetchPrograms() {
    this.socketService.emitEvent('activePrograms', {});
  }

  public steps = [
    {
      key: "step1",
      title: "Description",
      valid: false,
      checked: false,
      submitted: false
    },
    {
      key: "step2",
      title: "Benefits & Resources",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step3",
      title: "Risks",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step4",
      title: "Milestones",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step5",
      title: "Project Organisation",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step6",
      title: "Categories",
      valid: true,
      checked: false,
      submitted: false
    },
    {
      key: "step7",
      title: "Submit Request",
      valid: true,
      checked: false,
      submitted: false
    }
  ];

  public activeStep = this.steps[0];

  setActiveStep(step) {
    this.activeStep = step;
  }

  prevStep() {
    let idx = this.steps.indexOf(this.activeStep);
    if (idx > 0) {
      this.activeStep = this.steps[idx - 1];
    }

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  nextStep() {
    this.activeStep = this.steps[this.steps.indexOf(this.activeStep) + 1];

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  // custom change detection
  ngDoCheck() {
  }

  fetchPMOs() {
    if (this.utilityService.PMOsList.length == 0) {
      this.socketService.emitEvent('usersFromRoles', { role: 'PMO' });
    } else {
      this.PMOsList = this.utilityService.PMOsList;
    }
  }

  fetchUsers() {
    this.usersList = this.utilityService.usersList;
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      if (this.utilityService.isTeamProject == true) {
        this.isTeamProject = true;
        this.utilityService.isTeamProject = false;
      }
      this.socketService.emitEvent('activePortfolios', {});
      this.socketService.emitEvent('fetchProjectOrder', { id: params.id });
    });
  }

  onEditorPreparingGoalsTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";
    }
  }

  onEditorPreparingDeliverablesTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";
    }
  }

  onEditorPreparingOutOfScopeTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";
    }
  }

  onEditorPreparingCommunicationTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "information") {
      e.editorOptions.maxLength = 20;
      e.editorOptions.placeholder = this.localize.transform("Information");
    }
    if (e.parentType == "dataRow" && e.dataField == "stakeholder") {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = this.localize.transform("Stakeholders");
    }
  }

  onEditorPreparingKPIsTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "value") {
      e.editorOptions.placeholder = this.localize.transform("0.00");
      if (e.row.data.description == "IRR") {
        e.editorOptions.placeholder = this.localize.transform("Rate Return %");
      } else if (e.row.data.description == "Payback Duration") {
        e.editorOptions.placeholder = this.localize.transform("Payback Duration");
      }
    }
  }

  onEditorPreparinginvolvedPartnerTable(e) {
    if (e.parentType == "dataRow" && e.dataField == "externalPartners") {
      e.editorOptions.placeholder = this.localize.transform("Involved Partners");
      e.editorOptions.maxLength = 20;
    }
    if (e.parentType == "dataRow" && e.dataField != "externalPartners") {
      e.editorOptions.placeholder = this.localize.transform("Person-Days");
    }
  }

  onEditorPreparingProjectCostTable(e) {
    if (e.parentType == "dataRow") {
      e.editorOptions.placeholder = "0.00";
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateResourcesTotalValue(e) {
    if (e.costTypes == "Total") {
      globalThis.formObject.costTypeTable[4].total =
        globalThis.projectCostsValueCalculator("budget") +
        globalThis.projectCostsValueCalculator("opex");
      globalThis.formObject.costTypeTable[4].opex = globalThis.projectCostsValueCalculator(
        "opex"
      );
      globalThis.formObject.costTypeTable[4].capex = globalThis.projectCostsValueCalculator(
        "capex"
      );
      return globalThis.formObject.costTypeTable[4].total;
    } else if (e.costTypes == "Revenues") {
      globalThis.formObject.costTypeTable[2].total =
        Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
      return Math.abs(e.capex) * -1 + Math.abs(e.opex) * -1;
    } else {
      if (e.costTypes == "External Costs") {
        globalThis.formObject.costTypeTable[0].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Internal Costs") {
        globalThis.formObject.costTypeTable[1].total =
          e.capex * 1 + e.opex * 1;
      } else if (e.costTypes == "Reserve") {
        globalThis.formObject.costTypeTable[3].total =
          e.capex * 1 + e.opex * 1;
      }
      return e.capex * 1 + e.opex * 1;
    }
  }

  calculateRiskExposureValue(e) {
    if (
      typeof e.extentOfLosses == "number" &&
      typeof e.probability == "number"
    ) {
      globalThis.formObject.risks.find(risk => risk.id == e.id).riskExposure =
        e.extentOfLosses * e.probability / 100;
      return globalThis.formObject.risks.find(risk => risk.id == e.id).riskExposure;
    } else {
      return "";
    }
  }

  projectCostsValueCalculator(field) {
    let one: any = globalThis.formObject.costTypeTable[0][field] * 1;
    let two: any = globalThis.formObject.costTypeTable[1][field] * 1;
    let three: any = globalThis.formObject.costTypeTable[2][field] * 1;
    let four: any = globalThis.formObject.costTypeTable[3][field] * 1;
    let five: any = globalThis.formObject.costTypeTable[4][field] * 1;
    let six: any = globalThis.formObject.costTypeTable[5][field] * 1;
    let total = one + two + three + four - Math.abs(five) + six;
    globalThis.formObject.costTypeTable[6][field] = total;
    return total;
  }

  projectBudgetCostsValueCalculator(field) {
    if (field == 'Revenues') {
      globalThis.formObject.costTypeTable.forEach(costTable => {
        let current = +costTable.current < 0 ? +costTable.current * -1 : +costTable.current;
        let next = +costTable.next < 0 ? +costTable.next * -1 : +costTable.next;
        let reset = +costTable.reset < 0 ? +costTable.reset * -1 : +costTable.reset;
        costTable.budget = current + next + reset;
      });
    } else {
      globalThis.formObject.costTypeTable.forEach(costTable => {
        costTable.budget = +costTable.current + +costTable.next + +costTable.reset;
      });
    }
  }

  calculateResourcesTotalValueForCapex(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("budget");
      }
    } else if (e.costTypes == "Revenues") {
      return e.budget ? Math.abs(e.budget) * -1 : "";
    } else {
      return e.budget;
    }
  }

  calculateResourcesTotalValueForCapexCurrent(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("current");
      }
    } else if (e.costTypes == "Revenues") {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('Revenues');
      }
      return e.current ? Math.abs(e.current) * -1 : "";
    } else {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('');
      }
      return e.current;
    }
  }

  calculateResourcesTotalValueForCapexNext(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("next");
      }
    } else if (e.costTypes == "Revenues") {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('Revenues');
      }
      return e.next ? Math.abs(e.next) * -1 : "";
    } else {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('');
      }
      return e.next;
    }
  }

  calculateResourcesTotalValueForCapexReset(e) {
    if (e.costTypes == "Total") {
      if (globalThis.formObject) {
        return globalThis.projectCostsValueCalculator("reset");
      }
    } else if (e.costTypes == "Revenues") {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('Revenues');
      }
      return e.reset ? Math.abs(e.reset) * -1 : "";
    } else {
      if (globalThis.formObject) {
        globalThis.projectBudgetCostsValueCalculator('');
      }
      return e.reset;
    }
  }

  onEditorPreparingRisks(e) {
    if (e.parentType == "dataRow" && e.dataField == "id") {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = "Enter your description";
    }
    if (e.parentType == "dataRow" && e.dataField == "probability") {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = "%";
    }
    if (e.parentType == "dataRow" && e.dataField == "extentOfLosses") {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = "CHF";
    }
    if (e.parentType == "dataRow" && e.dataField == "riskExposure") {
      e.editorOptions.maxLength = 4;
    }
  }

  // Chart events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  updateChart() {
    if (
      this.trippleConstraint.cost < 11 && this.trippleConstraint.cost > 0 && this.trippleConstraint.time > 0 && this.trippleConstraint.time < 11 &&
      this.trippleConstraint.scope > 0 && this.trippleConstraint.scope < 11) {
      this.formObject.radarChartData[0].data[1] = this.trippleConstraint.cost;
      this.formObject.radarChartData[0].data[0] = this.trippleConstraint.time;
      this.formObject.radarChartData[0].data[2] = this.trippleConstraint.scope;
      this.renderChart = false;
      setTimeout(() => {
        this.renderChart = true;
      }, 0);
    } else {
      this.notificationBox("Warning", "Values must be between 1-10.", "warning");
    }

    this.spinner.hide();
  }

  descriptionValuechange(e: any, data: any): void {
    this.dataGrid.instance.cellValue(data.rowIndex, data.column.dataField, data.value);
    this.dataGrid.instance.saveEditData();
  }

  onEditorPreparingDependenciesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'Description') {
      e.editorOptions.maxLength = 150;
    }
  }

  dependenciesRowClick(e) {
    this.openDependenciesEditDialog(this.dependenciesPopUpTemplate, e.key);
  }

  openDependenciesEditDialog(template, obj) {
    obj.project = this.dependenciesProjectColumnOptions.find(val => val.id == obj.project);
    this.dependenciesForm = JSON.parse(JSON.stringify(obj));
    this.modalRef = this.modalService.show(template, this.config);
  }

  editDependencies() {
    let obj = JSON.parse(JSON.stringify(this.dependenciesForm));
    obj.project = obj.project ? obj.project.id : '';
    this.formObject.impactedByDependenciesTable[this.dependenciesForm.id - 1] = obj;
    this.modalRef.hide();
  }

  resetDependencies(obj) {
    let dependencyObj = this.formObject.impactedByDependenciesTable.find(val => val.id == obj.id);
    dependencyObj.description = '';
    dependencyObj.impact = '';
    dependencyObj.project = '';
  }

  resetRisk(obj) {
    let riskObj = this.formObject.risks.find(val => val.id == obj.id);
    riskObj.name = '';
    riskObj.report = false;
    riskObj.riskStrategy = { id: '' };
    riskObj.description = '';
    riskObj.measures = '';
    riskObj.extentOfLosses = null;
    riskObj.probability = null;
    riskObj.riskExposure = null;
  }

  closeRiskModal() {
    this.riskForm.name = this.backupForm.name;
    this.riskForm.report = this.backupForm.report;
    this.riskForm.riskStrategy = this.backupForm.riskStrategy;
    this.riskForm.description = this.backupForm.description;
    this.riskForm.measures = this.backupForm.measures;
    this.riskForm.extentOfLosses = this.backupForm.extentOfLosses;
    this.riskForm.probability = this.backupForm.probability;
    this.riskForm.riskExposure = this.backupForm.riskExposure;
    this.modalRef.hide();
  }

  submit() {
    this.submitFlag = true;
    this.eventType = "submit";

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.showNotificationBox('Step 1: Please Enter Project Name First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.purpose == '' || this.formObject.purpose == undefined) {
      this.showNotificationBox('Step 1: Please Enter Purpose First.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.showNotificationBox('Step 1: Please Select a Project Manager First.');
      this.submitFlag = false;
      return;
    }
    if (!this.isStartDateValid) {
      this.showNotificationBox('Step 1: Please enter start date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.startDate == undefined) {
      this.showNotificationBox('Step 1: Please select a start date first.');
      this.submitFlag = false;
      return;
    }
    if (!this.isEndDateValid) {
      this.showNotificationBox('Step 1: Please enter end date in correct format (dd.MM.yyyy) first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.endDate == undefined) {
      this.showNotificationBox('Step 1: Please select a end date first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.currentSituation == "" || this.formObject.currentSituation == undefined) {
      this.showNotificationBox('Step 1: Please fill current situation first.');
      this.submitFlag = false;
      return;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter goals first.');
      this.submitFlag = false;
      return;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter deliverables first.');
      this.submitFlag = false;
      return;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter out of scope first.');
      this.submitFlag = false;
      return;
    }
    let communicationCheck = this.formObject.communicationTable.filter(val => val.information.length > 0);
    if (communicationCheck.length == 0) {
      this.showNotificationBox('Step 1: Please enter a communication first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.showNotificationBox('Step 2: Please select a currency first.');
      this.submitFlag = false;
      return;
    }
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
    if (quantativeBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter qualitative benefits first.');
      this.submitFlag = false;
      return;
    }
    let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
    if (qualitativeBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter quantitative benefits first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.budgeted == undefined || this.formObject.budgeted == "") {
      this.showNotificationBox('Step 2: Please enter budgeted first.')
      this.submitFlag = false;
      return;
    }
    if (this.formObject.costTypeTable[6].budget == 0) {
      this.showNotificationBox('Step 2: Please fill project costs.');
      this.submitFlag = false;
      return;
    }
    let involvedPartnersBenefitsCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
    if (involvedPartnersBenefitsCheck.length == 0) {
      this.showNotificationBox('Step 2: Please enter involved partners first.');
      this.submitFlag = false;
      return;
    }
    let risksCheck = this.formObject.risks.filter(val => val.description.length > 0 && val.name.length > 0
      && val.riskStrategy.name != undefined && val.measures.length > 0 && val.extentOfLosses != '' && val.probability != '');
    if (risksCheck.length == 0) {
      this.showNotificationBox('Step 3: Please enter a risk first.');
      this.submitFlag = false;
      return;
    }
    let impactedByDependenciesCheck = this.formObject.impactedByDependenciesTable.filter(val => val.description.length > 0);
    if (impactedByDependenciesCheck.length == 0) {
      this.showNotificationBox('Step 3: Please enter a dependencies first.');
      this.submitFlag = false;
      return;
    }
    let mileStonesCheck = this.mileStonesValues.filter(val => val.description.length > 0 && val.name.length > 0 && val.dueDate != "");
    if (mileStonesCheck.length == 0) {
      this.showNotificationBox('Step 4: Please enter a milestone first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.mandatoryProject == undefined || this.formObject.mandatoryProject.id == "") {
      this.showNotificationBox('Step 6: Please select mandatory project first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.portfolio == undefined || this.formObject.portfolio.id == "") {
      this.showNotificationBox('Step 6: Please select portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.subPortfolio == undefined || this.formObject.subPortfolio.id == "") {
      this.showNotificationBox('step 6: Please select sub-portfolio first');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessUnit == undefined || this.formObject.businessUnit.id == "") {
      this.showNotificationBox('Step 6: Please select business unit first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessArea == undefined || this.formObject.businessArea.id == "") {
      this.showNotificationBox('Step 6: Please select business area first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.businessSegment == undefined || this.formObject.businessSegment.id == "") {
      this.showNotificationBox('Step 6: Please select business segment first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.reportingLevel == undefined || this.formObject.reportingLevel.id == "") {
      this.showNotificationBox('Step 6: Please select reporting level first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.classification == undefined || this.formObject.classification.id == "") {
      this.showNotificationBox('Step 6: Please select classification first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectType == undefined || this.formObject.projectType.id == "") {
      this.showNotificationBox('Step 6: Please select project type first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.program != undefined) {
      if (this.formObject.program.programName != "None") {
        if (this.formObject.program.id == "") {
          this.showNotificationBox('Step 6: Please select program first.');
          this.submitFlag = false;
          return;
        }
      }
    } else {
      this.showNotificationBox('Step 6: Please select program first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.strategicContribution == undefined || this.formObject.strategicContribution.id == "") {
      this.showNotificationBox('Step 6: Please select strategic contribution first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.feasibility == undefined || this.formObject.feasibility.id == "") {
      this.showNotificationBox('Step 6: Please select feasibility first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.profitability == undefined || this.formObject.profitability.id == "") {
      this.showNotificationBox('Step 6: Please select profitability first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.itRelevant == undefined || this.formObject.itRelevant.id == "") {
      this.showNotificationBox('step 6: Please select IT Relevant');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.showNotificationBox('step 6: Please select IT platform');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectMethodology == undefined || this.formObject.projectMethodology.id == "") {
      this.showNotificationBox('step 6: Please select Project Methodology.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.showNotificationBox('step 6: Please select confidential option.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.portfolioQuestions && this.formObject.portfolioQuestions.length > 0) {
      for (let i = 0; i < this.formObject.portfolioQuestions.length; i++) {
        if (this.formObject.portfolioQuestions[i] == "") {
          this.showNotificationBox('Step 7: Please fill all questions first.');
          this.submitFlag = false;
          return;
        }
      }
    }
    if (this.formObject.additionalInformation == undefined || this.formObject.additionalInformation == "") {
      this.showNotificationBox('Step 7: Please fill additional information first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[0] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[1] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[2] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[3] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[4] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[5] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[6] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[7] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.question[8] == "") {
      this.showNotificationBox('Step 7: Please fill all questions first.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.showNotificationBox('Step 7: Please Select a PMO First.');
      this.submitFlag = false;
      return;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.showNotificationBox('Step 7: Please enter a valid email address for project sponsor.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.showNotificationBox('Step 7: Please Select a Project Sponsor First.');
      this.submitFlag = false;
      return;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.showNotificationBox('Step 7: Please enter a valid email address for project fico.');
      this.submitFlag = false;
      return;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.showNotificationBox('Step 7: Please Select a Project Fico First.');
      this.submitFlag = false;
      return;
    }

    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("submit");
    } else {
      this.submitHelper();
    }
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  submitHelper() {
    this.spinner.show();

    if (this.projectObj.isPhase) {
      this.formObject.projectName = this.formObject.projectName + this.projectSuffix;
    }

    let obj = {
      orderSubmitted: true,
      isOrderFreezed: true,
      docType: "Order",
      status: "Submitted",
      workflowStatus: "Order has been sent to PMO for approval.",
      projectName: this.formObject.projectName,
      subPortfolio: this.formObject.subPortfolio,
      endDate: this.formObject.endDate,
      isPMOApprovedOrder: false,
      isSponsorApprovedOrder: false,
      projectOrder: {
        id: this.projectObj.projectOrder.length != 0 ? this.projectObj.projectOrder[0].id : undefined,
        version: this.projectObj.projectOrder.length == 0 ? 1 : this.projectObj.projectOrder[0].version + 1,
        status: "Submitted",
        projectName: this.formObject.projectName,
        purpose: this.formObject.purpose,
        projectManager: this.formObject.projectManager,
        projectSponsor: this.formObject.projectSponsor,
        projectFico: this.formObject.projectFico,
        startDate: this.formObject.startDate,
        endDate: this.formObject.endDate,
        date: new Date().toISOString(),
        currentSituation: this.formObject.currentSituation,
        goals: this.formObject.goals,
        deliverables: this.formObject.deliverables,
        outOfScope: this.formObject.outOfScope,
        impactedByDependenciesTable: this.formObject.impactedByDependenciesTable,
        communicationTable: this.formObject.communicationTable,
        stakeholders: this.formObject.stakeholders,
        risks: this.formObject.risks,
        quantitativeBenefit: this.formObject.quantitativeBenefit,
        kpisTable: this.formObject.kpisTable,
        mileStonesValues: this.mileStonesValues,
        projectOrganizationChart: this.diagram.export(),
        budgeted: this.formObject.budgeted,
        costTypeTable: this.formObject.costTypeTable,
        involvedPartnersTable: this.formObject.involvedPartnersTable,
        mandatoryProject: this.formObject.mandatoryProject,
        itPlatform: this.getItPlatform(),    // this.formObject.itPlatform,
        itRelevant: this.formObject.itRelevant,
        portfolio: this.formObject.portfolio,
        subPortfolio: this.formObject.subPortfolio,
        businessUnit: this.formObject.businessUnit,
        businessArea: this.formObject.businessArea,
        businessSegment: this.formObject.businessSegment,
        reportingLevel: this.formObject.reportingLevel,
        classification: this.formObject.classification,
        projectType: this.formObject.projectType,
        program: this.formObject.program,
        strategicContribution: this.formObject.strategicContribution,
        feasibility: this.formObject.feasibility,
        profitability: this.formObject.profitability,
        projectMethodology: this.formObject.projectMethodology,
        confidential: this.formObject.confidential,
        additionalInformation: this.formObject.additionalInformation,
        pmoOfficer: this.formObject.pmoOfficer,
        trippleConstraint: this.trippleConstraint,
        currency: this.formObject.currency,
        question: this.formObject.question,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : ""
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added
      }
    };
    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0) {
      obj.projectOrder['portfolioQuestions'] = this.formObject.portfolioQuestions;
    }
    this.formObject.question = this.formObject.question.map(val => this.localize.transform(val));
    let questionsHtml: string = this.utilityService.getOrderQuestionsHtml(this.orderQuestions, this.formObject.question);
    let portfolioQuestionsHtml: string = this.getPortfolioQuestionsHTML();

    if (obj.projectOrder.id == undefined) {
      this.apiService.post("/project/order/approval", {
        projectId: this.projectObj.id,
        obj: obj,
        formObject: this.formObject
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/orderPreview/${response.id}" target="_blank">${'Projektauftrag / Project Order : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/order/${response.id}" target="_blank">${'Projektauftrag / Project Order : ' + this.formObject.projectName}</a>` + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Order has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    } else {
      let order = obj.projectOrder;
      delete obj.projectOrder;

      this.apiService.put("/project/order/approval", {
        projectId: this.projectObj.id,
        obj: obj,
        formObject: this.formObject,
        order: order
      }).subscribe((response: any) => {
        this.socketService.emitEvent('sendEmail', {
          email: response.pmoOfficer.email,
          subject: "oneView: Projekt Genehmigungsworkflow",
          message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Outline Approval')[0].text + ` <br>
          <a href="${this.apiService.FrontEndPATH}/orderPreview/${response.id}" target="_blank">${'Projektauftrag / Project Order : ' + this.formObject.projectName}</a>` + questionsHtml
        });

        //Send Notifications to Assigned emails in subportfolio
        if (this.formObject.subPortfolio && this.formObject.subPortfolio.projectNotificationEmails && this.formObject.subPortfolio.projectNotificationEmails.length > 0) {
          let notificationEmails = [];
          if (this.formObject.itRelevant.name == 'No') {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.filter(item => item.isITRelevant != true).map(item => item.email);
          } else {
            notificationEmails = this.formObject.subPortfolio.projectNotificationEmails.map(item => item.email);
          }

          if (notificationEmails.length > 0) {
            this.socketService.emitEvent('sendEmail', {
              email: notificationEmails,
              subject: "oneView Projekt-Notification: Neue Projektaktivität",
              message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Submission Notification')[0].text + ` <br>
               <a href="${this.apiService.FrontEndPATH}/view/order/${response.id}" target="_blank">${'Projektauftrag / Project Order : ' + this.formObject.projectName}</a>` + portfolioQuestionsHtml + questionsHtml
            });
          }
        }

        this.notificationBox("Success", "Order has been sent to PMO for approval.", "success");
        this.submitFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        this.utilityService.getUsersOpenOutlinesCount();
        this.router.navigate(["projects"]);
      });
    }
  }

  validateStepsOnSave() {
    this.isStepValidated = [true, true, true, true, true, true, true];

    if (this.formObject.projectName == undefined || this.formObject.projectName == "") {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.purpose == '' || this.formObject.purpose == undefined) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.projectManager == undefined || this.formObject.projectManager == "") {
      this.isStepValidated[0] = false;
    }
    if (!this.isStartDateValid) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.startDate == undefined) {
      this.isStepValidated[0] = false;
    }
    if (!this.isEndDateValid) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.endDate == undefined) {
      this.isStepValidated[0] = false;
    }
    if (this.formObject.currentSituation == "" || this.formObject.currentSituation == undefined) {
      this.isStepValidated[0] = false;
    }
    let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
    if (goalsCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
    if (deliverablesCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
    if (outOfScopeCheck.length == 0) {
      this.isStepValidated[0] = false;
    }
    let communicationCheck = this.formObject.communicationTable.filter(val => val.information.length > 0);
    if (communicationCheck.length == 0) {
      this.isStepValidated[0] = false;
    }

    //step 2 start
    if (this.formObject.currency == "" || this.formObject.currency == undefined) {
      this.isStepValidated[1] = false;
    }
    let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
    if (quantativeBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }
    let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
    if (qualitativeBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.budgeted == undefined || this.formObject.budgeted == "") {
      this.isStepValidated[1] = false;
    }
    if (this.formObject.costTypeTable[6].budget == 0) {
      this.isStepValidated[1] = false;
    }
    let involvedPartnersBenefitsCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
    if (involvedPartnersBenefitsCheck.length == 0) {
      this.isStepValidated[1] = false;
    }

    //Step 3 start
    let risksCheck = this.formObject.risks.filter(val => val.description.length > 0 && val.name.length > 0
      && val.riskStrategy.name != undefined && val.measures.length > 0 && val.extentOfLosses != '' && val.probability != '');
    if (risksCheck.length == 0) {
      this.isStepValidated[2] = false;
    }
    let impactedByDependenciesCheck = this.formObject.impactedByDependenciesTable.filter(val => val.description.length > 0);
    if (impactedByDependenciesCheck.length == 0) {
      this.isStepValidated[2] = false;
    }

    //Step 4 start
    let mileStonesCheck = this.mileStonesValues.filter(val => val.description.length > 0 && val.name.length > 0 && val.dueDate != "");
    if (mileStonesCheck.length == 0) {
      this.isStepValidated[3] = false;
    }

    //Step 6 start
    if (this.formObject.mandatoryProject == undefined || this.formObject.mandatoryProject.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.portfolio == undefined || this.formObject.portfolio.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.subPortfolio == undefined || this.formObject.subPortfolio.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.businessUnit == undefined || this.formObject.businessUnit.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.businessArea == undefined || this.formObject.businessArea.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.businessSegment == undefined || this.formObject.businessSegment.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.reportingLevel == undefined || this.formObject.reportingLevel.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.classification == undefined || this.formObject.classification.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.projectType == undefined || this.formObject.projectType.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.program != undefined) {
      if (this.formObject.program.programName != "None") {
        if (this.formObject.program.id == "") {
          this.isStepValidated[5] = false;
        }
      }
    } else {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.strategicContribution == undefined || this.formObject.strategicContribution.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.feasibility == undefined || this.formObject.feasibility.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.profitability == undefined || this.formObject.profitability.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.itRelevant == undefined || this.formObject.itRelevant.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.itPlatform == undefined || this.formObject.itPlatform.length <= 0 /*this.formObject.itPlatform.id == ""*/) {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.projectMethodology == undefined || this.formObject.projectMethodology.id == "") {
      this.isStepValidated[5] = false;
    }
    if (this.formObject.confidential == '' || this.formObject.confidential == undefined) {
      this.isStepValidated[5] = false;
    }

    //Step 7 start
    if (this.formObject.additionalInformation == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[0] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[1] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[2] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[3] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[4] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[5] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[6] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[7] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.question[8] == "") {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.pmoOfficer.id == "" || this.formObject.pmoOfficer.id == undefined) {
      this.isStepValidated[6] = false;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.formObject.projectSponsor.email) == false) {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.projectSponsor.id == "" || this.formObject.projectSponsor.id == undefined) {
      this.isStepValidated[6] = false;
    }
    if (emailRegex.test(this.formObject.projectFico.email) == false) {
      this.isStepValidated[6] = false;
    }
    if (this.formObject.projectFico.id == "" || this.formObject.projectFico.id == undefined) {
      this.isStepValidated[6] = false;
    }

  }

  getPortfolioQuestionsHTML() {
    let questionsHtml = '';

    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0 && this.groups.length > 0) {
      questionsHtml = '<br><br>';
      this.formObject.portfolioQuestions = this.formObject.portfolioQuestions.map(val => this.localize.transform(val));

      this.groups.map(department => {
        questionsHtml += `<h3>${department}</h3>`;

        this.formObject.portfolio.questions.map((vals, i) => {
          if (vals.group == department) {
            questionsHtml += `
              <p><strong>${vals.question}</strong></p>
              <p>${this.formObject.portfolioQuestions[i]}</p>
            `;
          }
        });
      })
    }

    return questionsHtml;
  }

  saveForm(e) {
    this.saveFlag = true;
    this.eventType = "save";

    this.isSavedPressed = true;
    this.validateStepsOnSave();

    if (
      this.formObject.isAttachement1Added == true ||
      this.formObject.isAttachement2Added == true
    ) {
      this.uploadAttachments("save");
    } else {
      this.saveHelper();
    }
  }

  saveHelper() {
    this.spinner.show();

    if (this.projectObj.isPhase) {
      this.formObject.projectName = this.formObject.projectName + this.projectSuffix;
    }

    let obj = {
      isOrderFreezed: false,
      status: "Draft",
      docType: "Order",
      workflowStatus: "Order has not been submitted yet.",
      projectName: this.formObject.projectName,
      subPortfolio: this.formObject.subPortfolio,
      projectOrder: {
        id: this.projectObj.projectOrder.length != 0 ? this.projectObj.projectOrder[0].id : undefined,
        version: this.projectObj.projectOrder.length == 0 ? 0 : this.projectObj.projectOrder[0].version,
        status: "Draft",
        projectName: this.formObject.projectName,
        purpose: this.formObject.purpose,
        projectManager: this.formObject.projectManager,
        projectSponsor: this.formObject.projectSponsor,
        projectFico: this.formObject.projectFico,
        startDate: this.formObject.startDate,
        endDate: this.formObject.endDate,
        // date: new Date().toISOString(),
        currentSituation: this.formObject.currentSituation,
        goals: this.formObject.goals,
        deliverables: this.formObject.deliverables,
        outOfScope: this.formObject.outOfScope,
        impactedByDependenciesTable: this.formObject.impactedByDependenciesTable,
        communicationTable: this.formObject.communicationTable,
        stakeholders: this.formObject.stakeholders,
        risks: this.formObject.risks,
        quantitativeBenefit: this.formObject.quantitativeBenefit,
        kpisTable: this.formObject.kpisTable,
        mileStonesValues: this.mileStonesValues,
        projectOrganizationChart: this.diagram.export(),
        budgeted: this.formObject.budgeted,
        costTypeTable: this.formObject.costTypeTable,
        involvedPartnersTable: this.formObject.involvedPartnersTable,
        mandatoryProject: this.formObject.mandatoryProject,
        portfolio: this.formObject.portfolio,
        subPortfolio: this.formObject.subPortfolio,
        itPlatform: this.getItPlatform(), //this.formObject.itPlatform,
        itRelevant: this.formObject.itRelevant,
        businessUnit: this.formObject.businessUnit,
        businessArea: this.formObject.businessArea,
        businessSegment: this.formObject.businessSegment,
        reportingLevel: this.formObject.reportingLevel,
        classification: this.formObject.classification,
        projectType: this.formObject.projectType,
        program: this.formObject.program,
        strategicContribution: this.formObject.strategicContribution,
        feasibility: this.formObject.feasibility,
        profitability: this.formObject.profitability,
        projectMethodology: this.formObject.projectMethodology,
        confidential: this.formObject.confidential,
        additionalInformation: this.formObject.additionalInformation,
        pmoOfficer: this.formObject.pmoOfficer,
        trippleConstraint: this.trippleConstraint,
        currency: this.formObject.currency,
        question: this.formObject.question,
        attachment1: this.attachment1 ? this.attachment1 : "",
        attachment2: this.attachment2 ? this.attachment2 : "",
        attachments: [
          this.attachment1 ? this.attachment1 : "",
          this.attachment2 ? this.attachment2 : ""
        ],
        isAttachement1Added: this.formObject.isAttachement1Added,
        isAttachement2Added: this.formObject.isAttachement2Added
      }
    };
    if (this.formObject.portfolio.questions && this.formObject.portfolio.questions.length > 0) {
      obj.projectOrder['portfolioQuestions'] = this.formObject.portfolioQuestions;
    }
    this.formObject.attachments = obj.projectOrder.attachments;

    if (this.projectObj.projectOrder.length == 0) {
      this.apiService.put("/projects/" + this.projectObj.id, obj).subscribe(projectResponse => {
        this.notificationBox("Success", "Order has been saved.", "success");
        this.projectObj = projectResponse;
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        if (this.projectObj.isPhase) {
          let sepIndex = this.projectObj.projectName.lastIndexOf("-");
          this.formObject.projectName = this.projectObj.projectName.substring(0, sepIndex);
        }
      });
    } else {
      let order = obj.projectOrder;
      delete obj.projectOrder;

      this.apiService.put("/project/order", {
        projectId: this.projectObj.id,
        project: obj,
        order: order
      }).subscribe(projectResponse => {
        this.notificationBox("Success", "Order has been saved.", "success");
        this.projectObj = projectResponse;
        this.saveFlag = false;
        this.isFormSaveSubmit = true;
        this.spinner.hide();
        if (this.projectObj.isPhase) {
          let sepIndex = this.projectObj.projectName.lastIndexOf("-");
          this.formObject.projectName = this.projectObj.projectName.substring(0, sepIndex);
        }
      });
    }
  }

  getItPlatform(): any {
    if (this.formObject.itPlatform.length > 0) {
      this.formObject.itPlatform.forEach((itPlatform, idx) => {
        if (itPlatform.id) {
          this.formObject.itPlatform[idx] = itPlatform.id;
        }
      });
    }
    return this.formObject.itPlatform;
  }


  openFileBrowser(id) {
    document.getElementById(id).click();
  }

  uploadAttachments(type: string) {
    this.showNotification = true;
    if (this.formObject.isAttachement1Added) {
      this.attachment1Uploaded = false;
      this.uploader.uploadAll();
    }
    if (this.formObject.isAttachement2Added) {
      this.attachment2Uploaded = false;
      this.uploader1.uploadAll();
    }
  }

  selectFile() {
    this.uploader.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement1Added = true;
      } else {
        this.uploader.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact1-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment1 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment1.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement1Added = false;
        this.attachment1 = attach;
        this.showNotification = false;
        this.attachment1Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  selectFile1() {
    this.uploader1.onAfterAddingFile = file => {
      if (this.utilityService.checkAllowedFileMimeTypes(file.file.type)) {
        file.withCredentials = false;
        this.formObject.isAttachement2Added = true;
      } else {
        this.uploader1.queue = [];
        file = {};

        setTimeout(() => {
          let obj: any = document.getElementById("attact2-span");
          obj.value = "";
        }, 100);

        this.notificationBox("Warning", "File type not supported!", "warning");
      }
    };
    this.uploader1.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let res = JSON.parse(response);
      this.apiService.post("/attachment", {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(attach => {
        if (this.formObject.id != undefined) {
          if (this.attachment2 != undefined) {
            // To check for previous attachment and to delete them
            this.apiService.delete("/attachment/" + this.attachment2.id).subscribe((response: any) => {
              this.apiService.post("/deleteFile", {
                path: response.path
              }).subscribe(fileResponse => {
                console.log(fileResponse);
              });
            });
          }
        }
        this.formObject.isAttachement2Added = false;
        this.attachment2 = attach;
        this.showNotification = false;
        this.attachment2Uploaded = true;

        if (this.attachment1Uploaded == true && this.attachment2Uploaded == true) {
          if (this.eventType == "save") {
            this.saveHelper();
          } else if ("submit") {
            this.submitHelper();
          }
        }
      });
    };
  }

  setAttachmentName() {
    let obj: any = document.getElementById("attact1-span");
    let obj2: any = document.getElementById("pmt-uploader");
    obj.value = obj2.value;
  }

  setAttachmentName2() {
    let obj: any = document.getElementById("attact2-span");
    let obj2: any = document.getElementById("pmt-uploader2");
    obj.value = obj2.value;
  }

  setCommentsData() {
    if (this.projectObj != undefined) {
      this.orderObj.status = this.projectObj.status;
      this.orderObj.purposeComments = this.projectObj.projectOrder[0].purposeComments;
      this.orderObj.currentSituationComments = this.projectObj.projectOrder[0].currentSituationComments;
      this.orderObj.goalsComments = this.projectObj.projectOrder[0].goalsComments;
      this.orderObj.deliverablesComments = this.projectObj.projectOrder[0].deliverablesComments;
      this.orderObj.outOfScopeComments = this.projectObj.projectOrder[0].outOfScopeComments;
      this.orderObj.qualitativeBenefitsComments = this.projectObj.projectOrder[0].qualitativeBenefitsComments;
      this.orderObj.qualitativeQuantitativeComments = this.projectObj.projectOrder[0].qualitativeQuantitativeComments;
      this.orderObj.involvedPartnersComments = this.projectObj.projectOrder[0].involvedPartnersComments;
      this.orderObj.risksComments = this.projectObj.projectOrder[0].risksComments;
      this.orderObj.categoriesComments = this.projectObj.projectOrder[0].categoriesComments;
      this.orderObj.additionalInfoComments = this.projectObj.projectOrder[0].additionalInfoComments;
      this.orderObj.communicationComments = this.projectObj.projectOrder[0].communicationComments;
      this.orderObj.financingComments = this.projectObj.projectOrder[0].financingComments;
      this.orderObj.dependenciesComments = this.projectObj.projectOrder[0].dependenciesComments;
      this.orderObj.milestonesComments = this.projectObj.projectOrder[0].milestonesComments;
      this.orderObj.orgChartComments = this.projectObj.projectOrder[0].orgChartComments;
      this.orderObj.attachmentsComments = this.projectObj.projectOrder[0].attachmentsComments;
    }
  }

  preview() {
    this.showOrderPreview = true;

    //Setting Redundant Values
    if (this.projectObj != undefined) {
      this.orderObj = JSON.parse(JSON.stringify(this.formObject));
      let projectId = this.projectObj;
      let trippleConstraint = this.trippleConstraint;
      let projectOrganizationChart = this.diagram.export();
      this.orderObj = { ...this.orderObj, projectId, trippleConstraint, projectOrganizationChart };//final as an object
      this.orderObj.mileStonesValues = this.mileStonesValues;
      this.orderObj = this.utilityService.setOrderPdfValues(this.orderObj);
    }

    if (this.projectObj && this.projectObj.projectOrder && this.projectObj.projectOrder.length > 0) {
      this.orderObj.projectOrderId = this.projectObj.projectOrder[0].id;
    }

    this.setCommentsData();

    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
      if (this.projectObj != undefined) {
      }
    });
    this.lgModal.show();
  }

  onCellPrepared(e) {
    if (e.columnIndex > 4) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onRowPrepared(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  partOfAProgramValueChanged(e) {
    if (this.programOptions != undefined) {
      this.programOptions.forEach(response => {
        if (response.id == e.value) {
          this.formObject.program = response;
          delete this.formObject.program.reports;
        }
      });
    }
  }

  pmoOfficerValueChanged(e) {
    if (this.PMOsList != undefined) {
      this.PMOsList.forEach(response => {
        if (response.id == e.value) {
          this.formObject.pmoOfficer = response;
        }
      });
    }
  }

  projectSponsorValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.formObject.projectSponsor = response;
          }
        });
      }
    }
  }

  startDateValueChanged(e) {
    if (this.startDateChangeFlag == true) {
      this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
      this.formObject.endDate = this.minimumDate;
    } else {
      this.startDateChangeFlag = true;
      // if (this.formObject.id == undefined) {
      //   this.minimumDate = moment(this.formObject.startDate).add('days', 1).format('MMM DD, YYYY');
      //   this.formObject.endDate = this.minimumDate;
      // }
    }
  }

  projectSponsorOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectSponsor = response;
            } else {
              this.formObject.projectSponsor = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectSponsor = { id: '' };
      }
    });
  }

  projectFicoOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;

      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.event.delegateTarget.value) {
              delete (response.projects);
              this.formObject.projectFico = response;
            } else {
              this.formObject.projectFico = { id: '' };
            }
          });
        }
      } else {
        this.formObject.projectFico = { id: '' };
      }
    });
  }

  projectFicoValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.formObject.projectFico = response;
        }
      });
    }
  }

  projectOnInputEvent(e) {
    // if (e.event.target.value == '') {
    //   this.projectSearchBox.instance.reset();
    // }

    // if (e.event.target.value != '') {
    //   this.projectSubject.next(e.event.target.value);
    // }
    if (e != '') {
      this.projectSubject.next(e);
    }
  }

  initProjectListeners() {
    this.projectSubject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(value => {
      if (value != undefined) {
        this.socketService.emitEvent('getRecentActiveProjects', { search: value });
      }

      return [];
    })).subscribe((response: any) => { }, error => { });

    this.socketService.listen('getRecentActiveProjects').subscribe(response => {
      // this.dependenciesProjectColumnOptions = response;
      this.dependenciesProjectFormColumnOptions = response;
    });

    this.socketService.listen('getSelectiveProjects').subscribe(response => {
      this.backupProjectsList = response;
      this.dependenciesProjectColumnOptions = this.backupProjectsList;
    });
  }

  projectValueChanged(e) {
    // if (e.selectedItem != undefined) {
    //   this.dependenciesForm.project = e.selectedItem.id;

    //   this.dependenciesProjectFormColumnOptions.forEach(response => {
    //     if (response.id == e.selectedItem.id) {
    //       this.backupProjectsList.push(response);
    //       this.dependenciesProjectColumnOptions.push(response);
    //     }
    //   });
    // }

    if (e.value) {
      // this.dependenciesForm.project = e.value.id;

      this.backupProjectsList.push(e.value);
      this.dependenciesProjectColumnOptions.push(e.value);
    }
  }

  mandatoryProjectValueChanged(e) {
    if (this.mandatoryProjectOptions != undefined) {
      this.mandatoryProjectOptions.values.forEach((response: any) => {
        if (response.id == e.value) {
          this.formObject.mandatoryProject = response;
        }
      });
    }
  }

  portfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.portfolioList != undefined) {
        this.portfolioList.forEach(res => {
          if (res.id == e.value) {
            this.formObject.portfolio = res;
            this.formObject.portfolioQuestions = [];
            this.groups = [];
            this.groupsHolder = [];
            if (this.formObject.portfolio.questions.length > 0) {
              this.formObject.portfolio.questions.forEach((element, index) => {
                this.formObject.portfolioQuestions[index] = '';
                this.groupsHolder[element.group] = element.email;
              });
              this.groups = Object.keys(this.groupsHolder)
            }
            this.subPortfolioList = res.subPortfolio
            if (this.formObject.subPortfolio && this.formObject.subPortfolio.id) {
              let subPortfolio = this.subPortfolioList.find(val => val.id == this.formObject.subPortfolio.id);
              if (!subPortfolio) {
                this.formObject.subPortfolio = { id: '' };
              }
            }
          }
        })
      }
    }
  }

  subPortfolioSelectValueChanged(e) {
    if (e.value != undefined) {
      if (this.subPortfolioList != undefined) {
        if (this.subPortfolioList.find(x => x.id == e.value)) {
          this.formObject.subPortfolio = this.subPortfolioList.find(x => x.id == e.value);
        }
      }
    }
  }

  itPlatformValueChanged(e) {
    if (this.itPlatformOptions != undefined) {
      this.itPlatformOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itPlatform = response;
        }
      });
    }
  }

  itRelevantValueChanged(e) {
    if (this.itRelevantOptions != undefined) {
      this.itRelevantOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.itRelevant = response;
        }
      });
    }
  }

  businessUnitValueChanged(e) {
    if (this.businessUnitOptions != undefined) {
      this.businessUnitOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessUnit = response;
        }
      });
    }
  }

  businessAreaValueChanged(e) {
    if (this.businessAreaOptions != undefined) {
      this.businessAreaOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessArea = response;
        }
      });
    }
  }

  businessSegmentValueChanged(e) {
    if (this.businessSegmentOptions != undefined) {
      this.businessSegmentOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.businessSegment = response;
        }
      });
    }
  }

  reportingLevelValueChanged(e) {
    if (this.reportingLevelOptions != undefined) {
      this.reportingLevelOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.reportingLevel = response;
        }
      });
    }
  }

  classificationValueChanged(e) {
    if (this.classificationOptions != undefined) {
      this.classificationOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.classification = response;
        }
      });
    }
  }

  projectTypeValueChanged(e) {
    if (this.projectTypeOptions != undefined) {
      this.projectTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectType = response;
        }
      });
    }
  }

  strategicContributionValueChanged(e) {
    if (this.strategicContributionOptions != undefined) {
      this.strategicContributionOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.strategicContribution = response;
        }
      });
    }
  }

  feasibilityValueChanged(e) {
    if (this.feasibilityOptions != undefined) {
      this.feasibilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.feasibility = response;
        }
      });
    }
  }

  profitabilityValueChanged(e) {
    if (this.profitabilityOptions != undefined) {
      this.profitabilityOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.profitability = response;
        }
      });
    }
  }

  projectMethodologyValueChanged(e) {
    if (this.projectMethodologyOptions != undefined) {
      this.projectMethodologyOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.formObject.projectMethodology = response;
        }
      });
    }
  }

  confidentialValueChanged(e) {
    if (this.confidentialOptions != undefined) {
      this.confidentialOptions.forEach(response => {
        if (response == e.value) {
          this.formObject.confidential = response;
        }
      });
    }
  }

  dependenciesImpactValueChanged(e) {
    if (this.dependenciesImpactColumnOptions != undefined) {
      this.dependenciesImpactColumnOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.dependenciesForm.impact = response;
        }
      });
    }
  }

  syncTreeViewSelection(e) {
    let component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (component) {
      component.unselectAll();
    }

    if (this.formObject.itPlatform) {
      this.formObject.itPlatform.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  treeViewItemSelectionChanged(e) {
    const nodes = e.component.getNodes();

    let selectedItems = nodes.filter(val => val.selected == true);
    if (selectedItems.length > 3) {
      this.showNotificationBox('step 6: It Platform can select maximum no of 3.');
    } else {
      this.formObject.itPlatform = this.getSelectedItemsKeys(nodes);
    }
  }

  // ########################################################################################
  // Risks Table Configuration
  openRiskModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openRiskEditDialog(template, obj) {
    this.riskForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.modalRef = this.modalService.show(template, this.config);
  }

  risksRowClick(e) {
    this.openRiskEditDialog(this.riskPopUpTemplate, e.key);
  }

  riskStrategyValueChanged(e) {
    if (this.risksTopFiveRiskStrategySelectOptions != undefined) {
      this.risksTopFiveRiskStrategySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.riskForm.riskStrategy = response;
        }
      });
    }
  }

  checkRange(): boolean {
    if (this.riskForm.probability < 100) {
      return true;
    } else {
      this.notificationBox("Warning", "Values must be between 1-99.", "warning");
      return false;
    }
  }

  editRisk() {
    if (this.riskFormValidation() && this.checkRange()) {
      this.modalRef.hide();
      // Sorting Risks by Risk Exposure
      this.formObject.risks = this.formObject.risks.sort(({ riskExposure: a }, { riskExposure: b }) => (parseFloat(b) || 0) - (parseFloat(a) || 0));
      this.formObject.risks.forEach((risk, index) => {
        risk.id = index + 1;
      });
    }
  }

  riskFormValidation(): boolean {
    if (this.riskForm.name.length <= 0) {
      this.showNotificationBox('Please enter a risk name first.');
      return false;
    }
    if (this.riskForm.description.length <= 0) {
      this.showNotificationBox('Please enter a risk description first.');
      return false;
    }
    if (this.riskForm.riskStrategy.name == undefined) {
      this.showNotificationBox('Please enter a risk strategy first.');
      return false;
    }
    if (this.riskForm.measures.length <= 0) {
      this.showNotificationBox('Please enter a risk measure first.');
      return false;
    }
    if (this.riskForm.extentOfLosses == "") {
      this.showNotificationBox('Please enter a risk extent of losses first.');
      return false;
    }
    if (this.riskForm.probability == "") {
      this.showNotificationBox('Please enter a risk probability first.');
      return false;
    }
    else {
      return true;
    }
  }

  // ##############################################################################################
  // Milestone Table Configurations
  onEditorPreparingMilestonesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'id') {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = 'Enter your description';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'extentOfLosses') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = 'CHF';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  openMilestoneEditDialog(template, obj) {
    this.milestoneForm = obj;
    this.modalRef = this.modalService.show(template, this.config);
  }


  milestoneEdit() {
    if (this.milestoneformValidation()) {
      this.modalRef.hide();
      // Sorting Milestones by Due date
      this.mileStonesValues = this.mileStonesValues.sort(({ dueDate: a }, { dueDate: b }) => (a || new Date(2200, 11)) - (b || new Date(2200, 11)));
      this.mileStonesValues.forEach((milestone, index) => {
        milestone.id = index + 1;
      });
    }
  }

  milestoneformValidation(): boolean {
    if (this.milestoneForm.name.length <= 0) {
      this.showNotificationBox('Please enter a milestone name first.');
      return false;
    }
    if (this.milestoneForm.description.length <= 0) {
      this.showNotificationBox('Please enter a milestone description first.');
      return false;
    }
    if (!this.isDueDateValid) {
      this.showNotificationBox('Please enter milestone due date in correct format (dd.MM.yyyy) first.');
      return false;
    }
    if (this.milestoneForm.dueDate == "" || this.milestoneForm.dueDate == null) {
      this.showNotificationBox('Please enter a milestone due date first.');
      return false;
    }
    else {
      return true;
    }
  }

  milestoneRowClick(e) {
    this.openMilestoneEditDialog(this.milestonePopUpTemplate, e.key);
  }

  // ########################################################################################
  // PSP Table Configurations
  onEditorPreparingPspTableDescription(e) {
    if (e.parentType == "dataRow" && e.dataField == "description") {
      e.editorOptions.maxLength = 150;
      // e.editorOptions.placeholder = "Describe your risk here";
    } else if (e.dataField == "id") {
      e.editorOptions.maxLength = 16;
    }
  }

  communicationRowClick(e) {
    this.communicationForm = e.key;
    this.modalRef = this.modalService.show(this.communicationPopUpTemplate, this.config);
  }

  communicationEdit() {
    this.modalRef.hide();
  }


  public showAttachmentDeleteButton(type: string): boolean {
    if (this.formObject.attachments != undefined) {
      if (this.formObject.attachments.length > 0) {
        if (type == '1') {
          return (this.formObject.id != undefined && this.formObject.attachments[0] != "");
        } else {
          return (this.formObject.id != undefined && this.formObject.attachments[1] != "");
        }
      } else {
        return false;
      }
    }
  }

  deleteAttachment(type: string) {

    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (type == '1') {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[0].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment1 = "";
              this.formObject.attachments[0] = "";

              this.apiService.put('/projectOrder/' + this.projectObj.projectOrder[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact1-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        } else {
          this.spinner.show();
          this.apiService.delete("/attachment/" + this.formObject.attachments[1].id).subscribe((response: any) => {
            this.apiService.post("/deleteFile", {
              path: response.path
            }).subscribe(fileResponse => {
              this.attachment2 = "";
              this.formObject.attachments[1] = "";

              this.apiService.put('/projectOrder/' + this.projectObj.projectOrder[0].id, {
                attachments: this.formObject.attachments
              }).subscribe(response => {
                let obj: any = document.getElementById("attact2-span");
                obj.value = "";

                this.notificationBox("Success", "Attachment deleted.", "success");
                this.spinner.hide();
              });
            });
          });
        }
      }
    });
  }

  onKeyExtentOfLossesInfo(e) {
    // if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57)
    // {
    //     e.preventDefault();
    // }
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  onKeyProbabilityInfo(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  onKeyPurposeInfo(e) {
    if (e.key == 'Enter') {
      e.preventDefault();
    }
  }

  onKeyCurrentSituationInfo(e) {
    // let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 264) {
        e.preventDefault();
        this.currentSituationLimit = true;
      } else {
        this.currentSituationLimit = false;
      }
    } else {
      this.currentSituationLimit = false;
    }
  }

  onKeyAdditionalInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.additionalInfoLimit = true;
      } else {
        this.additionalInfoLimit = false;
      }
    } else {
      this.additionalInfoLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  canDeactivate(): boolean {
    if (this.isFormSaveSubmit == true) {
      return false;
    }
    else {
      return true;
    }
  }

  checkValidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'communication') {
      let communicationCheck = this.formObject.communicationTable.filter(val => val.information.length > 0);
      if (communicationCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'involvedPartners') {
      let involvedPartnersTableCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
      if (involvedPartnersTableCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Quantitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'risks') {
      let risksCheck = this.formObject.risks.filter(val => val.description.length > 0 && val.name.length > 0
        && val.riskStrategy.name != undefined && val.measures.length > 0 && val.extentOfLosses != '' && val.probability != '');
      if (risksCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Dependencies') {
      let impactedByDependenciesCheck = this.formObject.impactedByDependenciesTable.filter(val => val.description.length > 0);
      if (impactedByDependenciesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else if (feild == 'Milestones') {
      let mileStonesCheck = this.mileStonesValues.filter(val => val.description.length > 0 && val.name.length > 0 && val.dueDate != "");
      if (mileStonesCheck.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkInvalidFeild(feild) {
    if (feild == 'Goals') {
      let goalsCheck = this.formObject.goals.filter(val => val.description.length > 0);
      if (goalsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Deliverables') {
      let deliverablesCheck = this.formObject.deliverables.filter(val => val.description.length > 0);
      if (deliverablesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Out Of Scope') {
      let outOfScopeCheck = this.formObject.outOfScope.filter(val => val.description.length > 0);
      if (outOfScopeCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'communication') {
      let communicationCheck = this.formObject.communicationTable.filter(val => val.information.length > 0);
      if (communicationCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'involvedPartners') {
      let involvedPartnersTableCheck = this.formObject.involvedPartnersTable.filter(val => val.externalPartners.length > 0);
      if (involvedPartnersTableCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Qualitative Benefits') {
      let quantativeBenefitsCheck = this.formObject.quantitativeBenefit.filter(val => val.description.length > 0);
      if (quantativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Quantitative Benefits') {
      let qualitativeBenefitsCheck = this.formObject.kpisTable.filter(val => val != null && val.value != null && val.value.length > 0);
      if (qualitativeBenefitsCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'risks') {
      let risksCheck = this.formObject.risks.filter(val => val.description.length > 0 && val.name.length > 0
        && val.riskStrategy.name != undefined && val.measures.length > 0 && val.extentOfLosses != '' && val.probability != '');
      if (risksCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Dependencies') {
      let impactedByDependenciesCheck = this.formObject.impactedByDependenciesTable.filter(val => val.description.length > 0);
      if (impactedByDependenciesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else if (feild == 'Milestones') {
      let mileStonesCheck = this.mileStonesValues.filter(val => val.description.length > 0 && val.name.length > 0 && val.dueDate != "");
      if (mileStonesCheck.length == 0) {
        return true;
      } else {
        false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('usersFromRoles');
    this.socketService.removeListener('activePrograms');
    this.socketService.removeListener('fetchProjectOrder');
    this.socketService.removeListener('sendEmail');
    this.socketService.removeListener('questionsByFormName');
    this.socketService.removeListener('helpGuideByFormName');
    this.socketService.removeListener('activePortfolios');
  }
}

class OrderForm {
  id?: string;
  projectName: string = "";
  projectManager: any;
  projectSponsor: any;
  startDate: Date;
  endDate: Date;
  currentSituation: string = "";
  goals: string = "";
  deliverables: string = "";
  outOfScope: string = "";
  information: string = "";
  stakeholders: string = "";
  quantitativeBenefit: string = "";
  milestone1: string;
  milestone2: string;
  milestone3: string;
  milestone4: string;
  milestone5: string;
  milestone6: string;
  milestone7: string;
  milestone8: string;
  milestone9: string;
  milestone10: string;
  milestone1Date: string;
  milestone2Date: string;
  milestone3Date: string;
  milestone4Date: string;
  milestone5Date: string;
  milestone6Date: string;
  milestone7Date: string;
  milestone8Date: string;
  milestone9Date: string;
  milestone10Date: string;
  budgeted: string;
  psp: Array<any>;
  comments: string = "";
  mandatoryProject: string;
  businessUnit: string;
  businessArea: string;
  businessSegment: string;
  reportingLevel: string;
  classification: string;
  projectType: string;
  program: string;
  strategicContribution: string;
  feasibility: string;
  profitability: string;
  additionalInformation: string = "";
  pmoOfficer: string;
  projectSponsorClient: string;
  projectManagerIn: string;
  pmoFormalControl: string;
  projectClientIn: string;
  projectFico: any;
}


