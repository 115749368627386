import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import localeDECH from '@angular/common/locales/de-CH';
import { FormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { MatDatetimepickerModule } from "@mat-datetimepicker/core";
import { AppRoutes } from './app.routing';
import { ModalModule } from 'ngx-bootstrap';
import { DxScrollViewModule, DxDataGridModule } from 'devextreme-angular';
import { I18nService } from './shared/i18n/i18n.service';
import { ApiService } from './services/api.service';
import { UtilityService } from './services/utility.service';
import { AuthService } from './services/auth.service';
import { DropdownResolverService } from './resolvers/dropdown-resolver.service';
import { CanDeactivateService } from 'app/services/can-deactivate.service';
import { NotificationsService } from './services/notifications.service';
import { SocketService } from './services/socket.service';
import { NgxSpinnerModule } from "ngx-spinner";

registerLocaleData(localeDECH);

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      onSameUrlNavigation: 'ignore',
      initialNavigation: 'enabled',
      paramsInheritanceStrategy: 'always'
    }),
    FixedpluginModule,
    ModalModule.forRoot(),
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    DxScrollViewModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    AuthLayoutComponent,
  ],
  providers: [
    I18nService,
    ApiService,
    UtilityService,
    AuthService,
    DropdownResolverService,
    CanDeactivateService,
    NotificationsService,
    SocketService,
    { provide: LOCALE_ID, useValue: 'de-CH' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
