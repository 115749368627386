import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubportfoliosRoutingModule } from './subportfolios-routing.module';
import { SubportfoliosComponent } from './subportfolios.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "../shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [SubportfoliosComponent],
  imports: [
    CommonModule,
    SubportfoliosRoutingModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule
  ]
})
export class SubportfoliosModule { }
