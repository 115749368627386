import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule } from '@angular/material';
import { I18nModule } from '../i18n/i18n.module';
import { FormsModule } from '@angular/forms';
import { DxTextAreaModule, DxAutocompleteModule } from 'devextreme-angular';

@NgModule({
    imports: [RouterModule, CommonModule, MatButtonModule, I18nModule, FormsModule, DxTextAreaModule, DxAutocompleteModule],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})
export class NavbarModule { }