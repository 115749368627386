import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-sub-portfolio-details',
  templateUrl: './sub-portfolio-details.component.html',
  styleUrls: ['./sub-portfolio-details.component.scss']
})
export class SubPortfolioDetailsComponent implements OnInit {
  tabs: FormGroup;
  subPortfolioId;
  portfolioId;
  budgetType;
  portfolioName: String = '';
  subPortfolioName: String = '';
  isUserPrivileged = false;
  subportfoliosList = [];

  constructor(private utilityService: UtilityService, private route: ActivatedRoute, private formBuilder: FormBuilder,
    private router: Router, private apiService: ApiService, public authService: AuthService) {
    this.utilityService.reportsTab = "Budget";

    if (this.authService.userObj.role == "pmo" || this.authService.userObj.role == "admin") {
      this.isUserPrivileged = true;
    }

    this.route.params.subscribe(params => {
      if (params.subPortfolio != undefined) {
        this.subPortfolioId = params.subPortfolio;
        this.fetchDetails();
      }
      if (params.portfolioId != undefined) {
        this.portfolioId = params.portfolioId;
      }
    });
  }

  ngOnInit() {
    this.tabs = this.formBuilder.group({
    });
  }

  tabsItemClick(path, queryParams) {
    this.utilityService.reportsTab = path;
  }

  fetchDetails() {
    this.apiService.get('/subPortfolio/' + this.subPortfolioId).subscribe((subportfolio: any) => {
      this.portfolioName = subportfolio.portfolio.name;
      this.subPortfolioName = subportfolio.name;
    });
  }

  subportfoliosValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.router.navigate([`dashboard/portfolio/subPortfolio/${e.itemData.id}/details/budget/Sub-Portfolio`])
    }
  }

  subportfoliosOnInputEvent(e) {
    this.apiService.get('/subPortfolio/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.subportfoliosList = response;
    });
  }

  ngAfterViewInit() {
    this.utilityService.initTabs();

    setTimeout(() => {
      let sub = this.route.params.subscribe(params => {
        if (params.tabIndex != undefined) {
          if (params.tabIndex * 1 == 1) {
            this.utilityService.reportsTab = "Budget";
            // $('app-project-detail .nav-item > a[href="#budget"]').click();
            setTimeout(() => {
              // $('app-project-detail .tab-pane[id="status"]').addClass('active');
            }, 200);
          }
        }
      });
    }, 500);
  }

}
