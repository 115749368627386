import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';

export const DashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        redirectTo: 'home'
      },
      // {
      //   path: 'home',
      //   loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
      // },

      // {
      //   path: 'projectsListWithPortfolio',
      //   loadChildren: () => import('./projects-list-with-portfolio/projects-list-with-portfolio.module').then(mod => mod.ProjectsListWithPortfolioModule)
      // },

      {
        path: 'home',
        loadChildren: () => import('./projects-list-with-portfolio/projects-list-with-portfolio.module').then(mod => mod.ProjectsListWithPortfolioModule)
      },
      {
        path: 'ordersListWithPortfolio',
        loadChildren: () => import('./orders-list-with-portfolio/orders-list-with-portfolio.module').then(mod => mod.OrdersListWithPortfolioModule)
      },
      {
        path: 'portfolioGanttView',
        loadChildren: () => import('./portfolio-gantt-view/portfolio-gantt-view.module').then(mod => mod.PortfolioGanttViewModule)
      },
      {
        path: 'pipelineView',
        loadChildren: () => import('./pipeline-view/pipeline-view.module').then(mod => mod.PipelineViewModule)
      },
      {
        path: 'programs',
        loadChildren: () => import('./programs/programs.module').then(mod => mod.ProgramsModule)
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./portfolio/portfolio.module').then(mod => mod.PortfolioModule)
      }
    ]
  },
  {
    path: 'portfolio/create',
    loadChildren: () => import('./portfolio/create-portfolio/create-portfolio.module').then(mod => mod.CreatePortfolioModule)
  },
  {
    path: 'portfolio/:id',
    loadChildren: () => import('./portfolio/create-portfolio/create-portfolio.module').then(mod => mod.CreatePortfolioModule)
  },
  {
    path: 'portfolio/subPortfolio/:subPortfolio/details',
    loadChildren: () => import('./portfolio/sub-portfolio-details/sub-portfolio-details.module').then(mod => mod.SubPortfolioDetailsModule),
  },
];
