import { AuthService } from './../../services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ApiService } from '../../services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UtilityService } from '../../services/utility.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
declare var $: any;

var globalThis;

@Component({
  selector: 'app-orders-list-with-portfolio',
  templateUrl: './orders-list-with-portfolio.component.html',
  styleUrls: ['./orders-list-with-portfolio.component.scss']
})
export class OrdersListWithPortfolioComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  resizingModes: string[] = ['nextColumn', 'widget'];
  columnResizingMode: string;
  smallOrdersList: any = [];
  smallOrdersPageIndex = 2;
  smallOrdersCount: number = 0;
  portfolioList = [{ id: undefined, name: 'All' }];
  subPortfolioList = [{ id: undefined, name: 'All' }];
  stateStore;
  isFilterApplied: boolean = false;
  isResetPressed: boolean = true;
  currencyFormat = { format: "Fr #'###.##" };
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  backupFiltered;
  portfolioFilter;
  programFilter;
  businessAreaFilter;
  businessUnitFilter;
  projectCategoryFilter;
  reportingLevelFilter;
  statusFilter;
  subProjectsList;
  backUpList = [];
  partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
  businessUnitOptions;
  businessAreaOptions;
  reportingLevelOptions;
  statusOptions;
  classificationOptions;
  subPortfolioFilter;
  isSubPortfolio: boolean = true;
  // isClickFilter: boolean = false;
  loadingVisible = false;
  ordersPageIndex = 2;
  itRelevantOptions;
  itRelevantFilter;
  itPlatformOptions;
  itPlatformFilter = [];
  ordersSearchList = [];
  orderName;
  usersList = [];
  orderSponsorFilter;
  orderManagerFilter;
  orderManagerEmail;
  orderSponsorEmail;
  orderNameFilter;
  filtersArray;
  userIds;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeViewObj;
  isSubportfolioFilter: boolean = false;
  BASE_PATH = '';
  attachmentQueryParams = '';
  showPreview = false;
  smallOrderPreview;
  @ViewChild('lgModal', { static: false }) public lgModal;
  FrontEnd_PATH;
  projectsCount: number = 0;
  dropdownPageIndex = 1;

  constructor(private apiService: ApiService, private modalService: BsModalService, private utilityService: UtilityService,
    private authService: AuthService, private router: Router, private socketService: SocketService, private spinner: NgxSpinnerService, private localize: I18nPipe) {
    // this.socketService.listen('allPortfolios').subscribe(response => {
    //   this.portfolioList = this.portfolioList.concat(response);
    // });

    // this.socketService.listen('allPrograms').subscribe(response => {
    //   this.partOfaProgramOptions = response;
    // });

    // this.fetchPortfoliosList();
    // this.fetchProgram();
    globalThis = this;
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      this.usersList = responseUsers;
      this.setFilterValues(this.filtersArray);
      this.socketService.emitEvent('smallOrdersFilterCount', { filtersArray: this.filtersArray });
    })

    this.socketService.listen('smallOrdersFilterCount').subscribe(count => {
      this.isResetPressed = false;

      //Limiting data to 400 records
      if (count < 400) {
        this.projectsCount = count;
      } else {
        this.projectsCount = 400;
      }

      this.socketService.emitEvent('smallOrdersFilter', { pageIndex: this.dropdownPageIndex, pageSize: 10, filtersArray: this.filtersArray });
    });

    this.socketService.listen('smallOrdersFilter').subscribe(projects => {
      this.smallOrdersList = this.smallOrdersList.concat(projects);
      // this.smallOrdersList = projects;
      this.populateValues();
      this.backUpList = JSON.parse(JSON.stringify(this.smallOrdersList));
      this.backupFiltered = JSON.parse(JSON.stringify(this.smallOrdersList));
      this.dataGrid.instance.option('dataSource', this.smallOrdersList);
      this.spinner.hide();

      if (this.dropdownPageIndex < (this.projectsCount / 10)) {
        this.dropdownPageIndex++;
        this.socketService.emitEvent('smallOrdersFilter', { pageIndex: this.dropdownPageIndex, pageSize: 10, filtersArray: this.filtersArray });
      }
    });

    this.socketService.listen("getSelectiveOrders").subscribe(response => {
      this.ordersSearchList = response;
      if (this.userIds.length > 0) {
        this.filtersArray = this.filtersArray;
        this.socketService.emitEvent('fetchMultipleUsers', { userIds: this.userIds });
      } else {
        this.setFilterValues(this.filtersArray);
        this.socketService.emitEvent('smallOrdersFilterCount', { filtersArray: this.filtersArray });
      }
    });

    this.stateStore = {
      enabled: true,
      storageKey: 'smallOrdersPortfolioList',
      type: 'localStorage',
      savingTimeout: 200
    };
    this.utilityService.breadcrumb = 'Orders';
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Dashboard', 'Orders'];
    this.statusOptions = [{ id: 0, name: 'Draft' }, { id: 1, name: 'Start' }, { id: 2, name: 'Closed' }];
    this.businessAreaOptions = this.utilityService.businessAreaOptions;
    this.businessUnitOptions = this.utilityService.businessUnitOptions;
    this.reportingLevelOptions = this.utilityService.reportingLevelOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.classificationOptions = this.utilityService.classificationOptions;
    this.itRelevantOptions = JSON.parse(JSON.stringify(this.utilityService.itRelevantOptions));
    this.itRelevantOptions.values.unshift({ name: '-', id: undefined }); //Adding All option
    this.itPlatformOptions = this.utilityService.itPlatformOptions;

    this.apiService.get('/portfoliosPrograms').subscribe((response: any) => {
      this.portfolioList = this.portfolioList.concat(response.portfolios);
      this.partOfaProgramOptions = [{ id: undefined, programName: 'All' }];
      this.partOfaProgramOptions = this.partOfaProgramOptions.concat(response.programs);

      let filtersArray = JSON.parse(localStorage.getItem("portfolioOrdersFilter"));

      if (filtersArray && filtersArray.length > 0) {
        let userIds = [];
        let orderIds = [];

        filtersArray.forEach(filter => {
          if (Object.keys(filter)[0] == 'orderSponsor') {
            userIds.push(filter.orderSponsor);
          }
          if (Object.keys(filter)[0] == 'orderManager') {
            userIds.push(filter.orderManager);
          }
          if (Object.keys(filter)[0] == 'id') {
            orderIds.push(filter.id);
          }
        });

        this.filtersArray = filtersArray;
        this.userIds = userIds;

        if (orderIds.length > 0) {
          this.socketService.emitEvent("getSelectiveOrders", { ids: orderIds });
        } else {
          if (userIds.length > 0) {

            this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
          } else {
            this.setFilterValues(filtersArray);
            this.socketService.emitEvent('smallOrdersFilterCount', { filtersArray });
          }
        }
      } else {
        this.spinner.hide();
      }
    })


    // this.socketService.listen('smallOrdersCount').subscribe(count => {
    //   this.smallOrdersCount = count;

    //   if (count > 10) {
    //     this.socketService.emitEvent('smallOrdersIndex', { pageIndex: this.smallOrdersPageIndex, pageSize: 10, userId: this.authService.userObj.id });
    //   }
    // });

    // this.socketService.listen('smallOrdersIndex').subscribe((response: any) => {
    //   this.smallOrdersList = this.smallOrdersList.concat(response);
    //   this.backUpList = JSON.parse(JSON.stringify(this.smallOrdersList));
    //   this.backupFiltered = JSON.parse(JSON.stringify(this.smallOrdersList));

    //   if (this.smallOrdersPageIndex < (this.smallOrdersCount / 10)) {
    //     this.smallOrdersPageIndex++;
    //     this.socketService.emitEvent('smallOrdersIndex', { pageIndex: this.smallOrdersPageIndex, pageSize: 10, userId: this.authService.userObj.id });
    //   }
    // });

    // this.fetchSmallOrders();
  }

  selectResizing(data) {
    this.columnResizingMode = data.value;
  }

  populateValues() {
    this.smallOrdersList.forEach((smallOrder, idx) => {
      // populate itPlatfomrs

      if (this.itPlatformOptions && this.itPlatformOptions.values && smallOrder.itPlatform && smallOrder.itPlatform.length > 0) {
        let itPlatforms = [];

        smallOrder.itPlatform.forEach((itPlatform, idx) => {
          let itPlatformObj = this.itPlatformOptions.values.find(val => val.id == itPlatform);

          if (itPlatformObj) {
            if (idx == smallOrder.itPlatform.length - 1) {
              itPlatforms.push({ id: itPlatformObj.id, name: itPlatformObj.name });
            } else {
              itPlatforms.push({ id: itPlatformObj.id, name: itPlatformObj.name, coma: ',' });
            }
          }
        });
        smallOrder.itPlatform = itPlatforms;
      }
    });
  }

  openOrderPreview(smallOrder) {
    this.showPreview = false;
    this.smallOrderPreview = smallOrder;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  ordersOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.orderNameFilter != undefined) {
      this.smallOrdersList = [];
      this.orderNameFilter = undefined;
      this.orderName = '';
      this.filter();
    }
    this.apiService.get('/smallOrder/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.ordersSearchList = response;
    });
  }

  ordersValueChanged(e) {
    if (e.itemData.id != undefined && e.itemData.id != null) {
      this.orderNameFilter = e.itemData;
      this.filter();
    }
  }


  onITPlatformSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  onStatusSelectionChanged(e) {
    if (!this.isResetPressed) {
      this.filter();
    }
  }
  // fetchSmallOrders() {
  //   this.spinner.show();
  //   this.apiService.get('/smallOrder?limit=10').subscribe((response: any) => {
  //     this.smallOrdersList = response;
  //     this.backUpList = JSON.parse(JSON.stringify(this.smallOrdersList));
  //     this.backupFiltered = JSON.parse(JSON.stringify(this.smallOrdersList));
  //     this.socketService.emitEvent('smallOrdersCount', {});
  //     this.spinner.hide();
  //   });
  // }

  // refreshSmallOrders() {
  //   this.smallOrdersPageIndex = 2;
  //   this.fetchSmallOrders();
  // }

  // fetchPortfoliosList() {
  //   this.socketService.emitEvent('allPortfolios', {});
  // }

  filterOrders(data) {
    if (data.value.name != "All") {
      this.smallOrdersList = JSON.parse(JSON.stringify(this.backUpList.filter(val => val.portfolio == undefined ? val.portfolio : val.portfolio.id == data.value.id)));
    } else {
      this.smallOrdersList = JSON.parse(JSON.stringify(this.backUpList));
    }
  }

  orderSponsorValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.orderSponsorFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  orderSponsorOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.orderSponsorFilter != undefined) {
      this.smallOrdersList = [];
      this.orderSponsorFilter = undefined;
      this.orderSponsorEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.orderSponsorFilter = response;
      //       } else {
      //         this.orderSponsorFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.orderSponsorFilter = { id: '' };
      // }
    });
  }

  orderManagerValueChanged(e) {
    if (!this.isResetPressed) {
      if (this.usersList.length > 0) {
        if (this.usersList != undefined) {
          this.usersList.forEach(response => {
            if (response.email == e.value) {
              delete (response.projects);
              this.orderManagerFilter = response;
              this.filter();
            }
          });
        }
      }
    }
  }

  orderManagerOnInputEvent(e) {
    if (e.event.originalEvent.inputType == 'deleteContentBackward' && this.orderManagerFilter != undefined) {
      this.smallOrdersList = [];
      this.orderManagerFilter = undefined;
      this.orderManagerEmail = '';
      this.filter();
    }
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
      // if (this.usersList.length > 0) {
      //   if (this.usersList != undefined) {
      //     this.usersList.forEach(response => {
      //       if (response.email == e.event.delegateTarget.value) {
      //         delete (response.projects);
      //         this.orderManagerFilter = response;
      //       } else {
      //         this.orderManagerFilter = { id: '' };
      //       }
      //     });
      //   }
      // } else {
      //   this.orderManagerFilter = { id: '' };
      // }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  filter() {
    // this.isClickFilter = true;
    let filtersArray = this.setFilters();

    if (filtersArray.length > 0) {
      this.dropdownPageIndex = 1;
      this.smallOrdersList = [];
      this.filtersArray = filtersArray;

      localStorage.setItem('portfolioOrdersFilter', JSON.stringify(filtersArray));

      this.socketService.emitEvent('smallOrdersFilterCount', { filtersArray });
      this.spinner.show();
    } else {
      this.smallOrdersList = [];
    }
    //this.portfolioChange();
    //this.subPortfolioChange();
    //this.programChange();
    //this.businessUnitChange();
    //this.businessAreaChange();
    //this.projectTypeChange();
    //this.projectCategoryChange();
    //this.reportingLevelChange();
    //this.statusChange();
    //this.phaseChange();
    //this.methodologyChange();
    // this.backupFiltered = JSON.parse(JSON.stringify(this.reportsList));


    // this.modalRef.hide();
  }

  setFilters() {
    let filtersArray = new Array;

    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      filtersArray.push({ status: this.statusFilter })
    }
    if (this.programFilter) {
      filtersArray.push({ program: this.programFilter.id })
    }
    if (this.portfolioFilter) {
      filtersArray.push({ portfolio: this.portfolioFilter.id })
    }
    if (this.subPortfolioFilter) {
      filtersArray.push({ subPortfolio: this.subPortfolioFilter.id })
    }
    if (this.itPlatformFilter && this.itPlatformFilter.length > 0) {
      filtersArray.push({ itPlatform: this.itPlatformFilter })
    }
    if (this.itRelevantFilter) {
      filtersArray.push({ itRelevant: this.itRelevantFilter })
    }
    if (this.orderSponsorFilter) {
      filtersArray.push({ orderSponsor: this.orderSponsorFilter.id })
    }
    if (this.orderManagerFilter) {
      filtersArray.push({ orderManager: this.orderManagerFilter.id })
    }
    if (this.orderNameFilter) {
      filtersArray.push({ id: this.orderNameFilter.id })
    }

    this.isFilterApplied = true;
    return filtersArray;
  }

  setFilterValues(filterArray) {
    filterArray.forEach(filter => {
      switch (Object.keys(filter)[0]) {

        case 'portfolio':
          this.portfolioFilter = this.portfolioList.find(val => val.id == filter.portfolio)
          break;

        case 'subPortfolio':
          this.subPortfolioList = [{ id: undefined, name: 'All' }];
          this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
          this.subPortfolioFilter = this.subPortfolioList.find(val => val.id == filter.subPortfolio)
          this.isSubportfolioFilter = true;
          this.isSubPortfolio = false;
          break;

        case 'program':
          this.programFilter = this.partOfaProgramOptions.find(val => val.id == filter.program)
          break;

        case 'status':
          this.statusFilter = filter.status;
          break;

        case 'itPlatform':
          this.itPlatformFilter = filter.itPlatform;
          break;

        case 'itRelevant':
          this.itRelevantFilter = filter.itRelevant;
          break;

        case 'orderManager':
          this.orderManagerFilter = this.usersList.find(val => val.id == filter.orderManager)
          if (this.orderManagerFilter) {
            this.orderManagerEmail = this.orderManagerFilter.email;
          }
          break;

        case 'orderSponsor':
          this.orderSponsorFilter = this.usersList.find(val => val.id == filter.orderSponsor)
          if (this.orderSponsorFilter) {
            this.orderSponsorEmail = this.orderSponsorFilter.email;
          }
          break;

        case 'id':
          this.orderNameFilter = this.ordersSearchList.find(val => val.id == filter.id)
          if (this.orderNameFilter) {
            this.orderName = this.orderNameFilter.name;
          }
          break;


        default:
        // code block
      }

    });

  }

  resetFilter() {
    this.dropdownPageIndex = 1;
    this.isResetPressed = true;

    this.smallOrdersList = [];
    this.portfolioFilter = '';
    this.subPortfolioFilter = '';
    this.programFilter = '';
    // this.businessAreaFilter = '';
    // this.businessUnitFilter = '';
    // this.projectTypeFilter = '';
    // this.projectCategoryFilter = '';
    // this.reportingLevelFilter = '';
    this.statusFilter = '';
    // this.projectPhaseFilter = '';
    // this.projectMethodologyFilter = '';
    this.orderManagerFilter = '';
    this.orderSponsorFilter = '';
    this.itRelevantFilter = '';
    this.itPlatformFilter = [];
    this.orderNameFilter = '';
    this.orderName = '';
    this.orderManagerEmail = '';
    this.orderSponsorEmail = '';
    this.isSubPortfolio = true;
    this.isFilterApplied = false;
    localStorage.removeItem("portfolioOrdersFilter");

    let interval = setInterval(() => {
      if (this.isResetPressed == true) {
        clearInterval(interval);
        this.isResetPressed = false;
      }
    }, 500);
  }

  portfolioChange() {
    if (this.portfolioFilter) {
      this.smallOrdersList = this.smallOrdersList.filter(reportObj => {
        if (reportObj.portfolio != undefined) {
          return reportObj.portfolio.id == this.portfolioFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  programChange() {
    if (this.programFilter) {
      this.smallOrdersList = this.smallOrdersList.filter(reportObj => {
        if (reportObj.program != undefined) {
          return reportObj.program.id == this.programFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  businessUnitChange() {
    if (this.businessUnitFilter) {
      let projectsBusinessUnit = this.smallOrdersList.filter(reportObj => reportObj.businessUnit != undefined);
      if (projectsBusinessUnit != undefined && projectsBusinessUnit != null) {
        this.smallOrdersList = projectsBusinessUnit.filter(reportObj => reportObj.businessUnit.name == this.businessUnitFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  businessAreaChange() {
    if (this.businessAreaFilter) {
      let projectsBusinessArea = this.smallOrdersList.filter(reportObj => reportObj.businessArea != undefined);
      if (projectsBusinessArea != undefined && projectsBusinessArea != null) {
        this.smallOrdersList = projectsBusinessArea.filter(reportObj => reportObj.businessArea.name == this.businessAreaFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  projectCategoryChange() {
    if (this.projectCategoryFilter) {
      let projectsclassification = this.smallOrdersList.filter(reportObj => reportObj.classification != undefined);
      if (projectsclassification != undefined && projectsclassification != null) {
        this.smallOrdersList = projectsclassification.filter(reportObj => reportObj.classification.name == this.projectCategoryFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  reportingLevelChange() {
    if (this.reportingLevelFilter) {
      let projectsReportingLevel = this.smallOrdersList.filter(reportObj => reportObj.reportingLevel != undefined);
      if (projectsReportingLevel != undefined && projectsReportingLevel != null) {
        this.smallOrdersList = projectsReportingLevel.filter(reportObj => reportObj.reportingLevel.name == this.reportingLevelFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  statusChange() {
    if (this.statusFilter != '' && this.statusFilter != "No Status" && this.statusFilter != undefined) {
      this.smallOrdersList = this.smallOrdersList.filter(reportObj => reportObj.status == this.statusFilter);
      this.isFilterApplied = true;
    }
  }

  itRelevantChange() {
    if (this.itRelevantFilter) {
      let itRelevant = this.smallOrdersList.filter(reportObj => reportObj.itRelevant != undefined);
      if (itRelevant != undefined && itRelevant != null) {
        this.smallOrdersList = itRelevant.filter(reportObj => reportObj.itRelevant.name == this.itRelevantFilter.name);
        this.isFilterApplied = true;
      }
    }
  }

  itPlatformChange() {
    if (this.itPlatformFilter && this.itPlatformFilter.length > 0) {
      let itPlatform = this.smallOrdersList.filter(reportObj => reportObj.itPlatform != undefined);
      if (itPlatform != undefined && itPlatform != null) {
        this.smallOrdersList = itPlatform.filter(reportObj => JSON.stringify(reportObj.itPlatform) == JSON.stringify(this.itPlatformFilter));
        this.isFilterApplied = true;
      }
    }
  }

  // fetchProgram() {
  //   this.socketService.emitEvent('allPrograms', {});
  // }

  calculateTotalExposureValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].totalExposure;
    }
  }

  calculateRiskExposureVsCurrentBudgetValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].riskExposureVsCurrentBudget;
    }
  }

  calculatePlannedEndDateVsForecastDateValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].plannedDateVSForecastDate;
    }
  }

  calculateCurrentBudgetVsForecastValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].currentBudgetVSForecast;
    }
  }

  calculateManagementSummaryValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].managementSummary;
    }
  }

  calculatePercentageCompleteValue(e) {
    if (e.smallOrderStatusReports.length > 0) {
      return e.smallOrderStatusReports[e.smallOrderStatusReports.length - 1].percentageComplete;
    }
  }

  filterSubPortfolio() {
    if (!this.isSubportfolioFilter) {
      this.subPortfolioFilter = '';
      // this.filter();

      if (this.portfolioFilter && this.portfolioFilter != "" && this.portfolioFilter.name != 'All') {
        this.subPortfolioList = [{ id: undefined, name: 'All' }];
        this.subPortfolioList = this.subPortfolioList.concat(this.portfolioFilter.subPortfolio);
        this.subPortfolioFilter = this.subPortfolioList[0];
        if (this.subPortfolioList != undefined) {
          if (this.subPortfolioList.length > 0) {
            this.isSubPortfolio = false;
          }
        }
      } else {
        this.subPortfolioList = [{ id: undefined, name: 'All' }];
        this.isSubPortfolio = true;
      }
    } else {
      this.isSubportfolioFilter = false;
    }
  }

  subPortfolioValueChanged() {
    if (this.subPortfolioFilter || this.portfolioFilter) {
      if (!this.isResetPressed) {
        this.filter();
      }
    }
  }

  programValueChanged() {
    if (!this.isResetPressed) {
      this.filter();
    }
  }

  subPortfolioChange() {
    if (this.subPortfolioFilter) {
      this.smallOrdersList = this.smallOrdersList.filter(reportObj => {
        if (reportObj.subPortfolio != undefined) {
          return reportObj.subPortfolio.id == this.subPortfolioFilter.id;
        }
      });
      this.isFilterApplied = true;
    }
  }

  getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        globalThis.localize.transform(item.itemData.name);
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }


  ngOnDestroy() {
    this.socketService.removeListener('fetchMultipleUsers');
    this.socketService.removeListener('smallOrdersFilter');
    this.socketService.removeListener("getSelectiveOrders");
    this.socketService.removeListener("smallOrdersFilterCount");
  }

  showNotificationBox(msg) {
    this.utilityService.showNotification(this.localize.transform("Warning"),
      this.localize.transform(msg), "warning");
  }


}
