import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubPortfolioDetailsComponent } from './sub-portfolio-details.component';

const routes: Routes = [
  {
    path: '',
    component: SubPortfolioDetailsComponent,
    children: [
      {
        path: 'budget/:budgetType',
        loadChildren: () => import('../portfolio-budget/portfolio-budget.module').then(mod => mod.PortfolioBudgetModule)
      },
      {
        path: 'ganttview',
        loadChildren: () => import('../../portfolio/sub-portfolio-gantt-chart/sub-portfolio-gantt-chart.module').then(mod => mod.SubPortfolioGanttChartModule)
      },
      {
        path: 'statusReport',
        loadChildren: () => import('./sub-portfolio-status-report/sub-portfolio-status-report.module').then(mod => mod.SubPortfolioStatusReportModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./sub-portfolio-projects/sub-portfolio-projects.module').then(mod => mod.SubPortfolioProjectsModule)
      },
      {
        path: 'milestones',
        loadChildren: () => import('./sub-portfolio-milestones/sub-portfolio-milestones.module').then(mod => mod.SubPortfolioMilestonesModule)
      },
      {
        path: 'risks',
        loadChildren: () => import('./sub-portfolio-risks/sub-portfolio-risks.module').then(mod => mod.SubPortfolioRisksModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./sub-portfolio-analytics/sub-portfolio-analytics.module').then(mod => mod.SubPortfolioAnalyticsModule)
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubPortfolioDetailsRoutingModule { }
