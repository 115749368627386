import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  currencyOptions = ['CHF', 'USD', 'EUR', 'GBP'];
  baseCurrency;
  rates: {
    GBP: number;
    USD: number;
    CHF: number;
    EUR: number;
  };
  version: any = 'v1.0';
  releasingDate: any = '26/12/2019';

  constructor(private apiService: ApiService, private authService: AuthService, private utilityService: UtilityService,
    private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.rates = {
      GBP: 1,
      USD: 1,
      CHF: 1,
      EUR: 1
    };

    this.utilityService.breadcrumb = 'Currency';
    this.utilityService.breadcrumbList = ['Settings', 'Currency'];
    this.baseCurrency = this.authService.userObj.configuration.baseCurrency;
    this.rates = this.authService.userObj.configuration.rates;
  }

  ngOnInit() {
  }

  save() {
    this.spinner.show();
    this.apiService.put('/configurations/' + this.authService.userObj.configuration.id, {
      baseCurrency: this.baseCurrency,
      rates: this.rates
    }).subscribe(response => {
      this.authService.userObj.configuration = response;
      localStorage.setItem('userObj', JSON.stringify(this.authService.userObj));

      this.utilityService.showNotification(
        this.localize.transform("Save Success"),
        this.localize.transform("Currency Configuration has been saved"),
        "success",
      );
      this.spinner.hide();
    });
  }
}
