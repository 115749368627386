import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import * as XLSX from 'ts-xlsx';
import { ClipboardService } from 'ngx-clipboard';
declare const $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  tabsEN = [];
  tabsDE = [];
  tabs: FormGroup;
  tabsItemId = 'dashboard';
  BASE_PATH;
  attachmentQueryParams = '';
  arrayBuffer: any;
  file: File;
  dropDown = false

  constructor(public utilityService: UtilityService, public authService: AuthService, public apiService: ApiService, private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder, public router: Router, private localize: I18nPipe, private clipboardService: ClipboardService) {
    this.tabsEN = ['Dashboard', 'Projects', 'Gantt View', 'Project Pipeline', 'Programs', 'Portfolio List'];
    this.tabsDE = ['Dashboard', 'Projekte', 'Gantt-Ansicht', 'Projektpipeline', 'Programme', 'Portfolio-Liste'];

    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
  }

  ngOnInit() {
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 500);

    this.tabs = this.formBuilder.group({
    });

    this.utilityService.portfolioTab.subscribe(portfolio => {
      if (portfolio.tabIndex != undefined) {
        if (portfolio.tabIndex * 1 == 5) {
          setTimeout(() => {
            $('app-dashboard .nav-item a[href="#portfolio"]').click();
            this.tabsItemId = 'portfolio';
          }, 1000);
        }
      }
    });
  }

  refreshDashboard() {
    this.utilityService.refreshDashboard();
    this.utilityService.refreshProjects();
  }

  ngAfterViewInit() {
    this.utilityService.initTabs();
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    if (this.file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.spinner.show();
      this.readFile();
    } else {
      this.showNotification("Failed", "Please upload a valid Excel File!", "warning");
    }
  }
  dropDownMenu(){
    this.dropDown = !this.dropDown
   
  }
  readFile() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.utilityService.processExcelFile(excelData);
    }

    fileReader.readAsArrayBuffer(this.file);
  }

  copyProjectsIdentifiers() {
    this.spinner.show();
    this.apiService.get('/dashboard/getActiveProjectsIdentifiers').subscribe((res: any = []) => {
      let psps = res.map(project => project.projectIdentifier);
      let formattedIds = psps.join("\r\n");
      this.clipboardService.copyFromContent(formattedIds);
      this.showNotification("Copied", "Active Projects PSPs have been copied!", "success")
      this.spinner.hide();
    })
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  openModal() {
    if (this.utilityService.dashboardFilterBtn.observers.length > 1) {
      let oberser = this.utilityService.dashboardFilterBtn.observers[0];
      this.utilityService.dashboardFilterBtn.observers = [];
      this.utilityService.dashboardFilterBtn.observers.push(oberser);
    }
    this.utilityService.dashboardFilterBtn.next();
  }
}
