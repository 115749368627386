import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { DxAutocompleteModule, DxDateBoxModule, DxSwitchModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

@NgModule({
    imports: [RouterModule, CommonModule, I18nModule, DxSwitchModule, DxAutocompleteModule, FormsModule, DxDateBoxModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
    providers: [DatePipe]
})

export class SidebarModule { }
