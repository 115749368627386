import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SmallOrdersRoutingModule } from './small-orders-routing.module';
import { SmallOrdersComponent } from './small-orders.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "../shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClipboardModule } from 'ngx-clipboard';
import { ModalModule } from 'ngx-bootstrap';
import { PDFModule } from '../pdf/pdf.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';

@NgModule({
  imports: [
    CommonModule,
    SmallOrdersRoutingModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    CurrencyFormatterPipeModule,
    NgxSpinnerModule,
    ClipboardModule,
    ModalModule,
    PDFModule
  ],
  declarations: [
    SmallOrdersComponent
  ]
})
export class SmallOrdersModule { }
