import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import swal from 'sweetalert2';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit, OnDestroy {
  projectList: any = [];
  activeCount: number = 0;
  closedCount: number = 0;
  outlinePreview;
  orderPreview;
  changeRequestPreview;
  closingReportPreview;
  showPreview = false;
  showOrderPreview = false;
  showChangePreview = false;
  showClosingPreview = false;
  activeProjectsPageIndex = 2;
  closedProjectsPageIndex = 2;
  workflowProgress = 0;
  @ViewChild('lgModal', { static: false }) public lgModal;
  @ViewChild('lgOrderModal', { static: false }) public lgOrderModal;
  @ViewChild('lgChangeModal', { static: false }) public lgChangeModal;
  @ViewChild('lgClosingModal', { static: false }) public lgClosingModal;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private utilityService: UtilityService, private localize: I18nPipe,
    private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService,
    private router: Router) {
    this.utilityService.breadcrumb = 'Workflow';
    this.utilityService.breadcrumbList = ['Settings', 'Workflow'];
    this.BASE_PATH = this.apiService.BASE_PATH + '/attachment/protected';
    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
  }

  ngOnInit() {
    this.spinner.show();
    this.socketService.listen('resetProjectsCount').subscribe(activeCount => {
      this.activeCount = activeCount;
      this.workflowProgress = (this.projectList.length * 100 / this.activeCount);

      if (activeCount > 20) {
        this.socketService.emitEvent('resetProjectsIndex', { pageIndex: this.activeProjectsPageIndex, pageSize: 20 });
      }
    });

    this.socketService.listen('resetProjectsIndex').subscribe(response => {
      this.projectList = this.projectList.concat(response);
      this.workflowProgress = (this.projectList.length * 100 / this.activeCount);

      if (this.activeProjectsPageIndex < (this.activeCount / 20)) {
        this.activeProjectsPageIndex++;
        this.socketService.emitEvent('resetProjectsIndex', { pageIndex: this.activeProjectsPageIndex, pageSize: 20 });
      }
    });

    this.getActiveProjects();
  }

  getActiveProjects(): void {
    this.spinner.show();
    this.apiService.get('/projects/getResetProjects?limit=20').subscribe(response => {
      this.projectList = response;
      this.socketService.emitEvent('resetProjectsCount', {});
      this.spinner.hide();
    });
  }

  notification(title, message, color = '#5b835b') {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      'success',
    );
  }

  openOutlinePreview(outline) {
    this.showPreview = false;
    outline.projectOutline[0].projectName = outline.projectName;
    this.apiService.get('/projectOutline/' + outline.projectOutline[0].id).subscribe(response => {
      this.outlinePreview = response;

      setTimeout(() => {
        this.showPreview = true;
      }, 100);
      this.lgModal.show();
      this.lgModal.onHidden.subscribe((reason: string) => {
        this.showPreview = false;
      });
    });
  }

  openOrderPreview(project, order) {
    this.showOrderPreview = false;
    order.projectId = project;
    order = this.utilityService.setOrderPdfValues(order);
    this.orderPreview = order;

    setTimeout(() => {
      this.showOrderPreview = true;
    }, 100);
    this.lgOrderModal.show();
    this.lgOrderModal.onHidden.subscribe((reason: string) => {
      this.showOrderPreview = false;
    });
  }

  openChangeRequestPreview(project, changeRequest) {
    this.showChangePreview = false;
    changeRequest.project = project;
    changeRequest = this.utilityService.setChangeRequestPdfValues(changeRequest);
    this.changeRequestPreview = changeRequest;

    setTimeout(() => {
      this.showChangePreview = true;
    }, 100);
    this.lgChangeModal.show();
    this.lgChangeModal.onHidden.subscribe((reason: string) => {
      this.showChangePreview = false;
    });
  }

  openClosingReportPreview(project, closingReport) {
    this.showClosingPreview = false;
    closingReport.project = project;
    closingReport = this.utilityService.setClosingReportPdfValues(closingReport);
    this.closingReportPreview = closingReport;

    setTimeout(() => {
      this.showClosingPreview = true;
    }, 100);
    this.lgClosingModal.show();
    this.lgClosingModal.onHidden.subscribe((reason: string) => {
      this.showClosingPreview = false;
    });
  }

  showChangeRequest(data) {
    let flag;
    if (data.key.closingReport) {
      flag = data.key.closingReport.length > 0 ?
        (data.key.closingReport[0].isApproved == true ? false : (data.key.closingReport[0].isSubmitted == true ?
          false : true)) : true;
    }
    if (flag) {
      if (data.key.changeRequests.length > 0) {
        return data.key.changeRequests != undefined ?
          (data.key.changeRequests[data.key.changeRequests.length - 1].isApproved == false ?
            (data.key.changeRequests[data.key.changeRequests.length - 1].isSubmitted == true ?
              false : true)
            : true)
          : true;
      } else {
        return true;
      }
    } else {
      return flag;
    }
  }


  recallOutline(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('When the request is recalled, the Manager must resubmit the request. Do you wish to continue?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.put('/projects/' + obj.id, {
          status: 'Recalled',
          isFreezed: false,
          outlineSubmitted: true,
          outlineRecalled: true,
          workflowStatus: 'Outline has been recalled.',
          outlineRecallDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.get('/getOutlinesByProject/' + obj.id + '?limit=0').subscribe((approvals: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);

            let approvalObj = approvals[0];
            approvalObj.status = 'Recalled';
            approvalObj.isFreezed = true;
            approvalObj.outlineRecalled = true;
            approvalObj.projectStatus = 'Recalled';
            approvalObj.overallStatus = "Recalled";
            approvalObj.outlineRecallDate = new Date().toISOString();

            let approvedOutineNew = approvalObj.projectOutline;
            approvedOutineNew.projectId = projectResponse;
            approvedOutineNew.projectId.status = 'Recalled';
            approvedOutineNew.status = 'Recalled';
            approvedOutineNew.projectId.workflowStatus = 'Outline has been recalled.';

            this.apiService.put('/outlineApproval/' + approvalObj.id, approvalObj).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Outline',
                  version: resp.version
                },
                projectItem: { projectOutline: approvedOutineNew, projectStatus: 'Recalled', outlineRecallDate: new Date().toISOString(), overallStatus: "Recalled" }
              }).subscribe((resp: any) => {
                let notificationEmails = [];
                notificationEmails.push(approvalObj.assignedTo.email);

                if (approvalObj.sentTo == 'Sponsor') {
                  let pmoEmail = approvals.filter(approval => approval.version == approvalObj.version && approval.docType == 'Outline' && approval.sentTo == 'PMO').map(pmoAppr => pmoAppr.assignedTo.email);
                  notificationEmails.push(...pmoEmail);
                }

                this.socketService.emitEvent('sendEmail', {
                  email: notificationEmails,
                  subject: "oneView: Workflow zurückgerufen",
                  message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Recalled Email')[0].text + ` <br>
                  <a href="${this.apiService.FrontEndPATH}/view/outline/${approvalObj.id}" target="_blank">${'Projektskizze / Project Outline : ' + response.projectName}</a>`
                });

                this.apiService.put('/projectOutline/' + response.projectOutline[0].id, {
                  status: 'Recalled',
                }).subscribe(outlineResponse => {
                });

                this.getActiveProjects();
                this.notification('Success', "Outline Recalled Successfully.", 'success');
                this.spinner.hide();
              });
            });
          });
        });
      }
    });
  }

  recallOrder(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('When the request is recalled, the Manager must resubmit the request. Do you wish to continue?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.put('/projects/' + obj.id, {
          status: 'Recalled',
          isOrderFreezed: false,
          orderSubmitted: true,
          workflowStatus: 'Order has been recalled.',
          orderRecalled: true,
          orderRecallDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.get('/getOutlinesByProject/' + obj.id + '?limit=0').subscribe((approvals: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);
            let approvalObj = approvals[0];
            approvalObj.status = 'Recalled';
            approvalObj.projectStatus = 'Recalled';
            approvalObj.orderRecalled = true;
            approvalObj.isFreezed = true;
            approvalObj.orderRecallDate = new Date().toISOString();
            approvalObj.overallStatus = "Recalled";

            let approvedOrderNew = approvalObj.projectOrder;
            approvedOrderNew.status = 'Recalled';
            approvedOrderNew.projectId.status = 'Recalled';
            approvedOrderNew.projectId = projectResponse;
            approvedOrderNew.projectId.orderRecallDate = new Date().toISOString();
            approvedOrderNew.projectId.workflowStatus = 'Order has been recalled.'

            this.apiService.put('/outlineApproval/' + approvalObj.id, approvalObj).subscribe((resp: any) => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: resp.project.id,
                  docType: 'Order',
                  version: resp.version
                },
                projectItem: { projectOrder: approvedOrderNew, projectStatus: 'Recalled', orderRecallDate: new Date().toISOString(), overallStatus: "Recalled" }
              }).subscribe((resp: any) => {
                this.apiService.put('/projectOrder/' + response.projectOrder[0].id, {
                  status: 'Recalled',
                }).subscribe(orderRes => {

                  let notificationEmails = [];
                  notificationEmails.push(approvalObj.assignedTo.email);

                  if (approvalObj.sentTo == 'Sponsor') {
                    let pmoEmail = approvals.filter(approval => approval.version == approvalObj.version && approval.docType == 'Order' && approval.sentTo == 'PMO').map(pmoAppr => pmoAppr.assignedTo.email);
                    notificationEmails.push(...pmoEmail);
                  } else if (approvalObj.sentTo == 'FICO') {
                    let previousEmails = approvals.filter(approval => approval.version == approvalObj.version && approval.docType == 'Order' && (approval.sentTo == 'PMO' || approval.sentTo == 'Sponsor')).map(pmoAppr => pmoAppr.assignedTo.email);
                    notificationEmails.push(...previousEmails);
                  }

                  this.socketService.emitEvent('sendEmail', {
                    email: notificationEmails,
                    subject: "oneView: Workflow zurückgerufen",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Recalled Email')[0].text + ` <br>
                     <a href="${this.apiService.FrontEndPATH}/view/order/${approvalObj.id}" target="_blank">${'Projektauftrag / Project Order : ' + response.projectName}</a>`
                  });

                  this.getActiveProjects();
                  this.notification('Success', "Order Recalled Successfully.", 'success');
                  this.spinner.hide();
                });
              });
            });
          });
        });
      }
    });
  }

  recallChangeRequest(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('When the request is recalled, the Manager must resubmit the request. Do you wish to continue?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.put('/projects/' + obj.id, {
          status: 'Recalled',
          changeRequestMade: false,
          changeRequestRecalled: true,
          workflowStatus: 'Change Request has been recalled back from approver.',
          changeRequestRecallDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.get('/getOutlinesByProject/' + obj.id + '?limit=0').subscribe((approvals: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);

            let approvedchangeRequest = approvals[0].project;
            let embeddedChangeRequest = approvals[0].changeRequest;

            approvedchangeRequest.status = 'Recalled';
            approvedchangeRequest.workflowStatus = 'Change Request has been recalled back from approver.'
            approvedchangeRequest.changeRequestRecallDate = new Date().toISOString();

            embeddedChangeRequest.project.status = 'Recalled';
            embeddedChangeRequest.project.workflowStatus = 'Change Request has been recalled back from approver.'
            embeddedChangeRequest.project.changeRequestRecallDate = new Date().toISOString();

            let approvalObj = approvals[0];

            this.apiService.put('/outlineApproval/' + approvalObj.id + '?populate=false', {
              project: approvedchangeRequest,
              status: 'Recalled',
              projectStatus: 'Recalled',
              changeRequestRecalled: true,
              isFreezed: true,
              changeRequestRecallDate: new Date().toISOString()
            }).subscribe((resp: any) => {

              this.apiService.post('/updatepreviousapproval?populate=false', {
                query: {
                  project: resp.project,
                  docType: 'Change Request',
                  version: resp.version,
                  crNo: resp.crNo,
                },
                projectItem: {
                  changeRequest: embeddedChangeRequest,
                  project: approvedchangeRequest,
                  projectStatus: 'Recalled',
                  changeRequestRecallDate: new Date().toISOString(),
                  overallStatus: "Recalled"
                }
              }).subscribe((resp: any) => {
                this.apiService.put('/changeRequest/' + approvalObj.changeRequest.id + '?populate=false', {
                  isApproved: false,
                  isSubmitted: true,
                  isEditable: true,
                  status: 'Recalled',
                }).subscribe(crResponse => {
                  this.apiService.put('/outlineApproval/' + approvalObj.id, {
                    changeRequest: crResponse,
                  }).subscribe((approvalResponse: any) => {
                    let notificationEmails = [];
                    notificationEmails.push(approvalObj.assignedTo.email);

                    if (approvalObj.sentTo == 'Sponsor') {
                      let pmoEmail = approvals.filter(approval => approval.version == approvalObj.version && approval.crNo == approvalObj.crNo && approval.docType == 'Change Request' && approval.sentTo == 'PMO').map(pmoAppr => pmoAppr.assignedTo.email);
                      notificationEmails.push(...pmoEmail);
                    } else if (approvalObj.sentTo == 'FICO') {
                      let previousEmails = approvals.filter(approval => approval.version == approvalObj.version && approval.crNo == approvalObj.crNo && approval.docType == 'Change Request' && (approval.sentTo == 'PMO' || approval.sentTo == 'Sponsor')).map(pmoAppr => pmoAppr.assignedTo.email);
                      notificationEmails.push(...previousEmails);
                    }

                    this.socketService.emitEvent('sendEmail', {
                      email: notificationEmails,
                      subject: "oneView: Workflow zurückgerufen",
                      message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Recalled Email')[0].text + ` <br>
                      <a href="${this.apiService.FrontEndPATH}/view/changeRequest/${approvalObj.id}" target="_blank">${'Änderungsantrag / Change Request : ' + response.projectName}</a>`
                    });

                    this.notification('Success', "Change Request has been recalled from Approver.", "success");
                    this.getActiveProjects();
                    this.spinner.hide();
                  });
                });
              });
            });
          });
        });
      }
    });
  }

  recallClosingReport(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform('When the request is recalled, the Manager must resubmit the request. Do you wish to continue?')}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.put('/projects/' + obj.id, {
          status: 'Recalled',
          changeRequestMade: false,
          workflowStatus: 'Closing Report has been recalled back from approver.',
          closingReportSubmitted: true,
          closingReportRecalled: true,
          closingReportRecallDate: new Date().toISOString()
        }).subscribe((response: any) => {
          this.apiService.get('/getOutlinesByProject/' + obj.id + '?limit=0').subscribe((approvals: any) => {
            let projectResponse = Object.assign({}, response);
            delete (projectResponse.projectOutline);
            delete (projectResponse.projectOrder);
            delete (projectResponse.history);
            delete (projectResponse.changeRequests);
            delete (projectResponse.closingReport);
            delete (projectResponse.approvals);
            delete (projectResponse.user);
            delete (projectResponse.subPortfolio);

            let approvalObj = approvals[0];

            let approvedclosingReport = approvalObj.closingReport;
            approvedclosingReport.project.status = 'Recalled';
            approvedclosingReport.project.closingReportRecallDate = new Date().toISOString();
            approvedclosingReport.project.workflowStatus = 'Closing Report has been recalled back from approver.'

            approvalObj.status = 'Recalled';
            approvalObj.projectStatus = 'Recalled';
            approvalObj.isFreezed = true;
            approvalObj.closingReportRecallDate = new Date().toISOString();
            approvalObj.overallStatus = "Recalled";

            this.apiService.put('/outlineApproval/' + approvalObj.id, approvalObj).subscribe(approvalResponse => {
              this.apiService.post('/updatepreviousapproval/', {
                query: {
                  project: approvalObj.project.id,
                  docType: 'Closing Report',
                  version: approvalObj.version
                },
                projectItem: { closingReport: approvedclosingReport, projectStatus: 'Recalled', closingReportRecallDate: new Date().toISOString(), overallStatus: "Recalled" }
              }).subscribe((resp: any) => {
                this.apiService.put('/closingReport/' + approvalObj.closingReport.id, {
                  isApproved: false,
                  isSubmitted: false,
                  status: 'Recalled'
                }).subscribe(crResponse => {
                  let notificationEmails = [];
                  notificationEmails.push(approvalObj.assignedTo.email);

                  if (approvalObj.sentTo == 'Sponsor') {
                    let pmoEmail = approvals.filter(approval => approval.version == approvalObj.version && approval.docType == 'Closing Report' && approval.sentTo == 'PMO').map(pmoAppr => pmoAppr.assignedTo.email);
                    notificationEmails.push(...pmoEmail);
                  } else if (approvalObj.sentTo == 'FICO') {
                    let previousEmails = approvals.filter(approval => approval.version == approvalObj.version && approval.docType == 'Closing Report' && (approval.sentTo == 'PMO' || approval.sentTo == 'Sponsor')).map(pmoAppr => pmoAppr.assignedTo.email);
                    notificationEmails.push(...previousEmails);
                  }

                  this.socketService.emitEvent('sendEmail', {
                    email: notificationEmails,
                    subject: "oneView: Workflow zurückgerufen",
                    message: this.utilityService.emailTemplates.filter(val => val.event == 'Project Recalled Email')[0].text + ` <br>
                    <a href="${this.apiService.FrontEndPATH}/view/closingReport/${approvalObj.id}" target="_blank">${'Abschlussbericht /Closing Report : ' + response.projectName}</a>`
                  });

                  this.getActiveProjects();
                  this.notification('Success', "Closing Report has been recalled to sender.", "success");
                  this.spinner.hide();
                });
              });
            });
          });
        });
      }
    });
  }


  navigateToProjectDetails(routeObj, isTeamProject, isTeamEdit) {
    this.utilityService.isTeamProject = isTeamProject;
    this.utilityService.isTeamEdit = isTeamEdit;
    this.router.navigate(routeObj);
  }

  ngOnDestroy() {
    this.socketService.removeListener('resetProjectsCount');
    this.socketService.removeListener('resetProjectsIndex');
  }
}
