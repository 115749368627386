import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { ClipboardService } from 'ngx-clipboard';
import { SocketService } from 'app/services/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-program-status-report',
  templateUrl: './program-status-report.component.html',
  styleUrls: ['./program-status-report.component.scss']
})
export class ProgramStatusReportComponent implements OnInit, OnDestroy {
  progSponsorEmail;
  tabs: FormGroup
  currentTab = 'Overview';
  programObj: any = {
    programCostTable1: [],
    programSponsor: {
      id: 0
    },
    programManager: {
      id: 0
    },
  };
  usersList = [];
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  programCostTable1;
  aggregatedProgramTable1;
  programId;
  statusReportTable = [];
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  totalTime;
  timeCompleted;
  endDateVSPlannedEndDate;
  plannedDateVSForecastDate;
  tabIndex = 0;
  prevStatusReport;
  goals: any = [];
  programBudgetCurrentYear = [];
  programBudgetNextYear = [];
  programBudgetCurrentYearCheck: boolean = false;
  programBudgetNextYearCheck: boolean = false;
  statusOptions = [];
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  public uploader1: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });
  attachment1;
  attachment2;
  attachment1Uploaded = true;
  attachment2Uploaded = true;
  showNotification = false;
  FrontEnd_PATH;
  isProgramCost: boolean = false;
  isAggregatedProgramCost: boolean = true;
  tabsItemId;
  isVisible: boolean = false;
  stateStore;

  constructor(public utilityService: UtilityService, private apiService: ApiService, public authService: AuthService,
    private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
    private localize: I18nPipe, private clipboardService: ClipboardService, private socketService: SocketService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Program Details";
    this.currentTab = "Overview";

    this.FrontEnd_PATH = this.apiService.FrontEndPATH;

    globalThis = this;

    this.stateStore = {
      enabled: true,
      storageKey: 'programStatusReportList',
      type: 'localStorage',
      savingTimeout: 200
    };

    this.route.params.subscribe(params => {
      this.programId = params.id;
      this.socketService.emitEvent('fetchProgram', { programId: params.id })
    });

    this.socketService.listen('programStatusReports').subscribe(response => {
      this.statusReportTable = response;
      this.isVisible = true;
      this.spinner.hide();
    });

    this.socketService.listen('fetchProgram').subscribe(response => {
      this.programObj = response;
      this.utilityService.breadcrumbList = ['My Programs', this.programObj.programName, 'Details', 'Status Report'];

      if (this.programObj.programCostTable1 != undefined && this.programObj.programCostTable1 != undefined) {
        this.programCostTable1 = this.programObj.programCostTable1;
        this.programCostTable1 = this.programObj.programCostTable1;
      }

      if (this.programObj.programBudgetCurrentYear != undefined) {
        this.programBudgetCurrentYear = this.programObj.programBudgetCurrentYear;
      }
      if (this.programObj.programBudgetNextYear != undefined) {
        this.programBudgetNextYear = this.programObj.programBudgetNextYear;
      }
      if (this.programObj.programBudgetCurrentYearCheck != undefined) {
        this.programBudgetCurrentYearCheck = this.programObj.programBudgetCurrentYearCheck;
      }
      if (this.programObj.programBudgetNextYearCheck != undefined) {
        this.programBudgetNextYearCheck = this.programObj.programBudgetNextYearCheck;
      }

      if (this.programObj.programManager == undefined) {
        this.programObj.programManager = {
          email: ''
        }
      }

      if (this.programObj.programSponsor == undefined) {
        this.programObj.programSponsor = {
          email: ''
        }
      }

      if (this.programObj.goals != undefined) {
        this.goals = this.programObj.goals;
      }

      this.fetchStatusReports(this.programId);

      if (this.programObj.isProgramCost != undefined) {
        this.isProgramCost = this.programObj.isProgramCost;
      }

      if (this.programObj.isAggregatedProgramCost != undefined) {
        this.isAggregatedProgramCost = this.programObj.isAggregatedProgramCost;
      }

      if (this.programObj.programSponsor && this.programObj.programSponsor.email != '') {
        this.progSponsorEmail = this.programObj.programSponsor.email;
      }
    });
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  fetchStatusReports(id) {
    this.socketService.emitEvent('programStatusReports', { id });
  }

  actionEvent(event, item) {
    let elem: any = document.getElementById('status-' + item.key.id);

    if (event.target.value == "Edit") {
      this.router.navigate([`/program/detail/statusReport/${this.programId}/${this.statusReportTable.length > 1 ? this.statusReportTable[this.statusReportTable.length - 2].id : null}/${item.key.id}`]);
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "View") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        this.openStatusPreview(item.key, item.component.getVisibleRows()[item.rowIndex - 1].key);
      } else {
        this.openStatusPreview(item.key, {});
      }
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "Delete") {
      this.deleteReportConfirmation(item.key.id);
      elem.options.selectedIndex = 0;
    } else if (event.target.value == "copy") {
      if (item.component.getVisibleRows()[item.rowIndex - 1] != undefined) {
        let text = `${this.FrontEnd_PATH}/view/program/statusReport/${item.component.getVisibleRows()[item.rowIndex - 1].key.id}/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      } else {
        let text = `${this.FrontEnd_PATH}/view/program/statusReport/0/${item.key.id}`;
        this.clipboardService.copyFromContent(text);
      }
      elem.options.selectedIndex = 0;
    }
  }

  deleteReportConfirmation(id) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('/programStatusReports/' + id).subscribe(response => {    // statusReports
          let data = this.statusReportTable.filter(report => report.id != id);
          this.statusReportTable = data;

          this.apiService.put('/program/' + this.programId, {
            hasDraftReport: false,
          }).subscribe(response => {
            this.programObj.hasDraftReport = false;
            this.createReportDisableChecker();
            swal(
              {
                title: `${this.localize.transform('Success')}`,
                text: `${this.localize.transform('Project Report has been Deleted.')}`,
                type: 'success',
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              }
            );
          });
        });
      }
    });
  }

  openStatusPreview(outline, prev) {
    this.statusReportPreview = outline;
    this.prevStatusReport = prev;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  format(value) {
    return 'Progress: ' + Math.round(value * 100) + '%';
  }

  createReportDisableChecker(): boolean {
    if (this.programObj.hasDraftReport == true || this.authService.userObj.role == 'guest') {
      return true;
    } else {
      return false;
    }
  }

  notificationBox(title, text, type) {
    swal({
      title: this.localize.transform(title),
      text: this.localize.transform(text),
      type: type,
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false
    });
  }


  ngOnDestroy() {
    this.socketService.removeListener('programStatusReports');
    this.socketService.removeListener('fetchProgram');
  }
}
