import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
declare var $: any;

@Component({
  selector: 'app-small-order-details',
  templateUrl: './small-order-details.component.html',
  styleUrls: ['./small-order-details.component.scss']
})
export class SmallOrderDetailsComponent implements OnInit, AfterViewInit {
  tabs: FormGroup;
  isTeamSmallOrder: boolean = false;

  constructor(public route: ActivatedRoute, public utilityService: UtilityService, private formBuilder: FormBuilder) {
    this.utilityService.breadcrumb = "Small Order Details";
  }

  ngOnInit() {
    this.isTeamSmallOrder = this.utilityService.isTeamSmallOrder;

    if (this.isTeamSmallOrder) {
        setTimeout(() => {
          $('app-small-order-details .nav-item a[href="#gantt"]').click();
        }, 1000);
    }

    this.tabs = this.formBuilder.group({
    });
  }

  ngAfterViewInit() {
    this.utilityService.initTabs();
  }

}
