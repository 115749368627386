import { Component, OnInit } from '@angular/core';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-errors-log',
  templateUrl: './errors-log.component.html',
  styleUrls: ['./errors-log.component.scss']
})
export class ErrorsLogComponent implements OnInit {
  logsCount: number = 0;
  logsList: any = [];

  constructor(private socketService: SocketService, private utilityService: UtilityService) { }

  ngOnInit() {
    this.utilityService.breadcrumbList = ['Settings', 'Errors Log'];
    this.socketService.emitEvent('errosLogsCount', {});
    this.socketService.emitEvent('errosLogsIndex', { pageIndex: 1, pageSize: 10 });


    this.socketService.listen('errosLogsCount').subscribe(count => {
      this.logsCount = count;
    });

    this.socketService.listen('errosLogsIndex').subscribe((response: any) => {
      this.logsList = response;
    });
  }

  onPageChange(event){
    this.socketService.emitEvent('errosLogsIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
  }

}
