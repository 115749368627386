import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
var globalThis;

@Component({
  selector: 'app-subportfolio-risks',
  templateUrl: './subportfolio-risks.component.html',
  styleUrls: ['./subportfolio-risks.component.scss']
})
export class SubportfolioRisksComponent implements OnInit, OnChanges {
  modalRef: BsModalRef;
  PMOsList;
  risksList;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  riskForm: any = {
    name: '',
    probability: 0,
    riskStrategy: {
      id: ''
    },
    description: '',
    measures: ''
  };
  statusOptions;
  @Input() risksInputList = [];
  @Input() isMember;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isEdit = false;
  risksTopFiveRiskStrategySelectOptions

  risksTopFiveReputationRiskSelectOptions = [{
    reputationRisks: 0,
  }, {
    reputationRisks: 1,
  }, {
    reputationRisks: 2,
  }, {
    reputationRisks: 3,
  }, {
    reputationRisks: 4,
  }];
  reportRelevantRisksCount = 0;
  backupForm;
  @Output() updateTotalExposure = new EventEmitter<any>();
  invalidChars = [
    "-",
    "+",
    "e",
  ];
  helpGuide;
  BASE_PATH;
  attachmentQueryParams = '';


  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService) {
    globalThis = this;
    this.PMOsList = this.utilityService.PMOsList;
    this.risksTopFiveRiskStrategySelectOptions = this.utilityService.risksTopFiveRiskStrategySelectOptions;

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.getHelpGuideData();
  }

  ngOnChanges(changes: any) {
    if (changes.risksInputList && changes.risksInputList.currentValue != undefined) {
      this.risksInputList = changes.risksInputList.currentValue;
      this.risksList = changes.risksInputList.currentValue;
      this.reportRelevantRisksCount = 0;
      this.risksInputList.forEach(risk => {
        if (risk.report) {
          this.reportRelevantRisksCount++;
        }
      });
      this.dataGrid.instance.option('dataSource', this.risksList);
    }
  }

  ngOnInit() {
    this.statusOptions = ['Active', 'Inactive'];
    this.risksList = [];
    if (this.risksInputList != undefined) {
      this.risksList = this.risksInputList;
      this.reportRelevantRisksCount = 0;
      this.risksInputList.forEach(risk => {
        if (risk.report) {
          this.reportRelevantRisksCount++;
        }
      });
      this.dataGrid.instance.option('dataSource', this.risksList);
    } else {
      this.risksList = [];
      this.reportRelevantRisksCount = 0;
    }

    if (this.reportRelevantRisksCount == undefined) {
      this.reportRelevantRisksCount = 0;
    }
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Program Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  onEditorPreparingMilestonesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'projectName') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
    }
  }

  onEditorPreparingRisksTopFiveTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'id') {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = 'Enter your description';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'extentOfLosses') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = 'CHF';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  calculateRiskExposureValue(e) {
    if (typeof (e.extentOfLosses) == 'number' && typeof (e.probability) == 'number') {
      globalThis.risksList[e.id - 1].riskExposure = e.extentOfLosses * e.probability / 100;
      return globalThis.risksList[e.id - 1].riskExposure;
    } else {
      return '';
    }
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.riskForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  openDeleteDialog(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant risk cannot be deleted.', 'warning');
        } else {
          let idx;
          this.risksList.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });

          this.risksList.splice(idx, 1);
          //Updating id's 
          for (let index = 0; index < this.risksList.length; index++) {
            this.risksList[index].id = index + 1;
          }
          this.updateTotalExposure.emit({
            risks: this.risksList
          });
          this.dataGrid.instance.option('dataSource', this.risksList);
          this.showNotificationBox('Success', 'Risk deleted.', 'success');
        }
      }
    });
  }

  save() {
    if (this.riskFormValidation() && this.checkRange()) {
      if (this.risksList.length == 0) {
        this.riskForm.id = 1;
      } else {
        this.riskForm.id = this.risksList[this.risksList.length - 1].id + 1;
      }

      if (typeof (this.riskForm.extentOfLosses) == 'number' && typeof (this.riskForm.probability) == 'number') {
        this.riskForm.riskExposure = this.riskForm.extentOfLosses * this.riskForm.probability / 100;
      }

      if (this.riskForm.report == true) {
        if (this.reportRelevantRisksCount <= 2) {
          this.risksList.push(this.riskForm);
          this.resetForm();
          this.reportRelevantRisksCount++;
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.risksList);
          this.updateTotalExposure.emit({
            risks: this.risksList
          });
          this.modalRef.hide();
        } else {
          this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
        }
      } else {
        this.risksList.push(this.riskForm);
        this.resetForm();
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.risksList);
        this.updateTotalExposure.emit({
          risks: this.risksList
        });
        this.modalRef.hide();
      }
    }
  }

  edit() {
    if (this.riskFormValidation() && this.checkRange()) {
      if (this.riskForm.report == true) {
        if (this.backupForm.report == true && this.riskForm.report == true) {

          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.risksList);
          this.updateTotalExposure.emit({
            risks: this.risksList
          });
          this.modalRef.hide();
        } else {
          if (this.reportRelevantRisksCount <= 2) {
            if (this.backupForm.report == false && this.riskForm.report == true) {
              this.reportRelevantRisksCount++;
            }
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.risksList);
            this.updateTotalExposure.emit({
              risks: this.risksList
            });
            this.modalRef.hide();
          } else {
            this.riskForm.report = false;
            this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
          }
        }
      } else {
        if (this.backupForm.report == true && this.riskForm.report == false) {
          this.reportRelevantRisksCount--;
        }

        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.risksList);
        this.updateTotalExposure.emit({
          risks: this.risksList
        });
        this.modalRef.hide();
      }
    }
  }

  resetForm() {
    this.riskForm = {
      name: '',
      report: false,
      riskStrategy: {
        id: ''
      },
      description: '',
      measures: ''
    };
  }

  checkRange(): boolean {
    if (this.riskForm.probability < 100) {
      return true;
    } else {
      this.showNotificationBox('Warning', 'Values must be between 1-99.', 'warning');
      return false;
    }
  }

  riskStrategyValueChanged(e) {
    if (this.risksTopFiveRiskStrategySelectOptions != undefined) {
      this.risksTopFiveRiskStrategySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.riskForm.riskStrategy = response;
        }
      });
    }
  }

  riskFormValidation(): boolean {
    if (this.riskForm.description.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a risk description first.', 'warning');
      return false;
    }
    if (this.riskForm.riskStrategy.name == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk strategy first.', 'warning');
      return false;
    }
    if (this.riskForm.measures.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a risk measure first.', 'warning');
      return false;
    }
    if (this.riskForm.extentOfLosses == "" || this.riskForm.extentOfLosses == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk extent of losses first.', 'warning');
      return false;
    }
    if (this.riskForm.probability == "" || this.riskForm.probability == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk probability first.', 'warning');
      return false;
    }
    else {
      return true;
    }
  }

  onKeyExtentOfLossesInfo(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  onKeyProbabilityInfo(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}