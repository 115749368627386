import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateProjectReportRoutingModule } from './create-project-report-routing.module';
import { CreateProjectReportComponent } from './create-project-report.component';
import {
  DxDataGridModule, DxDateBoxModule, DxSliderModule, DxRadioGroupModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxAutocompleteModule
} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ModalModule } from 'ngx-bootstrap';
import { PDFProjectStatusReportModule } from 'app/projects/project-detail/pdf-project-status-report/pdf-project-status-report.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProjectDecisionsModule } from './project-decisions/project-decisions.module';
import { ProjectMeasuresModule } from './project-measures/project-measures.module';
import { ProjectMilestonesModule } from './project-milestones/project-milestones.module';
import { ProjectRisksModule } from './project-risks/project-risks.module';

@NgModule({
  imports: [
    CommonModule,
    CreateProjectReportRoutingModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxSliderModule,
    DxRadioGroupModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxAutocompleteModule,
    ModalModule,
    PDFProjectStatusReportModule,
    NgxSpinnerModule,
    ProjectDecisionsModule,
    ProjectMeasuresModule,
    ProjectMilestonesModule,
    ProjectRisksModule
  ],
  declarations: [
    CreateProjectReportComponent
  ]
})
export class CreateProjectReportModule { }
