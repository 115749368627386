import { Routes } from '@angular/router';

import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { DropdownResolverService } from './resolvers/dropdown-resolver.service';
import { AuthGuard } from './gaurds/auth.guard';

export const AppRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      dropdowns: DropdownResolverService
    },
    loadChildren: () => import('./layouts/admin/admin-layout.module').then(mod => mod.AdminLayoutModule)
  },
  {
    path: 'auth/:id',
    component: AuthLayoutComponent,
    loadChildren: () => import('./components/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'signout',
    component: AuthLayoutComponent,
    loadChildren: () => import('./logout/logout.module').then(mod => mod.LogoutModule)
  },
  {
    path: 'view/program/statusReport/:prev/:new',
    canActivate: [AuthGuard],
    loadChildren: () => import('./view/pdf-program-status-report-view/pdf-program-status-report-view.module').then(mod => mod.PdfProgramStatusReportViewModule)
  },
];
