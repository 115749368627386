import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersListWithPortfolioComponent } from './orders-list-with-portfolio.component';

const routes: Routes = [
  {
    path: '',
    component: OrdersListWithPortfolioComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersListWithPortfolioRoutingModule { }
