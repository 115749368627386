import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallOrderOrgChartRoutingModule } from './small-order-org-chart-routing.module';
import { SmallOrderOrgChartComponent } from './small-order-org-chart.component';
import { OrgChartModule } from 'app/components/org-chart/org-chart.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { I18nModule } from 'app/shared/i18n/i18n.module';


@NgModule({
  declarations: [SmallOrderOrgChartComponent],
  imports: [
    CommonModule,
    SmallOrderOrgChartRoutingModule,
    OrgChartModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule
  ]
})
export class SmallOrderOrgChartModule { }
