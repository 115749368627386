import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from 'app/services/api.service';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  newsList: any;
  newsCount: number = 0;
  newsSearch: string = ''
  isEditModal: boolean = false;
  formObj = {
    eventDate: new Date(),
    description: '',
    isPast: false,
    linkText: '',
    link: ''
  };
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  isEventDateValid: boolean = true;

  constructor(private modalService: BsModalService, private apiService: ApiService, private socketService: SocketService,
    private localize: I18nPipe, private utilityService: UtilityService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.apiService.get('/news?limit=10').subscribe(news => {
      this.newsList = news;
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Settings', 'News'];
    this.socketService.emitEvent('newsCount', {});
    this.socketService.listen('newsCount').subscribe(count => {
      this.newsCount = count;
    });

    this.socketService.listen('newsIndex').subscribe((response: any) => {
      this.newsList = response;
    });

    this.socketService.listen('newsSearchIndex').subscribe(response => {
      this.newsList = response;
    });

    this.socketService.listen('newsSearch').subscribe(newsResult => {
      this.newsCount = newsResult.count;
      this.newsList = newsResult.news;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEditModal = true;
    this.formObj = obj;
    this.formObj.isPast = obj.type == "Past" ? true : false;
    this.openModal(template);
  }

  closeModal() {
    if (this.isEditModal) {
      this.isEditModal = false;
    }
    this.modalRef.hide();
    this.resetInputFields();
  }

  resetInputFields() {
    this.formObj = {
      eventDate: new Date(),
      description: '',
      isPast: false,
      linkText: '',
      link: ''
    };
  }

  onSearchChange(event) {
    this.socketService.emitEvent('newsSearch', { search: event.target.value });
  }

  onPageChange(event) {
    if (this.newsSearch == '') {
      this.socketService.emitEvent('newsIndex', { pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    } else {
      this.socketService.emitEvent('newsSearchIndex', { search: this.newsSearch, pageIndex: event.pageIndex + 1, pageSize: event.pageSize });
    }
  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  save(formObj) {
    if (!this.isEventDateValid) {
      this.notification("Error", "Please enter event date in correct format (dd.MM.yyyy) first.", "danger");
      return;
    }
    if (formObj.eventDate == undefined || formObj.eventDate == '') {
      this.notification("Error", "Please enter a valid date.", "danger");
      return;
    }
    if (formObj.description == '') {
      this.notification("Error", "Please enter News Description.", "danger");
      return;
    }
    if (formObj.link != '' && (formObj.linkText == undefined || formObj.linkText == '')) {
      this.notification("Error", "Please enter Link Text for the entered Link.", "danger");
      return;
    }
    this.spinner.show();
    if (this.isEditModal) {
      this.apiService.put('/news/' + formObj.id, {
        eventDate: this.formObj.eventDate,
        description: this.formObj.description,
        isPast: this.formObj.isPast,
        linkText: this.formObj.linkText,
        link: this.formObj.link
      }).subscribe(news => {
        this.notification('Success', "News has been updated successfully.", 'success');
        this.socketService.emitEvent('newsCount', {});
        this.socketService.emitEvent('newsIndex', { pageIndex: 1, pageSize: 10 });
        this.closeModal();
        this.spinner.hide();
      })
    } else {
      this.apiService.post('/news', formObj).subscribe(news => {
        this.notification('Success', "News has been added successfully.", 'success');
        this.socketService.emitEvent('newsCount', {});
        this.socketService.emitEvent('newsIndex', { pageIndex: 1, pageSize: 10 });
        this.closeModal();
        this.spinner.hide();
      })
    }

  }
}
