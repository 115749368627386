import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectSanierungRoutingModule } from './project-sanierung-routing.module';
import { ProjectSanierungComponent } from './project-sanierung.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProjectSanierungComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    FormsModule,
    I18nModule,
    CurrencyFormatterPipeModule,
    ProjectSanierungRoutingModule
  ]
})
export class ProjectSanierungModule { }