import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateProgramReportRoutingModule } from './create-program-report-routing.module';
import { CreateProgramReportComponent } from './create-program-report.component';
import { DxDataGridModule, DxDateBoxModule, DxSliderModule, DxRadioGroupModule, 
  DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxPopoverModule, DxAutocompleteModule} from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { PDFProgramStatusReportModule } from 'app/programs/program-detail/pdf-program-status-report/pdf-program-status-report.module';
import { ModalModule } from 'ngx-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgramRisksModule } from './program-risks/program-risks.module';
import { ProgramMilestonesModule } from './program-milestones/program-milestones.module';
import { ProgramMeasuresModule } from './program-measures/program-measures.module';
import { ProgramDecisionsModule } from './program-decisions/program-decisions.module';

@NgModule({
  imports: [
    CommonModule,
    CreateProgramReportRoutingModule,
    DxDataGridModule,
    DxDateBoxModule, 
    DxSliderModule, 
    DxRadioGroupModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyFormatterPipeModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopoverModule,
    DxAutocompleteModule,
    PDFProgramStatusReportModule,
    ModalModule,
    NgxSpinnerModule,
    ProgramRisksModule,
    ProgramMilestonesModule,
    ProgramMeasuresModule,
    ProgramDecisionsModule
  ],
  declarations: [
    CreateProgramReportComponent
  ]
})
export class CreateProgramReportModule { }
