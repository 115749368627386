import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects.component';
import { RolesGuard } from 'app/gaurds/roles.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProjectsComponent,
      },
      {
        path: 'detail/:id',
        loadChildren: () => import('./project-detail/project-detail.module').then(mod => mod.ProjectDetailModule)
      }
      ,
      {
        path: 'outline',
        loadChildren: () => import('./project-outline/project-outline.module').then(mod => mod.ProjectOutlineModule)
      },
      {
        path: 'outline/:id',
        loadChildren: () => import('./project-outline/project-outline.module').then(mod => mod.ProjectOutlineModule)
      }
      ,
      {
        path: 'order/:id',
        loadChildren: () => import('./project-order/project-order.module').then(mod => mod.ProjectOrderModule)
      },
      {
        path: 'changeRequest/:id/:crId',
        loadChildren: () => import('./project-change-request/project-change-request.module').then(mod => mod.ProjectChangeRequestModule)
      },
      {
        path: 'closingReport/:id/:crId',
        loadChildren: () => import('./project-closing-report/project-closing-report.module').then(mod => mod.ProjectClosingReportModule)
      }
    ],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    },
    canActivate: [RolesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }