import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';

const LoginRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(LoginRoutes),
    I18nModule
  ],
  declarations: [
    LoginComponent,
  ],
  providers: [
  ]
})

export class LoginModule { }
