import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
let globalThis;

@Component({
  selector: 'app-create-status-report',
  templateUrl: './create-status-report.component.html',
  styleUrls: ['./create-status-report.component.scss']
})
export class CreateStatusReportComponent implements OnInit {
  reportObj: any = {
    overallStatusComments: '',
    scopeStatusComments: '',
    costStatusComments: '',
    timeStatusComments: '',
  };
  conf;
  subportfolioId;
  isNew = false;
  isPrev = false;
  reportId;
  options = ['Green', 'Yellow', 'Red'];
  riskOptions = ['Low', 'Medium', 'High'];
  programName = '';
  programId;
  isMember: boolean = false;
  statusReportPreview;
  showPreview = false;
  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  @ViewChild('budgetYearSelector', { static: false }) public budgetYearSelector: DxSelectBoxComponent
  prevStatusReport;
  lastStatusReport;
  overallCommentsLimit: boolean = false;
  scopeCommentsLimit: boolean = false;
  costCommentsLimit: boolean = false;
  timeCommentsLimit: boolean = false;
  isLocked: boolean = false;
  helpGuide;
  risksList = [];
  milestonesList = [];
  measuresList = [];
  decisionsList = [];
  aggregatedSubportfolioTable: any = [];
  yearsList: any = [];
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(public utilityService: UtilityService, private apiService: ApiService, private router: Router, private route: ActivatedRoute,
    private authService: AuthService, private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Create Report";

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.reportObj.overallStatus = 'Select One';
    this.reportObj.scopeStatus = 'Select One';
    this.reportObj.costStatus = 'Select One';
    this.reportObj.timeStatus = 'Select One';
    this.reportObj.riskStatus = 'Select One';
    this.reportObj.percentageComplete = 0;
    this.reportObj.risks = [];
    this.reportObj.milestones = [];
    this.reportObj.measures = [];
    this.reportObj.decisions = [];
    globalThis = this;
    this.getHelpGuideData();
  }

  ngOnInit() {
    this.spinner.show();
    this.initAggregatedValues();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    let sub = this.route.params.subscribe(params => {
      if (params.reportId != undefined) { //Edit
        this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Status Report', 'Edit'];
        this.reportId = params.reportId;
        this.subportfolioId = params.subPortfolio;

        this.apiService.get('/subportfolioStatusReport/' + params.reportId).subscribe((reportObj: any) => {
          this.reportObj = reportObj;
          this.aggregatedSubportfolioTable = reportObj.aggregatedSubportfolioFinancials;

          if (params.lastStatus != null && params.lastStatus != "null" && params.lastStatus != undefined) {
            this.apiService.get('/subportfolioStatusReport/' + params.lastStatus).subscribe((lastStatusReportObj: any) => {
              this.lastStatusReport = JSON.parse(JSON.stringify(lastStatusReportObj));
            });
          }
          this.spinner.hide();
        });
      } else { // New
        this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Status Report', 'Create'];
        this.isNew = true;
        this.isPrev = true;
        this.subportfolioId = params.subPortfolio;

        if (params.lastStatus == null || params.lastStatus == "null" || params.lastStatus == undefined) { // Case: No previous report
          this.spinner.hide();
        } else { // Case: Having previous report
          this.apiService.get('/subportfolioStatusReport/' + params.lastStatus).subscribe((reportObj: any) => {
            this.reportObj = reportObj;
            this.aggregatedSubportfolioTable = reportObj.aggregatedSubportfolioFinancials;
            this.lastStatusReport = JSON.parse(JSON.stringify(reportObj));
            delete (this.reportObj.id);
            this.spinner.hide();
          });
        }
      }
    });
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Program Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  fetchSubPortfolioBudgetYears(id) {
    this.spinner.show();
    this.apiService.get('/portfolioBudgetYear/' + id).subscribe((years: any) => {
      this.yearsList = years;
    })
  }

  onYearChanged(event) {
    this.spinner.show();
    this.apiService.get(`/projectBudgetCost/year/${event.value.id}`).subscribe(projectBudgetResponse => {
      this.apiService.get(`/orderBudgetCost/year/${event.value.id}`).subscribe(orderBudgetResponse => {
        this.calculateTotalYearlyFinancials(projectBudgetResponse, orderBudgetResponse);
      });
    });
  }

  calculateTotalYearlyFinancials(projects, orders) {
    this.initAggregatedValues();
    projects.forEach(project => {
      for (let i = 0; i < 7; i++) {
        this.aggregatedSubportfolioTable[i].forecast += parseInt(project.budget[i].yearlyForecast || 0);
        this.aggregatedSubportfolioTable[i].currentBudget += parseInt(project.budget[i].budget || 0);
        this.aggregatedSubportfolioTable[i].originalBudget += parseInt(project.budget[i].Yearly_Budget_Fixed || 0);
      }
    })

    orders.forEach(order => {
      for (let i = 0; i < 7; i++) {
        this.aggregatedSubportfolioTable[i].forecast += parseInt(order.budget[i].yearlyForecast || 0);
        this.aggregatedSubportfolioTable[i].currentBudget += parseInt(order.budget[i].budget || 0);
        this.aggregatedSubportfolioTable[i].originalBudget += parseInt(order.budget[i].Yearly_Budget_Fixed || 0);
      }
    })
    this.budgetYearSelector.instance.option("disabled", false);
    this.spinner.hide();
  }

  calculateVarianceValues(report) {
    this.conf = <any>(this.authService.getUserObj()).configuration;
  }

  onStatusValueChanged(event) {
    // this.calculateOverallStatus(event.value);
  }

  calculateOverallStatus(value) {
    if (this.options.indexOf(value) > this.options.indexOf(this.reportObj.overallStatus)) {
      this.reportObj.overallStatus = value;
    } else {
      //get biggest index
      for (let i = 2; i >= 0; i--) {
        if (this.reportObj.scopeStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        } else if (this.reportObj.timeStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        } else if (this.reportObj.costStatus == this.options[i]) {
          this.reportObj.overallStatus = this.options[i];
          break;
        }
      }
    }
  }

  showNotification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  submit() {
    this.isLocked = true;

    if (this.reportObj.reportingDate == "" || this.reportObj.reportingDate == null ||
      this.reportObj.reportingDate == undefined) {
      this.showNotification('Warning', 'Please select reporting date first.', 'warning');
      this.isLocked = false;
      return;
    }
    if (this.reportObj.overallStatus == 'Select One') {
      this.showNotification('Warning', 'Please select overall status first.', 'warning');
      this.isLocked = false;
      return;
    }
    if (this.reportObj.scopeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select scope status first.', 'warning');
      this.isLocked = false;
      return;
    }
    if (this.reportObj.costStatus == 'Select One') {
      this.showNotification('Warning', 'Please select cost status first.', 'warning');
      this.isLocked = false;
      return;
    }
    if (this.reportObj.timeStatus == 'Select One') {
      this.showNotification('Warning', 'Please select time status first.', 'warning');
      this.isLocked = false;
      return;
    }
    if (this.reportObj.riskStatus == 'Select One') {
      this.showNotification('Warning', 'Please select risk status first.', 'warning');
      this.isLocked = false;
      return;
    }

    this.reportObj.isSubmitted = true;
    this.reportObj.status = 'Submitted';
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);

    if (this.subportfolioId != undefined) {
      this.submitHelper(this.subportfolioId);
      // } else {
      //   this.apiService.get('/programStatusReports/' + this.reportId).subscribe((response: any) => {
      //     this.submitHelper(response.program.id);
      //   });
    }
  }

  submitHelper(id) {
    let obj: any = {};
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete).toFixed(0);
    this.reportObj.aggregatedSubportfolioFinancials = this.aggregatedSubportfolioTable;

    if (this.reportObj.id == undefined && this.isNew == true) { //Submitting New report
      this.reportObj.subportfolio = id;
      this.apiService.post('/subportfolioStatusReport', this.reportObj).subscribe(reportObj => {
        this.apiService.put('/subPortfolio/' + id, {
          hasDraftReport: false,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
          this.isLocked = false;
          this.router.navigate(['/dashboard/portfolio/subPortfolio/' + this.subportfolioId + '/details/statusReport/']);
        });
      });
    } else { //submitting drafted report
      this.apiService.put('/subportfolioStatusReport/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.apiService.put('/subPortfolio/' + id, {
          hasDraftReport: false,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Submit Success'), this.localize.transform('Status Report has been created.'), 'success');
          this.isLocked = false;
          this.router.navigate(['/dashboard/portfolio/subPortfolio/' + this.subportfolioId + '/details/statusReport/']);
        });
      });
    }
  }

  save() {
    this.isLocked = true;
    this.reportObj.status = 'Draft';
    this.reportObj.isSubmitted = false;
    this.reportObj.subportfolio = this.subportfolioId;
    this.reportObj.percentageComplete = Math.round(this.reportObj.percentageComplete);
    this.saveHelper();
  }

  saveHelper() {
    this.reportObj.aggregatedSubportfolioFinancials = this.aggregatedSubportfolioTable;

    if (this.reportObj.id != undefined && this.isNew == false) { //If saving existing report //not new
      let id = this.reportObj.subportfolio;

      this.apiService.put('/subportfolioStatusReport/' + this.reportObj.id, this.reportObj).subscribe(reportObj => {
        this.reportObj.program = id;
        this.apiService.put('/subPortfolio/' + id, {
          hasDraftReport: true,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Status Report has been saved.'), 'success');
          this.isLocked = false;
        });
      });
    } else { //Saving new status report
      if (this.isPrev == true) {
        delete (this.reportObj.id);
      }

      this.reportObj.subportfolio = this.subportfolioId;
      this.apiService.post('/subportfolioStatusReport', this.reportObj).subscribe((reportObj: any) => {
        this.reportObj.id = reportObj.id;

        this.apiService.put('/subPortfolio/' + this.subportfolioId, {
          hasDraftReport: true,
        }).subscribe(response => {
          this.utilityService.showNotification(this.localize.transform('Save Success'), this.localize.transform('Status Report has been saved.'), 'success');
          this.isLocked = false;

          // if (this.subportfolioId != undefined) { }
        });
      });
    }
  }

  initAggregatedValues() {
    this.aggregatedSubportfolioTable = [{
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 5,
      group: "OTHERS",
    }, {
      costType: "Total",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 6,
      group: "OTHERS",
    },];
  }

  onRowPreparedAggregated(e) {
    if (e.rowIndex == 9 || e.rowIndex == 0) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onCellPreparedAggregated(e) {
    if (e.columnIndex < 2 || e.columnIndex == 5) {
      e.cellElement.style.backgroundColor = "#ddd";
    }
    if (e.columnIndex > 0) {
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    } else if (e.columnIndex == 0) {
      e.cellElement.style.borderBottom = "1px solid #eee";
    }
  }

  calculateAggregateValue(e) {
    if (e.costType == 'Revenues') {
      return e.actualCost ? (Math.abs(e.actualCost) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedSubportfolioTable[6].actualCost = globalThis.projectAggregateCostsValueCalculator('actualCost');
      return globalThis.aggregatedSubportfolioTable[6].actualCost;
    } else {
      return e.actualCost ? e.actualCost : '';
    }
  }

  calculateAggregateForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.forecast ? (Math.abs(e.forecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedSubportfolioTable[6].forecast = globalThis.projectAggregateCostsValueCalculator('forecast');
      return globalThis.aggregatedSubportfolioTable[6].forecast;
    } else {
      return e.forecast ? e.forecast : '';
    }
  }

  calculateAggregateCurrentBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedSubportfolioTable[6].currentBudget = globalThis.projectAggregateCostsValueCalculator1('currentBudget');
      return globalThis.aggregatedSubportfolioTable[6].currentBudget;
    } else {
      return e.currentBudget ? e.currentBudget : '';
    }
  }

  calculateAggregateOriginalBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.originalBudget ? (Math.abs(e.originalBudget) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.aggregatedSubportfolioTable[6].originalBudget = globalThis.projectAggregateCostsValueCalculator1('originalBudget');
      return globalThis.aggregatedSubportfolioTable[6].originalBudget;
    } else {
      return e.originalBudget ? e.originalBudget : '';
    }
  }

  projectAggregateCostsValueCalculator(field) {
    let one: any = globalThis.aggregatedSubportfolioTable[0][field] * 1;
    let two: any = globalThis.aggregatedSubportfolioTable[1][field] * 1;
    let three: any = globalThis.aggregatedSubportfolioTable[2][field] * 1;
    let four: any = globalThis.aggregatedSubportfolioTable[3][field] * 1;
    let five: any = globalThis.aggregatedSubportfolioTable[4][field] * 1;
    let six: any = globalThis.aggregatedSubportfolioTable[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  projectAggregateCostsValueCalculator1(field) {
    let one: any = globalThis.aggregatedSubportfolioTable[0][field] * 1;
    let two: any = globalThis.aggregatedSubportfolioTable[1][field] * 1;
    let three: any = globalThis.aggregatedSubportfolioTable[2][field] * 1;
    let four: any = globalThis.aggregatedSubportfolioTable[3][field] * 1;
    let five: any = globalThis.aggregatedSubportfolioTable[4][field] * 1;
    let six: any = globalThis.aggregatedSubportfolioTable[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  parseMilestoneValues(response): Object {
    let ms = response.mileStonesValues;
    let obj: any = {};
    obj.reportingDate = new Date(this.reportObj.reportingDate);

    obj.milestone1 = ms.milestone1Completed ? null : ms[`milestone1Date`];
    obj.milestone2 = ms.milestone2Completed ? null : ms[`milestone2Date`];
    obj.milestone3 = ms.milestone3Completed ? null : ms[`milestone3Date`];
    obj.milestone4 = ms.milestone4Completed ? null : ms[`milestone4Date`];
    obj.milestone5 = ms.milestone5Completed ? null : ms[`milestone5Date`];
    obj.milestone6 = ms.milestone6Completed ? null : ms[`milestone6Date`];
    obj.milestone7 = ms.milestone7Completed ? null : ms[`milestone7Date`];
    obj.milestone8 = ms.milestone8Completed ? null : ms[`milestone8Date`];
    obj.milestone9 = ms.milestone9Completed ? null : ms[`milestone9Date`];
    obj.milestone10 = ms.milestone10Completed ? null : ms[`milestone10Date`];

    if (response.milestoneTable != undefined) {
      response.milestoneTable.push(obj);
    } else {
      response.milestoneTable = [obj];
    }
    return response;
  }

  format(value) {
    return value + "%";
  }

  openPreview() {
    this.statusReportPreview = this.reportObj;
    this.prevStatusReport = this.lastStatusReport;

    setTimeout(() => {
      this.showPreview = true;
    }, 100);
    this.lgModal.show();
    this.lgModal.onHidden.subscribe((reason: string) => {
      this.showPreview = false;
    });
  }

  backToProgramDetail() {
    this.router.navigate(['dashboard/portfolio/subPortfolio/details/']);
  }

  confirmation() {

    let msg = 'Changes are not possible once submitted. Are you sure you want to submit the report.';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.submit();
      }
    });
  }

  onKeyOverallStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 128) {
        e.preventDefault();
        this.overallCommentsLimit = true;
      } else {
        this.overallCommentsLimit = false;
      }
    } else {
      this.overallCommentsLimit = false;
    }
  }

  onKeyScopeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.scopeCommentsLimit = true;
      } else {
        this.scopeCommentsLimit = false;
      }
    } else {
      this.scopeCommentsLimit = false;
    }
  }

  onKeyCostStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.costCommentsLimit = true;
      } else {
        this.costCommentsLimit = false;
      }
    } else {
      this.costCommentsLimit = false;
    }
  }

  onKeyTimeStatusCommentsInfo(e) {
    let lines = e.target.value.split(/\r*\n/);
    if (e.key != 'Backspace' && e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
      if (e.target.scrollHeight > 108) {
        e.preventDefault();
        this.timeCommentsLimit = true;
      } else {
        this.timeCommentsLimit = false;
      }
    } else {
      this.timeCommentsLimit = false;
    }
  }

  onRightClick(e) {
    e.preventDefault();
  }

  updateMTAGrid(e: any) {
    this.reportObj.milestones = e.mileStonesValues;
  }

  updateTotalMeasures(e) {
    this.reportObj.measures = e.measuresList;
  }

  updateTotalExposure(e) {
    this.reportObj.risks = e.risks;
  }

  updateDecisions(e) {
    this.reportObj.decisions = e.decisionsList;
  }
}