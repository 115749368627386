import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

var globalThis;

@Component({
  selector: 'app-project-decisions',
  templateUrl: './project-decisions.component.html',
  styleUrls: ['./project-decisions.component.scss']
})
export class ProjectDecisionsComponent implements OnInit {
  decisionsList = [];
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  decisionForm: any = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '' };
  usersList;
  decisionTypeOptions;
  statusOptions;
  @Input() isMember;
  @Input() reportId;
  @ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;
  isEdit = false;
  public reportRelevantDecisionCount: number = 0;
  private backupForm: any;
  @Output() updateDecisionGrid = new EventEmitter<any>();
  helpGuide;
  isDecisionDateValid: boolean = true;
  userSearchResponse: any;
  BASE_PATH;
  attachmentQueryParams = '';

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private route: ActivatedRoute, private authService: AuthService,
    private spinner: NgxSpinnerService) {
    this.usersList = this.utilityService.usersList;

    this.attachmentQueryParams = `?accessToken=${localStorage.accessToken}&signature=${this.authService.userObj.id}`;
    this.BASE_PATH = this.apiService.BASE_PATH;

    this.getHelpGuideData();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.current != null && params.current != "null" && params.current != undefined) {
        this.apiService.get('/statusReports/' + params.current).subscribe((statusReportResponse: any) => {
          this.apiService.get('/reports/' + statusReportResponse.projectReport.id).subscribe((response: any) => { //this.reportId
            if (response.decisions == undefined) {
              this.decisionsList = [];
              this.reportRelevantDecisionCount = 0;
            } else {
              this.decisionsList = response.decisions;
              if (response.reportRelevantDecisionCount == undefined || this.decisionsList.length <= 0) {
                this.reportRelevantDecisionCount = 0;
              } else {
                this.reportRelevantDecisionCount = response.reportRelevantDecisionCount;
              }
              this.dataGrid.instance.option('dataSource', this.decisionsList);
            }
            if (this.decisionsList.length > 0) {
              this.reportRelevantDecisionCount = 0;
              for (let i = 0; i < this.decisionsList.length; i++) {
                if (this.decisionsList[i].report == true) {
                  this.reportRelevantDecisionCount++;
                }
              }
            }
          });
        });
      } else {
        this.apiService.get('/reports/' + params.id).subscribe((response: any) => { //this.reportId
          if (response.decisions == undefined) {
            this.apiService.put('/reports/' + this.reportId, {
              decisions: [],
              reportRelevantDecisionCount: 0
            }).subscribe(response => { });
          } else {
            this.decisionsList = response.decisions;
            if (this.decisionsList.length <= 0) {
              this.reportRelevantDecisionCount = 0;
            } else {
              this.reportRelevantDecisionCount = response.reportRelevantDecisionCount;
            }
            this.dataGrid.instance.option('dataSource', this.decisionsList);
          }
          if (this.decisionsList.length > 0) {
            this.reportRelevantDecisionCount = 0;
            for (let i = 0; i < this.decisionsList.length; i++) {
              if (this.decisionsList[i].report == true) {
                this.reportRelevantDecisionCount++;
              }
            }
          }
        });
      }
      // else { // First time creation of status report 
      //   this.apiService.get('/reports/' + params.projectReport).subscribe(response => {
      //     this.decisionsList = response.decisions;
      //     this.dataGrid.instance.option('dataSource', this.decisionsList);
      //   });
      // }
    });

    // this.decisionTypeOptions = ['Phase / Gate', 'Budget Increase', 'Project Steering', 'IT-Architectur', 'IT-Security', 'Compliance', 'Other'];
    this.decisionTypeOptions = this.utilityService.decisionTypeOptions;
    this.statusOptions = ['Requested', 'Accepted', 'Denied'];
  }

  getHelpGuideData() {
    this.apiService.get('/helpGuide/getHelpGuideByFormName/Project Status Report').subscribe((response: any) => {
      this.helpGuide = response.fields;
    });
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
    this.modalService.onHidden.subscribe((reason: string) => {
      this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
    });
  }

  async save() {
    this.userSearchResponse = await this.apiService.get('/user/search/' + this.decisionForm.decisionBy.name).retry(2).toPromise();
    if (this.decisionformValidation()) {
      if (this.decisionsList.length == 0) {
        this.decisionForm.id = 1;
      } else {
        this.decisionForm.id = this.decisionsList[this.decisionsList.length - 1].id + 1;
      }

      if (this.decisionForm.report == true) {
        if (this.reportRelevantDecisionCount <= 2) {
          this.decisionsList.push(this.decisionForm);
          this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
          this.decisionForm.report = this.reportId;
          this.reportRelevantDecisionCount++;
                  this.spinner.show();

          this.apiService.put('/reports/' + this.reportId, {
            decisions: this.decisionsList,
            reportRelevantDecisionCount: this.reportRelevantDecisionCount,
          }).subscribe(response => {
            this.isEdit = false;
            this.updateDecisionGrid.emit({ decisions: this.decisionsList });
            this.dataGrid.instance.option('dataSource', this.decisionsList);
            this.modalRef.hide();
	    this.spinner.hide();
          });
        } else {
          this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
        }
      } else {
        this.decisionsList.push(this.decisionForm);
        this.decisionForm = { decisionType: { id: '' }, decisionBy: { email: '' }, description: '', report: false };
        this.decisionForm.report = this.reportId;
                this.spinner.show();

        this.apiService.put('/reports/' + this.reportId, {
          decisions: this.decisionsList,
          reportRelevantDecisionCount: this.reportRelevantDecisionCount,
        }).subscribe(response => {
          this.isEdit = false;
          this.updateDecisionGrid.emit({ decisions: this.decisionsList });
          this.dataGrid.instance.option('dataSource', this.decisionsList);
          this.modalRef.hide();
                  this.spinner.hide();
        });
      }
    }
  }

  async edit() {
    this.userSearchResponse = await this.apiService.get('/user/search/' + this.decisionForm.decisionBy.name).retry(2).toPromise();
    if (this.decisionformValidation()) {
      if (this.decisionForm.report == true) {
        if (this.backupForm.report == true && this.decisionForm.report == true) {
              this.spinner.show();
          this.apiService.put('/reports/' + this.reportId, {
            decisions: this.decisionsList,
            reportRelevantDecisionCount: this.reportRelevantDecisionCount
          }).subscribe(response => {
            this.isEdit = false;
            this.updateDecisionGrid.emit({ decisions: this.decisionsList });
            this.dataGrid.instance.option('dataSource', this.decisionsList);
            this.modalRef.hide();
	    this.spinner.hide();
          });
        } else {
          if (this.reportRelevantDecisionCount <= 2) {
            if (this.backupForm.report == false && this.decisionForm.report == true) {
              this.reportRelevantDecisionCount++;
            }

            this.apiService.put('/reports/' + this.reportId, {
              decisions: this.decisionsList,
              reportRelevantDecisionCount: this.reportRelevantDecisionCount
            }).subscribe(response => {
              this.isEdit = false;
              this.updateDecisionGrid.emit({ decisions: this.decisionsList });
              this.dataGrid.instance.option('dataSource', this.decisionsList);
              this.modalRef.hide();
	      this.spinner.hide();
            });
          } else {
            this.decisionForm.report = false;
            this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
          }
        }
      } else {
        if (this.backupForm.report == true && this.decisionForm.report == false) {
          this.reportRelevantDecisionCount--;
        }
            this.spinner.show();
        this.apiService.put('/reports/' + this.reportId, {
          decisions: this.decisionsList
        }).subscribe(response => {
          this.isEdit = false;
          this.updateDecisionGrid.emit({ decisions: this.decisionsList });
          this.dataGrid.instance.option('dataSource', this.decisionsList);
          this.modalRef.hide();
	  this.spinner.hide();
        });
      }
    }
  }

  decisionformValidation(): boolean {
    if (this.reportId == undefined || this.reportId == null) {
      this.showNotificationBox('Warning', 'Please create the Sub-Project First', 'warning');
      return false;
    }
    if (!this.isDecisionDateValid) {
      this.showNotificationBox('Warning', 'Please enter decision date in correct format (dd.MM.yyyy) first.', 'warning');
      return false;
    }
    if (this.decisionForm.decisionBy.azureId == undefined || this.decisionForm.decisionBy.azureId == null) {
      this.showNotificationBox('Warning', 'Decision From field email which is not in active directory.', 'warning');
      return false;
    }
    let usersList = this.userSearchResponse;
    if (usersList.length <= 0) {
      this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
      return false;
    }
    if (usersList.length > 0) {
      let getUser = usersList.find(val => val.email == this.decisionForm.decisionBy.email);
      if (getUser == undefined || getUser == null) {
        this.showNotificationBox("Warning", "Decision From field email which is not in active directory.", "warning");
        return false;
      } else {
        return true;
      }
    } 
    else {
      return true;
    }
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.decisionForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  valueChangeEvent(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.decisionForm.decisionBy = response;
          }
        });
      }
    }
  }

  onInputEvent(e) {
    this.apiService.get('/user/search/' + this.decisionForm.decisionBy.email).retry(2).subscribe(response => {
      this.usersList = response;
    });
  }

  decisionTypeValueChanged(e) {
    if (this.decisionTypeOptions != undefined) {
      this.decisionTypeOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.decisionForm.decisionType = response;
        }
      });
    }
  }

  openDeleteDialog(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant decision cannot be deleted.', 'warning');
        } else {
          let idx;
          this.decisionsList.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });
          this.decisionsList.splice(idx, 1);

          // Updating id's 
          for (let index = 0; index < this.decisionsList.length; index++) {
            this.decisionsList[index].id = index + 1;
          }

          this.apiService.put('/reports/' + this.reportId, {
            decisions: this.decisionsList,
          }).subscribe(response => {
            this.updateDecisionGrid.emit({ decisions: this.decisionsList });
            this.dataGrid.instance.option('dataSource', this.decisionsList);
            // this.showNotificationBox('Success', 'Decision deleted.', 'success');
          });
        }
      }
    });
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
