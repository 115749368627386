import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment';
import { DxChartComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import * as jsPDF from 'jspdf';
import { ShortNumberPipe } from 'app/shared/short-number-pipe/short-number.pipe';
import "dhtmlx-gantt";
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
declare var html2canvas: any;
declare var gantt: any;
declare var $: any;
let globalThis;


@Component({
  selector: 'app-pdf-program-status-report',
  templateUrl: './pdf-program-status-report.component.html',
  styleUrls: ['./pdf-program-status-report.component.scss']
})
export class PdfProgramStatusReportComponent implements OnInit {
  @Input() report;
  @Input() prevReport;
  programReport;
  evaList = [];
  projectsPerProjectTypeChart;
  progressChart;
  financialTrend;
  @ViewChild('progressChart', { static: false }) progressChartElem: DxChartComponent;
  @ViewChild('financialTrend', { static: true }) financialTrendElem: DxChartComponent;
  @ViewChild('milestoneTrendAnalysis', { static: false }) milestoneTrendAnalysisElem: DxChartComponent;
  mtaChart: any = [{
    reportingDate: moment(Date.now()),
    milestones: [Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now(), Date.now()]
  }];
  mtaReport;
  risks = [];
  risksTopFiveRiskCategorySelectOptions;
  risksTopFiveReputationRiskSelectOptions = [{
    reputationRisks: 0,
  }, {
    reputationRisks: 1,
  }, {
    reputationRisks: 2,
  }, {
    reputationRisks: 3,
  }, {
    reputationRisks: 4,
  }];

  otherRisksRE = 0;
  totalRisksRE = 0;
  totalPercent = 0;

  riskPrevious = '';
  riskCurrent = '';

  showLoading: boolean = false;

  tasks: Task[] = [];
  links = [];
  ganttChart = false;
  reportsList;
  @ViewChild("gantt", { static: true }) ganttContainer: ElementRef;

  constructor(private apiService: ApiService, private authService: AuthService, private shortNumber: ShortNumberPipe, private localize: I18nPipe) {
    globalThis = this;

    this.financialTrend = [{
      type: this.localize.transform("Original Budget"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Current Budget"),
      value: 0,
    }, {
      type: this.localize.transform("Actual Cost"),
      value: 0,
    }, {
      type: this.localize.transform("Yearly Forecast"),
      value: 0,
    },];
  }

  ngOnInit() {
    if (this.report.risks != undefined) {
      for (let i = 0; i < this.report.risks.length; i++) {
        if (this.report.risks[i].report == false) {
          this.otherRisksRE += this.report.risks[i].riskExposure;
        }
        this.totalRisksRE += this.report.risks[i].riskExposure;
      }
    }

    this.otherRisksRE = +this.otherRisksRE.toFixed(2);
    this.totalRisksRE = +this.totalRisksRE.toFixed(2);

    // Calculate Risk previous and current status
    if (this.report.riskStatus == "Low") {
      this.riskCurrent = "L";
    } else if (this.report.riskStatus == 'Medium') {
      this.riskCurrent = 'M';
    } else {
      this.riskCurrent = 'H';
    }

    if (this.prevReport) {
      if (this.prevReport.riskStatus == "Low") {
        this.riskPrevious = "L";
      } else if (this.prevReport.riskStatus == 'Medium') {
        this.riskPrevious = 'M';
      } else if (this.prevReport.riskStatus == 'High') {
        this.riskPrevious = 'H';
      } else {
        this.riskPrevious = '';
      }
    } else {
      this.riskPrevious = '';
    }

    if (this.report.program.id != undefined) {
      this.report.program = this.report.program.id
    }

    // Setting Financial Trend Data
    if (this.report.financialTrend != undefined) {
      this.financialTrend = this.report.financialTrend;
    }

    setTimeout(() => {
      this.financialTrendElem.instance.option('dataSource', this.financialTrend);
    }, 0);

    this.apiService.get('/program/' + this.report.program).subscribe((projectReportObj: any) => {
      this.programReport = projectReportObj;

      if (this.programReport.aggregatedProgramTable1 != undefined) {
        if (this.programReport.aggregatedProgramTable1[6].currentBudget > 0 && this.totalRisksRE > 0) {
          this.totalPercent = +((this.totalRisksRE * 100) / this.programReport.aggregatedProgramTable1[6].currentBudget).toFixed(2);
        }
      }

      // Populating Financial trend Data
      if (this.programReport.aggregatedProgramTable1 != undefined) {
        this.initAggregatedValues();
        for (let i = 0; i < 7; i++) {
          this.programReport.reports.forEach((report, idx) => {
            this.programReport.aggregatedProgramTable1[i].actualCost += Math.round(report.costTypeTable[i].actualCost * this.currencyParser(report.currency));
            this.programReport.aggregatedProgramTable1[i].forecast += Math.round(report.costTypeTable[i].forecast * this.currencyParser(report.currency));
            this.programReport.aggregatedProgramTable1[i].currentBudget += Math.round(report.costTypeTable[i].currentBudget * this.currencyParser(report.currency));
            this.programReport.aggregatedProgramTable1[i].originalBudget += Math.round(report.costTypeTable[i].originalBudget * this.currencyParser(report.currency));
          });
          this.programReport.aggregatedProgramTable1[i].actualCost += this.programReport.programCostTable1[i].actualCost * 1;
          this.programReport.aggregatedProgramTable1[i].forecast += this.programReport.programCostTable1[i].forecast * 1;
          this.programReport.aggregatedProgramTable1[i].currentBudget += this.programReport.programCostTable1[i].currentBudget * 1;
          this.programReport.aggregatedProgramTable1[i].originalBudget += this.programReport.programCostTable1[i].originalBudget * 1;
        }

        // this.financialTrend[0].value = this.programReport.aggregatedProgramTable1[6].originalBudget;
        // this.financialTrend[1].value = this.programReport.aggregatedProgramTable1[6].currentBudget;
        // this.financialTrend[2].value = this.programReport.aggregatedProgramTable1[6].actualCost;
        // this.financialTrend[3].value = this.programReport.aggregatedProgramTable1[6].forecast;
      }

      if (this.financialTrendElem) {
        this.financialTrendElem.instance.option('dataSource', this.financialTrend);
      }

      projectReportObj.reports.forEach((report, index) => {
        this.apiService.get('/reports/' + report.id).subscribe(reportObj => {

          projectReportObj.reports[index] = reportObj;
          if (projectReportObj.reports.length - 1 == index) {
            this.reportsList = projectReportObj.reports;
            this.reset();
          }
        });
      });
    });

    if (gantt.getDatastore() != null) {
      gantt.clearAll();
    }

    gantt.config.xml_date = "%d.%m.%Y";
    gantt.config.date_grid = "%d.%m.%Y";
    gantt.config.smart_scales = false;
    gantt.config.drag_move = false;
    gantt.config.drag_progress = false;
    gantt.config.drag_resize = false;
    gantt.config.readonly = true;
    gantt.config.scale_unit = "year";
    gantt.config.date_scale = "%M, %Y";
    gantt.config.chartTitle = "Reports Gantt View";
    gantt.config.heading_label = "Project Name";
    gantt.config.autofit = true;

    gantt.config.columns = [
      { name: "text", label: "Project Name", tree: true, align: "left", min_width: 150, width: 250, resize: true },
      {
        name: "overall", label: "Overall", align: "center", width: 100, resize: true, template: function (task) {
          // return (Math.floor(task.overall * 100) || 0) + '%';
          let css = task.overall.toLowerCase();
          if (css == 'green') {
            return `<ul class="pmt-ricons ">
          <li>
           <span class="${css}"></span>
          </li>
          <li>
           <span></span>
          </li>
          <li>
           <span></span>
          </li>
         </ul>`
          } else if (css == 'yellow') {
            return `<ul class="pmt-ricons ">
            <li>
             <span></span>
            </li>
            <li>
             <span class="${css}"></span>
            </li>
            <li>
             <span></span>
            </li>
          </ul>`
          } else if (css == 'red') {
            return `<ul class="pmt-ricons ">
            <li>
             <span></span>
            </li>
            <li>
             <span></span>
            </li>
            <li>
             <span class="${css}"></span>
            </li>
          </ul>`
          } else {
            return `<ul class="pmt-ricons ">
            <li>
             <span></span>
            </li>
            <li>
             <span></span>
            </li>
            <li>
             <span></span>
            </li>
          </ul>`
          }
        }
      },
      { name: "start_date", label: "Start Date", align: "center", width: 90, resize: true },
      { name: "end_date", label: "End Date", align: "center", width: 90, resize: true },
      //{
      //   name: "progress", label: "Progress", align: "center", width: 70, resize: true, template: function (task) {
      //     return (Math.floor(task.progress * 100) || 0) + '%';
      //   }
      // },
    ];
    gantt.config.keep_grid_width = false;
    gantt.config.grid_resize = true;

    gantt.init(this.ganttContainer.nativeElement);

    gantt.clearAll();
  }

  initAggregatedValues() {
    this.programReport.aggregatedProgramTable1 = [{
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 0,
      group: "CAPEX",
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 1,
      group: "CAPEX",
    }, {
      costType: "External Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 2,
      group: "OPEX"
    }, {
      costType: "Internal Costs",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 3,
      group: "OPEX"
    }, {
      costType: "Revenues",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 4,
      group: "OTHERS",
    }, {
      costType: "Reserves",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 5,
      group: "OTHERS",
    }, {
      costType: "Total",
      actualCost: 0,
      forecast: 0,
      currentBudget: 0,
      originalBudget: 0,
      id: 6,
      group: "OTHERS",
    },];
  }

  currencyParser(currency): number {
    if (currency == 'CHF') {
      return 1;
    } else if (currency == 'EUR') {
      return this.authService.userObj.configuration.rates.EUR;
    } else if (currency == 'GBP') {
      return this.authService.userObj.configuration.rates.GBP;
    } else if (currency == 'USD') {
      return this.authService.userObj.configuration.rates.USD;
    }
  }


  onCellPrepared(e) {
    e.cellElement.style.backgroundColor = "#f0f0f0";
  }

  customizeSeries(e) {
    return e ? { showInLegend: true } : { showInLegend: false };
  }

  onDone(e) {
    setTimeout(() => {
      let series = e.component.getSeriesByName("M1");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[0].showInLegend", false);
      }
      series = e.component.getSeriesByName("M2");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[1].showInLegend", false);
      }
      series = e.component.getSeriesByName("M3");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[2].showInLegend", false);
      }
      series = e.component.getSeriesByName("M4");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[3].showInLegend", false);
      }
      series = e.component.getSeriesByName("M5");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[4].showInLegend", false);
      }
      series = e.component.getSeriesByName("M6");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[5].showInLegend", false);
      }
      series = e.component.getSeriesByName("M7");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[6].showInLegend", false);
      }
      series = e.component.getSeriesByName("M8");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[7].showInLegend", false);
      }
      series = e.component.getSeriesByName("M9");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[8].showInLegend", false);
      }
      series = e.component.getSeriesByName("M10");
      if (series.getAllPoints().length == 0) {
        e.component.option("series[9].showInLegend", false);
      }
    });
  }

  download() {
    let imgWidth = 297;
    let pageHeight = 210; //295
    let position = 0;

    const elementToPrint: any = $('#printable-area');
    let downloadElem = elementToPrint.find('#download')[0];
    downloadElem.remove();
    const pdf = new jsPDF('l', 'mm', 'a4'); //[200, 800]

    this.showLoading = true;

    html2canvas(elementToPrint[0], { scale: 2.0 }).then(function (canvas) {
      let imgHeight = canvas.height * imgWidth / canvas.width;
      // let imgHeight = 416;
      // canvas.width = 3795;

      let heightLeft = imgHeight;
      let image = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(image, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(image, 'JPEG', 0, position + 2, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      pdf.save('Program Report.pdf');
      globalThis.showLoading = false;
      elementToPrint.children().children().append(downloadElem);
    });
  }

  customizeFinancialTrendLabel(e) {
    return globalThis.shortNumber.transform(e.value);
  }

  reset() {
    gantt.clearAll();
    this.reportsList.forEach(element => {
      let overall = "Empty";
      if (element.statusReports != undefined) {
        if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].overallStatus != undefined) {
          overall = element.statusReports[element.statusReports.length - 1].overallStatus;
        } else {
          overall = "Empty";
        }
      } else {
        overall = "Empty";
      }
      this.tasks.push({
        id: element.id,
        text: element.projectName,
        overall: overall, //element.statusReports != undefined ? element.statusReports[element.statusReports.length - 1].overallStatus : "",
        start_date: moment(element.startDate).format('DD/MM/YYYY'),
        end_date: moment(element.endDate).format('DD/MM/YYYY'),
        // progress: element.statusReports.length > 0 ? element.statusReports[element.statusReports.length - 1].percentageComplete / 100 : 0,
      });
    });
    let [data, links] = [this.tasks, this.links];
    gantt.parse({ data });
  }
}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  text?: string;
  // progress: number;
  duration?: number;
  overall: any;
}