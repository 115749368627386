import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private socketService: SocketService) { }

  createNotification(projectId, projectItem, action, actor) {
    let notificationObj = {
      projectId, projectItem, action, actor
    }
    this.socketService.emitEvent('createNotification', notificationObj);
  }

}
