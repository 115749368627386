import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

var globalThis;

@Component({
  selector: 'app-project-budget',
  templateUrl: './project-budget.component.html',
  styleUrls: ['./project-budget.component.scss']
})
export class ProjectBudgetComponent implements OnInit {
  @Input() dataSource: any;
  @Input() additionalColumns: any = [];
  @Input() fixedColumns: any = [];
  @Input() assignmentsCount;
  @Output() update: EventEmitter<any> = new EventEmitter();
  budgetPlanningCheck2: boolean = true;
  editedField: any;
  editedColumn: any;
  datagridColumns;
  columnIterationIndex = 0;

  constructor() {
    globalThis = this;
    this.calculateBudgetValue = this.calculateBudgetValue.bind(this);
    this.calculateThereofITValue = this.calculateThereofITValue.bind(this);
    // this.calculateCurrentBudgetValue = this.calculateCurrentBudgetValue.bind(this);
    this.calculateyearlyForecastValue = this.calculateyearlyForecastValue.bind(this);
    this.calculateRemainingProjectBudgetValue = this.calculateRemainingProjectBudgetValue.bind(this);
  }

  ngOnInit() {
    if (this.additionalColumns != undefined && this.additionalColumns.length > 0) {
      for (let i = 0; i < this.additionalColumns.length; i++) {
        this[this.additionalColumns[i].dataField] = function (e) {
          if (e.costType == 'Revenues') {
            return this.additionalColumns[i] ? (Math.abs(e[this.additionalColumns[i].dataField]) * -1) : '';
          } else if (e.costType == "Total") {
            if (this.additionalColumns[i]) {
              this.dataSource[6][this.additionalColumns[i].dataField] = this.projectCostsValueCalculator(this.additionalColumns[i].dataField, this.dataSource);
              return this.dataSource[6][this.additionalColumns[i].dataField];
            }
          } else {
            return this.additionalColumns[i] ? e[this.additionalColumns[i].dataField] : '';
          }
        }

        this[this.additionalColumns[i].dataField] = this[this.additionalColumns[i].dataField].bind(this)
      }
    }

    if (this.fixedColumns != undefined && this.fixedColumns.length > 0) {
      for (let i = 0; i < this.fixedColumns.length; i++) {
        this[this.fixedColumns[i].dataField] = function (e) {
          if (e.costType == 'Revenues') {
            return this.fixedColumns[i] ? (Math.abs(e[this.fixedColumns[i].dataField]) * -1) : '';
          } else if (e.costType == "Total") {
            if (this.fixedColumns[i]) {
              this.dataSource[6][this.fixedColumns[i].dataField] = this.projectCostsValueCalculator(this.fixedColumns[i].dataField, this.dataSource);
              return this.dataSource[6][this.fixedColumns[i].dataField];
            }
          } else {
            return this.fixedColumns[i] ? e[this.fixedColumns[i].dataField] : '';
          }
        }

        this[this.fixedColumns[i].dataField] = this[this.fixedColumns[i].dataField].bind(this)
      }
    }

  }

  onRowPrepared(e) {
    this.setRowIndex9(e);
  }

  onCellPrepared(e) {
    let editableAssignment = (this.assignmentsCount && this.assignmentsCount.count > 0) ? 2 : 0;
    if (e.columnIndex > 1 && e.columnIndex < (5 + editableAssignment)) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex != 9 && e.parentType == 'dataRow') {
      if (e.columnIndex > 3) {
        e.cellElement.style.backgroundColor = "#FFF";
      }
    }

    if (this.fixedColumns.length > 0) {
      if (e.columnIndex > 4 && e.columnIndex < 5 + this.fixedColumns.length + editableAssignment) {
        e.cellElement.style.backgroundColor = "#ddd";
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparing(e) {
    this.editRowIndex9(e);
  }

  calculateBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.budget ? (Math.abs(e.budget) * -1) : '';
    } else if (e.costType == "Total") {
      this.dataSource[6].budget = this.projectCostsValueCalculator('budget', this.dataSource);
      return this.dataSource[6].budget;
    } else {
      return e.budget ? e.budget : '';
    }
  }

  calculateThereofITValue(e) {
    if (e.costType == 'Revenues') {
      return e.thereofIT ? (Math.abs(e.thereofIT) * -1) : '';
    } else if (e.costType == "Total") {
      this.dataSource[6].thereofIT = this.projectCostsValueCalculator('thereofIT', this.dataSource);
      return this.dataSource[6].thereofIT;
    } else {
      return e.thereofIT ? e.thereofIT : '';
    }
  }

  // calculateCurrentBudgetValue(e) {
  //   if (e.costType == 'Revenues') {
  //     return e.currentBudget ? (Math.abs(e.currentBudget) * -1) : '';
  //   } else if (e.costType == "Total") {
  //     this.dataSource[6].currentBudget = this.projectCostsValueCalculator('currentBudget', this.dataSource);
  //     return this.dataSource[6].currentBudget;
  //   } else {
  //     return e.currentBudget ? e.currentBudget : '';
  //   }
  // }

  calculateyearlyForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.yearlyForecast ? (Math.abs(e.yearlyForecast) * -1) : '';
    } else if (e.costType == "Total") {
      this.dataSource[6].yearlyForecast = this.projectCostsValueCalculator('yearlyForecast', this.dataSource);
      return this.dataSource[6].yearlyForecast;
    } else {
      return e.yearlyForecast ? e.yearlyForecast : '';
    }
  }

  calculateRemainingProjectBudgetValue(e) {
    if (e.costType == 'Revenues') {
      return e.remainingProjectBudget ? (Math.abs(e.remainingProjectBudget) * -1) : '';
    } else if (e.costType == "Total") {
      this.dataSource[6].remainingProjectBudget = this.projectCostsValueCalculator('remainingProjectBudget', this.dataSource);
      return this.dataSource[6].remainingProjectBudget;
    } else {
      return e.remainingProjectBudget ? e.remainingProjectBudget : '';
    }
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  projectCostsValueCalculator(field, dataSource) {
    let calculationArray = [];
    for (let i = 0; i < 6; i++) {
      calculationArray[i] = dataSource[i][field] * 1;
    }

    let total = calculationArray[0] + calculationArray[1] + calculationArray[2] + calculationArray[3] - calculationArray[4] + calculationArray[5];
    return total;
  }

}
