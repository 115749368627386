import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-project-budget',
  templateUrl: './project-budget.component.html',
  styleUrls: ['./project-budget.component.scss']
})
export class ProjectBudgetComponent implements OnInit {
  spLink: string = '';
  projSponsorEmail;
  projFicoEmail;
  tabs: FormGroup;
  projectBudget: any = [];
  additionalColumns: any = [];
  projectObj: any = {
    uid: '',
    portfolio: {
      id: ''
    },
    subPortfolio: {
      id: ''
    }
  };
  projectReportId;

  projectFicoBackup;
  projectId;
  public isTeamEdit: boolean = false;
  public isTeamProject: boolean = false;
  public enableCreateReportButton: boolean = false;
  projectSubPortfolioBackup;
  projectBudgetList: any = [];
  stateStore;
  tabsItemId;
  showGrid: boolean = true;

  constructor(private apiService: ApiService, private authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private formBuilder: FormBuilder, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    globalThis = this;
    this.getProjectInfo();

    this.projectObj.costTypeTable = [
      {
        costType: "OPEX External Cost",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 0,
      }, {
        costType: "OPEX Internal Cost",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 1,
      }, {
        costType: "CAPEX External Cost",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 2,
      }, {
        costType: "CAPEX Internal Cost",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 3,
      }, {
        costType: "Revenues",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 4,
      }, {
        costType: "Reserves",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 5,
      }, {
        costType: "Total",
        actualCost: '',
        forecast: '',
        currentBudget: "",
        originalBudget: '',
        id: 6,
      },];
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      if (params.isTeamProject != undefined) {
        if (params.isTeamProject == "true") {
          this.isTeamProject = true;
          if (params.isTeamEdit == "true") {
            this.isTeamEdit = true;
          }
        } else {
          this.isTeamEdit = true;
        }
      } else {
        this.isTeamEdit = true;
      }

      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.apiService.get('/projectBudgetCost/project/' + projectData.project.id).subscribe((responceBudget: any) => {

          this.utilityService.breadcrumbList = ['My Projects', projectData.projectName, 'Details', 'Budget'];
          this.projectReportId = params.id;
          this.projectId = projectData.project.id;
          this.projectBudgetList = responceBudget;
          this.projectBudgetList.sort((a, b) => { return a.portfolioBudgetYear.year - b.portfolioBudgetYear.year });

          this.projectBudget = responceBudget[0];

          if (this.projectBudget != undefined && this.projectBudget.portfolioBudgetYear != undefined) {
            this.additionalColumns = this.projectBudget.portfolioBudgetYear.additionalColumns ? this.projectBudget.portfolioBudgetYear.additionalColumns : [];
          }

          this.projectObj = projectData;

          if (this.projectObj.documentLink == undefined || this.projectObj.documentLink == '') {
            this.spLink = '';
          } else {
            this.spLink = this.projectObj.documentLink;
          }

          if (this.projectObj.projectSponsor && this.projectObj.projectSponsor.email != '') {
            this.projSponsorEmail = this.projectObj.projectSponsor.email;
          }
          if (this.projectObj.projectFico && this.projectObj.projectFico.email != '') {
            this.projFicoEmail = this.projectObj.projectFico.email;
          }
          this.spinner.hide();
        });
      });
    });
  }

  onYearChanged(event) {
    this.showGrid = false;
    this.spinner.show();
    this.projectBudget = event.value;
    this.additionalColumns = event.value.portfolioBudgetYear.additionalColumns ? this.projectBudget.portfolioBudgetYear.additionalColumns : [];

    setTimeout(() => {
      this.showGrid = true;
      this.spinner.hide();
    }, 500);
  }

  saveProjectBudget() {
    this.spinner.show();
    this.apiService.put('/projectBudgetCost/' + this.projectBudget.id, { budget: this.projectBudget.budget }).subscribe(res => {
      this.showNotificationBox('Save Success', 'Project Budget has been saved.', 'success');
      this.spinner.hide();
    });
  }

  onRowPreparedBudgetPlanning1(e) {
    this.setRowIndex9(e);
  }

  onRowPreparedBudgetPlanning2(e) {
    this.setRowIndex9(e);
  }

  setRowIndex9(e) {
    if (e.rowIndex == 9) {
      e.rowElement.style.backgroundColor = '#ddd';
      e.rowElement.style.fontWeight = 700;
    }
  }

  onCellPreparedBudgetPlanning1(e) {
    if (e.columnIndex > 1 && e.columnIndex < 3) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.columnIndex > 4) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onCellPreparedBudgetPlanning2(e) {
    if (e.columnIndex > 1 && e.columnIndex < 4) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.columnIndex > 3) {
      e.cellElement.style.backgroundColor = "#ddd";
      e.cellElement.style.borderBottom = "1px solid #eee";
      e.cellElement.style.borderTop = "1px solid #eee";
      e.cellElement.style.borderRight = "1px solid #cac3c3";
    }

    if (e.rowIndex == 9) {
      if (e.columnIndex > 0) {
        e.cellElement.style.borderBottom = "1px solid #eee";
        e.cellElement.style.borderTop = "1px solid #eee";
        e.cellElement.style.borderRight = "1px solid #cac3c3";
      }
    }
  }

  onEditorPreparingBudgetPlanning1(e) {
    this.editRowIndex9(e);
  }

  onEditorPreparingBudgetPlanning2(e) {
    this.editRowIndex9(e);
  }

  editRowIndex9(e) {
    if (e.parentType == 'dataRow') {
      if (e.row.rowIndex == 9) {
        e.editorOptions.readOnly = true;
      }
    }
  }

  calculateBudgetYearlyForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.yearlyForecast ? (Math.abs(e.yearlyForecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.projectBudget.budget[6].yearlyForecast = globalThis.projectBudgetValueCalculator('yearlyForecast');
      return globalThis.projectBudget.budget[6].yearlyForecast;
    } else {
      return e.yearlyForecast ? e.yearlyForecast : '';
    }
  }

  calculatethereofITForecastValue(e) {
    if (e.costType == 'Revenues') {
      return e.thereofITForecast ? (Math.abs(e.thereofITForecast) * -1) : '';
    } else if (e.costType == "Total") {
      globalThis.projectBudget.budget[6].thereofITForecast = globalThis.projectBudgetValueCalculator('thereofITForecast');
      return globalThis.projectBudget.budget[6].thereofITForecast;
    } else {
      return e.thereofITForecast ? e.thereofITForecast : '';
    }
  }

  projectBudgetValueCalculator(field) {
    let one: any = globalThis.projectBudget.budget[0][field] * 1;
    let two: any = globalThis.projectBudget.budget[1][field] * 1;
    let three: any = globalThis.projectBudget.budget[2][field] * 1;
    let four: any = globalThis.projectBudget.budget[3][field] * 1;
    let five: any = globalThis.projectBudget.budget[4][field] * 1;
    let six: any = globalThis.projectBudget.budget[5][field] * 1;
    let total = one + two + three + four - five + six;
    return total;
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
