import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import 'rxjs/operator/map';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-small-order-team',
  templateUrl: './small-order-team.component.html',
  styleUrls: ['./small-order-team.component.scss']
})
export class SmallOrderTeamComponent implements OnInit {
  @Input() isProjectUneditable;
  teamId;
  teamUsers: any = [];
  user: any = {
    email: ""
  };
  usersList: any = [];
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  isView: boolean = false;
  isEdit: boolean = false;
  isRightEdit: boolean = false;
  rightsId;
  smallOrderId;
  isTeamSmallOrder: boolean = false;;
  isVisible: boolean = false;
  isEditCheckboxDisabled: boolean = false;

  constructor(private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private authService: AuthService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList[3] = 'Team';
    this.route.params.subscribe(params => {
      this.smallOrderId = params.id;
      this.isTeamSmallOrder = this.utilityService.isTeamSmallOrder;

      this.apiService.get('/smallOrder/team/' + this.smallOrderId).subscribe((smallOrderTeam: any) => {
        if (smallOrderTeam.teamId != null && smallOrderTeam.teamId != undefined) {
          this.teamId = smallOrderTeam.teamId;
          this.teamUsers = smallOrderTeam.teamUsers;
        }
        this.spinner.hide();
        this.isVisible = true;
      });
    });
  }


  openModal(template: TemplateRef<any>) {
    this.resetForm();
    this.modalRef = this.modalService.show(template, this.config);
  }

  userValueChanged(e) {
    if (this.usersList.length > 0) {
      if (this.usersList != undefined) {
        this.usersList.forEach(response => {
          if (response.email == e.value) {
            delete (response.projects);
            this.user = response;
          }
        });
      }
    }
  }

  onEditClicked(e) {
    if (e.value) {
      this.isEditCheckboxDisabled = true
      this.isView = true;
    } else {
      this.isEditCheckboxDisabled = false
    }
  }

  userOnInputEvent(e) {
    this.apiService.get('/user/search/' + this.user.email).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  addUser() {
    if (this.user.id != '' && this.user.id != undefined && this.user.id != null &&
      this.user.email != '' && this.user.email != undefined && this.user.name != null) {
      if (this.teamUsers.find(val => val.id == this.user.id) != undefined) {
        this.notificationBox("Warning", "User has been already added.", "warning");
      } else {
        this.spinner.show();
        if (this.teamId != undefined && this.teamId != null) {
          let teamUsersIds = this.getteamUsersIds();
          teamUsersIds.push(this.user.id);
          this.apiService.put('/smallOrderTeam/' + this.teamId, {
            users: teamUsersIds,
          }).subscribe((responseTeam: any) => {
            this.apiService.post('/smallOrderRights', {
              user: this.user.id,
              smallOrder: this.smallOrderId,
              isView: this.isView,
              isEdit: this.isEdit
            }).subscribe((responseRights: any) => {
              let userObj = { user: '', id: '', isView: '', isEdit: '' };
              userObj.user = this.user;
              userObj.id = responseRights.id;
              userObj.isView = responseRights.isView;
              userObj.isEdit = responseRights.isEdit;
              this.teamUsers.push(userObj);
              this.notificationBox("Success", "User Added Successfully.", "success");
              this.modalRef.hide();
              this.spinner.hide();
            });
          });
        } else {
          this.apiService.post('/smallOrderTeam', {
            users: [this.user.id],
            smallOrder: this.smallOrderId
          }).subscribe((responseTeam: any) => {
            this.teamId = responseTeam.id;
            this.apiService.post('/smallOrderRights', {
              user: this.user.id,
              smallOrder: this.smallOrderId,
              isView: this.isView,
              isEdit: this.isEdit
            }).subscribe((responseRights: any) => {
              let userObj = { user: '', id: '', isView: '', isEdit: '' };
              userObj.user = this.user;
              userObj.id = responseRights.id;
              userObj.isView = responseRights.isView;
              userObj.isEdit = responseRights.isEdit;
              this.teamUsers.push(userObj);
              this.notificationBox("Success", "User Added Successfully.", "success");
              this.modalRef.hide();
              this.spinner.hide();
            });
          });
        }
      }
    } else {
      this.notificationBox("Warning", "Please select user first.", "warning");
    }
  }

  deleteRights(obj) {
    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform("Do you want to remove this user?")}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        if (this.teamId != undefined && this.teamId != null) {
          let teamUsersIds = this.getteamUsersIds();

          let index = teamUsersIds.findIndex(x => x == obj.user.id);
          if (index > -1) {
            teamUsersIds.splice(index, 1);
          }

          if (teamUsersIds.length > 0) { //If there is atleast one user left in team, we update existing team
            this.apiService.put('/SmallOrderTeam/' + this.teamId, {
              users: teamUsersIds,
            }).subscribe((responseTeam: any) => {
              this.apiService.delete('/smallOrderRights/' + obj.id).subscribe((responseRights: any) => {
                let indexInTeamUsers = this.teamUsers.findIndex(x => x.id == obj.id);
                if (indexInTeamUsers > -1) {
                  this.teamUsers.splice(indexInTeamUsers, 1);
                }

                this.notificationBox("Success", "User Deleted Successfully.", "success");
                this.spinner.hide();
              });
            });

          } else { //If there is no user left in team, delete the team
            this.apiService.delete('/SmallOrderTeam/' + this.teamId).subscribe((responseTeam: any) => {
              this.apiService.delete('/smallOrderRights/' + obj.id).subscribe((responseRights: any) => {
                let indexInTeamUsers = this.teamUsers.findIndex(x => x.id == obj.id);
                if (indexInTeamUsers > -1) {
                  this.teamUsers.splice(indexInTeamUsers, 1);
                }

                this.teamId = null;
                this.notificationBox("Success", "User Deleted Successfully.", "success");
                this.spinner.hide();
              });
            });
          }
        }
      }
    });
  }

  editRights() {
    this.spinner.show();
    this.apiService.put('/smallOrderRights/' + this.rightsId, {
      isView: this.isView,
      isEdit: this.isEdit
    }).subscribe((responseRights: any) => {
      let userObj = this.teamUsers.find(val => val.id == this.rightsId);
      userObj.isView = responseRights.isView;
      userObj.isEdit = responseRights.isEdit;
      this.notificationBox("Success", "Rights Updated Successfully.", "success");
      this.modalRef.hide();
      this.spinner.hide();
    });
  }

  getteamUsersIds(): any {
    let teamUsersIds = [];
    this.teamUsers.forEach(team => {
      teamUsersIds.push(team.user.id);
    });
    return teamUsersIds;
  }

  openRightsModel(template, obj) {
    this.resetForm();
    this.isRightEdit = true;
    this.user = obj.user;
    this.rightsId = obj.id;
    this.isView = obj.isView;
    this.isEdit = obj.isEdit;
    this.modalRef = this.modalService.show(template, this.config);
  }

  resetForm() {
    this.user = {
      email: ""
    };
    this.isRightEdit = false;
    this.isEdit = false;
    this.isView = false;
  }

  notificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
