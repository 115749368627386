import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

let globalThis;

@Component({
  selector: 'app-project-trend-analysis',
  templateUrl: './project-trend-analysis.component.html',
  styleUrls: ['./project-trend-analysis.component.scss']
})
export class ProjectTrendAnalysisComponent implements OnInit {
  projectBudget: any = [];
  additionalColumns: any = [];
  projectObj: any = {
    uid: '',
    portfolio: {
      id: ''
    },
    projectBudgetOriginal: "",
    projectManager: {
      id: 0,
      email: ''
    },
    pmoOfficer: '',
    projectSponsor: '',
    projectFico: '',
    kpisTable: [{}, {}, {}, {}, { value: '' }],
    program: '',
    threeYearsBudgetBreakdown: [],
    mandatoryProject: {
      id: ""
    },
    businessArea: {
      id: ""
    },
    businessUnit: {
      id: ""
    },
    businessSegment: {
      id: ""
    },
    reportingLevel: {
      id: ""
    },
    classification: {
      id: ""
    },
    projectType: {
      id: ""
    },
    strategicContribution: {
      id: ""
    },
    profitability: {
      id: ""
    },
    feasibility: {
      id: ""
    },
    projectMethodology: {
      id: ""
    },
    projectPhase: {
      id: ""
    },
    digitalizationTopic: {
      id: ""
    },
    digitalizationFocus: {
      id: ""
    },
    itPlatform: {
      id: ""
    },
    itRelevant: {
      id: ""
    },
    subPortfolio: {
      id: ''
    }
  };
  technologyOptions;
  projectReportId;
  portfolioList = [];
  subPortfolioList = [];
  projectId;
  public isTeamEdit: boolean = false;
  public isTeamProject: boolean = false;
  projectBudgetList: any = [];

  constructor(private apiService: ApiService, private authService: AuthService,
    public route: ActivatedRoute, private router: Router, public utilityService: UtilityService,
    private localize: I18nPipe, private formBuilder: FormBuilder, private clipboardService: ClipboardService,
    private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "Project Details";

    globalThis = this;
    this.getProjectInfo();
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);
  }

  getProjectInfo() {
    let sub = this.route.params.subscribe(params => {
      this.isTeamProject = this.utilityService.isTeamProject;
      this.isTeamEdit = this.utilityService.isTeamEdit;

      this.apiService.get('/reports/' + params.id).subscribe((projectData: any) => {
        this.apiService.get('/getActivePortfolios').subscribe((portfolioList: any) => {
          this.apiService.get('/projectBudgetCost/project/' + projectData.project.id).subscribe((responceBudget: any) => {

            this.utilityService.breadcrumbList = ['My Projects', projectData.projectName, 'Details', 'Trend Analysis'];
            this.projectReportId = params.id;
            this.projectId = projectData.project.id;
            this.portfolioList = portfolioList;
            this.projectBudgetList = responceBudget;
            this.projectBudget = responceBudget[0];
            this.additionalColumns = this.projectBudget.portfolioBudgetYear.additionalColumns ? this.projectBudget.portfolioBudgetYear.additionalColumns : [];

            this.projectObj = projectData;
            this.spinner.hide();
          });
        });
      });
    });
  }

  updateMTAGrid(e: any) {
    this.projectObj.mileStonesValues = e.mileStonesValues;

    //Update History MTAGrid (Add Columns)
    if (this.projectObj.milestoneTable.length > 0) {
      for (let i = 0; i < this.projectObj.milestoneTable.length; i++) {
        for (let j = 0; j < this.projectObj.mileStonesValues.length; j++) {
          if (this.projectObj.milestoneTable[i]['milestone' + (j + 1)] == undefined) {
            this.projectObj.milestoneTable[i]['milestone' + (j + 1)] = '';
          }
        }
      }
    }
  }

  updateMTAGridDeletionCase(e: any) {
    this.projectObj.mileStonesValues = e.mileStonesValues;

    if (this.projectObj.milestoneTable.length > 0) {
      for (let i = 0; i < this.projectObj.milestoneTable.length; i++) {
        for (let j = e.index; j < this.projectObj.milestoneTable.length; j++) {
          if ((j + 2) <= this.projectObj.milestoneTable.length) {
            this.projectObj.milestoneTable[i]['milestone' + (j + 1)] = this.projectObj.milestoneTable[i]['milestone' + (j + 2)];
          }
        }
      }
    }
  }
}
