import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExcelExportComponent } from './excel-export.component';
import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule, DxCheckBoxModule, DxTagBoxModule, DxAutocompleteModule, DxDateBoxModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [ExcelExportComponent],
  imports: [
    CommonModule,
    DxDataGridModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxAutocompleteModule,
    MaterialModule,
    ModalModule,
    DxDateBoxModule
  ],
  exports: [ExcelExportComponent],
})
export class ExcelExportModule { }
