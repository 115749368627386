import { Component, OnInit, TemplateRef } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';

@Component({
  selector: 'app-small-order-links',
  templateUrl: './small-order-links.component.html',
  styleUrls: ['./small-order-links.component.scss']
})
export class SmallOrderLinksComponent implements OnInit {
  links = [];
  formObj = {
    title: '',
    linkType: 'smallOrder',
    link: '',
    id: ''
  };
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  isEditModal: boolean = false;

  constructor(private spinner: NgxSpinnerService, private apiService: ApiService, private modalService: BsModalService, private utilityService: UtilityService, private localize: I18nPipe) { }

  ngOnInit(): void {
    this.utilityService.breadcrumb = 'Small Order Links';
    this.utilityService.breadcrumbList = ['Settings', 'Small Order Links'];
    this.fetchData();
  }
  createLink(template: TemplateRef<any>) {
    if (this.links.length >= 6) {
      this.notification("Error", "Maximum 6 links can be added", "danger");
      return;
    }
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  fetchData() {
    this.apiService.get('/externalLinks').subscribe((links: any) => {
      this.links = links;
    })
  }

  notification(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  openEditDialog(template, obj) {
    this.isEditModal = true;
    this.formObj = obj;
    this.openModal(template);
  }

  closeModal() {
    if (this.isEditModal) {
      this.isEditModal = false;
    }
    this.modalRef.hide();
    this.resetInputFields();
  }

  resetInputFields() {
    this.formObj = {
      title: '',
      linkType: 'smallOrder',
      link: '',
      id: ''
    };
  }

  save() {
    if (this.formObj.title == undefined || this.formObj.title == '') {
      this.notification("Error", "Please enter a title.", "danger");
      return;
    }
    if (this.formObj.link == '') {
      this.notification("Error", "Please enter a valid link.", "danger");
      return;
    }

    this.spinner.show();
    if (this.isEditModal) {
      this.apiService.put('/externalLinks/' + this.formObj.id, {
        title: this.formObj.title,
        linkType: this.formObj.linkType,
        link: this.formObj.link
      }).subscribe(news => {
        this.notification('Success', "Link has been updated successfully.", 'success');
        this.closeModal();
        this.fetchData();
        this.spinner.hide();
      })
    } else {
      this.apiService.post('/externalLinks', this.formObj).subscribe(news => {
        this.notification('Success', "Link has been added successfully.", 'success');
        this.closeModal();
        this.fetchData();
        this.spinner.hide();
      })
    }
  }

  deleteLink(obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.apiService.delete('/externalLinks/' + obj.id).subscribe((response: any) => {
          this.spinner.hide();
          this.fetchData();
          this.notification('Success', 'Link deleted successfully.', 'success');
        });
      }
    });
  }

}
