import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import PerfectScrollbar from 'perfect-scrollbar';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from 'app/services/auth.service';
var globalThis;

declare const $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  animations: [
    trigger(
      'sidebarOpen',
      [
        transition(
          ':enter',
          [
            style({ left: 70, opacity: 1 }),
            animate('.2s ease-in', style({ left: 249, opacity: 1 }))
          ]
        ),
      ]
    ),
    trigger(
      'sidebarCollapse',
      [
        transition(
          ':enter',
          [
            style({ left: 249, opacity: 1 }),
            animate('.2s ease-in', style({ left: 70, opacity: 1 }))
          ]
        ),
      ]
    ),
  ]
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  url: string;
  location: Location;
  @ViewChild('sidebar', { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const body = document.getElementsByTagName('body')[0];

    if (this.utilityService.misc.sidebar_mini_active === true && window.innerWidth > 1440) {
      body.classList.remove('sidebar-mini');
      this.utilityService.misc.sidebar_mini_active = false;
      this.utilityService.isCollapseSidebar = true;
    } else if (this.utilityService.misc.sidebar_mini_active === false && window.innerWidth <= 1440) {
      setTimeout(function () {
        body.classList.add('sidebar-mini');
        globalThis.utilityService.misc.sidebar_mini_active = true;
        globalThis.utilityService.isCollapseSidebar = false;
      }, 300);
    }
  }

  constructor(private router: Router, location: Location, private authService: AuthService, private socket: SocketService, public utilityService: UtilityService,
    private route: ActivatedRoute) {
    globalThis = this;
    this.location = location;
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const parsedToken = this.authService.parseJwt(token);
    if (parsedToken) {
      if (parsedToken.isNewSystem == undefined || parsedToken.isNewSystem == null || parsedToken.isNewSystem == false) {
        this.authService.isLoggedIn = false;
        localStorage.removeItem('userObj');
        localStorage.removeItem('token');
        localStorage.removeItem('configuration');
        localStorage.removeItem('language');
        this.router.navigate(['auth/login']);
      }
    }

    if (this.utilityService.misc.sidebar_mini_active === false && window.innerWidth <= 1440) {
      setTimeout(function () {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('sidebar-mini');
        globalThis.utilityService.misc.sidebar_mini_active = true;
        globalThis.utilityService.isCollapseSidebar = false;
      }, 300);
    }

    this.initDropdowns();

    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        }
        else
          window.scrollTo(0, 0);
      }
    });
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;
    });
    const html = document.getElementsByTagName('html')[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      html.classList.add('perfect-scrollbar-on');
    }
    else {
      html.classList.add('perfect-scrollbar-off');
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.navbar.sidebarClose();
    });
  }

  // minimizeSidebar() {
  //   const body = document.getElementsByTagName('body')[0];

  //   if (this.utilityService.misc.sidebar_mini_active === true) {
  //     body.classList.remove('sidebar-mini');
  //     this.utilityService.misc.sidebar_mini_active = false;
  //     this.utilityService.isCollapseSidebar = true;
  //   } else {
  //     setTimeout(function () {
  //       body.classList.add('sidebar-mini');
  //       globalThis.utilityService.misc.sidebar_mini_active = true;
  //       globalThis.utilityService.isCollapseSidebar = false;
  //     }, 300);
  //   }

  //   // we simulate the window Resize so the charts will get updated in realtime.
  //   const simulateWindowResize = setInterval(function () {
  //     window.dispatchEvent(new Event('resize'));
  //   }, 180);

  //   // we stop the simulation of Window Resize after the animations are completed
  //   setTimeout(function () {
  //     clearInterval(simulateWindowResize);
  //   }, 1000);
  // }

  initDropdowns() {
    this.route.snapshot.data.dropdowns.forEach(element => {
      if (element.field == "Mandatory Projects") {
        this.utilityService.mandatoryProjectOptions = element;
      } else if (element.field == "Business Area") {
        this.utilityService.businessAreaOptions = element;
      } else if (element.field == "Business Unit") {
        this.utilityService.businessUnitOptions = element;
      } else if (element.field == "Business Segment") {
        this.utilityService.businessSegmentOptions = element;
      } else if (element.field == "Reporting Level") {
        this.utilityService.reportingLevelOptions = element;
      } else if (element.field == "Classification") {
        this.utilityService.classificationOptions = element;
      } else if (element.field == "Project Type") {
        this.utilityService.projectTypeOptions = element;
      } else if (element.field == "Strategic Contribution") {
        this.utilityService.strategicContributionOptions = element;
      } else if (element.field == "Profitability") {
        this.utilityService.profitabilityOptions = element;
      } else if (element.field == "Feasibility") {
        this.utilityService.feasibilityOptions = element;
      } else if (element.field == "Project Phase") {
        this.utilityService.projectPhaseOptions = element;
      } else if (element.field == "Risk Strategy") { //'Strategy'
        this.utilityService.risksTopFiveRiskStrategySelectOptions = element;
      } else if (element.field == "Decision Type") {
        this.utilityService.decisionTypeOptions = element;
      } else if (element.field == "Document Type") {
        this.utilityService.typeOptions = element;
      } else if (element.field == "Project Methodology") {
        this.utilityService.projectMethodologyOptions = element;
      } else if (element.field == "Lesson Category") {
        this.utilityService.lessonsCategoryOptions = element;
      } else if (element.field == "Impact") {
        this.utilityService.impactOptions = element;
      } else if (element.field == "Digitalization Topic") {
        this.utilityService.digitalizationTopicOptions = element;
      } else if (element.field == "Technology") {
        this.utilityService.technologyOptions = element;
      } else if (element.field == "Digitalization Focus") {
        this.utilityService.digitalizationFocusOptions = element;
      } else if (element.field == "IT Platform") {
        this.utilityService.itPlatformOptions = element;
      } else if (element.field == "IT Relevant") {
        this.utilityService.itRelevantOptions = element;
      }
    });
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
      return true;
    } else {
      return false;
    }
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      // let ps = new PerfectScrollbar(elemMainPanel, {
      //   wheelSpeed: 0.4,
      // });
      let ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
