import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from 'app/services/api.service';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';

@Component({
  selector: 'app-budget-import',
  templateUrl: './budget-import.component.html',
  styleUrls: ['./budget-import.component.scss']
})
export class BudgetImportComponent implements OnInit {
  public uploader: any = new FileUploader({
    url: this.apiService.BASE_PATH + "/upload",
    itemAlias: "attachment",
    authToken: 'Bearer ' + this.apiService.accessToken
  });

  constructor(private apiService: ApiService, private utilityService: UtilityService, private localize: I18nPipe) { }

  ngOnInit() {
    this.budgetImport();
  }

  budgetImport() {
    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (file.file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.uploader.uploadAll();
      } else {
        this.notification('Warning', 'Invalid file type.', 'warning');
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      let res = JSON.parse(response);

      this.apiService.post('/budgetImport', {
        path: res.files[0].fd,
        fileName: res.files[0].fd.split('/').pop(),
        originalName: res.files[0].filename
      }).subscribe(response => {
        this.notification('Success', 'Budget Imported successfully.');
      });
    }
  }

  notification(title, message, color = 'success') {
    this.utilityService.showNotification(
      this.localize.transform(title),
      this.localize.transform(message),
      color,
    );
  }


}
