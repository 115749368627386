import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;
  userObj: any;
  isPreview: boolean = false;
  previewLink = '';

  constructor(public router: Router, private apiService: ApiService) {
    this.isAuthenticated();
    this.updateAccessToken();
  }

  isAuthenticated(): boolean {
    const userObj = JSON.parse(localStorage.getItem('userObj'));
    const token = localStorage.getItem('token');

    if (userObj != undefined && token != undefined) {
      this.isLoggedIn = true;
      this.userObj = userObj;
      this.fetchAccessToken();
    }
    return this.isLoggedIn;
  }

  isAdmin(): boolean {
    const userObj = JSON.parse(localStorage.getItem('userObj'));
    if (userObj != undefined) {
      if (userObj.role == 'admin')
        return true;
    }
    return false;
  }

  checkAllowedUserByRole(allowedRoles: Array<any>): boolean {
    const userObj = JSON.parse(localStorage.getItem('userObj'));
    const viewerRole = userObj.isViewerAlso ? 'viewer' : '';
    const subportfolioManagerRole = userObj.isSubportfolioManager ? 'subPortfolioManager' : '';

    let result = false;
    allowedRoles.forEach(role => {
      if (!result) {
        result = (role == userObj.role || role == viewerRole || role == subportfolioManagerRole) ? true : false;
      }
    });
    return result;
  }

  logout() {
    this.userObj = {};
    this.apiService.get('/logout').subscribe((response: any) => {
      this.isLoggedIn = false;
      localStorage.removeItem('userObj');
      localStorage.removeItem('token');
      localStorage.removeItem('configuration');
      localStorage.removeItem('language');
      window.location.href = response.uri;
    });
  }

  setUserObj(obj) {
    localStorage.setItem('userObj', JSON.stringify(obj));
  }

  getUserObj(): any {
    return JSON.parse(localStorage.getItem('userObj'));
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  updateAccessToken() {
    let check = 0;
    setInterval(() => {
      if (check == 0) {
        check = 1;
      } else {
        this.fetchAccessToken();
      }
    }, 1000 * 60 * 60 * 59); // 24 hours
  }

  fetchAccessToken() {
    this.apiService.get(`/auth/${this.userObj.id}/accessToken/refresh`).subscribe((response: any) => {
      localStorage.setItem('accessToken', response.accessToken);
    });
  }
}
