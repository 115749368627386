import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment';
import "dhtmlx-gantt";
import { SocketService } from 'app/services/socket.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { UtilityService } from 'app/services/utility.service';
declare var gantt: any;

@Component({
  selector: 'app-program-gantt-view',
  templateUrl: './program-gantt-view.component.html',
  styleUrls: ['./program-gantt-view.component.scss']
})
export class ProgramGanttViewComponent implements OnInit, OnDestroy {
  tasks: Task[] = [];
  links = [];
  ganttChart = false;
  portfolioList = [];
  reportsList = [];
  idsArray = [];
  reportsListIndex: number = 1;
  portfolioListIndex: number = 1;
  reportsCount: number = 0;
  portfoliosCount: number = 0;
  @ViewChild("gantt", { static: true }) ganttContainer: ElementRef;
  public selectedFilter: string = 'year';
  programId;

  constructor(private apiService: ApiService, private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private localize: I18nPipe, private utilityService: UtilityService) {
    this.socketService.listen('allPortfolioCount').subscribe(count => {
      this.portfoliosCount = count;
      this.socketService.emitEvent('allPortfolioIndex', { pageIndex: this.portfolioListIndex, pageSize: 10 });
    });

    this.socketService.listen('allPortfolioIndex').subscribe(data => {
      data.forEach(dataElement => {
        this.portfolioList.push(dataElement);
      });
      this.portfolioListIndex++;
      if (this.portfolioListIndex <= Math.ceil(this.portfoliosCount / 10)) {
        this.socketService.emitEvent('allPortfolioIndex', { pageIndex: this.portfolioListIndex, pageSize: 10 })
      }
    });

    this.socketService.listen('selectiveReportsIndex').subscribe(data => {
      data.forEach(dataElement => {
        this.reportsList.push(dataElement);
      });
      this.reportsListIndex++;
      if (this.reportsListIndex <= Math.ceil(this.reportsCount / 10)) {
        this.socketService.emitEvent('selectiveReportsIndex', { pageIndex: this.reportsListIndex, pageSize: 10 })
      } else {
        this.reset();
        this.socketService.emitEvent('allPortfolioCount', {});
      }
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.fetchProjectsList();

    gantt.config.xml_date = "%d.%m.%Y";
    gantt.config.date_grid = "%d.%m.%Y";
    gantt.config.smart_scales = false;
    gantt.config.drag_move = false;
    gantt.config.drag_progress = false;
    gantt.config.drag_resize = false;
    gantt.config.readonly = true;
    gantt.config.scale_unit = "year";
    gantt.config.date_scale = "%M, %Y";
    gantt.config.chartTitle = "Reports Gantt View";
    gantt.config.heading_label = "Project Name";
    gantt.config.autofit = true;

    gantt.config.columns = [
      { name: "text", label: this.localize.transform("Project Name"), tree: true, align: "left", min_width: 150, width: 250, resize: true },
      { name: "start_date", label: this.localize.transform("Start Date"), align: "center", width: 105, resize: true },
      { name: "end_date", label: this.localize.transform("End Date"), align: "center", width: 105, resize: true },
      {
        name: "progress", label: this.localize.transform("Progress"), align: "center", width: 90, resize: true, template: function (task) {
          return (Math.floor(task.progress * 100) || 0) + '%';
        }
      },
    ];
    gantt.config.keep_grid_width = false;
    gantt.config.grid_resize = true;

    gantt.init(this.ganttContainer.nativeElement);

    gantt.clearAll();
  }

  fetchProjectsList() {
    if (gantt.getDatastore() != null) {
      gantt.clearAll();
    }

    let sub = this.route.params.subscribe(params => {
      this.programId = params.id;

      this.apiService.get('/program/' + this.programId).subscribe((response: any) => {
        this.utilityService.breadcrumbList = ['My Programs', response.programName, 'Details', 'Gantt View'];
        response.reports.forEach((report, index) => {
          this.idsArray.push(report.id);
          if (response.reports.length - 1 == index) {
            this.reportsCount = this.idsArray.length;
            this.socketService.emitEvent('selectiveReportsIndex', { ids: this.idsArray, pageIndex: 1, pageSize: 10 });
          }
        });
        this.spinner.hide();
      });
    });
  }

  fetchPortfoliosList() {
    this.apiService.get('/portfolio').subscribe((portfolioList: any) => {
      this.portfolioList = portfolioList;
    });
  }

  filterProjects(dataObj) {
    this.tasks = [];
    this.reportsList.forEach(reportObj => {
      if (reportObj.portfolio != undefined) {
        if (reportObj.portfolio.id == dataObj.value.id) {
          this.tasks.push({
            id: reportObj.id,
            text: reportObj.projectName,
            start_date: reportObj.startDate,
            end_date: reportObj.plannedEndDate,
            progress: reportObj.statusReports.length > 0 ? reportObj.statusReports[reportObj.statusReports.length - 1].percentageComplete / 100 : 0,
          });
        }
      }
    });

    let [data, links] = [this.tasks, this.links];
    gantt.clearAll();
    gantt.parse({ data, links });
    gantt.render();
  }

  switchDateScale(value: string) {
    this.selectedFilter = value;
    gantt.config.scale_unit = value;
    gantt.render();
  }

  reset() {
    gantt.clearAll();
    this.reportsList.forEach(element => {
      let progress;
      if (element.statusReports != undefined) {
        if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].percentageComplete != undefined) {
          progress = element.statusReports[element.statusReports.length - 1].percentageComplete / 100;
        } else {
          progress = 0;
        }
      } else {
        progress = 0;
      }
      this.tasks.push({
        id: element.id,
        text: element.projectName,
        start_date: moment(element.startDate).format('DD/MM/YYYY'),
        end_date: moment(element.plannedEndDate).format('DD/MM/YYYY'),
        progress: progress //element.statusReports != undefined ? element.statusReports[element.statusReports.length - 1].percentageComplete / 100 : 0,
      });
    });
    let [data, links] = [this.tasks, this.links];
    gantt.parse({ data });
  }

  ngOnDestroy() {
    this.socketService.removeListener('selectiveReportsIndex');
    this.socketService.removeListener('allPortfolioCount');
    this.socketService.removeListener('allPortfolioIndex');
  }

}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  text?: string;
  progress: number;
  duration?: number;
}