import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'app/services/api.service';
import { BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { SocketService } from 'app/services/socket.service';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  emailTextList = [];
  isEdit = false;
  emailObj: any = {};
  modalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  @ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;

  constructor(private apiService: ApiService, private modalService: BsModalService, private utilityService: UtilityService,
    private socketService: SocketService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.socketService.emitEvent('emailConfig', {});
    this.socketService.listen('emailConfig').subscribe(response => {
      this.emailTextList = response;
      this.spinner.hide();
    });

    this.utilityService.breadcrumb = 'Email Configuration';
    this.utilityService.breadcrumbList = ['Settings', 'Email Configuration'];
  }

  ngOnInit() {
    this.spinner.show();
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.emailObj = obj;
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    if (!this.isEdit) {
      this.emailObj = {};
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  edit(id) {
    this.spinner.show();
    let text = this.emailObj.text;

    this.apiService.put('/emailConfig/' + id, {
      text: text,
    }).subscribe(response => {
      this.apiService.get('/emailConfig').subscribe((response: any) => {
        this.emailTextList = response;
        this.utilityService.emailTemplates = response;
        this.isEdit = false;
        this.dataGrid.instance.option('dataSource', this.emailTextList);
        this.modalRef.hide();
        this.spinner.hide();
      });
    });
  }

  ngOnDestroy() {
    this.socketService.removeListener('emailConfig');
  }

}
