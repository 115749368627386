import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilityService } from 'app/services/utility.service';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'app/services/auth.service';
import { SocketService } from 'app/services/socket.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {
  programsList = [];
  programsCount: number = 0;
  programsSearch: any = '';
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isPm: boolean = false;
  programSubject = new Subject<any>();
  stateStore;
  programsPageIndex = 2;

  constructor(public utilityService: UtilityService, private authService: AuthService, private apiService: ApiService,
    private socketService: SocketService, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = "My Programs";
    this.utilityService.breadcrumbList = ['My Programs'];

    if (this.authService.userObj.role == "admin" || this.authService.userObj.role == "pmo") {
      this.isPm = false;
    } else {
      this.isPm = true;
    }

    setTimeout(() => {
      $("app-my-app .main-panel").animate({ scrollTop: 0 }, "slow");
    }, 200);

    this.stateStore = {
      enabled: true,
      storageKey: 'programsList',
      type: 'localStorage',
      savingTimeout: 200
    };
  }

  ngOnInit() {

    this.socketService.listen('programsCount').subscribe(count => {
      this.programsCount = count;

      if (count > 20) {
        this.socketService.emitEvent('programsIndex', { pageIndex: this.programsPageIndex, pageSize: 20, userId: this.authService.userObj.id });
      }
    });

    this.socketService.listen('programsIndex').subscribe((response: any) => {
      this.programsList = this.programsList.concat(response);

      if (this.programsPageIndex < (this.programsCount / 20)) {
        this.programsPageIndex++;
        this.socketService.emitEvent('programsIndex', { pageIndex: this.programsPageIndex, pageSize: 20, userId: this.authService.userObj.id });
      }
    });

    this.fetchPrograms();
  }

  fetchPrograms() {
    this.spinner.show();
    this.apiService.get('/getProgramsByUser/' + this.authService.userObj.id + '?limit=20').subscribe((response: any) => {
      this.programsList = response;
      this.socketService.emitEvent('programsCount', { userId: this.authService.userObj.id });
      this.spinner.hide();
    });
  }

  refreshGrid() {
    this.dataGrid.instance.option('dataSource', this.programsList);
  }

  ngOnDestroy() {
    this.socketService.removeListener('programsCount');
    this.socketService.removeListener('programsIndex');
  }

}
