import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import 'rxjs/operator/catch';

@Injectable()
export class DropdownResolverService implements Resolve<Observable<any>>{

  constructor(private apiService: ApiService) { }

  resolve(): Observable<any> | any {
    return this.apiService.get("/dropdown").catch(() => {
      return Observable.of('Network or server error.');
    });
  }

}
