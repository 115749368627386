import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'app/services/socket.service';
import { UtilityService } from 'app/services/utility.service';

@Component({
  selector: 'app-sub-portfolio-milestones',
  templateUrl: './sub-portfolio-milestones.component.html',
  styleUrls: ['./sub-portfolio-milestones.component.scss']
})
export class SubPortfolioMilestonesComponent implements OnInit, OnDestroy {
  agregatedMilstonesList = [];
  subportfolioId;
  isVisible: boolean = false;

  constructor(private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private socketService: SocketService, private utilityService: UtilityService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.utilityService.breadcrumbList = ['Subportfolios', 'Details', 'Milestones'];

    let sub = this.route.params.subscribe(params => {
      this.subportfolioId = params.subPortfolio;

      this.socketService.listen('subportfolioReports').subscribe(reports => {
        reports.forEach((report, index) => {
          if (report.status == 'Active') {
            report.mileStonesValues.forEach(milestone => {
              if (milestone.description != "") {
                let obj: any = {};
                obj.projectName = report.projectName;
                obj.description = milestone.description;
                obj.dueDate = milestone.dueDate;
                obj.report = milestone.report;
                if (milestone.status == undefined) {
                  obj.status = "";
                }
                else {
                  obj.status = milestone.status;
                }
                this.agregatedMilstonesList.push(obj);
              }
            });
          }
        });
        this.isVisible = true;
        this.spinner.hide();
      });

      this.socketService.emitEvent('subportfolioReports', { id: this.subportfolioId });

    });
  }

  onEditorPreparingMilestonesTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'projectName') {
      e.editorOptions.maxLength = 50;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
    }
  }

  ngOnDestroy() {
    this.socketService.removeListener('subportfolioReports');
  }
}
