import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectAssignmentRoutingModule } from './project-assignment-routing.module';
import { ProjectAssignmentComponent } from './project-assignment.component';
import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule, DxCheckBoxModule, DxTagBoxModule, DxAutocompleteModule, DxDropDownBoxModule, DxTreeViewModule } from 'devextreme-angular';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { PDFModule } from 'app/pdf/pdf.module';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [ProjectAssignmentComponent],
  imports: [
    CommonModule,
    ProjectAssignmentRoutingModule,
    DxDataGridModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxAutocompleteModule,
    MaterialModule,
    PDFModule,
    ModalModule,
    DxDropDownBoxModule,
    DxTreeViewModule
  ]
})
export class ProjectAssignmentModule { }
