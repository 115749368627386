import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

var globalThis;

@Component({
  selector: 'app-order-risks',
  templateUrl: './order-risks.component.html',
  styleUrls: ['./order-risks.component.scss']
})
export class OrderRisksComponent implements OnInit {
  public modalRef: BsModalRef;
  risksList;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  riskForm: any = {
    name: '',
    probability: 0,
    riskStrategy: {
      id: ''
    },
    description: '',
    measures: ''
  };
  statusOptions;
  @Input() smallOrderId;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  isEdit = false;
  risksTopFiveRiskStrategySelectOptions;
  risksTopFiveReputationRiskSelectOptions = [
    {
      reputationRisks: 0,
    }, {
      reputationRisks: 1,
    }, {
      reputationRisks: 2,
    }, {
      reputationRisks: 3,
    }, {
      reputationRisks: 4,
    }];
  reportRelevantRisksCount = 0;
  backupForm;
  @Output() updateTotalExposure = new EventEmitter<any>();
  invalidChars = [
    "-",
    "+",
    "e",
  ];
  currency: string;
  helpGuide;

  constructor(private apiService: ApiService, private modalService: BsModalService, private spinner: NgxSpinnerService,
    private utilityService: UtilityService, private localize: I18nPipe, private route: ActivatedRoute, private authService: AuthService) {
    globalThis = this;
    this.risksTopFiveRiskStrategySelectOptions = this.utilityService.risksTopFiveRiskStrategySelectOptions;
  }

  ngOnInit() {
    this.statusOptions = ['Active', 'Inactive'];
    this.risksList = [
      {
        id: 1,
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 2,
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 3,
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 4,
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      }, {
        id: 5,
        description: '',
        probability: '',
        extentOfLosses: '',
        riskExposure: '',
        riskStrategy: '',
        reputationRisks: '',
        measures: '',
        report: false,
      },];

    this.apiService.get('/smallOrder/' + this.smallOrderId).subscribe((response: any) => {
      this.currency = response.currency;

      if (response.risks != undefined) {
        this.risksList = response.risks;
        this.reportRelevantRisksCount = response.reportRelevantRisksCount;
        this.dataGrid.instance.option('dataSource', this.risksList);
      }
      else {
        this.risksList = [];
        this.reportRelevantRisksCount = 0;
      }
      if (this.reportRelevantRisksCount == undefined) {
        this.reportRelevantRisksCount = 0;
      }

      if (this.risksList.length > 0) {
        this.reportRelevantRisksCount = 0;
        for (let i = 0; i < this.risksList.length; i++) {
          if (this.risksList[i].report == true) {
            this.reportRelevantRisksCount++;
          }
        }
      }
    });
  }

  onEditorPreparingRisksTopFiveTable(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'id') {
      e.editorOptions.maxLength = 2;
    }
    if (e.parentType == 'dataRow' && e.dataField == 'description') {
      e.editorOptions.maxLength = 50;
      e.editorOptions.placeholder = 'Enter your description';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'probability') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = '%';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'extentOfLosses') {
      e.editorOptions.maxLength = 4;
      e.editorOptions.placeholder = 'CHF';
    }
    if (e.parentType == 'dataRow' && e.dataField == 'riskExposure') {
      e.editorOptions.maxLength = 4;
    }
  }

  calculateRiskExposureValue(e) {
    if (typeof (e.extentOfLosses) == 'number' && typeof (e.probability) == 'number') {
      globalThis.risksList[e.id - 1].riskExposure = e.extentOfLosses * e.probability / 100;
      return globalThis.risksList[e.id - 1].riskExposure;
    } else {
      return '';
    }
  }

  openModal(template: TemplateRef<any>, state) {
    if (state == "New") {
      this.isEdit = false;
      this.resetForm();
    }

    if (state == "Edit") {
      if (!this.isEdit) {
        this.resetForm();
      }
    }

    this.modalRef = this.modalService.show(template, this.config);
  }

  openEditDialog(template, obj) {
    this.isEdit = true;
    this.riskForm = obj;
    this.backupForm = JSON.parse(JSON.stringify(obj));
    this.openModal(template, "Edit");
  }

  openDeleteDialog(template, obj) {
    let msg = 'Are you sure you want to delete?';

    swal({
      title: `${this.localize.transform('Confirmation')}`,
      text: `${this.localize.transform(msg)}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: `${this.localize.transform('Yes')}`,
      cancelButtonText: `${this.localize.transform('No')}`,
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if (obj.report == true) {
          this.showNotificationBox('Warning', 'Report relevant risk cannot be deleted.', 'warning');
        } else {
          let idx;
          this.risksList.forEach((val, index) => {
            if (val.id == obj.id) {
              idx = index;
            }
          });

          this.risksList.splice(idx, 1);
          //Updating id's 
          for (let index = 0; index < this.risksList.length; index++) {
            this.risksList[index].id = index + 1;
          }

          this.apiService.put('/smallOrder/' + this.smallOrderId, {
            risks: this.risksList
          }).subscribe(response => {
            this.dataGrid.instance.option('dataSource', this.risksList);
            this.showNotificationBox('Success', 'Risk deleted.', 'success');
          });
        }
      }
    });
  }

  save() {
    if (this.smallOrderId != undefined) {
      if (this.riskFormValidation() && this.checkRange()) {
        if (this.risksList.length == 0) {
          this.riskForm.id = 1;
        } else {
          this.riskForm.id = this.risksList[this.risksList.length - 1].id + 1;
        }

        if (typeof (this.riskForm.extentOfLosses) == 'number' && typeof (this.riskForm.probability) == 'number') {
          this.riskForm.riskExposure = this.riskForm.extentOfLosses * this.riskForm.probability / 100;
        }

        if (this.riskForm.report == true) {
          if (this.reportRelevantRisksCount <= 2) {
            this.risksList.push(this.riskForm);
            this.resetForm();
            this.reportRelevantRisksCount++;
            this.spinner.show();
            this.apiService.put('/smallOrder/' + this.smallOrderId, {
              reportRelevantRisksCount: this.reportRelevantRisksCount,
              risks: this.risksList
            }).subscribe(response => {
              this.isEdit = false;
              this.dataGrid.instance.option('dataSource', this.risksList);
              this.updateTotalExposure.emit({
                risks: this.risksList
              });
              this.modalRef.hide();
              this.spinner.hide();
            });
          } else {

            this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
          }
        } else {
          this.risksList.push(this.riskForm);
          this.resetForm();
          this.spinner.show();
          this.apiService.put('/smallOrder/' + this.smallOrderId, {
            reportRelevantRisksCount: this.reportRelevantRisksCount,
            risks: this.risksList
          }).subscribe(response => {
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.risksList);
            this.updateTotalExposure.emit({
              risks: this.risksList
            });
            this.modalRef.hide();
            this.spinner.hide();
          });
        }
      }
    }
  }

  resetForm() {
    this.riskForm = {
      name: '',
      report: false,
      riskStrategy: {
        id: ''
      },
      description: '',
      measures: ''
    };
  }

  edit() {
    if (this.riskFormValidation() && this.checkRange()) {
      if (this.riskForm.report == true) {
        if (this.backupForm.report == true && this.riskForm.report == true) {
          this.spinner.show();
          this.apiService.put('/smallOrder/' + this.smallOrderId, {
            reportRelevantRisksCount: this.reportRelevantRisksCount,
            risks: this.risksList
          }).subscribe(response => {
            this.isEdit = false;
            this.dataGrid.instance.option('dataSource', this.risksList);
            this.updateTotalExposure.emit({
              risks: this.risksList
            });
            this.modalRef.hide();
            this.spinner.hide();
          });
        } else {
          if (this.reportRelevantRisksCount <= 2) {
            if (this.backupForm.report == false && this.riskForm.report == true) {
              this.reportRelevantRisksCount++;
            }
            this.spinner.show();
            this.apiService.put('/smallOrder/' + this.smallOrderId, {
              reportRelevantRisksCount: this.reportRelevantRisksCount,
              risks: this.risksList
            }).subscribe(response => {
              this.isEdit = false;
              this.dataGrid.instance.option('dataSource', this.risksList);
              this.updateTotalExposure.emit({
                risks: this.risksList
              });
              this.modalRef.hide();
              this.spinner.hide();
            });
          } else {
            this.riskForm.report = false;
            this.showNotificationBox('Warning', 'Report relevant can be maximum of 3', 'warning');
          }
        }
      } else {
        if (this.backupForm.report == true && this.riskForm.report == false) {
          this.reportRelevantRisksCount--;
        }
        this.spinner.show();

        this.apiService.put('/smallOrder/' + this.smallOrderId, {
          reportRelevantRisksCount: this.reportRelevantRisksCount,
          risks: this.risksList
        }).subscribe(response => {
          this.isEdit = false;
          this.dataGrid.instance.option('dataSource', this.risksList);
          this.updateTotalExposure.emit({
            risks: this.risksList
          });
          this.modalRef.hide();
          this.spinner.hide();
        });
      }
    }
  }

  checkRange(): boolean {
    if (this.riskForm.probability < 100) {
      return true;
    } else {
      this.showNotificationBox('Warning', 'Values must be between 1-99.', 'warning');
      return false;
    }
  }

  riskStrategyValueChanged(e) {
    if (this.risksTopFiveRiskStrategySelectOptions != undefined) {
      this.risksTopFiveRiskStrategySelectOptions.values.forEach(response => {
        if (response.id == e.value) {
          this.riskForm.riskStrategy = response;
        }
      });
    }
  }

  riskFormValidation(): boolean {
    if (this.riskForm.name.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a risk name first.', 'warning');
      return false;
    }
    if (this.riskForm.description.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a risk description first.', 'warning');
      return false;
    }
    if (this.riskForm.riskStrategy.name == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk strategy first.', 'warning');
      return false;
    }
    if (this.riskForm.measures.length <= 0) {
      this.showNotificationBox('Warning', 'Please enter a risk measure first.', 'warning');
      return false;
    }
    if (this.riskForm.extentOfLosses == "" || this.riskForm.extentOfLosses == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk extent of losses first.', 'warning');
      return false;
    }
    if (this.riskForm.probability == "" || this.riskForm.probability == undefined) {
      this.showNotificationBox('Warning', 'Please enter a risk probability first.', 'warning');
      return false;
    }
    else {
      return true;
    }
  }

  onKeyExtentOfLossesInfo(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  onKeyProbabilityInfo(e) {
    if (this.invalidChars.includes(e.key) || (((e.ctrlKey || e.metaKey) && e.keyCode == 86))) {
      e.preventDefault();
    }
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }
}
