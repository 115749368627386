import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DxChartModule, DxPieChartModule, DxDataGridModule, DxSelectBoxModule, DxScrollViewModule, DxProgressBarModule, DxPopupModule, DxTextBoxModule } from 'devextreme-angular';

import { DashboardComponent } from './dashboard.component';
import { DashboardRoutes } from './dashboard.routing';
import { I18nModule } from "../shared/i18n/i18n.module";
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { DropdownResolverService } from 'app/resolvers/dropdown-resolver.service';

import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BudgetImportModule } from 'app/budget-import/budget-import.module';
import { ExcelExportModule } from 'app/settings/excel-export/excel-export.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(DashboardRoutes),
    FormsModule,
    MaterialModule,
    I18nModule,
    DxChartModule,
    DxPieChartModule,
    DxDataGridModule,
    DxSelectBoxModule,
    CurrencyFormatterPipeModule,
    ReactiveFormsModule,
    DxScrollViewModule,
    DxProgressBarModule,
    DxPopupModule,
    DxTextBoxModule,
    ModalModule.forRoot(),
    FileUploadModule,
    NgxSpinnerModule,
    BudgetImportModule,
    ClipboardModule,
    ExcelExportModule
  ],
  declarations: [
    DashboardComponent
  ],
  providers: [
    DropdownResolverService,
    DatePipe
  ]
})

export class DashboardModule { }
