import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProgramsRoutingModule } from './programs-routing.module';
import { ProgramsComponent } from './programs.component';
import { DxDataGridModule } from 'devextreme-angular';
import { I18nModule } from "../shared/i18n/i18n.module";
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    ProgramsRoutingModule,
    DxDataGridModule,
    I18nModule,
    MaterialModule,
    FormsModule,
    NgxSpinnerModule
  ],
  declarations: [
    ProgramsComponent
  ],
  exports: [
    ProgramsComponent
  ]
})
export class ProgramsModule { }
