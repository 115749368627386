import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import 'rxjs/operator/map';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'app/services/utility.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-checklist',
  templateUrl: './project-checklist.component.html',
  styleUrls: ['./project-checklist.component.scss']
})
export class ProjectChecklistComponent implements OnInit {
  public questionsList: Array<any>;
  public orderQuestionsList: Array<any>;
  public changeRequestQuestionsList: Array<any> = [];
  public closingQuestionsList: Array<any> = [];
  public portfolioQuestionsList: Array<any> = [];
  public portfolioOrderQuestionsList: Array<any> = [];
  public portfolioChangeRequestQuestionsList: Array<any> = [];
  public portfolioClosingQuestionsList: Array<any> = [];
  portfolio;

  questions;
  orderQuestion;
  changeRequestQuestion;
  closingQuestion;
  portfolioOutlineAnswers;
  portfolioOrderAnswers;
  portfolioChangeRequestAnswers;
  portfolioClosingAnswers;
  isVisible: boolean = false;

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, private utilityService: UtilityService, public route: ActivatedRoute) {
    this.utilityService.breadcrumbList[3] = 'Check List';

    let sub = this.route.params.subscribe(params => {
      this.apiService.get('/portfolioByReport/' + params.id).subscribe((portfolio: any) => {
        this.portfolio = portfolio;
        this.portfolioQuestionsList =  portfolio.questions ? portfolio.questions : [];
        this.portfolioOrderQuestionsList = JSON.parse(JSON.stringify(this.portfolioQuestionsList));
        this.portfolioChangeRequestQuestionsList = JSON.parse(JSON.stringify(this.portfolioQuestionsList));
        this.portfolioClosingQuestionsList = JSON.parse(JSON.stringify(this.portfolioQuestionsList));

        this.apiService.get('/reports/getDocumentAnswers/' + params.id).subscribe((responseArray: any) => {
          let response = responseArray[0];
          this.questions = response.question ? response.question : [];
          this.orderQuestion = response.orderQuestion ? response.orderQuestion : [];
          this.changeRequestQuestion = response.changeRequestQuestion ? response.changeRequestQuestion : [];
          this.closingQuestion = response.closingQuestion ? response.closingQuestion : [];

          this.portfolioOutlineAnswers = response.portfolioQuestions ? response.portfolioQuestions : [];
          this.portfolioOrderAnswers = response.orderPortfolioQuestions ? response.orderPortfolioQuestions : [];
          this.portfolioChangeRequestAnswers = response.portfolioChangeRequestQuestion ? response.portfolioChangeRequestQuestion : [];
          this.portfolioClosingAnswers = response.portfolioClosingReportQuestion ? response.portfolioClosingReportQuestion : [];

          if (this.portfolioOutlineAnswers.length > 0) {
            let i = 0;
            this.portfolioOutlineAnswers.map(val => {
              this.portfolioQuestionsList[i++].val = val;
            });
          }

          if (this.portfolioOrderAnswers.length > 0) {
            let i = 0;
            this.portfolioOrderAnswers.map(val => {
              this.portfolioOrderQuestionsList[i++].val = val;
            });
          }

          if (this.portfolioChangeRequestAnswers.length > 0) {
            let i = 0;
            this.portfolioChangeRequestAnswers.map(val => {
              this.portfolioChangeRequestQuestionsList[i++].val = val;
            });
          }

          if (this.portfolioClosingAnswers.length > 0) {
            let i = 0;
            this.portfolioClosingAnswers.map(val => {
              this.portfolioClosingQuestionsList[i++].val = val;
            });
          }

          this.apiService.get('/questions/getQuestionsByFormName/outline').subscribe((response: any) => {
            this.questionsList = response.questions;

            let i = 0;
            if (this.questions != undefined && this.questions.length > 0) {
              this.questions.map(val => {
                this.questionsList[i++].val = val;
              });
            }

            this.isVisible = true;
            this.spinner.hide();
          });

          this.apiService.get('/questions/getQuestionsByFormName/order').subscribe((response: any) => {
            this.orderQuestionsList = response.questions;

            let i = 0;
            if (this.orderQuestion != undefined && this.orderQuestion.length > 0) {
              this.orderQuestion.map(val => {
                this.orderQuestionsList[i++].val = val;
              });
            }
          });

          this.apiService.get('/questions/getQuestionsByFormName/Change Request').subscribe((response: any) => {
            this.changeRequestQuestionsList = response.questions;

            let i = 0;
            if (this.changeRequestQuestion != undefined && this.changeRequestQuestion.length > 0) {
              this.changeRequestQuestion.forEach(val => {
                this.changeRequestQuestionsList[i++].val = val;
              });
            }
          });

          this.apiService.get('/questions/getQuestionsByFormName/Closing Report').subscribe((response: any) => {
            this.closingQuestionsList = response.questions;

            let i = 0;
            if (this.closingQuestion != undefined && this.closingQuestion.length > 0) {
              this.closingQuestion.forEach(val => {
                this.closingQuestionsList[i++].val = val;
              });
            }
          });
        })
      });

    })
  }

  ngOnInit() {
    this.spinner.show();
  }
}
