import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SmallOrderBudgetRoutingModule } from './small-order-budget-routing.module';
import { SmallOrderBudgetComponent } from './small-order-budget.component';
import { I18nModule } from "../../shared/i18n/i18n.module";
import { NgxSpinnerModule } from 'ngx-spinner';
import { DxChartModule, DxPieChartModule, DxDataGridModule, DxSelectBoxModule, DxScrollViewModule, DxProgressBarModule, DxPopupModule, DxTextBoxModule } from 'devextreme-angular';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';


@NgModule({
  declarations: [SmallOrderBudgetComponent],
  imports: [
    CommonModule,
    SmallOrderBudgetRoutingModule,
    DxDataGridModule,
    I18nModule,
    FormsModule,
    NgxSpinnerModule,
    DxSelectBoxModule,
    CurrencyFormatterPipeModule
  ]
})
export class SmallOrderBudgetModule { }
