import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramStatusReportComponent } from './program-status-report.component';

const routes: Routes = [
  {
    path: '',
    component: ProgramStatusReportComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramStatusReportRoutingModule { }
