import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, NgZone, TemplateRef, ApplicationRef, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SocketService } from 'app/services/socket.service';
import 'rxjs/operator/map';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import swal from 'sweetalert2';
declare var gantt: any;
let globalThis;

@Component({
  selector: 'app-project-gantt-chart',
  templateUrl: './project-gantt-chart.component.html',
  styleUrls: ['./project-gantt-chart.component.scss']
})
export class ProjectGanttChartComponent implements OnInit, OnDestroy {
  @Input() isProjectUneditable;
  @ViewChild("gantt", { static: true }) ganttContainer: ElementRef;
  project;
  tasks: Task[] = [];
  links = [];
  ganttChart;
  viewOpt = 'Show Resource view';
  task: any = {
    id: '',
    text: '',
    time: ''
  };
  peopleList: any = [];
  usersList: any = [];
  ownerObj = {
    id: '',
    name: '',
    email: ''
  };
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  configLogsModal = {
    animated: true,
    keyboard: true,
    backdrop: true,
    class: "change-logs-modal",
    ignoreBackdropClick: true
  };
  configkanbanModal = {
    animated: true,
    keyboard: true,
    backdrop: true,
    class: "kanban-modal",
    ignoreBackdropClick: true
  };
  configMilestoneReportModal = {
    animated: true,
    keyboard: true,
    backdrop: true,
    class: "milestone-report-modal",
    ignoreBackdropClick: true
  };
  public modalRef: BsModalRef;
  @ViewChild('popUp', { static: false }) popUp: TemplateRef<any>;
  @ViewChild('changeLogspopUp', { static: false }) changeLogspopUp: TemplateRef<any>;
  @ViewChild('milestoneReportpopUp', { static: false }) milestoneReportpopUp: TemplateRef<any>;
  @ViewChild('kanbanpopUp', { static: false }) kanbanpopUp: TemplateRef<any>;
  projectReportId;
  public selectedFilter: string = 'year';
  private isCriticalPathShow: boolean = false;
  showActionBar: boolean = true;
  isShowResourceChart: boolean = false;
  resourceConfig;
  resourceTemplates;
  ganttAndResourceLayout;
  ganttOnlyLayout;
  commentsFeedData = [];
  userGroupIndex = undefined;
  taskGroupIndex = undefined;
  dateGroupIndex = 0;
  changeLogsBuffer = [];
  projectChangeLogs = [];
  openedTaskBackup = null;
  userGroupIndexLogs = undefined;
  taskGroupIndexLogs = undefined;
  dateGroupIndexLogs = 0;
  mtaObj;
  mtaChart = [];
  mileStonesValues = [];
  boardData: any = [
    {
      columnId: 'todo',
      column: 'Todo',
      list: []
    },
    {
      columnId: 'inProgress',
      column: 'In Progress',
      list: []
    },
    {
      columnId: 'testing',
      column: 'Testing',
      list: []
    },
    {
      columnId: 'done',
      column: 'Done',
      list: []
    },
  ];
  boardDataBackup: any = [
    {
      columnId: 'todo',
      column: 'Todo',
      list: []
    },
    {
      columnId: 'inProgress',
      column: 'In Progress',
      list: []
    },
    {
      columnId: 'testing',
      column: 'Testing',
      list: []
    },
    {
      columnId: 'done',
      column: 'Done',
      list: []
    },
  ];
  tasksList: any = [];
  tasksBackupList: any = [];

  constructor(private apiService: ApiService, public utilityService: UtilityService, private localize: I18nPipe,
    private ref: ChangeDetectorRef, private ngZone: NgZone, private modalService: BsModalService, private appRef: ApplicationRef,
    private spinner: NgxSpinnerService, private route: ActivatedRoute, private socketService: SocketService, private authService: AuthService) {
    globalThis = this;
  }

  ngOnInit() {
    this.spinner.show();

    let sub = this.route.params.subscribe(params => {
      if (params.id != undefined) {
        this.utilityService.breadcrumb = "Project Gantt View";
        this.projectReportId = params.id;

        this.apiService.get('/reports/' + this.projectReportId).subscribe((response: any) => {
          this.project = response;
          this.utilityService.breadcrumbList = ['My Projects', this.project.projectName, 'Details', 'Trend Analysis'];
          let userIds = [];
          userIds.push(this.project.user.id);

          if (this.project.ganttChart != null && this.project.ganttChart != undefined) {
            this.project.ganttChart.data.forEach(task => {
              task.color = task.status == true ? '#51c185' : '#002d69';
              if (task.owner_id) {
                userIds.push(task.owner_id);
              }
              if (task.comments) {
                task.comments.forEach(element => {
                  if (element.commentTime) {
                    let date = element.commentTime.split(" ")[0];
                    this.commentsFeedData.push({ id: task.id, name: task.text, comment: element.message, user: element.user, commentTime: element.commentTime, commentDate: date });
                  }
                });
              }
            });
          }

          this.fetchGanttProjectLogs();
          this.fetchGanttMilestoneReport();

          this.utilityService.projectDetail.next({
            projectReportId: this.projectReportId,
            projectId: response.project,
            isTeamProject: this.utilityService.isTeamProject,
            projectName: this.project.projectName,
            tabIndex: 2
          });

          this.socketService.emitEvent('fetchMultipleUsers', { userIds: userIds });
        });
      }
    });

    this.socketService.listen('fetchMultipleUsers').subscribe(responseUsers => {
      responseUsers.forEach(user => {
        this.peopleList.push({ id: user.id, text: user.name, email: user.email, parent: null });
      });

      // initialize gantt chart
      gantt.init(this.ganttContainer.nativeElement);
      // to clear gantt data
      gantt.clearAll();

      let resourceMode = "hours";

      // identify folder_row, group_row and highlighted_resource in gantt chart and add css accordingly  
      gantt.templates.grid_row_class = function (start, end, task) {
        let css = [];
        if (gantt.hasChild(task.id)) {
          css.push("folder_row");
        }

        if (task.$virtual) {
          css.push("group_row")
        }

        if (globalThis.shouldHighlightTask(task)) {
          css.push("highlighted_resource");
        }

        if (task.status) {
          css.push("milestone-row-done");
        } else {
          if (moment() > moment(task.end_date).add(1, 'days')) {
            css.push("milestone-row-expired");
          } else if (task.kanbanStatus == 'inProgress') {
            css.push("milestone-row-inProgress");
          }
        }

        return css.join(" ");
      };

      // if task is highlighted return highlighted_resource string else nothing
      gantt.templates.task_row_class = function (start, end, task) {
        if (globalThis.shouldHighlightTask(task)) {
          return "highlighted_resource";
        }
        return "";
      };

      gantt.templates.timeline_cell_class = function (task, date) {
        if (!gantt.isWorkTime({ date: date, task: task }))
          return "week_end";
        return "";
      };

      gantt.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
        let css = [];
        css.push("resource_marker");
        if (tasks.length <= 1) {
          css.push("workday_ok");
        } else {
          css.push("workday_over");
        }
        return css.join(" ");
      };

      // shows the working hours in a day on task
      gantt.templates.resource_cell_value = function (start_date, end_date, resource, tasks) {
        let html = "<div>"
        if (resourceMode == "hours") {
          html += tasks.length * 8;
        } else {
          html += tasks.length;
        }
        html += "</div>";
        return html;
      };

      // if task is highlighted return highlighted_resource string else nothing
      this.resourceTemplates = {
        grid_row_class: function (start, end, resource) {
          let css = [];
          if (gantt.$resourcesStore.hasChild(resource.id)) {
            css.push("folder_row");
            css.push("group_row");
          }
          if (globalThis.shouldHighlightResource(resource)) {
            css.push("highlighted_resource");
          }
          return css.join(" ");
        },
        task_row_class: function (start, end, resource) {
          let css = [];
          if (globalThis.shouldHighlightResource(resource)) {
            css.push("highlighted_resource");
          }
          if (gantt.$resourcesStore.hasChild(resource.id)) {
            css.push("group_row");
          }

          return css.join(" ");

        }
      };

      // use to show resource chart and their labels and functionalities 
      this.resourceConfig = {
        scale_height: 30,
        scales: [
          { unit: "day", step: 1, date: "%d %M" }
        ],
        columns: [
          {
            name: "name",
            label: this.localize.transform("Name"),
            tree: true,
            width: 200,
            template: function (resource) {
              return resource.text;
            }, resize: true
          },
          {
            name: "progress",
            label: this.localize.transform("Complete"),
            align: "center",
            template: function (resource) {
              let tasks = globalThis.getResourceTasks(resource.id);

              let totalToDo = 0,
                totalDone = 0;
              tasks.forEach(function (task) {
                totalToDo += task.duration;
                totalDone += task.duration * (task.progress || 0);
              });

              let completion = 0;
              if (totalToDo) {
                completion = Math.floor((totalDone / totalToDo) * 100);
              }

              return Math.floor(completion) + "%";
            }, resize: true
          },
          {
            name: "workload", label: this.localize.transform("Workload"), align: "center", template: function (resource) {
              let tasks = globalThis.getResourceTasks(resource.id);
              let totalDuration = 0;
              tasks.forEach(function (task) {
                totalDuration += task.duration;
              });
              return (totalDuration || 0) * 8 + "h";
            }, resize: true
          },
          {
            name: "capacity", label: this.localize.transform("Capacity"), align: "center", template: function (resource) {
              let store = gantt.getDatastore(gantt.config.resource_store);
              let n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1

              let state = gantt.getState();

              return gantt.calculateDuration(state.min_date, state.max_date) * n * 8 + "h";
            }
          }
        ]
      };

      gantt.config.scales = [
        { unit: "month", step: 1, format: "%F, %Y" },
        { unit: "day", step: 1, format: "%d %M" }
      ];

      gantt.config.auto_scheduling = true;
      gantt.config.auto_scheduling_strict = true;
      gantt.config.work_time = true;
      gantt.config.task_height = 16;
      gantt.config.row_height = 40;
      gantt.config.drag_resize = true;
      gantt.config.readonly = false;
      gantt.config.resource_store = "resource";
      gantt.config.resource_property = "owner_id";
      gantt.config.order_branch = true;
      gantt.config.open_tree_initially = true;
      gantt.config.scale_height = 50;
      gantt.config.scale_unit = "year";
      gantt.config.date_scale = "%d %M, %Y";
      gantt.config.types["meeting"] = "type_id";
      gantt.config.lightbox["meeting_sections"] = [
        { name: "title", height: 30, map_to: "text", type: "textarea", focus: true },
        { name: "details", height: 70, map_to: "details", type: "textarea" },
        { name: "type", type: "typeselect", map_to: "type", height: 40 },
        { name: "meettime", type: "duration", single_date: true, time_format: ["%d", "%m", "%Y", "%H:%i"], map_to: "auto" },
        { name: "document", height: 30, type: "textarea", map_to: "document" },
      ];

      gantt.config.lightbox.milestone_sections = [
        { name: "description", height: 50, map_to: "text", type: "textarea", focus: true },
        { name: "comments", height: 300, type: "template", map_to: "my_template" },
        { name: "type", type: "typeselect", map_to: "type" },
        { name: "time", type: "duration", single_date: true, map_to: "auto" },
        { name: "status", height: 50, type: "checkbox", map_to: "status" }
      ];

      gantt._is_lightbox_timepicker = function () { return false; };

      // let textEditor = { type: "text", map_to: "text" };
      // let startDateEditor = { type: "date", map_to: "start_date", min: new Date(2018, 0, 1) };
      // let endDateEditor = { type: "date", map_to: "end_date", min: new Date(2018, 0, 1) };

      this.ganttAndResourceLayout = {
        css: "gantt_container",
        rows: [
          {
            gravity: 2,
            cols: [
              { view: "grid", group: "grids", scrollY: "scrollVer" },
              { resizer: true, width: 1 },
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              { view: "scrollbar", id: "scrollVer", group: "vertical" }
            ]
          },
          { resizer: true, width: 1, next: "resources" },
          {
            height: 35,
            cols: [
              { html: "", group: "grids" },
              { resizer: true, width: 1 },
              {
                html: `<label class='active gantt-radio-btn'>Hours per day <input checked type='radio' name='resource-mode' value='hours'></label>" +
                    "<label class='gantt-radio-btn'>Tasks per day <input type='radio' name='resource-mode' value='tasks'></label>`, css: "resource-controls"
              }
            ]
          },
          {
            gravity: 1,
            id: "resources",
            config: this.resourceConfig,
            templates: this.resourceTemplates,
            cols: [
              { view: "resourceGrid", group: "grids", scrollY: "resourceVScroll" },
              { resizer: true, width: 1 },
              { view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll" },
              { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
            ]
          },
          { view: "scrollbar", id: "scrollHor" }
        ]
      };


      this.ganttOnlyLayout = gantt.config.layout = {
        css: "gantt_container",
        rows: [
          {
            gravity: 2,
            cols: [
              { view: "grid", group: "grids", scrollY: "scrollVer" },
              { resizer: true, width: 1 },
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              { view: "scrollbar", id: "scrollVer", group: "vertical" }
            ]
          },
          { view: "scrollbar", id: "scrollHor" }
        ]
      };


      // gantt chart labels shows on the left side
      gantt.config.columns = [
        { name: "", label: "", width: 30, resize: true, template: gantt.getWBSCode },
        {
          name: "text", label: this.localize.transform("Project Name/Task Name"), tree: true, width: 220, resize: true, template: function (obj) {
            return `<div title="${obj.notes}">
              ${obj.text}
            </div>`
          }
        },
        {
          name: "start_date", label: this.localize.transform("Start Dates"), align: "center", width: 110, resize: true, template: function (obj) {
            return `<div">
            ${moment(obj.start_date).format('DD-MM-YYYY')}
          </div>`
          }
        },
        {
          name: "end_date", label: this.localize.transform("End Date"), align: "center", width: 110, resize: true, template: function (obj) {
            return `<div">
            ${moment(obj.end_date).format('DD-MM-YYYY')}
          </div>`
          }
        },
        {
          name: "owner",
          align: "left",
          width: 120,
          label: this.localize.transform("Owner"),
          template: function (task) {
            if (task.type == gantt.config.types.project) {
              return "";
            }

            let store = gantt.getDatastore(gantt.config.resource_store);
            let owner = store.getItem(task[gantt.config.resource_property]);
            if (owner) {
              return owner.text;
            } else {
              return "Unassigned";
            }
          }, resize: true
        },
        {
          name: "duration", label: this.localize.transform("Duration"), width: 100, align: "center", resize: true, template: function (obj) {
            return `<div> ${obj.type == gantt.config.types.meeting ? 0 : obj.duration}</div>`
          }
        },
        {
          name: "link", label: this.localize.transform("Link"), width: 60, align: "center", resize: true, template: function (obj) {
            return `<div>
            <a class="${obj.document && obj.document.length > 0 ? "link-green" : "link-grey"}" href="${obj.document}" target="_blank">
              <i class="fa fa-link" style="color:black; font-size:18px;"></i>
            </a>
          </div>`
          }
        },
        {
          name: "comments", label: this.localize.transform("Comments"), width: 100, align: "center", resize: true, template: function (obj) {
            return `
            ${obj.comments && obj.type == gantt.config.types.milestone ? obj.comments.length : 0}`
          }
        },
        { name: "add", width: 44 }
      ];

      // add scrolling in work chart and add radio buttons to select hours per day and tasks per day 
      gantt.config.layout = this.ganttOnlyLayout;

      // on gantt initialization shows the buttons on gantt task creation popup and shows tha radio buttons on work chart 
      // and add some css on it 
      gantt.attachEvent("onGanttReady", function () {
        gantt.config.buttons_left = ["gantt_delete_btn", "gantt_cancel_btn", "complete_button"];
        gantt.config.buttons_right = ["gantt_save_btn"];

        // adding baseline display
        gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.planned_start && task.planned_end) {
                var sizes = gantt.getTaskPosition(task, task.planned_start, task.planned_end);
                var el = document.createElement('div');
                el.className = 'baseline';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.task_height + 13 + 'px';
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function (task, view) {
              if (task.planned_start && task.planned_end) {
                return gantt.getTaskPosition(task, task.planned_start, task.planned_end);
              }
              return null;
            }
          }
        });

        let radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
        radios.forEach(function (r) {
          gantt.event(r, "change", function (e) {
            let radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
            radios.forEach(function (r) {
              r.parentNode.className = r.parentNode.className.replace("active", "");
            });

            if (this.checked) {
              resourceMode = this.value;
              this.parentNode.className += " active";
              gantt.getDatastore(gantt.config.resource_store).refresh();
            }
          });
        });
      });

      // create the treeDatastore for gantt and store in resourcesStore
      gantt.$resourcesStore = gantt.createDatastore({
        name: gantt.config.resource_store,
        type: "treeDatastore",
        initItem: function (item) {
          item.parent = item.parent || gantt.config.root_id;
          item[gantt.config.resource_property] = item.parent;
          item.open = true;
          return item;
        }
      });

      // refresh data on after gantt selection
      gantt.$resourcesStore.attachEvent("onAfterSelect", function (id) {
        gantt.refreshData();
      });

      // parse people data in gantt work chart to peoplecollection 
      gantt.$resourcesStore.attachEvent("onParse", function () {
        let people = [];
        gantt.$resourcesStore.eachItem(function (res) {
          if (!gantt.$resourcesStore.hasChild(res.id)) {
            let copy = gantt.copy(res);
            copy.key = res.id;
            copy.label = res.text;
            people.push(copy);
          }
        });
        gantt.updateCollection("people", people);
      });

      // parse the people data in gantt resourcesStore
      gantt.$resourcesStore.parse(this.peopleList);

      // click on owner button get data form task creation form and store in local variabla and open owner modal popup 
      //close task creation form popup
      gantt.attachEvent("onLightboxButton", function (button_id, node, e) {
        if (button_id == "complete_button") {
          let taskId = gantt.getState().lightbox;
          if (taskId == null || taskId == undefined) {
            gantt.hideLightbox();
          } else {
            globalThis.task.id = taskId;
            globalThis.task.text = gantt.getLightboxSection('description').getValue();
            globalThis.task.time = gantt.getLightboxSection('time').getValue();
            let owner_id = gantt.getTask(globalThis.task.id).owner_id;
            if (owner_id != undefined && owner_id != null) {
              globalThis.ownerObj = JSON.parse(JSON.stringify(globalThis.peopleList.find(val => val.id == owner_id)));
              if (globalThis.ownerObj) {
                if (!globalThis.ownerObj.name) {
                  globalThis.ownerObj.name = globalThis.ownerObj.text;
                }
              }
            } else {
              globalThis.ownerObj = { id: '', name: '', email: '' };
            }
            gantt.hideLightbox();
            globalThis.modalRef = globalThis.modalService.show(globalThis.popUp, this.config);
          }
        }
      });

      gantt.attachEvent("onBeforeLightbox", function (id) {
        let task = gantt.getTask(id);

        if (task.type == gantt.config.types.milestone || task.type == gantt.config.types.task) {
          let commentsArray = task.comments ? task.comments : [];
          let commentItems = '';

          for (let i = 0; i < commentsArray.length; i++) {
            let user = commentsArray[i].user;
            let message = commentsArray[i].message;
            let commentTime = commentsArray[i].commentTime ? commentsArray[i].commentTime : '';

            commentItems += `<li style='padding: 0px 0px 0px 0px;' class='list-group-item comment-tile'> 
              <div class='row' style='margin: 0px 0px 0px 0px !important; width: 100%;'>
              <div class='col-sm-9' style='margin-right: 0rem;'>
              <h4 style='margin-right: 1rem; font-size: 1rem; line-height: 1em;' class='comment-email'>  ${user}  </h4> 
              </div> 
              <div class='col-sm-3' style='color: black;'> ${commentTime} </div>
              <div class='col-sm-12' style='margin-right: 0rem;'>
              <h5 class='comment-text' style='width:100%; margin-bottom: 10px; font-size: 0.95rem; line-height: 1em; word-break: break-word;'>  ${message}  </h5> 
              </div>
              </div>
              </li>`;
          }


          let commentsHtml = `<div style='height: 150px; overflow-y:auto'> <ul class='list-group' data-taskId="${id}">  ${commentItems}  </ul> </div> 
            <textarea id='comments-area' class='form-control input-lg' cols='30' placeholder='Enter Comment' 
            rows='5' style='height: 100px !important; font-size: 16px; background-color: white !important;'></textarea> 
            <button mat-raised-button id='saveCommentID' class='btn btn-success btn-md pull-right btn-comments'> 
            <i class='fa fa-comments'></i> Add Comment</button>`;

          task.my_template = commentsHtml;
        }

        return true;
      });

      gantt.attachEvent("onLightbox", function (id) {
        let task = gantt.getTask(id);
        globalThis.openedTaskBackup = Object.assign({}, task);

        if (task.type == gantt.config.types.milestone || task.type == gantt.config.types.task) {
          let commentslistElem = document.getElementsByClassName("list-group")[0] as any;
          commentslistElem.scrollIntoView(false);
          //For status
          // let statusElem = document.getElementById("status").parentNode;
          // const doneByElem = document.createElement('span');
          // doneByElem.classList.add("pull-right");
          // doneByElem.style.marginRight = "15px";

          // doneByElem.innerHTML = `by <b> ${'Manager, Portfolio'} </b>`;
          // statusElem.appendChild(doneByElem);
        }

        return true;
      });

      gantt.attachEvent("onLightboxSave", function (id, task, is_new) {
        task.color = task.status == true ? '#51c185' : '#002d69';

        if (is_new) {
          globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "task_created", task_type: task.type, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
        } else {
          if (globalThis.openedTaskBackup.text != task.text) {
            globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "task_name_changed", task_type: task.type, taskId: task.id, oldTaskName: globalThis.openedTaskBackup.text, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
          }
          if (globalThis.openedTaskBackup.status != task.status) {
            globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "status_changed", newStatus: task.status, task_type: task.type, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
          }
          if (globalThis.openedTaskBackup.start_date.getTime() != task.start_date.getTime()) {
            globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "start_date_changed", task_type: task.type, old_date: globalThis.openedTaskBackup.start_date, new_date: task.start_date, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
          }
          if (globalThis.openedTaskBackup.end_date.getTime() != task.end_date.getTime()) {
            globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "end_date_changed", task_type: task.type, old_date: globalThis.openedTaskBackup.end_date, new_date: task.end_date, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
          }
        }

        if (task.$new) {
          delete task.$new;
          gantt.addTask(task, task.parent);
        } else {
          gantt.updateTask(task.id);
        }

        return true;
      });

      gantt.attachEvent("onAfterTaskDelete", function (id, task) {
        globalThis.changeLogsBuffer.push({ projectId: globalThis.project.project, action: "task_deleted", task_type: task.type, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
        return true;
      });

      gantt.attachEvent("onTemplatesReady", function () {
        // let toggle = document.createElement("i");
        // toggle.className = "fa fa-expand gantt-fullscreen";
        // gantt.toggleIcon = toggle;
        // gantt.$container.appendChild(toggle);
        // toggle.onclick = function () {
        //   if (!gantt.getState().fullscreen) {
        //     gantt.expand();
        //   }
        //   else {
        //     gantt.collapse();
        //   }
        // };
      });

      gantt.attachEvent("onAfterUndo", function (action) {
        //catch undo actions and handle gantt change logs
        if (action && action.commands) {
          let changeObj = action.commands[action.commands.length - 1];

          if (changeObj.type == 'update') {
            if (changeObj.oldValue.text != changeObj.value.text) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "task_name_changed"));
              task.isRemoved = true;
            }

            if (changeObj.oldValue.status != changeObj.value.status) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "status_changed"));
              task.isRemoved = true;
            }

            if (changeObj.oldValue.start_date.getTime() != changeObj.value.start_date.getTime()) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "start_date_changed"));
              task.isRemoved = true;
            }

            if (globalThis.openedTaskBackup.end_date.getTime() != changeObj.value.end_date.getTime()) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "end_date_changed"));
              task.isRemoved = true;
            }
          }

          if (changeObj.type == 'add') {
            let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "task_created"));
            task.isRemoved = true;
          }

          if (changeObj.type == 'remove') {
            let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && (changeLog.isRemoved == undefined || !changeLog.isRemoved) && changeLog.action == "task_deleted"));
            task.isRemoved = true;
          }
        }
      });

      gantt.attachEvent("onAfterRedo", function (action) {
        //catch redo actions and handle gantt change logs
        if (action && action.commands) {
          let changeObj = action.commands[action.commands.length - 1];

          if (changeObj.type == 'update') {
            if (changeObj.oldValue.text != changeObj.value.text) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "task_name_changed"));
              task.isRemoved = false;
            }

            if (changeObj.oldValue.status != changeObj.value.status) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "status_changed"));
              task.isRemoved = false;
            }

            if (changeObj.oldValue.start_date.getTime() != changeObj.value.start_date.getTime()) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "start_date_changed"));
              task.isRemoved = false;
            }

            if (globalThis.openedTaskBackup.end_date.getTime() != changeObj.value.end_date.getTime()) {
              let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "end_date_changed"));
              task.isRemoved = false;
            }
          }

          if (changeObj.type == 'add') {
            let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "task_created"));
            task.isRemoved = false;
          }

          if (changeObj.type == 'remove') {
            let task = globalThis.changeLogsBuffer.find(changeLog => (changeLog.taskId == changeObj.value.id && changeLog.isRemoved && changeLog.action == "task_deleted"));
            task.isRemoved = false;
          }

        }
      });

      gantt.attachEvent("onExpand", function () {
        // let icon = gantt.toggleIcon;
        // if (icon) {
        //   icon.className = icon.className.replace("fa-expand", "fa-compress");
        // }

        //create space for gantt menu in full screen
        let ganttElem = document.getElementsByClassName("gantt-chart")[0] as any;
        ganttElem.style.top = '40px';

        //add menu bar into full screen
        let menuElem = document.getElementById("gantt-menu-bar") as any;
        menuElem.classList.add("gantt-menu-full-screen");
      });

      gantt.attachEvent("onCollapse", function () {
        // let icon = gantt.toggleIcon;
        // if (icon) {
        //   icon.className = icon.className.replace("fa-compress", "fa-expand");
        // }

        //remove menu bar from full screen
        let menuElem = document.getElementById("gantt-menu-bar") as any;
        menuElem.classList.remove("gantt-menu-full-screen");
      });

      // set complete_button lable name
      gantt.locale.labels["complete_button"] = "Owner";
      gantt.locale.labels.section_owner = "Owner";
      gantt.locale.labels.section_notes = "Notes";
      gantt.locale.labels.section_status = "Status";
      gantt.locale.labels.section_priority = "Priority";
      gantt.locale.labels.section_document = "Link";
      gantt.locale.labels.baseline_enable_button = 'Set';
      gantt.locale.labels.baseline_disable_button = 'Remove';
      gantt.locale.labels.section_baseline = "Planned";
      gantt.locale.labels["type_meeting"] = "Meeting";
      gantt.locale.labels.section_title = "Subject";
      gantt.locale.labels.section_details = "Details";
      gantt.locale.labels.section_time = "Start Date";
      gantt.locale.labels.section_comments = "Comments";
      gantt.locale.labels.section_meettime = "Date";
      gantt.locale.labels.confirm_deleting = "Item will be deleted permanently, are you sure?";

      // customizeable controls on task creation from popup
      gantt.form_blocks["my_editor"] = {
        render: function (sns) {
          // return "<div class='gantt_cal_ltext'>"
          // +"<input type='text'></div>";
          return `<div class='gantt_cal_ltext'>
              <dx-select-box [dataSource]='ownerOptions' [(ngModel)]='owner_id' placeholder='{{'Owner' | i18n}}'></dx-select-box>
              </div>`
        },
        set_value: function (node, value, task, section) {
          node.childNodes[0].value = value || "";
        },
        get_value: function (node, task, section) {
          return node.childNodes[0].value;
        },
        focus: function (node) {
          globalThis.appRef.tick();
          globalThis.ref.detectChanges();
          globalThis.ref.markForCheck();
          let a = node.childNodes[0];
          a.select();
          a.focus();
        }
      };

      gantt.form_blocks["checkbox"] = {
        render: function (sns) {
          return `<div class="gantt_cal_lsection" style="padding-top: 0;">
            <input type="checkbox" name="status" id="status"> 
            <label for="status" style="position: relative; top: -1px;">Done</label>
          </div>`
        },
        set_value: function (node, value, task, section) {
          node.childNodes[1].checked = value;
        },
        get_value: function (node, task, section) {
          return node.childNodes[1].checked;
        },
        focus: function (node) {
          globalThis.appRef.tick();
          globalThis.ref.detectChanges();
          globalThis.ref.markForCheck();
          let a = node.childNodes[1];
          a.select();
          a.focus();
        }
      };

      // controls that shows on task creation form popup
      gantt.config.lightbox.sections = [
        { name: "description", height: 50, map_to: "text", type: "textarea", focus: true },
        { name: "notes", height: 70, map_to: "notes", type: "textarea", },
        { name: "comments", height: 300, type: "template", map_to: "my_template" },
        //  { name: "owner", height: 50, map_to: "owner_id", type: "select", options: gantt.serverList("people") },
        // { name: "owner", height: 60, type: "my_editor", options: gantt.serverList("people"), map_to: "owner_id" },
        { name: "time", height: 30, type: "time", map_to: 'auto' },
        { name: "type", height: 30, type: "typeselect", map_to: "type" },
        {
          name: "priority", height: 30, map_to: "priority", type: "select", options: [
            { key: 1, label: "High" },
            { key: 2, label: "Medium" },
            { key: 3, label: "Low" }
          ]
        },
        { name: "document", height: 30, type: "textarea", map_to: "document" },
        { name: "status", height: 50, type: "checkbox", map_to: "status" },
        {
          name: "baseline",
          map_to: { start_date: "planned_start", end_date: "planned_end" },
          button: true,
          type: "duration_optional"
        },
      ];

      gantt.templates.task_class = function (start, end, task) {
        if (task.type == gantt.config.types.meeting) {
          return "meeting_task";
        }

        if (task.type == gantt.config.types.milestone && task.status) {
          return "milestone_done";
        }

        if (task.planned_end) {
          let classes = ['has-baseline'];
          if (end.getTime() > task.planned_end.getTime()) {
            classes.push('overdue');
          }
          return classes.join(' ');
        }

        return "";
      };

      gantt.templates.task_text = function (start, end, task) {
        if (task.type == gantt.config.types.meeting) {
          return "Meeting: <b>" + task.text + "</b>";
        }
        return task.text;
      };

      gantt.templates.rightside_text = function (start, end, task) {
        if (task.type == gantt.config.types.milestone) {
          return task.text;
        }

        if (task.planned_end) {
          if (end.getTime() > task.planned_end.getTime()) {
            let overdue = Math.ceil(Math.abs((end.getTime() - task.planned_end.getTime()) / (24 * 60 * 60 * 1000)));
            let text = "<b>Overdue: " + overdue + " days</b>";
            return text;
          }
        }

        return "";
      };

      gantt.attachEvent("onTaskLoading", function (task) {
        task.planned_start = gantt.date.parseDate(task.planned_start, "xml_date");
        task.planned_end = gantt.date.parseDate(task.planned_end, "xml_date");
        return true;
      });


      // Multiselection Indent/Outdent
      let actions = {
        "undo": function () {
          gantt.undo();
        },
        "redo": function () {
          gantt.redo();
        },
        "indent": function indent(task_id) {
          let prev_id = gantt.getPrevSibling(task_id);
          while (gantt.isSelectedTask(prev_id)) {
            let prev = gantt.getPrevSibling(prev_id);
            if (!prev) break;
            prev_id = prev;
          }
          if (prev_id) {
            let new_parent = gantt.getTask(prev_id);
            gantt.moveTask(task_id, gantt.getChildren(new_parent.id).length, new_parent.id);
            new_parent.type = gantt.config.types.project;
            new_parent.$open = true;
            gantt.updateTask(task_id);
            gantt.updateTask(new_parent.id);
            return task_id;
          }
          return null;
        },
        "outdent": function outdent(task_id, initialIndexes, initialSiblings) {
          let cur_task = gantt.getTask(task_id);
          let old_parent = cur_task.parent;
          if (gantt.isTaskExists(old_parent) && old_parent != gantt.config.root_id) {
            let index = gantt.getTaskIndex(old_parent) + 1;
            let prevSibling = initialSiblings[task_id].first;

            if (gantt.isSelectedTask(prevSibling)) {
              index += (initialIndexes[task_id] - initialIndexes[prevSibling]);
            }
            gantt.moveTask(task_id, index, gantt.getParent(cur_task.parent));
            if (!gantt.hasChild(old_parent))
              gantt.getTask(old_parent).type = gantt.config.types.task;
            gantt.updateTask(task_id);
            gantt.updateTask(old_parent);
            return task_id;
          }
          return null;
        },
        "del": function (task_id) {
          if (gantt.isTaskExists(task_id)) gantt.deleteTask(task_id);
          return task_id;
        },
        "moveForward": function (task_id) {
          globalThis.shiftTask(task_id, 1);
        },
        "moveBackward": function (task_id) {
          globalThis.shiftTask(task_id, -1);
        }
      };
      let cascadeAction = {
        "indent": true,
        "outdent": true,
        "del": true
      };

      let singularAction = {
        "undo": true,
        "redo": true
      };

      gantt.performAction = function (actionName) {
        var action = actions[actionName];
        if (!action)
          return;

        if (singularAction[actionName]) {
          action();
          return;
        }

        gantt.batchUpdate(function () {

          // need to preserve order of items on indent/outdent,
          // remember order before changing anything:
          var indexes = {};
          var siblings = {};
          gantt.eachSelectedTask(function (task_id) {
            gantt.ext.undo.saveState(task_id, "task");
            indexes[task_id] = gantt.getTaskIndex(task_id);
            siblings[task_id] = {
              first: null
            };

            var currentId = task_id;
            while (gantt.isTaskExists(gantt.getPrevSibling(currentId)) && gantt.isSelectedTask(gantt.getPrevSibling(currentId))) {
              currentId = gantt.getPrevSibling(currentId);
            }
            siblings[task_id].first = currentId;
          });

          var updated = {};
          gantt.eachSelectedTask(function (task_id) {

            if (cascadeAction[actionName]) {
              if (!updated[gantt.getParent(task_id)]) {
                var updated_id = action(task_id, indexes, siblings);

                updated[updated_id] = true;
              } else {
                updated[task_id] = true;
              }
            } else {
              action(task_id, indexes);
            }
          });
        });
      };

      // Multiselection Indent/Outdent END

      gantt.init(this.ganttContainer.nativeElement);
      this.getProject();
    });
  }

  viewChangeLogs() {
    this.modalRef = this.modalService.show(this.changeLogspopUp, this.configLogsModal);
  }

  viewKanbanView() {
    this.papulateKanbanBoard();
    this.modalRef = this.modalService.show(this.kanbanpopUp, this.configkanbanModal);
  }

  viewMilestoneReport() {
    this.mileStonesValues = [];
    let ganttData = gantt.serialize().data;

    if (ganttData != null && ganttData != undefined) {
      for (let i = 0; i < ganttData.length; i++) {
        if (ganttData[i].type == gantt.config.types.milestone) {
          let mileStonesValuesItem = {};
          mileStonesValuesItem['key'] = 'm_' + ganttData[i].id;
          mileStonesValuesItem['name'] = ganttData[i].text;
          this.mileStonesValues.push(mileStonesValuesItem);
        }
      }
    }
    this.modalRef = this.modalService.show(this.milestoneReportpopUp, this.configMilestoneReportModal);
  }

  createMilestoneReport() {
    let ganttData = gantt.serialize().data;
    if (ganttData == null || ganttData == undefined || ganttData.length == 0) {
      this.showNotificationBox('Error', 'No Milestones in Gantt.', 'warning');
      return;
    }
    let isMilestone = false;

    for (let i = 0; i < ganttData.length; i++) {
      if (ganttData[i].type == gantt.config.types.milestone) {
        isMilestone = true;
      }
    }
    if (!isMilestone) {
      this.showNotificationBox('Error', 'No Milestones in Gantt.', 'warning');
      return;
    }

    if (this.changeLogsBuffer.length > 0) {
      swal({
        title: `${this.localize.transform('Save Changes')}`,
        text: `${this.localize.transform('You have unsaved changes. You need to save your changes first. Save?')}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: `${this.localize.transform('Yes')}`,
        cancelButtonText: `${this.localize.transform('No')}`,
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          //First save data
          this.saveGanttChart();
        }
      });
    } else {
      this.createMilestoneReportHelper();
    }
  }

  createMilestoneReportHelper() {
    let reportingDate = new Date();
    let reportingItem = { reportingDate };
    let ganttData = gantt.serialize().data;

    if (ganttData != null && ganttData != undefined) {
      for (let i = 0; i < ganttData.length; i++) {
        if (ganttData[i].type == gantt.config.types.milestone) {
          reportingItem['m_' + ganttData[i].id] = new Date(this.dateFormat(ganttData[i].start_date));
          reportingItem['date'] = moment().format('DD-MM-YYYY');
        }
      }

      let todaysEntryIndex = this.mtaChart.findIndex(item => item.date == moment().format('DD-MM-YYYY'));
      if (todaysEntryIndex > -1) {
        this.mtaChart.splice(todaysEntryIndex, 1);
      }
      this.mtaChart.push(reportingItem);

      this.spinner.show();
      if (this.mtaObj && this.mtaObj.id) {
        this.apiService.put('/GanttMilestonesReport/' + this.mtaObj.id, { mtaChart: this.mtaChart }).subscribe((response: any) => {
          console.log(response)
          this.showNotificationBox('Save Success', 'Milestone report created.', 'success');
          this.spinner.hide();
          this.fetchGanttMilestoneReport();
        });

      } else {
        this.apiService.post('/GanttMilestonesReport', { projectId: this.project.project.id, mtaChart: this.mtaChart }).subscribe((response: any) => {
          console.log(response)
          this.showNotificationBox('Save Success', 'Milestone report created.', 'success');
          this.spinner.hide();
          this.fetchGanttMilestoneReport();
        });
      }

    } else {
      this.showNotificationBox('Error', 'No Milestones in Gantt.', 'warning');
    }
  }

  toggleFullScreen() {
    if (!gantt.getState().fullscreen) {
      gantt.expand();
    }
    else {
      gantt.collapse();
    }
  }

  changeGroupBy(column) {
    if (column == 'user') {
      this.dateGroupIndex = undefined
      this.taskGroupIndex = undefined;
      this.userGroupIndex = 0;
    }

    if (column == 'task') {
      this.dateGroupIndex = undefined
      this.userGroupIndex = undefined;
      this.taskGroupIndex = 0;
    }

    if (column == 'date') {
      this.userGroupIndex = undefined;
      this.taskGroupIndex = undefined;
      this.dateGroupIndex = 0;

      setTimeout(() => {
        let button = document.getElementsByClassName("dx-datagrid-drag-action")[0] as any;
        button.click();
      }, 200)
    }
  }

  changeGroupByLogs(column) {
    if (column == 'user') {
      this.dateGroupIndexLogs = undefined
      this.taskGroupIndexLogs = undefined;
      this.userGroupIndexLogs = 0;
    }

    if (column == 'task') {
      this.dateGroupIndexLogs = undefined
      this.userGroupIndexLogs = undefined;
      this.taskGroupIndexLogs = 0;
    }

    if (column == 'date') {
      this.userGroupIndexLogs = undefined;
      this.taskGroupIndexLogs = undefined;
      this.dateGroupIndexLogs = 0;

      setTimeout(() => {
        let button = document.getElementsByClassName("dx-datagrid-drag-action")[1] as any;
        button.click();
      }, 200)
    }
  }

  saveComments() {
    let textArea = document.getElementById("comments-area") as any;
    let commentMess = textArea.value;

    if (commentMess.length == 0 || commentMess == "") {
      this.showNotificationBox('Warning', 'Please Enter a comment first.', 'warning');
      return;
    }

    let commentslistElem = document.getElementsByClassName("list-group")[0] as any;

    let task = gantt.getTask(commentslistElem.getAttribute("data-taskId"));
    if (task.comments && task.comments.length > 0) {
      task.comments.push({ userId: this.authService.userObj.id, user: this.authService.userObj.name, message: commentMess, commentTime: moment().format('DD-MM-YYYY HH:mm') })
    } else {
      task.comments = [{ userId: this.authService.userObj.id, user: this.authService.userObj.name, message: commentMess, commentTime: moment().format('DD-MM-YYYY HH:mm') }]
    }

    this.commentsFeedData.push({ id: task.id, name: task.text, comment: commentMess, user: this.authService.userObj.name, commentTime: moment().format('DD-MM-YYYY HH:mm'), commentDate: moment().format('DD-MM-YYYY') });

    this.spinner.show();
    this.ganttChart = gantt.serialize();
    this.apiService.put('/reports/' + this.projectReportId, {
      ganttChart: this.ganttChart
    }).subscribe((response: any) => {
      const div = document.createElement('li');
      div.classList.add("list-group-item", "comment-tile");
      div.style.padding = "0px 0px 0px 0px";

      div.innerHTML = `<div class='row' style='margin: 0px 0px 0px 0px !important; width: 100%;'>
      <div class='col-sm-9' style='margin-right: 0rem;'>
      <h4 style='margin-right: 1rem; font-size: 1rem; line-height: 1em;' class='comment-email'> ${this.authService.userObj.name} </h4>     
      </div>   
      <div class='col-sm-3' style='color: black;'> ${moment().format('DD-MM-YYYY HH:mm')} </div>
      <div class='col-sm-12' style='margin-right: 0rem;'>
      <h5 class='comment-text' style='width:100%; margin-bottom: 10px; font-size: 0.95rem; line-height: 1em; word-break: break-word;'> ${commentMess}  </h5>
      </div>
      </div>`;

      commentslistElem.appendChild(div);
      commentslistElem.scrollIntoView(false);
      textArea.value = '';

      this.showNotificationBox('Save Success', 'Comment has been saved.', 'success');
      this.spinner.hide();
    });

  }

  ngAfterViewChecked() {
    let elem = document.getElementById("saveCommentID") as any;

    if (elem && elem.getAttribute("isEventAttached") != "true") {
      document.getElementById("saveCommentID").addEventListener('click', this.saveComments.bind(this));
      elem.setAttribute("isEventAttached", "true");
    }
  }


  performAction(action: string) {
    gantt.performAction(action);
  }

  switchDateScale(value: string) {
    this.selectedFilter = value;
    gantt.config.scale_unit = value;
    gantt.render();
  }

  getProject() {
    if (this.project.ganttChart != null && this.project.ganttChart != undefined) {
      let [data, links] = [this.project.ganttChart.data, this.project.ganttChart.links];
      gantt.parse({ data, links });
    } else {
      let startDate: any = moment(this.project.startDate);
      let endDate: any = moment(this.project.endDate);
      let dateDiffDays = endDate.diff(startDate, 'days');
      this.tasks.push({
        id: this.project.id,
        text: this.project.projectName,
        notes: this.project.notes || '',
        start_date: moment(this.project.startDate).format('DD/MM/YYYY'),
        end_date: moment(this.project.plannedEndDate).format('DD/MM/YYYY'),
        planned_start: moment(this.project.startDate).format('DD/MM/YYYY'),
        planned_end: moment(this.project.plannedEndDate).format('DD/MM/YYYY'),
        duration: dateDiffDays,
        progress: 0,
        owner_id: this.project.user.id,
        status: this.project.status,
        document: this.project.document,
        color: this.project.status == true ? '#51c185' : '#002d69',
        type: this.project.type
      });

      let [data, links] = [this.tasks, this.links];
      gantt.parse({ data, links });
    }

    gantt.render();
    this.spinner.hide();
  }

  saveGanttChart() {
    this.spinner.show();
    this.ganttChart = gantt.serialize();
    this.apiService.put('/reports/' + this.projectReportId, {
      ganttChart: this.ganttChart
    }).subscribe((response: any) => {
      gantt.clearUndoStack();
      gantt.clearRedoStack();

      if (this.changeLogsBuffer.length > 0) {
        //remove all flagged logs
        let changeLogsBufferFinal = [];

        for (let i = 0; i < this.changeLogsBuffer.length; i++) {
          if (this.changeLogsBuffer[i].isRemoved) {
            continue
          }
          changeLogsBufferFinal.push(this.changeLogsBuffer[i]);
        }

        if (changeLogsBufferFinal.length > 0) {
          this.apiService.post('/createGanttLogs', { logsArray: changeLogsBufferFinal }).subscribe(resp => {
            this.changeLogsBuffer = [];
            this.fetchGanttProjectLogs();
          });
        }
      }

      this.showNotificationBox('Save Success', 'Project gantt chart has been saved.', 'success');
      this.spinner.hide();
    });
  }

  saveOwner() {
    if (this.ownerObj.id != undefined && this.ownerObj.id != null && this.ownerObj.id != '') {
      gantt.serverList("people").push({ id: this.ownerObj.id, text: this.ownerObj.name, email: this.ownerObj.email, parent: null });
      this.peopleList.push({ id: this.ownerObj.id, text: this.ownerObj.name, email: this.ownerObj.email, parent: null });
      gantt.$resourcesStore.parse(gantt.serverList("people"));

      let task = gantt.getTask(this.task.id);
      task.text = this.task.text;
      task.owner_id = this.ownerObj.id;
      task.duration = this.task.time.duration;
      task.end_date = this.task.time.end_date;
      task.start_date = this.task.time.start_date;

      if (this.openedTaskBackup.owner_id != task.owner_id) {
        globalThis.changeLogsBuffer.push({ projectId: this.project.project, action: "owner_changed", new_owner_name: this.ownerObj.name, new_owner_email: this.ownerObj.email, task_type: task.type, taskId: task.id, taskName: task.text, user: globalThis.authService.userObj.id, date: moment().format('DD-MM-YYYY'), dateTime: moment().format('DD-MM-YYYY HH:mm') })
      }

      if (task.$new) {
        delete task.$new;
        gantt.addTask(task, task.parent);
      } else {
        gantt.updateTask(task.id);
      }

      this.toggleGroups();
      this.toggleGroups();
      this.ownerObj = { id: '', name: '', email: '' };
      this.modalRef.hide();
    } else {
      this.showNotificationBox('Warning', 'Please select owner first.', 'warning');
    }
  }

  fetchGanttProjectLogs() {
    this.apiService.get('/ganttLogsByProject/' + this.project.project.id).subscribe((logs: any) => {
      this.projectChangeLogs = logs;
    });
  }

  fetchGanttMilestoneReport() {
    this.apiService.get('/ganttMilestoneReportByProject/' + this.project.project.id).subscribe((response: any) => {
      this.mtaObj = response.length > 0 ? response[0] : undefined;
      this.mtaChart = this.mtaObj ? this.mtaObj.mtaChart : [];

      this.mtaChart.forEach((element, index) => {
        element.reportingDate = new Date(element.reportingDate);
        for (let i = 1; i <= Object.keys(element).length - 1; i++) {
          if (Object.keys(element)[i] != 'date') {
            element[Object.keys(element)[i]] = element[Object.keys(element)[i]] != undefined ? new Date(element[Object.keys(element)[i]]) : null;
          }
        }
        this.mtaChart[index] = element;
      });
    });
  }

  //DD-MM-YYYY HH:mm to YYYY-MM-DD
  dateFormat(date) {
    let dateString = date.split(" ")[0];
    let dateArray = dateString.split("-");
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }

  ownerValueChanged(e) {
    if (this.usersList != undefined) {
      this.usersList.forEach(response => {
        if (response.email == e.value) {
          delete (response.projects);
          this.ownerObj = response;
        }
      });
    }
  }

  ownerOnInputEvent(e) {
    this.apiService.get('/user/search/' + e.event.delegateTarget.value).retry(2).subscribe((response: any) => {
      this.usersList = response;
    });
  }

  checkProgress(element): any {
    if (element.statusReports != undefined) {
      if (element.statusReports.length > 0 && element.statusReports[element.statusReports.length - 1].percentageComplete != undefined) {
        return element.statusReports[element.statusReports.length - 1].percentageComplete / 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // show or hide resouce chart
  toggleResourceChatVisibility() {
    this.isShowResourceChart = !this.isShowResourceChart;

    if (this.isShowResourceChart) {
      gantt.config.layout = this.ganttAndResourceLayout;
    } else {
      gantt.config.layout = this.ganttOnlyLayout;
    }

    gantt.init(this.ganttContainer.nativeElement);
  }

  // show gantt chart group mode or tasks mode
  toggleGroups() {
    gantt.$groupMode = !gantt.$groupMode;
    if (gantt.$groupMode) {
      this.viewOpt = "show gantt view";

      let groups = gantt.$resourcesStore.getItems().map(function (item) {
        let group = gantt.copy(item);
        group.group_id = group.id;
        group.id = gantt.uid();
        return group;
      });

      gantt.groupBy({
        groups: groups,
        relation_property: gantt.config.resource_property,
        group_id: "group_id",
        group_text: "text"
      });
    } else {
      this.viewOpt = "show resource view";
      gantt.groupBy(false);
    }
  }

  // highlighted task on gantt chart 
  shouldHighlightTask(task) {
    let store = gantt.$resourcesStore;
    let taskResource = task[gantt.config.resource_property],
      selectedResource = store.getSelectedId();
    if (taskResource == selectedResource || store.isChildOf(taskResource, selectedResource)) {
      return true;
    }
  }

  // highlighted resource on gantt chart 
  shouldHighlightResource(resource) {
    let selectedTaskId = gantt.getState().selected_task;
    if (gantt.isTaskExists(selectedTaskId)) {
      let selectedTask = gantt.getTask(selectedTaskId),
        selectedResource = selectedTask[gantt.config.resource_property];

      if (resource.id == selectedResource) {
        return true;
      } else if (gantt.$resourcesStore.isChildOf(selectedResource, resource.id)) {
        return true;
      }
    }
    return false;
  }

  // get particular resource task from gantt chart
  getResourceTasks(resourceId) {
    let store = gantt.getDatastore(gantt.config.resource_store),
      field = gantt.config.resource_property,
      tasks;

    if (store.hasChild(resourceId)) {
      tasks = gantt.getTaskBy(field, store.getChildren(resourceId));
    } else {
      tasks = gantt.getTaskBy(field, resourceId);
    }
    return tasks;
  }

  ngOnDestroy() {
    this.socketService.removeListener('fetchMultipleUsers');
  }

  showNotificationBox(title, msg, type) {
    this.utilityService.showNotification(this.localize.transform(title), this.localize.transform(msg), type);
  }

  exportGantt(type: string) {
    switch (type) {
      case 'PDF': {
        gantt.exportToPDF({
          raw: true
        });
        break;
      }
      case 'PNG': {
        gantt.exportToPNG({
          raw: true
        });
        break;
      }
      case 'Excel': {
        var oldCols = gantt.config.columns;
        gantt.config.columns = [
          { name: "", label: "", width: "60", template: gantt.getWBSCode },
          {
            name: "text", label: this.localize.transform("Project Name/Task Name"), tree: true, align: "left", width: "300", template: function (obj) {
              return obj.text
            }
          },
          { name: "start_date", label: this.localize.transform("Start Date"), align: "center", width: 60 },
          { name: "end_date", label: this.localize.transform("End Date"), align: "center", width: 60 },
          {
            name: "owner", align: "center", width: "120", label: this.localize.transform("Owner"),
            template: function (task) {
              if (task.type == gantt.config.types.project) {
                return "";
              }

              let store = gantt.getDatastore(gantt.config.resource_store);
              let owner = store.getItem(task[gantt.config.resource_property]);
              if (owner) {
                return owner.text;
              } else {
                return "Unassigned";
              }
            }
          },
          {
            name: "duration", label: this.localize.transform("Duration"), width: "60", align: "center", template: function (obj) {
              return obj.type == gantt.config.types.meeting ? 0 : obj.duration;
            }
          },
        ];

        gantt.exportToExcel(
          {
            name: 'Gantt.xlsx',
            cellColors: true,
            date_format: "DD-MM-YYYY"
          });

        gantt.config.columns = oldCols;

        break;
      }
      case 'MS Project': {
        gantt.exportToMSProject();
        break;
      }
    }
  }

  toggleCriticalPath() {
    this.isCriticalPathShow = !this.isCriticalPathShow;

    if (this.isCriticalPathShow) {
      gantt.config.highlight_critical_path = true;
    } else {
      gantt.config.highlight_critical_path = false;
    }

    gantt.render();
  }

  shiftTask(task_id, direction) {
    var task = gantt.getTask(task_id);
    task.start_date = gantt.date.add(task.start_date, direction, "day");
    task.end_date = gantt.calculateEndDate(task.start_date, task.duration);
    gantt.updateTask(task.id);
  }

  // Kanban methods
  boardDrop(event: CdkDragDrop<Board[]>) {
    moveItemInArray(this.boardData, event.previousIndex, event.currentIndex);
  }

  papulateKanbanBoard() {
    this.boardData[0].list = [];
    this.boardData[1].list = [];
    this.boardData[2].list = [];
    this.boardData[3].list = [];

    let ganttData = gantt.serialize().data;

    if (ganttData != null && ganttData != undefined) {
      ganttData.forEach((taskObj) => {
        if (taskObj.type == gantt.config.types.task) {
          let ownerObj = this.peopleList.find(val => val.id == taskObj.owner_id);

          if (taskObj.status == true) {
            this.boardData[3].list.push({ id: taskObj.id, title: taskObj.text, endDate: taskObj.end_date ? taskObj.end_date.split(" ")[0] : "", assignedTo: taskObj.owner_id == null ? taskObj.owner_id : ownerObj.text });
          } else if (taskObj.status == false) {
            if (taskObj.kanbanStatus == 'inProgress') {
              this.boardData[1].list.push({ id: taskObj.id, title: taskObj.text, endDate: taskObj.end_date ? taskObj.end_date.split(" ")[0] : "", assignedTo: taskObj.owner_id == null ? taskObj.owner_id : ownerObj.text });
            } else if (taskObj.kanbanStatus == 'testing') {
              this.boardData[2].list.push({ id: taskObj.id, title: taskObj.text, endDate: taskObj.end_date ? taskObj.end_date.split(" ")[0] : "", assignedTo: taskObj.owner_id == null ? taskObj.owner_id : ownerObj.text });
            } else {
              this.boardData[0].list.push({ id: taskObj.id, title: taskObj.text, endDate: taskObj.end_date ? taskObj.end_date.split(" ")[0] : "", assignedTo: taskObj.owner_id == null ? taskObj.owner_id : ownerObj.text });
            }
          }
        }
      });
      setTimeout(() => {
        this.boardDataBackup = JSON.parse(JSON.stringify(this.boardData));
      }, 1000)
    }
  }

  onKanbanSearch(event) {
    let search = event.target.value;
    if (search != undefined) {
      this.boardData[0].list = this.boardDataBackup[0].list.filter(item => (item.title.toUpperCase().includes(search.toUpperCase())) || (item.assignedTo && item.assignedTo.toUpperCase().includes(search.toUpperCase())));
      this.boardData[1].list = this.boardDataBackup[1].list.filter(item => (item.title.toUpperCase().includes(search.toUpperCase())) || (item.assignedTo && item.assignedTo.toUpperCase().includes(search.toUpperCase())));
      this.boardData[2].list = this.boardDataBackup[2].list.filter(item => (item.title.toUpperCase().includes(search.toUpperCase())) || (item.assignedTo && item.assignedTo.toUpperCase().includes(search.toUpperCase())));
      this.boardData[3].list = this.boardDataBackup[3].list.filter(item => (item.title.toUpperCase().includes(search.toUpperCase())) || (item.assignedTo && item.assignedTo.toUpperCase().includes(search.toUpperCase())));
    }
  }

  get columnId(): string[] {
    return this.boardData.map(obj => obj.columnId);
  }

  listItemdrop(event: CdkDragDrop<ListItem[]>, column) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.updateEpicKanbanStatus(event.container.data[event.currentIndex], event.previousContainer.id, event.container.id);
    }
  }

  updateEpicKanbanStatus(taskObj, previousState, currentState) {
    if (currentState == 'todo') {
      let task = gantt.getTask(taskObj.id);
      task.status = false;
      task.kanbanStatus = 'todo';
    } else if (currentState == 'inProgress') {
      let task = gantt.getTask(taskObj.id);
      task.status = false;
      task.kanbanStatus = 'inProgress';
    } else if (currentState == 'testing') {
      let task = gantt.getTask(taskObj.id);
      task.status = false;
      task.kanbanStatus = 'testing';
    } else if (currentState == 'done') {
      let task = gantt.getTask(taskObj.id);
      task.status = true;
    }

    gantt.render();
  }
}

export class Task {
  id: number;
  start_date: string;
  end_date: string;
  planned_start?: string;
  planned_end?: string;
  text: string;
  duration: number;
  owner_id: any;
  progress: number;
  //parent: number;,
  color?: string;
  notes?: string;
  status?: boolean;
  document?: string;
  type?: any;
}

interface Board {
  columnId: string;
  column: string;
  list: Array<ListItem>;
}

interface ListItem {
  id: string;
  title: string;
  assignedTo; string;
  product: string;
}
