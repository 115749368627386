import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectOutlineComponent } from './project-outline.component';
import { CanDeactivateService } from 'app/services/can-deactivate.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProjectOutlineComponent,
        canDeactivate: [CanDeactivateService]
      }
    ],
    data: {
      allowedRoles: ['pmo', 'admin', 'pm', 'guest']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectOutlineRoutingModule { }