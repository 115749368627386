import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportedSapRoutingModule } from './imported-sap-routing.module';
import { ImportedSapComponent } from './imported-sap.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { MaterialModule } from 'app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DxDataGridModule } from 'devextreme-angular';
import { CurrencyFormatterPipeModule } from 'app/shared/currency-formatter-pipe/currencyFormatterPipe.module';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  declarations: [ImportedSapComponent],
  imports: [
    CommonModule,
    ImportedSapRoutingModule,
    I18nModule,
    MaterialModule,
    DxDataGridModule,
    CurrencyFormatterPipeModule,
    NgxSpinnerModule,
    ClipboardModule
  ]
})
export class ImportedSapModule { }
