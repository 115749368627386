import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ApiService } from 'app/services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UtilityService } from 'app/services/utility.service';
import { I18nPipe } from 'app/shared/i18n/i18n.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-strategic-business-drivers',
  templateUrl: './strategic-business-drivers.component.html',
  styleUrls: ['./strategic-business-drivers.component.scss']
})
export class StrategicBusinessDriversComponent implements OnInit {
  businessDrivers = [];
  valueItems;
  driver1 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver2 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver3 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver4 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver5 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver6 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  driver7 = {
    name: '',
    value: '',
    description: '',
    pointsDescription: ['Sample Text', '', '', '', '', ''],
  };
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  editFormObj = [];
  currentEditKey;

  constructor(private authService: AuthService, private apiService: ApiService, private modalService: BsModalService,
    private utilityService: UtilityService, private localize: I18nPipe, private spinner: NgxSpinnerService) {
    this.utilityService.breadcrumb = 'Strategic Business Drivers';
    this.utilityService.breadcrumbList = ['Settings', 'Strategic Business Drivers'];

    this.apiService.get('/getGlobalConfigurations').subscribe((response: any) => {
      if (response.businessDrivers == undefined) {
        this.businessDrivers = [{}, {}, {}, {}, {}, {}, {}]
      } else {
        this.businessDrivers = response.businessDrivers;

        this.driver1 = {
          name: this.businessDrivers[0].name,
          value: this.businessDrivers[0].value,
          description: this.businessDrivers[0].description,
          pointsDescription: this.businessDrivers[0].pointsDescription
        };
        this.driver2 = {
          name: this.businessDrivers[1].name,
          value: this.businessDrivers[1].value,
          description: this.businessDrivers[1].description,
          pointsDescription: this.businessDrivers[1].pointsDescription
        };
        this.driver3 = {
          name: this.businessDrivers[2].name,
          value: this.businessDrivers[2].value,
          description: this.businessDrivers[2].description,
          pointsDescription: this.businessDrivers[2].pointsDescription
        };
        this.driver4 = {
          name: this.businessDrivers[3].name,
          value: this.businessDrivers[3].value,
          description: this.businessDrivers[3].description,
          pointsDescription: this.businessDrivers[3].pointsDescription
        };
        this.driver5 = {
          name: this.businessDrivers[4].name,
          value: this.businessDrivers[4].value,
          description: this.businessDrivers[4].description,
          pointsDescription: this.businessDrivers[4].pointsDescription
        };
        this.driver6 = {
          name: this.businessDrivers[5].name,
          value: this.businessDrivers[5].value,
          description: this.businessDrivers[5].description,
          pointsDescription: this.businessDrivers[5].pointsDescription
        };
        this.driver7 = {
          name: this.businessDrivers[6].name,
          value: this.businessDrivers[6].value,
          description: this.businessDrivers[6].description,
          pointsDescription: this.businessDrivers[6].pointsDescription
        };
      }
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.valueItems = [0, 1, 2, 3, 4, 5];
  }

  openModal(template: TemplateRef<any>, arrObj, key) {
    this.editFormObj = arrObj;
    this.currentEditKey = key;
    this.modalRef = this.modalService.show(template, this.config);
  }

  save() {
    this.parser();

    let total: any = this.driver1.value + this.driver2.value + this.driver3.value + this.driver4.value + this.driver5.value + this.driver6.value + this.driver7.value;
    if (total > 100) {
      this.utilityService.showNotification(
        this.localize.transform("Warning"),
        this.localize.transform("Priority Weightage must not be greater than 100."),
        "success",
      );
      return;
    }
    this.spinner.show();

    this.apiService.put('/configurations/' + this.authService.userObj.configuration.id, {
      businessDrivers: this.businessDrivers
    }).subscribe(response => {
      this.authService.userObj.configuration = response;
      this.authService.setUserObj(this.authService.userObj);

      this.utilityService.showNotification(
        this.localize.transform("Save Success"),
        this.localize.transform("Strategic Business Drivers Configuration has been saved"),
        "success",
      );
      this.spinner.hide();
    });
  }

  edit() {
    this.modalRef.hide();
  }

  parser() {
    this.businessDrivers[0].name = this.driver1.name;
    this.businessDrivers[0].value = this.driver1.value;
    this.businessDrivers[0].description = this.driver1.description;
    this.businessDrivers[0].pointsDescription = this.driver1.pointsDescription;

    this.businessDrivers[1].name = this.driver2.name;
    this.businessDrivers[1].value = this.driver2.value;
    this.businessDrivers[1].description = this.driver2.description;
    this.businessDrivers[1].pointsDescription = this.driver2.name == '' ? [] : this.driver2.pointsDescription;

    this.businessDrivers[2].name = this.driver3.name;
    this.businessDrivers[2].value = this.driver3.value;
    this.businessDrivers[2].description = this.driver3.description;
    this.businessDrivers[2].pointsDescription = this.driver3.name == '' ? [] : this.driver3.pointsDescription;

    this.businessDrivers[3].name = this.driver4.name;
    this.businessDrivers[3].value = this.driver4.value;
    this.businessDrivers[3].description = this.driver4.description;
    this.businessDrivers[3].pointsDescription = this.driver4.name == '' ? [] : this.driver4.pointsDescription;

    this.businessDrivers[4].name = this.driver5.name;
    this.businessDrivers[4].value = this.driver5.value;
    this.businessDrivers[4].description = this.driver5.description;
    this.businessDrivers[4].pointsDescription = this.driver5.name == '' ? [] : this.driver5.pointsDescription;

    this.businessDrivers[5].name = this.driver6.name;
    this.businessDrivers[5].value = this.driver6.value;
    this.businessDrivers[5].description = this.driver6.description;
    this.businessDrivers[5].pointsDescription = this.driver6.name == '' ? [] : this.driver6.pointsDescription;

    this.businessDrivers[6].name = this.driver7.name;
    this.businessDrivers[6].value = this.driver7.value;
    this.businessDrivers[6].description = this.driver7.description;
    this.businessDrivers[6].pointsDescription = this.driver7.name == '' ? [] : this.driver7.pointsDescription;
  }

  onValueChangedDriver1(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver2(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver3(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver4(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver5(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver6(e) {
    // this.driver1Points = e.itemData;
  }

  onValueChangedDriver7(e) {
    // this.driver1Points = e.itemData;
  }
}
