import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectDetailComponent } from './project-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectDetailComponent,
    children: [
      {
        path: 'overview',
        loadChildren: () => import('./project-overview/project-overview.module').then(mod => mod.ProjectOverviewModule)
      },
      {
        path: 'statusReport',
        loadChildren: () => import('./project-status-report/project-status-report.module').then(mod => mod.ProjectStatusReportModule)
      },
      {
        path: 'importedSap',
        loadChildren: () => import('./imported-sap/imported-sap.module').then(mod => mod.ImportedSapModule)
      },
      {
        path: 'team/:id',
        loadChildren: () => import('./project-team/project-team.module').then(mod => mod.ProjectTeamModule)
      },
      {
        path: 'lessonsLearned',
        loadChildren: () => import('./project-lessons-learned/project-lessons-learned.module').then(mod => mod.ProjectLessonsLearnedModule)
      },
      {
        path: 'gantt',
        loadChildren: () => import('./project-gantt-chart/project-gantt-chart.module').then(mod => mod.ProjectGanttChartModule)
      },
      {
        path: 'budget',
        loadChildren: () => import('./project-budget/project-budget.module').then(mod => mod.ProjectBudgetModule)
      },
      {
        path: 'sanierung-wk',
        loadChildren: () => import('./project-sanierung/project-sanierung.module').then(mod => mod.ProjectSanierungModule)
      },
      {
        path: 'eva',
        loadChildren: () => import('./project-eva/project-eva.module').then(mod => mod.ProjectEVAModule)
      },
      {
        path: 'trendAnalysis',
        loadChildren: () => import('./project-trend-analysis/project-trend-analysis.module').then(mod => mod.ProjectTrendAnalysisModule)
      },
      {
        path: 'checklist',
        loadChildren: () => import('./project-checklist/project-checklist.module').then(mod => mod.ProjectChecklistModule)
      },
      {
        path: 'phasesFinancials',
        loadChildren: () => import('./project-phases-financials/project-phases-financials.module').then(mod => mod.ProjectPhasesFinancialsModule)
      }
    ]
  },
  {
    path: 'history',
    loadChildren: () => import('./project-history/project-history.module').then(mod => mod.ProjectHistoryModule)
  },
  {
    path: 'statusReport/create/:previous',
    loadChildren: () => import('./create-project-report/create-project-report.module').then(mod => mod.CreateProjectReportModule)
  },
  {
    path: 'statusReport/create/:previous/:current',
    loadChildren: () => import('./create-project-report/create-project-report.module').then(mod => mod.CreateProjectReportModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectDetailRoutingModule { }
