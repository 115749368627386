import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallOrderDetailsRoutingModule } from './small-order-details-routing.module';
import { SmallOrderDetailsComponent } from './small-order-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [SmallOrderDetailsComponent],
  imports: [
    CommonModule,
    SmallOrderDetailsRoutingModule,
    NgxSpinnerModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SmallOrderDetailsModule { }
